import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import { AddMenu } from "components/TopNav/AddMenu";
import { CompanyMenu } from "components/TopNav/CompanyMenu";
import { HelpMenu } from "components/TopNav/HelpMenu";
import { Notifications } from "components/TopNav/Notifications";
import { SettingsMenu } from "components/TopNav/SettingsMenu";
import { SyncMenu } from "components/TopNav/SyncMenu";
import { TopNavIcon } from "components/TopNav/TopNavIcon";
import { UserMenu } from "components/TopNav/UserMenu";
import { Loading } from "components/utility/Loading";

import { i18n } from "services/i18nService";

import { usePrivileges } from "hooks/usePrivileges";

import { setSelectedMenuNode } from "globalState/leftNavSlice";

import { theme } from "SosTheme";
import { TOP_NAV_BAR_HEIGHT } from "appConstants";
import {
  HIDDEN_TOP_NAV_PATHS,
  USER_PRIVILEGE,
  CONFIGURATION_PATH,
} from "appConstants";

const sx = {
  gridArea: "header",
  height: TOP_NAV_BAR_HEIGHT,
  position: "fixed",
  zIndex: "topNavBar",
  displayPrint: "none",
};

const styles = {
  navLeft: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  navRight: {
    display: "flex",
    alignItems: "start",
    gap: theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
  },
  topBar: {
    height: TOP_NAV_BAR_HEIGHT,
    width: "100vw",
    minWidth: theme.breakpoints.values.md,
    boxShadow: "0px 0 4px 0px rgb(0 0 0 / 50%)",
    top: 0,
    left: 0,
    backgroundColor: "white",
    zIndex: theme.zIndex.topNavBar,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    justifyContent: "space-between",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
};

export function TopNavBar() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { hasPrivilegesOrIsAdmin } = usePrivileges();

  const isLoggedIn = useSelector((state) => state.authentication.isLoggedIn);
  const loadingState = useSelector((state) => state.loading.list);

  const viewSync = hasPrivilegesOrIsAdmin(USER_PRIVILEGE.sync);

  const isConfigurationPath = pathname.startsWith(CONFIGURATION_PATH);
  const companyChooserPath = pathname === "/choosecompany";
  const expiredOrDisabledPath =
    pathname === "/disabled" || pathname === "/expired";

  if (HIDDEN_TOP_NAV_PATHS.includes(pathname) || !isLoggedIn) {
    return null;
  }

  return (
    <>
      {isConfigurationPath && (
        <Box
          sx={{
            position: "relative",
            height: TOP_NAV_BAR_HEIGHT,
            width: "100vw",
            zIndex: theme.zIndex.topNavBarOverLay,
          }}
        />
      )}
      <Box sx={{ ...sx, opacity: isConfigurationPath ? "0.5" : "1" }}>
        <div style={styles.topBar}>
          <div style={styles.navLeft}>
            <Link to="/">
              <div
                data-testing="top-left-logo"
                // this onClick function is here for Cypress testing; it
                // closes all the left nav dropdowns to a known state to
                // facilitate opening the desired one
                onClick={() => dispatch(setSelectedMenuNode(null))}
              >
                <img
                  src="/images/sos_logo.png"
                  alt="SOS Inventory logo"
                  style={{ height: "35px", display: "block" }}
                />
              </div>
            </Link>
            <CompanyMenu pathname={pathname} />
          </div>
          <div style={styles.navRight}>
            {isLoggedIn === "yes" && !companyChooserPath && (
              <>
                {!expiredOrDisabledPath && (
                  <>
                    <Link to="/search">
                      <TopNavIcon
                        image="/images/search.svg"
                        functionText={i18n("iconAltText.search")}
                      />
                    </Link>
                    <AddMenu />
                    <SettingsMenu />
                    {viewSync && <SyncMenu />}
                    <TopNavIcon
                      image="/images/print.svg"
                      onClick={() => window.print()}
                      functionText={i18n("iconAltText.print")}
                    />
                    <Notifications />
                    <HelpMenu />
                  </>
                )}
                <UserMenu />
              </>
            )}
          </div>
        </div>
        {loadingState && !isConfigurationPath && (
          <Box>
            <Loading />
          </Box>
        )}
      </Box>
    </>
  );
}
