import { ReportMultiSelect } from "components/formFields/ReportMultiSelect";

import { i18n } from "services/i18nService";

import { ALL_FILTER_OPTION } from "appConstants";

export function CategoryFilter(props) {
  const {
    value,
    name,
    categories,
    onValueChange,
    label,
    setAllFilterChoicesSelected,
  } = props;

  function handleValueChange(name, value) {
    onValueChange(name, value);
    // track whether all choices are selected within this filter
    setAllFilterChoicesSelected({
      [name]: categories && categories.length === value?.length,
    });
  }

  return (
    <ReportMultiSelect
      name={name}
      options={
        categories?.length ? [ALL_FILTER_OPTION, ...categories] : categories
      }
      onValueChange={handleValueChange}
      value={value}
      placeholder={i18n("reports.placeholder.DefaultIsAll", {
        type: i18n("reports.categories"),
      })}
      label={label}
    />
  );
}
