import { useEffect, useState } from "react";

import { IN_TRANSACTION } from "appConfig";

import { getList } from "services/sosInventoryService/sosApi";
import { formatName } from "services/utility/formatting";

import { OBJECT_TYPES } from "appConstants";

/**
 * @name    useFetchFilterNames
 *
 * @summary This hook is currently only used as a part of the report page sub header
 *          logic. This dynamic fetching was needed since the custom report GET requests
 *          return the filter data without the name component, i.e. {id: 7}).
 *
 *          This hook takes a list of objects each with an objectType value
 *          and an additional customReportKey value (if the corresponding custom report
 *          data exists on a key that is seperate from the object type we need to use to
 *          fetch the name data).
 *
 *          The custom report data is then mapped through and we fetch the record value
 *          id present for the relevant record values, and then return the same data
 *          except now with the name data included.
 *
 * @param   customReportNameFetchList (Array) - object type array to be mapped
 *          through in order to fetch data
 *
 * @param   report (Object) - the custom report data corresponding to the
 *          filters, columns, etc for the custom report
 *
 *  @param  isCustomReport (Boolean) - corresponds to whether the hook is being called
 *          for a custom report or a regular report. The data provided by this hook is
 *          only needed for custom reports.
 *
 * @returns (Object) - returns an object with key value pair for each of the entries
 *          within customReportNameFetchList, whose values are an array of objects
 *          that includes both the id and name needed for the report sub-header UI
 *
 */

export function useFetchFilterNames(
  customReportNameFetchList,
  report,
  isCustomReport
) {
  const [list, setList] = useState(null);

  useEffect(() => {
    async function retrieveRecords() {
      try {
        const listArray = await Promise.all(
          customReportNameFetchList.map(
            async ({ objectType, customReportKey }) => {
              const reportFilterKey = customReportKey || objectType;
              if (!report[reportFilterKey]) {
                return null;
              }
              const ids = Array.isArray(report[reportFilterKey])
                ? report[reportFilterKey].map(({ id }) => id).join(",")
                : report[reportFilterKey].id;

              const records = await getList(
                objectType,
                { inTransaction: IN_TRANSACTION, ids },
                IN_TRANSACTION
              );
              return records.map((record) => ({
                id: record.id,
                name:
                  objectType === OBJECT_TYPES.SALES_REP.fullString
                    ? formatName(record)
                    : record.name,
              }));
            }
          )
        );
        const listObject = listArray.reduce((seed, e, i) => {
          const { objectType, customReportKey } = customReportNameFetchList[i];
          seed[customReportKey || objectType] = e;
          return seed;
        }, {});
        setList(listObject);
      } catch (e) {
        console.error(e);
      }
    }
    if (isCustomReport && customReportNameFetchList?.length) {
      retrieveRecords();
    }
  }, [customReportNameFetchList, isCustomReport, report]);

  return list;
}
