import { useEffect, useState } from "react";

import { Box, Card, CardContent, CardHeader, Typography } from "@mui/material";

import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { jsonFetch } from "services/sosInventoryService/sosApi";

export function About() {
  const [backEndInfo, setBackEndInfo] = useState({
    version: i18n("global.Loading.Lower"),
  });

  useEffect(() => {
    async function getBackEndInfo() {
      const response = await jsonFetch("GET", "/info/apiversion");
      const { success, json } = response;
      if (success) {
        setBackEndInfo(json.data);
      } else {
        setBackEndInfo({
          version: i18n("global.CurrentlyUnavailable"),
        });
      }
    }
    getBackEndInfo();
  }, []);

  return (
    <Box sx={{ "& a": { color: "primaryLink" } }} m={2} data-testing="homePage">
      <Card variant="outlined" sx={{ maxWidth: 600, mt: "1rem", mb: "1rem" }}>
        <CardHeader
          title="SOS Inventory"
          subheader="&copy; 2008-2023 by SOS Inventory Software, LLC. All Rights
            Reserved."
          titleTypographyProps={{ variant: "h2" }}
        />
        <CardContent>
          <Typography>
            {`${i18n("about.version")}: 0.3.1-${
              process.env.REACT_APP_BUILD_NUMBER
            }`}
          </Typography>
          <Typography>
            {`${i18n("about.apiVersion")}: ${backEndInfo.version}`}
          </Typography>
          <VSpace space={1} />
          <Typography>
            <a href="https://www.sosinventory.com/terms-of-service">
              {i18n("about.termsOfService")}
            </a>
          </Typography>
          <Typography>
            <a href="https://www.sosinventory.com/privacy-policy">
              {i18n("about.privacyPolicy")}
            </a>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
