import { useState, useEffect } from "react";

import { DEBOUNCE_WAIT } from "appConfig";

import { RowItemSelect } from "components/formFields/row/RowItemSelect";

import { getItemQuicklist } from "services/sosInventoryService/sosApi";
import { itemsIncludingValue } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";

// can pass *either* itemType or itemFormType, but not both
export function RowItem(props) {
  const {
    value,
    itemsCount,
    dataIndex,
    items: externalItems,
    onValueChange,
    itemType,
    itemFormType,
  } = props;

  const [items, setItems] = useState(externalItems);
  const [timeoutId, setTimeoutId] = useState();

  useEffect(() => setItems(externalItems), [externalItems]);

  if ((itemType && itemFormType) || (!itemType && !itemFormType)) {
    handleProgramError(
      new Error(
        `RowItem must be passed itemType ${itemType} or itemFormType ${itemFormType}, but not both`
      )
    );
  }

  const fetchItemsOnChange = items && items.length < itemsCount;

  function fetchItems(query) {
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(async () => {
        const { data } = itemFormType
          ? await getItemQuicklist({ formType: itemFormType, query })
          : await getItemQuicklist({ type: itemType, query });
        setItems(itemsIncludingValue(data, value));
      }, DEBOUNCE_WAIT)
    );
  }

  return (
    <RowItemSelect
      dataIndex={dataIndex}
      options={items || null}
      onValueChange={onValueChange}
      value={value}
      onInputChange={(_, queryString) =>
        fetchItemsOnChange && fetchItems(queryString)
      }
      onBlur={() => fetchItemsOnChange && setItems(externalItems)}
      fetchItemsOnChange={fetchItemsOnChange}
      dataTesting="item"
    />
  );
}
