import Dialog from "@mui/material/Dialog";

import { QuickView } from "components/quickView/QuickView";

const styles = {
  height: "100%",
  "& .MuiDialog-paper": {
    minHeight: "93vh",
    maxHeight: "93vh",
    "@media print": { minHeight: "100%", maxHeight: "100%" },
  },
};

export function QuickViewModal(props) {
  const { objectType, onClose, id } = props;

  return (
    <Dialog
      open
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth="md"
      sx={styles}
    >
      <QuickView objectType={objectType} id={id} onClose={onClose} />
    </Dialog>
  );
}
