import { convertNativeToCustom } from "services/utility/customFields";
import { normalizeJobWorkCenter } from "services/utility/jobWorkCenter";
import { cleanTransactionNumber } from "services/utility/misc";
import { TYPES } from "services/utility/schema";

import { LINE_ITEM_FIELDS } from "editConfig";

export function inventoryTransactionBeforeSave(objectType, record) {
  const newRecord = { ...record };

  newRecord.number = cleanTransactionNumber(record.id, record.number);

  // remove any line items with no inventory item
  let materialLines = record.lines.filter((line) => line.item?.id);

  // for each line
  const newLines = [];
  materialLines.forEach((line) => {
    let newLine = { ...line };

    newLine = normalizeJobWorkCenter(newLine);

    // normalize other reference fields; if no referenced record is selected,
    // set the field to null
    LINE_ITEM_FIELDS[objectType][TYPES.reference].forEach((field) => {
      if (
        line[field] &&
        typeof line[field].id === "string" &&
        line[field].id === ""
      ) {
        newLine[field] = null;
      }
    });

    // convert any number or currency fields that are "" to zero
    const fieldsToCheck = LINE_ITEM_FIELDS[objectType][TYPES.number];
    fieldsToCheck.forEach((field) => {
      if (line[field] === "") {
        newLine[field] = 0;
      }
    });

    // convert item to remove unnecessary data
    if (line.item?.id) {
      newLine.item = { id: line.item.id };
    }

    // remove itemDetails from line; it's only used in this frontend
    delete newLine.itemDetails;

    // remove relatedRecords from line; it's only used in this frontend
    delete newLine.relatedRecords;

    newLines.push(newLine);
  });

  newRecord.customFields = convertNativeToCustom(record.customFields);

  // update the record with the new lines
  newRecord.lines = newLines;

  return newRecord;
}
