import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { DatePicker } from "components/html/Input/DatePicker";

import { i18n } from "services/i18nService";
import { generatePurchaseOrdersFromReorders } from "services/sosInventoryService/sosApi";
import { SUCCESS } from "services/sosInventoryService/sosApi";

import { openAlert } from "globalState/alertSlice";
import { recordsChangedTick } from "globalState/recordsChangedSlice";

import { ButtonProgress } from "./utility/ButtonProgress";

export function GeneratePurchaseOrdersDialog(props) {
  const { open, onClose, objectIds } = props;

  const dispatch = useDispatch();

  const [date, setDate] = useState(new Date());
  const [dateError, setDateError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  async function generatePos() {
    if (!date) {
      setDateError(true);
      return;
    }
    setInProgress(true);
    const response = await generatePurchaseOrdersFromReorders(objectIds, date);
    if (response === SUCCESS) {
      onClose();
      dispatch(
        openAlert({
          type: "success",
          message: i18n("alert.SuccessGeneratePosFromReorders"),
        })
      );
      dispatch(recordsChangedTick());
    } else {
      onClose();
      dispatch(
        openAlert({ type: "error", message: i18n("alert.ErrorGeneral") })
      );
    }
    setInProgress(false);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      sx={{ inset: "0 0 30%" }}
    >
      <DialogTitle>{i18n("global.GeneratePurchaseOrders")}</DialogTitle>
      <DialogContent>
        <div>
          <DatePicker
            value={date}
            onValueChange={(_, newDate) => {
              setDate(newDate);
              setDateError(false);
            }}
            label={i18n("frmLabel.PurchaseOrderDate")}
            error={dateError}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {i18n("button.Cancel")}
        </Button>
        <div style={{ position: "relative" }}>
          <Button
            color="secondary"
            disabled={dateError || inProgress}
            onClick={generatePos}
          >
            {i18n("button.Generate")}
          </Button>
          {inProgress && <ButtonProgress color="secondary" />}
        </div>
      </DialogActions>
    </Dialog>
  );
}
