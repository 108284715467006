import { i18n } from "services/i18nService";

import { initialErrorState } from "views/common";

export function isValid(itemUoms, baseUom) {
  const errors = initialErrorState();

  if (!baseUom) {
    errors.messages.push(i18n("isValid.uom.MustHaveBaseUnitSelected"));
    errors.entity.push("baseUnit");
  }

  itemUoms.forEach((_, index) => {
    errors.line[index] = [];
  });

  itemUoms.forEach((itemUom, index) => {
    // uom conversion does not match base uom
    if (itemUom.uom?.id === baseUom?.id) {
      errors.messages.push(
        i18n("isValid.uom.BaseUnitAlsoInTable", {
          lineNumber: index + 1,
        })
      );
      errors.line[index].push("uom");
    }
  });

  itemUoms.forEach((itemUom, index) => {
    // does not have a conversion
    if (itemUom.uom && !itemUom.conversion) {
      errors.messages.push(
        i18n("isValid.uom.RatioRequired", {
          lineNumber: index + 1,
        })
      );
      errors.line[index].push("conversion");
    }
  });

  itemUoms.forEach((itemUom, index) => {
    // does not have a uom selection
    if (itemUom.conversion && !itemUom.uom) {
      errors.messages.push(
        i18n("isValid.uom.UomRequired", {
          lineNumber: index + 1,
        })
      );
      errors.line[index].push("uom");
    }
  });

  const itemUomIds = itemUoms
    .filter(({ uom }) => uom)
    .map(({ uom }) => uom?.id);

  const itemUomSet = new Set(itemUomIds);
  itemUomIds.forEach((item, index) => {
    if (itemUomSet.has(item)) {
      itemUomSet.delete(item);
    } else {
      errors.messages.push(
        i18n("isValid.uom.DuplicateUom", { lineNumber: index + 1 })
      );
      errors.line[index].push("uom");
    }
  });

  return errors;
}
