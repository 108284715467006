import { useState } from "react";

import { Box } from "@mui/material";

import { FrmTextField } from "components/formFields/FrmTextField";
import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";
import { isOneOrMoreEmailAddresses } from "services/utility/validation";

export function FrmEmail(props) {
  const {
    name,
    disabled,
    label,
    value,
    onBlur,
    setEmailValid = () => {},
    showHoverHelp = true,
    sx,
  } = props;

  const [touched, setTouched] = useState(false);
  const [valid, setValid] = useState(false);

  function handleValueChange(_, newValue) {
    setTouched(true);
    const isValid = isOneOrMoreEmailAddresses(newValue);
    setValid(isValid);
    setEmailValid(isValid);
  }

  function handleValueBlur(_, newValue) {
    onBlur(name, newValue);
  }

  return (
    <>
      <Box width="20em" display="flex" alignItems="center" sx={sx}>
        <FrmTextField
          value={value || ""}
          label={label}
          disabled={disabled}
          onValueChange={handleValueChange}
          onValueBlur={handleValueBlur}
          error={touched && !valid}
        />
        {showHoverHelp && (
          <HoverHelp
            title={i18n("hoverHelp.SeparateEmailByComma")}
            sx={{ marginLeft: 0.5 }}
          />
        )}
      </Box>
    </>
  );
}
