import { Loading } from "classes/Loading";

import { LineItemText } from "components/formFields/LineItemText";
import { LineSkeleton } from "components/formFields/LineSkeleton";
import { LineTableCell } from "components/formFields/LineTableCell";

import { DEFAULT_DECIMALS_UNROUNDED } from "appConstants";

export function LineOnHand(props) {
  const { value } = props;

  const isLoading = value instanceof Loading;

  return (
    <LineTableCell
      sx={{
        minWidth: "5.5rem",
        backgroundColor: isLoading ? "inherit" : "staticTableCell",
      }}
    >
      {isLoading ? (
        <LineSkeleton />
      ) : (
        <LineItemText
          decimal
          decimalPlaces={DEFAULT_DECIMALS_UNROUNDED}
          value={value}
          dataTesting="lineOnHand"
          sx={{ color: "staticTableCellText" }}
        />
      )}
    </LineTableCell>
  );
}
