import { purchasingTransactionBeforeSave } from "services/sosInventoryService/purchasingTransaction/beforeSave";

import { OBJECT_TYPES } from "appConstants";

const OBJECT_TYPE = OBJECT_TYPES.RETURN_TO_VENDOR.fullString;

export function beforeSave(record) {
  const newRecord = purchasingTransactionBeforeSave(OBJECT_TYPE, record);
  return newRecord;
}
