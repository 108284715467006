import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

export function FrmTax(props) {
  const { value, onValueChange, error, taxCodes, maxWidth } = props;

  return (
    <FrmSelectFromObjects
      name="taxCode"
      options={taxCodes?.length ? taxCodes : []}
      label={i18n("global.DefaultTax")}
      onValueChange={onValueChange}
      value={value}
      error={error}
      maxWidth={maxWidth}
    />
  );
}
