import { Box, Card, CardContent, Grid } from "@mui/material";

import { ActionButton } from "components/ActionButton";
import { CardGrid } from "components/CardGrid";
import { FrmTextField } from "components/formFields/FrmTextField";
import { CardHeader } from "components/formFields/formCards/CardHeader";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

export function Address(props) {
  const {
    address,
    onValueBlur,
    title,
    setToOtherAddress,
    addressType,
    ...unexpected
  } = props;
  checkForUnexpectedProps("Address", unexpected);

  function handleValueBlur(name, value) {
    return onValueBlur(addressType, { ...address, [name]: value });
  }

  return (
    <Card variant="outlined" data-testing={addressType + "Address"}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="start">
          <CardHeader text={title} />
          {setToOtherAddress && (
            <ActionButton
              sx={{ marginTop: "0.3em" }}
              headerButton
              onClick={setToOtherAddress}
              dataTesting="setToOtherAddress"
            >
              {i18n("customer.SameAsBilling")}
            </ActionButton>
          )}
        </Box>

        <CardGrid>
          {[1, 2, 3, 4, 5].map((i) => (
            <FrmTextField
              key={"line" + i}
              name={"line" + i}
              label={`Line ${i}`}
              maxLength={400}
              onValueBlur={handleValueBlur}
              value={address ? address["line" + i] : ""}
              dataTesting={"line" + i}
            />
          ))}
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6}>
              <FrmTextField
                name="city"
                label="City"
                maxLength={255}
                onValueBlur={handleValueBlur}
                value={address?.city}
              />
            </Grid>
            <Grid item xs={6}>
              <FrmTextField
                name="stateProvince"
                label="State/province"
                maxLength={255}
                onValueBlur={handleValueBlur}
                value={address?.stateProvince}
              />
            </Grid>
            <Grid item xs={6}>
              <FrmTextField
                name="postalCode"
                label="Postal code"
                maxLength={30}
                onValueBlur={handleValueBlur}
                value={address?.postalCode}
              />
            </Grid>
            <Grid item xs={6}>
              <FrmTextField
                name="country"
                label="Country"
                maxLength={255}
                onValueBlur={handleValueBlur}
                value={address?.country}
              />
            </Grid>
          </Grid>
        </CardGrid>
      </CardContent>
    </Card>
  );
}
