import { useMemo } from "react";

import { CustomField } from "components/CustomFields/CustomField";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { SectionHeading } from "components/SectionHeading";

import { i18n } from "services/i18nService";
import {
  reconcileCustomFields,
  getCustomFieldValue,
} from "services/utility/customFields";

export function CustomFields(props) {
  const { customFieldDefinitions, customFields, onValueChange, setErrors } =
    props;

  const reconciledCustomFields = useMemo(
    () => reconcileCustomFields(customFieldDefinitions, customFields),
    [customFieldDefinitions, customFields]
  );

  function handleValueChange(fieldName, value) {
    // get the right custom field; find it by custom field name
    const newCustomFields = [...customFields];
    const index = reconciledCustomFields.findIndex(
      ({ name }) => name === fieldName
    );
    const newCustomField = reconciledCustomFields[index];
    newCustomField.value = value;
    newCustomFields[index] = newCustomField;
    onValueChange("customFields", newCustomFields);
  }

  if (!customFieldDefinitions || customFields?.length === 0) {
    return null;
  }

  const customFieldArray = customFieldDefinitions.asArray;

  return (
    <>
      <SectionHeading>
        {customFieldArray.length > 1
          ? i18n("global.CustomFields")
          : i18n("global.CustomField")}
      </SectionHeading>

      <FrmFieldGrid gridAutoFlow="dense">
        {customFieldArray.map(({ name, dataType, listValues }) => (
          <CustomField
            key={name}
            name={name}
            dataType={dataType}
            onValueChange={(name, value) => handleValueChange(name, value)}
            value={getCustomFieldValue(reconciledCustomFields, name)}
            selectOptions={listValues?.split(",")}
            setErrors={setErrors}
          />
        ))}
      </FrmFieldGrid>
    </>
  );
}
