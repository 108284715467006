export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixBuild: record.buildNumber.prefix,
    nextBuildNumber: record.buildNumber.nextNumber,
  };

  delete newRecord.buildNumber;

  return newRecord;
}
