import { Box, Typography } from "@mui/material";

import { FrmTextField } from "components/formFields/FrmTextField";

import { i18n } from "services/i18nService";

export function FrmCustomerPortalSubdomain(props) {
  const { value, onValueChange, name, sx } = props;

  return (
    <Box sx={sx}>
      <FrmTextField
        name={name}
        value={value}
        onValueChange={onValueChange}
        maxLength={100}
      />
      <Typography variant="helperText" component="div">
        {i18n("settings.sales.customerPortal.portalDomain.addressLabel") + ":"}
      </Typography>
      <Typography variant="helperText" component="div">
        {value ? `https://${value}.orderfacts.com` : ""}
      </Typography>
    </Box>
  );
}
