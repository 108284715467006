import { enUS } from "date-fns/locale";

export const locales = { "en-US": enUS };
// arbitrary number to use for extracting decimal separator
// in a given locale
const NUM_IN_DEFAULT_LOCALE = 1.1;

export function getLocalization(settings) {
  const { localeCode, dateFormat, homeCurrency } = settings;
  const locale = locales[localeCode];

  // get decimal number decimal point character
  const decimalSeparator = Intl.NumberFormat(locale)
    .formatToParts(NUM_IN_DEFAULT_LOCALE)
    .find(({ type }) => type === "decimal").value;

  // get minimum decimal places for currency
  const currencyMinimumFractionDigits = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: homeCurrency.code,
  }).resolvedOptions().minimumFractionDigits;

  return {
    decimalSeparator,
    currencyMinimumFractionDigits,
    validNumber: new RegExp("^-?\\d*\\" + decimalSeparator + "?\\d*$"),
    dateFormat: dateFormat || locale.formatLong.date({ width: "short" }),
    homeCurrency: homeCurrency?.code,
  };
}
