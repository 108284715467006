import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
} from "@mui/material";

import { AddLines } from "components/EditPage/AddLines";
import { ClearLines } from "components/EditPage/ClearLines";
import { LineTableHeadingCell } from "components/formFields/LineTableCell";
import { LineTableFooterCell } from "components/formFields/LineTableCell";
import { LineDragInsertCopy } from "components/formFields/line/LineDragInsertCopy";
import { OcostAmountHeading } from "components/formFields/ocost/OcostAmount";
import { OcostBillHeading } from "components/formFields/ocost/OcostBill";
import { OcostClassHeading } from "components/formFields/ocost/OcostClass";
import { OcostInventoryItemHeading } from "components/formFields/ocost/OcostInventoryItem";
import { OcostVendorHeading } from "components/formFields/ocost/OcostVendor";
import { DragHandleHeading } from "components/utility/DragHandle";

import { i18n } from "services/i18nService";
import { setPageDirty } from "services/utility/edit";
import { textForAddLineOrLines } from "services/utility/lineItems";

import { OtherCost } from "views/ItemReceipts/ItemReceipt/ItemReceipt/OtherCost";

import { EMPTY_OTHER_COST } from "editConfig";

// created this wrapper to make drag and drop code easier to see
export function OtherCosts(props) {
  const {
    otherCosts,
    otherCostHandler,
    items,
    vendors,
    classes,
    recordVendorId,
    setClearOtherCosts,
    itemsCount,
    lineHandler,
    objectType,
    lineType,
  } = props;

  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  if (!otherCosts) {
    return null;
  }

  function handleDrop(result) {
    const { source, destination } = result;

    // if there's no destination, the item was dropped outside of the
    // droppable area
    if (!destination) {
      return;
    }

    // destination same as source, no-op
    if (destination.index === source.index) {
      return;
    }

    // make the move
    const newLines = [...otherCosts];
    const savedSource = newLines.splice(source.index, 1)[0];
    newLines.splice(destination.index, 0, savedSource);
    otherCostHandler({ type: "set", lines: newLines });
  }

  function addLines() {
    setPageDirty();
    lineHandler({
      type: "appendEmpty",
      numLinesToAdd,
      objectType,
      lineType,
    });
  }

  return (
    <>
      <TableContainer data-testing="lineItems" sx={{ overflowX: "initial" }}>
        <Table size="small" padding="none" stickyHeader>
          <TableHead>
            <TableRow sx={{ verticalAlign: "bottom", lineHeight: "1" }}>
              <DragHandleHeading />
              <ClearLines setClearLines={setClearOtherCosts} />
              <LineTableHeadingCell>
                {i18n("columns.Line")}
              </LineTableHeadingCell>
              <OcostInventoryItemHeading />
              <OcostAmountHeading />
              <OcostVendorHeading />
              <OcostBillHeading />
              <OcostClassHeading />
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="lineItems">
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {otherCosts.map((otherCost, index) => (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {(draggableProvided, snapshot) => {
                        return (
                          <TableRow
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            sx={{
                              ...draggableProvided.draggableProps.style,
                              backgroundColor: snapshot.isDragging
                                ? "dragBackground"
                                : "none",
                            }}
                            key={otherCost.lineNumber}
                          >
                            <LineDragInsertCopy
                              draggableProvided={draggableProvided}
                              snapshot={snapshot}
                              insertEmptyLine={() => {
                                setPageDirty();
                                otherCostHandler({
                                  type: "insert",
                                  insertAt: otherCost.lineNumber + 1,
                                  newLine: EMPTY_OTHER_COST.itemreceipt,
                                });
                              }}
                              lineNumber={otherCost.lineNumber}
                            />
                            <OtherCost
                              otherCost={otherCost}
                              otherCostHandler={otherCostHandler}
                              items={items}
                              itemsCount={itemsCount}
                              vendors={vendors}
                              classes={classes}
                              recordVendorId={recordVendorId}
                            />
                          </TableRow>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
          <TableFooter>
            <TableRow>
              <LineTableFooterCell sx={{ border: "none" }} />
              <AddLines
                title={textForAddLineOrLines(numLinesToAdd)}
                onClick={addLines}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
