import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { Money } from "classes/DecimalClasses";

import { FrmTextField } from "components/formFields/FrmTextField";
import { ButtonProgress } from "components/utility/ButtonProgress";

import { i18n } from "services/i18nService";
import { refundPayment } from "services/sosInventoryService/sosApi";

import { openAlert } from "globalState/alertSlice";

export function RefundDialog(props) {
  const { close, id, amount } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [refundAmount, setRefundAmount] = useState(amount);

  async function refund() {
    setIsLoading(true);
    const amount = refundAmount.toNumber();
    const { success, message } = await refundPayment([{ id, amount }]);
    if (success) {
      close();
      dispatch(
        openAlert({
          type: "success",
          message: i18n("alert.RefundPaymentSuccess"),
        })
      );
    } else {
      dispatch(openAlert({ type: "error", message }));
    }
    setIsLoading(false);
  }

  return (
    <Dialog open={true} onClose={close} maxWidth="xs" sx={{ inset: "0 0 40%" }}>
      <DialogTitle>{i18n("global.Refund")}</DialogTitle>
      <DialogContent>
        <Box width="20em" display="flex" alignItems="center">
          <FrmTextField
            money
            label={i18n("global.Amount")}
            onValueBlur={(_, value) => setRefundAmount(new Money(value))}
            value={refundAmount}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} color="secondary" onClick={close}>
          {i18n("button.Cancel")}
        </Button>
        <div style={{ position: "relative" }}>
          <Button color="secondary" disabled={isLoading} onClick={refund}>
            {i18n("global.Refund")}
          </Button>
          {isLoading && <ButtonProgress />}
        </div>
      </DialogActions>
    </Dialog>
  );
}
