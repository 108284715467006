import { useState, useEffect } from "react";

import { Print } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  TableCell,
} from "@mui/material";

import { LineTableCell } from "components/formFields/LineTableCell";
import { CloseX } from "components/utility/CloseX";
import { Loading } from "components/utility/Loading";
import { ModalTitle } from "components/utility/ModalTitle";

import { i18n } from "services/i18nService";
import { getBinContents } from "services/sosInventoryService/sosApi";

import { theme } from "SosTheme";

const iconSx = {
  padding: "0.4rem",
  height: "36px",
  border: "1px solid",
  borderColor: "lineSeparatorMinor",
  backgroundColor: "white",
  displayPrint: "none",
};

export function BinContentsDialog(props) {
  const { id, onClose, binNumber } = props;

  const [contents, setContents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //   const dispatch = useDispatch();

  useEffect(() => {
    async function _getBinContents(id) {
      setIsLoading(true);
      const response = await getBinContents(id);
      setIsLoading(false);
      setContents(response);
    }
    _getBinContents(id);
  }, [id]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      sx={{ inset: "0 0 30%" }}
    >
      <DialogTitle
        sx={{
          backgroundColor: "dialogTitleBackground",
          borderBottom: "1px solid",
          borderBottomColor: "lineSeparatorMinor",
          padding: "0.5rem 1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <CloseX onClick={onClose} />
            <ModalTitle label={i18n("global.Contents")} text={binNumber} />
          </div>
          <IconButton
            onClick={() => window.print()}
            sx={iconSx}
            style={{ borderRadius: theme.shape.borderRadius }}
            size="large"
          >
            <Print />
          </IconButton>
        </div>
      </DialogTitle>
      {isLoading && <Loading />}
      {contents && (
        <DialogContent>
          <Table sx={{ mt: "0.5rem" }} size="small" padding="none" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ p: "0.3rem" }}>
                  {i18n("columns.Item")}
                </TableCell>
                <TableCell sx={{ p: "0.3rem" }} align="right">
                  {i18n("columns.Quantity")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contents.map(({ name, quantity }) => (
                <TableRow sx={{ position: "inherit" }}>
                  <LineTableCell>
                    <Typography name={name} sx={{ p: "0.3rem" }}>
                      {name}
                    </Typography>
                  </LineTableCell>
                  <LineTableCell>
                    <Typography name={name} align="right" sx={{ p: "0.3rem" }}>
                      {quantity}
                    </Typography>
                  </LineTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      )}
    </Dialog>
  );
}
