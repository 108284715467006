import { REFERENCE_FIELDS, REFERENCE_ENTITY_MAP } from "appConfig";
import { IN_TRANSACTION } from "appConfig";

import { getRecord } from "services/sosInventoryService/sosApi";
import { getExchangeRate } from "services/sosInventoryService/sosApi";

import { dirtyPageOn, dirtyPageOff } from "globalState/dirtyPageSlice";
import globalState from "globalState/globalState";

// get related records; need to ignore "payment" here, as it's not
// really a reference field; this could maybe be cleaned up by defining
// a new type of field in schema.js...?
export async function getRelatedReferenceObjects(objectType, record) {
  const fieldsWithRecordValues = REFERENCE_FIELDS[objectType].filter(
    (field) => record[field]
  );
  const records = await Promise.all(
    fieldsWithRecordValues.map(async (field) => {
      if (record[field]) {
        return await getRecord(
          REFERENCE_ENTITY_MAP[field] ? REFERENCE_ENTITY_MAP[field] : field,
          record[field].id,
          IN_TRANSACTION
        );
      }
    })
  );
  return fieldsWithRecordValues.reduce((acc, field, i) => {
    acc[field] = records[i];
    return acc;
  }, {});
}

// sets the global "dirty page" flag: true = dirty, false = not dirty;
// can/should be called from any user input event handler that changes
// the data in an input field; note specifically that we do *not* set
// the dirty page on blur, as this causes issues when just tabbing through
// fields, or on exit when nothing has been changed by the user
export function setPageDirty(dirtyNotDirty = true) {
  globalState.dispatch(dirtyNotDirty ? dirtyPageOn() : dirtyPageOff());
}

export async function handleRefreshExchangeRate(
  currencies,
  record,
  handleInputFieldChange
) {
  const currencyRecord = currencies.find(
    ({ id }) => id === record?.currency.id
  );
  const response = await getExchangeRate(currencyRecord.code, record?.date);
  const newExchangeRate = response.exchangeRate;
  handleInputFieldChange("exchangeRate", newExchangeRate);
}

// this insures that the items array includes the value passed; it is used
// to populate select field options, to be sure that there isn't a case
// where the option list doesn't include the current value of the field
export function itemsIncludingValue(items, value) {
  let newItems = items ? [...items] : [];
  if (value) {
    const { id, name } = value;
    const foundItem = items?.find(({ id }) => id === value.id);
    if (!foundItem) {
      newItems.unshift({ id, name });
    }
  }
  return newItems;
}
