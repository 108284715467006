import { configureStore } from "@reduxjs/toolkit";

import { setItemInStorage } from "services/localStorageManager";

import alertReducer from "globalState/alertSlice";
import authenticationReducer from "globalState/authenticationSlice";
import bearerTokenReducer from "globalState/bearerTokenSlice";
import dirtyPageReducer from "globalState/dirtyPageSlice";
import globalDialogReducer from "globalState/globalDialogSlice";
import leftNavReducer from "globalState/leftNavSlice";
import loadingReducer from "globalState/loadingSlice";
import modalReducer from "globalState/modalSlice";
import notificationsReducer from "globalState/notificationsSlice";
import recordsChangedReducer from "globalState/recordsChangedSlice";
import settingsModeReducer from "globalState/settingsModeSlice";
import userCompanySettingsReducer from "globalState/userCompanySettingsSlice";

// middleware to persist certain parts of the state to browser storage
const persistUserCompanySettings = (store) => (next) => (action) => {
  if (action.type.slice(0, 19) === "userCompanySettings") {
    const result = next(action);
    const stringifiedState = JSON.stringify(
      store.getState().userCompanySettings
    );
    // see https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_1_%E2%80%93_escaping_the_string_before_encoding_it
    const encodedState = window.btoa(encodeURIComponent(stringifiedState));
    setItemInStorage("userCompanySettings", encodedState);
    return result;
  } else {
    return next(action);
  }
};

// middlewhere to set window.onbeforeunload when dirtyPage is set, so
// that events outside of our app (like browser back error) are captured
// and the user is warned
const setGlobalDirtyPageFunction = (store) => (next) => (action) => {
  if (action.type.slice(0, 9) === "dirtyPage") {
    const result = next(action);
    if (action.type.slice(10) === "dirtyPageOn") {
      window.onbeforeunload = () => true;
    }
    if (action.type.slice(10) === "dirtyPageOff") {
      window.onbeforeunload = undefined;
    }
    return result;
  } else {
    return next(action);
  }
};

export default configureStore({
  reducer: {
    alert: alertReducer,
    authentication: authenticationReducer,
    bearerToken: bearerTokenReducer,
    dirtyPage: dirtyPageReducer,
    globalDialog: globalDialogReducer,
    leftNav: leftNavReducer,
    loading: loadingReducer,
    modal: modalReducer,
    notifications: notificationsReducer,
    recordsChanged: recordsChangedReducer,
    settingsMode: settingsModeReducer,
    userCompanySettings: userCompanySettingsReducer,
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }),
    persistUserCompanySettings,
    setGlobalDirtyPageFunction,
  ],
});
