export const deData = `
[global]
Dashboard = Instrumententafel
SalesOrder = Kundenauftrag
Item = Artikel
Calendar = Kalender
Purchasing = Einkauf
Production = Produktion
Inventory = Inventar
Sales = Der Umsatz
Fulfillment = Erfüllung

[leftNav]
MoreTransactions = Weitere Transaktionen
Reports = Berichte
QuickLinks = Schnellzugriff
`.trim();
