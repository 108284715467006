import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
  name: "loading",
  initialState: { list: false, editModal: false },
  reducers: {
    loadingIndicatorOn: (state) => ({ ...state, list: true }),
    loadingIndicatorOff: (state) => ({ ...state, list: false }),
    editModalLoadingIndicatorOn: (state) => ({ ...state, editModal: true }),
    editModalLoadingIndicatorOff: (state) => ({ ...state, editModal: false }),
  },
});

export const {
  loadingIndicatorOn,
  loadingIndicatorOff,
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} = loadingSlice.actions;
export default loadingSlice.reducer;
