import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { Reconnecting } from "views/Reconnecting";

export const ExpiredDisabledRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.authentication);

  if (isLoggedIn === "yes") {
    return <Outlet />;
  } else if (isLoggedIn === "no") {
    sessionStorage.setItem(
      "redirectPath",
      window.location.pathname + window.location.search
    );
    return <Navigate to="/login" />;
  } else {
    return <Reconnecting />;
  }
};
