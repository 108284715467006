import { useState, useEffect } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TextField } from "@mui/material";

import {
  outlinedStyles,
  inputPropStyles,
} from "components/formFields/lineItemFieldStyle";

import { checkForUnexpectedProps } from "services/utility/misc";
import { isZero } from "services/utility/misc";

export function LineItemTextField(props) {
  const {
    id,
    name,
    disabled,
    select,
    multiline,
    maxLength,
    required,
    onValueChange,
    onClick,
    value: initialValue,
    children,
    metadata,
    number,
    money,
    width,
    pairedField,
    endAdornment,
    cursorPointer,
    error,
    resize,
    maxRows,
    readOnly,
    dataTesting,
    ...unexpected
  } = props;
  checkForUnexpectedProps("LineItemTextField", unexpected);

  const [focused, setFocused] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [value, setValue] = useState(metadata.editStr(metadata, initialValue));

  let borderRadius = "6px";
  if (pairedField) {
    borderRadius = pairedField === "left" ? "6px 0 0 6px" : "0 6px 6px 0";
  }

  const overflowHandling = multiline
    ? {}
    : {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      };

  const sx = {
    width,
    fontWeight: "fontWeightRegular",
    marginTop: 0,
    marginBottom: 0,
    cursor: cursorPointer ? "pointer" : "inherit",
  };

  useEffect(() => {
    setValue(metadata.editStr(metadata, initialValue));
  }, [initialValue, name, metadata]);

  function onChange(e) {
    setUpdated(true);
    if (maxLength && e.target.value.length > maxLength) {
      return;
    }

    setValue(e.target.value);
    if (select) {
      onValueChange(name, getValue());
    }
  }

  function onBlur() {
    setFocused(false);
    if (!readOnly && updated) {
      onValueChange(name, getValue());
    }
  }

  function getValue() {
    //added to update the input on focus to not show the default zero value
    if (focused && isZero(value) && !updated) {
      return "";
    }
    return value;
  }

  return (
    <TextField
      readOnly={readOnly}
      autoComplete="off"
      value={getValue()}
      variant="outlined"
      onFocus={() => setFocused(true)}
      id={id}
      name={name}
      disabled={disabled}
      select={select}
      multiline={multiline}
      maxRows={maxRows}
      onChange={onChange}
      onClick={onClick}
      onBlur={onBlur}
      required={required}
      fullWidth={width ? false : true}
      error={error}
      data-testing={dataTesting}
      inputProps={{
        readOnly,
        style: {
          ...overflowHandling,
          textAlign: number || money ? "right" : "left",
          cursor: cursorPointer ? "pointer" : "inherit",
          resize: resize ? "both" : "none",
          minWidth: resize ? "90%" : "inherit",
        },
      }}
      sx={error ? sx : { ...sx, ...outlinedStyles }}
      InputProps={{
        endAdornment,
        sx: inputPropStyles,
        style: { borderRadius, cursor: cursorPointer ? "pointer" : "inherit" },
      }}
      InputLabelProps={{ shrink: true }}
      SelectProps={{ IconComponent: KeyboardArrowDownIcon }}
      type={money || number ? "number" : "text"}
    >
      {children}
    </TextField>
  );
}
