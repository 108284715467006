import { useEffect, useState } from "react";

import { getLotsByItemLocationBinDate } from "services/sosInventoryService/sosApi";

/**
 * @name    useItemLots
 *
 * @summary returns an array of lots, optionally filtered by item, location,
 *          and bin
 *
 * @param   itemId (number)
 *          locationName (string) name property of a location reference
 *            object
 *          dateTime (date) date/time at which onhand quantities should be
 *            calculated
 *          lotTracking (boolean) is lot tracking enabled for this item?
 *          binId (number, optional)
 *
 * @returns array of lots; while waiting for API response, returns null
 */
export function useItemLots(itemId, locationName, binId, date, lotTracking) {
  const [lots, setLots] = useState(null);

  useEffect(() => {
    async function _getLots(itemId, locationName, binId, date) {
      const lots = await getLotsByItemLocationBinDate(
        itemId,
        locationName,
        binId,
        date
      );

      setLots(lots);
    }

    if (Boolean(itemId && locationName && date && lotTracking)) {
      setLots(null);
      _getLots(itemId, locationName, binId, date);
    } else {
      setLots([]);
    }
  }, [itemId, locationName, binId, date, lotTracking]);

  return lots;
}
