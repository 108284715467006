import { Card, CardContent } from "@mui/material";

import { CardHeader } from "components/formFields/formCards/CardHeader";

import { i18n } from "services/i18nService";
import { formatCompanyInfo } from "services/utility/formatting";
import { isAddressEmpty } from "services/utility/misc";

export function Location(props) {
  const { address, label } = props;
  return (
    <Card variant="outlined" sx={{ width: "20rem" }}>
      <CardHeader
        text={label}
        sx={{ marginTop: "0.5rem", marginLeft: 1.6 }}
        gutterBottom={false}
      />
      <CardContent sx={{ padding: "0 1rem" }}>
        <div
          style={{
            whiteSpace: "pre-line",
            lineHeight: "1rem",
            overflow: "auto",
            overflowWrap: "break-word",
          }}
        >
          {isAddressEmpty(address) ? (
            i18n("global.NoAddressEntered")
          ) : (
            <div>{formatCompanyInfo(address, true)}</div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
