import { Tooltip, Box } from "@mui/material";

import { TooltipIcon } from "components/icons/TooltipIcon";

import { theme } from "SosTheme";

export const sx = {
  "& .MuiTooltip-tooltip": {
    backgroundColor: "tooltipBackground",
    color: theme.palette.disabled,
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(14),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.3),
    paddingBottom: theme.spacing(1.3),
  },
};

export function HoverHelp(props) {
  const { title, sx: externalSx, color = "primaryText" } = props;

  return (
    <Tooltip title={title} sx={sx}>
      <Box
        sx={{
          ...externalSx,
          lineHeight: 0,
          position: "relative",
          display: "inline",
        }}
      >
        <TooltipIcon color={color} />
      </Box>
    </Tooltip>
  );
}
