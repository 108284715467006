import { RowSelectBase } from "components/formFields/row/RowSelectBase";

import { checkForUnexpectedProps } from "services/utility/misc";

import PropTypes from "prop-types";

export function RowSelectScalar(props) {
  const {
    name,
    options,
    dataIndex,
    onValueChange,
    value,
    label,
    disableClearable,
    error,
    ...unexpected
  } = props;
  checkForUnexpectedProps("RowSelectScalar", unexpected);

  // this gets called whenever the user changes the selected option
  function handleChange(_, newValue, reason) {
    const value = reason === "clear" ? null : newValue;
    onValueChange(name, value, dataIndex);
  }

  // this gets called for each option, when the selected option changes, to
  // determine which, of all the options, was selected
  function isOptionEqualToValue(option, value) {
    if (!option || !value) {
      return false;
    }
    return value === option || (value === "" && option === "");
  }

  function renderOption(props, option) {
    return <li {...props}>{option}</li>;
  }

  return (
    <RowSelectBase
      renderOption={renderOption}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={(option) => option}
      onValueChange={handleChange}
      options={options}
      disableClearable={disableClearable}
      value={value}
      label={label}
      error={error}
    />
  );
}

RowSelectScalar.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  onValueChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  disableClearable: PropTypes.bool,
  error: PropTypes.bool,
};
