import { i18n } from "services/i18nService";

import {
  SALES_GROUP_BY_OPTIONS,
  ALL_OPTION_WITH_ALL_ID,
  NONE_GROUP_BY_OPTION,
  SALES_ORDER_TYPE_OPTIONS,
  SALES_ORDER_ORDER_TYPE_OPTION,
  SUB_COMPONENT,
} from "views/Reports/reportConstants";
import { OPEN_CLOSED_OPTIONS } from "views/Reports/reportConstants";

import { ALL_FILTER_OPTION, OBJECT_TYPES } from "appConstants";

export const MasterSalesReport = {
  userHasSufficientPrivilege: (p) =>
    (p.viewEstimates || p.editEstimates) &&
    (p.viewInvoices || p.editInvoices) &&
    (p.ViewSalesOrders || p.editSalesOrders) &&
    (p.ViewSalesReceipts || p.editSalesReceipts),
  planIncludesReport: () => true,
  customReportNameFetchList: [
    { objectType: OBJECT_TYPES.ITEM.fullString, customReportKey: "categories" },
    {
      objectType: OBJECT_TYPES.CUSTOMER_TYPE.fullString,
      customReportKey: "customerType",
    },
    {
      objectType: OBJECT_TYPES.SALES_REP.fullString,
      customReportKey: "salesReps",
    },
    {
      objectType: OBJECT_TYPES.LOCATION.fullString,
      customReportKey: "locations",
    },
    { objectType: OBJECT_TYPES.CHANNEL.fullString },
    { objectType: OBJECT_TYPES.CLASS.fullString },
    { objectType: OBJECT_TYPES.CUSTOMER.fullString },
    { objectType: OBJECT_TYPES.DEPARTMENT.fullString },
    { objectType: OBJECT_TYPES.ITEM.fullString },
    { objectType: OBJECT_TYPES.SALES_REP.fullString },
    { objectType: OBJECT_TYPES.VENDOR.fullString },
  ],
  requiredFiltersNotSetMsg: i18n("reports.OrderTypeFilterRequired"),
  customFieldObjectTypes: [
    "estimate",
    "invoice",
    "salesreceipt",
    "salesorder",
    "item",
  ],
  columns: [
    { apiName: "OrderNumber", label: "Order #", default: true },
    { apiName: "Order Date", default: true },
    { apiName: "Txn Type", default: true },
    { apiName: "Customer", default: true },
    { apiName: "Item", default: true },
    { apiName: "Location" },
    { apiName: "Description", default: true },
    { apiName: "Ordered", default: true, number: true },
    { apiName: "Picked", number: true },
    { apiName: "Shipped", number: true },
    { apiName: "Invoiced", number: true },
    { apiName: "Backordered", number: true },
    { apiName: "UOM" },
    { apiName: "Unit Price", number: true },
    { apiName: "Open Amt", number: true },
    { apiName: "Total Amt", number: true },
    { apiName: "Due Date" },
    { apiName: "Sales Rep" },
    { apiName: "Channel" },
    { apiName: "Category" },
    { apiName: "Class" },
    { apiName: "Customer Type" },
    { apiName: "Department" },
    { apiName: "Comment" },
    { apiName: "Priority" },
    { apiName: "Stage" },
    { apiName: "Customer PO" },
    { apiName: "On Hand", number: true },
    { apiName: "Available", number: true },
    { apiName: "COGS" },
    { apiName: "Profit", number: true },
    { apiName: "SKU" },
    { apiName: "Item Barcode" },
    { apiName: "CustomerPartNumber", label: "Customer Part #" },
    { apiName: "List Price", number: true },
    { apiName: "Vendor" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
    { apiName: "Landed Amt", alwaysShow: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      defaultValue: () => new Date(),
      datePrefix: "startOf",
      type: "filter",
    },
    {
      apiName: "date1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.OrderType"),
      component: "ReportMultiSelect",
      apiName: "users",
      defaultValue: [SALES_ORDER_ORDER_TYPE_OPTION],
      options: SALES_ORDER_TYPE_OPTIONS,
      type: "filter",
      required: true,
    },
    {
      label: i18n("filter.Location"),
      component: "LocationsFilter",
      apiName: "locations",
      defaultValue: null,
      placeholder: i18n("reports.placeholder.DefaultIsAll", {
        type: i18n("reports.locations"),
      }),
      type: "filter",
    },
    {
      label: i18n("filter.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Item"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Category"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Channel"),
      component: "ChannelFilter",
      apiName: "channel",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Class"),
      component: "ClassFilter",
      apiName: "class",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.CustomerType"),
      component: "CustomerTypeFilter",
      apiName: "customerType",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Department"),
      component: "DepartmentFilter",
      apiName: "department",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.SalesRep"),
      component: "SalesRepFilter",
      apiName: "salesReps",
      defaultValue: [ALL_FILTER_OPTION],
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains2",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.KeywordSearch"),
      component: "FrmTextFieldWithHoverHelp",
      apiName: "nameContains",
      defaultValue: null,
      helpText: i18n("reports.hoverHelp.SalesKeywordSearch"),
      type: "filter",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "orderType",
      label: i18n("reports.OptionLabel.OpenClosed"),
      component: "FrmSelectFromObjects",
      options: OPEN_CLOSED_OPTIONS,
      defaultValue: ALL_OPTION_WITH_ALL_ID,
      type: "option",
    },
    {
      apiName: "groupBy",
      defaultValue: NONE_GROUP_BY_OPTION,
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmSelectFromObjects",
      options: SALES_GROUP_BY_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
