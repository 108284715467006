import { FilterSelectField } from "components/formFields/FilterSelectField";

import { i18n } from "services/i18nService";

import { SYNC_ITEM_OBJECT_TYPES } from "appConstants";

export function EditSyncObjectFilter(props) {
  const { filters, setFilters } = props;
  const { filterSettings } = filters;
  const { Type = "" } = filterSettings;

  function handleChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        Type: value?.id || "",
      },
      startingRecord: 1,
    }));
  }
  return (
    <FilterSelectField
      options={SYNC_ITEM_OBJECT_TYPES}
      onValueChange={handleChange}
      value={{ id: Type }}
      label={i18n("filter.SyncObject")}
      disableClearable
    />
  );
}
