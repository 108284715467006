import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  getRecord,
  getAllReference,
} from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

export function useBins(location) {
  const [bins, setBins] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location) {
      async function getBinInfo() {
        let locationInfo;
        try {
          locationInfo = await getRecord("location", location.id);
          const availableBins = locationInfo.bins;
          setBins(availableBins);
        } catch (e) {
          handleProgramError(e);
        }
      }
      getBinInfo();
    } else {
      async function getAllBins() {
        const bins = await getAllReference("bins");
        const structuredBins = bins.map((bin) => ({
          id: bin.id,
          number: bin.number,
          location: bin.location,
        }));
        setBins(structuredBins);
      }
      getAllBins();
    }
  }, [location, dispatch]);

  return bins;
}
