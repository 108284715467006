import { DragIndicatorRounded } from "@mui/icons-material";

import { LineTableHeadingCell } from "components/formFields/LineTableCell";

import { theme } from "SosTheme";

export function DragHandleHeading() {
  return (
    <LineTableHeadingCell>
      <div
        style={{
          position: "absolute",
          right: "100%",
          width: "1.3em",
          zIndex: theme.zIndex.stickyHeader,
          padding: "2.5rem 0.75rem 0",
          marginBottom: "3rem",
          backgroundColor: theme.palette.componentBackground,
        }}
      ></div>
    </LineTableHeadingCell>
  );
}
// width and height are in units of "dots"
export function DragHandle() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0 0.5rem",
      }}
    >
      <DragIndicatorRounded />
    </div>
  );
}
