import SvgIcon from "@mui/material/SvgIcon";

import { theme } from "SosTheme";

export function RentalIcon(props) {
  const { highlighted, ...otherProps } = props;
  const color = highlighted ? theme.palette.accent : theme.palette.primaryText;

  return (
    <SvgIcon viewBox="0 0 32 32"  fill={color} {...otherProps}>
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g fillRule="nonzero">      
          <path className="cls-1" d="m20.25,17.57c-.08,0-.16-.02-.24-.07-.16-.1-.26-.29-.26-.5v-4.5H.48c-.28,0-.5-.26-.5-.57v-6.27c0-.32.22-.57.5-.57h19.27V.57c0-.21.1-.4.26-.5.16-.1.36-.09.51.03l10.79,8.21c.14.11.22.28.22.48s-.08.37-.22.48l-10.79,8.21c-.08.06-.18.1-.28.1ZM.98,11.35h19.27c.28,0,.5.26.5.57v4.01l9.38-7.14L20.75,1.64v4.01c0,.32-.22.57-.5.57H.98v5.13Z"/>
          <rect className="cls-1" x=".48" y="3.09" width="1.62" height="1.14"/>
          <rect className="cls-1" x="4.02" y="3.09" width="5.98" height="1.14"/>
          <path className="cls-1" d="m11.79,32c-.1,0-.19-.03-.28-.1L.73,23.69c-.14-.11-.22-.28-.22-.48s.08-.37.22-.48l10.79-8.21c.15-.12.35-.13.51-.03.16.1.26.29.26.5v4.5h19.27c.28,0,.5.26.5.57v6.27c0,.32-.22.57-.5.57H12.29v4.5c0,.21-.1.4-.26.5-.07.05-.15.07-.24.07ZM1.91,23.22l9.38,7.14v-4.01c0-.32.22-.57.5-.57h19.27v-5.13H11.79c-.28,0-.5-.26-.5-.57v-4.01L1.91,23.22Z"/>
          <rect className="cls-1" x="29.94" y="27.77" width="1.62" height="1.14"/>
          <rect className="cls-1" x="22" y="27.77" width="6.02" height="1.14"/>
        </g>
      </g>
    </SvgIcon>
  );
}
