import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import {
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";

import { ClearLines } from "components/EditPage/ClearLines";
import { LineTableHeadingCell } from "components/formFields/LineTableCell";
import { LineColumnFillHeading } from "components/formFields/line/LineColumnFillHeading";
import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";
import { LineCostAdjustmentHeading } from "components/formFields/line/LineCostAdjustment";
import { LineDragInsertCopy } from "components/formFields/line/LineDragInsertCopy";
import { LineInventoryItemHeading } from "components/formFields/line/LineInventoryItem";
import { LineItemFooter } from "components/formFields/line/LineItemFooter";
import { DragHandleHeading } from "components/utility/DragHandle";

import { i18n } from "services/i18nService";
import { expandSalesItemGroup } from "services/sosInventoryService/domainLogic";
import { setPageDirty } from "services/utility/edit";
import { handleDrop, copyDown } from "services/utility/lineItems";

import { useJobWorkcenters } from "hooks/useJobWorkcenters";
import { useRelated } from "hooks/useRelated";

import { LineItem } from "views/Adjustments/Adjustment/LineItem";

import { EMPTY_LINE_ITEM } from "editConfig";

export function LineItems(props) {
  const {
    record,
    objectType,
    lines,
    items,
    totals,
    relatedRecords,
    itemsCount,
    addItem,
    lineHandler,
    setClearLines,
    lineItemsErrors,
    setErrors,
  } = props;

  const classes = useRelated("class");
  const jobWorkcenters = useJobWorkcenters();

  // SETTINGS
  const classEnabled = useSelector(
    (state) => state.userCompanySettings.settings.classEnabled
  );
  const jobEnabled = useSelector(
    (state) => state.userCompanySettings.settings.jobEnabled
  );
  const uomEnabled = useSelector(
    (state) => state.userCompanySettings.settings.uomEnabled
  );

  function insertEmptyLine(insertAt) {
    setPageDirty();
    lineHandler({
      type: "insert",
      insertAt: insertAt,
      newLine: EMPTY_LINE_ITEM[objectType],
    });
  }

  if (!lines) {
    return null;
  }

  return (
    <>
      <TableContainer data-testing="lineItems" sx={{ overflowX: "initial" }}>
        <Table size="small" padding="none" stickyHeader>
          <TableHead>
            <TableRow sx={{ verticalAlign: "bottom", lineHeight: "1" }}>
              <DragHandleHeading />
              <ClearLines setClearLines={setClearLines} />
              <LineTableHeadingCell>
                {i18n("columns.Line")}
              </LineTableHeadingCell>
              <LineInventoryItemHeading lineHandler={lineHandler} />
              <LineColumnHeading labelCode="Description" />
              <LineColumnHeading labelCode="OldQuantity" align="right" />
              <LineColumnHeading labelCode="NewQuantity" />
              <LineColumnHeading labelCode="AdjustQuantityBy" />
              {uomEnabled && <LineColumnHeading labelCode="UOM" />}
              <LineCostAdjustmentHeading
                lines={lines}
                date={record.date}
                lineHandler={lineHandler}
              />
              {classEnabled && (
                <LineColumnFillHeading
                  labelCode="Class"
                  field="class"
                  lineHandler={lineHandler}
                />
              )}
              {jobEnabled && (
                <LineColumnFillHeading
                  labelCode="Job"
                  field="jobWorkcenter"
                  lineHandler={lineHandler}
                />
              )}
            </TableRow>
          </TableHead>
          <DragDropContext
            onDragEnd={(result) => handleDrop(result, lineHandler, lines)}
          >
            <Droppable droppableId="lineItems">
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {lines.map((line, index) => (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {(draggableProvided, snapshot) => {
                        return (
                          <TableRow
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            sx={{
                              ...draggableProvided.draggableProps.style,
                              position: "inherit",
                              backgroundColor: snapshot.isDragging
                                ? "dragBackground"
                                : "none",
                            }}
                            data-testing={"line-" + index}
                          >
                            <LineDragInsertCopy
                              draggableProvided={draggableProvided}
                              snapshot={snapshot}
                              insertEmptyLine={insertEmptyLine}
                              lineNumber={line.lineNumber}
                              onCopyDown={() =>
                                copyDown(line, lineHandler, record, objectType)
                              }
                              showCopyDown={
                                line.lineNumber === lines.length ? false : true
                              }
                            />

                            <LineItem
                              objectType={objectType}
                              record={record}
                              classes={classes}
                              relatedRecords={relatedRecords}
                              line={line}
                              lineHandler={lineHandler}
                              expandItemGroup={(lineToExpand) =>
                                expandSalesItemGroup(
                                  lineToExpand,
                                  lineHandler,
                                  objectType
                                )
                              }
                              items={items}
                              itemsCount={itemsCount}
                              addItem={addItem}
                              jobWorkcenters={jobWorkcenters}
                              lineItemsErrors={lineItemsErrors}
                              setErrors={setErrors}
                              draggableProvided={draggableProvided}
                              draggableSnapshot={snapshot}
                            />
                          </TableRow>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
          <LineItemFooter
            firstCellSpan={6}
            totals={totals}
            quantityString="quantityDiff"
            lineHandler={lineHandler}
            objectType={objectType}
          />
        </Table>
      </TableContainer>
    </>
  );
}
