// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import {
  extractName,
  formatMoneyWithAdornments,
  extractWarranty,
  extractWarrantyStart,
  extractWarrantyEnd,
  formatDocumentInfo,
  formatNumberLinkField,
  truncate,
} from "services/utility/formatting";
import { starColumn } from "services/utility/schema";

import { USER_PRIVILEGE } from "appConstants";

export const columnInfo = [
  starColumn,
  {
    name: "number",
    fixed: true,
    heading: i18n("columns.SerialNumber"),
    formatFunc: formatNumberLinkField,
    sortDirection: "asc",
    minWidth: "7em",
    linkField: true,
    sortable: true,
  },
  {
    name: "item",
    heading: i18n("columns.Item"),
    formatFunc: extractName,
    defaultSort: true,
    sortDirection: "asc",
    sortable: true,
    default: true,
  },
  {
    name: "location",
    heading: i18n("columns.Location"),
    formatFunc: extractName,
    sortable: true,
    default: true,
  },
  {
    name: "description",
    heading: i18n("columns.Description"),
    minWidth: "15em",
    formatFunc: truncate,
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "cost",
    heading: i18n("columns.Cost"),
    formatFunc: formatMoneyWithAdornments,
    align: "right",
    privilegeRestriction: [USER_PRIVILEGE.editItems, USER_PRIVILEGE.viewCosts],
  },
  {
    name: "net",
    heading: i18n("columns.Net"),
    formatFunc: formatMoneyWithAdornments,
    align: "right",
    privilegeRestriction: [USER_PRIVILEGE.editItems, USER_PRIVILEGE.viewCosts],
  },
  {
    name: "customer",
    heading: i18n("columns.Customer"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "25em",
    sortable: true,
  },
  {
    name: "status",
    heading: i18n("columns.Status"),
    sortable: true,
  },
  {
    name: "warranty",
    heading: i18n("columns.Warranty"),
    formatFunc: extractWarranty,
    sortable: true,
  },
  {
    name: "WarrantyStart",
    fieldName: "warranty",
    heading: i18n("columns.WarrantyStart"),
    formatFunc: extractWarrantyStart,
    sortable: true,
  },
  {
    name: "WarrantyEnd",
    fieldName: "warranty",
    heading: i18n("columns.WarrantyEnd"),
    formatFunc: extractWarrantyEnd,
    sortable: true,
  },
];

export function getEmptyRecord() {
  return {
    syncToken: 0,
    starred: 0,
    item: null,
    location: null,
    customer: null,
    warranty: { start: null, end: null, warranty: null },
    description: null,
    status: null,
    cost: new Money(0),
    net: new Money(0),
    hasImage: false,
    summaryOnly: false,
    imageAsBase64String: null,
    imageChanged: false,
    documents: [],
    customFields: null,
    keys: null,
    values: null,
  };
}

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: ["cost", "net"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const LINE_ACTIONS = ["pdf", "delete", "history"];
export const BATCH_ACTIONS = ["pdf", "delete"];
