import { afterGet as globalAfterGet } from "services/sosInventoryService/afterGet";
import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/process/schema";
import { productionTransactionAfterGet } from "services/sosInventoryService/productionTransaction/afterGet";
import {
  transformFields,
  transformLineItemFields,
  transformSerial,
} from "services/utility/afterGet";

import { OBJECT_TYPES } from "appConstants";
import { OUTPUT_LINE_ITEM_FIELDS, INPUT_LINE_ITEM_FIELDS } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.PROCESS.fullString;

export function afterGet(record) {
  let newRecord = transformFields(record, FIELDS_TO_TRANSFORM);

  // TODO: Update logic to not include ternary once inputs
  // value corrected in populateFromworkorderLine EP
  newRecord.inputs = newRecord.inputs
    ? transformLineItemFields(
        newRecord.inputs,
        INPUT_LINE_ITEM_FIELDS[OBJECT_TYPE]
      )
    : [];

  newRecord.outputs = transformLineItemFields(
    newRecord.outputs,
    OUTPUT_LINE_ITEM_FIELDS[OBJECT_TYPE]
  );

  // transform the output lot and serial
  newRecord.outputs = newRecord.outputs.map((output) =>
    transformSerial(output, newRecord.autoSerialLots)
  );

  newRecord = globalAfterGet(newRecord);
  return productionTransactionAfterGet(newRecord);
}
