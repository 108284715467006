import { useSelector } from "react-redux";

import { FilterSelectField } from "components/formFields/FilterSelectField";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function EditCustomerFilter(props) {
  const { filters, setFilters } = props;
  const { filterSettings } = filters;
  const { Customer } = filterSettings;

  const retrievedCustomers = useRelated("customer");

  const findCustomerByFullName = useSelector(
    (state) => state.userCompanySettings.settings.findCustomerByFullName
  );
  const optionDisplayText = findCustomerByFullName ? "fullname" : "name";

  const allOption = { id: 0, [optionDisplayText]: i18n("filter.All") };

  function handleChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        Customer: value?.id || "",
      },
      startingRecord: 1,
    }));
  }

  const customers = retrievedCustomers?.map((customer) => ({
    id: customer.id.toString(),
    [optionDisplayText]: customer[optionDisplayText],
  }));

  const options = customers ? [allOption, ...customers] : [];

  return (
    <FilterSelectField
      options={options}
      optionDisplayText={optionDisplayText}
      onValueChange={handleChange}
      value={Customer ? { id: Customer } : allOption}
      label={i18n("filter.Customer")}
      dataTesting="customerFilter"
      disableClearable
    />
  );
}
