// see views/common.js for error object structure
import { i18n } from "services/i18nService";
import { purchasingTransactionIsValid } from "services/sosInventoryService/purchasingTransaction/isValid";

export function isValid(record, relatedRecords, lines) {
  const errors = purchasingTransactionIsValid(record, relatedRecords, lines);

  if (record.dropShip && !record.customer?.id) {
    errors.messages.push(i18n("purchaseOrder.Error.Customer"));
    errors.entity.push("customer");
  }

  return errors;
}
