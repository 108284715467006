import { LineItemNumericCalculatorField } from "components/formFields/LineItemNumericCalculatorField";
import { LineTableCell } from "components/formFields/LineTableCell";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineShipped(props) {
  const { value, onValueChange, objectType } = props;

  return (
    <LineTableCell sx={{ minWidth: "4rem" }}>
      <LineItemNumericCalculatorField
        name="shipped"
        value={value}
        onValueChange={onValueChange}
        metadata={LINE_ITEM_METADATA[objectType].shipped}
      />
    </LineTableCell>
  );
}
