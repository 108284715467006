import { LineTableCell } from "components/formFields/LineTableCell";
import { LineBin } from "components/formFields/line/LineBin";
import { LineInventoryItem } from "components/formFields/line/LineInventoryItem";
import { LineWorker } from "components/formFields/line/LineWorker";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

import { ITEM_TYPES } from "appConstants";
import { TO_FROM_BIN_OBJECTS } from "appConstants";

export function LineInventoryItemBin(props) {
  const {
    line,
    itemFormType,
    objectType,
    items,
    itemsCount,
    showBin = true,
    addItem,
    handleItemChange,
    expandItemGroup,
    isInError,
    bins,
    showWorkers,
    workers,
    fromBin,
    toBin,
    toBins,
    handleReferenceChange,
    location,
    fromLocation,
    toLocation,
    lineLoading,
    allowExpand,
    sx = {},
    ...unexpected
  } = props;
  checkForUnexpectedProps("LineInventoryItemBin", unexpected);

  const itemType = line.itemDetails?.type;
  const isLaborItem = itemType === ITEM_TYPES.LABOR;

  return (
    <LineTableCell sx={{ minWidth: "13rem", ...sx }}>
      <LineInventoryItem
        lineLoading={lineLoading}
        itemFormType={itemFormType}
        line={line}
        items={items}
        itemsCount={itemsCount}
        addItem={addItem}
        onValueChange={handleItemChange}
        allowExpand={allowExpand}
        expandItemGroup={expandItemGroup}
        error={isInError("item")}
      />
      <LineWorker
        value={line.worker}
        workers={workers}
        itemSelected={line.item?.id}
        onValueChange={handleReferenceChange}
        itemType={itemType}
        showWorkers={showWorkers}
      />
      {showBin && (
        <>
          {!isLaborItem &&
            (TO_FROM_BIN_OBJECTS.hasOwnProperty(objectType) ? (
              <>
                <LineBin
                  value={fromBin}
                  bins={bins}
                  name={TO_FROM_BIN_OBJECTS[objectType].fromBin}
                  label={i18n("columns.colon.FromBin")}
                  itemSelected={line.item?.id}
                  onValueChange={handleReferenceChange}
                  location={fromLocation}
                  itemType={itemType}
                  error={isInError("fromBin")}
                />
                <LineBin
                  value={toBin}
                  bins={toBins}
                  name="toBin"
                  label={i18n("columns.colon.ToBin")}
                  itemSelected={line.item?.id}
                  onValueChange={handleReferenceChange}
                  location={toLocation}
                  itemType={itemType}
                  error={isInError("toBin")}
                />
              </>
            ) : (
              <LineBin
                value={line.bin}
                bins={bins}
                name="bin"
                label={i18n("columns.colon.Bin")}
                itemSelected={line.item?.id}
                onValueChange={handleReferenceChange}
                location={location}
                itemType={itemType}
                error={isInError("bin")}
              />
            ))}
        </>
      )}
    </LineTableCell>
  );
}
