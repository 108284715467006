import SvgIcon from "@mui/material/SvgIcon";

import { theme } from "SosTheme";

const sx = {
  fill: "primaryText",
  "&:hover": { fill: theme.palette.selectIcon },
  maxWidth: "1.5rem",
  cursor: "pointer",
  fontSize: "3.80rem",
};

export function SwapIcon(props) {
  const { ...otherProps } = props;
  const color = theme.palette.primaryText;

  return (
    <SvgIcon sx={sx} viewBox="0 0 68 204" fill={color} {...otherProps}>
      <g stroke="none" strokeWidth="2" fillRule="evenodd">
        <path
          d="M34,73.7C18.4,73.7,5.7,86.4,5.7,102s12.7,28.3,28.4,28.3s28.4-12.7,28.4-28.3
		S49.7,73.7,34,73.7z M18.4,93.5l9.9-9.9l9.9,9.9h-7.1v11.3h-5.7V93.5H18.4z M49.6,110.6l-9.9,9.9l-9.9-9.9h7.1V99.2h5.7v11.3H49.6z
		"
        />
        <g stroke={color} fill="none" strokeWidth="4">
          <path d="M0.5,17.1h10.1h10.1c3.7,0,7.1,1.6,9.5,4.1s3.9,6.1,3.9,10v21.4v21.4" />
          <path d="M0.5,186.4h10.1h10.1c3.7,0,7.1-1.6,9.5-4.1c2.4-2.6,3.9-6.1,3.9-10V151v-21.4" />
        </g>
      </g>
    </SvgIcon>
  );
}
