import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { TextField } from "@mui/material";

import { Tooltip } from "components/Tooltip";
import {
  outlinedStyles,
  inputPropStyles,
} from "components/formFields/lineItemFieldStyle";

import { checkForUnexpectedProps } from "services/utility/misc";
import { isZero } from "services/utility/misc";

// decimal-eval is not a hugely popular library, so in case of a problem
// with this library, we could temporarily drop back to using window.Function
// (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/
// Reference/Global_Objects/eval#never_use_eval!)
import { evaluate } from "decimal-eval";

const NUMERIC_EXPRESSION_MATCHER = /^[\d+\-*/ ().]*$/g;

const sx = { fontWeight: "fontWeightRegular", marginTop: 0, marginBottom: 0 };

export function LineItemNumericCalculatorField(props) {
  const {
    name,
    label,
    value: initialValue,
    toolTipText = "",
    onValueChange,
    metadata,
    error,
    readOnly,
    dataTesting,
    disabled = false,
    ...unexpected
  } = props;
  checkForUnexpectedProps("LineItemNumericCalculatorField", unexpected);

  const validNumber = useSelector(
    (state) => state.userCompanySettings.localization.validNumber
  );

  const [focused, setFocused] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [value, setValue] = useState(metadata.editStr(metadata, initialValue));

  useEffect(() => {
    setValue(metadata.editStr(metadata, initialValue));
  }, [initialValue, metadata]);

  function onChange(e) {
    setUpdated(true);

    // if it's not a valid number...
    if (!validNumber.test(e.target.value)) {
      // see if it's a valid(-ish) expression
      if (!e.target.value.match(NUMERIC_EXPRESSION_MATCHER)) {
        return;
      }
    }

    setValue(e.target.value);
  }

  function onBlur(e) {
    if (!validNumber.test(e.target.value)) {
      if (e.target.value.match(NUMERIC_EXPRESSION_MATCHER)) {
        try {
          e.target.value = evaluate(e.target.value);
        } catch (SyntaxError) {
          e.target.value = "";
        }
      } else {
        e.target.value = "";
      }
    }
    setFocused(false);

    if (updated) {
      onValueChange(name, e.target.value);
      setUpdated(false);
    }
  }

  function getValue() {
    // added to update the input on focus to not show the default zero value
    if (focused && isZero(value) && !updated) {
      return "";
    }
    return value;
  }

  return (
    <Tooltip title={toolTipText}>
      <TextField
        autoComplete="off"
        value={getValue()}
        disabled={disabled}
        variant="outlined"
        onFocus={() => setFocused(true)}
        name={name}
        label={label}
        margin="dense"
        onChange={onChange}
        onBlur={onBlur}
        fullWidth
        error={error}
        data-testing={dataTesting}
        inputProps={{
          readOnly: readOnly,
          style: {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            textAlign: "right",
          },
        }}
        sx={error ? sx : { ...sx, ...outlinedStyles }}
        InputProps={{ sx: inputPropStyles }}
        InputLabelProps={{ shrink: true }}
      />
    </Tooltip>
  );
}
