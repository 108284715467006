import { getDefaultBin } from "services/sosInventoryService/domainLogic";
import { filterAndFormatBinOptions } from "services/utility/misc";
import { getBaseUom } from "services/utility/uoms";

export async function updateLineWithItem(item, line, record) {
  const { location } = record;
  if (!item) {
    return line;
  }

  // transfer the appropriate properties from the new inventory
  // item to the line item
  const newLine = {
    ...line,
    item,
    relatedRecords: { ...line.relatedRecords, item },
    description: item.description,
    weightunit: item.weightUnit,
    volumeunit: item.volumeUnit,
    uom: getBaseUom(item.uoms),
    onhand: item.onhand,
    itemDetails: {
      itemWeight: item.weight,
      itemVolume: item.volume,
      itemUoms: item.uoms,
      serialTracking: item.serialTracking,
      lotTracking: item.lotTracking,
      type: item.type,
    },
  };

  // if we have locationBins, update bin options
  if (item.locationBins) {
    const filteredAndFormattedBinOptions = filterAndFormatBinOptions(
      item.locationBins
    );
    newLine.availableBins = filteredAndFormattedBinOptions;
    // and set the default bin on the line
    newLine.bin = await getDefaultBin(
      item.id,
      location.id,
      filteredAndFormattedBinOptions
    );
  } else {
    newLine.availableBins = null;
    newLine.bin = null;
  }

  return newLine;
}
