export function beforeSave(record) {
  const newRecord = { ...record };

  newRecord.legalAddressLine1 = record.legalAddress.legalAddressLine1;
  newRecord.legalAddressLine2 = record.legalAddress.legalAddressLine2;
  newRecord.legalCity = record.legalAddress.legalCity;
  newRecord.legalCountry = record.legalAddress.legalCountry;
  newRecord.legalPostalCode = record.legalAddress.legalPostalCode;
  newRecord.legalState = record.legalAddress.legalState;

  delete newRecord.legalAddress;
  return newRecord;
}
