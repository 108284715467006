import { i18n } from "services/i18nService";
import {
  formatDateTimeToDateTimeString,
  truncate,
} from "services/utility/formatting";

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["date"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const syncErrorConfig = {
  columns: [
    {
      heading: i18n("columns.DateTime"),
      defaultSort: true,
      sortDirection: "desc",
      apiName: "date",
      sortable: true,
      formatFunc: formatDateTimeToDateTimeString,
    },
    {
      heading: i18n("columns.Request"),
      apiName: "requestType",
      sortable: true,
    },
    {
      heading: i18n("columns.QuickBooksMessage"),
      apiName: "message",
      formatFunc: truncate,
      maxWidth: "60rem",
      sortable: true,
    },
    {
      heading: i18n("columns.Object"),
      apiName: "quickBooksId",
      sortable: true,
    },
  ],
};
