import { i18n } from "services/i18nService";

import {
  NONE_GROUP_BY_OPTION,
  ALL_OPTION_WITH_ALL_ID,
  SUB_COMPONENT,
} from "views/Reports/reportConstants";

import { OBJECT_TYPES } from "appConstants";

const GROUP_BY_OPTIONS = [
  NONE_GROUP_BY_OPTION,
  { id: "Category", name: i18n("global.Category") },
  { id: "Class", name: i18n("global.Class") },
  { id: "Customer", name: i18n("global.Customer") },
  { id: "Department", name: i18n("global.Department") },
  { id: "Item", name: i18n("global.Item") },
  { id: "Location", name: i18n("global.Location") },
  { id: "Vendor", name: i18n("global.Vendor") },
];

const OPEN_CLOSED_OPTIONS = [
  ALL_OPTION_WITH_ALL_ID,
  { id: "Open", name: i18n("global.Open") },
  { id: "Closed", name: i18n("global.Closed") },
];

const ITEM_RECEIPT_ORDER_TYPE_OPTION = {
  id: "IR",
  name: i18n("objectType.itemreceipt.SentencePlural"),
};

const ORDER_TYPE_OPTIONS = [
  { id: "PO", name: i18n("objectType.purchaseorder.SentencePlural") },
  ITEM_RECEIPT_ORDER_TYPE_OPTION,
  { id: "RTV", name: i18n("objectType.returntovendor.SentencePlural") },
];

const DROP_SHIP_OPTIONS = [
  ALL_OPTION_WITH_ALL_ID,
  { id: "DropShip", name: i18n("reports.ShowDropShippedOnly") },
  { id: "NotDropShip", name: i18n("reports.ShowNotDropShippedOnly") },
];

export const MasterPurchaseReport = {
  userHasSufficientPrivilege: (p) =>
    p.receiveInventory && (p.viewPurchaseOrders || p.editPurchaseOrders),
  planIncludesReport: () => true,
  customReportNameFetchList: [
    { objectType: OBJECT_TYPES.ITEM.fullString, customReportKey: "categories" },
    {
      objectType: OBJECT_TYPES.LOCATION.fullString,
      customReportKey: "locations",
    },
    { objectType: OBJECT_TYPES.CLASS.fullString },
    { objectType: OBJECT_TYPES.CUSTOMER.fullString },
    { objectType: OBJECT_TYPES.DEPARTMENT.fullString },
    { objectType: OBJECT_TYPES.ITEM.fullString },
    { objectType: OBJECT_TYPES.VENDOR.fullString },
  ],
  customFieldObjectTypes: ["purchaseorder", "itemreceipt", "item"],
  requiredFiltersNotSetMsg: i18n("reports.OrderTypeFilterRequired"),
  columns: [
    { apiName: "OrderNumber", label: "Txn #", default: true },
    { apiName: "Txn Type", default: true },
    { apiName: "Txn Date", default: true },
    { apiName: "Vendor", default: true },
    { apiName: "Item", default: true },
    { apiName: "Location" },
    { apiName: "Description", default: true },
    { apiName: "Ordered", default: true, number: true },
    { apiName: "Received", default: true, number: true },
    { apiName: "Backordered", number: true },
    { apiName: "UOM" },
    { apiName: "Unit Price", number: true },
    { apiName: "Open Amt", number: true },
    { apiName: "Total Amt", number: true },
    { apiName: "Landed Amt", number: true },
    { apiName: "ETA" },
    { apiName: "Expected Ship" },
    { apiName: "Customer" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
    { apiName: "CustomerPartNumber", label: "Customer Part #" },
    { apiName: "SKU" },
    { apiName: "Comment" },
    { apiName: "Terms" },
    { apiName: "Payment" },
    { apiName: "Drop Ship" },
    { apiName: "Blanket PO" },
    { apiName: "Contract Manufacturing" },
    { apiName: "On Hand", number: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      defaultValue: () => new Date(),
      datePrefix: "startOf",
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.OrderType"),
      component: "ReportMultiSelect",
      apiName: "users",
      defaultValue: [ITEM_RECEIPT_ORDER_TYPE_OPTION],
      options: ORDER_TYPE_OPTIONS,
      type: "filter",
      required: true,
    },
    {
      label: i18n("filter.Locations"),
      component: "LocationsFilter",
      apiName: "locations",
      defaultValue: null,
      placeholder: i18n("reports.placeholder.DefaultIsAll", {
        type: i18n("reports.locations"),
      }),
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Item"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Class"),
      component: "ClassFilter",
      apiName: "class",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Department"),
      component: "DepartmentFilter",
      apiName: "department",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "valueCalculation",
      label: i18n("reports.FilterLabel.DropShip"),
      component: "FrmButtonGroup",
      defaultValue: ALL_OPTION_WITH_ALL_ID,
      options: DROP_SHIP_OPTIONS,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains2",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.KeywordSearch"),
      component: "FrmTextFieldWithHoverHelp",
      apiName: "nameContains",
      defaultValue: null,
      helpText: i18n("reports.hoverHelp.PurchaseKeywordSearch"),
      type: "filter",
    },
    {
      apiName: "date1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "orderType",
      label: i18n("reports.OptionLabel.OpenClosed"),
      component: "FrmSelectFromObjects",
      options: OPEN_CLOSED_OPTIONS,
      defaultValue: ALL_OPTION_WITH_ALL_ID,
      type: "option",
    },
    {
      apiName: "groupBy",
      defaultValue: NONE_GROUP_BY_OPTION,
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmSelectFromObjects",
      options: GROUP_BY_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
