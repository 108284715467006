import { i18n } from "services/i18nService";

import { initialErrorState } from "views/common";

export function nameIsValid(record) {
  const errors = initialErrorState();
  errors.entity = [];

  if (!record.name || !record.name.trim().length) {
    errors.messages.push(i18n("isValid.Name"));
    errors.entity.push("name");
  }

  return errors;
}
