import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Box } from "@mui/material";

import { SelectButtonCombo } from "components/formFields/SelectButtonCombo";

import { i18n } from "services/i18nService";
import { afterTouchLine } from "services/sosInventoryService/fulfillmentTransaction/afterTouchLine";
import { getRecord } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";
import {
  getBaseUom,
  getUomConversionFromUomReference,
} from "services/utility/uoms";

import { useCustomerData } from "hooks/useCustomerData";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { getObjectFromFullString } from "appConstants";

export function AddCustomerOrder(props) {
  const { customer, lineHandler, objectType, label } = props;

  const orders = useCustomerData(customer, objectType);

  const [order, setOrder] = useState(orders?.length ? orders[0] : null);

  const dispatch = useDispatch();

  function changeOrder(_, value) {
    setOrder(orders.find(({ id }) => id === value.id));
  }

  useEffect(() => {
    setOrder(orders?.length ? orders[0] : null);
  }, [orders, setOrder]);

  async function addOrder() {
    if (!order) {
      return;
    }

    dispatch(editModalLoadingIndicatorOn());
    const sourceOrder = await getRecord(order.objectType, order.txnId);
    const nonZeroLines = sourceOrder.lines.filter(({ shipped, quantity }) =>
      shipped.lt(quantity)
    );
    const transactionType = getObjectFromFullString(
      order.objectType
    ).shortString;

    const newLines = await Promise.all(
      nonZeroLines.map(async (line) => {
        const item = await getRecord("item", line.item.id);
        const uom = line.uom || getBaseUom(item.uoms);
        const conversion = getUomConversionFromUomReference(uom, item.uoms);
        const newLine = {
          ...line,
          linkedTransaction: {
            id: sourceOrder.id,
            refNumber: sourceOrder.number,
            lineNumber: line.lineNumber,
            transactionType,
          },
          quantity: line.quantity.minus(line.shipped),
          onhand: item.onhand.div(conversion),
          relatedRecords: { item },
          itemDetails: {
            itemWeight: item.weight,
            itemVolume: item.volume,
            itemUoms: item.uoms,
            serialTracking: item.serialTracking,
            lotTracking: item.lotTracking,
            type: item.type,
            useMarkup: item.useMarkup,
            markupPercent: item.markupPercent,
            baseSalesPrice: item.baseSalesPrice,
          },
        };
      delete newLine.id;
        return afterTouchLine(newLine, "quantity");
      })
    );
    if (newLines.length) {
      setPageDirty();
      lineHandler({ type: "append", newLines });
    }

    dispatch(editModalLoadingIndicatorOff());
  }

  return (
    <Box display="flex" alignItems="flex-end" size="small">
      <SelectButtonCombo
        key={orders}
        disableClearable
        maxWidth="12rem"
        minWidth="12rem"
        selectLabel={label}
        buttonLabel={i18n("global.Add")}
        value={order}
        onValueChange={changeOrder}
        onClick={addOrder}
        options={orders}
        disabled={!Boolean(customer)}
      />
    </Box>
  );
}
