import { Decimal, Money } from "classes/DecimalClasses";

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["quantity", "baseVolume", "baseWeight", "volume", "weight"],
  MONEY_FIELDS: ["cost", "total"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const EMPTY_LINE = {
  lineId: "",
  lineNumber: "",
  componentItem: null,
  description: null,
  quantity: new Decimal(0),
  cost: new Money(0),
  total: new Money(0),
  typeOfItem: null,
  baseWeight: new Decimal(0),
  weight: new Decimal(0),
  weightUnit: "lb",
  baseVolume: new Decimal(0),
  volume: new Decimal(0),
  volumeUnit: "cbm",
  notes: null,
};
