import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { openModal, closeModal } from "globalState/modalSlice";

const nonIds = ["new", "newfrom", "newfromparent"];

export function useEditModal(objectType, id, allowEdit = true) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (id && allowEdit) {
      dispatch(
        openModal({
          id: nonIds.includes(id) ? null : id,
          objectType,
        })
      );
    } else {
      dispatch(closeModal());
    }
    return () => dispatch(closeModal());
  }, [dispatch, id, objectType, allowEdit]);
}
