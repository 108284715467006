import { FrmSelectBase } from "components/formFields/FrmSelectBase";

import { checkForUnexpectedProps } from "services/utility/misc";

/**
 * @name    FrmSelectScalarWithObjectOptions
 *
 * @summary presents options for the user to choose from when the
 *          options are standard SOS reference objects ({id, name})
 *
 * @param   name (string) name of the input field, which will be provided
 *          as a parameter to onChange (see below)
 *
 * @param   value (string or number) - the value of the field in the
 *          record that this component is providing for input
 *
 * @param   options (array of standard SOS reference objects: {id, name}) -
 *          choices the user should have for this field
 *
 * @param   onChange (function) - this component will call the onChange
 *          function when the user selects a new value, passing onChange the name
 *          of the field and the new (scalar) value
 */
export function FrmSelectScalarWithObjectOptions(props) {
  const {
    options,
    label,
    onValueChange,
    error,
    disabled,
    value,
    name,
    dataTesting,
    maxWidth,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmSelectScalarWithObjectOptions", unexpected);

  function renderOption(props, option) {
    return (
      <li data-testing="selectOption" {...props}>
        {option.name}
      </li>
    );
  }

  function isOptionEqualToValue(option, value) {
    return option.id === value.id;
  }

  return (
    <FrmSelectBase
      name={name}
      error={error}
      label={label}
      disabled={disabled}
      maxWidth={maxWidth}
      value={options.find(({ id }) => id === value) || null}
      options={options}
      onValueChange={(name, value) => onValueChange(name, value?.id || "")}
      getOptionLabel={({ name }) => name || ""}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={renderOption}
      dataTesting={dataTesting}
    />
  );
}
