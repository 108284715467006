import { Card, CardContent, Grid } from "@mui/material";

import { CardGrid } from "components/CardGrid";
import { FrmTextField } from "components/formFields/FrmTextField";

export function LegalAddress(props) {
  const { name, value, onValueChange } = props;

  function handleValueBlur(fieldName, fieldValue) {
    return onValueChange(name, { ...value, [fieldName]: fieldValue });
  }

  return (
    <Card variant="outlined" sx={{ backgroundColor: "inherit" }}>
      <CardContent>
        <CardGrid>
          {[1, 2].map((i) => (
            <FrmTextField
              key={"legalAddressLine" + i}
              name={"legalAddressLine" + i}
              label={`Line ${i}`}
              maxLength={400}
              onValueBlur={handleValueBlur}
              value={value ? value["legalAddressLine" + i] : ""}
            />
          ))}
          <Grid container direction="row" spacing={1}>
            <Grid item xs={6}>
              <FrmTextField
                name="legalCity"
                label="City"
                maxLength={255}
                onValueBlur={handleValueBlur}
                value={value?.legalCity}
              />
              <FrmTextField
                name="legalPostalCode"
                label="Postal code"
                maxLength={30}
                onValueBlur={handleValueBlur}
                value={value?.legalPostalCode}
              />
            </Grid>
            <Grid item xs={6}>
              <FrmTextField
                name="legalState"
                label="State/province"
                maxLength={255}
                onValueBlur={handleValueBlur}
                value={value?.legalState}
              />
              <FrmTextField
                name="legalCountry"
                label="Country"
                maxLength={255}
                onValueBlur={handleValueBlur}
                value={value?.legalCountry}
              />
            </Grid>
          </Grid>
        </CardGrid>
      </CardContent>
    </Card>
  );
}
