import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
  name: "alert",
  initialState: {
    type: "",
    message: null,
    open: false,
  },
  reducers: {
    openAlert: (state, action) => {
      return {
        ...state,
        type: action.payload.type,
        message: action.payload.message,
        open: true,
      };
    },
    closeAlert: (state) => {
      return {
        ...state,
        type: "",
        open: false,
        message: null,
      };
    },
  },
});

export const { openAlert, closeAlert } = alertSlice.actions;
export default alertSlice.reducer;
