import { i18n } from "services/i18nService";
import {
  formatNameLinkField,
  formatAddress,
  formatBooleanYesOrBlank,
} from "services/utility/formatting";

export const columnInfo = [
  {
    name: "name",
    fixed: true,
    heading: i18n("columns.Name"),
    formatFunc: formatNameLinkField,
    defaultSort: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "address",
    heading: i18n("columns.Address"),
    formatFunc: formatAddress,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "defaultLocation",
    heading: i18n("columns.Default"),
    formatFunc: formatBooleanYesOrBlank,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "binTracking",
    heading: i18n("columns.BinTracking"),
    formatFunc: formatBooleanYesOrBlank,
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
  },
  {
    name: "nonNettable",
    heading: i18n("columns.NonNettable"),
    formatFunc: formatBooleanYesOrBlank,
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
  },
];

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];

export function getEmptyRecord(settings) {
  return {
    name: null,
    address: null,
    company: null,
    contact: null,
    salesRate: settings.defaultTaxRate,
    default: false,
    binTracking: false,
    nonNettable: false,
  };
}
