import { useSelector } from "react-redux";

import { LineItemAutocompleteField } from "components/formFields/LineItemAutocompleteField";
import {
  LineTableCell,
  LineTableHeadingCell,
} from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";

export function OcostClassHeading() {
  const classEnabled = useSelector(
    (state) => state.userCompanySettings.settings.classEnabled
  );
  return (
    <>
      {classEnabled && (
        <LineTableHeadingCell>{i18n("columns.Class")}</LineTableHeadingCell>
      )}
    </>
  );
}

export function OcostClass(props) {
  const { value, classes, onValueChange } = props;

  const classEnabled = useSelector(
    (state) => state.userCompanySettings.settings.classEnabled
  );

  return (
    <>
      {classEnabled && (
        <LineTableCell sx={{ minWidth: "5rem" }}>
          <LineItemAutocompleteField
            name="class"
            options={classes}
            value={value}
            onValueChange={onValueChange}
            allowTooltip
          />
        </LineTableCell>
      )}
    </>
  );
}
