export function afterGet(record) {
  const newRecord = {
    ...record,
    processNumber: {
      prefix: record.prefixProcess,
      nextNumber: record.nextProcessNumber,
    },
  };

  delete newRecord.prefixProcess;
  delete newRecord.nextProcess;

  return newRecord;
}
