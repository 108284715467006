export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixInvoice: record.invoiceNumber.prefix,
    nextInvoiceNumber: record.invoiceNumber.nextNumber,
  };

  delete newRecord.invoiceNumber;

  return newRecord;
}
