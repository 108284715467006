import { SETTINGS as accounts } from "views/Configuration/settings/inventory/accounts";
import { SETTINGS as barcode } from "views/Configuration/settings/inventory/barcode";
import { SETTINGS as general } from "views/Configuration/settings/inventory/general";
import { SETTINGS as transactions } from "views/Configuration/settings/inventory/transactions";

export const SETTINGS = {
  general,
  transactions,
  accounts,
  barcode,
};
