export const SETTINGS = [
  { apiName: "expandKitsOnSalesOrder", component: "FrmCheckbox" },
  { apiName: "salesOrderText", component: "FrmResizeTextField", maxLength: 50 },
  {
    apiName: "salesOrderNumber",
    component: "FrmTransactionNumber",
    transactionType: "salesorder",
    hasInputLabel: true,
  },
  { apiName: "replyEmailSO", component: "FrmEmail", maxLength: 255 },
  { apiName: "salesOrderEmail", component: "FrmResizeTextField" },
  { apiName: "salesOrderFooter", component: "FrmResizeTextField" },
  {
    apiName: "defaultCCSalesOrder",
    component: "FrmEmail",
    maxLength: 255,
  },
  { apiName: "editLocksEnabled", component: "FrmCheckbox" },
];
