import { useSelector } from "react-redux";

import { FrmSelectWithAdd } from "components/formFields/FrmSelectWithAdd";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

export function FrmVendorWithAdd(props) {
  const {
    value,
    onValueChange,
    setAddNewVendor,
    setNewVendorName,
    error,
    vendors,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmVendorWithAdd", unexpected);

  const searchByAnyPartOfVendor = useSelector(
    (state) => state.userCompanySettings.settings.searchByAnyPartOfVendor
  );

  async function handleChange(_, value, input) {
    if (value?.id === "add") {
      setAddNewVendor(true);
      setNewVendorName(input);
      return;
    }
    onValueChange("vendor", value);
  }

  if (!vendors) {
    return null;
  }

  return (
    <FrmSelectWithAdd
      name="vendor"
      options={vendors}
      label={i18n("frmLabel.Vendor")}
      onValueChange={handleChange}
      value={value}
      error={error}
      sx={{ width: "25rem" }}
      dataTesting="vendor"
      searchOptionsByAnyPart={searchByAnyPartOfVendor}
    />
  );
}
