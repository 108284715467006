import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

import { OBJECT_TYPES } from "appConstants";

export function FrmCustomerType(props) {
  const { value, onValueChange } = props;

  const customerTypes = useRelated(OBJECT_TYPES.CUSTOMER_TYPE.fullString);

  return (
    <FrmSelectFromObjects
      label={i18n("customer.CustomerType")}
      name="customerType"
      value={value}
      onValueChange={onValueChange}
      options={customerTypes}
      dataTesting="customerType"
    />
  );
}
