import { useEffect, useState } from "react";

import { getTaxCodes } from "services/sosInventoryService/sosApi";

export const TAX_CODE_TYPE_PURCHASE = "purchase";
export const TAX_CODE_TYPE_SALES = "sales";
export const TAX_CODE_TYPE_ALL = "all";

export function useTaxCodes(taxCodeType) {
  const [records, setRecords] = useState(null);

  useEffect(() => {
    async function retrieveRecords() {
      setRecords(await getTaxCodes(taxCodeType));
    }
    retrieveRecords();
  }, [taxCodeType]);

  return records;
}
