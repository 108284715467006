import { Button } from "@mui/material";

const sx = {
  color: "TextFieldButtonCombo.buttonText",
  backgroundColor: "TextFieldButtonCombo.buttonBackground",
  borderColor: "TextFieldButtonCombo.buttonOutline",
  borderStyle: "solid",
  borderWidth: "1px",
  borderLeftWidth: 0,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  height: "2.9em",
  top: "8px",
  "&:hover": { backgroundColor: "TextFieldButtonCombo.buttonHover" },
};

export function SideButton(props) {
  const { label, onClick, disabled } = props;
  return (
    <Button
      variant="contained"
      size="small"
      onClick={onClick}
      disabled={disabled}
      disableElevation
      sx={sx}
    >
      {label}
    </Button>
  );
}
