export function afterGet(record) {
  const newRecord = {
    ...record,
    settings: {
      format: record.barcodeFormat,
      width: record.barcodeWidth,
      height: record.barcodeHeight,
    },
  };

  delete newRecord.barcodeWidth;
  delete newRecord.barcodeHeight;
  delete newRecord.barcodeFormat;

  return newRecord;
}
