import { Layout } from "Layout";

import { SyncList } from "components/SyncPage/SyncList";

import { i18n } from "services/i18nService";

import { usePrivileges } from "hooks/usePrivileges";

import { Forbidden } from "Forbidden";

export function SyncPermission(props) {
  const { objectType } = props;

  const { isAdmin } = usePrivileges();

  return (
    <Layout pageTitle={i18n(`objectType.${objectType}.SentencePlural`)}>
      {isAdmin ? <SyncList objectType={objectType} /> : <Forbidden />}
    </Layout>
  );
}
