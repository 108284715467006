// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import {
  extractName,
  formatDocumentInfo,
  formatBooleanYesOrBlank,
  formatDecimal,
  formatNumberLinkField,
  truncate,
} from "services/utility/formatting";
import { starColumn } from "services/utility/schema";

export const columnInfo = [
  starColumn,
  {
    name: "number",
    fixed: true,
    heading: i18n("columns.LotNumber"),
    formatFunc: formatNumberLinkField,
    sortDirection: "asc",
    sortable: true,
    linkField: true,
  },
  {
    name: "description",
    heading: i18n("columns.Description"),
    minWidth: "15em",
    formatFunc: truncate,
    sortable: true,
    default: true,
  },
  {
    name: "item",
    heading: i18n("columns.Item"),
    formatFunc: extractName,
    defaultSort: true,
    sortDirection: "asc",
    minWidth: "10em",
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "itemDescription",
    heading: i18n("columns.ItemDescription"),
    sortDirection: "asc",
    sortable: true,
  },
  {
    name: "onhand",
    heading: i18n("columns.InStock"),
    formatFunc: formatDecimal,
    align: "right",
    sortable: true,
  },
  {
    name: "available",
    heading: i18n("columns.Available"),
    formatFunc: formatDecimal,
    align: "right",
    sortable: true,
  },
  {
    name: "expiration",
    heading: i18n("columns.ExpDate"),
    formatFunc: formatDate,
    minWidth: "8em",
    sortable: true,
  },
  {
    name: "recalled",
    heading: i18n("columns.Recalled"),
    formatFunc: formatBooleanYesOrBlank,
    sortable: true,
  },
];

export function getEmptyRecord() {
  return {
    syncToken: 0,
    starred: 0,
    number: "",
    item: null,
    itemDescription: null,
    description: null,
    expiration: null,
    recalled: false,
    expired: false,
    customFields: null,
    onhand: new Decimal(0),
    available: new Decimal(0),
    summaryOnly: false,
    archived: false,
    documents: [],
    keys: null,
    values: null,
  };
}

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["onhand", "available"],
  MONEY_FIELDS: [],
  DATE_FIELDS: ["expiration"],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const LINE_ACTIONS = ["pdf", "delete", "archive", "history"];
export const BATCH_ACTIONS = ["pdf", "delete", "archive"];
