import { useState } from "react";
import { useDispatch } from "react-redux";

import { Box, Button, Typography } from "@mui/material";

import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmTextField } from "components/formFields/FrmTextField";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { submitFsPaymentInfoRequest } from "services/sosInventoryService/sosApi";

import { openAlert } from "globalState/alertSlice";

export function SosPaymentsContact() {
  const [formFields, setFormFields] = useState({});

  const dispatch = useDispatch();

  function handleFieldChange(field, newValue) {
    setFormFields((prev) => ({ ...prev, [field]: newValue }));
  }

  async function submitForm() {
    const success = await submitFsPaymentInfoRequest(formFields);
    if (success) {
      dispatch(openAlert({ type: "success", message: i18n("sosPayments.22") }));
    } else {
      dispatch(openAlert({ type: "error", message: i18n("sosPayments.23") }));
    }
  }

  return (
    <Box
      sx={{
        margin: 2,
        marginBottom: 0,
        width: "100%",
        maxWidth: "30rem",
      }}
    >
      <Typography variant="h2">{i18n("sosPayments.01")}</Typography>
      <VSpace space={3} />
      <Typography variant="h3">{i18n("sosPayments.15")}</Typography>
      <Typography>{i18n("sosPayments.16")}</Typography>
      <VSpace space={1} />
      <FrmTextField
        name="name"
        label={i18n("sosPayments.17")}
        maxLength={50}
        onValueBlur={handleFieldChange}
        value={formFields.name}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <FrmTextField
          name="email"
          label={i18n("sosPayments.18")}
          maxLength={100}
          onValueBlur={handleFieldChange}
          value={formFields.email}
          sx={{ width: "16rem" }}
        />
        <FrmTextField
          name="phone"
          label={i18n("sosPayments.19")}
          maxLength={20}
          onValueBlur={handleFieldChange}
          value={formFields.phone}
          sx={{ width: "12rem" }}
        />
      </Box>
      <FrmTextField
        name="companyName"
        label={i18n("sosPayments.20")}
        maxLength={100}
        onValueBlur={handleFieldChange}
        value={formFields.companyName}
      />
      <FrmResizeTextField
        minRows={2}
        name="description"
        label={i18n("sosPayments.21")}
        onValueBlur={handleFieldChange}
        width="95%"
        value={formFields.description}
      />
      <VSpace space={1} />
      <Button variant="contained" onClick={submitForm}>
        {i18n("sosPayments.14")}
      </Button>
    </Box>
  );
}
