import { forwardRef } from "react";

export const A = forwardRef((props, ref) => {
  const { to, children, ...rest } = props;
  return (
    <a href={to} {...rest} ref={ref}>
      {children}
    </a>
  );
});
