import { useEffect, useState } from "react";

import { getItemQuicklist } from "services/sosInventoryService/sosApi";
import { createItemQuickListFilters } from "services/utility/misc";

import { ITEM_QUICKLIST_CASES } from "appConstants";

/**
 * @name    useItems
 *
 * @summary retrieves the quicklist version of item records (not the full
 *          record); either all items or various subsets ("types" of items),
 *          which are documented below
 *
 * @param   type (one of the enumerated strings in ITEM_QUICKLIST_CASES)
 *          see appConstants.js for valid values; if type is undefined,
 *          ALL items will be returned
 *
 * @param   reGet (any value) - when reGet changes, the item list will be
 *          retrieved again from the API; this is generally useful when
 *          it's known that the items in the database have changed for
 *          some reason, like a new item has been added via "quick add";
 *          a typical way to use this prop is to increment an integer each
 *          time a re-get is desired
 *
 * @returns { items, itemsCount} - where items is an array of inventory item
 *          quick list records, and itemsCount is the *total* number of rele-
 *          vant records in the database; not that this is *not* the length
 *          of the array (except )
 */
export function useItems(type, reGet = false) {
  const [data, setData] = useState({ items: null, itemsCount: null });

  useEffect(() => {
    async function retrieveRecords() {
      const filters = createItemQuickListFilters(
        type || ITEM_QUICKLIST_CASES.ALL
      );
      const { data, totalCount } = await getItemQuicklist(filters);
      setData({ items: data, itemsCount: totalCount });
    }
    retrieveRecords();
  }, [type, reGet]);

  return data;
}
