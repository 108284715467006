import { Box } from "@mui/material";

import { Money } from "classes/DecimalClasses";

import { FrmTextField } from "components/formFields/FrmTextField";

import { i18n } from "services/i18nService";

import { theme } from "SosTheme";

export function TotalsShipping(props) {
  const { value, onValueChange } = props;

  function handleChange(name, value) {
    onValueChange(name, new Money(value || 0));
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "6fr 4fr 4fr 2fr",
          columnGap: theme.spacing(1),
          alignItems: "center",
        }}
      >
        <div></div>
        <div></div>
        <div>
          <FrmTextField
            money
            name="shippingAmount"
            label={i18n("global.ShippingAmount")}
            value={value}
            onValueBlur={handleChange}
            dataTesting="shippingAmount"
          />
        </div>
        <div
          style={{
            fontWeight: theme.typography.fontWeightMedium,
            paddingTop: "0.25rem",
          }}
        >
          {i18n("global.Shipping")}
        </div>
      </div>
    </Box>
  );
}
