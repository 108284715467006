import { Link as MUILink } from "@mui/material";
import { styled } from "@mui/system";

const CustomLink = styled(MUILink)({
  color: "primaryLink",
  "&:visited": { color: "primaryLink" },
});

export function ExternalLink(props) {
  const { href, target, children } = props;
  return (
    <CustomLink underline="none" href={href} target={target}>
      {children}
    </CustomLink>
  );
}
