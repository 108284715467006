import { useState } from "react";

import CloudDownload from "@mui/icons-material/CloudDownload";
import CloudUpload from "@mui/icons-material/CloudUpload";
import HeadsetMic from "@mui/icons-material/HeadsetMic";
import Settings from "@mui/icons-material/Settings";
import SettingsSystemDaydream from "@mui/icons-material/SettingsSystemDaydream";
import { MenuList } from "@mui/material";

import { Divider } from "components/Divider";
import { DropDownMenu } from "components/TopNav/DropDownMenu";
import { MenuItemLink } from "components/TopNav/MenuItemLink";
import { MenuListItemText } from "components/TopNav/MenuListItemText";
import { NavListItemIcon } from "components/TopNav/NavListItemIcon";
import { TopNavIcon } from "components/TopNav/TopNavIcon";

import { i18n } from "services/i18nService";
import { isBetaEnvironment } from "services/utility/misc";

import { usePrivileges } from "hooks/usePrivileges";

export function SettingsMenu() {
  const { hasPrivilegesOrIsAdmin, isSupport } = usePrivileges();
  const [anchor, setAnchor] = useState(null);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
    }

    setAnchor(null);
  }
  const openMenu = Boolean(anchor);
  const menuId = openMenu ? "settings-menu" : undefined;

  return (
    <>
      <TopNavIcon
        image="/images/tools_and_settings.svg"
        onClick={(e) => setAnchor(e.currentTarget)}
        functionText={i18n("iconAltText.toolsAndSettings")}
      />
      <DropDownMenu
        id={menuId}
        open={openMenu}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
      >
        <MenuList
          id="settings-list-grow"
          onKeyDown={handleListKeyDown}
          sx={{ px: 1 }}
        >
          {hasPrivilegesOrIsAdmin([
            "PurchasingSettings",
            "InventorySettings",
            "SalesSettings",
            "EditShipments",
          ]) && (
            <div>
              <MenuItemLink
                // disable configuration nav for beta environment
                disabled={isBetaEnvironment}
                to="/configuration"
                onClick={() => setAnchor(null)}
              >
                <NavListItemIcon>
                  <Settings />
                </NavListItemIcon>
                <MenuListItemText
                  primary={i18n("topNav.settingsMenu.settings")}
                />
              </MenuItemLink>
              <Divider />
            </div>
          )}
          <MenuItemLink to="/import" onClick={() => setAnchor(null)}>
            <NavListItemIcon>
              <CloudUpload />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.settingsMenu.import")} />
          </MenuItemLink>
          <MenuItemLink to="/export" onClick={() => setAnchor(null)}>
            <NavListItemIcon>
              <CloudDownload />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.settingsMenu.export")} />
          </MenuItemLink>
          <MenuItemLink to="/dataUtilities" onClick={() => setAnchor(null)}>
            <NavListItemIcon>
              <SettingsSystemDaydream />
            </NavListItemIcon>
            <MenuListItemText
              primary={i18n("topNav.settingsMenu.dataUtilities")}
            />
          </MenuItemLink>
          {isSupport && (
            <div>
              <Divider />
              <MenuItemLink
                to="/supportConsole"
                onClick={() => setAnchor(null)}
              >
                <NavListItemIcon>
                  <HeadsetMic />
                </NavListItemIcon>
                <MenuListItemText
                  primary={i18n("topNav.settingsMenu.support")}
                />
              </MenuItemLink>
            </div>
          )}
        </MenuList>
      </DropDownMenu>
    </>
  );
}
