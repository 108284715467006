import { Box } from "@mui/material";

import { checkForUnexpectedProps } from "services/utility/misc";

export function CardGrid(props) {
  const { gridColumn, children, ...unexpected } = props;
  checkForUnexpectedProps("CardGrid", unexpected);

  return (
    <Box
      sx={{
        display: "grid",
        rowGap: 1,
        gridColumn: gridColumn,
        gridAutoRows: "min-content",
      }}
    >
      {children}
    </Box>
  );
}
