import { BaseDateFilter } from "components/ListPage/Widgets/EditDisplayResults/BaseDateFilter";

export function DateFilter(props) {
  const {
    toDate,
    fromDate,
    setFilters,
    setDateError,
    setDateFilter,
    dateFilter,
  } = props;

  function onDateChange(dates) {
    const { fromDate, toDate } = dates;
    setFilters((prevParams) => ({
      ...prevParams,
      fromDate,
      toDate,
      startingRecord: 1,
    }));
  }

  return (
    <BaseDateFilter
      toDate={toDate}
      fromDate={fromDate}
      setDateError={setDateError}
      setDateFilter={setDateFilter}
      onDateChange={onDateChange}
      dateFilter={dateFilter}
    />
  );
}
