import { useState, useMemo } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, Box, TextField } from "@mui/material";

import {
  textFieldStyle,
  clearIndicatorStyles,
} from "components/formFields/lineItemFieldStyle";
import { ButtonProgress } from "components/utility/ButtonProgress";

import { i18n } from "services/i18nService";

export function DocumentSearch(props) {
  const {
    onValueChange,
    label,
    documents,
    options: initialOptions,
    onInputChange,
    loading,
  } = props;

  // add "Add new item" to options
  const options = initialOptions ? [...initialOptions] : [];
  options.unshift({
    id: "add",
    fileName: (
      <Box
        sx={{
          color: "primary.main",
          fontWeight: "Medium",
        }}
      >
        {i18n("document.AddNewDocument")}
      </Box>
    ),
  });

  const documentIds = useMemo(
    () => (documents ? documents?.map(({ id }) => id) : []),
    [documents]
  );

  const [inputValue, setInputValue] = useState("");

  function handleInputChange(_, value, reason) {
    if (reason === "reset") {
      setInputValue("");
      onInputChange(null);
    }
    if (reason === "input") {
      setInputValue(value);
      onInputChange(value);
    }
  }

  function getOptionLabel(option) {
    if (typeof option.fileName === "string") {
      return option.fileName;
    }
    // since we never show the "Add new document" in the input field, what
    // we return here doesn't matter
    if (option.id === "add") {
      return "";
    }
    throw new Error("Unexpected option in getOptionLabel");
  }

  function filterOptions(options) {
    return options.filter(({ id }) => !documentIds.includes(id));
  }

  const isLoading = !Array.isArray(options);

  return (
    <>
      <div style={{ position: "relative", flexGrow: 1 }}>
        <Autocomplete
          loading={isLoading}
          multiple
          options={isLoading ? [] : options}
          onChange={(_, __, ___, selected) => onValueChange(selected)}
          renderTags={() => null}
          value={documents ? documents : []}
          getOptionLabel={getOptionLabel}
          filterOptions={filterOptions}
          filterSelectedOptions
          onInputChange={handleInputChange}
          inputValue={inputValue}
          freeSolo
          fullWidth
          popupIcon={<KeyboardArrowDownIcon sx={{ color: "selectIcon" }} />}
          sx={clearIndicatorStyles}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                disabled={loading}
                placeholder={i18n("document.SearchPlaceholder")}
                variant="outlined"
                label={label}
                margin="dense"
                sx={textFieldStyle}
              />
            );
          }}
          renderOption={(props, option) => (
            <li {...props}>{option.fileName}</li>
          )}
        />
        {loading && <ButtonProgress />}
      </div>
    </>
  );
}
