import { LineItemTextField } from "components/formFields/LineItemTextField";

import { LINE_ITEM_METADATA } from "services/sosInventoryService/itemReceipt/schema";

export function LineDescription(props) {
  const { value, onValueChange, maxLength } = props;

  return (
    <LineItemTextField
      name="description"
      multiline
      maxRows={4}
      resize
      value={value}
      onValueChange={onValueChange}
      maxLength={maxLength}
      metadata={LINE_ITEM_METADATA.description}
      dataTesting="description"
    />
  );
}
