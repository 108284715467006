import { i18n } from "services/i18nService";
import {
  formatNameLinkField,
  formatBooleanYesOrBlank,
} from "services/utility/formatting";

import { OBJECT_TYPES } from "appConstants";

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: formatNameLinkField,
    fixed: true,
    linkField: true,
    defaultSort: true,
    sortable: true,
  },
  {
    name: "type",
    heading: i18n("columns.Type"),
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },

  {
    name: "monitor",
    heading: i18n("columns.Monitor"),
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "dateCompare",
    heading: i18n("columns.Trigger"),
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "active",
    heading: i18n("columns.Enabled"),
    formatFunc: formatBooleanYesOrBlank,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "repeat",
    heading: i18n("columns.Repeat"),
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "interval",
    heading: i18n("columns.Interval"),
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "method",
    heading: i18n("columns.AlertMethod"),
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "email",
    heading: i18n("columns.Email"),
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
];

export const LINE_ACTIONS = ["test", "delete"];
export const BATCH_ACTIONS = ["delete"];

export const LOW_INVENTORY_ALERT_TYPE = {
  name: i18n("alert.LowInventory"),
  id: "Low Inventory",
};

export const CUSTOM_FIELD_ALERT_TYPE = {
  name: i18n("alert.CustomField"),
  id: "CustomField",
};

export const ALERT_TYPES = [
  { name: i18n("alert.BillDue"), id: "Bill Due" },
  CUSTOM_FIELD_ALERT_TYPE,
  { name: i18n("alert.InvoiceDue"), id: "Invoice Past Due" },
  { name: i18n("alert.LotExpiration"), id: "LotExpiration" },
  LOW_INVENTORY_ALERT_TYPE,
  { name: i18n("alert.SalesOrderDue"), id: "SalesOrderDue" },
  { name: i18n("alert.SalesReceiptDue"), id: "SalesReceiptDue" },
  { name: i18n("alert.TaskDue"), id: "TaskDue" },
];

export const NOTIFY_USER_OPTION = {
  name: i18n("alert.NotifyAUser"),
  id: "Notify",
};
export const SEND_EMAIL_OPTION = {
  name: i18n("alert.SendAnEmail"),
  id: "Email",
};
export const SEND_TEXT_OPTION = { name: i18n("alert.SendAText"), id: "Text" };

export const ALERT_METHOD_OPTIONS = [
  NOTIFY_USER_OPTION,
  SEND_EMAIL_OPTION,
  SEND_TEXT_OPTION,
];

const DAILY_REPEAT_OPTION = { name: i18n("alert.Daily"), id: "Daily" };

export const REPEAT_OPTIONS = [
  { name: i18n("alert.Always"), id: "Always" },
  DAILY_REPEAT_OPTION,
  { name: i18n("alert.Never"), id: "Never" },
];

export const INTERVAL_OPTIONS = [
  { name: i18n("alert.Hourly"), id: "Hourly" },
  DAILY_REPEAT_OPTION,
  { name: i18n("alert.Weekly"), id: "Weekly" },
];

export const ALERT_ON_OPTIONS = [
  {
    id: OBJECT_TYPES.ADJUSTMENT.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.ADJUSTMENT.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.BUILD.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.BUILD.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.CUSTOMER.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.CUSTOMER.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.ESTIMATE.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.ESTIMATE.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.INVOICE.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.INVOICE.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.ITEM_RECEIPT.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.ITEM_RECEIPT.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.ITEM.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.ITEM.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.JOB.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.JOB.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.LOT.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.LOT.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.PAYMENT.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.PAYMENT.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.PICK_TICKET.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.PICK_TICKET.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.PROCESS.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.PROCESS.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.PURCHASE_ORDER.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.PURCHASE_ORDER.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.RENTAL.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.RENTAL.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.RENTAL_RETURN.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.RENTAL_RETURN.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.RETURN.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.RETURN.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.RETURN_TO_VENDOR.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.RETURN_TO_VENDOR.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.RMA.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.RMA.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.SALES_ORDER.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.SALES_ORDER.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.SALES_RECEIPT.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.SALES_RECEIPT.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.SERIAL.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.SERIAL.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.SHIPMENT.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.SHIPMENT.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.TRANSFER.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.TRANSFER.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.VENDOR.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.VENDOR.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.WORK_ORDER.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.WORK_ORDER.fullString}.SentencePlural`
    ),
  },
];

export const LOW_INV_LOT_EXP_OPTIONS = [
  { id: "Available", name: i18n("alert.AvailableForSale") },
  { id: "OnHand", name: i18n("alert.InStock") },
];

export const MOBILE_CARRIER_OPTIONS = [
  { name: "AT&T", id: "ATT" },
  { name: "Australia (any)", id: "AU" },
  { name: "Orange", id: "Orange" },
  { name: "Sprint", id: "Sprint" },
  { name: "Telus", id: "Telus" },
  { name: "T-Mobile", id: "TMobile" },
  { name: "Verizon", id: "Verizon" },
];

export function getEmptyRecord(userId) {
  return {
    name: null,
    type: LOW_INVENTORY_ALERT_TYPE.id,
    email: null,
    active: true,
    repeat: DAILY_REPEAT_OPTION.id,
    interval: DAILY_REPEAT_OPTION.id,
    user: { id: +userId },
    location: null,
    category: null,
    customField: null,
    dateCompare: null,
    mobileCarrier: null,
    mobileNumber: null,
    lowInventoryCalculation: null,
    itemNameContains: null,
  };
}
