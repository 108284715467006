import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import { formatDecimal, extractName } from "services/utility/formatting";

export const onRma = {
  relatedObjectType: "rma",
  headerText: i18n("modalHeader.onRma"),
  columns: [
    {
      jsonName: "refNumber",
      label: i18n("columns.ReferenceNumber"),
      linkColumn: true,
      sortable: true,
    },
    {
      jsonName: "quantity",
      label: i18n("columns.Ordered"),
      formatFunc: formatDecimal,
      number: true,
      sortable: true,
    },
    {
      jsonName: "received",
      label: i18n("columns.Received"),
      formatFunc: formatDecimal,
      number: true,
      sortable: true,
    },
    {
      jsonName: "customer",
      label: i18n("columns.Customer"),
      formatFunc: extractName,
      sortable: true,
    },
    { jsonName: "memo", label: i18n("columns.Memo") },
    {
      jsonName: "shipMethod",
      label: i18n("columns.ShipMethod"),
      formatFunc: extractName,
    },
    { jsonName: "trackingNumber", label: i18n("columns.TrackingNumber") },
    {
      jsonName: "expiration",
      label: i18n("columns.Expiration"),
      formatFunc: formatDate,
      sortable: true,
    },
  ],
  FIELDS_TO_TRANSFORM: {
    DECIMAL_FIELDS: ["quantity", "received"],
    MONEY_FIELDS: [],
    DATE_FIELDS: ["expiration"],
    DATETIME_FIELDS: [],
    NON_UTC_DATETIME_FIELDS: [],
  },
};
