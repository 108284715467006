import { Box } from "@mui/material";

import { FrmTextField } from "components/formFields/FrmTextField";

import { i18n } from "services/i18nService";

import { theme } from "SosTheme";

export function TotalsDiscount(props) {
  const { value, percentValue, onPercentChange, onAmountChange } = props;
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "6fr 4fr 4fr 2fr",
          columnGap: theme.spacing(1),
          alignItems: "center",
        }}
      >
        <div></div>
        <FrmTextField
          number
          name="discountPercent"
          label={i18n("global.DiscountPercent")}
          value={percentValue ? percentValue.toString() : percentValue}
          onValueBlur={onPercentChange}
          dataTesting="discountPercent"
        />
        <div style={{ fontWeight: theme.typography.fontWeightMedium }}>
          <FrmTextField
            money
            name="discountAmount"
            label={i18n("global.DiscountAmount")}
            value={value}
            onValueBlur={onAmountChange}
            dataTesting="discountAmount"
          />
        </div>
        <div
          style={{
            fontWeight: theme.typography.fontWeightMedium,
            paddingTop: "0.25rem",
          }}
        >
          {i18n("global.Discount")}
        </div>
      </div>
    </Box>
  );
}
