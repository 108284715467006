import { Box } from "@mui/material";

import { FrmCheckbox } from "components/formFields/frm/FrmCheckbox";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";

export function FrmSyncDirections(props) {
  const { name, value, onValueChange } = props;

  function handleValueChange(fieldName, newValue) {
    onValueChange(name, { ...value, [fieldName]: newValue });
  }

  return (
    <Box>
      <FrmCheckbox
        name="syncSOStoQBO"
        label={i18n("settings.connections.quickbooks.syncSOStoQBO.label")}
        value={value.syncSOStoQBO}
        onValueChange={handleValueChange}
      />
      <VSpace space={2} />
      <FrmCheckbox
        name="syncQBOtoSOS"
        label={i18n("settings.connections.quickbooks.syncQBOtoSOS.label")}
        value={value.syncQBOtoSOS}
        onValueChange={handleValueChange}
      />
    </Box>
  );
}
