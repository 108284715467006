export function afterGet(record) {
  const newRecord = {
    ...record,
    syncDirections: {
      syncSOStoQBO: record.syncSOStoQBO,
      syncQBOtoSOS: record.syncQBOtoSOS,
    },
  };

  delete newRecord.syncSOStoQBO;
  delete newRecord.syncQBOtoSOS;

  return newRecord;
}
