// dispatch recordsChangedTick to indicate that the underlying set of list
// page records in the database has changed; the recordsChanged state value
// can be used to trigger a reload of list page records
import { createSlice } from "@reduxjs/toolkit";

export const recordsChangedSlice = createSlice({
  name: "recordsChanged",
  initialState: 0,
  reducers: { recordsChangedTick: (state) => state + 1 },
});

export const { recordsChangedTick } = recordsChangedSlice.actions;
export default recordsChangedSlice.reducer;
