export const SETTINGS = [
  {
    apiName: "companyName",
    component: "FrmTextField",
  },
  {
    apiName: "legalAddress",
    component: "LegalAddress",
  },
];
