// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import {
  extractName,
  formatDocumentInfo,
  formatStatus,
  formatDateTimeToDate,
  formatNumberLinkField,
  truncate,
  formatBooleanToYesOrBlank,
  formatQuantityWithUom,
  extractDescription,
  formatLinkableItem,
} from "services/utility/formatting";
import {
  dateTypeEditStrFunction,
  referenceTypeEditStrFunction,
  inventoryItemTypeEditStrFunction,
  textTypeEditStrFunction,
  linkedTransactionTypeEditStrFunction,
  numberTypeEditStrFunction,
  decimalTypeEditStrFunction,
} from "services/utility/list";
import { parseHTML } from "services/utility/parsing";
import { TYPES, starColumn } from "services/utility/schema";

import { DEFAULT_DECIMALS_UNROUNDED, NO_REF_NUMBER_STRING } from "appConstants";

export function getEmptyRecord() {
  return {
    starred: 0,
    syncToken: 0,
    number: "",
    date: new Date(),
    customer: null,
    location: null,
    channel: null,
    priority: null,
    assignedToUser: null,
    shippingMethod: null,
    trackingNumber: null,
    linkedTransaction: null,
    comment: null,
    customerNotes: null,
    closed: false,
    customFields: [],
    pickBy: null,
    total: new Decimal(0),
    archived: false,
    summaryOnly: false,
    hasSignature: false,
    documents: [],
    keys: null,
    values: null,
    lines: [],
  };
}

export const LINE_ITEM_METADATA = {
  lineNumber: {},
  item: {
    type: TYPES.reference,
    editStr: inventoryItemTypeEditStrFunction,
    initialValue: { id: null, name: null, description: null },
  },
  description: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  quantity: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weight: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: numberTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weightunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  volume: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  class: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  jobWorkcenter: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  volumeunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  linkedTransaction: {
    type: TYPES.linkedTransaction,
    editStr: linkedTransactionTypeEditStrFunction,
  },
  linkedTransactiontransactionType: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  linkedTransactionRefNumber: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  linkedTransactionLineNumber: {
    type: TYPES.number,
    editStr: numberTypeEditStrFunction,
  },
  uom: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  bin: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  lot: {
    type: TYPES.reference,
    editStr: textTypeEditStrFunction,
  },
  duedate: {
    type: TYPES.date,
    editStr: dateTypeEditStrFunction,
    initialValue: () => new Date(),
  },
  shipped: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  picked: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  invoiced: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  returned: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  produced: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  onhand: {
    type: TYPES.itemField,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  itemDetails: { initialValue: {} },
  relatedRecords: {
    initialValue: {},
  },
};

export const columnInfo = [
  starColumn,
  {
    name: "number",
    fixed: true,
    heading: i18n("columns.ReferenceNumber"),
    formatFunc: formatNumberLinkField,
    minWidth: "7em",
    linkField: true,
    sortable: true,
  },
  {
    name: "date",
    heading: i18n("columns.Date"),
    formatFunc: formatDateTimeToDate,
    defaultSort: true,
    sortDirection: "asc",
    sortable: true,
    default: true,
  },
  {
    name: "location",
    heading: i18n("columns.Location"),
    formatFunc: extractName,
    sortable: true,
    default: true,
  },
  {
    name: "status",
    fieldName: "closed",
    formatFunc: formatStatus,
    heading: i18n("columns.Status"),
    sortable: true,
    default: true,
  },
  {
    name: "comment",
    heading: i18n("columns.Comment"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "customer",
    heading: i18n("columns.Customer"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "25em",
    sortable: true,
  },
  {
    name: "pickBy",
    heading: i18n("columns.PickBy"),
    formatFunc: formatDate,
    sortable: true,
  },
  {
    name: "priority",
    heading: i18n("columns.Priority"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "assignedToUser",
    heading: i18n("columns.AssignedTo"),
    formatFunc: extractName,
    minWidth: "10em",
    sortable: true,
  },
  {
    name: "channel",
    heading: i18n("columns.Channel"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "customerNotes",
    heading: i18n("columns.CustomerNotes"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
  },
  {
    name: "hasSignature",
    heading: i18n("columns.Signature"),
    formatFunc: formatBooleanToYesOrBlank,
  },
];

const columnArray = Object.entries(columnInfo);
const defaultColumnInfo = columnArray.filter((col) => col[1].required);
export const defaultColumns = defaultColumnInfo.map((col) => col[0]);

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: ["total"],
  DATE_FIELDS: ["pickBy"],
  DATETIME_FIELDS: ["date"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const LINE_ACTIONS = [
  ["quickView", "pdf", "email"],
  "template",
  "shipment",
  "archive",
  "openClose",
  "delete",
  "history",
];
export const BATCH_ACTIONS = [
  ["pdf", "email"],
  "delete",
  "archive",
  "openClose",
];
export const REFERENCE_FIELDS = ["location", "customer"];

export const ITEM_CALCULATED_FIELDS = ["onhand"];

export const QUICK_VIEW_CONFIG = {
  titleSetting: "pickTicketTitle",
  footerSetting: "pickTicketFooter",
  signatureLinesLabel: i18n("colon.PulledBy"),
  tableConfig: [{ type: "lines" }],
  headerFields: [
    {
      name: "date",
      formatFunc: formatDateTimeToDate,
      label: i18n("columns.Date"),
    },
    {
      name: "number",
      label: i18n("frmLabel.PickTicketNumber"),
      defaultValue: NO_REF_NUMBER_STRING,
    },
  ],
  fields: [],
  lines: [
    {
      name: "item",
      formatFunc: formatLinkableItem,
      heading: i18n("columns.Item"),
      align: "left",
      isItemField: true,
    },
    {
      name: "description",
      formatFunc: extractDescription,
      heading: i18n("columns.Description"),
      align: "left",
    },
    {
      name: "quantity",
      formatFunc: formatQuantityWithUom,
      heading: i18n("columns.Quantity"),
      align: "right",
    },
  ],
};
