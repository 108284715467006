import { FrmSelectBase } from "components/formFields/FrmSelectBase";

import { checkForUnexpectedProps } from "services/utility/misc";

import PropTypes from "prop-types";

export function FilterSelectField(props) {
  const {
    name,
    options,
    onValueChange,
    optionDisplayText = "name",
    value,
    label,
    dataTesting,
    disableClearable,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FilterSelectField", unexpected);

  function renderOption(props, option) {
    return (
      <li {...props} key={option.id}>
        <span data-testing="selectOption">{option[optionDisplayText]}</span>
      </li>
    );
  }

  function getOptionLabel(option) {
    if (option[optionDisplayText]) {
      return option[optionDisplayText];
    } else {
      const label = options?.find(({ id }) => id === option.id);
      return label ? label[optionDisplayText] : "";
    }
  }

  return (
    <FrmSelectBase
      name={name}
      value={value}
      label={label}
      loading={!Array.isArray(options)}
      options={
        options
          ? options.map((option) => ({
              id: option.id,
              [optionDisplayText]: option[optionDisplayText],
            }))
          : []
      }
      onValueChange={onValueChange}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={renderOption}
      dataTesting={dataTesting}
      disableClearable={disableClearable}
    />
  );
}

FilterSelectField.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  disableClearable: PropTypes.bool,
  dataTesting: PropTypes.string,
};
