import { Box, Pagination } from "@mui/material";

import { Filters } from "components/ListPage/List/Row/History/Filters";
import { Search } from "components/ListPage/Widgets/Search";

const styles = { marginLeft: "0.5rem", marginRight: "0.5rem" };

export function Widgets(props) {
  const {
    retrievalParams,
    setRetrievalParams,
    totalHistoryCount,
    typeOptions,
  } = props;

  const { start, maxResults } = retrievalParams;

  function pageChange(_, page) {
    setRetrievalParams((prevParams) => ({
      ...prevParams,
      start: (page - 1) * maxResults + 1,
    }));
  }

  return (
    <Box sx={{ displayPrint: "none" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          height: "3.5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        ></div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div style={styles}>
            <Search
              setRetrievalParams={setRetrievalParams}
              objectType="notification"
            />
          </div>

          <div style={styles}>
            <Filters
              retrievalParams={retrievalParams}
              setRetrievalParams={setRetrievalParams}
              typeOptions={typeOptions}
            />
          </div>
          <div style={styles}>
            <Pagination
              page={(start - 1) / maxResults + 1}
              size="small"
              shape="rounded"
              siblingCount={1}
              count={Math.ceil(totalHistoryCount / maxResults)}
              onChange={pageChange}
            />
          </div>
        </div>
      </div>
    </Box>
  );
}
