export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixAdjustment: record.adjustmentNumber.prefix,
    nextAdjustmentNumber: record.adjustmentNumber.nextNumber,
    prefixTransfer: record.transferNumber.prefix,
    nextTransferNumber: record.transferNumber.nextNumber,
  };

  delete newRecord.adjustmentNumber;
  delete newRecord.transferNumber;

  return newRecord;
}
