export function afterGet(record) {
  const newRecord = {
    ...record,
    pictureSize: { width: record.pictureWidth, height: record.pictureHeight },
    barcodeSize: { width: record.barcodeWidth, height: record.barcodeHeight },
    allowNegativeInBins: !record.preventNegativeInBins,
    includeCategoryPrefix: !record.findItemsByShortName,
    serialLotNumbering: {
      automaticallyNumber: record.autoSerialNumbers,
      numberRandomly: record.autoSerialLotRandom,
      autoLength: record.autoSerialNumberLength,
      serials: {
        prefix: record.autoSerialNumberPrefix,
        nextNumber: record.autoSerialNextSequential,
      },
      lots: {
        prefix: record.autoLotNumberPrefix,
        nextNumber: record.autoLotNextSequential,
      },
    },
  };

  delete newRecord.pictureWidth;
  delete newRecord.pictureHeight;
  delete newRecord.barcodeWidth;
  delete newRecord.barcodeHeight;
  delete newRecord.preventNegativeInBins;
  delete newRecord.findItemsByShortName;

  // serialLotNumbering related fields
  delete newRecord.autoSerialNumbers;
  delete newRecord.autoSerialLotRandom;
  delete newRecord.autoSerialNumberLength;
  delete newRecord.autoSerialNumberPrefix;
  delete newRecord.autoSerialNextSequential;
  delete newRecord.autoLotNumberPrefix;
  delete newRecord.autoLotNextSequential;

  return newRecord;
}
