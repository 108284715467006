import { convertNativeToCustom } from "services/utility/customFields";
import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/vendor/schema";
import { htmlEncode } from "services/utility/parsing";

export function beforeSave(record) {
  const newRecord = { ...record };

  newRecord.customFields = convertNativeToCustom(record.customFields);

  // HTML encode relevant fields
  if (FIELDS_TO_TRANSFORM.HTML_ENCODED_FIELDS) {
    FIELDS_TO_TRANSFORM.HTML_ENCODED_FIELDS.forEach((field) => {
      newRecord[field] = htmlEncode(newRecord[field]);
    })
  }

  return newRecord;
}
