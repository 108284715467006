import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Layout } from "Layout";

import { CustomFields } from "components/CustomFields/CustomFields";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmTextField } from "components/formFields/FrmTextField";
import {
  FrmShipMethod,
  FrmCreateBill,
  FrmComment,
  FrmDepartment,
  FrmNumber,
} from "components/formFields/frm";
import { FrmLocation } from "components/formFields/frm/FrmLocation";
import { FrmSignature } from "components/formFields/frm/FrmSignature";
import { DateTimePicker } from "components/html/Input/DateTimePicker";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";
import { WarningModal } from "components/utility/WarningModal";

import { i18n } from "services/i18nService";
import {
  updateTotals,
  ZERO_TOTALS,
} from "services/sosInventoryService/transfer/updateTotals";
import { setPageDirty } from "services/utility/edit";

import { useBins } from "hooks/useBins";
import { useCustomFieldDefinitions } from "hooks/useCustomFieldDefinitions";
import { useErrors } from "hooks/useErrors";
import { useItems } from "hooks/useItems";
import { useLineHandler } from "hooks/useLineHandler";

import { LineItems } from "views/Transfers/Transfer/LineItems";
import {
  updateTransaction,
  getTransaction,
} from "views/Transfers/Transfer/controller";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { theme } from "SosTheme";
import { OBJECT_TYPES } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.TRANSFER.fullString;

export function Transfer(props) {
  const { id, onClose, newFromId, newFromObjectType } = props;

  // TRANSACTION STATE
  const [record, setRecord] = useState();
  const [lines, lineHandler] = useLineHandler();
  const [relatedRecords, setRelatedRecords] = useState({});

  const CURRENT_STATE = { record, lines, relatedRecords };

  // UI STATE
  const { errors, setErrors, isInError } = useErrors();
  const [clearLines, setClearLines] = useState(false);
  const transactionCustomFieldDefs = useCustomFieldDefinitions(OBJECT_TYPE);
  const [itemAdded, setItemAdded] = useState(0);
  const [totals, setTotals] = useState(ZERO_TOTALS);
  const toBins = useBins(record?.toLocation);
  const loadingState = useSelector((state) => state.loading.editModal);

  // UPDATERS FOR CONTROLLER TO UPDATE STATE
  const UPDATERS = useMemo(
    () => ({
      setRecord,
      lineHandler,
      setRelatedRecords,
      setErrors,
    }),
    [lineHandler, setErrors]
  );

  // RELATED RECORD LISTS
  const { items, itemsCount } = useItems(null, itemAdded);

  // HANDLE ALL USER INPUT
  function handleInputFieldChange(field, newValue) {
    setPageDirty();
    updateTransaction(field, newValue, CURRENT_STATE, UPDATERS);
  }

  // SETTINGS
  const departmentEnabled = useSelector(
    (state) => state.userCompanySettings.settings.departmentEnabled
  );

  // OTHER SETUP
  const dispatch = useDispatch();

  // LOAD INITIAL TRANSACTION STATE
  useEffect(() => {
    async function _getTransaction() {
      dispatch(editModalLoadingIndicatorOn());
      await getTransaction(
        id,
        newFromId,
        newFromObjectType,
        UPDATERS,
        transactionCustomFieldDefs
      );
      dispatch(editModalLoadingIndicatorOff());
    }
    if (transactionCustomFieldDefs) {
      _getTransaction();
    }
    _getTransaction();
  }, [
    dispatch,
    id,
    newFromId,
    newFromObjectType,
    lineHandler,
    UPDATERS,
    transactionCustomFieldDefs,
  ]);

  // update totals whenever the line items change
  useEffect(() => updateTotals(lines, setTotals), [lines]);

  function doClearLines() {
    setPageDirty();
    lineHandler({ type: "clear", blankLine: EMPTY_LINE_ITEM.transfer });
    setClearLines(false);
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        relatedRecords={relatedRecords}
        lines={lines}
        setErrors={setErrors}
        errors={errors}
        text={record?.number}
        handleClose={onClose}
      />
      {loadingState && <Loading />}
      <ObjectEditDialogContent>
        <Layout
          pageTitle={
            id
              ? `${i18n("objectType.transfer.Sentence")} ${
                  record?.number || ""
                }`
              : i18n("objectType.transfer.New")
          }
        >
          <div id="B" style={{ margin: theme.spacing(2) }}>
            <FormErrors errors={errors} setErrors={setErrors} />
            {record ? (
              <>
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }}>
                    <DateTimePicker
                      value={record.date}
                      name="date"
                      label={i18n("frmLabel.DateTime")}
                      onValueChange={handleInputFieldChange}
                      error={isInError("date")}
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmNumber
                      recordId={record.id}
                      label={i18n("transfer.labelNumber")}
                      value={record.number}
                      onValueChange={handleInputFieldChange}
                      error={isInError("number")}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmLocation
                      disableClearable
                      name="fromLocation"
                      value={record.fromLocation}
                      label={i18n("frmLabel.FromLocation")}
                      onValueChange={handleInputFieldChange}
                      width="25rem"
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmLocation
                      disableClearable
                      name="toLocation"
                      value={record.toLocation}
                      label={i18n("frmLabel.ToLocation")}
                      onValueChange={handleInputFieldChange}
                      dataTesting="toLocation"
                      width="25rem"
                    />
                  </div>
                </FrmFieldGrid>
                <VSpace space={1} />
                <LineItems
                  record={record}
                  objectType={OBJECT_TYPE}
                  toBins={toBins}
                  relatedRecords={relatedRecords}
                  lines={lines}
                  items={items}
                  totals={totals}
                  itemsCount={itemsCount}
                  addItem={() => setItemAdded((prev) => prev + 1)}
                  lineHandler={lineHandler}
                  setClearLines={setClearLines}
                  lineItemsErrors={errors.line}
                  setErrors={setErrors}
                />

                <VSpace space={2} />

                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmTextField
                      money
                      name="shippingAmount"
                      label={i18n("frmLabel.ShippingCost")}
                      value={record.shippingAmount}
                      onValueBlur={handleInputFieldChange}
                      sx={{ maxWidth: "20rem" }}
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmShipMethod
                      maxWidth="20rem"
                      onValueChange={handleInputFieldChange}
                      value={record?.shippingMethod}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmTextField
                      name="trackingNumber"
                      label={i18n("frmLabel.TrackingNumber")}
                      sx={{ maxWidth: "20rem" }}
                      maxLength={21}
                      value={record.trackingNumber}
                      onValueBlur={handleInputFieldChange}
                    />
                  </div>
                  {departmentEnabled && (
                    <div style={{ gridColumn: "2 / 2" }}>
                      <FrmDepartment
                        maxWidth="20rem"
                        value={record.department}
                        onValueChange={handleInputFieldChange}
                      />
                    </div>
                  )}
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmComment
                      value={record.comment}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmCreateBill
                      value={record.createBillForShippingAmount || false}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmSignature
                      objectType={OBJECT_TYPE}
                      id={record.id}
                      hasSignature={record.hasSignature}
                    />
                  </div>
                </FrmFieldGrid>

                <CustomFields
                  customFieldDefinitions={transactionCustomFieldDefs}
                  customFields={record.customFields}
                  onValueChange={handleInputFieldChange}
                  setErrors={setErrors}
                />
              </>
            ) : (
              !isInError("record") && (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )
            )}
            <VSpace space={4} />
          </div>
          {record && (
            <>
              <WarningModal
                title={i18n("global.ClearLines")}
                message={i18n("global.clearLinesMessage")}
                open={clearLines}
                onOk={doClearLines}
                onClose={() => setClearLines(false)}
              />
            </>
          )}
        </Layout>
      </ObjectEditDialogContent>
    </>
  );
}
