import { Box, LinearProgress, alpha } from "@mui/material";

import { theme } from "SosTheme";

const sx = {
  backgroundColor: alpha(theme.palette.primary.main, 0.75),
  "& .MuiLinearProgress-barColorPrimary": {
    backgroundColor: alpha(theme.palette.barColorPrimary, 0.75),
  },
};

export function Loading() {
  return (
    <Box width="100%">
      <LinearProgress data-testing="loading" sx={sx} />
    </Box>
  );
}
