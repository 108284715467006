import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import { LeftNavIcons } from "components/LeftNav/LeftNavIcons";
import { LeftNavText } from "components/LeftNav/LeftNavText";

import { LEFT_NAV_ICONS } from "globalState/leftNavSlice";

import { HIDDEN_LEFT_NAV_PATHS, TOP_NAV_BAR_HEIGHT } from "appConstants";

const sx = {
  backgroundColor: "componentBackground",
  borderRight: "1px solid",
  borderRightColor: "lineSeparatorMinor",
  zIndex: "leftNav",
  scrollBehavior: "smooth",
  overflowY: "auto",
  overflowX: "hidden",
  paddingTop: 2,
  position: "fixed",
  gridArea: "nav",
  displayPrint: "none",
  height: `calc(100vh - ${TOP_NAV_BAR_HEIGHT})`,
  top: TOP_NAV_BAR_HEIGHT,
};

export function LeftNav() {
  const { pathname } = useLocation();
  const leftNavState = useSelector((state) => state.leftNav.iconsText);
  const isLoggedIn = useSelector((state) => state.authentication.isLoggedIn);
  const showLeftNav =
    !HIDDEN_LEFT_NAV_PATHS.some((path) => pathname.startsWith(path)) &&
    isLoggedIn;

  const navComponent =
    leftNavState === LEFT_NAV_ICONS ? <LeftNavIcons /> : <LeftNavText />;

  return (
    <Box sx={sx} data-testing="leftNav">
      {showLeftNav && navComponent}
    </Box>
  );
}
