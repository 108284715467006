import { useState } from "react";
import { useDispatch } from "react-redux";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, MenuItem } from "@mui/material";

import { TextFieldButtonCombo } from "components/formFields/TextFieldButtonCombo";

import { i18n } from "services/i18nService";
import { afterTouchLine } from "services/sosInventoryService/purchasingTransaction/afterTouchLine";
import { getRecord } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";

import { useVendorPos } from "hooks/useVendorPos";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { OBJECT_TYPES } from "appConstants";

const OBJECT_TYPE = OBJECT_TYPES.PURCHASE_ORDER.fullString;

export function AddPo(props) {
  const { vendor, lineHandler } = props;

  const [po, setPo] = useState(null);

  const dispatch = useDispatch();

  const pos = useVendorPos(vendor);

  let poValue;
  if (!pos || pos.length === 0) {
    poValue = "";
  } else {
    poValue = po || pos[0].id;
  }

  async function addPo() {
    if (!po) {
      return;
    }
    dispatch(editModalLoadingIndicatorOn());
    const sourcePo = await getRecord(
      OBJECT_TYPES.PURCHASE_ORDER.fullString,
      po
    );
    const nonZeroLines = sourcePo.lines.filter(({ received, quantity }) =>
      received.lt(quantity)
    );
    const newLines = nonZeroLines.map((line) => {
      const newLine = {
        ...line,
        linkedTransaction: {
          id: sourcePo.id,
          refNumber: sourcePo.number,
          lineNumber: line.lineNumber,
          transactionType: OBJECT_TYPES.PURCHASE_ORDER.shortString,
        },
        quantity: line.quantity.minus(line.received),
      };
      delete newLine.id;
      return afterTouchLine(newLine, "quantity");
    });
    if (newLines.length) {
      setPageDirty();
      lineHandler({ type: "append", newLines });
    }
    dispatch(editModalLoadingIndicatorOff());
  }

  return (
    <Box display="flex" alignItems="flex-end" size="small">
      <TextFieldButtonCombo
        TextFieldProps={{
          select: true,
          name: OBJECT_TYPE,
          label: i18n("objectType.purchaseorder.Sentence"),
          disabled: !pos.length,
          onChange: (e) => setPo(e.target.value),
          value: poValue,
          SelectProps: {
            IconComponent: KeyboardArrowDownIcon,
            MenuProps: { disableScrollLock: true },
          },
        }}
        ButtonProps={{ onClick: addPo, disabled: !pos.length }}
        buttonLabel={i18n("global.Add")}
      >
        {pos.map(({ id, number }) => (
          <MenuItem key={id} value={id}>
            {number}
          </MenuItem>
        ))}
      </TextFieldButtonCombo>
    </Box>
  );
}
