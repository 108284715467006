import { SETTINGS as general } from "views/Configuration/settings/purchasing/general";
import { SETTINGS as itemReceipts } from "views/Configuration/settings/purchasing/itemReceipts";
import { SETTINGS as purchaseOrders } from "views/Configuration/settings/purchasing/purchaseOrders";
import { SETTINGS as returnsToVendors } from "views/Configuration/settings/purchasing/returnsToVendors";

export const SETTINGS = {
  general,
  purchaseOrders,
  itemReceipts,
  returnsToVendors,
};
