import { Decimal } from "classes/DecimalClasses";

// when this field (the key) changes, we should run the (value) list of
// updater functions

export const ZERO_TOTALS = { quantity: new Decimal(0) };

export function updateTotals(lines, setTotals) {
  if (!lines || lines.length === 0) {
    setTotals(ZERO_TOTALS);
    return;
  }

  const quantity = lines.reduce(
    (total, line) => total.plus(line.quantity),
    new Decimal(0)
  );

  setTotals({ quantity });
}
