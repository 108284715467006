import {
  DATE_FORMAT_OPTIONS,
  NOTIFICATION_METHOD_OPTIONS,
  PASSWORD_QUESTIONS_OPTIONS,
  MOBILE_CARRIER_OPTIONS,
} from "services/sosInventoryService/settings/user/general/schema";
import { getTimeZoneOptions } from "services/utility/dates";

export function afterGet(record) {
  const newRecord = { ...record };
  newRecord.confirmEmail = record.email;

  newRecord.smsEnabled = Boolean(record.mobileNumber);

  newRecord.timeZone = getTimeZoneOptions().find(
    ({ id }) => id === record.timeZone
  );

  newRecord.dateFormat = DATE_FORMAT_OPTIONS.find(
    ({ id }) => id === record.dateFormat
  );

  newRecord.notificationMethod = NOTIFICATION_METHOD_OPTIONS.find(
    ({ id }) => id === record.notificationMethod
  );

  newRecord.passwordQuestion = PASSWORD_QUESTIONS_OPTIONS.find(
    ({ id }) => id === record.passwordQuestion
  );

  newRecord.mobileCarrier = MOBILE_CARRIER_OPTIONS.find(
    ({ id }) => id === record.mobileCarrier
  );
  return newRecord;
}
