import { useDispatch } from "react-redux";

import { LinkText } from "components/utility/LinkText";

import { i18n } from "services/i18nService";
import { addToReorderList, SUCCESS } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

import { openAlert } from "globalState/alertSlice";
import {
  loadingIndicatorOn,
  loadingIndicatorOff,
} from "globalState/loadingSlice";

export function AddToReorderList(props) {
  const { id } = props;
  const dispatch = useDispatch();

  async function handleAddToReorderList() {
    dispatch(loadingIndicatorOn());
    const response = await addToReorderList([id]);
    if (response === SUCCESS) {
      const message = i18n("alert.SuccessAddedToReorderList");
      dispatch(openAlert({ type: "success", message }));
    } else {
      handleProgramError(new Error(i18n("alert.ErrorNotAddedToReorderList")));
    }
    dispatch(loadingIndicatorOff());
  }

  return (
    <td style={{ textAlign: "center" }}>
      <LinkText onClick={handleAddToReorderList}>{i18n("global.Add")}</LinkText>
    </td>
  );
}
