import { CloudDownload } from "@mui/icons-material";
import { Button, Box, Pagination } from "@mui/material";

import { Search } from "components/ListPage/Widgets/Search";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

import { BatchActions } from "views/Notifications/List/BatchActions";
import { Filters } from "views/Notifications/List/Filters";

export function Widgets(props) {
  const {
    checkedIds,
    setCheckedIds,
    setSelectAllState,
    retrievalParams,
    setRetrievalParams,
    totalRecords,
    ...unexpected
  } = props;
  checkForUnexpectedProps("Widgets", unexpected);

  function pageChange(_, page) {
    setRetrievalParams((prevParams) => ({
      ...prevParams,
      startingRecord: (page - 1) * retrievalParams.maxResults + 1,
    }));
  }

  return (
    <Box
      sx={{
        displayPrint: "none",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: "3.5rem",
        m: "0 0.5rem",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <BatchActions
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          setSelectAllState={setSelectAllState}
        />

        <Button>
          <CloudDownload sx={{ marginRight: "0.5rem" }} />
          {i18n("listPage.Widgets.export")}
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Search
          setRetrievalParams={setRetrievalParams}
          objectType="notification"
        />

        <Filters
          retrievalParams={retrievalParams}
          setRetrievalParams={setRetrievalParams}
        />
        <Pagination
          page={
            (retrievalParams.startingRecord - 1) / retrievalParams.maxResults +
            1
          }
          size="small"
          shape="rounded"
          siblingCount={1}
          count={Math.ceil(totalRecords / retrievalParams.maxResults)}
          onChange={pageChange}
        />
      </div>
    </Box>
  );
}
