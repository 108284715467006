import { FilterSelectField } from "components/formFields/FilterSelectField";

import { i18n } from "services/i18nService";

import { useLocations } from "hooks/useLocations";

import { ALL_FILTER_OPTION } from "appConstants";

export function EditToFromLocationFilters(props) {
  const { filters, setFilters, showTo, showFrom } = props;

  const { filterSettings } = filters;
  const { ToLocation, FromLocation } = filterSettings;

  const retrievedLocations = useLocations();

  function handleFromChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        FromLocation: value?.id || "",
      },
      startingRecord: 1,
    }));
  }

  function handleToChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        ToLocation: value?.id || "",
      },
      startingRecord: 1,
    }));
  }

  const locations = retrievedLocations?.map(({ id, name }) => ({
    id: id.toString(),
    name,
  }));

  const options = locations ? [ALL_FILTER_OPTION, ...locations] : [];

  return (
    <>
      {showFrom && (
        <FilterSelectField
          options={options}
          onValueChange={handleFromChange}
          value={FromLocation ? { id: FromLocation } : ALL_FILTER_OPTION}
          label={i18n("filter.FromLocation")}
          dataTesting="fromLocationFilter"
          disableClearable
        />
      )}
      {showTo && (
        <FilterSelectField
          options={options}
          onValueChange={handleToChange}
          value={ToLocation ? { id: ToLocation } : ALL_FILTER_OPTION}
          label={i18n("filter.ToLocation")}
          dataTesting="toLocationFilter"
          disableClearable
        />
      )}
    </>
  );
}
