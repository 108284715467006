import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Box } from "@mui/material";

import { SelectButtonCombo } from "components/formFields/SelectButtonCombo";

import { i18n } from "services/i18nService";
import { afterTouchLine } from "services/sosInventoryService/moreTransaction/afterTouchLine";
import { getRecord } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";
import { getBaseUom } from "services/utility/uoms";
import { getUomConversionFromUomReference } from "services/utility/uoms";

import { useCustomerData } from "hooks/useCustomerData";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { OBJECT_TYPES } from "appConstants";

export function AddRental(props) {
  const { customer, lineHandler, objectType } = props;

  const rentals = useCustomerData(customer, objectType);

  const [rental, setRental] = useState(rentals?.length ? rentals[0] : null);

  const dispatch = useDispatch();

  function changeRental(_, value) {
    setRental(rentals.find(({ id }) => id === value.id));
  }

  useEffect(() => {
    setRental(rentals?.length ? rentals[0] : null);
  }, [rentals]);

  function addRental() {
    if (!rental) {
      return;
    }
    (async () => {
      dispatch(editModalLoadingIndicatorOn());
      const sourceRental = await getRecord(
        OBJECT_TYPES.RENTAL.fullString,
        rental.txnId
      );
      const nonZeroLines = sourceRental.lines.filter(({ returned, quantity }) =>
        returned.lt(quantity)
      );

      const newLines = await Promise.all(
        nonZeroLines.map(async (line) => {
          const item = await getRecord("item", line.item.id);
          const uom = line.uom || getBaseUom(item.uoms);
          const conversion = getUomConversionFromUomReference(uom, item.uoms);
          const newLine = {
            ...line,
            linkedTransaction: {
              id: sourceRental.id,
              refNumber: sourceRental.number,
              lineNumber: line.lineNumber,
              transactionType: OBJECT_TYPES.RENTAL.shortString,
            },
            quantity: line.quantity.minus(line.returned),
            onhand: item.onhand.div(conversion),
            relatedRecords: { item },
            itemDetails: {
              itemWeight: item.weight,
              itemVolume: item.volume,
              itemUoms: item.uoms,
              serialTracking: item.serialTracking,
              lotTracking: item.lotTracking,
              type: item.type,
            },
          };
          delete newLine.id;
          return afterTouchLine(newLine, "quantity");
        })
      );

      if (newLines.length > 0) {
        setPageDirty();
        lineHandler({ type: "append", newLines });
      }
      dispatch(editModalLoadingIndicatorOff());
    })();
  }

  return (
    <Box display="flex" alignItems="flex-end" size="small">
      <SelectButtonCombo
        key={rentals}
        disableClearable
        maxWidth="12rem"
        selectLabel={i18n("frmLabel.AddRental")}
        buttonLabel={i18n("global.Add")}
        value={rental}
        onValueChange={changeRental}
        onClick={addRental}
        options={rentals}
        disabled={!Boolean(customer)}
      />
    </Box>
  );
}
