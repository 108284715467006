// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { i18n } from "services/i18nService";
import {
  extractName,
  formatDocumentInfo,
  formatHyperlink,
  formatNameLinkField,
  truncate,
  formatSync,
  formatAddress,
  formatName,
} from "services/utility/formatting";
import { parseHTML } from "services/utility/parsing";
import { starColumn } from "services/utility/schema";

export const columnInfo = [
  starColumn,
  {
    name: "name",
    fixed: true,
    heading: i18n("columns.Name"),
    formatFunc: formatNameLinkField,
    defaultSort: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "companyName",
    heading: i18n("columns.Company"),
    sortable: true,
    default: true,
  },
  {
    name: "phone",
    heading: i18n("columns.Phone"),
    minWidth: "9em",
    default: true,
  },
  {
    name: "email",
    heading: i18n("columns.Email"),
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "id",
    heading: i18n("columns.System"),
    sortDirection: "asc",
    minWidth: "7em",
    align: "right",
    sortable: true,
  },
  {
    name: "contact",
    heading: i18n("columns.Contact"),
    formatFunc: formatName,
    minWidth: "8em",
  },
  {
    name: "address",
    heading: i18n("columns.Address"),
    formatFunc: formatAddress,
    minWidth: "10em",
    maxWidth: "20em",
    sortable: true,
  },
  {
    name: "website",
    heading: i18n("columns.Url"),
    formatFunc: formatHyperlink,
    minWidth: "12em",
    sortable: true,
  },
  {
    name: "terms",
    heading: i18n("columns.Terms"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "currency",
    heading: i18n("columns.Currency"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "notes",
    heading: i18n("columns.Notes"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
  },
  {
    name: "sync",
    fieldName: "syncMessage",
    heading: i18n("columns.Sync"),
    formatFunc: formatSync,
    sortable: true,
  },
  { name: "mobile", heading: i18n("columns.Mobile") },
  { name: "altPhone", heading: i18n("columns.AltPhone") },
  { name: "fax", heading: i18n("columns.Fax") },
  { name: "accountNumber", heading: i18n("columns.AccountNumber") },
  {
    name: "taxCode",
    heading: i18n("columns.TaxCode"),
    formatFunc: extractName,
  },
];

export function getEmptyRecord(settings) {
  return {
    accountNumber: null,
    address: {
      line1: null,
      line2: null,
      line3: null,
      line4: null,
      line5: null,
      city: null,
      stateProvince: null,
      postalCode: null,
      country: null,
    },
    altAddresses: [],
    altPhone: null,
    archived: false,
    companyName: null,
    contact: {
      title: null,
      firstName: null,
      middleName: null,
      lastName: null,
      suffix: null,
    },
    currency: settings.homeCurrencyRef,
    customFields: [],
    documents: [],
    email: null,
    fax: null,
    keys: null,
    mobile: null,
    name: null,
    notes: null,
    phone: null,
    showOnForms: true,
    starred: 0,
    summaryOnly: false,
    syncToken: 1,
    taxCode: null,
    terms: settings.defaultTerms,
    values: null,
    website: "",
  };
}

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["lastSync"],
  NON_UTC_DATETIME_FIELDS: [],
  HTML_ENCODED_FIELDS: ["name"],
};

export const LINE_ACTIONS = [
  "pdf",
  "delete",
  "archive",
  "addresses",
  "vendorHistory",
  "sync",
];
export const BATCH_ACTIONS = ["pdf", "archive", "delete", "sync"];
