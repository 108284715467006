import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

import { useRelated } from "hooks/useRelated";

export function FrmAssignedTo(props) {
  const {
    value,
    onValueChange,
    error,
    maxWidth,
    name = "assignedToUser",
    label = i18n("frmLabel.AssignedToUser"),
    disableClearable,
    disabled,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmAssignedTo", unexpected);

  const users = useRelated("user");

  function getValue() {
    if (value?.name) {
      return value;
    }
    return users ? users.find(({ id }) => id === value?.id) : null;
  }

  return (
    <FrmSelectFromObjects
      name={name}
      label={label}
      value={getValue()}
      onValueChange={onValueChange}
      options={users}
      error={error}
      maxWidth={maxWidth}
      disabled={disabled}
      disableClearable={disableClearable}
    />
  );
}
