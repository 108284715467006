import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";

export function FrmStatusMessage(props) {
  const { onValueChange, value, error } = props;

  const customerPortalEnabled = useSelector(
    (state) => state.userCompanySettings.settings.customerPortalEnabled
  );

  if (!customerPortalEnabled) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" sx={{ maxWidth: "100%" }}>
      <FrmResizeTextField
        minRows={2}
        maxRows={4}
        name="statusMessage"
        label={i18n("frmLabel.StatusMessage")}
        onValueBlur={onValueChange}
        width="95%"
        value={value}
        error={error}
      />
      <HoverHelp
        title={i18n("hoverHelp.StatusMessage")}
        sx={{ marginLeft: 0.5 }}
      />
    </Box>
  );
}
