import { i18n } from "services/i18nService";
import { formatNameLinkField, extractName } from "services/utility/formatting";

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: formatNameLinkField,
    fixed: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "vendor",
    heading: i18n("columns.Vendor"),
    formatFunc: extractName,
    fixed: true,
    sortable: true,
    fixedRightOfAction: true,
  },
  {
    name: "carrierCode",
    heading: i18n("columns.CarrierCode"),
    fixed: true,
    sortable: true,
    fixedRightOfAction: true,
  },
];

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];

export function getEmptyRecord() {
  return {
    name: null,
    vendor: null,
    carrierCode: null,
    shipStationCarrier: null,
    shipStationService: null,
  };
}
