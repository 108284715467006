import { i18n } from "services/i18nService";

export const SETTINGS = [
  {
    apiName: "allowNegativeInventory",
    component: "FrmCheckbox",
  },
  {
    apiName: "allowNegativeInBins",
    component: "FrmCheckbox",
  },
  {
    apiName: "defaultItemIsTaxable",
    component: "FrmCheckbox",
  },
  {
    apiName: "defaultItemSyncWithQBO",
    component: "FrmCheckbox",
  },
  {
    apiName: "calculateReorderPoints",
    planIncludesSetting: (_, pro) => pro,
    component: "FrmCheckbox",
  },
  {
    apiName: "includeCategoryPrefix",
    component: "FrmCheckbox",
  },
  {
    apiName: "searchAnyPartOfItem",
    component: "FrmCheckbox",
  },
  {
    apiName: "searchItemDescription",
    component: "FrmCheckbox",
  },
  {
    apiName: "inventoryMethod",
    component: "FrmRadioGroup",
    options: [
      { value: "FIFO", label: i18n("settings.valuationMethod.FIFO") },
      { value: "Average", label: i18n("settings.valuationMethod.Average") },
      {
        value: "LIFO",
        label: i18n("settings.valuationMethod.LIFO"),
        disabled: true,
        tooltip: i18n("hoverHelp.valuationMethod.LIFO"),
      },
    ],
  },
  {
    apiName: "valueSerialsBySpecificId",
    component: "FrmCheckbox",
  },
  {
    apiName: "useTransactionsForSerialQOH",
    component: "FrmCheckbox",
    displayIf: "useTransactionsForSerialQOH",
  },
  {
    apiName: "overrideFIFObyLocation",
    component: "FrmCheckbox",
  },
  {
    apiName: "dontShowQuantityWhenEditing",
    component: "FrmCheckbox",
  },
  {
    apiName: "pictureSize",
    component: "FrmImageSize",
  },
  {
    apiName: "unitsOfMeasureEnabled",
    component: "FrmCheckbox",
  },
  {
    apiName: "rentalInventoryEnabled",
    component: "FrmCheckbox",
  },
  {
    apiName: "trackWorkersOnLaborItems",
    component: "FrmCheckbox",
  },
  { apiName: "jobTrackingEnabled", component: "FrmCheckbox" },
  {
    apiName: "serialLotNumbering",
    component: "FrmSerialLotNumbering",
    explanationOverride: (
      <>
        {i18n("settings.inventory.general.serialLotNumbering.explanation.1")}
        <p>
          {i18n("settings.inventory.general.serialLotNumbering.explanation.2")}
        </p>
        <p>
          {i18n("settings.inventory.general.serialLotNumbering.explanation.3")}
        </p>
        <p>
          {i18n("settings.inventory.general.serialLotNumbering.explanation.4")}
        </p>
        <p>
          {i18n("settings.inventory.general.serialLotNumbering.explanation.5")}
        </p>
      </>
    ),
  },
];
