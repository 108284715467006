import { TableSortLabel } from "@mui/material";

import { TableCell } from "components/TableCell";

/**
 * @name    SortableColumnHeader
 *
 * @summary a table column header, optionally sortable
 *
 * @param   isSortable (boolean) - should this column be sortable by the user?
 *
 * @param   isSorted (boolean) - is this column currently being sorted by?
 *
 * @param   direction (string, "asc" or "desc") - is this column currently
 *          sorted ascending or descending; only valid when isSorted is true
 *

 * @param   onClick (function) - function that will be called when the user
 *          clicks on this column header; signature: function(sortName: string),
 *          where sortName is the sortName prop passed to this component
 *
 * @param   sortName (string) - string to pass back to the calling component
 *          via the onClick function
 */
export function SortableColumnHeader(props) {
  const {
    isSortable,
    activeSortName,
    direction,
    maxWidth,
    minWidth,
    align,
    label,
    onClick,
    sortName,
    backgroundColor,
  } = props;

  return (
    <TableCell
      align={align}
      sx={{
        whiteSpace: "normal",
        position: "sticky",
        top: "0px",
        maxWidth,
        minWidth,
        lineHeight: "18px",
        backgroundColor,
      }}
    >
      {isSortable ? (
        <TableSortLabel
          sx={{
            "& .MuiTableSortLabel-icon": { paddingLeft: "0" },
          }}
          active={activeSortName === sortName}
          direction={direction}
          onClick={() => onClick(sortName)}
        >
          {label}
        </TableSortLabel>
      ) : (
        <span>{label}</span>
      )}
    </TableCell>
  );
}
