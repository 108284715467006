export function afterGet(record) {
  const newRecord = {
    ...record,
    salesOrderNumber: {
      prefix: record.prefixSalesOrder,
      nextNumber: record.nextSONumber,
    },
  };

  delete newRecord.prefixSalesOrder;
  delete newRecord.nextSalesOrderNumber;

  return newRecord;
}
