import { useSelector } from "react-redux";

import {
  CalendarMonth,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { Button, Box, Typography, Chip } from "@mui/material";

import { GroupingBracket } from "components/utility/GroupingBracket";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";
import { formatSchedule } from "services/utility/reports";

import { ReorderReportActions } from "views/Reports/jsonReports/ReorderReportActions";
import { CUSTOM_REPORT, STANDARD_REPORT } from "views/Reports/reportConstants";

export function Widgets(props) {
  const {
    reportCategory,
    setEmailDialogOpen,
    setDownloadDialogOpen,
    retrieve,
    retrievalDisabled,
    requiredFiltersNotSetMsg,
    settingsPanelOpen,
    setSettingsPanelOpen,
    settingsValuesChanged,
    save,
    setSaveAsCustomDialogOpen,
    name,
    setCustomName,
    setRenameCustomDialogOpen,
    setScheduleDialogOpen,
    setUnscheduleDialogOpen,
    scheduleSaved,
    schedule,
    settings,
    checkedIds,
    showReorderReportActions,
    ...unexpected
  } = props;
  checkForUnexpectedProps("Widgets", unexpected);

  const isLoading = useSelector((state) => state.loading.list);

  return (
    <>
      <Box
        id="full-widget-bar"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: "4.5rem",
          pt: "1.0rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "4.5rem",
          }}
        >
          {/* retrieval stuff */}
          <GroupingBracket
            disabled={retrievalDisabled || isLoading}
            requiredFiltersNotSetMsg={requiredFiltersNotSetMsg}
            hoverMsg={
              retrievalDisabled
                ? requiredFiltersNotSetMsg
                : i18n("reports.WidgetText.RetrievingReport")
            }
            heading={i18n("reports.Action.Retrieve")}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                variant="contained"
                sx={{ height: "2.0rem", width: "4.5rem" }}
                disabled={retrievalDisabled || isLoading}
                onClick={() => {
                  retrieve("Display");
                  setSettingsPanelOpen(false);
                }}
                data-testing="displayReport"
              >
                {i18n("global.Display")}
              </Button>

              <Button
                disabled={retrievalDisabled || isLoading}
                onClick={() => setEmailDialogOpen(true)}
              >
                {i18n("global.Email")}
              </Button>

              <Button
                disabled={retrievalDisabled || isLoading}
                onClick={() => setDownloadDialogOpen(true)}
              >
                {i18n("global.Download")}
              </Button>
            </Box>
          </GroupingBracket>
          {/* Schedule */}
          {scheduleSaved && (
            <GroupingBracket heading="Schedule" sx={{ ml: "1.0em" }}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Chip
                  label={formatSchedule(schedule)}
                  size="small"
                  sx={{
                    marginBottom: "0.15rem",
                  }}
                  icon={<CalendarMonth />}
                />
                <Button onClick={() => setScheduleDialogOpen(true)}>
                  {i18n("reports.Action.ChangeSchedule")}
                </Button>
                <Button onClick={() => setUnscheduleDialogOpen(true)}>
                  {i18n("reports.Action.Unschedule")}
                </Button>
              </Box>
            </GroupingBracket>
          )}
          {!scheduleSaved && (
            <Button
              sx={{ lineHeight: "1", ml: "0.5rem" }}
              onClick={() => setScheduleDialogOpen(true)}
            >
              {i18n("reports.Action.Schedule")}
            </Button>
          )}
          {/* Save/save as */}
          {settingsValuesChanged && reportCategory === STANDARD_REPORT && (
            <Button
              sx={{ lineHeight: "1" }}
              onClick={() => setSaveAsCustomDialogOpen(true)}
            >
              <>{i18n("reports.Action.SaveAsCustom")}</>
            </Button>
          )}
          {reportCategory === CUSTOM_REPORT && (
            <>
              {settingsValuesChanged && (
                <Button sx={{ lineHeight: "1" }} onClick={save}>
                  {i18n("reports.Action.SaveSettings")}
                </Button>
              )}
              <Button
                sx={{ lineHeight: "1" }}
                onClick={() => {
                  setCustomName(name);
                  setSaveAsCustomDialogOpen(true);
                }}
              >
                {i18n("reports.Action.SaveAsCustom")}
              </Button>
            </>
          )}
          {showReorderReportActions && (
            <ReorderReportActions settings={settings} checkedIds={checkedIds} />
          )}
        </Box>

        {/* Open/close settings panel */}
        <Box
          display="flex"
          onClick={() => setSettingsPanelOpen(!settingsPanelOpen)}
          sx={{ cursor: "pointer" }}
        >
          <Typography
            color="primary"
            sx={{ fontWeight: "Medium", ml: "0.5rem" }}
          >
            {i18n("global.Settings")}
          </Typography>
          {settingsPanelOpen ? (
            <KeyboardArrowUp color="primary" />
          ) : (
            <KeyboardArrowDown color="primary" />
          )}
        </Box>
      </Box>
    </>
  );
}
