// we use the Big library's methods (plus, eq, etc.) throughout the code;
// therefore, if we switch to another library, we might have to implement
// shims here; for example, if the new library used "equals" instead of
// "eq", we would have to define an "eq" method here and have it call
// "equals" in the new library
import Big from "big.js";

import { handleProgramError } from "services/utility/errors";

import globalState from "globalState/globalState";

function booleanError(functionName) {
  return new Error(
    `Decimal | other argument to ${functionName} must be Decimal`
  );
}

export class Decimal extends Big {
  constructor(num) {
    if (
      typeof num !== "number" &&
      typeof num !== "string" &&
      !(num instanceof Big)
    ) {
      const errorText = `Decimal | invalid value passed to constructor: ${num} (${typeof num}))`;
      handleProgramError(new Error(errorText));
      return;
    }
    super(num);
  }

  plus(other) {
    return new Decimal(super.plus(other));
  }

  minus(other) {
    return new Decimal(super.minus(other));
  }

  times(other) {
    if (other instanceof Money) {
      return new Money(super.times(other));
    } else {
      return new Decimal(super.times(other));
    }
  }

  div(other) {
    if (other instanceof Money) {
      handleProgramError(
        new Error("Decimal | Cannot divide a Decimal by a Money")
      );
    } else {
      return new Decimal(super.div(other));
    }
  }

  eq(other) {
    if (other instanceof Decimal) {
      return super.eq(other);
    } else {
      handleProgramError(booleanError("eq"));
    }
  }

  lt(other) {
    if (other instanceof Decimal) {
      return super.lt(other);
    } else {
      handleProgramError(booleanError("lt"));
    }
  }

  lte(other) {
    if (other instanceof Decimal) {
      return super.lte(other);
    } else {
      handleProgramError(booleanError("lte"));
    }
  }

  gt(other) {
    if (other instanceof Decimal) {
      return super.gt(other);
    } else {
      handleProgramError(booleanError("gt"));
    }
  }

  gte(other) {
    if (other instanceof Decimal) {
      return super.gte(other);
    } else {
      handleProgramError(booleanError("gte"));
    }
  }

  round(decimalPlaces, roundingMode) {
    return new Decimal(super.round(decimalPlaces, roundingMode));
  }

  abs() {
    return new Decimal(super.abs());
  }

  static get ZERO() {
    return new Decimal(0);
  }
}

const currencyError = "Money | Currency value must be string";
const noCurrencyError = "Money | Money values must have a currency";
const mathError = "Money | Math on different currencies not supported";
const divError = "Money | Can only divide by Decimal or Money values";
const timesError = "Money | Money can only be multiplied by Decimal values";

export class Money extends Decimal {
  constructor(num, currency) {
    if (
      typeof num !== "number" &&
      typeof num !== "string" &&
      !(num instanceof Big)
    ) {
      handleProgramError(
        booleanError(
          `Money | invalid value passed to constructor: ${num} (${typeof num})`
        )
      );
    }
    if (currency && typeof currency !== "string") {
      handleProgramError(new Error(currencyError));
    }
    super(num);
    const homeCurrency =
      globalState.getState().userCompanySettings.settings.homeCurrency;
    this.currency = currency || homeCurrency.code;
    if (!this.currency) {
      handleProgramError(new Error(noCurrencyError));
    }
  }
  plus(other) {
    if (this.isSameCurrency(other)) {
      return new Money(super.plus(other));
    } else {
      handleProgramError(booleanError(new Error(mathError)));
    }
  }
  minus(other) {
    if (this.isSameCurrency(other)) {
      return new Money(super.minus(other));
    } else {
      handleProgramError(booleanError(new Error(mathError)));
    }
  }
  times(other) {
    if (!(other instanceof Decimal)) {
      handleProgramError(booleanError(new Error(timesError)));
    }
    return new Money(super.times(other));
  }
  div(other) {
    if (!this.isMoneyOrDecimal(other)) {
      handleProgramError(booleanError(new Error(divError)));
    }
    if (!this.isSameCurrency(other)) {
      handleProgramError(booleanError(new Error(mathError)));
    }
    const thisValue = new Decimal(this);
    const otherValue = new Decimal(other);
    const quotient = thisValue.div(otherValue);
    if (other instanceof Money) {
      return new Decimal(quotient);
    } else {
      return new Money(quotient, this.currency);
    }
  }
  eq(other) {
    if (this.isSameCurrency(other)) {
      return super.eq(other);
    } else {
      handleProgramError(booleanError(new Error(mathError)));
    }
  }
  lt(other) {
    if (this.isSameCurrency(other)) {
      return super.lt(other);
    } else {
      handleProgramError(booleanError(new Error(mathError)));
    }
  }
  lte(other) {
    if (this.isSameCurrency(other)) {
      return super.lte(other);
    } else {
      handleProgramError(booleanError(new Error(mathError)));
    }
  }
  gt(other) {
    if (this.isSameCurrency(other)) {
      return super.gt(other);
    } else {
      handleProgramError(booleanError(new Error(mathError)));
    }
  }
  gte(other) {
    if (this.isSameCurrency(other)) {
      return super.gte(other);
    } else {
      handleProgramError(booleanError(new Error(mathError)));
    }
  }
  round(decimalPlaces, roundingMode) {
    return new Money(super.round(decimalPlaces, roundingMode));
  }

  // TODO: when the private method syntax is supported, make the following a private method (by
  // prefixing the method name with "#")
  isSameCurrency(other) {
    return (
      other.constructor.name !== "Money" || other.currency === this.currency
    );
  }
  isMoneyOrDecimal(other) {
    return other instanceof Money || other instanceof Decimal;
  }

  static get ZERO() {
    return new Money(0);
  }
}
