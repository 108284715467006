import { Layout } from "Layout";

import { ListPage } from "components/ListPage/ListPage";

import { i18n } from "services/i18nService";

import { usePrivileges } from "hooks/usePrivileges";

import { Forbidden } from "Forbidden";
import { USER_PRIVILEGE, OBJECT_TYPES } from "appConstants";

const objectType = OBJECT_TYPES.FS_PAYMENT.fullString;

export function FsPaymentList() {
  const { hasPrivilegesOrIsAdmin } = usePrivileges();

  const allowList = hasPrivilegesOrIsAdmin([USER_PRIVILEGE.processPayment]);

  return (
    <Layout pageTitle={i18n(`objectType.${objectType}.SentencePlural`)}>
      {allowList ? <ListPage objectType={objectType} /> : <Forbidden />}
    </Layout>
  );
}
