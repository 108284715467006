export const SETTINGS = [
  { apiName: "useQBInvoiceNumber", component: "FrmCheckbox" },
  {
    apiName: "invoiceNumber",
    component: "FrmTransactionNumber",
    transactionType: "invoice",
    hasInputLabel: true,
  },
  { apiName: "replyEmailInvoice", component: "FrmEmail", maxLength: 255 },
  { apiName: "invoiceEmail", component: "FrmResizeTextField" },
  { apiName: "invoiceFooter", component: "FrmResizeTextField" },
  { apiName: "defaultCCInvoice", component: "FrmEmail", maxLength: 255 },
  { apiName: "useShippingCostFromShipment", component: "FrmCheckbox" },
];
