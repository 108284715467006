// takes as input an object, like {a: "123", b: 5}, and returns
// a string in URL query parameters format, like "?a=123&b=5"
export function objectToQueryParams(queryObject = {}) {
  return Object.keys(queryObject)
    .filter(
      (key) => queryObject[key] !== undefined && queryObject[key] !== null
    )
    .map((key) => key + "=" + encodeURIComponent(queryObject[key]))
    .join("&");
}

export function removeFalseyValues(object) {
  const newObject = { ...object };
  Object.keys(newObject).forEach((key) => {
    if (!newObject[key]) {
      delete newObject[key];
    }
  });
  return newObject;
}
