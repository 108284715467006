import { Grid } from "@mui/material";

import { FrmTextField } from "components/formFields/FrmTextField";

import { i18n } from "services/i18nService";
import { formatContact } from "services/utility/formatting";

export function LocationAddress(props) {
  const {
    value,
    handleInputFieldChange,
    addressType,
    disableContactEditing,
    dataIndex,
  } = props;

  function handleAddressChange(name, newValue) {
    const address = {
      ...value,
      address: { ...value.address, [name]: newValue },
    };
    handleInputFieldChange(addressType, address, dataIndex);
  }

  function handleContactChange(name, newValue) {
    const address = { ...value, [name]: newValue };
    handleInputFieldChange(addressType, address, dataIndex);
  }

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={6}>
        <FrmTextField
          name="company"
          label={i18n("global.Company")}
          maxLength={255}
          onValueBlur={handleContactChange}
          value={value?.company}
          disabled={disableContactEditing}
        />
        <FrmTextField
          name="contact"
          label={i18n("global.Contact")}
          maxLength={255}
          value={formatContact(value?.contact)}
          onValueBlur={handleContactChange}
          disabled={disableContactEditing}
        />
        <FrmTextField
          name="phone"
          label={i18n("global.Phone")}
          maxLength={255}
          value={value?.phone}
          onValueBlur={handleContactChange}
          disabled={disableContactEditing}
        />
        <FrmTextField
          name="email"
          label={i18n("global.Email")}
          maxLength={255}
          value={value?.email}
          onValueBlur={handleContactChange}
          disabled={disableContactEditing}
        />
      </Grid>
      <Grid item xs={6}>
        {[1, 2, 3, 4, 5].map((i) => (
          <FrmTextField
            key={"line" + i}
            name={"line" + i}
            label={`Line ${i}`}
            maxLength={400}
            onValueBlur={handleAddressChange}
            value={value?.address ? value.address["line" + i] : ""}
            dataTesting={"line" + i}
          />
        ))}
        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <FrmTextField
              name="city"
              label={i18n("global.City")}
              maxLength={255}
              onValueBlur={handleAddressChange}
              value={value?.address?.city}
            />
          </Grid>
          <Grid item xs={6}>
            <FrmTextField
              name="stateProvince"
              label={i18n("global.State/Province")}
              maxLength={255}
              onValueBlur={handleAddressChange}
              value={value?.address?.stateProvince}
            />
          </Grid>
          <Grid item xs={6}>
            <FrmTextField
              name="postalCode"
              label="Postal code"
              maxLength={30}
              onValueBlur={handleAddressChange}
              value={value?.address?.postalCode}
            />
          </Grid>
          <Grid item xs={6}>
            <FrmTextField
              name="country"
              label={i18n("global.Country")}
              maxLength={255}
              onValueBlur={handleAddressChange}
              value={value?.address?.country}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
