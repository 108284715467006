import { FilterSelectField } from "components/formFields/FilterSelectField";

import { useRelated } from "hooks/useRelated";

export function ClassFilter(props) {
  const { value, onValueChange, name, label } = props;

  const classes = useRelated("class");

  return (
    <FilterSelectField
      name={name}
      label={label}
      options={classes}
      value={value}
      onValueChange={onValueChange}
    />
  );
}
