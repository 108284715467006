import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Layout } from "Layout";

import { CustomFields } from "components/CustomFields/CustomFields";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { LineItemTotals } from "components/formFields/Totals/LineItemTotals";
import {
  FrmNumber,
  FrmLocation,
  FrmAccount,
  FrmComment,
  FrmDepartment,
} from "components/formFields/frm";
import { FrmSignature } from "components/formFields/frm/FrmSignature";
import { DateTimePicker } from "components/html/Input/DateTimePicker";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";
import { WarningModal } from "components/utility/WarningModal";

import { i18n } from "services/i18nService";
import {
  updateTotals,
  ZERO_TOTALS,
} from "services/sosInventoryService/adjustment/updateTotals";
import { setPageDirty } from "services/utility/edit";

import { useCustomFieldDefinitions } from "hooks/useCustomFieldDefinitions";
import { useErrors } from "hooks/useErrors";
import { useItems } from "hooks/useItems";
import { useLineHandler } from "hooks/useLineHandler";

import { LineItems } from "views/Adjustments/Adjustment/LineItems";
import {
  getTransaction,
  updateTransaction,
} from "views/Adjustments/Adjustment/controller";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { theme } from "SosTheme";
import { OBJECT_TYPES, ITEM_QUICKLIST_CASES } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.ADJUSTMENT.fullString;

export function Adjustment(props) {
  const { id, onClose, newFromId, newFromObjectType } = props;

  // TRANSACTION STATE
  const [record, setRecord] = useState();
  const [lines, lineHandler] = useLineHandler();
  const [relatedRecords, setRelatedRecords] = useState({});
  const CURRENT_STATE = { record, lines, relatedRecords };

  // UI STATE
  const { errors, setErrors, isInError } = useErrors();
  const [totals, setTotals] = useState(ZERO_TOTALS);
  const [clearLines, setClearLines] = useState(false);
  const transactionCustomFieldDefs = useCustomFieldDefinitions(OBJECT_TYPE);
  const loadingState = useSelector((state) => state.loading.editModal);
  const [itemAdded, setItemAdded] = useState(0);

  // RELATED RECORD LISTS
  const { items, itemsCount } = useItems(ITEM_QUICKLIST_CASES.SALES, itemAdded);

  // SETTINGS
  const departmentEnabled = useSelector(
    (state) => state.userCompanySettings.settings.departmentEnabled
  );

  // OTHER SETUP
  const dispatch = useDispatch();

  // UPDATERS FOR CONTROLLER TO UPDATE STATE
  const UPDATERS = useMemo(
    () => ({
      setRecord,
      lineHandler,
      setRelatedRecords,
    }),
    [setRecord, lineHandler]
  );

  // LOAD INITIAL TRANSACTION STATE
  useEffect(() => {
    async function _getTransaction() {
      dispatch(editModalLoadingIndicatorOn());
      await getTransaction(
        id,
        newFromId,
        newFromObjectType,
        UPDATERS,
        transactionCustomFieldDefs
      );
      dispatch(editModalLoadingIndicatorOff());
    }
    if (transactionCustomFieldDefs) {
      _getTransaction();
    }
  }, [
    dispatch,
    id,
    newFromId,
    newFromObjectType,
    lineHandler,
    UPDATERS,
    transactionCustomFieldDefs,
  ]);

  // HANDLE ALL USER INPUT
  function handleInputFieldChange(field, newValue) {
    setPageDirty();
    updateTransaction(field, newValue, CURRENT_STATE, UPDATERS);
  }

  useEffect(() => updateTotals(lines, setTotals), [lines]);

  function doClearLines() {
    setPageDirty();
    lineHandler({ type: "clear", blankLine: EMPTY_LINE_ITEM.adjustment });
    setClearLines(false);
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        relatedRecords={relatedRecords}
        lines={lines}
        setErrors={setErrors}
        errors={errors}
        text={record?.number}
        handleClose={onClose}
      />
      {loadingState && <Loading />}
      <ObjectEditDialogContent>
        <Layout
          pageTitle={
            id
              ? `${i18n("objectType.adjustment.Sentence")} ${
                  record?.number || ""
                }`
              : i18n("objectType.adjustment.New")
          }
        >
          <div id="B" style={{ margin: theme.spacing(2) }}>
            <FormErrors errors={errors} setErrors={setErrors} />
            {record ? (
              <>
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }}>
                    <DateTimePicker
                      name="date"
                      value={record.date}
                      label={i18n("frmLabel.DateTime")}
                      onValueChange={handleInputFieldChange}
                      error={isInError("date")}
                      dataTesting="date"
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmNumber
                      name="number"
                      recordId={record.id}
                      label={i18n("adjustment.labelNumber")}
                      value={record.number}
                      onValueChange={handleInputFieldChange}
                      error={isInError("number")}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmLocation
                      value={record.location}
                      onValueChange={handleInputFieldChange}
                      width="25rem"
                      error={isInError("location")}
                    />
                  </div>
                </FrmFieldGrid>
                <VSpace space={1} />
                <LineItems
                  record={record}
                  objectType={OBJECT_TYPE}
                  relatedRecords={relatedRecords}
                  lines={lines}
                  items={items}
                  totals={totals}
                  itemsCount={itemsCount}
                  addItem={() => setItemAdded((prev) => prev + 1)}
                  lineHandler={lineHandler}
                  lineItemsErrors={errors.line}
                  setErrors={setErrors}
                  setClearLines={setClearLines}
                />
                <LineItemTotals
                  value={totals.valueDiff}
                  label={i18n("global.CostBasisAdjustment")}
                  objectType={OBJECT_TYPE}
                />
                <VSpace space={2} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <FrmAccount
                    name="account"
                    label={i18n("frmLabel.Account")}
                    onValueChange={handleInputFieldChange}
                    value={record.account}
                  />
                  {departmentEnabled && (
                    <div style={{ gridColumn: "2 / 2" }}>
                      <FrmDepartment
                        maxWidth="20rem"
                        value={record.department}
                        onValueChange={handleInputFieldChange}
                      />
                    </div>
                  )}
                  <div style={{ gridColumn: "1 / 2", maxWidth: "70ch" }}>
                    <FrmComment
                      value={record.comment}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2", maxWidth: "70ch" }}>
                    <FrmSignature
                      objectType={OBJECT_TYPE}
                      id={record.id}
                      hasSignature={record.hasSignature}
                    />
                  </div>
                </FrmFieldGrid>

                <CustomFields
                  customFieldDefinitions={transactionCustomFieldDefs}
                  customFields={record.customFields}
                  onValueChange={handleInputFieldChange}
                  setErrors={setErrors}
                />
              </>
            ) : (
              !isInError("record") && (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )
            )}
            <VSpace space={4} />
          </div>
          {record && (
            <>
              <WarningModal
                title={i18n("global.ClearLines")}
                message={i18n("global.clearLinesMessage")}
                open={clearLines}
                onOk={doClearLines}
                onClose={() => setClearLines(false)}
              />
            </>
          )}
        </Layout>
      </ObjectEditDialogContent>
    </>
  );
}
