export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixRental: record.rentalNumber.prefix,
    nextRentalNumber: record.rentalNumber.nextNumber,
  };

  delete newRecord.rentalNumber;

  return newRecord;
}
