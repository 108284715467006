import SvgIcon from "@mui/material/SvgIcon";

import { theme } from "SosTheme";

export function CalendarIcon(props) {
  const { highlighted, ...otherProps } = props;
  const color = highlighted ? theme.palette.accent : theme.palette.primaryText;

  return (
    <SvgIcon viewBox="0 0 32 32" fill={color} {...otherProps}>
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g fillRule="nonzero">
          <path d="M23.6684138,2.48275862 C23.4118621,1.22537931 22.297931,0.275862069 20.9655172,0.275862069 C20.6604138,0.275862069 20.4137931,0.522482759 20.4137931,0.827586207 C20.4137931,1.13268966 20.6604138,1.37931034 20.9655172,1.37931034 C21.878069,1.37931034 22.6206897,2.12193103 22.6206897,3.03448276 C22.6206897,3.94703448 21.878069,4.68965517 20.9655172,4.68965517 C20.6604138,4.68965517 20.4137931,4.93627586 20.4137931,5.24137931 C20.4137931,5.54648276 20.6604138,5.79310345 20.9655172,5.79310345 C22.2973793,5.79310345 23.4118621,4.84358621 23.6684138,3.5862069 L30.8965517,3.5862069 L30.8965517,8.55172414 L1.10344828,8.55172414 L1.10344828,3.5862069 L8.82758621,3.5862069 L10.4827586,3.5862069 C10.7878621,3.5862069 11.0344828,3.33958621 11.0344828,3.03448276 C11.0344828,2.72937931 10.7878621,2.48275862 10.4827586,2.48275862 L9.48082759,2.48275862 C9.70924138,1.84165517 10.3161379,1.37931034 11.0344828,1.37931034 C11.9470345,1.37931034 12.6896552,2.12193103 12.6896552,3.03448276 C12.6896552,3.94703448 11.9470345,4.68965517 11.0344828,4.68965517 C10.7293793,4.68965517 10.4827586,4.93627586 10.4827586,5.24137931 C10.4827586,5.54648276 10.7293793,5.79310345 11.0344828,5.79310345 C12.5555862,5.79310345 13.7931034,4.55558621 13.7931034,3.03448276 C13.7931034,1.51337931 12.5555862,0.275862069 11.0344828,0.275862069 C9.70262069,0.275862069 8.58813793,1.22537931 8.33158621,2.48275862 L0,2.48275862 L0,9.65517241 L0,31.7241379 L32,31.7241379 L32,9.65517241 L32,2.48275862 L23.6684138,2.48275862 Z M30.8965517,30.6206897 L1.10344828,30.6206897 L1.10344828,9.65517241 L30.8965517,9.65517241 L30.8965517,30.6206897 Z"></path>
          <path d="M14.3448276,1.37931034 C15.2573793,1.37931034 16,2.12193103 16,3.03448276 C16,3.94703448 15.2573793,4.68965517 14.3448276,4.68965517 C14.0397241,4.68965517 13.7931034,4.93627586 13.7931034,5.24137931 C13.7931034,5.54648276 14.0397241,5.79310345 14.3448276,5.79310345 C15.865931,5.79310345 17.1034483,4.55558621 17.1034483,3.03448276 C17.1034483,1.51337931 15.865931,0.275862069 14.3448276,0.275862069 C14.0397241,0.275862069 13.7931034,0.522482759 13.7931034,0.827586207 C13.7931034,1.13268966 14.0397241,1.37931034 14.3448276,1.37931034 Z"></path>
          <path d="M17.6551724,1.37931034 C18.5677241,1.37931034 19.3103448,2.12193103 19.3103448,3.03448276 C19.3103448,3.94703448 18.5677241,4.68965517 17.6551724,4.68965517 C17.350069,4.68965517 17.1034483,4.93627586 17.1034483,5.24137931 C17.1034483,5.54648276 17.350069,5.79310345 17.6551724,5.79310345 C19.1762759,5.79310345 20.4137931,4.55558621 20.4137931,3.03448276 C20.4137931,1.51337931 19.1762759,0.275862069 17.6551724,0.275862069 C17.350069,0.275862069 17.1034483,0.522482759 17.1034483,0.827586207 C17.1034483,1.13268966 17.350069,1.37931034 17.6551724,1.37931034 Z"></path>
          <circle cx="12.137931" cy="13.5172414" r="1"></circle>
          <circle cx="16" cy="13.5172414" r="1"></circle>
          <circle cx="19.862069" cy="13.5172414" r="1"></circle>
          <circle cx="23.7241379" cy="13.5172414" r="1"></circle>
          <circle cx="27.5862069" cy="13.5172414" r="1"></circle>
          <circle cx="4.4137931" cy="17.9310345" r="1"></circle>
          <circle cx="8.27586207" cy="17.9310345" r="1"></circle>
          <circle cx="12.137931" cy="17.9310345" r="1"></circle>
          <circle cx="16" cy="17.9310345" r="1"></circle>
          <circle cx="19.862069" cy="17.9310345" r="1"></circle>
          <circle cx="23.7241379" cy="17.9310345" r="1"></circle>
          <circle cx="27.5862069" cy="17.9310345" r="1"></circle>
          <circle cx="4.4137931" cy="21.7931034" r="1"></circle>
          <circle cx="8.27586207" cy="21.7931034" r="1"></circle>
          <circle cx="12.137931" cy="21.7931034" r="1"></circle>
          <circle cx="16" cy="21.7931034" r="1"></circle>
          <circle cx="19.862069" cy="21.7931034" r="1"></circle>
          <circle cx="23.7241379" cy="21.7931034" r="1"></circle>
          <circle cx="27.5862069" cy="21.7931034" r="1"></circle>
          <circle cx="4.4137931" cy="26.2068966" r="1"></circle>
          <circle cx="8.27586207" cy="26.2068966" r="1"></circle>
          <circle cx="12.137931" cy="26.2068966" r="1"></circle>
          <circle cx="16" cy="26.2068966" r="1"></circle>
          <circle cx="19.862069" cy="26.2068966" r="1"></circle>
        </g>
      </g>
    </SvgIcon>
  );
}
