import { useSelector } from "react-redux";

import { LineItemNumericCalculatorField } from "components/formFields/LineItemNumericCalculatorField";
import { LineTableCell } from "components/formFields/LineTableCell";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineDiscount(props) {
  const { percentDiscount, onValueChange, objectType, readOnly } = props;
  const lineItemDiscountsEnabled = useSelector(
    (state) => state.userCompanySettings.settings.lineItemDiscountsEnabled
  );

  if (!lineItemDiscountsEnabled) {
    return null;
  }

  return (
    <LineTableCell sx={{ minWidth: "7rem" }}>
      <LineItemNumericCalculatorField
        name="percentdiscount"
        value={percentDiscount || ""}
        onValueChange={onValueChange}
        metadata={LINE_ITEM_METADATA[objectType].percentdiscount}
        readOnly={readOnly}
        dataTesting="linePercentDiscount"
      />
    </LineTableCell>
  );
}
