// returns an array of serial numbers
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

import { i18n } from "services/i18nService";

export function Upload(props) {
  const { uploadOpen, setUploadOpen, setSerials } = props;

  function done() {
    setUploadOpen(false);
  }

  function process(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = processUploadedFile;
    reader.readAsText(file);
  }

  function processUploadedFile(e) {
    const serials = e.target.result.split("\n");
    const validSerials = serials
      .filter((serial) => serial)
      .map((serial) => serial.replace(/(\r\n|\n|\r)/gm, ""));
    setSerials(validSerials);
    setUploadOpen(false);
  }

  return (
    <Dialog open={uploadOpen}>
      <DialogTitle>{i18n("serials.UploadSerialNumbers")}</DialogTitle>
      <DialogContent>
        <label>
          <Button component="span" variant="contained">
            <TextField
              variant="outlined"
              type="file"
              onChange={process}
              sx={{ display: "none" }}
            />
            {i18n("serials.ChooseSerialFileAndImport")}
          </Button>
        </label>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={done}>
          {i18n("button.Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
