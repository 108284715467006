// Actual domain logic should be in a domainLogic.js file, at the appropriate
// level in the hierarchy.
import { IN_TRANSACTION } from "appConfig";

import { Decimal } from "classes/DecimalClasses";

import { extractBillingAndShippingFromCustomer } from "services/sosInventoryService/domainLogic";
import { getEmptyRecord } from "services/sosInventoryService/payment/schema";
import { getRecord, getRecordFrom } from "services/sosInventoryService/sosApi";
import {
  copyCustomFieldValues,
  reconcileCustomFields,
  getCustomFieldDefinitions,
} from "services/utility/customFields";
import { getRelatedReferenceObjects } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";
import { formatCardOnFile } from "services/utility/formatting";

import globalState from "globalState/globalState";

import { OBJECT_TYPES, SOS_PAY_CREDIT, SOS_PAY_ACH } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.PAYMENT.fullString;

export async function getTransaction(
  id,
  newFromId,
  newFromObjectType,
  updaters,
  customFieldDefs
) {
  let record, lines, relatedRecords;

  const numLinesToAdd =
    globalState.getState().userCompanySettings.settings.numLinesToAdd;

  if (id || newFromObjectType) {
    if (id) {
      record = await getRecord(OBJECT_TYPE, id);
    } else {
      // it's a "populate from" request
      record = await getRecordFrom(OBJECT_TYPE, newFromObjectType, newFromId);
      delete record.id;
    }

    lines = record.lines;
    updaters.lineHandler({ type: "set", lines });

    record.customFields = reconcileCustomFields(
      customFieldDefs,
      record.customFields
    );

    relatedRecords = await getRelatedReferenceObjects(OBJECT_TYPE, record);
    if (relatedRecords.customer.hasCardOnFile) {
      record.paymentInfo = formatCardOnFile(null, relatedRecords.customer);
      record.storeCustomerToken = true;
      record.hasCardOnFile = true;
    }
    updaters.setRecord(record);
    updaters.setRelatedRecords(relatedRecords);
  } else {
    // new record
    const { settings } = globalState.getState().userCompanySettings;
    const defaultLocation =
      globalState.getState().userCompanySettings.settings.defaultLocation;

    record = {
      ...getEmptyRecord(settings),
      customFields: reconcileCustomFields(customFieldDefs, []),
    };

    lines = Array(numLinesToAdd).fill(EMPTY_LINE_ITEM[OBJECT_TYPE]);
    updaters.lineHandler({ type: "set", lines });

    relatedRecords = {};

    if (defaultLocation) {
      const txnLocation = defaultLocation || null;
      record.location = txnLocation;

      const location = await getRecord(
        "location",
        txnLocation.id,
        IN_TRANSACTION
      );
      record.billing = location;
      relatedRecords = { ...relatedRecords, location };
    }

    updaters.setRelatedRecords(relatedRecords);
    updaters.setRecord(record);
  }
}

export async function updateTransaction(
  field,
  newValue,
  transactionCustomFieldDefs,
  currentState,
  updaters,
  currencies
) {
  const { record, lines, relatedRecords } = currentState;
  if (!record || !lines || !relatedRecords) {
    handleProgramError(new Error("update Transaction | invalid currentState"));
  }

  let newRecord, newLines, newRelatedRecords;

  switch (field) {
    case "billing":
    case "channel":
    case "checkNumber":
    case "comment":
    case "customerMessage":
    case "customerNotes":
    case "customFields":
    case "date":
    case "department":
    case "depositAccount":
    case "storeCustomerToken":
    case "location":
    case "number":
    case "paymentMethod":
    case "shipping":
    case "trackingNumber":
      newRecord = { ...record, [field]: newValue };
      updaters.setRecord(newRecord);
      break;

    case "exchangeRate":
      newRecord = { ...record, [field]: new Decimal(newValue || 0) };
      updaters.setRecord(newRecord);
      break;

    case "accountToken":
      newRecord = {
        ...record,
        [field]: newValue.token,
        paymentInfo: newValue.paymentInfo,
        storeCustomerToken: true,
        hasCardOnFile: true,
        showKeepOnFile: true,
      };
      updaters.setRecord(newRecord);

      break;

    case "customer":
      if (!newValue) {
        newRecord = {
          ...record,
          customer: null,
          accountToken: null,
          paymentInfo: null,
          hasCardOnFile: null,
        };
        updaters.setRecord(newRecord);
        newRelatedRecords = { ...relatedRecords, customer: null };
        break;
      }

      const customerCustomFieldDefs = await getCustomFieldDefinitions(
        "customer"
      );

      // get the newly selected customer record
      const customer = await getRecord("customer", newValue.id, IN_TRANSACTION);

      // be sure there are custom field entries for each defined custom field
      // in the customer record
      const customerCustomFields = reconcileCustomFields(
        customerCustomFieldDefs,
        customer.customFields
      );

      // initialize any transaction custom fields to their matching customer
      // custom field values, if any
      const newTransactionCustomFields = copyCustomFieldValues(
        customerCustomFieldDefs,
        customerCustomFields,
        transactionCustomFieldDefs,
        record.customFields
      );

      // get contact, billing, and shipping info from customer
      const { billing } = extractBillingAndShippingFromCustomer(customer);

      newRecord = {
        ...record,
        customer: newValue,
        customerNotes: customer.notes,
        customFields: newTransactionCustomFields,
        billing,
      };

      const { hasCardOnFile, expMonth, expYear, paymentMethod } = customer;

      if (paymentMethod) {
        newRecord.paymentMethod = paymentMethod;
      } else if (hasCardOnFile) {
        const isCard = expMonth > 0 && expYear > 0;
        newRecord.paymentMethod = isCard
          ? currentState.paymentMethods.find(
              ({ sosPayType }) => sosPayType === SOS_PAY_CREDIT
            )
          : currentState.paymentMethods.find(
              ({ sosPayType }) => sosPayType === SOS_PAY_ACH
            );
      }

      if (hasCardOnFile) {
        newRecord.paymentInfo = formatCardOnFile(null, customer);
        newRecord.hasCardOnFile = true;
        newRecord.storeCustomerToken = true;
      } else {
        newRecord.accountToken = null;
        newRecord.paymentInfo = null;
        newRecord.hasCardOnFile = false;
        newRecord.storeCustomerToken = false;
      }
      updaters.setRecord(newRecord);
      newRelatedRecords = { ...relatedRecords, customer };

      break;

    case "currency":
      updaters.setRecord((prev) => ({
        ...prev,
        currency: newValue.id ? { id: newValue.id, name: newValue.name } : null,
        exchangeRate: newValue.id
          ? currencies.find(({ id }) => id === newValue.id).exchangeRate
          : null,
      }));
      break;

    default:
      handleProgramError(
        new Error(`updateTransaction | unknown field (${field})`)
      );
  }

  if (newLines) {
    updaters.lineHandler({ type: "set", lines: newLines });
  }
  if (newRelatedRecords) {
    updaters.setRelatedRecords(newRelatedRecords);
  }
}
