export function beforeSave(record) {
  const newRecord = {
    ...record,
    syncSOStoQBO: record.syncDirections.syncSOStoQBO,
    syncQBOtoSOS: record.syncDirections.syncQBOtoSOS,
  };

  delete newRecord.syncDirections;

  return newRecord;
}
