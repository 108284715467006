import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";

import { IN_TRANSACTION } from "appConfig";

import { ButtonProgress } from "components/utility/ButtonProgress";
import { FieldHelperText } from "components/utility/FieldHelperText";
import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";
import {
  sendTransactionEmail,
  getRecord,
} from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";
import { getEmailBody } from "services/utility/misc";
import { isOneOrMoreEmailAddresses } from "services/utility/validation";

import { useFormTemplates } from "hooks/useFormTemplates";

import { openAlert } from "globalState/alertSlice";

import { OBJECT_TYPES } from "appConstants";

const REQUIRE_TO_EMAIL = [
  OBJECT_TYPES.ADJUSTMENT.fullString,
  OBJECT_TYPES.BUILD.fullString,
  OBJECT_TYPES.PROCESS.fullString,
  OBJECT_TYPES.TRANSFER.fullString,
  OBJECT_TYPES.WORK_ORDER.fullString,
];

export function EmailDialog(props) {
  const {
    multiple,
    open,
    onClose,
    objectType,
    objectIds,
    transactionNumber,
    formTemplates: externalFormTemplates,
    documents: externalDocuments,
    billing,
    vendorId,
    customerId,
  } = props;

  const dispatch = useDispatch();
  const company = useSelector((state) => state.userCompanySettings.company);
  const formTemplates = useFormTemplates(objectType, externalFormTemplates);
  const [emailInfo, setEmailInfo] = useState({
    to: "",
    subject: "",
    message: getEmailBody(objectType),
    body: "",
    copyMe: false,
    template: { id: "" },
  });
  const [inProgress, setInProgress] = useState(false);
  const [toIsValid, setToIsValid] = useState(false);
  const [toTouched, setToTouched] = useState(false);
  const [documents, setDocuments] = useState(externalDocuments);

  const disableEmailTo = multiple && !REQUIRE_TO_EMAIL.includes(objectType);

  useEffect(() => {
    async function getContactEmail(type, id) {
      try {
        const { email } = await getRecord(type, id, IN_TRANSACTION);
        setEmailInfo((previous) => ({ ...previous, to: email }));
      } catch (e) {
        handleProgramError(e);
      }
    }

    if (
      objectType === OBJECT_TYPES.PURCHASE_ORDER.fullString &&
      billing?.email
    ) {
      setEmailInfo((previous) => ({ ...previous, to: billing.email }));
    } else if (vendorId) {
      getContactEmail(OBJECT_TYPES.VENDOR.fullString, vendorId);
    } else if (customerId) {
      getContactEmail(OBJECT_TYPES.CUSTOMER.fullString, customerId);
    } else {
      setEmailInfo((previous) => ({
        ...previous,
        to: disableEmailTo ? "n/a" : "",
      }));
    }
  }, [vendorId, customerId, objectType, billing, disableEmailTo]);

  useEffect(() => {
    setEmailInfo((previous) => ({
      ...previous,
      subject: multiple
        ? "n/a"
        : `${i18n(
            `objectType.${objectType}.Sentence`
          )} ${transactionNumber} from ${company.name}`,
    }));
    if (disableEmailTo) {
      setToIsValid(true);
    }
  }, [objectType, transactionNumber, company, disableEmailTo, multiple]);

  useEffect(() => {
    setToIsValid(
      (multiple && !REQUIRE_TO_EMAIL.includes(objectType)) ||
        isOneOrMoreEmailAddresses(emailInfo.to)
    );
  }, [emailInfo.to, multiple, objectType]);

  useEffect(() => {
    if (formTemplates?.length && !emailInfo.template.id) {
      setEmailInfo((previous) => ({
        ...previous,
        template: { id: formTemplates[0].id },
      }));
    }
  }, [emailInfo.template?.id, formTemplates]);

  function onChange(e) {
    const { id, value } = e.target;
    setEmailInfo((previous) => ({ ...previous, [id]: value }));
  }

  function handleCopyMeChange() {
    setEmailInfo((prev) => ({ ...prev, copyMe: !prev.copyMe }));
  }

  function handleTemplateChange(e) {
    setEmailInfo({ ...emailInfo, template: { id: e.target.value } });
  }

  async function send() {
    setInProgress(true);
    const attachments = documents?.reduce(
      (acc, document) => (!document.dontSend ? acc + `${document.id},` : acc),
      ""
    );
    const response = await sendTransactionEmail(
      objectType,
      objectIds,
      emailInfo.template.id,
      emailInfo.to,
      emailInfo.subject,
      emailInfo.message,
      attachments,
      emailInfo.copyMe
    );
    setInProgress(false);
    if (response.success) {
      onClose();
      dispatch(
        openAlert({ type: "success", message: i18n("alert.SuccessSent") })
      );
    } else {
      onClose();
      dispatch(
        openAlert({ type: "error", message: i18n("alert.ErrorGeneral") })
      );
    }
  }

  function handleCheck(e) {
    const id = e.target.name;
    const isChecked = e.target.checked;
    const newDocuments = documents.map((document) => {
      if (document.id === +id) {
        document.dontSend = !isChecked;
      }
      return document;
    });
    setDocuments(newDocuments);
  }

  if (!formTemplates || !formTemplates.length) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{ inset: "0 0 10%" }}
    >
      <DialogTitle>{i18n("saveAndSend.Title")}</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center">
          <TextField
            disabled={disableEmailTo}
            id="to"
            label={i18n("saveAndSend.To")}
            type="email"
            required
            onChange={onChange}
            onBlur={() => setToTouched(true)}
            value={emailInfo.to || ""}
            variant="outlined"
            margin="dense"
            fullWidth
            InputLabelProps={{ shrink: true }}
            error={toTouched && !toIsValid}
          />
          <HoverHelp
            title={i18n("hoverHelp.SeparateEmailByComma")}
            sx={{ marginLeft: 0.5 }}
          />
        </Box>
        {multiple && (toTouched || !toIsValid) && (
          <FieldHelperText color="warning.main">
            {i18n("saveAndSend.ToError")}
          </FieldHelperText>
        )}
        <TextField
          disabled={multiple}
          id="subject"
          label={i18n("saveAndSend.Subject")}
          onChange={onChange}
          value={emailInfo.subject}
          variant="outlined"
          margin="dense"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          id="message"
          label={i18n("saveAndSend.Message")}
          onChange={onChange}
          value={emailInfo.message}
          variant="outlined"
          margin="dense"
          fullWidth
          multiline
          InputLabelProps={{ shrink: true }}
        />
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={emailInfo.copyMe}
                onChange={handleCopyMeChange}
              />
            }
            label={i18n("saveAndSend.CopyMe")}
            labelPlacement="end"
          />
        </Box>

        <Box display="flex" alignItems="center">
          <TextField
            select
            name="template"
            required
            label={i18n("saveAndSend.Template")}
            margin="dense"
            onChange={handleTemplateChange}
            value={emailInfo.template.id}
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
          >
            {formTemplates.map((el) => (
              <MenuItem key={el.id} value={el.id}>
                {el.name}
              </MenuItem>
            ))}
          </TextField>
          <HoverHelp
            title={i18n("hoverHelp.EmailTemplate")}
            sx={{ marginLeft: 0.5 }}
          />
        </Box>
        <Box>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography>
              {i18n("objectType.attachment.SentencePlural")}:{" "}
            </Typography>
            <HoverHelp
              title={i18n("hoverHelp.EmailAttachment")}
              sx={{ marginLeft: 0.5 }}
            />
          </div>
          <Typography sx={{ paddingTop: "0.25rem" }}>
            {i18n(`objectType.${objectType}.SentencePlural`)} (pdf)
          </Typography>
          {documents?.map((document) => {
            return (
              <div key={document.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="documentCheckbox"
                      onChange={handleCheck}
                      name={`${document.id}`}
                      checked={!document.dontSend}
                    />
                  }
                  sx={{ marginLeft: "0px" }}
                  label={document.fileName}
                />
              </div>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {i18n("button.Cancel")}
        </Button>
        <div style={{ position: "relative" }}>
          <Button
            color="secondary"
            disabled={!toIsValid || inProgress}
            onClick={send}
          >
            {i18n("button.Send")}
          </Button>
          {inProgress && <ButtonProgress />}
        </div>
      </DialogActions>
    </Dialog>
  );
}
