import { OTHER_COST_REFERENCE_FIELDS } from "appConfig";

import { purchasingTransactionBeforeSave } from "services/sosInventoryService/purchasingTransaction/beforeSave";
import { normalizeLinkedTransaction } from "services/sosInventoryService/utility/normalizeLinkedTransaction";
import { normalizeLot } from "services/utility/beforeSave";
import { TYPES } from "services/utility/schema";

import { OBJECT_TYPES } from "appConstants";
import { OTHER_COST_FIELDS } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.ITEM_RECEIPT.fullString;

export function beforeSave(record) {
  const newRecord = purchasingTransactionBeforeSave(OBJECT_TYPE, record);

  // convert the payment field back to a simple string
  newRecord.payment = record.payment.id;

  // for each line
  const newLines = [];
  newRecord.lines.forEach((line) => {
    const newLine = { ...line };

    newLine.lot = normalizeLot(newLine.lot);

    // ensure linkedTransaction is in a proper format for the backend
    newLine.linkedTransaction = normalizeLinkedTransaction(
      line.linkedTransaction
    );

    newLines.push(newLine);
  });

  // remove any other costs with no item
  const materialOtherCosts = record.otherCosts
    ? record.otherCosts.filter((otherCost) => otherCost.item?.id)
    : [];

  // for each other cost
  const newOtherCosts = [];
  materialOtherCosts.forEach((otherCost) => {
    const newOtherCost = { ...otherCost };
    // normalize reference fields; if no referenced record is selected,
    // set the field to null
    OTHER_COST_REFERENCE_FIELDS.itemreceipt.forEach((field) => {
      if (
        otherCost[field] &&
        typeof otherCost[field].id === "string" &&
        otherCost[field].id === ""
      ) {
        newOtherCost[field] = null;
      }
    });

    // convert any number or currency fields that are "" to zero
    const fieldsToCheck = OTHER_COST_FIELDS.itemreceipt[TYPES.number];
    fieldsToCheck.forEach((field) => {
      if (otherCost[field] === "") {
        newOtherCost[field] = 0;
      }
    });
    newOtherCosts.push(newOtherCost);
  });

  // update the record with the new lines
  newRecord.lines = newLines;
  newRecord.otherCosts = newOtherCosts;

  return newRecord;
}
