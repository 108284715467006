import { useParams } from "react-router-dom";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { ListPage } from "components/ListPage/ListPage";

import { i18n } from "services/i18nService";

import { useEditModal } from "hooks/useEditModal";
import { usePrivileges } from "hooks/usePrivileges";

import { Forbidden } from "Forbidden";
import { OBJECT_TYPES } from "appConstants";

const objectType = OBJECT_TYPES.USER.fullString;

export function UserList() {
  const { id } = useParams();
  const { isAdmin } = usePrivileges();

  useEditModal(objectType, id);

  return (
    <Layout pageTitle={i18n(`objectType.${objectType}.SentencePlural`)}>
      {isAdmin ? (
        <Box sx={{ display: "flex", position: "relative" }}>
          <ListPage objectType={objectType} isConfigurationList />
        </Box>
      ) : (
        <Forbidden />
      )}
    </Layout>
  );
}
