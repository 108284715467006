import { Money, Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import {
  extractName,
  formatDocumentInfo,
  formatNumberLinkField,
  formatDateTimeToDate,
  formatMoneyWithAdornments,
  getMoneyTotalWithAdornments,
  truncate,
  extractDescription,
  extractPaymentId,
  formatSync,
} from "services/utility/formatting";
import { extractAppliedToTransaction } from "services/utility/formatting";
import {
  referenceTypeEditStrFunction,
  textTypeEditStrFunction,
  linkedTransactionTypeEditStrFunction,
  decimalTypeEditStrFunction,
} from "services/utility/list";
import { parseHTML } from "services/utility/parsing";
import { starColumn } from "services/utility/schema";
import { TYPES } from "services/utility/schema";

import { DEFAULT_DECIMALS_ROUNDED } from "appConstants";
import { NO_REF_NUMBER_STRING } from "appConstants";

export const REFERENCE_FIELDS = ["customer"];

export function getEmptyRecord(settings) {
  return {
    number: "",
    date: new Date(),
    customer: null,
    location: null,
    paymentMethod: settings.defaultPaymentMethod,
    currency: settings.homeCurrencyRef,
    channel: null,
    department: null,
    depositAccount: settings.defaultDepositAssetAccount,
    billing: {
      company: null,
      contact: null,
      phone: null,
      email: null,
      addressName: null,
      addressType: null,
      address: {
        line1: null,
        line2: null,
        line3: null,
        line4: null,
        line5: null,
        city: null,
        stateProvince: null,
        postalCode: null,
        country: null,
      },
    },
    total: Money.ZERO,
    comment: null,
    customerMessage: null,
    customerNotes: null,
    archived: false,
    hasSignature: false,
    summaryOnly: false,
    customFields: [],
    exchangeRate: new Decimal(1),
    documents: [],
    lines: [],
  };
}

export const LINE_ITEM_METADATA = {
  lineNumber: {},
  class: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  jobWorkcenter: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  description: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  amount: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_ROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  linkedTransaction: {
    type: TYPES.linkedTransaction,
    editStr: linkedTransactionTypeEditStrFunction,
  },
  linkedTransactionRefNumber: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  relatedRecords: { initialValue: {} },
};

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["exchangeRate"],
  MONEY_FIELDS: ["total"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["date"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const columnInfo = [
  starColumn,
  {
    name: "number",
    fixed: true,
    heading: i18n("columns.ReferenceNumber"),
    formatFunc: formatNumberLinkField,
    minWidth: "7em",
    linkField: true,
    sortable: true,
  },
  {
    name: "date",
    heading: i18n("columns.Date"),
    formatFunc: formatDateTimeToDate,
    defaultSort: true,
    sortDirection: "asc",
    sortable: true,
    default: true,
  },
  {
    name: "customer",
    heading: i18n("columns.Customer"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "total",
    heading: i18n("columns.Amount"),
    formatFunc: formatMoneyWithAdornments,
    sortable: true,
    align: "right",
    default: true,
  },
  {
    name: "comment",
    heading: i18n("columns.Comment"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "location",
    heading: i18n("columns.Location"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "paymentMethod",
    heading: i18n("columns.Method"),
    formatFunc: extractName,
  },
  {
    name: "channel",
    heading: i18n("columns.Channel"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "department",
    heading: i18n("columns.Department"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "fsPayment",
    fieldName: "fsPaymentId",
    heading: i18n("columns.SosPmt"),
    formatFunc: extractPaymentId,
    minWidth: "5rem",
  },
  {
    name: "sync",
    fieldName: "syncMessage",
    heading: i18n("columns.Sync"),
    formatFunc: formatSync,
    sortable: true,
  },
];

const columnArray = Object.entries(columnInfo);
const defaultColumnInfo = columnArray.filter((col) => col[1].required);
export const defaultColumns = defaultColumnInfo.map((col) => col[0]);

export const LINE_ACTIONS = [
  ["quickView", "pdf", "email"],
  "template",
  "archive",
  "delete",
  "history",
  "sync",
];
export const BATCH_ACTIONS = [["pdf", "email"], "delete", "archive", "sync"];

export const QUICK_VIEW_CONFIG = {
  honorShipFromAddressOfLocationSetting: true,
  addressConfig: [{ name: "billing", label: i18n("address.Billing") }],
  tableConfig: [{ type: "lines" }],
  headerFields: [
    {
      name: "date",
      formatFunc: formatDateTimeToDate,
      label: i18n("columns.Date"),
    },
    {
      name: "number",
      label: i18n("frmLabel.PaymentNumber"),
      defaultValue: NO_REF_NUMBER_STRING,
    },
  ],
  hideShowItemNames: true,
  fields: [
    {
      name: "customerMessage",
      label: i18n("frmLabel.CustomerMessage"),
    },
  ],
  lines: [
    {
      name: "description",
      formatFunc: extractDescription,
      heading: i18n("columns.Description"),
    },
    {
      name: "amount",
      formatFunc: formatMoneyWithAdornments,
      footerFunc: getMoneyTotalWithAdornments,
      heading: i18n("columns.Amount"),
      align: "right",
    },
    {
      name: "linkedTransaction",
      formatFunc: extractAppliedToTransaction,
      heading: i18n("columns.AppliedTo"),
    },
  ],
};
