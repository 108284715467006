export function afterGet(record) {
  const newRecord = { ...record };

  newRecord.legalAddress = {
    legalAddressLine1: record.legalAddressLine1,
    legalAddressLine2: record.legalAddressLine2,
    legalCity: record.legalCity,
    legalCountry: record.legalCountry,
    legalPostalCode: record.legalPostalCode,
    legalState: record.legalState,
  };

  delete record.legalAddressLine1;
  delete record.legalAddressLine2;
  delete record.legalCity;
  delete record.legalCountry;
  delete record.legalPostalCode;
  delete record.legalState;

  return newRecord;
}
