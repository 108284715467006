import { i18n } from "services/i18nService";

import {
  NONE_GROUP_BY_OPTION,
  IN_STOCK_OPTION,
  INVENTORY_STATUS_OPTIONS,
} from "views/Reports/reportConstants";

import {
  ALL_FILTER_OPTION,
  ITEM_QUICKLIST_CASES,
  OBJECT_TYPES,
} from "appConstants";

const GROUP_BY_OPTIONS = [
  NONE_GROUP_BY_OPTION,
  { id: "Item", name: i18n("objectType.item.Sentence") },
];

export const SerialHistory = {
  userHasSufficientPrivilege: (p) =>
    p.viewInventory || p.viewItems || p.editItems,
  planIncludesReport: (plus) => plus,
  customReportNameFetchList: [
    { objectType: OBJECT_TYPES.ITEM.fullString, customReportKey: "categories" },
    { objectType: OBJECT_TYPES.CUSTOMER.fullString },
    { objectType: OBJECT_TYPES.ITEM.fullString },
    {
      objectType: OBJECT_TYPES.LOCATION.fullString,
      customReportKey: "locations",
    },
  ],
  customFieldObjectTypes: ["serial", "item"],
  columns: [
    { apiName: "Serial Number", default: true },
    { apiName: "Item", default: true },
    { apiName: "Description" },
    { apiName: "Location" },
    { apiName: "Status" },
    { apiName: "Customer" },
    { apiName: "Warranty" },
    { apiName: "Warranty Start" },
    { apiName: "Warranty End" },
  ],
  settings: [
    {
      label: i18n("filter.Location"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: [ALL_FILTER_OPTION],
      includeAllOption: true,
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Item"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
      itemType: ITEM_QUICKLIST_CASES.SERIAL,
    },
    {
      label: i18n("reports.FilterLabel.SerialNumberContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "txnType",
      defaultValue: IN_STOCK_OPTION,
      label: "Status",
      component: "FrmSelectFromObjects",
      options: INVENTORY_STATUS_OPTIONS,
      type: "filter",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: GROUP_BY_OPTIONS,
      type: "option",
    },
    {
      defaultValue: "",
      apiName: "header",
      component: "FrmTextField",
      label: i18n("global.Header"),
      type: "option",
    },
    {
      defaultValue: "",
      apiName: "footer",
      component: "FrmTextField",
      label: i18n("global.Footer"),
      type: "option",
    },
  ],
};
