import ClearIcon from "@mui/icons-material/Clear";
import { Box, Typography, Grid, IconButton } from "@mui/material";

export function FormError(props) {
  const { message, clear } = props;

  return (
    <Box
      p={2}
      my={2}
      bgcolor="tooltipBackground"
      borderLeft={4}
      borderColor="error.main"
    >
      <Grid spacing={1} container alignItems="center">
        <Grid item xs={11}>
          <Typography>{message}</Typography>
        </Grid>
        <Grid sx={{ textAlign: "right" }} item xs={1}>
          <IconButton onClick={clear} size="small">
            <ClearIcon color="error" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
