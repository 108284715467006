import { useState } from "react";

import { ActionButton } from "components/ActionButton";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { ButtonProgress } from "components/utility/ButtonProgress";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function FrmTemplate(props) {
  const { value, onValueChange, onApply, width } = props;
  const templates = useRelated("processtemplate");
  const [isApplyingTemplates, setIsApplyingTemplates] = useState(false);

  function handleOnChange(fieldName, value) {
    onValueChange(
      fieldName,
      value ? templates.find(({ id }) => id === value.id) : value
    );
  }

  async function handleOnApply() {
    setIsApplyingTemplates(true);
    await onApply();
    setIsApplyingTemplates(false);
  }

  return (
    <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
      <div style={{ position: "relative" }}>
        <FrmSelectFromObjects
          label={i18n("frmLabel.Template")}
          value={value}
          name="template"
          onValueChange={handleOnChange}
          options={templates}
          sx={{ width, maxWidth: "100%" }}
        />
        {isApplyingTemplates && <ButtonProgress />}
      </div>
      <ActionButton sx={{ marginTop: "0.3em" }} onClick={handleOnApply}>
        {i18n("global.Apply")}
      </ActionButton>
    </div>
  );
}
