export const SETTINGS = [
  { apiName: "pickTicketHeaderText", component: "FrmTextField" },
  {
    apiName: "pickTicketNumber",
    component: "FrmTransactionNumber",
    transactionType: "pickticket",
    hasInputLabel: true,
  },
  { apiName: "pickTicketFooter", component: "FrmResizeTextField" },
  {
    apiName: "replyEmailPickTicket",
    component: "FrmTextField",
    maxLength: 255,
  },
  { apiName: "pickTicketEmailText", component: "FrmResizeTextField" },
  { apiName: "pickTicketSerialsLots", component: "FrmCheckbox" },
  { apiName: "defaultCCPickTicket", component: "FrmTextField", maxLength: 255 },
];
