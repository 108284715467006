import { Grid } from "@mui/material";

import { CardGrid } from "components/CardGrid";
import { FrmTextField } from "components/formFields/FrmTextField";

import { i18n } from "services/i18nService";
import { formatContact } from "services/utility/formatting";

export function ContactAddress(props) {
  const { value, handleInputFieldChange, addressType } = props;

  function handleAddressChange(name, newValue) {
    handleInputFieldChange(addressType, {
      ...value,
      address: { ...value.address, [name]: newValue },
    });
  }

  function handleContactChange(name, newValue) {
    handleInputFieldChange(addressType, { ...value, [name]: newValue });
  }

  return (
    <CardGrid>
      <FrmTextField
        name="company"
        label={i18n("global.Company")}
        maxLength={255}
        onValueBlur={handleContactChange}
        value={value?.company}
      />
      <FrmTextField
        name="contact"
        label={i18n("global.Contact")}
        maxLength={255}
        onValueBlur={handleContactChange}
        value={formatContact(value?.contact)}
      />
      {[1, 2, 3, 4, 5].map((i) => (
        <FrmTextField
          key={"line" + i}
          name={"line" + i}
          label={`Line ${i}`}
          maxLength={400}
          onValueBlur={handleAddressChange}
          value={value?.address ? value.address["line" + i] : ""}
          dataTesting={"line" + i}
        />
      ))}
      <Grid container direction="row" spacing={1}>
        <Grid item xs={6}>
          <FrmTextField
            name="city"
            label={i18n("global.City")}
            maxLength={255}
            onValueBlur={handleAddressChange}
            value={value?.address?.city}
          />
        </Grid>
        <Grid item xs={6}>
          <FrmTextField
            name="stateProvince"
            label={i18n("global.State/Province")}
            maxLength={255}
            onValueBlur={handleAddressChange}
            value={value?.address?.stateProvince}
          />
        </Grid>
        <Grid item xs={6}>
          <FrmTextField
            name="postalCode"
            label="Postal code"
            maxLength={30}
            onValueBlur={handleAddressChange}
            value={value?.address?.postalCode}
          />
        </Grid>
        <Grid item xs={6}>
          <FrmTextField
            name="country"
            label={i18n("global.Country")}
            maxLength={255}
            onValueBlur={handleAddressChange}
            value={value?.address?.country}
          />
        </Grid>
      </Grid>
      <FrmTextField
        name="phone"
        label={i18n("global.Phone")}
        maxLength={255}
        onValueBlur={handleContactChange}
        value={value?.phone}
      />
      <FrmTextField
        name="email"
        label={i18n("global.Email")}
        maxLength={255}
        onValueBlur={handleContactChange}
        value={value?.email}
      />
    </CardGrid>
  );
}
