import { useState, useEffect } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TextField } from "@mui/material";

import { Decimal } from "classes/DecimalClasses";

import {
  outlinedStyles,
  inputPropStyles,
} from "components/formFields/lineItemFieldStyle";

import { isZero } from "services/utility/misc";

const sx = { marginTop: 0, marginBottom: 0 };

export function RowDecimalField(props) {
  const { dataIndex, name, onValueBlur, value: initialValue, error } = props;
  const [focused, setFocused] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [value, setValue] = useState(initialValue);

  useEffect(() => setValue(initialValue), [initialValue]);

  function onChange(e) {
    setUpdated(true);
    setValue(e.target.value);
  }

  function onBlur() {
    setFocused(false);
    if (updated) {
      onValueBlur(name, value ? new Decimal(value) : null, dataIndex);
      setUpdated(false);
    }
  }

  function getValue() {
    //added to update the input on focus to not show the default zero value
    if (focused && isZero(value) && !updated) {
      return "";
    }
    return value;
  }

  return (
    <TextField
      autoComplete="off"
      value={getValue()}
      variant="outlined"
      onFocus={() => setFocused(true)}
      name={name}
      type="number"
      margin="dense"
      onChange={onChange}
      onBlur={onBlur}
      fullWidth
      error={error}
      inputProps={{ style: { textAlign: "right" } }}
      sx={error ? sx : { ...sx, ...outlinedStyles }}
      InputProps={{ sx: inputPropStyles, style: { borderRadius: "6px" } }}
      SelectProps={{ IconComponent: KeyboardArrowDownIcon }}
      data-testing={name}
    />
  );
}
