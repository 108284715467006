import { Decimal } from "classes/DecimalClasses";

export const LINE_FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["weight", "height", "length", "width"],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const EMPTY_LINE = {
  description: null,
  dimensionUnit: "in",
  freightClass: "50",
  height: new Decimal(0),
  length: new Decimal(0),
  lineId: null,
  lineNumber: null,
  weight: new Decimal(0),
  weightUnit: "lb",
  width: new Decimal(0),
};
