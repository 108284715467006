import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { NEW_RECORD_SKELETON } from "appConfig";

import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { getRecord } from "services/sosInventoryService/sosApi";

import { useErrors } from "hooks/useErrors";
import { useRecord } from "hooks/useRecord";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

export function ContactMessageEdit(props) {
  const { id, onClose, objectType } = props;

  const dispatch = useDispatch();
  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();

  const loadingState = useSelector((state) => state.loading.editModal);

  useEffect(() => {
    async function _getRecord() {
      if (id) {
        dispatch(editModalLoadingIndicatorOn());
        const response = await getRecord(objectType, id);
        setRecord(response);
        dispatch(editModalLoadingIndicatorOff());
      } else {
        const emptyRecord = NEW_RECORD_SKELETON[objectType]();
        setRecord(emptyRecord);
      }
    }
    _getRecord();
  }, [id, dispatch, setRecord, objectType]);

  // if new transaction with record not defined
  // show null till record set in useObject
  if (!id && !record) {
    return null;
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={objectType}
        setErrors={setErrors}
        text={record?.name || ""}
        handleClose={onClose}
      />
      {loadingState && <Loading />}
      <ObjectEditDialogContent>
        <Layout
          pageTitle={
            id
              ? `${i18n(`objectType.${objectType}.Sentence`)} ${
                  record?.number || ""
                }`
              : i18n(`objectType.${objectType}.New`)
          }
        >
          <Box flexGrow="1" position="relative" m={2} mb={0}>
            <FormErrors errors={errors} setErrors={setErrors} />
            {record ? (
              <Box>
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2", maxWidth: "24rem" }}>
                    <FrmTextField
                      autoFocus
                      name="name"
                      label={i18n("frmLabel.Name")}
                      onValueBlur={handleInputFieldChange}
                      value={record.name}
                      error={isInError("name")}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmResizeTextField
                      name="message"
                      value={record.message}
                      label={i18n("frmLabel.Message")}
                      minRows={2}
                      width="100%"
                      onValueBlur={handleInputFieldChange}
                    />
                  </div>
                </FrmFieldGrid>
                <VSpace space={1} />
              </Box>
            ) : (
              !isInError("record") && (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )
            )}
          </Box>
          <VSpace space={4} />
        </Layout>
      </ObjectEditDialogContent>
    </>
  );
}
