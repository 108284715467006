import { useSelector } from "react-redux";

import { Typography } from "@mui/material";

import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmCurrency } from "components/formFields/frm/FrmCurrency";
import { LinkText } from "components/utility/LinkText";

import { i18n } from "services/i18nService";
import { locales } from "services/utility/localization";
import { checkForUnexpectedProps } from "services/utility/misc";

import { theme } from "SosTheme";

export function FrmCurrencyExchangeRate(props) {
  const {
    record,
    customerOrVendor,
    currencies,
    exchangeRate,
    handleInputChange,
    handleRefreshExchangeRate,
    currencyError,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmCurrencyExchangeRate", unexpected);

  const multiCurrencyEnabled = useSelector(
    (state) => state.userCompanySettings.settings.multiCurrencyEnabled
  );

  const homeCurrency = useSelector(
    (state) => state.userCompanySettings.settings.homeCurrency
  );

  const localeCode = useSelector(
    (state) => state.userCompanySettings.settings.localeCode
  );

  const locale = locales[localeCode];

  if (!multiCurrencyEnabled) {
    return null;
  }

  return (
    <div
      style={{ alignItems: "center", display: "flex", flexDirection: "row" }}
    >
      <div style={{ flex: "6 0 6em" }}>
        <FrmCurrency
          value={record?.currency}
          currencies={currencies}
          onValueChange={handleInputChange}
          disabled={customerOrVendor?.isInQuickBooks}
          error={currencyError}
        />
      </div>

      <div style={{ flex: `0 0 ${theme.spacing(1)}` }} />

      <div style={{ flex: "5 0 6em" }}>
        <FrmTextField
          number
          name="exchangeRate"
          label={i18n("frmLabel.ExchangeRate")}
          value={exchangeRate}
          onValueBlur={handleInputChange}
        />
      </div>

      <div style={{ flex: `0 0 ${theme.spacing(1)}` }} />

      <div style={{ flex: "5 0 2em", position: "relative", bottom: "-3px" }}>
        <Typography>
          ={" "}
          {new Intl.NumberFormat(locale, {
            style: "currency",
            currency: homeCurrency.code,
          }).format(1)}
        </Typography>
      </div>

      <div
        style={{
          flex: "10 0 10em",
          display: "inline",
          position: "relative",
          bottom: "-3px",
        }}
      >
        <LinkText onClick={handleRefreshExchangeRate}>
          {i18n("global.RefreshExchangeRate")}
        </LinkText>
      </div>
    </div>
  );
}
