export const SETTINGS = [
  { apiName: "defaultTaxCode", component: "FrmSalesTaxCode" },
  { apiName: "retrieveQuantityOnHand", component: "FrmCheckbox" },
  {
    apiName: "defaultTaxRate",
    component: "FrmTextField",
    number: true,
    width: "8rem",
  },
  { apiName: "defaultDiscountTaxable", component: "FrmCheckbox" },
  { apiName: "defaultShippingTaxable", component: "FrmCheckbox" },
  { apiName: "defaultPaymentMethod", component: "FrmPaymentMethod" },
  { apiName: "defaultTerms", component: "FrmTerms" },
  { apiName: "hideItemName", component: "FrmCheckbox" },
  { apiName: "findCustomersByFullName", component: "FrmCheckbox" },
  { apiName: "searchByAnyPartOfCustomer", component: "FrmCheckbox" },
  { apiName: "autoShipInvoices", component: "FrmCheckbox" },
  { apiName: "autoShipSalesReceipts", component: "FrmCheckbox" },
  { apiName: "autoBackOrderSalesReceipts", component: "FrmCheckbox" },
  { apiName: "autoSOForInvoice", component: "FrmCheckbox" },
  { apiName: "lineItemDiscountsEnabled", component: "FrmCheckbox" },
  { apiName: "marginPricingEnabled", component: "FrmCheckbox" },
  { apiName: "markupOnActualCost", component: "FrmCheckbox" },
  { apiName: "showWeightOnSalesForms", component: "FrmCheckbox" },
  { apiName: "showVolumeOnSalesForms", component: "FrmCheckbox" },
  { apiName: "includeIncomingInAvailableForSale", component: "FrmCheckbox" },
  { apiName: "showInStockOnlySOToPT", component: "FrmCheckbox" },
  {
    apiName: "defaultDepositAssetAccount",
    component: "FrmAccount",
    accountType: "otherCurrentAsset",
  },
  {
    apiName: "defaultDepositLiabilityAccount",
    component: "FrmAccount",
    accountType: "liability",
  },
];
