import { createSlice } from "@reduxjs/toolkit";

export const settingsModeSlice = createSlice({
  name: "settingsMode",
  initialState: false,
  reducers: {
    settingsModeOn: () => true,
    settingsModeOff: () => false,
  },
});

export const { settingsModeOn, settingsModeOff } = settingsModeSlice.actions;
export default settingsModeSlice.reducer;
