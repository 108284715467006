import { Money } from "classes/DecimalClasses";

export const LINE_FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: ["salesPrice", "purchaseCost"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: ["defaultPurchaseCost", "defaultSalesPrice"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const EMPTY_LINE = {
  item: null,
  purchaseCost: new Money(0),
  salesPrice: new Money(0),
  sku: null,
  variantName: null,
};

export const EMPTY_RECORD = {
  defaultPurchaseCost: new Money(0),
  defaultSalesPrice: new Money(0),
  lines: [],
};

export const BASE_UOM_SKELETON = {
  conversion: 1,
  purchaseCost: null,
  isBase: true,
  salesPrice: null,
};
