import { FrmItemSelect } from "components/formFields/frm";

import { i18n } from "services/i18nService";

import { ITEM_QUICKLIST_CASES } from "appConstants";

export function EditCategoryFilter(props) {
  const { filters, setFilters } = props;

  const { filterSettings } = filters;
  const { Category } = filterSettings;

  function handleChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        Category: value?.id || "",
      },
      startingRecord: 1,
    }));
  }

  return (
    <FrmItemSelect
      name="item"
      label={i18n("filter.Category")}
      value={Category ? { id: +Category } : null}
      onValueChange={handleChange}
      type={ITEM_QUICKLIST_CASES.CATEGORY}
      dataTesting="categoryFilter"
    />
  );
}
