import { createSlice } from "@reduxjs/toolkit";

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: [],
  reducers: {
    setNotifications: (state, action) => action.payload,
    deleteNotifications: (state, action) =>
      state.filter(({ id }) => !action.payload.includes(id)),
  },
});

export const { setNotifications, deleteNotifications } =
  notificationsSlice.actions;
export default notificationsSlice.reducer;
