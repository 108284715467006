import { Container, Typography } from "@mui/material";

import { Link } from "components/html/Link";

import { i18n } from "services/i18nService";

export function Forbidden() {
  return (
    <Container maxWidth="md" sx={{ paddingTop: "25px" }}>
      <Typography variant="h3">{i18n("restricted.Title")}</Typography>
      <p>
        {i18n("restricted.Info")} <Link to="/">{i18n("global.Home")}</Link>
      </p>
    </Container>
  );
}
