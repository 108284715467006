import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { i18n } from "services/i18nService";

export function UnscheduleDialog(props) {
  const {
    unscheduleDialogOpen,
    setUnscheduleDialogOpen,
    unscheduleReport,
    reportName,
  } = props;

  return (
    <Dialog
      open={unscheduleDialogOpen}
      onClose={() => setUnscheduleDialogOpen(false)}
    >
      <DialogTitle>{i18n("reports.Action.Unschedule")}</DialogTitle>
      <DialogContent>
        {i18n("reports.UnscheduleThisReport", { reportName })}
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          color="secondary"
          onClick={() => setUnscheduleDialogOpen(false)}
        >
          {i18n("button.Cancel")}
        </Button>
        <Button onClick={() => unscheduleReport()} color="secondary">
          {i18n("reports.Action.Unschedule")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
