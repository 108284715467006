import { isoToLocalDateTime } from "services/utility/dates";

// convert ISO string dates to JavaScript date objects
const dateFields = ["eventDateTime"];

export function afterGet(record) {
  let newRecord = { ...record };

  dateFields.forEach((dateField) => {
    if (record[dateField]) {
      newRecord[dateField] = isoToLocalDateTime(record[dateField]);
    }
  });

  return newRecord;
}
