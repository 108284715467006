import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { formatNameLinkField } from "services/utility/formatting";

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: formatNameLinkField,
    fixed: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "sortOrder",
    heading: i18n("columns.SortOrder"),
    maxWidth: "20em",
    fixed: true,
    defaultSort: true,
    fixedRightOfAction: true,
    sortable: true,
  },
];

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];

export function getEmptyRecord({ defaultAssetAccount }) {
  return {
    name: null,
    sortOrder: Decimal.ZERO,
    assetAccount: defaultAssetAccount,
  };
}
