import { i18n } from "services/i18nService";

import {
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  subDays,
  subWeeks,
  addWeeks,
  subMonths,
  addMonths,
  subQuarters,
  addQuarters,
  subYears,
  addYears,
} from "date-fns";

export const listPageQuickDates = [
  {
    name: i18n("Dates.AllDates"),
    from: () => null,
    to: () => null,
    group: "a",
  },
  {
    name: i18n("Dates.Today"),
    from: () => new Date(),
    to: () => new Date(),
    group: "a",
  },
  {
    name: i18n("Dates.Yesterday"),
    from: () => subDays(new Date(), 1),
    to: () => subDays(new Date(), 1),
    group: "a",
  },
  {
    name: i18n("Dates.ThisWeek"),
    from: () => startOfWeek(new Date()),
    to: () => endOfWeek(new Date()),
    group: "b",
  },
  {
    name: i18n("Dates.ThisWeekToDate"),
    from: () => startOfWeek(new Date()),
    to: () => new Date(),
    group: "b",
  },
  {
    name: i18n("Dates.ThisMonth"),
    from: () => startOfMonth(new Date()),
    to: () => endOfMonth(new Date()),
    group: "b",
  },
  {
    name: i18n("Dates.ThisMonthToDate"),
    from: () => startOfMonth(new Date()),
    to: () => new Date(),
    group: "b",
  },
  {
    name: i18n("Dates.ThisQuarter"),
    from: () => startOfQuarter(new Date()),
    to: () => endOfQuarter(new Date()),
    group: "b",
  },
  {
    name: i18n("Dates.ThisQuarterToDate"),
    from: () => startOfQuarter(new Date()),
    to: () => new Date(),
    group: "b",
  },
  {
    name: i18n("Dates.ThisYear"),
    from: () => startOfYear(new Date()),
    to: () => endOfYear(new Date()),
    group: "b",
  },
  {
    name: i18n("Dates.ThisYearToDate"),
    from: () => startOfYear(new Date()),
    to: () => new Date(),
    group: "b",
  },
  {
    name: i18n("Dates.LastWeek"),
    from: () => startOfWeek(subWeeks(new Date(), 1)),
    to: () => endOfWeek(subWeeks(new Date(), 1)),
    group: "c",
  },
  {
    name: i18n("Dates.LastWeekToDate"),
    from: () => startOfWeek(subWeeks(new Date(), 1)),
    to: () => new Date(),
    group: "c",
  },
  {
    name: i18n("Dates.LastMonth"),
    from: () => startOfMonth(subMonths(new Date(), 1)),
    to: () => endOfMonth(subMonths(new Date(), 1)),
    group: "c",
  },
  {
    name: i18n("Dates.LastMonthtoDate"),
    from: () => startOfMonth(subMonths(new Date(), 1)),
    to: () => new Date(),
    group: "c",
  },
  {
    name: i18n("Dates.LastQuarter"),
    from: () => startOfQuarter(subQuarters(new Date(), 1)),
    to: () => endOfQuarter(subQuarters(new Date(), 1)),
    group: "c",
  },
  {
    name: i18n("Dates.LastQuarterToDate"),
    from: () => startOfQuarter(subQuarters(new Date(), 1)),
    to: () => new Date(),
    group: "c",
  },
  {
    name: i18n("Dates.LastYear"),
    from: () => startOfYear(subYears(new Date(), 1)),
    to: () => endOfYear(subYears(new Date(), 1)),
    group: "c",
  },
  {
    name: i18n("Dates.LastYearToDate"),
    to: () => new Date(),
    from: () => startOfYear(subYears(new Date(), 1)),
    group: "c",
  },
  {
    name: i18n("Dates.Since30DaysAgo"),
    to: () => new Date(),
    from: () => subDays(new Date(), 30),
    group: "d",
  },
  {
    name: i18n("Dates.Since60DaysAgo"),
    from: () => subDays(new Date(), 60),
    to: () => new Date(),
    group: "d",
  },
  {
    name: i18n("Dates.Since90DaysAgo"),
    from: () => subDays(new Date(), 90),
    to: () => new Date(),
    group: "d",
  },
  {
    name: i18n("Dates.Since365DaysAgo"),
    from: () => subDays(new Date(), 365),
    to: () => new Date(),
    group: "d",
  },
];

const groupIds = listPageQuickDates.map((qd) => qd.group);
const uniqueGroupIds = new Set(groupIds);
export let quickDateGroups = [];
uniqueGroupIds.forEach((groupName) => {
  const thisGroup = listPageQuickDates.filter((qd) => qd.group === groupName);
  quickDateGroups.push(thisGroup);
});

export const endOfReportQuickDates = [
  {
    name: i18n("Dates.Today"),
    id: "Today",
    date: () => new Date(),
  },
  {
    name: i18n("Dates.Yesterday"),
    id: "Yesterday",
    date: () => subDays(new Date(), 1),
  },
  {
    name: i18n(`Dates.endOf.LastWeek`),
    id: "Last Week",
    date: () => endOfWeek(subWeeks(new Date(), 1)),
  },
  {
    name: i18n(`Dates.endOf.LastMonth`),
    id: "Last Month",
    date: () => endOfMonth(subMonths(new Date(), 1)),
  },
  {
    name: i18n(`Dates.endOf.LastQuarter`),
    id: "Last Quarter",
    date: () => endOfQuarter(subQuarters(new Date(), 1)),
  },
  {
    name: i18n(`Dates.endOf.LastYear`),
    id: "Last year",
    date: () => endOfYear(subYears(new Date(), 1)),
  },
  {
    name: i18n(`Dates.endOf.ThisWeek`),
    id: "This Week",
    date: () => endOfWeek(new Date()),
  },
  {
    name: i18n(`Dates.endOf.ThisMonth`),
    id: "This Month",
    date: () => endOfMonth(new Date()),
  },
  {
    name: i18n(`Dates.endOf.ThisQuarter`),
    id: "This Quarter",
    date: () => endOfQuarter(new Date()),
  },
  {
    name: i18n(`Dates.endOf.ThisYear`),
    id: "This Year",
    date: () => endOfYear(new Date()),
  },
  {
    name: i18n(`Dates.endOf.NextWeek`),
    id: "Next Week",
    date: () => endOfWeek(addWeeks(new Date(), 1)),
  },
  {
    name: i18n(`Dates.endOf.NextMonth`),
    id: "Next Month",
    date: () => endOfMonth(addMonths(new Date(), 1)),
  },
  {
    name: i18n(`Dates.endOf.NextQuarter`),
    id: "Next Quarter",
    date: () => endOfQuarter(addQuarters(new Date(), 1)),
  },
  {
    name: i18n(`Dates.endOf.NextYear`),
    id: "Next Year",
    date: () => endOfYear(addYears(new Date(), 1)),
  },
  {
    name: i18n("Dates.30DaysAgo"),
    id: "30 Days Ago",
    date: () => subDays(new Date(), 30),
  },
  {
    name: i18n("Dates.60DaysAgo"),
    id: "60 Days Ago",
    date: () => subDays(new Date(), 60),
  },
  {
    name: i18n("Dates.90DaysAgo"),
    id: "90 Days Ago",
    date: () => subDays(new Date(), 90),
  },
  {
    name: i18n("Dates.365DaysAgo"),
    id: "365 Days Ago",
    date: () => subDays(new Date(), 365),
  },
];

export const startOfReportQuickDates = [
  {
    name: i18n("Dates.Today"),
    id: "Today",
    date: () => new Date(),
  },
  {
    name: i18n("Dates.Yesterday"),
    id: "Yesterday",
    date: () => subDays(new Date(), 1),
  },
  {
    name: i18n(`Dates.startOf.LastWeek`),
    id: "Last Week",
    date: () => startOfWeek(subWeeks(new Date(), 1)),
  },
  {
    name: i18n(`Dates.startOf.LastMonth`),
    id: "Last Month",
    date: () => startOfMonth(subMonths(new Date(), 1)),
  },
  {
    name: i18n(`Dates.startOf.LastQuarter`),
    id: "Last Quarter",
    date: () => startOfQuarter(subQuarters(new Date(), 1)),
  },
  {
    name: i18n(`Dates.startOf.LastYear`),
    id: "Last year",
    date: () => startOfYear(subYears(new Date(), 1)),
  },
  {
    name: i18n(`Dates.startOf.ThisWeek`),
    id: "This Week",
    date: () => startOfWeek(new Date()),
  },
  {
    name: i18n(`Dates.startOf.ThisMonth`),
    id: "This Month",
    date: () => startOfMonth(new Date()),
  },
  {
    name: i18n(`Dates.startOf.ThisQuarter`),
    id: "This Quarter",
    date: () => startOfQuarter(new Date()),
  },
  {
    name: i18n(`Dates.startOf.ThisYear`),
    id: "This Year",
    date: () => startOfYear(new Date()),
  },
  {
    name: i18n(`Dates.startOf.NextWeek`),
    id: "Next Week",
    date: () => startOfWeek(addWeeks(new Date(), 1)),
  },
  {
    name: i18n(`Dates.startOf.NextMonth`),
    id: "Next Month",
    date: () => startOfMonth(addMonths(new Date(), 1)),
  },
  {
    name: i18n(`Dates.startOf.NextQuarter`),
    id: "Next Quarter",
    date: () => startOfQuarter(addQuarters(new Date(), 1)),
  },
  {
    name: i18n(`Dates.startOf.NextYear`),
    id: "Next Year",
    date: () => startOfYear(addYears(new Date(), 1)),
  },
  {
    name: i18n("Dates.30DaysAgo"),
    id: "30 Days Ago",
    date: () => subDays(new Date(), 30),
  },
  {
    name: i18n("Dates.60DaysAgo"),
    id: "60 Days Ago",
    date: () => subDays(new Date(), 60),
  },
  {
    name: i18n("Dates.90DaysAgo"),
    id: "90 Days Ago",
    date: () => subDays(new Date(), 90),
  },
  {
    name: i18n("Dates.365DaysAgo"),
    id: "365 Days Ago",
    date: () => subDays(new Date(), 365),
  },
];
