export function afterGet(record) {
  const newRecord = {
    ...record,
    purchaseOrderNumber: {
      prefix: record.prefixPurchaseOrder,
      nextNumber: record.nextPONumber,
    },
  };

  delete newRecord.prefixPurchaseOrder;
  delete newRecord.nextPONumber;

  return newRecord;
}
