import { salesTransactionBeforeSave } from "services/sosInventoryService/salesTransaction/beforeSave";
import { nonUtcDateTimeHandling } from "services/utility/beforeSave";

import { OBJECT_TYPES } from "appConstants";

export function beforeSave(record) {
  let newRecord = salesTransactionBeforeSave(
    OBJECT_TYPES.SALES_ORDER.fullString,
    record
  );

  newRecord = nonUtcDateTimeHandling(newRecord);

  return newRecord;
}
