import { Money } from "classes/DecimalClasses";

import { FrmTextField } from "components/formFields/FrmTextField";
import { HoverHelp } from "components/utility/HoverHelp";

import { checkForUnexpectedProps } from "services/utility/misc";

export function FrmTextFieldWithHoverHelp(props) {
  const {
    label,
    onValueBlur,
    value,
    error,
    name,
    number,
    sx,
    helpText,
    autoFocus,
    disabled,
    money,
    dataTesting,
    maxLength,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmTextFieldWithHoverHelp", unexpected);

  function handleBlur(name, value) {
    onValueBlur(name, money ? new Money(value || 0) : value);
  }

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
      <FrmTextField
        label={label}
        onValueBlur={handleBlur}
        value={value}
        error={error}
        name={name}
        number={number}
        autoFocus={autoFocus}
        disabled={disabled}
        sx={sx}
        money={money}
        maxLength={maxLength}
        dataTesting={dataTesting}
      />
      <HoverHelp title={helpText} sx={{ marginTop: "0.3em" }} />
    </div>
  );
}
