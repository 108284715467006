import { theme } from "SosTheme";

// props: color can be passed in, using any CSS color value
export function FadedHR(props) {
  const { color = theme.palette.primary.main } = props;

  const styles = {
    display: "block",
    border: "none",
    height: "2px",
    background: `radial-gradient(${color}, transparent 90%)`,
    marginTop: 0,
    marginBottom: 0,
  };

  return <hr style={styles} />;
}
