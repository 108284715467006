import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { ListPage } from "components/ListPage/ListPage";

import { i18n } from "services/i18nService";

import { usePlans } from "hooks/usePlans";

import { Forbidden } from "Forbidden";
import { OBJECT_TYPES } from "appConstants";

const objectType = OBJECT_TYPES.UPS_SHIPMENT.fullString;

export function UpsShipmentList() {
  const { hasAtLeastProPlan } = usePlans();

  const showUpsShipments = useSelector(
    (state) => state.userCompanySettings.settings.showUpsShipments
  );

  return (
    <Layout pageTitle={i18n(`objectType.${objectType}.SentencePlural`)}>
      <Box sx={{ display: "flex", position: "relative" }}>
        {hasAtLeastProPlan && showUpsShipments ? (
          <ListPage objectType={objectType} />
        ) : (
          <Forbidden />
        )}
      </Box>
    </Layout>
  );
}
