import { useEffect, useState } from "react";

import { Card, CardContent } from "@mui/material";

import { FrmSelectScalar } from "components/formFields/FrmSelectScalar";
import { LocationToggle } from "components/formFields/address/LocationToggle";
import { CardHeader } from "components/formFields/formCards/CardHeader";

import { i18n } from "services/i18nService";
import { getRecord } from "services/sosInventoryService/sosApi";

import { OBJECT_TYPES } from "appConstants";

const defaultOption = { name: "Default" };

export function BillingAddress(props) {
  const { value, vendor, handleInputFieldChange } = props;

  const [vendorLocations, setVendorLocations] = useState([defaultOption]);
  const [location, setLocation] = useState(defaultOption);

  useEffect(() => {
    async function getVendor() {
      const record = await getRecord(
        OBJECT_TYPES.VENDOR.fullString,
        vendor?.id
      );
      const altAddresses = record.altAddresses
        .map((address) => ({
          ...address,
          name: address.addressName,
        }))
        .sort((a, b) => (a.name < b.name ? -1 : 1));
      const defaultLocation = { address: record.address, name: "Default" };
      const allLocations = [defaultLocation, ...altAddresses];
      setVendorLocations(allLocations);
    }
    if (vendor?.id) {
      getVendor();
    }
  }, [vendor]);

  function handleOptionChange(fieldName, value) {
    if (value) {
      const location = vendorLocations.find(({ name }) => name === value);
      handleInputFieldChange(fieldName, location);
    }
    setLocation({ name: value });
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CardHeader text={i18n("address.Vendor")} gutterBottom={false} />
          <div style={{ flexGrow: 1, paddingLeft: "1rem" }}>
            <FrmSelectScalar
              name="billing"
              label={i18n("global.Address")}
              value={location.name}
              onValueChange={handleOptionChange}
              options={vendorLocations.map(({ name }) => name)}
            />
          </div>
        </div>
        <LocationToggle
          value={value}
          addressType="billing"
          handleInputFieldChange={handleInputFieldChange}
        />
      </CardContent>
    </Card>
  );
}
