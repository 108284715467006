import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function FrmCustomer(props) {
  const { value, onValueChange, disabled, sx, error } = props;

  const locations = useRelated("customer");

  return (
    <FrmSelectFromObjects
      name="customer"
      options={locations}
      disabled={disabled}
      value={value}
      label={i18n("frmLabel.Customer")}
      onValueChange={onValueChange}
      error={error}
      sx={sx}
    />
  );
}
