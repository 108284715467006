import { afterGet as globalAfterGet } from "services/sosInventoryService/afterGet";
import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/salesReceipt/schema";
import { salesTransactionAfterGet } from "services/sosInventoryService/salesTransaction/afterGet";
import {
  transformFields,
  transformLineItemFields,
} from "services/utility/afterGet";

import { OBJECT_TYPES } from "appConstants";
import { LINE_ITEM_FIELDS } from "editConfig";

export function afterGet(record) {
  let newRecord = transformFields(record, FIELDS_TO_TRANSFORM);

  newRecord.lines = transformLineItemFields(
    newRecord.lines,
    LINE_ITEM_FIELDS[OBJECT_TYPES.SALES_RECEIPT.fullString]
  );

  newRecord = salesTransactionAfterGet(newRecord);
  return globalAfterGet(newRecord);
}
