import { yyyymmddToDate } from "services/utility/dates";

export function afterGet(record) {
  return {
    ...record,
    object: {
      ...record.object,
      date: record.object.date ? yyyymmddToDate(record.object.date) : null,
    },
  };
}
