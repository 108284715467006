import { afterGet as globalAfterGet } from "services/sosInventoryService/afterGet";
import { moreTransactionAfterGet } from "services/sosInventoryService/moreTransaction/afterGet";
import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/rentalReturn/schema";
import {
  transformFields,
  transformLineItemFields,
} from "services/utility/afterGet";

import { OBJECT_TYPES } from "appConstants";
import { LINE_ITEM_FIELDS } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.RENTAL_RETURN.fullString;

export function afterGet(record) {
  let newRecord = transformFields(record, FIELDS_TO_TRANSFORM);

  newRecord.lines = transformLineItemFields(
    newRecord.lines,
    LINE_ITEM_FIELDS[OBJECT_TYPE]
  );

  newRecord = moreTransactionAfterGet(newRecord);

  return globalAfterGet(newRecord);
}
