import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function FrmChannel(props) {
  const { value, onValueChange, maxWidth } = props;

  const channels = useRelated("channel") || [];

  return (
    <FrmSelectFromObjects
      name="channel"
      label={i18n("frmLabel.Channel")}
      value={value}
      onValueChange={onValueChange}
      options={channels}
      maxWidth={maxWidth}
      dataTesting="channel"
    />
  );
}
