import { useMemo } from "react";

import { COLUMN_INFO } from "components/ListPage/listConfig";

import { makeCustomFieldName } from "services/utility/customFields";

export function useListPageColumns(objectType, customFieldDefinitions) {
  // start with the "regular" field list...
  const columnConfig = useMemo(() => COLUMN_INFO[objectType](), [objectType]);

  // ...and add custom fields
  const customColumns = customFieldDefinitions
    ? customFieldDefinitions.asArray.map(({ dataType, name }) => ({
        dataType,
        name: makeCustomFieldName(name),
        isCustomField: true,
      }))
    : [];

  return {
    allColumns: columnConfig.concat(customColumns),
    columnConfig,
    fixedColumns: columnConfig.filter(({ fixed }) => fixed),
  };
}
