import { Box, Typography } from "@mui/material";

import { FrmButtonGroup } from "components/formFields/FrmButtonGroup";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmTextFieldWithHoverHelp } from "components/formFields/FrmTextFieldWithHoverHelp";
import { ReportMultiSelect } from "components/formFields/ReportMultiSelect";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { handleProgramError } from "services/utility/errors";
import { checkForUnexpectedProps } from "services/utility/misc";

import { AssignedToFilter } from "views/Reports/Settings/AssignedTo";
import { CategoryFilter } from "views/Reports/Settings/Category";
import { ChannelFilter } from "views/Reports/Settings/Channel";
import { ClassFilter } from "views/Reports/Settings/Class";
import { CustomerFilter } from "views/Reports/Settings/Customer";
import { CustomerTypeFilter } from "views/Reports/Settings/CustomerType";
import { DateFilter } from "views/Reports/Settings/Date";
import { DepartmentFilter } from "views/Reports/Settings/Department";
import { ItemFilter } from "views/Reports/Settings/Item";
import { LocationFilter } from "views/Reports/Settings/Location";
import { LocationsFilter } from "views/Reports/Settings/Locations";
import { LotFilter } from "views/Reports/Settings/Lot";
import { PriceTierFilter } from "views/Reports/Settings/PriceTier";
import { SalesRepFilter } from "views/Reports/Settings/SalesRep";
import { SalesRepsFilter } from "views/Reports/Settings/SalesReps";
import { VendorFilter } from "views/Reports/Settings/Vendor";
import { SUB_COMPONENT } from "views/Reports/reportConstants";

import { ITEM_QUICKLIST_CASES } from "appConstants";

export function Filters(props) {
  const {
    reportFilters,
    filters,
    setFilters,
    setAllFilterChoicesSelected,
    categories,
    locations,
    salesReps,
    ...unexpected
  } = props;
  checkForUnexpectedProps("Filters", unexpected);

  function handleValueChange(fieldName, newValue) {
    setFilters((prev) => ({ ...prev, [fieldName]: newValue }));
  }

  return (
    <Box>
      <Typography variant="h5" color="primaryText" fontWeight="Bold">
        {i18n("reports.WidgetText.Filters")}
      </Typography>
      <Box>
        <VSpace space={1} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {reportFilters.map((filter, index) => {
            return (
              <Filter
                key={index}
                filter={filter}
                filters={filters}
                handleValueChange={handleValueChange}
                setFilters={setFilters}
                setAllFilterChoicesSelected={setAllFilterChoicesSelected}
                categories={categories}
                locations={locations}
                salesReps={salesReps}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

const FILTERS = {
  AssignedToFilter,
  CategoryFilter,
  ChannelFilter,
  ClassFilter,
  CustomerFilter,
  CustomerTypeFilter,
  DepartmentFilter,
  ItemFilter,
  LocationFilter,
  LocationsFilter,
  LotFilter,
  PriceTierFilter,
  SalesRepsFilter,
  VendorFilter,
};

export function Filter(props) {
  const {
    filter,
    filters,
    handleValueChange,
    setFilters,
    setAllFilterChoicesSelected,
    categories,
    locations,
    salesReps,
  } = props;

  const {
    label,
    component,
    apiName,
    macroApiName,
    includeTotalOnlyOption,
    options,
    itemType,
    onlyBinTracked,
    helpText,
    isMultiSelect,
    number,
    datePrefix,
    placeholder,
    includeAllOption,
  } = filter;

  switch (component) {
    // [sub-component] filters are part of another filter, and don't have
    // a visual component
    case SUB_COMPONENT:
      return null;
    case "FrmTextField":
      return (
        <FrmTextField
          name={apiName}
          label={label}
          value={filters[apiName]}
          onValueBlur={handleValueChange}
          number={number}
          dataTesting={apiName}
        />
      );

    case "FrmTextFieldWithHoverHelp":
      return (
        <FrmTextFieldWithHoverHelp
          name={apiName}
          label={label}
          value={filters[apiName]}
          onValueBlur={handleValueChange}
          helpText={helpText}
        />
      );
    case "DateFilter":
      return (
        <DateFilter
          macroValue={filters[macroApiName]}
          dateValue={filters[apiName]}
          onValueChange={handleValueChange}
          label={label}
          name={apiName}
          macroName={macroApiName}
          oneYearMax={filter.oneYearMax}
          datePrefix={datePrefix}
        />
      );
    case "AssignedToFilter":
    case "ChannelFilter":
    case "ClassFilter":
    case "CustomerFilter":
    case "CustomerTypeFilter":
    case "DepartmentFilter":
    case "ItemFilter":
    case "LocationFilter":
    case "LotFilter":
    case "PriceTierFilter":
    case "VendorFilter":
      const FilterComponent = FILTERS[component];
      return (
        <FilterComponent
          name={apiName}
          label={label}
          value={filters[apiName]}
          setFilters={setFilters}
          onValueChange={handleValueChange}
          includeTotalOnlyOption={includeTotalOnlyOption}
          itemType={itemType || ITEM_QUICKLIST_CASES.ALL}
          onlyBinTracked={onlyBinTracked}
          isMultiSelect={isMultiSelect}
          placeholder={placeholder}
          includeAllOption={includeAllOption}
        />
      );
    case "SalesRepFilter":
      return (
        <SalesRepFilter
          name={apiName}
          label={label}
          value={filters[apiName]}
          onValueChange={handleValueChange}
          salesReps={salesReps}
        />
      );
    case "SalesRepsFilter":
      return (
        <SalesRepsFilter
          value={filters[apiName]}
          onValueChange={handleValueChange}
          name={apiName}
          label={label}
          salesReps={salesReps}
        />
      );

    case "LocationsFilter":
      return (
        <LocationsFilter
          value={filters[apiName]}
          name={apiName}
          locations={locations}
          onValueChange={handleValueChange}
          placeholder={placeholder}
        />
      );

    case "CategoryFilter":
      return (
        <CategoryFilter
          name={apiName}
          label={label}
          value={filters[apiName]}
          onValueChange={handleValueChange}
          setAllFilterChoicesSelected={setAllFilterChoicesSelected}
          categories={categories}
        />
      );

    case "FrmSelectFromObjects":
      return (
        <FrmSelectFromObjects
          label={label}
          name={apiName}
          options={options}
          value={filters[apiName]}
          onValueChange={handleValueChange}
          disableClearable
          dataTesting={apiName}
        />
      );
    case "ReportMultiSelect":
      return (
        <ReportMultiSelect
          label={label}
          name={apiName}
          options={options}
          value={filters[apiName]}
          onValueChange={handleValueChange}
        />
      );
    case "FrmButtonGroup":
      return (
        <FrmButtonGroup
          label={label}
          name={apiName}
          options={options}
          value={filters[apiName]}
          onValueChange={handleValueChange}
        />
      );
    default:
      handleProgramError(
        new Error(`unexpected value for component string: ${component}`)
      );
  }
}
