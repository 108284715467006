import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { Checkbox, ListItemIcon, Box } from "@mui/material";

import { checkForUnexpectedProps } from "services/utility/misc";

import { ALL_FILTER_OPTION } from "appConstants";

const TAGS_LIMIT = 4;

export function ReportMultiSelect(props) {
  const {
    value,
    options,
    onValueChange,
    name,
    label,
    placeholder,
    dataTesting,
    ...unexpected
  } = props;
  checkForUnexpectedProps("ReportMultiSelect", unexpected);
  const isAllSelected =
    options?.length &&
    value?.length === options.filter(({ id }) => id > 0).length;

  function renderOption(props, option) {
    if (option === ALL_FILTER_OPTION) {
      return (
        <Box
          sx={{ backgroundColor: "background.default" }}
          {...props}
          key={option.id}
        >
          <ListItemIcon>
            <Checkbox
              disableRipple
              sx={{ p: 0 }}
              checked={isAllSelected}
              indeterminate={
                value?.length > 0 &&
                value?.length < options?.filter(({ id }) => id > 0).length
              }
            />
          </ListItemIcon>
          <Typography sx={{ fontWeight: "Bold" }}>
            {ALL_FILTER_OPTION.name}
          </Typography>
        </Box>
      );
    }
    return (
      <Box {...props} data-testing="selectOption" key={option.id}>
        <ListItemIcon>
          <Checkbox
            disableRipple
            sx={{ p: 0 }}
            checked={value?.map(({ id }) => id).indexOf(option.id) > -1}
          />
        </ListItemIcon>
        <div>{option.name}</div>
      </Box>
    );
  }

  function renderTags(tagArray) {
    const numTags = tagArray.length;

    return (
      <>
        {tagArray.slice(0, TAGS_LIMIT).map((tag, index, array) => {
          const tagName = tag?.name
            ? tag.name
            : options?.find(({ id }) => id === tag.id)?.name;
          return (
            <span key={index} style={{ margin: "2px" }}>
              {array.length === index + 1 ? tagName : `${tagName}, `}
            </span>
          );
        })}
        {numTags > TAGS_LIMIT && (
          <Typography sx={{ fontWeight: "Bold", marginLeft: "0.5rem" }}>
            {`+${numTags - TAGS_LIMIT}`}
          </Typography>
        )}
      </>
    );
  }

  function handleChange(_, value, __, option) {
    const allOption = value.find(({ id }) => id === 0);
    if (option?.option === ALL_FILTER_OPTION) {
      onValueChange(
        name,
        isAllSelected ? [] : options.filter(({ id }) => id > 0)
      );
      return;
    }

    if (isAllSelected && !allOption && option.id > 0) {
      onValueChange(name, []);
      return;
    }

    onValueChange(name, value);
  }

  return (
    <Autocomplete
      multiple
      name={name}
      disableListWrap
      data-testing={dataTesting}
      disableClearable
      disableCloseOnSelect
      popupIcon={
        <div style={{ height: "28px", width: "28px", paddingTop: "2px" }}>
          <KeyboardArrowDownIcon sx={{ color: "selectIcon" }} />
        </div>
      }
      limitTags={TAGS_LIMIT}
      loading={!Array.isArray(options)}
      onChange={handleChange}
      options={options || []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={value || []}
      size="small"
      renderOption={renderOption}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          label={label}
          placeholder={value?.length ? "" : placeholder}
        />
      )}
      renderTags={renderTags}
    />
  );
}
