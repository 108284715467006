import { transformUoms } from "services/utility/afterGet";
import { transformJobWorkCenter } from "services/utility/jobWorkCenter";

const lineTypes = ["lines", "outputs", "inputs"];

export function productionTransactionAfterGet(record) {
  let newRecord = { ...record };

  newRecord.number = record.number === "auto" ? "" : record.number;

  // date can be null on a "populateFrom" API call
  if (!newRecord.date) {
    newRecord.date = new Date();
  }

  // line items
  lineTypes
    .filter((lineType) => record[lineType])
    .forEach((lineType) => {
      const newLines = [];
      record[lineType].forEach((line) => {
        const newLine = { ...line };

        newLine.jobWorkcenter = transformJobWorkCenter(
          line.job,
          line.workcenter
        );

        if (line.itemDetails) {
          newLine.itemDetails.itemUoms = transformUoms(
            line.itemDetails.itemUoms
          );
        }

        // add property for related records
        newLine.relatedRecords = {};

        // and add this line to the collection of new lines
        newLines.push(newLine);
      });
      // update the record with the new lines
      newRecord[lineType] = newLines;
    });
  return newRecord;
}
