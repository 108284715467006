import { fulfillmentTransactionBeforeSave } from "services/sosInventoryService/fulfillmentTransaction/beforeSave";

import { OBJECT_TYPES } from "appConstants";

export function beforeSave(record) {
  const newRecord = fulfillmentTransactionBeforeSave(
    OBJECT_TYPES.PICK_TICKET.fullString,
    record
  );

  return newRecord;
}
