import { LineItemTextField } from "components/formFields/LineItemTextField";
import { LineTableCell } from "components/formFields/LineTableCell";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineReceived(props) {
  const { value, onValueChange, objectType, error } = props;

  return (
    <LineTableCell sx={{ minWidth: "6rem" }}>
      <LineItemTextField
        name="received"
        value={value}
        number
        onValueChange={onValueChange}
        metadata={LINE_ITEM_METADATA[objectType].received}
        error={error}
      />
    </LineTableCell>
  );
}
