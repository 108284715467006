export function afterGet(record) {
  const newRecord = {
    ...record,
    adjustmentNumber: {
      prefix: record.prefixAdjustment,
      nextNumber: record.nextAdjustmentNumber,
    },
    transferNumber: {
      prefix: record.prefixTransfer,
      nextNumber: record.nextTransferNumber,
    },
  };

  delete newRecord.prefixAdjustment;
  delete newRecord.nextAdjustmentNumber;
  delete newRecord.prefixTransfer;
  delete newRecord.nextTransferNumber;

  return newRecord;
}
