import { LineItemCheckboxField } from "components/formFields/LineItemCheckboxField";
import {
  LineTableCell,
  LineTableHeadingCell,
} from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";

export function OcostBillHeading() {
  return (
    <LineTableHeadingCell align="center">
      {i18n("columns.Bill")}
    </LineTableHeadingCell>
  );
}

export function OcostBill(props) {
  const { value, onValueChange } = props;

  return (
    <LineTableCell>
      <LineItemCheckboxField
        name="bill"
        value={value}
        onValueChange={onValueChange}
      />
    </LineTableCell>
  );
}
