import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/jobProfitLoss/schema";
import { transformFields } from "services/utility/afterGet";

export function afterGet(record) {
  const newRecord = { ...record };
  newRecord.lines = newRecord.lines.map((line) =>
    transformFields(line, FIELDS_TO_TRANSFORM)
  );
  return newRecord;
}
