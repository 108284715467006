import parse from "html-react-parser";

export function parseHTML(text) {
  return text ? parse(text) : text;
}

// takes as input data that has been stored with HTML encoding (like
// "Smith &amp; Smith") and outputs that data as regular text
export function htmlDecode(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  return doc.documentElement.textContent;
}

// encode a string so that special characters are represented as HTML
// entities; the reverse of htmlDecode above
export function htmlEncode(str) {
  const div = document.createElement("div");
  div.textContent = str;
  return div.innerHTML;
}