import { BaseDateFilter } from "components/ListPage/Widgets/EditDisplayResults/BaseDateFilter";

export function EditExpirationDateFilter(props) {
  const {
    filters,
    setFilters,
    setDateError,
    setDateFilter,
    dateFilter,
  } = props;
  const { filterSettings } = filters;
  const { FromExpiration, ToExpiration } = filterSettings;

  function onDateChange(dates) {
    const { fromDate, toDate } = dates;
    const newDates = { FromExpiration: fromDate, ToExpiration: toDate };
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: { ...prevParams.filterSettings, ...newDates },
      startingRecord: 1,
    }));
  }

  return (
    <BaseDateFilter
      toDate={ToExpiration}
      fromDate={FromExpiration}
      setDateError={setDateError}
      setDateFilter={setDateFilter}
      onDateChange={onDateChange}
      dateFilter={dateFilter}
    />
  );
}
