import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";

import { initialErrorState } from "views/common";

export function salesTransactionIsValid(record, _, lines) {
  const errors = initialErrorState();
  // not sure why this next line is required, but if eliminated, the entity property
  // is somehow getting hydrated with a value from a previous call to this function
  errors.entity = [];
  lines.forEach(({ lineNumber }) => {
    errors.line[lineNumber] = [];
  });

  // has a valid date
  if (!(record.date instanceof Date)) {
    errors.messages.push(i18n("isValid.salesTransaction.Date"));
    errors.entity.push("date");
  }

  // is associated with a customer
  if (typeof record.customer?.id !== "number") {
    errors.messages.push(i18n("isValid.Customer"));
    errors.entity.push("customer");
  }

  // has at least one line item with an inventory item selected
  const withItem = lines.filter(({ item }) => item?.id);
  if (!withItem.length) {
    errors.messages.push(i18n("isValid.LineItemRequired"));
  }

  // line items
  lines.forEach((line) => {
    // quantity must be <= 99,999,999 (database field limit)
    if (line.quantity.gt(new Decimal(99999999))) {
      errors.messages.push(
        i18n("isValid.LineItem.QuantityOverflow", {
          lineNumber: line.lineNumber,
        })
      );
      errors.line[line.lineNumber].push("quantity");
    }

    // unit price must be <= 99,999,999 (database field limit)
    if (line.unitprice.gt(new Decimal(99999999))) {
      errors.messages.push(
        i18n("isValid.LineItem.UnitpriceOverflow", {
          lineNumber: line.lineNumber,
        })
      );
      errors.line[line.lineNumber].push("unitprice");
    }

    // amount must be <= 99,999,999 (database field limit)
    if (line.amount.gt(new Decimal(99999999))) {
      errors.messages.push(
        i18n("isValid.LineItem.AmountOverflow", {
          lineNumber: line.lineNumber,
        })
      );
      errors.line[line.lineNumber].push("amount");
    }
  });

  return errors;
}
