import { useSelector } from "react-redux";

import {
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";

import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";
import { LineInventoryItemHeading } from "components/formFields/line/LineInventoryItem";

import { useBins } from "hooks/useBins";
import { useJobWorkcenters } from "hooks/useJobWorkcenters";
import { useRelated } from "hooks/useRelated";

import { LineItem } from "views/Builds/Build/Output/LineItem";

export function LineItems(props) {
  const {
    record,
    objectType,
    lines,
    items,
    relatedRecords,
    itemsCount,
    addItem,
    lineHandler,
    lineItemsErrors,
    setErrors,
  } = props;

  const classes = useRelated("class");
  const bins = useBins(record?.location);
  const jobWorkcenters = useJobWorkcenters();

  // SETTINGS
  const classEnabled = useSelector(
    (state) => state.userCompanySettings.settings.classEnabled
  );
  const jobEnabled = useSelector(
    (state) => state.userCompanySettings.settings.jobEnabled
  );
  const uomEnabled = useSelector(
    (state) => state.userCompanySettings.settings.uomEnabled
  );

  if (!lines) {
    return null;
  }

  return (
    <TableContainer
      data-testing="outputLineItems"
      sx={{ overflowX: "initial" }}
    >
      <Table size="small" padding="none" stickyHeader>
        <TableHead>
          <TableRow sx={{ verticalAlign: "bottom", lineHeight: "1" }}>
            <LineInventoryItemHeading lineHandler={lineHandler} />
            <LineColumnHeading labelCode="Description" />
            <LineColumnHeading labelCode="OnHand" align="right" />
            <LineColumnHeading labelCode="OnShortOrder" align="right" />
            <LineColumnHeading labelCode="QuantityToBuild" />
            {uomEnabled && <LineColumnHeading labelCode="UOM" />}
            <LineColumnHeading labelCode="AppliedToWO" />
            {classEnabled && <LineColumnHeading labelCode="Class" />}
            {jobEnabled && <LineColumnHeading labelCode="ToJob" />}
          </TableRow>
        </TableHead>
        <TableBody>
          {lines.map((line, index) => (
            <TableRow
              key={index}
              sx={{ position: "inherit", background: "none" }}
            >
              <LineItem
                objectType={objectType}
                record={record}
                bins={bins}
                classes={classes}
                relatedRecords={relatedRecords}
                line={line}
                lineHandler={lineHandler}
                items={items}
                itemsCount={itemsCount}
                addItem={addItem}
                jobWorkcenters={jobWorkcenters}
                lineItemsErrors={lineItemsErrors}
                setErrors={setErrors}
              />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
