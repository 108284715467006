import { useSelector } from "react-redux";

import { FrmAutoSerialLots } from "components/formFields/frm/FrmAutoSerialLots";
import { LinkText } from "components/utility/LinkText";

import { setPageDirty } from "services/utility/edit";
import { textForAddLineOrLines } from "services/utility/lineItems";
import { checkForUnexpectedProps } from "services/utility/misc";

export function BottomWidgets(props) {
  const {
    lineHandler,
    lineType,
    autoSerialLotNumbering,
    handleInputFieldChange,
    objectType,
    ...unexpected
  } = props;
  checkForUnexpectedProps("BottomWidgets", unexpected);

  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
      <div style={{ marginBottom: "0.5rem" }}>
        <LinkText
          onClick={() => {
            setPageDirty();
            lineHandler({
              type: "appendEmpty",
              lineType,
              numLinesToAdd,
              objectType,
            });
          }}
        >
          {textForAddLineOrLines(numLinesToAdd)}
        </LinkText>
      </div>
      {lineType === "outputs" && (
        <FrmAutoSerialLots
          value={autoSerialLotNumbering}
          name="autoSerialLotNumbering"
          onValueChange={handleInputFieldChange}
        />
      )}
    </div>
  );
}
