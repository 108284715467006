import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

import { Reconnecting } from "views/Reconnecting";

export const LoginRoute = () => {
  const { isLoggedIn, needsCompanyChooser } = useSelector(
    (state) => state.authentication
  );

  if (isLoggedIn === "yes" && needsCompanyChooser) {
    return <Navigate to="/choosecompany" />;
  } else if (isLoggedIn === "yes") {
    // for now we are disabling redirect on login.  Leaving code so this is easy to enable
    // once we handle the notification issue that needs to be done before we allow this.
    // const redirectPath = sessionStorage.getItem("redirectPath") || "/";
    const redirectPath = "/";
    sessionStorage.removeItem("redirectPath");
    return <Navigate to={redirectPath} />;
  } else if (isLoggedIn === "no") {
    return <Outlet />;
  } else {
    return <Reconnecting />;
  }
};
