import { logError } from "services/logError";

// FATAL: An unhandleable error.
// ERROR: A handleable error.
// WARN:  A warning.
// INFO:  Useful information, no error.
// DEBUG: Useful in development, no error.
export const LOG_LEVEL = {
  DEBUG: "DEBUG",
  INFO: "INFO",
  WARN: "WARN",
  ERROR: "ERROR",
  FATAL: "FATAL",
};
const levels = [
  LOG_LEVEL.DEBUG,
  LOG_LEVEL.INFO,
  LOG_LEVEL.WARN,
  LOG_LEVEL.ERROR,
  LOG_LEVEL.FATAL,
];

// defines the minimum level for logging for each of the development,
// test, and production environments (as defined by create-react-app in
// process.env.NODE_ENV)
const ENVIRONMENT_LOG_LEVEL = {
  development: LOG_LEVEL.DEBUG,
  test: LOG_LEVEL.INFO,
  production: LOG_LEVEL.WARN,
};

const logLevel = levels.indexOf(ENVIRONMENT_LOG_LEVEL[process.env.NODE_ENV]);

// callers can use one of two forms to invoke logging:
//   log.[level](error), such as log.warn(new Error("Almost out of milk"))
//       or
//   mainLog(text, level), such as
//     mainLog(new Error("Almost out of milk"), LOG_LEVEL.WARN)
//
// current practice is to run error handling through handleProgramError in
// services/utility/errors.js, which includes logging, so generally, the
// functions here should not be called directly

export const log = {
  fatal: (error) => mainLog(error, LOG_LEVEL.FATAL),
  error: (error) => mainLog(error, LOG_LEVEL.ERROR),
  warn: (error) => mainLog(error, LOG_LEVEL.WARN),
  info: (error) => mainLog(error, LOG_LEVEL.INFO),
  debug: (error) => mainLog(error, LOG_LEVEL.DEBUG),
};

export function mainLog(error, level) {
  if (levels.indexOf(level) >= logLevel) {
    console.log(error.stack);
    if (process.env.NODE_ENV === "production") {
      if (!logError(error, level)) {
        console.error("logService | failure in logError");
      }
    }
  }
}
