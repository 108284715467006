import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import {
  formatCompanyWithContactInfo,
  formatBooleanYesOrBlank,
} from "services/utility/formatting";
import { starColumn } from "services/utility/schema";

export const columnInfo = [
  starColumn,
  {
    name: "void",
    buttonLabel: i18n("columns.Void"),
    buttonAction: "void",
    isButton: true,
    fixed: true,
    excludeFromColumnList: true,
  },
  {
    name: "shipmentDate",
    heading: i18n("columns.ShipDate"),
    formatFunc: formatDate,
    defaultSort: true,
    sortDirection: "asc",
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "toAddress",
    heading: i18n("columns.To"),
    formatFunc: formatCompanyWithContactInfo,
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
  },
  {
    name: "fromAddress",
    heading: i18n("columns.From"),
    formatFunc: formatCompanyWithContactInfo,
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
  },
  {
    name: "shipmentIdentificationNumber",
    heading: i18n("columns.TrackingNumber"),
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "voided",
    heading: i18n("columns.Voided"),
    formatFunc: formatBooleanYesOrBlank,
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
];

export const LINE_ACTIONS = ["printLabel", "createReturnLabel"];
export const BATCH_ACTIONS = ["printLabel"];

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: [],
  DATE_FIELDS: ["shipmentDate"],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export function getEmptyRecord() {
  return {};
}
