import { i18n } from "services/i18nService";

import { ITEM_QUICKLIST_CASES } from "appConstants";

export const SerialsInBins = {
  userHasSufficientPrivilege: (p) =>
    p.viewInventory || p.viewItems || p.editItems,
  planIncludesReport: (plus) => plus,
  customReportNameFetchList: [
    { objectType: "item", customReportKey: "categories" },
    { objectType: "location", customReportKey: "locations" },
    { objectType: "item" },
  ],
  customFieldObjectTypes: ["serial", "item"],
  columns: [
    { apiName: "Serial Number", default: true },
    { apiName: "Item", default: true },
    { apiName: "Description" },
    { apiName: "Location" },
    { apiName: "Status" },
    { apiName: "Customer" },
    { apiName: "Warranty" },
    { apiName: "Warranty Start" },
    { apiName: "Warranty End" },
  ],
  settings: [
    {
      label: i18n("filter.BinTrackedLocation"),
      component: "LocationsFilter",
      apiName: "locations",
      defaultValue: null,
      onlyBinTracked: true,
      placeholder: i18n("reports.placeholder.DefaultIsAll", {
        type: i18n("reports.locations"),
      }),
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Item"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
      itemType: ITEM_QUICKLIST_CASES.SERIAL,
    },
    {
      label: i18n("reports.FilterLabel.SerialNumberContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.BinNumberContains"),
      component: "FrmTextField",
      apiName: "nameContains2",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "suppressIfEmpty",
      defaultValue: true,
      label: i18n("reports.OptionLabel.ShowOnlyIfInBin"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      defaultValue: "",
      apiName: "header",
      component: "FrmTextField",
      label: i18n("global.Header"),
      type: "option",
    },
    {
      defaultValue: "",
      apiName: "footer",
      component: "FrmTextField",
      label: i18n("global.Footer"),
      type: "option",
    },
  ],
};
