import { useEffect } from "react";

import { Box, Typography } from "@mui/material";

import { i18n } from "services/i18nService";

import { theme } from "SosTheme";

export function addUserVoiceFormsScript() {
  // add user voice hosted forms to body
  const script = document.createElement("script");
  script.src = "//widget.uservoice.com/kT2vcJjzmaxP7GswHvoNvQ.js";
  script.type = "text/javascript";
  script.async = true;
  document.body.appendChild(script);

  const element = document.getElementsByTagName("script")[0];
  element.parentNode.insertBefore(script, element);
}

export function UserVoice() {
  useEffect(() => addUserVoiceFormsScript(), []);

  return (
    <Box sx={{ display: "grid", minWidth: "1070px" }} m={2} mb={0}>
      <Box mb={1}>
        <Typography variant="h2">{i18n("global.FeatureRequests")}</Typography>
      </Box>
      <div style={{ maxWidth: "100%" }}>
        <div
          data-uv-inline="classic_widget"
          data-uv-mode="feedback"
          data-uv-primary-color={theme.palette.primary.main}
          data-uv-link-color={theme.palette.primaryLink}
          data-uv-forum-id="235784"
          data-uv-width="95%"
          data-uv-height="600px"
        ></div>
      </div>
    </Box>
  );
}
