import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { NEW_RECORD_SKELETON } from "appConfig";

import { i18n } from "services/i18nService";
import { getRecord, getSubCustomer } from "services/sosInventoryService/sosApi";
import { reconcileCustomFields } from "services/utility/customFields";
import { setPageDirty } from "services/utility/edit";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

export function useCustomer(
  id,
  setRecord,
  customFieldDefs,
  setErrors,
  presetName,
  newFromParent,
  settings
) {
  const { defaultTerms, defaultTaxCode, homeCurrencyRef } = settings;

  const getEmptyRecord = NEW_RECORD_SKELETON["customer"];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!customFieldDefs) {
      return;
    }
    if (id || newFromParent) {
      async function getVic(id) {
        dispatch(editModalLoadingIndicatorOn());
        try {
          let record;
          if (id) {
            record = await getRecord("customer", id);
          } else if (newFromParent) {
            record = await getSubCustomer(newFromParent);
          }
          record.customFields = reconcileCustomFields(
            customFieldDefs,
            record.customFields
          );

          setRecord(record);
          dispatch(editModalLoadingIndicatorOff());
        } catch (error) {
          return setLoadError(error);
        }
      }
      getVic(id);
    } else {
      const emptyRecord = getEmptyRecord({
        defaultTerms,
        defaultTaxCode,
        homeCurrencyRef,
      });
      setRecord((prevRecord) => ({
        ...prevRecord,
        ...emptyRecord,
        name: presetName || emptyRecord.name,
        customFields: reconcileCustomFields(customFieldDefs, []),
      }));
    }
    setPageDirty(false);

    function setLoadError(error) {
      setErrors((prev) => ({
        ...prev,
        entity: [...prev.entity, "record"],
        messages: [error.message || i18n("error.RecordLoadError")],
      }));
      return dispatch(editModalLoadingIndicatorOff());
    }
  }, [
    getEmptyRecord,
    dispatch,
    id,
    setRecord,
    customFieldDefs,
    presetName,
    setErrors,
    newFromParent,
    defaultTerms,
    defaultTaxCode,
    homeCurrencyRef,
  ]);
}
