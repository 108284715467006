import { yyyymmddToDate } from "services/utility/dates";
import { handleProgramError } from "services/utility/errors";

import {
  DATE_SETTINGS,
  DATE_MACRO_SETTINGS,
  REFERENCE_OBJECT_SETINGS,
  LOCATION_CATEGORY_SETTINGS,
  ARRAY_SETTINGS,
  OBJECT_SETTINGS,
  TOTAL_ONLY_FILTER_OPTION,
} from "views/Reports/reportConstants";

export function afterGet(record) {
  const newRecord = { ...record, customSettingsValues: record.settings };
  const settingsValues = newRecord.customSettingsValues;

  // for specific date values, convert from string to Javascript Date class
  for (const fieldName of DATE_SETTINGS) {
    if (typeof settingsValues[fieldName] === "string") {
      settingsValues[fieldName] =
        settingsValues[fieldName] === ""
          ? null
          : yyyymmddToDate(settingsValues[fieldName]);
    } else if (settingsValues[fieldName] !== null) {
      // some reports dont use both date values  ( i.e. bin contents report )
      handleProgramError(
        new Error(`Unexpected value for a date: ${settingsValues[fieldName]}`)
      );
    }
  }

  // convert date "macros" to objects with ids,
  // as expected by our macro selector component
  for (const fieldName of DATE_MACRO_SETTINGS) {
    if (typeof settingsValues[fieldName] === "string") {
      if (settingsValues[fieldName] === "") {
        settingsValues[fieldName] = null;
      } else {
        settingsValues[fieldName] = { id: settingsValues[fieldName] };
      }
    }
  }

  // map through the setting array to set the values to an object with id
  LOCATION_CATEGORY_SETTINGS.forEach((setting) => {
    // change "0" to 0 once https://app.clickup.com/t/86850ykvz complete
    const hasAllOption = settingsValues[setting].find((id) => id === "0");
    if (hasAllOption) {
      settingsValues[setting] = null;
    } else {
      settingsValues[setting] = settingsValues[setting].map((id) => ({
        // remove the isNaN logic once https://app.clickup.com/t/86850ykvz complete
        id: Number.isNaN(parseInt(id)) ? id : +id,
      }));
    }
  });

  // map through the setting array to set the values to an object with id
  ARRAY_SETTINGS.forEach((setting) => {
    settingsValues[setting] = settingsValues[setting].map((id) => ({
      // remove the isNaN logic once https://app.clickup.com/t/86850ykvz complete
      id: Number.isNaN(parseInt(id)) ? id : +id,
    }));
  });

  // update the setting to be an object with id
  OBJECT_SETTINGS.forEach((setting) => {
    if (settingsValues[setting] || settingsValues[setting] === 0) {
      settingsValues[setting] = { id: settingsValues[setting] };
    }
  });

  // in v8, saving the "totals only" location results in a
  // settingsValues.locations value of [-1], which requires this
  // special logic since v9 treats this "totals only" as the default
  // V8BACK: can simplify this when v8 hits end of life
  if (
    settingsValues.locations &&
    settingsValues.locations.length === 1 &&
    settingsValues.locations[0].id === TOTAL_ONLY_FILTER_OPTION.id
  ) {
    settingsValues.locations = null;
  }
  // reference id objects are tracked through the xxxxId naming
  // when we use just the base reference naming on the FE
  REFERENCE_OBJECT_SETINGS.forEach((setting) => {
    if (settingsValues[setting] || settingsValues[setting] === 0) {
      const slicedSetting = setting.slice(0, -2);
      settingsValues[slicedSetting] = { id: settingsValues[setting] };
      delete settingsValues[setting];
    }
  });

  delete record.settings;
  return { ...record, settingsValues };
}
