import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { purchasingTransactionIsValid } from "services/sosInventoryService/purchasingTransaction/isValid";

import { ITEM_TYPES_REQUIRING_BINS } from "appConstants";

export function isValid(record, relatedRecords, lines) {
  const errors = purchasingTransactionIsValid(record, relatedRecords, lines);

  lines.forEach((line) => {
    // a bin has been chosen, if the item and location require it
    if (
      relatedRecords.location?.binTracking &&
      line.relatedRecords.item &&
      ITEM_TYPES_REQUIRING_BINS.includes(line.relatedRecords.item?.type) &&
      !line.bin?.id
    ) {
      errors.messages.push(
        i18n("isValid.LineItem.MissingBin", {
          lineNumber: line.lineNumber,
        })
      );
      errors.line[line.lineNumber].push("bin");
    }

    // if not auto-numbered, number of serial numbers must match quantity
    if (line.relatedRecords.item?.serialTracking && !record.autoSerialLots) {
      const numNonBlankSerials = line.serials.filter(
        (serial) => serial.name !== ""
      ).length;
      if (!new Decimal(numNonBlankSerials).eq(line.quantity)) {
        errors.messages.push(
          i18n("isValid.LineItem.QuantitySerialsMismatch", {
            lineNumber: line.lineNumber,
          })
        );
        errors.line[line.lineNumber].push("quantity", "serials");
      }
    }
  });

  return errors;
}
