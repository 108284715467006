import { transformUoms } from "services/utility/afterGet";
import { transformJobWorkCenter } from "services/utility/jobWorkCenter";

import globalState from "globalState/globalState";

export function purchasingTransactionAfterGet(record) {
  let newRecord = { ...record };
  // API returns "auto" as the transaction number in /populatefrom... type
  // requests; in the v9 front end, we carry an "unknown" transaction number
  // (generally to be automatically set when saved) as "", so set it to that
  // here
  newRecord.number = record.number === "auto" ? "" : record.number;

  // date can be null on a "populateFrom" API call
  if (!newRecord.date) {
    newRecord.date = new Date();
  }

  // line items
  const newLines = [];
  record.lines.forEach((line) => {
    const newLine = { ...line };

    newLine.jobWorkcenter = transformJobWorkCenter(line.job, line.workcenter);

    if (line.itemDetails) {
      newLine.itemDetails.itemUoms = transformUoms(line.itemDetails.itemUoms);
    }

    // extract the taxCode directly into the line item, so that it is easy to
    // maintain it like any other select field; it will get reformatted back
    // into the tax property on beforeSave
    if (line.tax) {
      newLine.taxCode = line.tax.taxCode;
      delete newLine.tax.taxCode;
    }

    // if there is no currency value, set to home currency
    if (record.hasOwnProperty("currency") && !record.currency) {
      newRecord.currency =
        globalState.getState().userCompanySettings.settings.homeCurrencyRef;
    }

    // add property for related records
    newLine.relatedRecords = {};

    // sort serials, if present
    if (line.serials) {
      newLine.serials = line.serials.sort((a, b) => (a.name < b.name ? -1 : 1));
    }

    // and add this line to the collection of new lines
    newLines.push(newLine);
  });
  // update the record with the new lines
  newRecord.lines = newLines;

  return newRecord;
}
