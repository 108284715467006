import { Money } from "classes/DecimalClasses";

import { LineItemTextField } from "components/formFields/LineItemTextField";
import {
  LineTableCell,
  LineTableHeadingCell,
} from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";
import { OTHER_COST_METADATA } from "services/sosInventoryService/itemReceipt/schema";

export function OcostAmountHeading() {
  return (
    <LineTableHeadingCell align="right">
      {i18n("columns.Amount")}
    </LineTableHeadingCell>
  );
}

export function OcostAmount(props) {
  const { value, onValueChange } = props;

  return (
    <LineTableCell sx={{ minWidth: "7rem" }}>
      <LineItemTextField
        money
        name="amount"
        value={value}
        onValueChange={(name, value) =>
          onValueChange(name, new Money(value || 0))
        }
        metadata={OTHER_COST_METADATA.amount}
      />
    </LineTableCell>
  );
}
