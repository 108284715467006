import { Box, Button, ButtonGroup, Typography } from "@mui/material";

import { i18n } from "services/i18nService";

import { WEIGHT_UNITS, VOLUME_UNITS, DIMENSION_UNITS } from "appConstants";

const LABEL = {
  weightUnit: i18n("frmLabel.WeightUnit"),
  volumeUnit: i18n("frmLabel.VolumeUnit"),
  dimensionUnit: i18n("frmLabel.DimensionUnit"),
};

const UNITS = {
  weightUnit: WEIGHT_UNITS,
  volumeUnit: VOLUME_UNITS,
  dimensionUnit: DIMENSION_UNITS,
};

// name must be one of "weightUnit", "volumeUnit", "dimensionUnit"
export function FrmUnit(props) {
  const { name, value, onValueChange, noLabel, sx } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        ...sx,
      }}
    >
      {!noLabel && (
        <Typography
          sx={{
            fontSize: "0.8125rem",
            fontWeight: "Medium",
            color: "primaryText",
            marginRight: "1.0em",
          }}
        >
          {LABEL[name]}
        </Typography>
      )}
      <ButtonGroup variant="outlined" sx={{ py: 1.5 }}>
        {UNITS[name].map((option) => (
          <Button
            key={option}
            variant={option === value ? "contained" : "outlined"}
            onClick={() => onValueChange(name, option)}
            size="small"
          >
            {option.toString()}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
}
