export function normalizeJobWorkCenter(line) {
  // separate job and workcenter; we assume that it's both or none
  const newLine = { ...line };
  if (line.jobWorkcenter && line.jobWorkcenter.id) {
    const [job, workcenter] = line.jobWorkcenter.id.split("|");
    newLine.job = { id: parseInt(job) };
    newLine.workcenter = { id: parseInt(workcenter) };
  } else {
    newLine.job = null;
    newLine.workcenter = null;
  }
  return newLine;
}

export function transformJobWorkCenter(job, workcenter) {
  // create the job/workcenter combined field; we assume that if job is present,
  // then workcenter is present as well; job and workcenter are both reference
  // objects; so we'll wind up with either "nnn|nnn" or "|"
  return job
    ? {
        id: `${job?.id}|${workcenter?.id}`,
        name: job?.name + "->" + workcenter?.name,
      }
    : null;
}

export function isFinishedGoods(workCenter) {
  return workCenter && +workCenter.id === 1;
}
