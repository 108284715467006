// Actual domain logic should be in a domainLogic.js file, at the appropriate
// level in the hierarchy.
import { removeLotAndSerialValues } from "services/sosInventoryService/domainLogic";
import {
  updateBuildLineItems,
  updateLineItemSerials,
} from "services/sosInventoryService/productionTransaction/domainLogic";
import { getRecord } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

export async function updateTransaction(
  field,
  newValue,
  currentState,
  updaters
) {
  const { record, outputLines, inputLines, relatedRecords } = currentState;
  if (!record || !outputLines || !inputLines || !relatedRecords) {
    handleProgramError(new Error("update Transaction | invalid currentState"));
  }

  let newRecord, newOutputs, newInputs, newRelatedRecords;

  switch (field) {
    case "date":
      newRecord = { ...record, date: newValue };
      updaters.setRecord(newRecord);
      if (newValue) {
        newOutputs = await updateBuildLineItems(
          newValue,
          record.location,
          outputLines
        );
        newInputs = await updateBuildLineItems(
          newValue,
          record.location,
          inputLines
        );
      }

      break;

    case "location":
      newRecord = { ...record, location: newValue };
      updaters.setRecord(newRecord);
      const location = newValue
        ? await getRecord("location", newValue.id)
        : null;
      newRelatedRecords = { ...relatedRecords, location };
      newOutputs = await updateBuildLineItems(
        record.date,
        newValue,
        outputLines
      );
      newInputs = await updateBuildLineItems(record.date, newValue, inputLines);
      newInputs = removeLotAndSerialValues(newInputs);

      break;

    case "job":
    case "comment":
    case "department":
    case "number":
    case "customFields":
    case "workcenter":
      newRecord = { ...record, [field]: newValue };
      updaters.setRecord(newRecord);

      break;

    case "autoSerialLotNumbering":
      newRecord = { ...record, [field]: newValue };
      updaters.setRecord(newRecord);
      newOutputs = updateLineItemSerials(newValue, outputLines);
      break;

    default:
      handleProgramError(
        new Error(`updateTransaction | unknown field (${field})`)
      );
  }

  if (newOutputs) {
    updaters.outputLineHandler({ type: "set", lines: newOutputs });
  }
  if (newInputs) {
    updaters.inputLineHandler({ type: "set", lines: newInputs });
  }
  if (newRelatedRecords) {
    updaters.setRelatedRecords(newRelatedRecords);
  }
}
