import { Typography, Grid, Paper, Box } from "@mui/material";
import { Link as MuiLink } from "@mui/material";

import { i18n } from "services/i18nService";

const sx = {
  paper: { p: 4, width: "40em", mb: "20rem" },
  background: {
    minHeight: "100vh",
    backgroundImage: "linear-gradient(#b0c8f6, #5b8dee)",
  },
  "& a": { color: "primaryLink" },
  link: {
    color: "primaryLink",
    "&:visited": { color: "primaryLink" },
  },
};

export function BetaMaintenance() {
  return (
    <Grid
      container
      spacing={0}
      alignItems="center"
      justifyContent="center"
      sx={sx.background}
    >
      <Paper sx={sx.paper} elevation={5}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontWeight: "fontWeightRegular" }}>
            {i18n("beta.Maintenance.1")}{" "}
            <MuiLink
              sx={sx}
              href="https://live.sosinventory.com"
              underline="none"
            >
              {i18n("beta.Maintenance.2")}
            </MuiLink>{" "}
            {i18n("beta.Maintenance.3")}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  );
}
