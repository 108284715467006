import { Checkbox } from "@mui/material";

// pass the "currency" prop to get a currency adornment at the start of the
// field; TODO: make the adornment sensitive to localization settings

export function LineItemCheckboxField(props) {
  const { name, onValueChange, value } = props;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Checkbox
        name={name}
        checked={value}
        onChange={(e) => onValueChange(e.target.name, e.target.checked)}
      />
    </div>
  );
}
