import { Box, Typography } from "@mui/material";

import { Tooltip } from "components/Tooltip";

export function GroupingBracket(props) {
  const { heading, children, disabled, hoverMsg, sx } = props;

  return (
    <Box sx={sx}>
      <Tooltip title={disabled ? hoverMsg : ""}>
        <Box>
          <Box
            id="grouping-div"
            sx={{
              marginTop: "1.0rem",
              height: "1rem",
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "groupingBracket",
              borderRadius: "6px",
              clipPath: "inset(0 0 0.5em 0)",
            }}
          />
          <Typography
            component="span"
            sx={{
              color: disabled ? "disabled" : "primary.main",
              position: "relative",
              top: "-1.8rem",
              left: "0.6rem",
              fontSize: "0.65rem",
              backgroundColor: "white",
              px: "0.2rem",
            }}
          >
            {heading}
          </Typography>
          <Box sx={{ position: "relative", top: "-1.7rem", mx: "0.5rem" }}>
            {children}
          </Box>
        </Box>
      </Tooltip>
    </Box>
  );
}
