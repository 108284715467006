export const SETTINGS = [
  {
    apiName: "salesReceiptNumber",
    component: "FrmTransactionNumber",
    transactionType: "salesReceipt",
    hasInputLabel: true,
  },
  {
    apiName: "replyEmailSalesReceipt",
    component: "FrmEmail",
    maxLength: 255,
  },
  { apiName: "salesReceiptEmail", component: "FrmResizeTextField" },
  { apiName: "salesReceiptFooter", component: "FrmResizeTextField" },
  {
    apiName: "defaultCCSalesReceipt",
    component: "FrmEmail",
    maxLength: 255,
  },
  {
    apiName: "includeSalesReceiptsInAvailableForSale",
    component: "FrmCheckbox",
  },
];
