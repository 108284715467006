import { FilterSelectField } from "components/formFields/FilterSelectField";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

const anyOption = { id: 0, name: i18n("filter.Any") };
const unassignedOption = { id: "-1", name: i18n("filter.Unassigned") };

export function EditAssignedToFilter(props) {
  const { filters, setFilters } = props;
  const { filterSettings } = filters;
  const { UserId } = filterSettings;
  const retrievedUsers = useRelated("user") || [];

  function handleChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        UserId: value?.id || "",
      },
      startingRecord: 1,
    }));
  }

  const users = retrievedUsers.map(({ id, name }) => ({
    id: id.toString(),
    name: name,
  }));

  const options = [anyOption, unassignedOption, ...users];

  return (
    <FilterSelectField
      options={options}
      onValueChange={handleChange}
      value={UserId ? { id: UserId } : anyOption}
      label={i18n("filter.AssignedTo")}
      dataTesting="assignedToFilter"
      disableClearable
    />
  );
}
