import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  AccountTree,
  Description,
  FlashOn,
  ListAlt,
  SyncProblem,
  ViewColumn,
} from "@mui/icons-material";
import { MenuList } from "@mui/material";

import { Divider } from "components/Divider";
import { DropDownMenu } from "components/TopNav/DropDownMenu";
import { MenuItemLink } from "components/TopNav/MenuItemLink";
import { MenuListItemText } from "components/TopNav/MenuListItemText";
import { NavListItemIcon } from "components/TopNav/NavListItemIcon";
import { SyncMenuTopNavIcon } from "components/TopNav/SyncMenuTopNavIcon";

import { i18n } from "services/i18nService";
import { jsonFetch } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

import { usePrivileges } from "hooks/usePrivileges";

import {
  SYNC_STATUS_INTERVAL_ACTIVE,
  SYNC_STATUS_INTERVAL_PASSIVE,
} from "appConstants";

export function SyncMenu() {
  const { isAdmin, hasPrivilegesOrIsAdmin } = usePrivileges();
  const { pathname } = useLocation();

  const connectedToQBO = useSelector(
    (state) => state.userCompanySettings.settings.connectedToQBO
  );

  const [anchor, setAnchor] = useState(null);
  const [syncStatus, setSyncStatus] = useState(null);

  const statusFetchInProgress = useRef(false);

  const fetchInterval =
    syncStatus === "sync_inprogress"
      ? SYNC_STATUS_INTERVAL_ACTIVE
      : SYNC_STATUS_INTERVAL_PASSIVE;

  useEffect(() => {
    async function getSyncStatus() {
      if (connectedToQBO && !statusFetchInProgress.current) {
        statusFetchInProgress.current = true;
        const {
          success,
          json: { data },
        } = await jsonFetch("GET", "/sync/status");
        if (success) {
          switch (data) {
            case "sync_inprogress":
            case "sync_error":
              setSyncStatus(data);
              break;
            case "sync_complete":
              setSyncStatus((prev) =>
                prev === "sync_inprogress" ? data : prev
              );
              break;
            default:
              break;
          }
        } else {
          handleProgramError(
            new Error("GET syncStatus : error in jsonFetch GET")
          );
        }
        statusFetchInProgress.current = false;
      }
    }

    getSyncStatus();

    const interval = setInterval(() => getSyncStatus(), fetchInterval);
    return () => clearInterval(interval);
  }, [connectedToQBO, fetchInterval]);

  useEffect(
    () => setSyncStatus((prev) => (prev === "sync_complete" ? null : prev)),
    [pathname]
  );

  async function handleSyncStart() {
    setAnchor(null);
    const { success } = await jsonFetch("POST", "/sync/start?action=quick");
    if (success) {
      return setSyncStatus("sync_inprogress");
    } else {
      handleProgramError(new Error("GET syncStart: error in jsonFetch GET"));
    }
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
    }

    setAnchor(null);
  }

  const openMenu = Boolean(anchor);
  const menuId = openMenu ? "sync-menu" : undefined;

  return (
    <>
      {hasPrivilegesOrIsAdmin("Sync") && (
        <SyncMenuTopNavIcon
          syncStatus={syncStatus}
          onClick={(e) => setAnchor(e.currentTarget)}
          functionText={i18n("iconAltText.sync")}
        />
      )}
      <DropDownMenu
        id={menuId}
        open={openMenu}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
      >
        <MenuList
          id="sync-list-grow"
          onKeyDown={handleListKeyDown}
          sx={{ px: 1 }}
        >
          <MenuItemLink disabled={!connectedToQBO} onClick={handleSyncStart}>
            <NavListItemIcon>
              <FlashOn />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.syncMenu.syncNow")} />
          </MenuItemLink>
          <Divider />
          <MenuItemLink to="/syncitem" onClick={() => setAnchor(null)}>
            <NavListItemIcon>
              <ListAlt />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.syncMenu.previewSync")} />
          </MenuItemLink>
          <MenuItemLink to="/syncerror" onClick={() => setAnchor(null)}>
            <NavListItemIcon>
              <SyncProblem />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.syncMenu.syncErrors")} />
          </MenuItemLink>
          {isAdmin && (
            <div>
              <Divider />
              <MenuItemLink to="/account" onClick={() => setAnchor(null)}>
                <NavListItemIcon>
                  <Description />
                </NavListItemIcon>
                <MenuListItemText primary={i18n("topNav.syncMenu.accounts")} />
              </MenuItemLink>
              <MenuItemLink to="bill" onClick={() => setAnchor(null)}>
                <NavListItemIcon>
                  <Description />
                </NavListItemIcon>
                <MenuListItemText primary={i18n("topNav.syncMenu.bills")} />
              </MenuItemLink>
              <MenuItemLink to="/class" onClick={() => setAnchor(null)}>
                <NavListItemIcon>
                  <ViewColumn />
                </NavListItemIcon>
                <MenuListItemText primary={i18n("topNav.syncMenu.classes")} />
              </MenuItemLink>
              <MenuItemLink to="/creditmemo" onClick={() => setAnchor(null)}>
                <NavListItemIcon>
                  <Description />
                </NavListItemIcon>
                <MenuListItemText primary={i18n("topNav.syncMenu.credits")} />
              </MenuItemLink>
              <MenuItemLink to="/department" onClick={() => setAnchor(null)}>
                <NavListItemIcon>
                  <AccountTree />
                </NavListItemIcon>
                <MenuListItemText
                  primary={i18n("topNav.syncMenu.departments")}
                />
              </MenuItemLink>
              <MenuItemLink to="/employee" onClick={() => setAnchor(null)}>
                <NavListItemIcon>
                  <Description />
                </NavListItemIcon>
                <MenuListItemText primary={i18n("topNav.syncMenu.employees")} />
              </MenuItemLink>
              <MenuItemLink to="/journalentry" onClick={() => setAnchor(null)}>
                <NavListItemIcon>
                  <Description />
                </NavListItemIcon>
                <MenuListItemText primary={i18n("topNav.syncMenu.journals")} />
              </MenuItemLink>
              <MenuItemLink to="/purchase" onClick={() => setAnchor(null)}>
                <NavListItemIcon>
                  <Description />
                </NavListItemIcon>
                <MenuListItemText primary={i18n("topNav.syncMenu.purchases")} />
              </MenuItemLink>
              <MenuItemLink to="/taxcode" onClick={() => setAnchor(null)}>
                <NavListItemIcon>
                  <Description />
                </NavListItemIcon>
                <MenuListItemText primary={i18n("topNav.syncMenu.taxCodes")} />
              </MenuItemLink>
            </div>
          )}
        </MenuList>
      </DropDownMenu>
    </>
  );
}
