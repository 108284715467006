import { useState, useEffect } from "react";

import { TextField } from "@mui/material";

import { checkForUnexpectedProps } from "services/utility/misc";

export function RowBorderedTextField(props) {
  const {
    dataIndex,
    name,
    value: initialValue,
    onValueBlur,
    label,
    error,
    ...unexpected
  } = props;
  checkForUnexpectedProps("RowBorderedTextField", unexpected);

  const [value, setValue] = useState(initialValue);

  useEffect(() => setValue(initialValue), [initialValue]);

  return (
    <TextField
      label={label}
      name={name}
      autoComplete="off"
      margin="dense"
      error={error}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => onValueBlur(e.target?.name, e.target?.value, dataIndex)}
      value={value || ""}
      variant="outlined"
      fullWidth
      InputLabelProps={{ shrink: true }}
      inputProps={{
        style: {
          textAlign: "left",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      }}
    />
  );
}
