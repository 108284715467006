import { Decimal } from "classes/DecimalClasses";

import {
  updateLot,
  updateSerials,
  getUomConversion,
} from "services/utility/afterTouchLine";

// when this field (the key) changes, we should run the (value) list of
// updater functions
const UPDATERS = {
  uom: [updateOnHand],
  bin: [updateLot, updateSerials],
};

// this should be called each time an item receipt line item is modified;
// it returns a new copy of the line, with any necessary changes made
export function afterTouchInputLine(line, changedField) {
  if (!UPDATERS[changedField]) {
    return line;
  }

  let newLine = { ...line };

  UPDATERS[changedField].forEach((updater) => {
    newLine = updater(newLine);
  });
  return newLine;
}

function updateOnHand(line) {
  const {
    uom,
    relatedRecords: { item },
    itemDetails,
  } = line;

  // calculate the conversion using the item details UOM data
  const conversion = getUomConversion(itemDetails, uom);

  const onhand = item.id ? item.onhand.div(conversion) : new Decimal(0);
  return { ...line, onhand };
}

export const INPUT_ZERO_TOTALS = {
  quantity: new Decimal(0),
};

export function updateInputTotals(lines, setTotals) {
  if (!lines || lines.length === 0) {
    setTotals(INPUT_ZERO_TOTALS);
    return;
  }

  const quantity = lines.reduce((total, line) => {
    return total.plus(line.quantity);
  }, new Decimal(0));

  setTotals({ quantity });
}
