export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixRentalReturn: record.rentalReturnNumber.prefix,
    nextRentalReturnNumber: record.rentalReturnNumber.nextNumber,
  };

  delete newRecord.rentalReturnNumber;

  return newRecord;
}
