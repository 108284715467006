export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixPickTicket: record.pickTicketNumber.prefix,
    nextPickTicketNumber: record.pickTicketNumber.nextNumber,
  };

  delete newRecord.pickTicketNumber;

  return newRecord;
}
