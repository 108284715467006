import { useState } from "react";

import { Dialog } from "@mui/material";

import { QuickAdd } from "components/QuickAdd";

import { i18n } from "services/i18nService";

import { Vendor } from "views/Vendors/Vendor/Vendor";

export function QuickAddVendor(props) {
  const { open, onClose, initialNameValue, onQuickAdd, onAdd } = props;
  const [full, setFull] = useState(false);
  const [name, setName] = useState(initialNameValue);

  const dialogOptions = full
    ? {
        maxWidth: "md",
        fullWidth: true,
        scroll: "paper",
        style: { height: "100%" },
        PaperProps: { style: { minHeight: "93vh", maxHeight: "93vh" } },
      }
    : {};

  return (
    <Dialog open={open} onClose={onClose} {...dialogOptions}>
      {full ? (
        <Vendor
          key={open}
          name={name}
          onAdd={onAdd}
          onClose={onClose}
          fromForm={true}
        />
      ) : (
        <QuickAdd
          onValueChange={(_, value) => setName(value)}
          setFull={setFull}
          title={i18n("global.AddNewVendor")}
          onClose={onClose}
          name={name}
          onQuickAdd={() => onQuickAdd(name)}
          ctaText={i18n("global.AddMoreVendor")}
          label={i18n("global.Name")}
        />
      )}
    </Dialog>
  );
}
