import { Delete } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { formatDateTimeToDateTimeString } from "services/utility/formatting";

const ROW_VALUE_STYLE = { position: "relative", top: "5px" };
export function CommentTable(props) {
  const { commentHistory, currentComment, handleDelete } = props;

  return (
    <>
      <div>
        <b>{i18n("colon.Comment")} </b>
        {currentComment}
      </div>
      <VSpace space={1} />
      <div
        style={{
          fontWeight: "bold",
          marginBottom: "0.25rem",
        }}
      >
        {commentHistory.length > 0
          ? i18n("global.CommentHistory")
          : i18n("global.NoCommentHistory")}
      </div>
      {commentHistory.length > 0 && (
        <table style={{ marginRight: "1.5rem" }}>
          <thead>
            <tr>
              <th>{i18n("columns.Delete")}</th>
              <th>{i18n("columns.User")}</th>
              <th>{i18n("columns.DateTime")}</th>
              <th>{i18n("columns.Comment")}</th>
            </tr>
          </thead>
          <tbody>
            {commentHistory.map(({ user, date, message, id }, i) => {
              return (
                <tr key={i}>
                  <td style={{ textAlign: "center" }}>
                    <IconButton size="small" onClick={() => handleDelete(id)}>
                      <Delete fontSize="small" />
                    </IconButton>
                  </td>
                  <td style={ROW_VALUE_STYLE}>{user?.name}</td>
                  <td style={ROW_VALUE_STYLE}>
                    {formatDateTimeToDateTimeString(date)}
                  </td>
                  <td style={ROW_VALUE_STYLE}>{message}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
}
