import { LineItemTextField } from "components/formFields/LineItemTextField";
import { LineTableCell } from "components/formFields/LineTableCell";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineListPrice(props) {
  const { value, onValueChange, readOnly, objectType, error } = props;

  return (
    <LineTableCell sx={{ minWidth: "6rem" }}>
      <LineItemTextField
        money
        name="listPrice"
        value={value || ""}
        readOnly={readOnly}
        onValueChange={onValueChange}
        metadata={LINE_ITEM_METADATA[objectType].unitprice}
        error={error}
        dataTesting="listPrice"
      />
    </LineTableCell>
  );
}
