import { createSlice } from "@reduxjs/toolkit";

import {
  setItemInStorage,
  getItemInStorage,
} from "services/localStorageManager";

export const LEFT_NAV_TEXT = "left-nav-text";
export const LEFT_NAV_ICONS = "left-nav-icons";
export const LEFT_NAV = "left-nav";
export const LIST_TABLE_OFFSET_MAX = 13;
export const LIST_TABLE_OFFSET_MIN = 4;

export const leftNavSlice = createSlice({
  name: "leftNav",
  initialState: {
    iconsText: getItemInStorage(LEFT_NAV) || LEFT_NAV_TEXT,
    selectedMenuNode: null,
  },
  reducers: {
    toggle: (state) => {
      const iconsText =
        state.iconsText === LEFT_NAV_ICONS ? LEFT_NAV_TEXT : LEFT_NAV_ICONS;
      setItemInStorage(LEFT_NAV, iconsText);
      return {
        ...state,
        iconsText,
      };
    },
    setSelectedMenuNode: (state, action) => {
      return {
        ...state,
        selectedMenuNode: action.payload,
      };
    },
  },
});

export const { toggle, setSelectedMenuNode } = leftNavSlice.actions;
export default leftNavSlice.reducer;
