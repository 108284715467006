import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { useRelated } from "hooks/useRelated";

import { OBJECT_TYPES } from "appConstants";

export function FrmPaymentMethod(props) {
  const { value, onValueChange, maxWidth, label, name, disableClearable } =
    props;

  const paymentMethods = useRelated(OBJECT_TYPES.PAYMENT_METHOD.fullString);

  return (
    <FrmSelectFromObjects
      name={name}
      label={label}
      maxWidth={maxWidth}
      value={value}
      onValueChange={onValueChange}
      options={paymentMethods}
      disableClearable={disableClearable}
    />
  );
}
