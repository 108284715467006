import { LineItemText } from "components/formFields/LineItemText";
import { LineTableCell } from "components/formFields/LineTableCell";

export function LineVolumeAndVolumeUnit(props) {
  const { volume, volumeunit } = props;

  return (
    <LineTableCell sx={{ backgroundColor: "staticTableCell" }}>
      <LineItemText value={`${volume} ${volumeunit || ""}`} rightJustified />
    </LineTableCell>
  );
}
