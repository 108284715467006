import { useSelector, useDispatch } from "react-redux";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";

import { SortableColumnHeader } from "components/SortableColumnHeader";
import { TableCell } from "components/TableCell";
import { LineItemText } from "components/formFields/LineItemText";
import { LinkText } from "components/utility/LinkText";

import { i18n } from "services/i18nService";
import { formatMoney } from "services/utility/formatting";
import { formatDateTimeToDate } from "services/utility/formatting";

import { usePrivileges } from "hooks/usePrivileges";

import { openModal } from "globalState/modalSlice";

import { getObjectFromTypeString } from "appConstants";
import { USER_PRIVILEGE } from "appConstants";

export function List(props) {
  const { history, retrievalParams, setRetrievalParams } = props;
  const { hasPrivilegesOrIsAdmin } = usePrivileges();
  const dispatch = useDispatch();
  const homeCurrency = useSelector(
    (state) => state.userCompanySettings.settings.homeCurrency
  );

  function getHistoryString(historyEvent) {
    const { type, direction } = historyEvent;
    const { fullString } = getObjectFromTypeString(type);
    const lookupString = direction ? `${fullString}.${direction}` : fullString;
    return i18n(`itemEvent.${lookupString}`);
  }

  function handleColumnHeaderClick(sortName) {
    const newDirection =
      sortName === retrievalParams.sort
        ? retrievalParams.direction === "asc"
          ? "desc"
          : "asc"
        : "asc";
    setRetrievalParams((prevParams) => ({
      ...prevParams,
      sort: sortName,
      direction: newDirection,
      start: 1,
    }));
  }

  const canViewCosts = hasPrivilegesOrIsAdmin(USER_PRIVILEGE.viewCosts);

  return (
    <TableContainer component={Paper} sx={{ maxHeight: "calc(100vh - 210px)" }}>
      <Table size="small" stickyHeader>
        <TableHead>
          <TableRow sx={{ verticalAlign: "bottom" }}>
            <SortableColumnHeader label={i18n("columns.Date")} />
            <SortableColumnHeader
              isSortable
              label={i18n("columns.Event")}
              sortName="type"
              activeSortName={retrievalParams.sort}
              direction={retrievalParams.direction}
              onClick={handleColumnHeaderClick}
            />
            <SortableColumnHeader
              isSortable
              label={i18n("columns.ReferenceNumber")}
              sortName="refNumber"
              activeSortName={retrievalParams.sort}
              direction={retrievalParams.direction}
              onClick={handleColumnHeaderClick}
            />
            <SortableColumnHeader
              isSortable
              align="right"
              label={i18n("columns.TxnQty")}
              sortName="quantity"
              activeSortName={retrievalParams.sort}
              direction={retrievalParams.direction}
              onClick={handleColumnHeaderClick}
              maxWidth="8rem"
            />
            <SortableColumnHeader
              isSortable
              align="right"
              label={i18n("columns.TotalQty")}
              sortName="totalQuantity"
              activeSortName={retrievalParams.sort}
              direction={retrievalParams.direction}
              onClick={handleColumnHeaderClick}
              maxWidth="8rem"
            />
            {canViewCosts && (
              <SortableColumnHeader
                align="right"
                label={`${i18n("columns.CostBasis")} (${homeCurrency.code})`}
                maxWidth="8rem"
              />
            )}
            <SortableColumnHeader
              align="right"
              label={`${i18n("columns.Variance")} (${homeCurrency.code})`}
              maxWidth="8rem"
            />
            {canViewCosts && (
              <>
                <SortableColumnHeader
                  align="right"
                  label={`${i18n("columns.TotalCostBasis")} (${
                    homeCurrency.code
                  })`}
                  maxWidth="8rem"
                />
                <SortableColumnHeader
                  align="right"
                  label={`${i18n("columns.AvgValue")} (${homeCurrency.code})`}
                  maxWidth="8rem"
                />
                <SortableColumnHeader
                  align="right"
                  label={`${i18n("columns.TxnCostPerUnit")} (${
                    homeCurrency.code
                  })`}
                  maxWidth="10rem"
                />
              </>
            )}
            <SortableColumnHeader
              label={i18n("columns.Entity")}
              sortName="contactName"
              isSortable
              activeSortName={retrievalParams.sort}
              direction={retrievalParams.direction}
              onClick={handleColumnHeaderClick}
            />
            <SortableColumnHeader
              label={i18n("columns.FromLocation")}
              sortName="fromLocation"
              isSortable
              activeSortName={retrievalParams.sort}
              direction={retrievalParams.direction}
              onClick={handleColumnHeaderClick}
            />
            <SortableColumnHeader
              label={i18n("columns.ToLocation")}
              sortName="toLocation"
              activeSortName={retrievalParams.sort}
              direction={retrievalParams.direction}
              onClick={handleColumnHeaderClick}
              isSortable
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((h, index) => (
            <TableRow key={index}>
              <TableCell>
                <LineItemText value={formatDateTimeToDate(h.txnDate)} />
              </TableCell>
              <TableCell>
                <LineItemText value={getHistoryString(h)} />
              </TableCell>
              <TableCell>
                <LinkText
                  onClick={() =>
                    dispatch(
                      openModal({
                        objectType: getObjectFromTypeString(h.type).fullString,
                        modalProps: { id: h.id },
                      })
                    )
                  }
                >
                  {h.refNumber || `[${h.id}]`}
                </LinkText>
              </TableCell>
              <TableCell>
                <LineItemText decimal value={h.quantity} />
              </TableCell>
              <TableCell>
                <LineItemText decimal value={h.totalQuantity} />
              </TableCell>
              {canViewCosts && (
                <TableCell>
                  <LineItemText
                    rightJustified
                    value={formatMoney(h.costBasis, true, true)}
                  />
                </TableCell>
              )}
              <TableCell>
                <LineItemText
                  rightJustified
                  value={formatMoney(h.variance, true, true)}
                />
              </TableCell>
              {canViewCosts && (
                <>
                  <TableCell>
                    <LineItemText
                      rightJustified
                      value={formatMoney(h.totalCostBasis, true, true)}
                    />
                  </TableCell>
                  <TableCell>
                    <LineItemText
                      rightJustified
                      value={formatMoney(h.costPerUnit, true, true)}
                    />
                  </TableCell>
                  <TableCell>
                    <LineItemText
                      rightJustified
                      value={formatMoney(h.txnCostPerUnit, true, true)}
                    />
                  </TableCell>
                </>
              )}
              <TableCell>
                <LineItemText value={h.contactName} />
              </TableCell>
              <TableCell>
                <LineItemText
                  value={
                    h.fromBin.name
                      ? `${h.fromLocation.name} (bin ${h.fromBin.name})`
                      : h.fromLocation.name
                  }
                />
              </TableCell>
              <TableCell>
                <LineItemText
                  value={
                    h.toBin.name
                      ? `${h.toLocation.name} (bin ${h.toBin.name})`
                      : h.toLocation.name
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
