import { i18n } from "services/i18nService";

export const SUB_COMPONENT = "[sub-component]";

export const UNASSIGNED_OPTION = {
  id: 0,
  name: i18n("reports.FilterLabel.Unassigned"),
};

export const TOTAL_ONLY_FILTER_OPTION = {
  id: -1,
  name: i18n("filter.TotalOnly"),
};

export const ALL_OPTION_WITH_ALL_ID = {
  id: "All",
  name: i18n("filter.All"),
};

export const NONE_GROUP_BY_OPTION = { id: "None", name: i18n("global.None") };

export const GROUP_BY_CATEGORY_OR_VENDOR_OPTIONS = [
  NONE_GROUP_BY_OPTION,
  { id: "Category", name: i18n("global.Category") },
  { id: "Vendor", name: i18n("global.Vendor") },
];

export const GROUP_BY_ITEM_OR_VENDOR_OPTIONS = [
  NONE_GROUP_BY_OPTION,
  { id: "Item", name: i18n("global.Item") },
  { id: "Vendor", name: i18n("global.Vendor") },
];

export const GROUP_BY_ITEM_OR_CUSTOMER_OPTIONS = [
  NONE_GROUP_BY_OPTION,
  { id: "Item", name: i18n("global.Item") },
  { id: "Customer", name: i18n("global.Customer") },
];

export const GROUP_BY_ITEM_OPTIONS = [
  NONE_GROUP_BY_OPTION,
  { id: "Item", name: i18n("global.Item") },
];

export const FULFILLMENT_GROUP_BY_OPTIONS = [
  NONE_GROUP_BY_OPTION,
  { id: "Customer", name: i18n("reports.OptionLabel.Customer") },
  { id: "Item", name: i18n("reports.OptionLabel.Item") },
  { id: "Location", name: i18n("reports.OptionLabel.Location") },
];

export const ON_HAND_VALUE_CALCULATION_OPTION = {
  id: "On Hand",
  name: i18n("global.OnHand"),
};

export const ON_HAND_AND_AVAILABLE_VALUE_CALCULATION_OPTIONS = [
  ON_HAND_VALUE_CALCULATION_OPTION,
  { id: "Available", name: i18n("global.Available") },
];

export const VALUE_CALCULATION_OPTIONS = [
  ON_HAND_VALUE_CALCULATION_OPTION,
  { id: "Available", name: i18n("global.Available") },
  { id: "On SO", name: i18n("global.OnSO") },
  { id: "On SR", name: i18n("global.OnSR") },
  { id: "On PO", name: i18n("global.OnPO") },
  { id: "On RMA", name: i18n("global.OnRMA") },
  { id: "On WO", name: i18n("global.OnWO") },
  { id: "On Rental", name: i18n("global.OnRental") },
];

export const EXPIRED_RECALLED_OPTIONS = [
  ALL_OPTION_WITH_ALL_ID,
  {
    id: "NotExpiredNotRecalled",
    name: i18n("reports.FilterLabel.NotExpiredNotRecalled"),
  },
  { id: "Expired", name: i18n("reports.FilterLabel.Expired") },
  { id: "Recalled", name: i18n("reports.FilterLabel.Recalled") },
];

export const IN_STOCK_OPTION = {
  id: "InStock",
  name: i18n("reports.FilterLabel.InStock"),
};

export const INVENTORY_STATUS_OPTIONS = [
  ALL_OPTION_WITH_ALL_ID,
  IN_STOCK_OPTION,
  { id: "Used", name: i18n("reports.FilterLabel.Used") },
  { id: "Shipped", name: i18n("reports.FilterLabel.Shipped") },
  { id: "AdjustedOut", name: i18n("reports.FilterLabel.AdjustedOut") },
];

export const LOT_TRACE_OPTIONS = [
  { id: "CameFrom", name: "came from" },
  { id: "UsedIn", name: "went to" },
];

export const OPEN_CLOSED_OPTIONS = [
  ALL_OPTION_WITH_ALL_ID,
  { id: "Open", name: i18n("global.Open") },
  { id: "Closed", name: i18n("global.Closed") },
];

export const SALES_GROUP_BY_OPTIONS = [
  NONE_GROUP_BY_OPTION,
  { id: "Category", name: i18n("reports.OptionLabel.Category") },
  { id: "Channel", name: i18n("reports.OptionLabel.Channel") },
  { id: "Class", name: i18n("reports.OptionLabel.Class") },
  { id: "Customer", name: i18n("reports.OptionLabel.Customer") },
  { id: "Department", name: i18n("reports.OptionLabel.Department") },
  { id: "Item", name: i18n("reports.OptionLabel.Item") },
  { id: "Location", name: i18n("reports.OptionLabel.Location") },
  { id: "Sales Rep", name: i18n("reports.OptionLabel.SalesRep") },
  { id: "Vendor", name: i18n("reports.OptionLabel.Vendor") },
];

export const SALES_ORDER_ORDER_TYPE_OPTION = {
  id: "SO",
  name: i18n("objectType.salesorder.SentencePlural"),
};

export const SALES_ORDER_TYPE_OPTIONS = [
  { id: "Estimates", name: i18n("objectType.estimate.SentencePlural") },
  { id: "Invoices", name: i18n("objectType.invoice.SentencePlural") },
  SALES_ORDER_ORDER_TYPE_OPTION,
  { id: "SR", name: i18n("objectType.salesreceipt.SentencePlural") },
  { id: "Returns", name: i18n("objectType.return.SentencePlural") },
];

export const FULFILLMENT_ORDER_TYPE_OPTIONS = [
  ALL_OPTION_WITH_ALL_ID,
  SALES_ORDER_ORDER_TYPE_OPTION,
  { id: "SR", name: i18n("objectType.salesreceipt.SentencePlural") },
];

export const DATE_SETTINGS = ["date1", "date2", "paymentDate1", "paymentDate2"];
export const DATE_MACRO_SETTINGS = [
  "date1Macro",
  "date2Macro",
  "paymentDate1Macro",
  "paymentDate2Macro",
];
export const REFERENCE_OBJECT_SETINGS = [
  "classId",
  "channelId",
  "customerTypeId",
  "customerId",
  "departmentId",
  "itemId",
  "priceGroupId",
  "vendorId",
];

export const LOCATION_CATEGORY_SETTINGS = ["locations", "categories"];
export const ARRAY_SETTINGS = ["users", "salesReps"];
export const OBJECT_SETTINGS = ["groupBy", "valueCalculation", "orderType"];

export const STANDARD_REPORT = 1;
export const CUSTOM_REPORT = 2;
export const SCHEDULED_REPORT = 3;

let times = [];
for (let i = 0; i < 24; i++) {
  times.push({
    id: i.toString(),
    name: i18n(`reports.ScheduledReportOptions.Times.${i.toString()}`),
  });
}
export const SCHEDULED_REPORT_OPTIONS = {
  FREQUENCY: [
    {
      id: "Daily",
      name: i18n("reports.ScheduledReportOptions.Frequency.Daily"),
    },
    {
      id: "Weekly",
      name: i18n("reports.ScheduledReportOptions.Frequency.Weekly"),
    },
    {
      id: "Monthly",
      name: i18n("reports.ScheduledReportOptions.Frequency.Monthly"),
    },
  ],
  DAYS: [
    { id: "Monday", name: i18n("reports.ScheduledReportOptions.Days.Monday") },
    {
      id: "Tuesday",
      name: i18n("reports.ScheduledReportOptions.Days.Tuesday"),
    },
    {
      id: "Wednesday",
      name: i18n("reports.ScheduledReportOptions.Days.Wednesday"),
    },
    {
      id: "Thursday",
      name: i18n("reports.ScheduledReportOptions.Days.Thursday"),
    },
    { id: "Friday", name: i18n("reports.ScheduledReportOptions.Days.Friday") },
    {
      id: "Saturday",
      name: i18n("reports.ScheduledReportOptions.Days.Saturday"),
    },
    { id: "Sunday", name: i18n("reports.ScheduledReportOptions.Days.Sunday") },
  ],
  TIMES: times,
};
