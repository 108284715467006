import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import { WideSelectPopper } from "components/WideSelectPopper";
import { RowSelectBase } from "components/formFields/row/RowSelectBase";
import { ButtonProgress } from "components/utility/ButtonProgress";
import Truncate from "components/utility/Truncate";

import { getItemByNameBarcodeSku } from "services/sosInventoryService/sosApi";
import { checkForUnexpectedProps } from "services/utility/misc";

export function RowItemSelect(props) {
  const {
    onValueChange,
    dataIndex,
    value,
    label,
    options: initialOptions,
    onInputChange,
    onBlur: externalBlur,
    fetchItemsOnChange,
    dataTesting,
    ...unexpected
  } = props;
  checkForUnexpectedProps("RowItemSelect", unexpected);

  const [itemLoading, setItemLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const [options, setOptions] = useState(initialOptions);

  const searchByAnyPartOfItem = useSelector(
    (state) => state.userCompanySettings.settings.searchByAnyPartOfItem
  );

  useEffect(() => setCurrentValue(value), [value]);

  useEffect(() => setOptions(initialOptions), [initialOptions]);

  // this gets called whenever the user changes the selected option
  function handleChange(_, newValue, reason) {
    if (reason === "clear") {
      setCurrentValue(null);
      onValueChange("item", null, dataIndex);
      return;
    }

    setCurrentValue(newValue);
    onValueChange("item", newValue, dataIndex);
  }

  async function onBlur(e) {
    externalBlur && externalBlur();
    if (!currentValue?.id) {
      // ...look up by name, barcode, and SKU
      setItemLoading(true);
      const items = await getItemByNameBarcodeSku(e.target.value);
      if (items.length === 1) {
        const [item] = items;
        onValueChange("item", item, dataIndex);
      }
      setItemLoading(false);
    }
  }

  // this gets called when the component needs to know what to display
  // in the input field; not the value, which is the item id, but the
  // human-friendly *name* of the item
  function getOptionLabel(option) {
    // option or option.id can be blank on a newly inserted line;
    // and if we're adding a new item we don't want any text in the
    // component
    if (!Boolean(option?.id)) {
      return "";
    }
    if (currentValue?.name) {
      return currentValue.name;
    }
    const selectedOption = options.find(({ id }) => id === option.id);
    return selectedOption ? selectedOption.name : "";
  }

  // this gets called for each option, when the selected option changes, to
  // determine which, of all the options, was selected
  function isOptionEqualToValue(option, value) {
    return option.id === value?.id || (value === "" && option.id === "");
  }

  // this gets called when the user changes what is in the input field; it
  // should return an array of all the options that meet whatever criteria
  // we want to use; in the current case, we're just looking for a simple
  // substring match
  function filterOptions(options, state) {
    return options.filter(({ id, name, description }) => {
      if (id === null) {
        return false;
      }
      // if we're making API calls for queries, the list of options will
      // always be matches, so no need to check; however, if there's a value
      // already selected, skip this and match against that value's name,
      // below
      if (fetchItemsOnChange && !currentValue?.name) {
        return true;
      }

      // when focusing back to this field, with a value already selected,
      // state.inputValue will be null (the user hasn't typed anything new
      // here); so use the currentValue instead; if no current value, set
      // the value to match against to ""
      const matchValue = state.inputValue
        ? state.inputValue
        : currentValue?.name || "";

      if (searchByAnyPartOfItem) {
        return `${name} ${description}`
          .toLowerCase()
          .includes(matchValue.toLowerCase());
      }

      return (
        name.toLowerCase().startsWith(matchValue.toLowerCase()) ||
        description?.toLowerCase()?.includes(matchValue.toLowerCase())
      );
    });
  }

  function renderOption(props, option) {
    return (
      <div
        {...props}
        data-testing="selectOption"
        style={{ minHeight: "1.5em" }}
        key={option.id}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ minHeight: "1.2em", width: "45%" }}>
            {option.name}
            {option.sku && (
              <Box sx={{ color: "secondary.main" }}>{option.sku}</Box>
            )}
          </div>
          <div style={{ width: "10%" }} />
          <div style={{ width: "45%" }}>
            <Truncate lines={3}>{option.description}</Truncate>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      <RowSelectBase
        renderOption={renderOption}
        isOptionEqualToValue={isOptionEqualToValue}
        filterOptions={filterOptions}
        getOptionLabel={getOptionLabel}
        onValueChange={handleChange}
        PopperComponent={WideSelectPopper}
        options={options}
        onInputChange={onInputChange}
        onBlur={onBlur}
        value={currentValue}
        label={label}
        dataTesting={dataTesting}
      />
      {itemLoading && <ButtonProgress dataTesting="itemLoading" />}
    </div>
  );
}
