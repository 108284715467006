import { moreTransactionBeforeSave } from "services/sosInventoryService/moreTransaction/beforeSave";
import { nonUtcDateTimeHandling } from "services/utility/beforeSave";

import { OBJECT_TYPES } from "appConstants";

const OBJECT_TYPE = OBJECT_TYPES.RMA.fullString;

export function beforeSave(record) {
  let newRecord = moreTransactionBeforeSave(OBJECT_TYPE, record);
  newRecord = nonUtcDateTimeHandling(newRecord);
  return newRecord;
}
