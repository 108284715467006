import { Popper, Paper, ClickAwayListener } from "@mui/material";

import { theme } from "SosTheme";

export function DropDownMenu(props) {
  const { children, onClose, id, open, anchorEl, width } = props;

  const sx = {
    width,
    overflow: "hidden",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      background: theme.palette.primary.main,
      width: "34px",
      height: "4px",
      top: "0",
      right: "0",
    },
  };

  return (
    <>
      <Popper
        sx={{ zIndex: "dropDownMenu" }}
        placement="bottom-end"
        role={undefined}
        disablePortal
        onClose={onClose}
        id={id}
        open={open}
        anchorEl={anchorEl}
      >
        <Paper sx={sx} elevation={8}>
          <ClickAwayListener onClickAway={onClose}>
            {children}
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
}
