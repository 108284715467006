import { Box, Card, CardContent } from "@mui/material";

import { ActionButton } from "components/ActionButton";

import { handleProgramError } from "services/utility/errors";

export function TestError() {
  function testError() {
    try {
      const testingError = null;
      return testingError.log;
    } catch (e) {
      handleProgramError(e);
    }
  }

  return (
    <Box sx={{ display: "grid" }} m={2} mb={0}>
      <Card variant="outlined">
        {/* Set visibility to hidden in case a user stumbles upon route */}
        <CardContent sx={{ visibility: "hidden" }}>
          <ActionButton onClick={testError}>Test error</ActionButton>
        </CardContent>
      </Card>
    </Box>
  );
}
