import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { AttachFile } from "@mui/icons-material";
import { Box, Typography, Pagination, IconButton } from "@mui/material";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";

import { DocumentDialog } from "components/DocumentDialog";
import { SortableColumnHeader } from "components/SortableColumnHeader";
import { SYNC_MENU_CONFIG } from "components/SyncPage/syncMenuConfig";
import { TableCell } from "components/TableCell";
import { Tooltip } from "components/Tooltip";
import { LineItemText } from "components/formFields/LineItemText";

import { i18n } from "services/i18nService";
import { getListPageRecords } from "services/sosInventoryService/sosApi";
import { getPageHeight, getPageWidth } from "services/utility/misc";

import {
  ACCOUNT_NUMBER_SEPARATE_FIELD,
  createIndentedChartOfAccounts,
} from "hooks/useChartOfAccounts";

import {
  loadingIndicatorOn,
  loadingIndicatorOff,
} from "globalState/loadingSlice";

import { getObjectFromFullString } from "appConstants";

const MAX_RESULTS = 100;

export function SyncList(props) {
  const { objectType } = props;
  const dispatch = useDispatch();
  const syncConfig = SYNC_MENU_CONFIG[objectType];

  const defaultSort = syncConfig.columns.find(({ defaultSort }) => defaultSort);

  const [records, setRecords] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [getParams, setGetParams] = useState({
    start: 1,
    maxresults: MAX_RESULTS,
    sort: defaultSort?.apiName || "",
    direction: defaultSort?.sortDirection || "asc",
  });

  useEffect(() => {
    async function getRecords() {
      dispatch(loadingIndicatorOn());
      const fullObject = getObjectFromFullString(objectType);
      const response = await getListPageRecords(
        objectType,
        getParams,
        fullObject?.endpointUrl
      );
      if (objectType === "account") {
        const indentedAccounts = createIndentedChartOfAccounts(
          response.data,
          ACCOUNT_NUMBER_SEPARATE_FIELD
        );
        setRecords(indentedAccounts);
      } else {
        setRecords(response.data);
      }
      setTotalCount(response.totalCount);
      dispatch(loadingIndicatorOff());
    }
    getRecords();
  }, [getParams, dispatch, objectType]);

  function handleSort(columnClicked) {
    setGetParams((prevParams) => ({
      ...prevParams,
      start: 1,
      sort: columnClicked,
      direction: prevParams.direction === "asc" ? "desc" : "asc",
    }));
  }

  function handlePageChange(_, page) {
    setGetParams((prevParams) => ({
      ...prevParams,
      start: (page - 1) * MAX_RESULTS + 1,
    }));
  }

  if (!records) {
    return null;
  }

  return (
    <Box
      sx={{
        p: 2,
        pb: 1,
        display: "grid",
        minWidth: getPageWidth(),
        maxHeight: getPageHeight(),
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          mb: 0.5,
        }}
      >
        <Box>
          <Typography variant="h2">
            {i18n(`objectType.${objectType}.SentencePlural`)}
          </Typography>
          {syncConfig.note && <Typography>{syncConfig.note}</Typography>}
        </Box>
        <Pagination
          page={(getParams.start - 1) / MAX_RESULTS + 1}
          sx={{ float: "right", mb: 0.5 }}
          size="small"
          shape="rounded"
          siblingCount={1}
          count={Math.ceil(totalCount / MAX_RESULTS)}
          onChange={handlePageChange}
        />
      </Box>
      {records && (
        <Box boxShadow={1} sx={{ overflow: "auto", height: "100%" }}>
          <TableContainer component={Paper} sx={{ height: "100%" }}>
            <Table size="small">
              <TableHead>
                <TableRow style={{ verticalAlign: "bottom" }}>
                  {syncConfig.columns.map((column, index) => {
                    return (
                      <SortableColumnHeader
                        key={index}
                        align={column.align}
                        isSortable={column.sortable}
                        minWidth={column.minWidth}
                        label={column.heading}
                        sortName={column.apiName}
                        activeSortName={getParams.sort}
                        direction={getParams.direction}
                        onClick={handleSort}
                        backgroundColor="white"
                      />
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {records.map((record, index) => (
                  <Row
                    record={record}
                    setRecords={setRecords}
                    objectType={objectType}
                    index={index}
                    key={index}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
}

export function Row(props) {
  const { record, setRecords, index, objectType } = props;
  const syncConfig = SYNC_MENU_CONFIG[objectType];
  const [showAddDocument, setShowAddDocument] = useState(false);

  function handleDocumentUpdate(documents) {
    setRecords((prev) =>
      prev.map((e, i) => {
        if (index === i) {
          e.documents = documents;
        }
        return e;
      })
    );
  }

  return (
    <>
      <TableRow>
        {syncConfig.columns.map((column, index) => {
          const {
            formatFunc,
            maxWidth,
            apiName,
            apiFunc,
            isDocument,
            align = "left",
          } = column;
          const value = formatFunc
            ? formatFunc(record[apiName], record, column, apiFunc)
            : record[apiName];

          if (isDocument) {
            return (
              <TableCell
                key={index}
                sx={{ textAlign: align, position: "relative" }}
              >
                <Box
                  sx={{
                    p: 0,
                    whiteSpace: "pre-line",
                    wordWrap: "break-word",
                    fontSize: "0.8125rem",
                    verticalAlign: "initial",
                    pr: "1em",
                    maxWidth,
                  }}
                >
                  {value}
                </Box>
                <Box sx={{ position: "absolute", right: 5, top: 7 }}>
                  <Tooltip title={i18n("global.Documents")}>
                    <IconButton
                      size="small"
                      sx={{ p: 0 }}
                      onClick={() => setShowAddDocument(true)}
                    >
                      <AttachFile fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            );
          }

          return (
            <TableCell
              sx={{
                textAlign: align,
                maxWidth,
              }}
              key={index}
            >
              <LineItemText
                value={value}
                sx={{
                  p: 0,
                  whiteSpace: "pre-line",
                  wordWrap: "break-word",
                  fontSize: "0.8125rem",
                  verticalAlign: "initial",
                }}
                variant="div"
              />
            </TableCell>
          );
        })}
      </TableRow>
      {showAddDocument && (
        <DocumentDialog
          id={record.id}
          objectType={objectType}
          open={showAddDocument}
          closeDocumentDialog={() => setShowAddDocument(false)}
          setDocuments={handleDocumentUpdate}
          documents={record.documents}
        />
      )}
    </>
  );
}
