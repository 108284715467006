import { createSlice } from "@reduxjs/toolkit";

export const modalSlice = createSlice({
  name: "modal",
  initialState: { open: false },
  reducers: {
    openModal: (state, action) => {
      return {
        ...state,
        ...action.payload,
        open: true,
      };
    },
    closeModal: () => ({ open: false }),
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export default modalSlice.reducer;
