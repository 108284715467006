import { useSelector } from "react-redux";

import { LineItemAutocompleteField } from "components/formFields/LineItemAutocompleteField";
import { LineTableCell } from "components/formFields/LineTableCell";

export function LineTaxCode(props) {
  const { value, taxCodes, onValueChange } = props;

  const globalEnabled = useSelector(
    (state) => state.userCompanySettings.settings.globalEnabled
  );

  return (
    globalEnabled && (
      <LineTableCell sx={{ minWidth: "8rem" }}>
        <LineItemAutocompleteField
          name="taxCode"
          options={taxCodes}
          value={value}
          onValueChange={onValueChange}
          allowTooltip
          disableClearable
        />
      </LineTableCell>
    )
  );
}
