import { i18n } from "services/i18nService";
import { getDocument } from "services/sosInventoryService/sosApi";
import { formatDocumentInfo } from "services/utility/formatting";

export const employeeConfig = {
  columns: [
    {
      heading: i18n("columns.DisplayName"),
      apiName: "name",
      sortable: true,
      defaultSort: true,
    },
    {
      heading: i18n("columns.PhoneNumber"),
      apiName: "phone",
      sortable: true,
    },
    {
      heading: i18n("columns.Email"),
      apiName: "email",
      sortable: true,
    },
    {
      heading: i18n("columns.HireDate"),
      apiName: "hireDate",
      sortable: true,
    },
    {
      heading: i18n("columns.Documents"),
      apiName: "documents",
      formatFunc: formatDocumentInfo,
      apiFunc: getDocument,
      isDocument: true,
      maxWidth: "12rem",
    },
  ],
};
