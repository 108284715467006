import { SETTINGS as customerPortal } from "views/Configuration/settings/sales/customerPortal";
import { SETTINGS as estimates } from "views/Configuration/settings/sales/estimates";
import { SETTINGS as general } from "views/Configuration/settings/sales/general";
import { SETTINGS as invoices } from "views/Configuration/settings/sales/invoices";
import { SETTINGS as returns } from "views/Configuration/settings/sales/returns";
import { SETTINGS as rmas } from "views/Configuration/settings/sales/rmas";
import { SETTINGS as salesOrders } from "views/Configuration/settings/sales/salesOrders";
import { SETTINGS as salesReceipts } from "views/Configuration/settings/sales/salesReceipts";

export const SETTINGS = {
  estimates,
  general,
  invoices,
  returns,
  rmas,
  salesOrders,
  salesReceipts,
  customerPortal,
};
