import { KeyboardArrowDown } from "@mui/icons-material";
import { Autocomplete, TextField } from "@mui/material";

import { LineSelectPopper } from "components/formFields/LineSelectPopper";
import {
  textFieldStyle,
  outlinedStyles,
  inputPropStyles,
} from "components/formFields/lineItemFieldStyle";

import { checkForUnexpectedProps } from "services/utility/misc";

import PropTypes from "prop-types";

export function RowSelectBase(props) {
  const {
    name,
    options,
    onValueChange,
    onInputChange,
    onBlur,
    isOptionEqualToValue,
    filterOptions,
    value,
    label,
    disableClearable,
    getOptionLabel,
    renderOption,
    dataTesting,
    PopperComponent = LineSelectPopper,
    error,
    ...unexpected
  } = props;
  checkForUnexpectedProps("RowSelectBase", unexpected);

  function renderInput(params) {
    const textfield = (
      <TextField
        {...params}
        error={error}
        variant="outlined"
        label={label}
        margin="dense"
        fullWidth
        sx={error ? textFieldStyle : { ...textFieldStyle, ...outlinedStyles }}
        InputProps={{ ...params.InputProps, sx: inputPropStyles }}
        InputLabelProps={{ shrink: true }}
        SelectProps={{ MenuProps: { disableScrollLock: true } }}
      />
    );
    return textfield;
  }

  return (
    <Autocomplete
      name={name}
      value={value}
      loading={!Array.isArray(options)}
      options={options || []}
      onChange={onValueChange}
      filterOptions={filterOptions}
      onInputChange={onInputChange}
      onBlur={onBlur}
      PopperComponent={PopperComponent}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={renderOption}
      fullWidth
      disableClearable={disableClearable}
      popupIcon={<KeyboardArrowDown sx={{ color: "selectIcon" }} />}
      renderInput={renderInput}
      ListboxProps={{ "data-testing": "autocompleteOptions" }}
      data-testing={dataTesting}
    />
  );
}

RowSelectBase.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  renderOption: PropTypes.func.isRequired,
  isOptionEqualToValue: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  error: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  disableClearable: PropTypes.bool,
  dataTesting: PropTypes.string,
};
