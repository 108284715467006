import { ReportMultiSelect } from "components/formFields/ReportMultiSelect";

import { i18n } from "services/i18nService";

import { ALL_FILTER_OPTION } from "appConstants";

export function LocationsFilter(props) {
  const { value, name, onValueChange, locations, placeholder } = props;

  return (
    <ReportMultiSelect
      name={name}
      options={locations ? [ALL_FILTER_OPTION, ...locations] : locations}
      onValueChange={onValueChange}
      value={value}
      placeholder={placeholder}
      label={i18n("filter.Locations")}
    />
  );
}
