import { Avatar, Box } from "@mui/material";

function getNameInitials(name) {
  const array = name.split(" ").map((name) => name[0]);
  return array.length < 3
    ? array.join("")
    : `${array[0]}${array[array.length - 1]}`;
}

export function TopNavAvatar(props) {
  const { name, onClick, altText, dataTesting } = props;

  return (
    <Box
      onClick={onClick}
      sx={{ cursor: "pointer" }}
      data-testing={dataTesting}
    >
      <Avatar sx={{ width: 25, height: 25, fontSize: "0.75rem" }} alt={altText}>
        {getNameInitials(name)}
      </Avatar>
    </Box>
  );
}
