import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/customerHistory/schema";
import {
  transformFields,
  transformLineItemFields,
} from "services/utility/afterGet";
import { TYPES } from "services/utility/schema";

const LINE_ITEM_FIELDS = {
  [TYPES.number]: [],
  [TYPES.decimal]: ["quantity"],
  [TYPES.money]: ["unitprice", "amount"],
  [TYPES.reference]: [],
  [TYPES.date]: [],
  [TYPES.itemField]: [],
};
export function afterGet(record) {
  const newRecord = transformFields(record, FIELDS_TO_TRANSFORM);
  newRecord.lines = transformLineItemFields(newRecord.lines, LINE_ITEM_FIELDS);
  return newRecord;
}
