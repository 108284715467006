import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import { formatDecimal, extractName } from "services/utility/formatting";

export const onWo = {
  relatedObjectType: "workorder",
  headerText: i18n("modalHeader.onWo"),
  note: i18n("notes.onWo"),
  columns: [
    {
      jsonName: "refNumber",
      label: i18n("columns.ReferenceNumber"),
      linkColumn: true,
      sortable: true,
    },
    {
      jsonName: "txnDate",
      label: i18n("columns.TxnDate"),
      formatFunc: formatDate,
      sortable: true,
    },
    {
      jsonName: "location",
      label: i18n("columns.Location"),
      formatFunc: extractName,
      sortable: true,
    },
    {
      jsonName: "item",
      label: i18n("columns.Item"),
      formatFunc: extractName,
      sortable: true,
    },
    {
      jsonName: "quantity",
      label: i18n("columns.Quantity"),
      formatFunc: formatDecimal,
      number: true,
    },
    {
      jsonName: "produced",
      label: i18n("columns.Produced"),
      formatFunc: formatDecimal,
      number: true,
    },
    { jsonName: "memo", label: i18n("columns.Memo") },
  ],
  FIELDS_TO_TRANSFORM: {
    DECIMAL_FIELDS: ["quantity", "produced"],
    MONEY_FIELDS: [],
    DATE_FIELDS: ["txnDate"],
    DATETIME_FIELDS: [],
    NON_UTC_DATETIME_FIELDS: [],
  },
};
