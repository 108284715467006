// these functions manipulate a collection of transaction line items,
// which is passed in as a parameter; they must return a similar
// collection, transformed as appropriate
import { useReducer } from "react";

import { handleProgramError } from "services/utility/errors";

import {
  EMPTY_LINE_ITEM,
  EMPTY_OUTPUT_LINE_ITEM,
  EMPTY_INPUT_LINE_ITEM,
} from "editConfig";

function renumberLines(lines) {
  return lines.map((line, index) => {
    return { ...line, lineNumber: index + 1 };
  });
}

function reducer(oldLines, action) {
  // set page dirty for all line updates
  let newLines, insertAt, newLine, lines;
  switch (action.type) {
    case "set":
      // required: action.lines, set of lines which will become
      // the value of the lines state
      return renumberLines(action.lines);

    case "update":
      // required: action.updatedLine
      const { updatedLine } = action;
      newLines = [...oldLines];
      // lineNumbers are 1-based; array indices are 0-based, ergo:
      const index = updatedLine.lineNumber - 1;
      newLines[index] = updatedLine;
      return newLines;

    case "insert":
      // required: action.insertAt, action.newLine; inserts newLine
      // at insertAt
      ({ insertAt, newLine } = action);
      // some housekeeping: be sure the new line has no id, and has
      // a relatedRecords property
      if (!newLine.relatedRecords) {
        newLine.relatedRecords = {};
      }
      delete newLine.id;
      newLines = [...oldLines];
      newLines.splice(insertAt - 1, 0, newLine);
      return renumberLines(newLines);
    case "insertMany":
      ({ insertAt, lines } = action);
      newLines = [...oldLines];
      newLines.splice(insertAt - 1, 0, ...lines);
      return renumberLines(newLines);

    case "delete":
      // required: action.deleteAt, line number to delete (1-based)
      const { deleteAt } = action;
      newLines = [...oldLines];
      newLines.splice(deleteAt - 1, 1);
      return renumberLines(newLines);

    case "clear":
      // required: action.blankLine (to replace each current line)
      const { blankLine } = action;
      const clearedLines = oldLines.map(() => {
        return { ...blankLine };
      });
      return renumberLines(clearedLines);

    case "append":
      // required: action.newLines
      ({ newLines } = action);
      const oldLinesWithItem = oldLines.filter(({ item }) => item.id);
      return renumberLines(oldLinesWithItem.concat(newLines));

    case "appendEmpty":
      // required: action.numLinesToAdd
      // required: action.objectType
      const { numLinesToAdd, objectType, lineType = "line" } = action;
      newLines = [...oldLines];
      for (let i = 0; i < numLinesToAdd; i++) {
        // added check for functions in order to calculate values at transaction time
        let emptyLine;
        if (lineType === "line") {
          emptyLine = EMPTY_LINE_ITEM[objectType];
        }
        if (lineType === "outputs") {
          emptyLine = EMPTY_OUTPUT_LINE_ITEM[objectType];
        }
        if (lineType === "inputs") {
          emptyLine = EMPTY_INPUT_LINE_ITEM[objectType];
        }
        Object.entries(emptyLine).forEach(([key, value]) => {
          if (typeof value === "function") {
            emptyLine[key] = value();
          }
        });
        newLines.push(emptyLine);
      }
      return renumberLines(newLines);

    case "fillFieldFromFirstLine":
      // required: action.field
      // copy the value from the [field] field in line 1 to the other lines
      const { field } = action;
      return oldLines.map((line) => ({
        ...line,
        [field]: oldLines[0][field],
      }));
    case "sort":
      // required: action.direction, sets the direction of the sort -> "asc" vs "desc"
      // required: action.sortBy, sets the field on which to match on
      const isAscending = action.direction === "asc";
      const sortBy = action.sortBy;
      newLines = oldLines.sort((a, b) => {
        if (a.item[sortBy] < b.item[sortBy]) {
          return isAscending ? -1 : 1;
        }
        if (a.item[sortBy] > b.item[sortBy]) {
          return isAscending ? 1 : -1;
        }
        return 0;
      });
      return renumberLines(newLines);

    default:
      handleProgramError(
        new Error("useLineHandling | unexpected action.type: " + action.type)
      );
  }
}

export function useLineHandler() {
  const [state, dispatch] = useReducer(reducer, null);
  return [state, dispatch];
}
