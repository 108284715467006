import { Decimal, Money } from "classes/DecimalClasses";
import { Loading } from "classes/Loading";

import { calculateSalesItemPrice } from "services/sosInventoryService/salesTransaction/domainLogic";
import {
  afterTouchLine as baseAfterTouchLine,
  updateWeightAndVolume,
  updateAmount,
  getUomConversion,
} from "services/utility/afterTouchLine";
import {
  isNonZeroDecimalOrMoney,
  calculateMarginPercent,
} from "services/utility/misc";

import globalState from "globalState/globalState";

import { getSalesDecimalPlaces } from "appConstants";
import {
  DEFAULT_DECIMALS_UNROUNDED,
  DEFAULT_DECIMALS_ROUNDED,
} from "appConstants";

// when this field (the key) changes, we should run the (value) list of
// updater functions
const UPDATERS = {
  costLoadingWithMarkupItem: [
    updateWeightAndVolume,
    setCostAndMarginToLoading,
    setUnitPriceAndAmountToLoading,
  ],
  quantity: [
    updateUnitPrice,
    roundUnitPrice,
    updatePercentDiscount,
    updateMargin,
    updateAmount,
  ],
  quantityCostLoading: [
    updateUnitPrice,
    roundUnitPrice,
    updatePercentDiscount,
    updateAmount,
    updateWeightAndVolume,
    setCostAndMarginToLoading,
  ],
  quantityCostLoadingNoPriceUpdate: [
    updateWeightAndVolume,
    setCostAndMarginToLoading,
  ],
  quantityNoPriceUpdate: [updateMargin, updateAmount],
  uomWithCostLoading: [
    updateAvailable,
    updateListPrice,
    updateUnitPriceByDiscount,
    roundUnitPrice,
    updateAmount,
    updateWeightAndVolume,
    setCostAndMarginToLoading,
  ],
  cost: [updateMargin],
  unitprice: [
    addUserHasSetUnitPrice,
    updatePercentDiscount,
    updateMargin,
    updateAmount,
  ],
  listPrice: [updatePercentDiscount],
  amount: [updateUnitPriceByAmt, updateMargin, updatePercentDiscount],
  uom: [
    updateAvailable,
    updateListPrice,
    updateUnitPriceByDiscount,
    roundUnitPrice,
    updateMargin,
    updateAmount,
  ],
  item: [
    removeUserHasSetUnitPrice,
    roundUnitPrice,
    updatePercentDiscount,
    updateAmount,
    updateWeightAndVolume,
  ],
  percentdiscount: [
    updateUnitPriceByDiscount,
    roundUnitPrice,
    updateMargin,
    updateAmount,
  ],
  margin: [
    updateUnitPriceByMargin,
    roundUnitPrice,
    updatePercentDiscount,
    updateAmount,
  ],
  priceTier: [
    roundUnitPrice,
    updatePercentDiscount,
    updateMargin,
    updateAmount,
  ],
  available: [updateAvailable],
};

export function afterTouchLine(line, changedField, record) {
  return baseAfterTouchLine(UPDATERS, line, changedField, record);
}

function updatePercentDiscount(line) {
  const { unitprice, listPrice } = line;
  const percentdiscount = calculateDiscountPercent(listPrice, unitprice);
  return { ...line, percentdiscount };
}

function addUserHasSetUnitPrice(line) {
  return { ...line, userHasSetUnitPrice: true };
}

function removeUserHasSetUnitPrice(line) {
  return { ...line, userHasSetUnitPrice: false };
}

function updateUnitPrice(line, record) {
  const { uom, cost, quantity, itemDetails, item } = line;
  const { priceTier } = record;
  const itemData = { ...itemDetails, id: item.id };
  const unitprice = item
    ? calculateSalesItemPrice(
        itemData,
        quantity,
        priceTier,
        uom,
        itemDetails.itemUoms,
        cost
      )
    : new Decimal(0);
  return { ...line, unitprice };
}

function updateListPrice(line, record) {
  const { uom, cost, quantity, itemDetails, item } = line;
  const { priceTier } = record;
  const itemData = { ...itemDetails, id: item.id };
  const listPrice = item
    ? calculateSalesItemPrice(
        itemData,
        quantity,
        priceTier,
        uom,
        itemDetails.itemUoms,
        cost
      )
    : new Decimal(0);
  return { ...line, listPrice };
}

function updateUnitPriceByDiscount(line) {
  const { percentdiscount, listPrice } = line;
  const discount = isNonZeroDecimalOrMoney(percentdiscount)
    ? new Decimal(1).minus(percentdiscount.times(new Decimal(0.01)))
    : new Decimal(1);

  let unitprice = listPrice ? listPrice.times(discount) : new Money(0);
  return { ...line, unitprice };
}

function updateAvailable(line) {
  const {
    uom,
    relatedRecords: { item },
    itemDetails,
  } = line;

  if (!item?.available) {
    return { ...line, available: null };
  }

  // calculate the conversion using the item details UOM data
  const conversion = getUomConversion(itemDetails, uom);

  const available = isNonZeroDecimalOrMoney(item.available)
    ? item.available
        .div(conversion)
        .round(DEFAULT_DECIMALS_ROUNDED, Decimal.roundDown)
    : new Decimal(0);
  return { ...line, available };
}

function roundUnitPrice(line) {
  const { unitprice } = line;
  const { roundSalesPrices } =
    globalState.getState().userCompanySettings.settings;
  const decimalPlaces = getSalesDecimalPlaces(roundSalesPrices);
  return {
    ...line,
    unitprice: unitprice.round(decimalPlaces),
  };
}

function updateUnitPriceByMargin(line) {
  const { margin, cost, unitprice, quantity } = line;

  const unitCost = quantity.eq(Decimal.ZERO) ? cost : cost.div(quantity);
  const newUnitPrice = isNonZeroDecimalOrMoney(unitCost)
    ? unitCost.times(margin.div(new Decimal(100))).plus(unitCost)
    : unitprice;

  return { ...line, unitprice: newUnitPrice };
}

function updateUnitPriceByAmt(line) {
  const { quantity, unitprice, amount } = line;
  let newUnitPrice = quantity.eq(new Decimal(0))
    ? unitprice
    : amount.div(quantity);
  newUnitPrice = newUnitPrice.round(DEFAULT_DECIMALS_UNROUNDED);
  return { ...line, unitprice: newUnitPrice };
}

function setCostAndMarginToLoading(line) {
  return { ...line, cost: new Loading(), margin: new Loading() };
}

function setUnitPriceAndAmountToLoading(line) {
  return { ...line, unitprice: new Loading(), amount: new Loading() };
}

function updateMargin(line) {
  const { unitprice, cost, quantity } = line;
  if (cost instanceof Loading) {
    return line;
  }
  const margin = calculateMarginPercent(cost, unitprice, quantity);
  return { ...line, margin };
}

function calculateDiscountPercent(listPrice, unitprice) {
  if (isNonZeroDecimalOrMoney(listPrice) && unitprice) {
    return new Decimal(1)
      .minus(unitprice.div(listPrice))
      .times(new Decimal(100));
  }
  return undefined;
}
