import SvgIcon from "@mui/material/SvgIcon";

import { theme } from "SosTheme";

export function ReportsIcon(props) {
  const { highlighted, ...otherProps } = props;
  const color = highlighted ? theme.palette.accent : theme.palette.primaryText;

  return (
    <SvgIcon viewBox="0 0 32 32" fill={color} {...otherProps}>
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g fillRule="nonzero">
          <rect
            x="0.0001995842"
            y="30.8717676"
            width="31.9334719"
            height="1.06224066"
          ></rect>
          <path d="M30.8133389,4.60946058 L28.6844407,0.360497925 C28.5531809,0.0979917012 28.2336466,-0.00863070539 27.9705946,0.122356846 C27.9704615,0.122423237 27.9703285,0.122489627 27.9701954,0.122556017 L23.7123992,2.24703734 C23.4493472,2.37815768 23.3425696,2.69729461 23.4739626,2.95980083 C23.6053555,3.22230705 23.9251559,3.32886307 24.1882079,3.19774274 L27.2719168,1.66014938 L26.4304699,4.05762656 C22.7319751,14.569029 13.2058877,21.9433693 2.08385863,22.9049627 L2.1743368,23.9672033 C13.7154262,22.9694938 23.5999667,15.316249 27.435842,4.40816598 L28.2948524,1.958639 L29.860657,5.08428216 C29.9920499,5.34678838 30.3118503,5.4533444 30.5749023,5.32222407 C30.8380208,5.19110373 30.9447318,4.8720332 30.8133389,4.60946058 Z"></path>
          <path d="M6.38689397,25.5605643 L2.66132225,25.5605643 C2.36740125,25.5605643 2.12909771,25.7983734 2.12909771,26.0916846 L2.12909771,29.2784066 C2.12909771,29.5717178 2.36740125,29.809527 2.66132225,29.809527 L6.38689397,29.809527 C6.68081497,29.809527 6.9191185,29.5717178 6.9191185,29.2784066 L6.9191185,26.0916846 C6.9191185,25.7983734 6.68081497,25.5605643 6.38689397,25.5605643 Z M5.85466944,28.7472863 L3.19354678,28.7472863 L3.19354678,26.622805 L5.85466944,26.622805 L5.85466944,28.7472863 Z"></path>
          <path d="M12.2413638,23.9672033 L8.5157921,23.9672033 C8.2218711,23.9672033 7.98356757,24.2050124 7.98356757,24.4983237 L7.98356757,29.2784066 C7.98356757,29.5717178 8.2218711,29.809527 8.5157921,29.809527 L12.2413638,29.809527 C12.5352848,29.809527 12.7735884,29.5717178 12.7735884,29.2784066 L12.7735884,24.4983237 C12.7735884,24.2050124 12.5352848,23.9672033 12.2413638,23.9672033 Z M11.7091393,28.7472863 L9.04801663,28.7472863 L9.04801663,25.029444 L11.7091393,25.029444 L11.7091393,28.7472863 Z"></path>
          <path d="M18.0958337,21.3116017 L14.370262,21.3116017 C14.076341,21.3116017 13.8380374,21.5494108 13.8380374,21.842722 L13.8380374,29.2784066 C13.8380374,29.5717178 14.076341,29.809527 14.370262,29.809527 L18.0958337,29.809527 C18.3897547,29.809527 18.6280582,29.5717178 18.6280582,29.2784066 L18.6280582,21.842722 C18.6280582,21.5494108 18.3897547,21.3116017 18.0958337,21.3116017 Z M17.5636091,28.7472863 L14.9024865,28.7472863 L14.9024865,22.3738423 L17.5636091,22.3738423 L17.5636091,28.7472863 Z"></path>
          <path d="M23.9503035,17.5937593 L20.2247318,17.5937593 C19.9308108,17.5937593 19.6925073,17.8315685 19.6925073,18.1248797 L19.6925073,29.2784066 C19.6925073,29.5717178 19.9308108,29.809527 20.2247318,29.809527 L23.9503035,29.809527 C24.2442245,29.809527 24.4825281,29.5717178 24.4825281,29.2784066 L24.4825281,18.1248797 C24.4825281,17.8315685 24.2442245,17.5937593 23.9503035,17.5937593 Z M23.418079,28.7472863 L20.7569563,28.7472863 L20.7569563,18.656 L23.418079,18.656 L23.418079,28.7472863 Z"></path>
          <path d="M29.8047734,10.1580747 L26.0792017,10.1580747 C25.7852807,10.1580747 25.5469771,10.3958838 25.5469771,10.689195 L25.5469771,29.2784066 C25.5469771,29.5717178 25.7852807,29.809527 26.0792017,29.809527 L29.8047734,29.809527 C30.0986944,29.809527 30.3369979,29.5717178 30.3369979,29.2784066 L30.3369979,10.689195 C30.3369979,10.3958838 30.0986944,10.1580747 29.8047734,10.1580747 Z M29.2725489,28.7472863 L26.6114262,28.7472863 L26.6114262,11.2203154 L29.2725489,11.2203154 L29.2725489,28.7472863 Z"></path>
        </g>
      </g>
    </SvgIcon>
  );
}
