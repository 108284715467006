import { ReportMultiSelect } from "components/formFields/ReportMultiSelect";

import { ALL_FILTER_OPTION } from "appConstants";

export function SalesRepsFilter(props) {
  const { value, onValueChange, name, label, salesReps } = props;

  const options = salesReps?.length ? [ALL_FILTER_OPTION, ...salesReps] : [];

  return (
    <ReportMultiSelect
      name={name}
      options={options}
      onValueChange={onValueChange}
      value={value}
      label={label}
      dataTesting="reportSalesReps"
    />
  );
}
