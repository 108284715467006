/* 
  Used primarily as the initial state within useErrors.js
  and to reset the error state back to the default
  {
    entity: ["fieldName1","fieldName2"],
    line: {
      1: ["fieldName1","fieldName2"]
      2: [],
      3: ["fieldName1"]
    },
    messages: ["message1", "message2"]
  }

  entity is used to track record level error fields
  line is used to track line level error fields
  messages are used to track the error message displayed to users
*/

export function initialErrorState() {
  return { entity: [], line: {}, messages: [], customFieldErrors: [] };
}
