import { Box } from "@mui/material";

import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmSelectScalar } from "components/formFields/FrmSelectScalar";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmCheckbox } from "components/formFields/frm/FrmCheckbox";
import { CustomFieldDatePicker } from "components/html/Input/CustomFieldDatePicker";
import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";
import { handleProgramError } from "services/utility/errors";

export function CustomField(props) {
  const { name, dataType, onValueChange, value, selectOptions, setErrors } =
    props;

  switch (dataType) {
    case "Text":
      return (
        <div style={{ maxWidth: "70ch" }}>
          <FrmTextField
            name={name}
            label={name}
            onValueBlur={onValueChange}
            value={value}
          />
        </div>
      );
    case "Boolean":
      return (
        <Box display="flex" alignItems="center">
          <FrmCheckbox
            name={name}
            value={value}
            onValueChange={onValueChange}
            label={name}
          />
        </Box>
      );
    case "List":
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <FrmSelectScalar
            label={name}
            name={name}
            options={selectOptions || []}
            value={value || null}
            onValueChange={onValueChange}
            sx={{ flexGrow: 1 }}
          />
          {!selectOptions && (
            <HoverHelp
              color="error"
              title={i18n("hoverHelp.CustomListNoOptions")}
              sx={{ marginLeft: 0.5 }}
            />
          )}
        </div>
      );
    case "Number":
      return (
        <div style={{ maxWidth: "15rem" }}>
          <FrmTextField
            name={name}
            label={name}
            onValueBlur={onValueChange}
            value={value}
            number
          />
        </div>
      );
    case "TextArea":
      return (
        <div style={{ width: "100%", maxWidth: "70ch" }}>
          <FrmResizeTextField
            name={name}
            label={name}
            onValueBlur={onValueChange}
            value={value}
            width="100%"
            minRows={3}
          />
        </div>
      );
    case "Date":
      return (
        <div style={{ width: "100%" }}>
          <CustomFieldDatePicker
            key={value}
            name={name}
            label={name}
            value={value || null}
            setErrors={setErrors}
            onValueChange={onValueChange}
          />
        </div>
      );
    case "Money":
      return (
        <div style={{ maxWidth: "15rem" }}>
          <FrmTextField
            name={name}
            label={name}
            onValueBlur={onValueChange}
            value={value}
            money
          />
        </div>
      );
    default:
      handleProgramError(
        new Error(`CustomFields | unexpected dataType ${dataType}`)
      );
      return null;
  }
}
