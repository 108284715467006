import { Decimal } from "classes/DecimalClasses";

import { getUomDataFromItemDetails } from "services/utility/afterTouchLine";
import {
  afterTouchLine as baseAfterTouchLine,
  updateWeightAndVolume,
  updateAmount,
} from "services/utility/afterTouchLine";

// when this field (the key) changes, we should run the (value) list of
// updater functions
const UPDATERS = {
  quantity: [updateAmount, updateWeightAndVolume],
  unitprice: [updateAmount],
  amount: [updateUnitprice],
  uom: [setUnitPriceToBase, updateWeightAndVolume, updateAmount],
  item: [updateAmount, updateWeightAndVolume],
};

export function afterTouchLine(line, changedField) {
  return baseAfterTouchLine(UPDATERS, line, changedField);
}

function setUnitPriceToBase(line) {
  const { itemDetails, uom, relatedRecords } = line;
  const uomData = getUomDataFromItemDetails(itemDetails, uom);
  const unitprice =
    uomData.isBase || !uomData.purchaseCost
      ? relatedRecords.item.basePurchaseCost
      : uomData.purchaseCost;
  return { ...line, unitprice };
}

function updateUnitprice(line) {
  return {
    ...line,
    unitprice: line.quantity.eq(new Decimal(0))
      ? line.unitprice
      : line.amount.div(line.quantity),
  };
}
