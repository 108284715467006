import { useSelector } from "react-redux";

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Alert,
} from "@mui/material";

import { i18n } from "services/i18nService";

import { formatDistanceToNowStrict } from "date-fns";

const IS_LIVE_APP_URL =
  process.env.REACT_APP_V9_URL === "https://live.sosinventory.com";

const sx = {
  display: "grid",
  minWidth: "1070px",
  "& a": { color: "primaryLink" },
};

export function Home() {
  const {
    company: { status, expirationDate },
  } = useSelector((state) => state.userCompanySettings);

  const isTrial = status === "Trial";
  const days = formatDistanceToNowStrict(new Date(expirationDate), {
    unit: "day",
  });
  return (
    <Box sx={sx} m={2} mb={0} data-testing="homePage">
      {isTrial && (
        <Alert sx={{ mb: 1, maxWidth: 600 }} severity="info">
          {i18n("home.trial.1")} {days} {i18n("home.trial.2")}{" "}
          <a href={"/admin/accountInfo"}>{i18n("home.trial.3")}</a>
        </Alert>
      )}
      <Box mb={1}>
        <Typography variant="h2">
          {i18n(IS_LIVE_APP_URL ? "home.live.header" : "home.beta.header")}
        </Typography>
      </Box>
      {!IS_LIVE_APP_URL && (
        <Card variant="outlined" sx={{ maxWidth: 600 }}>
          <CardHeader title={i18n("home.beta.title")} />
          <CardContent>
            <Typography>{i18n("home.beta.p1")}</Typography>
            <ul>
              <li>{i18n("home.beta.bullet.1")}</li>
              <li>{i18n("home.beta.bullet.2")}</li>
              <li>{i18n("home.beta.bullet.3")}</li>
              <li>{i18n("home.beta.bullet.4")}</li>
              <li>{i18n("home.beta.bullet.5")}</li>
              <li>{i18n("home.beta.bullet.6")}</li>
            </ul>
            <Typography>
              {i18n("home.beta.p2.1")}{" "}
              <a href="/content/SOSInventory_BetaVersionGuide-9.0.pdf" download>
                {i18n("home.beta.p2.2")}
              </a>
            </Typography>
            <br />
            <Typography>
              {i18n("home.beta.p3.1")}{" "}
              <a href="mailto:support@sosinventory.com">
                support@sosinventory.com
              </a>
              {i18n("home.beta.p3.2")}
            </Typography>
            <br />
            <Typography>
              {i18n("home.beta.p4.1")}{" "}
              <a href="https://live.sosinventory.com">
                https://live.sosinventory.com
              </a>{" "}
              {i18n("home.beta.p4.2")}
            </Typography>
            <br />
            <Typography>{i18n("home.beta.p5")}</Typography>
          </CardContent>
        </Card>
      )}

      <Card variant="outlined" sx={{ maxWidth: 600, mt: "1rem", mb: "1rem" }}>
        <CardHeader title={i18n("home.help.title")} />
        <CardContent>
          <Typography>
            {i18n("home.help.p1.1")}{" "}
            <a href="mailto:support@sosinventory.com">
              support@sosinventory.com
            </a>
            {i18n("home.help.p1.2")}{" "}
            <a href="/Help/Contact">{i18n("home.help.p1.3")}</a>{" "}
            {i18n("home.help.p1.4")}
          </Typography>
          <br />
          <Typography>
            {i18n("home.help.p2.1")}{" "}
            <a href="mailto:support@sosinventory.com">
              support@sosinventory.com
            </a>{" "}
            {i18n("home.help.p2.2")}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
