export const esData = `
[global]
Dashboard = Tablero
SalesOrder = Órdenes de venta
Item = Ít
Calendar = Calendario
Purchasing = Adquisidor
Production = Producción
Inventory = Inventario
Sales = Ventas
Fulfillment = Realización

[leftNav]
MoreTransactions = Más transacciones
Reports = Informes
QuickLinks = Enlaces rápidos
`.trim();
