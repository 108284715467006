import { theme } from "SosTheme";

export const FIELD_STYLE = {
  normal: {
    backgroundColor: "white",
    color: theme.palette.primaryText,
    border: "none",
  },
  disabled: {},
};

export const textFieldStyle = {
  fontWeight: "fontWeightRegular",
  marginTop: 0,
  marginBottom: 0,
};

export const subLineWrapperStyle = {
  display: "flex",
  alignItems: "center",
  padding: "0 0rem 0 1rem",
  gap: "0.25rem",
  borderTop: `1px dashed ${theme.palette.lineSeparatorMinor}`,
};

export const clearIndicatorStyles = {
  "& .MuiAutocomplete-clearIndicator": { display: "none" },
};

export const inputPropStyles = {
  overflow: "hidden",
  fontSize: "0.8125rem",
  "&:hover:not($disabled)": {},
  "&$focused": {},
  "&$disabled": {},
  "& .MuiSelect-icon": { color: "selectIcon" },
  "& .Mui-focused": {},
  "& .Mui-disabled": {},
};

export const outlinedStyles = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderWidth: 0 },
    "&$disabled": {
      backgroundColor: FIELD_STYLE.disabled.backgroundColor,
      color: FIELD_STYLE.disabled.color,
    },
  },
};
