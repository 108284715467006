import { i18n } from "services/i18nService";

import { initialErrorState } from "views/common";

export function isValid(record) {
  const errors = initialErrorState();

  if (!(record.date instanceof Date)) {
    errors.messages.push(i18n("isValid.Date"));
    errors.entity.push("date");
  }
  return errors;
}
