import { FilterSelectField } from "components/formFields/FilterSelectField";

import { useRelated } from "hooks/useRelated";

import { OBJECT_TYPES } from "appConstants";

export function CustomerTypeFilter(props) {
  const { value, onValueChange, name, label } = props;

  const customerTypes = useRelated(OBJECT_TYPES.CUSTOMER_TYPE.fullString);

  return (
    <FilterSelectField
      name={name}
      label={label}
      options={customerTypes}
      value={value}
      onValueChange={onValueChange}
    />
  );
}
