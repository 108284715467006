import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmSelectScalarWithObjectOptions } from "components/formFields/FrmSelectScalarWithObjectOptions";
import { FrmTextField } from "components/formFields/FrmTextField";
import "components/formFields/frm";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { formatSyncItemObject } from "services/utility/formatting";

import { useErrors } from "hooks/useErrors";
import { useObject } from "hooks/useObject";
import { useRecord } from "hooks/useRecord";

import { OBJECT_TYPES } from "appConstants";
import { SYNC_ITEM_ACTIONS } from "appConstants";

const OBJECT_TYPE = OBJECT_TYPES.SYNC_ITEM.fullString;

export function SyncItem(props) {
  const { id, onClose, onAdd } = props;

  const { setErrors } = useErrors();

  const [record, setRecord, handleInputFieldChange] = useRecord();
  const loadingState = useSelector((state) => state.loading.editModal);

  useObject(OBJECT_TYPE, id, setRecord, false, setErrors, true);

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        text={record?.number || ""}
        handleClose={onClose}
        onAdd={onAdd}
        setErrors={setErrors}
      />

      {loadingState && <Loading />}

      {record && (
        <ObjectEditDialogContent>
          <Layout
            pageTitle={
              id
                ? `${i18n("global.syncitem.Sentence")} ${record?.number || ""}`
                : i18n("objectType.serial.New")
            }
          >
            <Box flexGrow="1" position="relative" id="B" m={2} mb={0}>
              {record ? (
                <Box>
                  <FrmFieldGrid gridAutoFlow="dense">
                    <FrmTextField
                      name="object"
                      label={i18n("syncitem.labelObject")}
                      value={formatSyncItemObject(record.object)}
                      disabled={true}
                    />
                    <FrmTextField
                      name="type"
                      label={i18n("syncitem.labelType")}
                      value={record.object.type}
                      disabled={true}
                    />
                    <FrmSelectScalarWithObjectOptions
                      name="action"
                      label={i18n("syncitem.labelAction")}
                      options={SYNC_ITEM_ACTIONS}
                      value={record.action}
                      onValueChange={handleInputFieldChange}
                    />
                  </FrmFieldGrid>
                </Box>
              ) : (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )}
            </Box>
            <VSpace space={4} />
          </Layout>
        </ObjectEditDialogContent>
      )}
    </>
  );
}
