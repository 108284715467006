import { useSelector } from "react-redux";

import { TOTALS_SALES_COLUMN_DIMENSION } from "appConfig";

import { Decimal } from "classes/DecimalClasses";

import { TotalsLine as TotalsSalesLine } from "components/formFields/Totals/Sales/TotalsLine";
import { ToggleTaxDiscount } from "components/formFields/Totals/ToggleTaxDiscount";

import { i18n } from "services/i18nService";
import {
  currencyMinMaxFractionDigits,
  formatPercentDigits,
} from "services/utility/formatting";
import { isNonZeroDecimalOrMoney } from "services/utility/misc";
import {
  getPercentFromAmount,
  getAmountFromPercent,
} from "services/utility/misc";

import { TotalsDiscount } from "views/SalesTransactions/TotalsDiscount";
import { TotalsShipping } from "views/SalesTransactions/TotalsShipping";
import { TotalsTax } from "views/SalesTransactions/TotalsTax";

function getLocalizedValue(value, digits) {
  return currencyMinMaxFractionDigits(value, digits, digits, true);
}

export function LineItemTotals(props) {
  const {
    totals,
    record,
    objectType,
    setRecord,
    handleInputFieldChange,
    taxCodes,
  } = props;

  // SETTINGS
  const taxEnabled = useSelector(
    (state) => state.userCompanySettings.settings.taxEnabled
  );
  const discountEnabled = useSelector(
    (state) => state.userCompanySettings.settings.discountEnabled
  );
  const shippingEnabled = useSelector(
    (state) => state.userCompanySettings.settings.shippingEnabled
  );
  const shippingTaxable = useSelector(
    (state) => state.userCompanySettings.settings.shippingTaxable
  );

  const currencyMinimumFractionDigits = useSelector(
    (state) =>
      state.userCompanySettings.localization.currencyMinimumFractionDigits
  );

  function getShowTax(tax) {
    return taxEnabled || record.taxUpdated
      ? true
      : isNonZeroDecimalOrMoney(tax);
  }

  // only show the tax, discount, and shipping UIs if their respective
  // setting enabled flag is true or the value is not equal to zero
  const showTax = getShowTax(totals.tax);
  const showDiscount =
    discountEnabled ||
    record.discountUpdated ||
    isNonZeroDecimalOrMoney(record.discountAmount);
  const showShipping =
    shippingEnabled ||
    record.shippingUpdated ||
    isNonZeroDecimalOrMoney(record.shippingAmount);

  const localizedAmount = getLocalizedValue(
    totals.amount,
    currencyMinimumFractionDigits
  );
  const localizedTax = getLocalizedValue(
    totals.tax,
    currencyMinimumFractionDigits
  );
  const localizedTotal = getLocalizedValue(
    totals.total,
    currencyMinimumFractionDigits
  );
  const localizedDiscountPercent = record.discountPercent
    ? formatPercentDigits(record.discountPercent)
    : record.discountPercent;
  const localizedTaxPercent = formatPercentDigits(record.taxPercent);

  function onDiscountPercentChange(discountType, value) {
    const discountPercent = new Decimal(+value);
    const discountAmount = getAmountFromPercent(discountPercent, totals.amount);
    setRecord((prevRecord) => ({
      ...prevRecord,
      discountType,
      discountPercent,
      discountAmount: isNonZeroDecimalOrMoney(discountAmount)
        ? discountAmount.times(new Decimal(-1))
        : discountAmount,
      discountUpdated: true,
    }));
  }

  function onDiscountAmountChange(discountType, value) {
    const discountAmount = new Decimal(+value).abs().times(new Decimal(-1));
    const discountPercent = getPercentFromAmount(
      discountAmount.times(new Decimal(-1)),
      totals.amount
    );
    setRecord((prevRecord) => ({
      ...prevRecord,
      discountAmount,
      discountPercent,
      discountType,
      discountUpdated: true,
    }));
  }

  function onTaxPercentChange(_, value) {
    const taxPercent = new Decimal(+value);
    const taxAmount = taxPercent.times(totals.amount).times(new Decimal(0.01));
    setRecord((prevRecord) => ({
      ...prevRecord,
      taxPercent,
      taxAmount,
      taxUpdated: true,
    }));
  }

  function setDiscountTaxable(discountTaxable) {
    setRecord((prevRecord) => ({ ...prevRecord, discountTaxable }));
  }

  return (
    <div style={{ display: "flex", width: TOTALS_SALES_COLUMN_DIMENSION }}>
      <div>
        <TotalsSalesLine
          label={i18n("global.Subtotal")}
          value={localizedAmount}
          dataTesting="subTotal"
        />
        {shippingTaxable && showShipping && (
          <TotalsShipping
            onValueChange={handleInputFieldChange}
            value={record.shippingAmount}
          />
        )}
        {record.discountTaxable ? (
          <>
            {showDiscount && (
              <TotalsDiscount
                onAmountChange={onDiscountAmountChange}
                onPercentChange={onDiscountPercentChange}
                value={record.discountAmount}
                percentValue={localizedDiscountPercent}
              />
            )}
            {showTax && (
              <TotalsTax
                onPercentChange={onTaxPercentChange}
                value={localizedTax}
                percentValue={localizedTaxPercent}
                taxCode={record.taxCode}
                setRecord={setRecord}
                objectType={objectType}
                taxCodes={taxCodes}
              />
            )}
          </>
        ) : (
          <>
            {showTax && (
              <TotalsTax
                onPercentChange={onTaxPercentChange}
                value={localizedTax}
                percentValue={localizedTaxPercent}
                taxCode={record.taxCode}
                setRecord={setRecord}
                objectType={objectType}
                taxCodes={taxCodes}
              />
            )}
            {showDiscount && (
              <TotalsDiscount
                onAmountChange={onDiscountAmountChange}
                onPercentChange={onDiscountPercentChange}
                value={record.discountAmount}
                percentValue={localizedDiscountPercent}
              />
            )}
          </>
        )}
        {!shippingTaxable && showShipping && (
          <TotalsShipping
            onValueChange={handleInputFieldChange}
            value={record.shippingAmount}
          />
        )}
        <TotalsSalesLine
          label={i18n("global.Total")}
          value={localizedTotal}
          dataTesting="total"
        />
      </div>
      {showDiscount && showTax && (
        <div style={{ marginTop: shippingTaxable ? "7.6rem" : "4.5rem" }}>
          <ToggleTaxDiscount
            discountTaxable={record.discountTaxable}
            setDiscountTaxable={setDiscountTaxable}
          />
        </div>
      )}
    </div>
  );
}
