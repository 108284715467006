import { accountConfig } from "services/sosInventoryService/account/schema";
import { billConfig } from "services/sosInventoryService/bill/schema";
import { creditMemoConfig } from "services/sosInventoryService/creditMemo/schema";
import { employeeConfig } from "services/sosInventoryService/employee/schema";
import { journalEntryConfig } from "services/sosInventoryService/journalEntry/schema";
import { purchaseConfig } from "services/sosInventoryService/purchase/schema";
import { syncErrorConfig } from "services/sosInventoryService/syncError/schema";
import { taxCodeConfig } from "services/sosInventoryService/taxCode/schema";

export const SYNC_MENU_CONFIG = {
  account: accountConfig,
  bill: billConfig,
  creditmemo: creditMemoConfig,
  employee: employeeConfig,
  purchase: purchaseConfig,
  taxcode: taxCodeConfig,
  journalentry: journalEntryConfig,
  syncerror: syncErrorConfig,
};
