// Named with sos anticipating future variants of the software that may adjust the menu layout
import {
  DashboardIcon,
  CalendarIcon,
  PurchasingIcon,
  ProductionIcon,
  InventoryIcon,
  SalesIcon,
  FulfillmentIcon,
  ReportsIcon,
  QuicklinksIcon,
  RentalIcon,
} from "components/icons";

// To add privilege checking for menu nodes, first import:
// import { USER_PRIVILEGE } from "appConstants";
// Then add to a subnode something like:
// privilegeRequired: [
//   USER_PRIVILEGE.viewVendors,
//   USER_PRIVILEGE.editVendors,
// ],

export const menuNodes = [
  {
    id: "dashboard",
    label: "global.Dashboard",
    to: "/dashboard",
    Icon: DashboardIcon,
    subNodes: [],
  },
  {
    id: "calendar",
    label: "global.Calendar",
    to: "/calendar",
    Icon: CalendarIcon,
    subNodes: [],
  },
  {
    id: "inventory",
    label: "global.Inventory",
    Icon: InventoryIcon,
    subNodes: [
      {
        label: "objectType.item.SentencePlural",
        plan: "companion",
        path: "/item",
      },
      {
        label: "objectType.adjustment.SentencePlural",
        plan: "companion",
        path: "/adjustment",
      },
      {
        label: "objectType.transfer.SentencePlural",
        plan: "plus",
        path: "/transfer",
      },
      {
        label: "objectType.serialinventory.SentencePlural",
        plan: "plus",
        path: "/serial",
      },
      { label: "objectType.lot.SentencePlural", plan: "plus", path: "/lot" },
    ],
  },
  {
    id: "purchasing",
    label: "global.Purchasing",
    Icon: PurchasingIcon,
    subNodes: [
      {
        label: "objectType.vendor.SentencePlural",
        plan: "companion",
        path: "/vendor",
      },
      {
        label: "objectType.purchaseorder.SentencePlural",
        plan: "companion",
        path: "/purchaseorder",
      },
      {
        label: "objectType.itemreceipt.SentencePlural",
        plan: "companion",
        path: "/itemreceipt",
      },
      {
        label: "objectType.returntovendor.SentencePlural",
        plan: "plus",
        path: "/returntovendor",
      },
      {
        label: "objectType.reorder.SentencePlural",
        plan: "companion",
        path: "/reorder",
      },
    ],
  },
  {
    id: "sales",
    label: "global.Sales",
    Icon: SalesIcon,
    subNodes: [
      {
        label: "objectType.customer.SentencePlural",
        plan: "companion",
        path: "/customer",
      },
      {
        label: "objectType.estimate.SentencePlural",
        plan: "companion",
        path: "/estimate",
      },
      {
        label: "objectType.salesorder.SentencePlural",
        plan: "companion",
        path: "/salesorder",
      },
      {
        label: "objectType.invoice.SentencePlural",
        plan: "companion",
        path: "/invoice",
      },
      {
        label: "objectType.salesreceipt.SentencePlural",
        plan: "companion",
        path: "/salesreceipt",
      },
      {
        label: "objectType.payment.SentencePlural",
        plan: "companion",
        setting: "paymentsVisible",
        privilegeRequired: ["PrPa"],
        path: "/payment",
      },
      {
        label: "objectType.return.SentencePlural",
        plan: "companion",
        path: "/return",
      },
      { label: "objectType.rma.SentencePlural", plan: "plus", path: "/rma" },
    ],
  },
  {
    id: "fulfillment",
    label: "global.Fulfillment",
    Icon: FulfillmentIcon,
    subNodes: [
      {
        label: "objectType.pickticket.SentencePlural",
        plan: "plus",
        path: "/pickticket",
      },
      {
        label: "objectType.shipment.SentencePlural",
        plan: "companion",
        path: "/shipment",
      },
    ],
  },
  {
    id: "production",
    label: "global.Production",
    Icon: ProductionIcon,
    subNodes: [
      {
        label: "objectType.build.SentencePlural",
        plan: "companion",
        path: "/build",
      },
      {
        label: "objectType.process.SentencePlural",
        plan: "pro",
        path: "/process",
      },
      { label: "objectType.job.SentencePlural", plan: "pro", path: "/job" },
      {
        label: "objectType.workorder.SentencePlural",
        plan: "pro",
        path: "/workorder",
      },
    ],
  },

  {
    id: "rental",
    label: "leftNav.Rentals",
    Icon: RentalIcon,
    subNodes: [
      {
        label: "objectType.rental.SentencePlural",
        plan: "plus",
        path: "/rental",
      },
      {
        label: "objectType.rentalreturn.SentencePlural",
        plan: "plus",
        path: "/rentalreturn",
      },
    ],
  },
  {
    id: "reports",
    label: "leftNav.Reports",
    to: "/report",
    Icon: ReportsIcon,
    hideFromRestrictedSalesReps: true,
    subNodes: [],
  },
  {
    id: "quicklinks",
    label: "leftNav.QuickLinks",
    Icon: QuicklinksIcon,
    subNodes: [
      {
        label: "leftNav.AddThisPage",
        plan: "companion",
        path: "/addQuickLink",
      },
    ],
  },
];
