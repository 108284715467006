import { useEffect, useState } from "react";

import { Card } from "@mui/material";

import { CardHeader } from "components/formFields/formCards/CardHeader";

import { i18n } from "services/i18nService";
import { getBarcodeImageBlob } from "services/sosInventoryService/sosApi";
import { imageBlobToBase64 } from "services/utility/images";

import { usePlans } from "hooks/usePlans";

export function FrmBarcode(props) {
  const { number } = props;

  const [barcodeImage, setBarcodeImage] = useState(undefined);
  const { hasAtLeastPlusPlan } = usePlans();
  useEffect(() => {
    if (number) {
      async function getImage() {
        const barcodeImageBlob = await getBarcodeImageBlob(`${number}`);
        imageBlobToBase64(barcodeImageBlob, setBarcodeImage);
      }
      getImage();
    } else {
      setBarcodeImage(undefined);
    }
  }, [number]);

  return hasAtLeastPlusPlan && barcodeImage ? (
    <Card
      variant="outlined"
      sx={{ marginTop: "1.0em", marginBottom: "1.0em", padding: "1.0em" }}
    >
      <CardHeader text={i18n("frmLabel.Barcode")} />
      <div style={{ textAlign: "center" }}>
        <img src={"data:image;base64, " + barcodeImage} alt="barcode" />
      </div>
    </Card>
  ) : null;
}
