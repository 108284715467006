export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixSalesOrder: record.salesOrderNumber.prefix,
    nextSONumber: record.salesOrderNumber.nextNumber,
  };

  delete newRecord.salesOrderNumber;

  return newRecord;
}
