import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import { Reconnecting } from "views/Reconnecting";

const expiredUrl = "/expired";
const disabledUrl = "/disabled";

const expiredWhiteList = ["/accountinfo"];

export const PrivateRoute = () => {
  const { pathname } = useLocation();
  const { isLoggedIn } = useSelector((state) => state.authentication);
  const status = useSelector(
    (state) => state.userCompanySettings.company.status
  );

  if (isLoggedIn === "yes") {
    if (status === "Expired" && !expiredWhiteList.includes(pathname)) {
      return <Navigate to={expiredUrl} />;
    }
    if (status === "Disabled") {
      return <Navigate to={disabledUrl} />;
    }
    return <Outlet />;
  } else if (isLoggedIn === "no") {
    sessionStorage.setItem(
      "redirectPath",
      window.location.pathname + window.location.search
    );
    return <Navigate to="/login" />;
  } else {
    return <Reconnecting />;
  }
};
