import { useState, useCallback } from "react";

import { TRANSACTION_MAX_RESULTS } from "appConfig";

import { getListPageRecords } from "services/sosInventoryService/sosApi";
import { getDocumentSelectionName } from "services/utility/documents";

import {
  getObjectFromTypeString,
  getObjectFromFullString,
  NO_COLUMNS,
} from "appConstants";

const GET_PARAMS = {
  inTransaction: true,
  columns: [NO_COLUMNS],
  maxResults: TRANSACTION_MAX_RESULTS,
};

async function getListData(fullString) {
  const response = await getListPageRecords(fullString, GET_PARAMS);
  if (response?.data) {
    const data = response.data.map((record) => {
      const { typeString } = getObjectFromFullString(fullString);
      const name = getDocumentSelectionName(record, typeString);
      return { id: record.id, name };
    });
    return { data, totalCount: response.totalCount };
  }
}

export function useDocumentObjectLists() {
  const [objectLists, setObjectLists] = useState({});

  async function addObjectList(fullString) {
    // only allow fetching for lists that have not
    // already been fetched by the hook previously
    if (objectLists.hasOwnProperty(fullString)) {
      return undefined;
    }

    const listData = await getListData(fullString);
    setObjectLists((prev) => ({ ...prev, [fullString]: listData }));
  }

  const addInitialObjectLists = useCallback(async (lines) => {
    // map the lines into a set of unique object type strings
    const nonUniqueObjectTypesArray = lines
      .filter(({ type }) => type)
      .map(({ type }) => getObjectFromTypeString(type).fullString);
    const objectTypesArray = [...new Set(nonUniqueObjectTypesArray)];

    // fetch the corresponding list for each object type asynchronously
    const listArray = await Promise.all(
      objectTypesArray.map(async (fullString) => await getListData(fullString))
    );

    // turn the list array into an object where each key
    // corresponds to an object type string
    const newListsObject = listArray.reduce((seed, list, i) => {
      const objectType = objectTypesArray[i];
      return { ...seed, [objectType]: list };
    }, {});

    setObjectLists(newListsObject);
  }, []);

  return { objectLists, addObjectList, addInitialObjectLists };
}
