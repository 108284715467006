import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import {
  formatNameLinkField,
  formatDecimalIntoPercentWithAdornments,
} from "services/utility/formatting";

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: formatNameLinkField,
    fixed: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "description",
    heading: i18n("columns.Description"),
    apiName: "description",
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "yield",
    heading: i18n("columns.ExpectedYield"),
    formatFunc: formatDecimalIntoPercentWithAdornments,
    align: "right",
    fixed: true,
    fixedRightOfAction: true,
  },
];

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["yield"],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const LINE_FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["quantity"],
  MONEY_FIELDS: ["cost"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];

export const EMPTY_LINE = {
  outputs: {
    item: { id: null, name: null },
    quantity: Decimal.ZERO,
    cost: Money.ZERO,
    total: Money.ZERO,
    waste: false,
  },
  inputs: {
    item: { id: null, name: null },
    quantity: Decimal.ZERO,
    cost: Money.ZERO,
    total: Money.ZERO,
    waste: false,
  },
};

export function getEmptyRecord({ numLinesToAdd }) {
  const outputs = new Array(numLinesToAdd).fill(EMPTY_LINE.outputs);
  const inputs = new Array(numLinesToAdd).fill(EMPTY_LINE.inputs);
  return {
    name: null,
    description: null,
    outputs,
    inputs,
  };
}
