import { NEW_RECORD_SKELETON } from "appConfig";

import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { getRecord, getRecordFrom } from "services/sosInventoryService/sosApi";
import { reconcileCustomFields } from "services/utility/customFields";
import { handleProgramError } from "services/utility/errors";

export async function updateObject(field, newValue, record, setRecord) {
  if (!record) {
    handleProgramError(new Error("update Transaction | invalid currentState"));
  }

  let newRecord;

  switch (field) {
    case "initialLocation":
      const locationRecord = await getRecord("location", newValue.id);
      newRecord = { ...record, [field]: locationRecord };
      setRecord(newRecord);
      break;
    case "tags":
      const tags = newValue.map((value) =>
        typeof value === "object" ? value.name : value
      );
      newRecord = { ...record, [field]: tags };
      setRecord(newRecord);
      break;
    case "baseSalesPrice":
    case "basePurchaseCost":
      newRecord = { ...record, [field]: new Money(newValue || 0) };
      setRecord(newRecord);
      break;
    case "name":
    case "type":
    case "sku":
    case "category":
    case "class":
    case "incomeAccount":
    case "assetAccount":
    case "cogsAccount":
    case "expenseAccount":
    case "serialTracking":
    case "lotTracking":
    case "hasVariants":
    case "alwaysShippable":
    case "showOnSalesForms":
    case "showOnPurchasingForms":
    case "showOnProductionForms":
    case "initialQuantity":
    case "initialQuantityDate":
    case "initialValue":
    case "initialBin":
    case "description":
    case "markupPercent":
    case "useMarkup":
    case "salesTaxCode":
    case "minimumPrice":
    case "customerPartNumber":
    case "taxable":
    case "commissionExempt":
    case "commissionRate":
    case "commissionAmount":
    case "purchaseDescription":
    case "vendor":
    case "vendorPartNumber":
    case "reorderPoint":
    case "maxStock":
    case "leadTime":
    case "bin":
    case "purchaseTaxCode":
    case "willSync":
    case "updateBigCommerce":
    case "updateShopify":
    case "weight":
    case "weightUnit":
    case "volume":
    case "volumeUnit":
    case "url":
    case "warranty":
    case "barcode":
    case "notes":
    case "customFields":
      newRecord = { ...record, [field]: newValue };
      setRecord(newRecord);
      break;

    default:
      handleProgramError(
        new Error(`updateTransaction | unknown field (${field})`)
      );
  }
}

export async function getObject(
  id,
  newFromId,
  newFromObjectType,
  presetName,
  customFieldDefs,
  UPDATERS,
  settings
) {
  const { setRecord, setErrors } = UPDATERS;
  const {
    defaultAssetAccount,
    defaultCOGSAccount,
    defaultExpenseAccount,
    defaultIncomeAccount,
    defaultTaxCode,
  } = settings;

  const getEmptyRecord = NEW_RECORD_SKELETON["item"];

  if (!customFieldDefs) {
    return;
  }

  if (id || newFromId) {
    try {
      let record;
      if (id) {
        record = await getRecord("item", id);
      } else {
        const recordFrom = await getRecordFrom(
          "item",
          newFromObjectType,
          newFromId
        );
        record = {
          ...recordFrom,
          initialValue: new Money(0),
          initialQuantity: new Decimal(0),
          initialQuantityDate: new Date(),
          initialLocation: null,
        };
      }
      record.customFields = reconcileCustomFields(
        customFieldDefs,
        record.customFields
      );

      setRecord(record);
    } catch (error) {
      setErrors((prev) => ({
        ...prev,
        entity: [...prev.entity, "record"],
        messages: [error.message || i18n("error.RecordLoadError")],
      }));
      return;
    }
  } else {
    // new record
    const emptyRecord = getEmptyRecord({
      defaultAssetAccount,
      defaultCOGSAccount,
      defaultExpenseAccount,
      defaultIncomeAccount,
      defaultTaxCode,
    });
    setRecord((prevRecord) => ({
      ...prevRecord,
      ...emptyRecord,
      name: presetName || emptyRecord.name,
      customFields: reconcileCustomFields(customFieldDefs, []),
    }));
  }
}
