import { useEffect, useState } from "react";

import {
  getCustomFieldDefinitions,
  getCustomFieldDefsAsObject,
} from "services/utility/customFields";

/**
 * @name        useCustomFieldDefinitions
 *
 * @summary     Hook that takes a object type string or an array of
 *              object type strings and returns their custom fields
 *              as a singular combined array
 *
 * @description Self-explanatory.
 *
 * @param       objectTypeStringOrArray (string or array)
 *
 * @returns     (customFieldDefs) - custom field definitions for the
 *              requested object type(s)
 */
export function useCustomFieldDefinitions(objectTypeStringOrArray) {
  const [customFieldDefs, setCustomFieldDefs] = useState();

  useEffect(() => {
    async function retrieveDefinitions(objectTypeStringOrArray) {
      const defArray = await getCustomFieldDefinitions(objectTypeStringOrArray);
      setCustomFieldDefs(defArray);
    }
    retrieveDefinitions(objectTypeStringOrArray);
  }, [objectTypeStringOrArray]);

  return customFieldDefs;
}

/**
 * @name        useCustomFieldDefsAsObject
 *
 * @summary     Hook that takes an array of object type strings and returns
 *              an object with it's corresponding object type matched to
 *              their custom fields matched
 *
 *
 * @description Self-explanatory.
 *
 * @param       objectTypeArray (array)
 *
 * @returns     (customFieldDefs) - object containing the custom field definitions for the
 *              requested object types
 */

export function useCustomFieldDefsAsObject(objectTypes) {
  const [customFieldDefs, setCustomFieldDefs] = useState(null);

  useEffect(() => {
    async function retrieveDefinitions(objectTypes) {
      const defObject = await getCustomFieldDefsAsObject(objectTypes);
      setCustomFieldDefs(defObject);
    }
    retrieveDefinitions(objectTypes);
  }, [objectTypes]);

  return customFieldDefs;
}
