import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmContactMessageSelector } from "components/formFields/frm/FrmContactMessageSelector";

import { i18n } from "services/i18nService";

export function FrmCustomerMessage(props) {
  const { value, onValueChange } = props;

  return (
    <>
      <FrmContactMessageSelector
        onChange={(value) => onValueChange("customerMessage", value.message)}
        type="customer"
      />
      <FrmResizeTextField
        margin="none"
        name="customerMessage"
        value={value}
        minRows={2}
        width="100%"
        label={i18n("frmLabel.CustomerMessage")}
        onValueBlur={onValueChange}
      />
    </>
  );
}
