export const SETTINGS = [
  {
    apiName: "rmaNumber",
    component: "FrmTransactionNumber",
    transactionType: "salesorder",
    hasInputLabel: true,
  },
  { apiName: "rmaFooter", component: "FrmResizeTextField" },
  { apiName: "replyEmailRMA", component: "FrmEmail", maxLength: 255 },
  { apiName: "rmaEmailText", component: "FrmResizeTextField" },
];
