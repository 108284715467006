import { useState, useEffect } from "react";

import { FilterList } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

import { EditMaxResults } from "components/ListPage/Widgets/EditDisplayResults/EditMaxResults";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";

export function Filters(props) {
  const { retrievalParams, setRetrievalParams } = props;
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState(retrievalParams);

  useEffect(() => setFilters(retrievalParams), [retrievalParams, setFilters]);

  function handleClose(isSaved = false) {
    setOpen(false);
    isSaved && setFilters(retrievalParams);
  }

  return (
    <>
      <Button data-testing="filterButton" onClick={() => setOpen(true)}>
        <FilterList sx={{ marginRight: "0.5rem" }} />
        {i18n("filter.label")}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DialogContent data-testing="filters" sx={{ width: "100%" }}>
            <VSpace space={2} />
            <div style={{ display: "grid" }}>
              <EditMaxResults filters={filters} setFilters={setFilters} />
            </div>
          </DialogContent>
        </div>

        <DialogActions sx={{ width: "100%" }} data-testing="dialogActions">
          <Button color="secondary" onClick={handleClose}>
            {i18n("button.Cancel")}
          </Button>
          <div style={{ position: "relative" }}>
            <Button
              color="secondary"
              onClick={() => {
                setRetrievalParams(filters);
                handleClose(true);
              }}
            >
              {i18n("button.Apply")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
