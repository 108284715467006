import { useEffect, useState } from "react";

import { FrmSelectBase } from "components/formFields/FrmSelectBase";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

import PropTypes from "prop-types";

/**
 * @name    FrmSelectFromObjects
 * @summary presents options for the user to choose from when the
 *          options are objects with properties for the display string and the
 *          value string
 *
 *          See FrmSelectBase for descriptions of the props, which are, for
 *          the most part, just passed through this component; props specific
 *          to this component are described below.
 *
 * @param   optionId (string) - the name of the property in the options objects
 *          that should be used as the *value* of the select field
 *
 * @param   optionDisplayText (string) - the name of the property in the options
 *          objects that should be used as the text that the user sees for the
 *          select field
 */
export function FrmSelectFromObjects(props) {
  const {
    name,
    dataIndex,
    value: initialValue,
    options: initialOptions,
    onValueChange,
    optionId = "id",
    optionDisplayText = "name",
    helperText,
    label,
    error,
    disableClearable,
    disabled,
    minWidth,
    maxWidth,
    fullWidth,
    margin,
    placeholder,
    sx,
    InputProps,
    dataTesting,
    getOptionDisabled,
    getOptionLabel,
    renderOption,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmSelectReference", unexpected);

  const [value, setValue] = useState(initialValue);
  const [options, setOptions] = useState();

  useEffect(() => {
    // no options, show "loading..."
    if (!initialOptions) {
      let newOptions = [
        { id: 0, [optionDisplayText]: i18n("global.DropdownLoading") },
      ];
      // if there's a value, show it in the dropdown
      if (initialValue) {
        newOptions.push({
          id: initialValue[optionId],
          [optionDisplayText]: initialValue[optionDisplayText],
        });
      }
      return;
    }

    if (initialValue === null) {
      setValue(initialValue);
    }

    // we have options
    // if we have a value, and it's in the options, we're good
    if (initialValue) {
      const matchingOption = initialOptions.find(
        (option) => option[optionId] === initialValue[optionId]
      );
      if (matchingOption) {
        setOptions(initialOptions);
        // set the value to the matching option so that we pick up any
        // option text that we may be adding, like "Bin 1 (Location 1)"
        // for when we show all bins; otherwise, the value displayed will
        // just be the value in the record, like "Bin 1" in this example
        setValue(matchingOption);
        return;
      }
    }

    // we have a value, but it's not in the options...add it
    if (initialValue) {
      setOptions([initialValue, ...initialOptions]);
      return;
    }
    setOptions(initialOptions);
  }, [initialOptions, initialValue, optionDisplayText, optionId]);

  function defaultRenderOption(props, option) {
    return (
      <li {...props} data-testing="selectOption" key={option[optionId]}>
        {option[optionDisplayText]}
      </li>
    );
  }

  function defaultGetOptionLabel(option) {
    if (option[optionDisplayText]) {
      return option[optionDisplayText];
    }
    if (!options) {
      return "";
    }
    const label = options.find(({ id }) => id === option.id);
    return label ? label[optionDisplayText] : "";
  }

  function isOptionEqualToValue(option, value) {
    if (!option || !value) {
      return false;
    }
    return value.id === option.id || (value === "" && option.id === "");
  }

  return (
    <FrmSelectBase
      name={name}
      value={value}
      loading={!Array.isArray(options)}
      options={
        options
          ? options.map((option) => ({
              id: option[optionId],
              [optionDisplayText]: option[optionDisplayText],
            }))
          : []
      }
      onValueChange={(name, value) => onValueChange(name, value, dataIndex)}
      getOptionLabel={getOptionLabel || defaultGetOptionLabel}
      getOptionDisabled={getOptionDisabled}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={renderOption || defaultRenderOption}
      helperText={helperText}
      label={label}
      error={error}
      minWidth={minWidth}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      margin={margin}
      disableClearable={disableClearable}
      disabled={disabled}
      InputProps={InputProps}
      placeholder={placeholder}
      sx={sx}
      dataTesting={dataTesting}
    />
  );
}

FrmSelectFromObjects.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  onValueChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  optionId: PropTypes.string,
  optionDisplayText: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.object,
  label: PropTypes.string,
  error: PropTypes.bool,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  disableClearable: PropTypes.bool,
  disabled: PropTypes.bool,
  InputProps: PropTypes.object,
  style: PropTypes.object,
};
