import { Decimal, Money } from "classes/DecimalClasses";

import { totalWeight, weightUnit } from "services/utility/afterTouchLine";

import globalState from "globalState/globalState";

export const ZERO_TOTALS = {
  total: Money.ZERO,
  tax: Money.ZERO,
  quantity: Decimal.ZERO,
  weight: Decimal.ZERO,
  volume: Decimal.ZERO,
  amount: Money.ZERO,
};

export function updateTotals(lines, setTotals, taxCodes) {
  function getTaxRate(taxCodeId) {
    if (!taxCodeId) {
      return Decimal.ZERO;
    }
    const { purchaseTaxRate } = taxCodes.find(({ id }) => id === taxCodeId);
    // rates are stored as percents, so divide by 100 to get rate as fraction
    return purchaseTaxRate.div(new Decimal(100));
  }

  if (!lines || lines.length === 0) {
    setTotals(ZERO_TOTALS);
    return;
  }
  const quantity = lines.reduce(
    (total, { quantity }) => total.plus(quantity),
    Decimal.ZERO
  );

  const amount = lines.reduce(
    (total, { amount }) => total.plus(amount),
    Decimal.ZERO
  );

  const { globalEnabled } = globalState.getState().userCompanySettings.settings;

  const tax = globalEnabled
    ? lines.reduce(
        (total, { amount, taxCode }) =>
          total.plus(amount.times(getTaxRate(taxCode?.id))),
        Decimal.ZERO
      )
    : null;

  const weight = totalWeight(lines);
  const weightunit = weightUnit(lines);

  // if we ever support more than cbm for volume, this will have to be
  // changed to mirror the weight calculation
  const volume = lines.reduce(
    (total, { volume }) => total.plus(volume),
    Decimal.ZERO
  );

  const total = globalEnabled ? amount.plus(tax) : amount;

  setTotals({
    total,
    quantity,
    amount,
    weight,
    weightunit,
    volume,
    volumeunit: "cbm",
    tax,
  });
}
