import { Loading } from "classes/Loading";

import { LineItemNumericCalculatorField } from "components/formFields/LineItemNumericCalculatorField";
import { LineSkeleton } from "components/formFields/LineSkeleton";
import { LineTableCell } from "components/formFields/LineTableCell";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineNewQuantity(props) {
  const { value, onValueChange, objectType, error, disabled = false } = props;

  const isLoading = value instanceof Loading;

  return (
    <LineTableCell sx={{ minWidth: "5.5rem" }}>
      {isLoading ? (
        <LineSkeleton />
      ) : (
        <LineItemNumericCalculatorField
          name="newQuantity"
          value={value}
          disabled={disabled}
          onValueChange={onValueChange}
          metadata={LINE_ITEM_METADATA[objectType].newQuantity}
          error={error}
          dataTesting="lineNewQuantity"
        />
      )}
    </LineTableCell>
  );
}
