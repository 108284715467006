import { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  addJqueryScript,
  addFullsteamFormsScript,
} from "services/utility/payments";

export function FullsteamForms(props) {
  const { idRef, tokenRef, responseRef, fullSteamCardRef } = props;

  const companyId = useSelector(
    (state) => state.userCompanySettings.settings.id
  );

  useEffect(() => {
    addFullsteamFormsScript(companyId);

    // Add jquery window object here as the Fullsteam embeded dialog expects it
    addJqueryScript();
  });

  return (
    <>
      <div ref={fullSteamCardRef} id="fullsteam-hosted-card-data" />
      <input
        ref={idRef}
        type="hidden"
        name="TransactionId"
        id="TransactionId"
      />
      <input
        ref={tokenRef}
        type="hidden"
        name="AccountToken"
        id="AccountToken"
      />
      <input
        ref={responseRef}
        type="hidden"
        name="HostedPaymentsResponse"
        id="HostedPaymentsResponse"
      />
    </>
  );
}
