import { SETTINGS as accounting } from "views/Configuration/settings/company/accounting";
import { SETTINGS as general } from "views/Configuration/settings/company/general";
import { SETTINGS as logo } from "views/Configuration/settings/company/logo";
import { SETTINGS as nameAndAddress } from "views/Configuration/settings/company/nameAndAddress";

export const SETTINGS = {
  general,
  accounting,
  nameAndAddress,
  logo,
};
