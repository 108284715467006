import { useSelector, useDispatch } from "react-redux";

import { Alert as MuiAlert, Snackbar } from "@mui/material";

import { closeAlert } from "globalState/alertSlice";

export function Alert() {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);
  const { message, type, open } = alert || {};

  function handleClose() {
    dispatch(closeAlert());
  }
  return (
    open && (
      <SnackAlert
        message={message}
        onClose={handleClose}
        type={type}
        open={open}
        autoHideDuration={type === "success" ? 4000 : null}
      />
    )
  );
}

// type should be one of "error", "info", "success", "warning"
function SnackAlert(props) {
  const { message, onClose, type, open, autoHideDuration } = props;
  return (
    <Snackbar
      open={open}
      sx={{ marginTop: "90px" }}
      anchorOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <MuiAlert data-testing={`${type}Alert`} variant="filled" onClose={onClose} severity={type}>
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
