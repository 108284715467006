import { REFERENCE_FIELDS } from "appConfig";

import { OBJECT_TYPES } from "appConstants";

export function beforeSave(record) {
  const newRecord = { ...record };

  REFERENCE_FIELDS[OBJECT_TYPES.REORDER.fullString].forEach((field) => {
    if (
      record[field] &&
      typeof record[field].id === "string" &&
      record[field].id === ""
    ) {
      newRecord[field] = null;
    }
  });

  return newRecord;
}
