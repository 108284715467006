export function afterGet(record) {
  const newRecord = {
    ...record,
    rentalNumber: {
      prefix: record.prefixRental,
      nextNumber: record.nextRentalNumber,
    },
  };

  delete newRecord.prefixRental;
  delete newRecord.nextRentalNumber;

  return newRecord;
}
