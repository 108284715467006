import { FilterSelectField } from "components/formFields/FilterSelectField";

import { useLocations } from "hooks/useLocations";

import { ALL_FILTER_OPTION } from "appConstants";

export function LocationFilter(props) {
  const {
    value,
    label,
    name,
    onValueChange,
    onlyBinTracked,
    includeAllOption,
  } = props;

  const locations = useLocations(onlyBinTracked);

  const options =
    locations && includeAllOption
      ? [ALL_FILTER_OPTION, ...locations]
      : locations;

  function getValue() {
    if (value) {
      return value[0];
    }
    return includeAllOption ? ALL_FILTER_OPTION : null;
  }

  return (
    <FilterSelectField
      name={name}
      options={options}
      onValueChange={(name, value) => onValueChange(name, [value])}
      value={getValue()}
      label={label}
      disableClearable
      dataTesting="reportLocation"
    />
  );
}
