import { useEffect, useState } from "react";

import { Typography } from "@mui/material";

import { LINKED_TRANSACTION_OPTIONS } from "appConfig";

import { LineItemSelectScalar } from "components/formFields/LineItemSelectScalar";
import { LineItemTextField } from "components/formFields/LineItemTextField";
import { LineTableCell } from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineLinkedTransactionSelect(props) {
  const { name, onValueChange, value: initialValue, objectType } = props;

  const METADATA = LINE_ITEM_METADATA[objectType];

  const [value, setValue] = useState(
    METADATA.linkedTransaction.editStr(null, initialValue, null)
  );

  useEffect(() => {
    setValue(METADATA.linkedTransaction.editStr(null, initialValue, null));
  }, [initialValue, name, METADATA]);

  function handleValueChange(fieldName, newValue) {
    onValueChange("linkedTransaction", {
      ...value,
      [fieldName]: fieldName === "lineNumber" ? parseInt(newValue) : newValue,
    });
  }

  return (
    <LineTableCell sx={{ minWidth: "10rem" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>
          <LineItemSelectScalar
            name="transactionType"
            options={LINKED_TRANSACTION_OPTIONS[objectType]}
            onValueChange={handleValueChange}
            value={value.transactionType}
            minWidth="6rem"
          />
        </div>
        <LineItemTextField
          name="refNumber"
          value={value.refNumber}
          onValueChange={handleValueChange}
          metadata={METADATA.linkedTransactionRefNumber}
          width="8rem"
        />
        {METADATA.linkedTransactionLineNumber && (
          <>
            <Typography sx={{ whiteSpace: "nowrap", fontSize: "0.8125rem" }}>
              {i18n("global.LineNumber")}
            </Typography>
            <LineItemTextField
              name="lineNumber"
              value={value.lineNumber}
              number
              onValueChange={handleValueChange}
              metadata={METADATA.linkedTransactionLineNumber}
              width="3.5rem"
            />
          </>
        )}
      </div>
    </LineTableCell>
  );
}
