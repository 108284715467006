import { useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { Search as SearchIcon } from "@mui/icons-material";
import { Box, Typography, InputAdornment, TextField } from "@mui/material";

import { ActionButton } from "components/ActionButton";
import { ButtonProgress } from "components/utility/ButtonProgress";
import { LinkText } from "components/utility/LinkText";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import {
  globalSearch,
  getRecentTransactions,
} from "services/sosInventoryService/sosApi";

import { RecentTransactionsList } from "views/Search/RecentTransactionsList";
import { SearchList } from "views/Search/SearchList";
import { TipsDialog } from "views/Search/TipsDialog";

import {
  loadingIndicatorOn,
  loadingIndicatorOff,
} from "globalState/loadingSlice";

import { Forbidden } from "Forbidden";

export function Search() {
  const { search } = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchRef = useRef();
  const [showRecentTransactions, setShowRecentTransactions] = useState(true);
  const [openTips, setOpenTips] = useState(false);
  const [searchResults, setSearchResults] = useState(null);
  const [recentTransactions, setRecentTransactions] = useState(null);

  const isLoading = useSelector((state) => state.loading.list);
  const restrictedSalesRep = useSelector(
    (state) => state.userCompanySettings.company.restrictedSalesRep
  );

  const terms = useMemo(
    () => new URLSearchParams(search).get("terms"),
    [search]
  );

  const [query, setQuery] = useState(terms || "");

  useEffect(() => {
    async function search() {
      dispatch(loadingIndicatorOn());
      if (terms) {
        const response = await globalSearch(terms);
        setSearchResults(response);
        setShowRecentTransactions(false);
      } else {
        const response = await getRecentTransactions();
        setRecentTransactions(response);
      }
      dispatch(loadingIndicatorOff());
    }
    search();
  }, [terms, dispatch]);

  useEffect(() => searchRef.current?.focus(), []);

  async function onSubmit(e) {
    e.preventDefault();
    navigate(`/search?terms=${query}`, { replace: true });
  }

  if (restrictedSalesRep) {
    return <Forbidden />;
  }

  return (
    <>
      <Box sx={{ display: "grid", minWidth: "1070px" }} m={2} mb={0}>
        <Box mb={1}>
          <Typography variant="h2">{i18n("global.GlobalSearch")} </Typography>
        </Box>
        <form onSubmit={onSubmit}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              size="small"
              fullWidth
              value={query}
              variant="outlined"
              color="primary"
              onChange={(e) => setQuery(e.target.value)}
              placeholder={i18n("global.TypeYourQuery")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{ ref: searchRef }}
            />
            <div style={{ position: "relative" }}>
              <ActionButton
                disabled={isLoading && Boolean(terms)}
                headerButton
                type="submit"
              >
                {i18n("global.Search")}
              </ActionButton>
              {isLoading && Boolean(terms) && <ButtonProgress />}
            </div>
          </div>
        </form>
        <VSpace space={1} />
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <Typography>{i18n("search.For")}</Typography>
          <LinkText onClick={() => setOpenTips(true)}>
            {i18n("search.TipsAndKeywords")}
          </LinkText>
        </div>
        <VSpace space={1} />
        {showRecentTransactions ? (
          <RecentTransactionsList records={recentTransactions} />
        ) : (
          <SearchList records={searchResults} />
        )}
      </Box>
      {openTips && <TipsDialog setOpen={setOpenTips} />}
    </>
  );
}
