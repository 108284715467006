import { memo } from "react";
import { useSelector } from "react-redux";

import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";

import { columnInfo } from "services/sosInventoryService/notification/schema";

import { Row } from "views/Notifications/List/Row";

function List_(props) {
  const {
    objectType,
    records,
    checkedIds,
    selectAllIds,
    selectNoIds,
    setCheckedIds,
    selectAllState,
    setSelectAllState,
  } = props;

  const editModalIsOpen = useSelector((state) => state.modal.open);
  const sx = {
    height: "100%",
    "@media print": {
      overflow: "hidden",
      minHeight: "100%",
      maxHeight: "100%",
      display: editModalIsOpen ? "none" : "block",
    },
  };

  if (!checkedIds) {
    return null;
  }

  function handleCheckChange() {
    if (selectAllState) {
      selectNoIds();
      setSelectAllState(false);
    } else {
      selectAllIds();
      setSelectAllState(true);
    }
  }

  return (
    <Box sx={{ whiteSpace: "nowrap", height: "100%" }}>
      <TableContainer sx={sx}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ padding: 0, paddingLeft: "1rem", width: "1.5rem" }}
              >
                <Checkbox
                  id="all"
                  checked={selectAllState}
                  onClick={handleCheckChange}
                />
              </TableCell>
              <TableCell sx={{ width: "2rem" }} />
              {columnInfo.map((column, i) => (
                <ColumnHeading key={i} column={column} />
              ))}
              <TableCell sx={{ width: "2rem" }} />
            </TableRow>
          </TableHead>
          <TableBody data-testing="tableBody">
            {records.map((row, i) => (
              <Row
                key={i}
                objectType={objectType}
                columns={columnInfo}
                row={row}
                selected={checkedIds.includes(row.id)}
                setCheckedIds={setCheckedIds}
                setSelectAllState={setSelectAllState}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function ColumnHeading(props) {
  const { column } = props;
  return (
    <TableCell
      align="left"
      sx={{
        whiteSpace: "normal",
        position: "sticky",
        top: "0px",
        width: column.width || undefined,
        paddingLeft: "1rem",
      }}
    >
      {column.heading}
    </TableCell>
  );
}

export const List = memo(List_);
