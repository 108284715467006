import { useEffect, useState, useMemo } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";

import { Delete } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  Table,
  TableHead,
  TableFooter,
  TableRow,
  TableBody,
  IconButton,
  Card,
  CardContent,
} from "@mui/material";

import { Decimal } from "classes/DecimalClasses";

import { AddLines } from "components/EditPage/AddLines";
import { ClearLines } from "components/EditPage/ClearLines";
import { LineTableFooterCell } from "components/formFields/LineTableCell";
import { LineTableCell } from "components/formFields/LineTableCell";
import { TotalsLine } from "components/formFields/Totals/TotalsLine";
import { CardHeader } from "components/formFields/formCards/CardHeader";
import { FrmUnit } from "components/formFields/frm/FrmUnit";
import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";
import { LineDragInsertCopy } from "components/formFields/line/LineDragInsertCopy";
import { RowDecimalField } from "components/formFields/row/RowDecimalField";
import { RowSelectScalar } from "components/formFields/row/RowSelectScalar";
import { RowTextField } from "components/formFields/row/RowTextField";
import { DragHandleHeading } from "components/utility/DragHandle";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { ModalHeader } from "components/utility/ModalHeader";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { EMPTY_LINE } from "services/sosInventoryService/box/schema";
import { getBoxes, saveBoxes } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";
import { textForAddLineOrLines } from "services/utility/lineItems";

import { useErrors } from "hooks/useErrors";

import { openAlert } from "globalState/alertSlice";

import { FREIGHT_CLASSES } from "appConstants";

const sx = {
  "& .MuiDialog-paper": {
    minHeight: "50vh",
    maxHeight: "93vh",
    "@media print": { minHeight: "100%", maxHeight: "100%" },
  },
};

export function Boxes(props) {
  const { close, id, identifierText } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [record, setRecord] = useState(null);

  const { errors, setErrors } = useErrors();

  const dispatch = useDispatch();

  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  useEffect(() => {
    async function _getItemUom(id) {
      if (id) {
        const response = await getBoxes(id);
        if (!response.lines.length) {
          // if no box lines, default to one
          response.lines = [EMPTY_LINE];
        }
        setRecord(response);
      }
    }
    _getItemUom(id);
  }, [id]);

  function handleDrop(result) {
    const { source, destination } = result;

    // if there's no destination, the item was dropped outside of the
    // droppable area
    if (!destination) {
      return;
    }

    // destination same as source, no-op
    if (destination.index === source.index) {
      return;
    }

    const newRecord = { ...record };
    const [savedSource] = newRecord.lines.splice(source.index, 1);
    newRecord.lines.splice(destination.index, 0, savedSource);
    setRecord(newRecord);
  }

  function handleChange(name, value) {
    setRecord((prevRecord) => ({ ...prevRecord, [name]: value }));
  }

  function handleFieldChange(name, value, rowIndex) {
    setRecord((prevRecord) => ({
      ...prevRecord,
      lines: prevRecord.lines.map((e, index) =>
        rowIndex === index ? { ...e, [name]: value } : e
      ),
    }));
  }

  function clearLines() {
    const lines = new Array(numLinesToAdd).fill(EMPTY_LINE);
    setRecord((prevRecord) => ({ ...prevRecord, lines }));
  }

  function deleteRow(rowIndex) {
    setRecord((prevRecord) => ({
      ...prevRecord,
      lines: prevRecord.lines.filter((_, index) => index !== rowIndex),
    }));
  }

  function copyRow(rowIndex) {
    const lines = record.lines;
    lines.splice(rowIndex + 1, 1, record.lines[rowIndex]);
    setRecord((prevRecord) => ({ ...prevRecord, lines }));
  }

  function appendRows() {
    const newLines = new Array(numLinesToAdd).fill(EMPTY_LINE);
    const lines = [...record.lines, ...newLines];
    setRecord((prevRecord) => ({ ...prevRecord, lines }));
  }

  function insertRow(rowIndex) {
    let lines = record.lines;
    lines.splice(rowIndex, 0, EMPTY_LINE);
    setRecord((prevRecord) => ({ ...prevRecord, lines }));
  }

  async function save() {
    setIsLoading(true);
    const response = await saveBoxes(id, record);
    if (response.success) {
      const message = i18n("alert.SaveBoxesSuccess");
      dispatch(openAlert({ type: "success", message }));
      close();
    } else {
      handleProgramError(new Error(i18n("error.SaveBoxesFailure")));
    }
    setIsLoading(false);
  }

  const weight = useMemo(
    () =>
      record?.lines
        ? record.lines.reduce(
            (seed, e) => seed.plus(e.weight || Decimal.ZERO),
            Decimal.ZERO
          )
        : Decimal.ZERO,
    [record?.lines]
  );

  return (
    <Dialog open={true} fullWidth maxWidth="md" onClose={close} sx={sx}>
      <ModalHeader
        label={i18n("global.Boxes")}
        onClose={close}
        text={identifierText}
        save={save}
      />
      {(!record || isLoading) && <Loading />}
      <DialogContent>
        <FormErrors errors={errors} setErrors={setErrors} />
        {record ? (
          <>
            <VSpace space={1} />
            <Table size="small" padding="none" stickyHeader>
              <TableHead>
                <TableRow>
                  <DragHandleHeading />
                  <ClearLines setClearLines={clearLines} />
                  <LineColumnHeading labelCode="Description" />
                  <LineColumnHeading labelCode="Weight" align="right" />
                  <LineColumnHeading labelCode="Length" align="right" />
                  <LineColumnHeading labelCode="Width" align="right" />
                  <LineColumnHeading labelCode="Height" align="right" />
                  <LineColumnHeading labelCode="FreightClass" align="right" />
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={handleDrop}>
                <Droppable droppableId="lineItems">
                  {(provided) => (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {record.lines.map((box, index, array) => (
                        <Draggable
                          key={index}
                          draggableId={`${index}`}
                          index={index}
                        >
                          {(draggableProvided, snapshot) => {
                            return (
                              <TableRow
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                sx={{
                                  ...draggableProvided.draggableProps.style,
                                  position: "inherit",
                                  backgroundColor: snapshot.isDragging
                                    ? "dragBackground"
                                    : "none",
                                }}
                              >
                                <LineDragInsertCopy
                                  draggableProvided={draggableProvided}
                                  snapshot={snapshot}
                                  insertEmptyLine={() => insertRow(index)}
                                  lineNumber={index + 1}
                                  onCopyDown={() => copyRow(index)}
                                  showCopyDown={index + 1 !== array.length}
                                />
                                <Row
                                  itemName={record.item.name}
                                  key={index}
                                  box={box}
                                  rowIndex={index}
                                  handleFieldChange={handleFieldChange}
                                  copyRow={copyRow}
                                  deleteRow={deleteRow}
                                  showCopyDown={index !== array.length - 1}
                                />
                              </TableRow>
                            );
                          }}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
              <TableFooter>
                <TableRow>
                  <LineTableFooterCell sx={{ border: "none" }} />
                  <AddLines
                    title={textForAddLineOrLines(numLinesToAdd)}
                    onClick={appendRows}
                  />
                </TableRow>
              </TableFooter>
            </Table>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div
                  style={{
                    display: "flex",
                    gap: "2rem",
                    justifyContent: "end",
                  }}
                >
                  <FrmUnit
                    name="weightUnit"
                    value={record.weightUnit}
                    onValueChange={handleChange}
                  />
                  <FrmUnit
                    name="dimensionUnit"
                    value={record.dimensionUnit}
                    onValueChange={handleChange}
                  />
                </div>
              </div>
              <Card variant="outlined" sx={{ width: "20rem" }}>
                <CardContent>
                  <CardHeader text={i18n("global.Totals")} />
                  <TotalsLine
                    label={i18n("global.Weight")}
                    value={weight}
                    units={record.weightUnit}
                  />
                </CardContent>
              </Card>
            </div>
            <VSpace space={1} />
            <VSpace space={1} />
          </>
        ) : (
          <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
        )}
      </DialogContent>
    </Dialog>
  );
}

export function Row(props) {
  const { rowIndex, box, handleFieldChange, deleteRow } = props;

  const { description, weight, length, width, height, freightClass } = box;

  return (
    <>
      <LineTableCell
        sx={{ width: "3rem", textAlign: "center", position: "relative" }}
      >
        <IconButton size="small" onClick={() => deleteRow(rowIndex)}>
          <Delete />
        </IconButton>
      </LineTableCell>
      <LineTableCell sx={{ width: "20rem" }}>
        <RowTextField
          name="description"
          value={description}
          onValueBlur={handleFieldChange}
          dataIndex={rowIndex}
        />
      </LineTableCell>
      <LineTableCell sx={{ width: "16rem" }}>
        <RowDecimalField
          name="weight"
          dataIndex={rowIndex}
          value={weight || ""}
          onValueBlur={handleFieldChange}
        />
      </LineTableCell>
      <LineTableCell>
        <RowDecimalField
          name="length"
          dataIndex={rowIndex}
          value={length || ""}
          onValueBlur={handleFieldChange}
        />
      </LineTableCell>
      <LineTableCell>
        <RowDecimalField
          name="width"
          dataIndex={rowIndex}
          value={width || ""}
          onValueBlur={handleFieldChange}
        />
      </LineTableCell>
      <LineTableCell>
        <RowDecimalField
          name="height"
          dataIndex={rowIndex}
          value={height || ""}
          onValueBlur={handleFieldChange}
        />
      </LineTableCell>
      <LineTableCell sx={{ width: "16rem" }}>
        <RowSelectScalar
          disableClearable
          name="freightClass"
          dataIndex={rowIndex}
          options={FREIGHT_CLASSES}
          onValueChange={handleFieldChange}
          value={freightClass}
        />
      </LineTableCell>
    </>
  );
}
