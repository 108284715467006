import { DialogContent } from "@mui/material";

export function ObjectEditDialogContent(props) {
  const { sx = {} } = props;
  return (
    <DialogContent
      sx={{
        "@media print": { overflow: "hidden", height: "100%", width: "100%" },
        pt: 0,
        ...sx,
      }}
    >
      {props.children}
    </DialogContent>
  );
}
