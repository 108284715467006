import { useEffect, useState } from "react";

import { getQuickList } from "services/sosInventoryService/sosApi";

export function useLots(params) {
  const [lots, setLots] = useState(null);

  useEffect(() => {
    async function _getLots() {
      const json = await getQuickList("lot");
      setLots(json.data);
    }

    setLots(null);
    _getLots();
  }, []);

  return lots;
}
