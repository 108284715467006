import { ITEM_CALCULATED_FIELDS } from "appConfig";
import { IN_TRANSACTION } from "appConfig";

import { Money } from "classes/DecimalClasses";

import { removeLotAndSerialValues } from "services/sosInventoryService/domainLogic";
import { updateLineItemOnhand } from "services/sosInventoryService/domainLogic";
import {
  updateAvailableBinsAndBin,
  updateLineRelatedRecordsItem,
} from "services/sosInventoryService/domainLogic";
import { updateLineItemToBins } from "services/sosInventoryService/domainLogic";
import { updateTransferLineRelatedRecordsItem } from "services/sosInventoryService/inventoryTransaction/domainLogic";
import { getRecord, getRecordFrom } from "services/sosInventoryService/sosApi";
import { getEmptyRecord } from "services/sosInventoryService/transfer/schema";
import { reconcileCustomFields } from "services/utility/customFields";
import { getRelatedReferenceObjects } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";

import globalState from "globalState/globalState";

import { OBJECT_TYPES } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.TRANSFER.fullString;

export async function getTransaction(
  id,
  newFromId,
  newFromObjectType,
  updaters,
  customFieldDefs
) {
  let record, lines, relatedRecords;

  if (id || newFromObjectType) {
    if (id) {
      record = await getRecord(OBJECT_TYPE, id);
    } else {
      // it's a "populate from" request
      record = await getRecordFrom(OBJECT_TYPE, newFromObjectType, newFromId);
      delete record.id;
    }

    record.customFields = reconcileCustomFields(
      customFieldDefs,
      record.customFields
    );
    lines = record.lines;
    delete record.lines;

    updaters.lineHandler({ type: "set", lines });
    updaters.setRecord(record);

    relatedRecords = await getRelatedReferenceObjects(OBJECT_TYPE, record);

    updaters.setRelatedRecords(relatedRecords);

    // get items for each line item, so that we have updated item info for
    // the calculations below
    lines = await updateLineRelatedRecordsItem(
      record.fromLocation,
      record.date,
      lines,
      ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
    );
    // set the default bin and the available bins for the dropdown
    lines = await updateAvailableBinsAndBin(record.fromLocation, lines);
    // update onhand info in the line items
    lines = updateLineItemOnhand(lines);
  } else {
    // new record
    const { settings } = globalState.getState().userCompanySettings;
    const defaultLocation =
      globalState.getState().userCompanySettings.settings.defaultLocation;
    const numLinesToAdd =
      globalState.getState().userCompanySettings.settings.numLinesToAdd;

    record = {
      ...getEmptyRecord(settings),
      customFields: reconcileCustomFields(customFieldDefs, []),
    };

    lines = Array(numLinesToAdd).fill(EMPTY_LINE_ITEM[OBJECT_TYPE]);

    relatedRecords = {};

    if (defaultLocation) {
      record.fromLocation = defaultLocation;
      record.toLocation = defaultLocation;
      const location = await getRecord(
        "location",
        defaultLocation.id,
        IN_TRANSACTION
      );

      relatedRecords = {
        ...relatedRecords,
        fromLocation: location,
        toLocation: location,
      };
    }

    updaters.setRelatedRecords(relatedRecords);
    updaters.setRecord(record);
  }
  updaters.lineHandler({ type: "set", lines });
}

export async function updateTransaction(
  field,
  newValue,
  currentState,
  updaters
) {
  const { record, lines, relatedRecords } = currentState;
  const { setRelatedRecords, lineHandler, setRecord } = updaters;
  if (!record || !lines || !relatedRecords) {
    handleProgramError(new Error("update Transaction | invalid currentState"));
  }

  let newRecord, newLines, newRelatedRecords;

  switch (field) {
    case "date":
      newRecord = { ...record, date: newValue };
      setRecord(newRecord);

      if (newValue) {
        newLines = await updateTransferLineRelatedRecordsItem(
          record.fromLocation,
          record.date,
          lines,
          ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
        );

        // set the default bin and the available bins for the dropdown
        newLines = await updateAvailableBinsAndBin(
          newRecord.fromLocation,
          newLines,
          "fromBin"
        );
      }
      break;

    case "fromLocation":
      newRecord = { ...record, fromLocation: newValue };
      setRecord(newRecord);

      try {
        const fromLocation = newValue
          ? await getRecord("location", newValue?.id)
          : null;
        newRelatedRecords = { ...relatedRecords, fromLocation };
        newLines = await updateTransferLineRelatedRecordsItem(
          fromLocation,
          record.date,
          lines,
          ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
        );

        // set the default bin and the available bins for the dropdown
        newLines = await updateAvailableBinsAndBin(
          newRecord.fromLocation,
          newLines,
          "fromBin"
        );
        newLines = removeLotAndSerialValues(newLines);
      } catch (e) {
        handleProgramError(e);
      }

      break;

    case "toLocation":
      newRecord = { ...record, toLocation: newValue };
      setRecord(newRecord);

      try {
        const toLocation = newValue
          ? await getRecord("location", newValue?.id)
          : null;
        newRelatedRecords = { ...relatedRecords, toLocation };
        newLines = await updateLineItemToBins(lines, toLocation);
      } catch (e) {
        handleProgramError(e);
      }

      break;

    case "comment":
    case "createBillForShippingAmount":
    case "customFields":
    case "department":
    case "number":
    case "shippingMethod":
    case "trackingNumber":
      newRecord = { ...record, [field]: newValue };
      setRecord(newRecord);
      break;

    case "shippingAmount":
      newRecord = { ...record, [field]: new Money(newValue || 0) };
      updaters.setRecord(newRecord);
      break;

    default:
      handleProgramError(
        new Error(`updateTransaction | unknown field (${field})`)
      );
  }

  if (newLines) {
    lineHandler({ type: "set", lines: newLines });
  }
  if (newRelatedRecords) {
    setRelatedRecords(newRelatedRecords);
  }
}
