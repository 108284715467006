import { Box } from "@mui/material";

import { FrmTextField } from "components/formFields/FrmTextField";

import { i18n } from "services/i18nService";

export function FrmImageSize(props) {
  const { value, onValueChange, name, disabled, includeLabels } = props;

  function handleValueChange(fieldName, newValue) {
    onValueChange(name, { ...value, [fieldName]: +newValue });
  }

  if (!value) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        top: "-1.0rem",
        columnGap: 1,
      }}
    >
      <FrmTextField
        number
        name="width"
        value={value.width}
        onValueBlur={handleValueChange}
        sx={{ maxWidth: "5.0rem" }}
        disabled={disabled}
        label={includeLabels ? i18n("global.Width") : undefined}
      />
      <Box sx={{ position: "relative", top: "2px" }}>x</Box>
      <FrmTextField
        number
        name="height"
        value={value.height}
        onValueBlur={handleValueChange}
        sx={{ maxWidth: "5.0rem" }}
        disabled={disabled}
        label={includeLabels ? i18n("global.Height") : undefined}
      />
    </Box>
  );
}
