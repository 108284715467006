import { i18n } from "services/i18nService";

import { SUB_COMPONENT } from "views/Reports/reportConstants";

import { OBJECT_TYPES } from "appConstants";

export const ProfitLossByJob = {
  userHasSufficientPrivilege: (p) => (p.viewJobs || p.editJobs) && p.viewCosts,
  planIncludesReport: (_, pro) => pro,
  customFieldObjectTypes: "job",
  customReportNameFetchList: [{ objectType: OBJECT_TYPES.CUSTOMER.fullString }],
  columns: [
    { apiName: "Name", default: true },
    { apiName: "Job Number" },
    { apiName: "Description" },
    { apiName: "Customer" },
    { apiName: "Income", number: true, alwaysShow: true },
    { apiName: "Cost", number: true, alwaysShow: true },
    { apiName: "Profit", number: true, alwaysShow: true },
    { apiName: "% Profit", number: true, alwaysShow: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      defaultValue: null,
      datePrefix: "startOf",
      type: "filter",
    },
    {
      apiName: "date1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("filter.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.JobNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "suppressIfEmpty",
      defaultValue: true,
      label: i18n("reports.OptionLabel.HideRowsWithNoTransactions"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedJobs"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
