export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixRMA: record.rmaNumber.prefix,
    nextRMANumber: record.rmaNumber.nextNumber,
  };

  delete newRecord.rmaNumber;

  return newRecord;
}
