import { i18n } from "services/i18nService";

import {
  EXPIRED_RECALLED_OPTIONS,
  GROUP_BY_ITEM_OPTIONS,
  NONE_GROUP_BY_OPTION,
  ON_HAND_AND_AVAILABLE_VALUE_CALCULATION_OPTIONS,
  ON_HAND_VALUE_CALCULATION_OPTION,
  SUB_COMPONENT,
} from "views/Reports/reportConstants";
import { ALL_OPTION_WITH_ALL_ID } from "views/Reports/reportConstants";

import { ITEM_QUICKLIST_CASES, OBJECT_TYPES } from "appConstants";

export const LotStockStatus = {
  userHasSufficientPrivilege: (p) =>
    p.viewInventory || p.viewItems || p.editItems,
  planIncludesReport: (plus) => plus,
  customReportNameFetchList: [
    { objectType: OBJECT_TYPES.ITEM.fullString, customReportKey: "categories" },
    {
      objectType: OBJECT_TYPES.LOCATION.fullString,
      customReportKey: "locations",
    },
    { objectType: OBJECT_TYPES.ITEM.fullString },
  ],
  customFieldObjectTypes: ["lot", "item"],
  columns: [
    { apiName: "Lot", default: true },
    { apiName: "Item", default: true },
    { apiName: "Description" },
    { apiName: "Item Description" },
    { apiName: "Expiration" },
    { apiName: "Recalled" },
    { apiName: "SKU" },
    { apiName: "Total", number: true, alwaysShow: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.asOf"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.AsOfDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date1Macro",
      component: SUB_COMPONENT,
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Location"),
      component: "LocationsFilter",
      apiName: "locations",
      defaultValue: null,
      includeTotalOnlyOption: true,
      placeholder: i18n("reports.placeholder.DefaultIsTotalsOnly"),
      type: "filter",
    },
    {
      label: i18n("filter.Category"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Item"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
      itemType: ITEM_QUICKLIST_CASES.LOT,
    },
    {
      label: i18n("reports.FilterLabel.LotNumberContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "txnType",
      defaultValue: ALL_OPTION_WITH_ALL_ID,
      label: "Status",
      component: "FrmSelectFromObjects",
      options: EXPIRED_RECALLED_OPTIONS,
      type: "filter",
    },
    {
      apiName: "suppressIfEmpty",
      defaultValue: false,
      label: i18n("reports.OptionLabel.HideRowsWithAllZeros"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowAsOfDate"),
      component: "FrmCheckbox",
      isAsOfDate: true,
      type: "option",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: GROUP_BY_ITEM_OPTIONS,
      type: "option",
    },
    {
      apiName: "valueCalculation",
      defaultValue: ON_HAND_VALUE_CALCULATION_OPTION,
      label: i18n("reports.OptionLabel.ReportValue"),
      component: "FrmSelectFromObjects",
      options: ON_HAND_AND_AVAILABLE_VALUE_CALCULATION_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
