import { moreTransactionBeforeSave } from "services/sosInventoryService/moreTransaction/beforeSave";

import { OBJECT_TYPES } from "appConstants";

const OBJECT_TYPE = OBJECT_TYPES.RETURN.fullString;

export function beforeSave(record) {
  const newRecord = moreTransactionBeforeSave(OBJECT_TYPE, record);
  return newRecord;
}
