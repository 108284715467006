import { useEffect, useState } from "react";

import { IN_TRANSACTION } from "appConfig";

import { getAll } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

// if useRelated is called with the inTransaction parameter set to
// IN_TRANSACTION, useRelated will pass this flag to sosApi, which
// will cause the related GETs to succeed, even if normally user
// privileges would cause them to fail; this should be used advisedly,
// as necessary when in a transaction that needs read access to
// related records (such as to populate a customer select list while
// editing an item receipt)
export function useUoms(objectType, inTransaction = IN_TRANSACTION) {
  const [records, setRecords] = useState(null);

  useEffect(() => {
    async function retrieveRecords() {
      const response = await getAll("uom", inTransaction);
      if (response.success) {
        setRecords(
          response.data.records.map(({ id, abbreviation }) => ({
            id,
            name: abbreviation,
          }))
        );
      } else {
        handleProgramError(
          new Error(
            `useUoms | unsuccessful call to getAll, message: ${response.message}`
          )
        );
      }
    }
    retrieveRecords();
  }, [objectType, inTransaction]);

  return records;
}
