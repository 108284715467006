import { afterGet as globalAfterGet } from "services/sosInventoryService/afterGet";
import { moreTransactionAfterGet } from "services/sosInventoryService/moreTransaction/afterGet";
import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/rma/schema";
import {
  transformFields,
  transformLineItemFields,
  nonUtcDateTimeHandling,
} from "services/utility/afterGet";

import { OBJECT_TYPES } from "appConstants";
import { LINE_ITEM_FIELDS } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.RMA.fullString;

export function afterGet(record) {
  let newRecord = nonUtcDateTimeHandling(record);

  newRecord = transformFields(newRecord, FIELDS_TO_TRANSFORM);

  newRecord.lines = transformLineItemFields(
    newRecord.lines,
    LINE_ITEM_FIELDS[OBJECT_TYPE]
  );

  newRecord = moreTransactionAfterGet(newRecord);

  return globalAfterGet(newRecord);
}
