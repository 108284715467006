import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box } from "@mui/material";

import { Tooltip } from "components/Tooltip";
import { LineTableFooterCell } from "components/formFields/LineTableCell";

export function AddLines(props) {
  const { title, onClick, sx = {} } = props;
  return (
    <LineTableFooterCell sx={{ textAlign: "center", border: "none" }}>
      <Tooltip title={title}>
        <Box
          data-testing="addLines"
          onClick={onClick}
          sx={{ cursor: "pointer", color: "add", mt: "-6px", ...sx }}
        >
          <AddCircleIcon />
        </Box>
      </Tooltip>
    </LineTableFooterCell>
  );
}
