import { useSelector } from "react-redux";

import {
  TableHead,
  Table,
  TableRow,
  TableBody,
  TableFooter,
} from "@mui/material";

import { Money } from "classes/DecimalClasses";
import { Loading } from "classes/Loading";

import { AddLines } from "components/EditPage/AddLines";
import { ClearLines } from "components/EditPage/ClearLines";
import { LineTableFooterCell } from "components/formFields/LineTableCell";
import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";
import { RowStaticMoney } from "components/formFields/row/RowStaticMoney";

import { EMPTY_LINE } from "services/sosInventoryService/processTemplate/schema";
import { textForAddLineOrLines } from "services/utility/lineItems";

import { TemplateRow } from "views/ProcessTemplates/ProcessTemplate/TemplateRow";

import { DEFAULT_DECIMALS_ROUNDED } from "appConstants";

export function TemplateTable(props) {
  const { setRecord, lines, items, itemsCount, lineType } = props;

  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  function clearLines() {
    const newLines = new Array(numLinesToAdd).fill(EMPTY_LINE[lineType]);
    setRecord((prevRecord) => ({ ...prevRecord, [lineType]: newLines }));
  }

  function appendRows() {
    const emptyLines = new Array(numLinesToAdd).fill(EMPTY_LINE[lineType]);
    const newLines = [...lines, ...emptyLines];
    setRecord((prevRecord) => ({ ...prevRecord, [lineType]: newLines }));
  }

  function deleteRow(rowIndex) {
    setRecord((prevRecord) => ({
      ...prevRecord,
      [lineType]: prevRecord[lineType].filter((_, index) => index !== rowIndex),
    }));
  }

  if (!lines) {
    return null;
  }

  const footerTotal = lines.reduce(
    (seed, { cost, quantity }) =>
      cost && !(cost instanceof Loading)
        ? seed.plus(cost.times(quantity))
        : seed,
    new Money(0)
  );

  return (
    <Table size="small" padding="none" stickyHeader>
      <TableHead>
        <TableRow>
          <ClearLines setClearLines={clearLines} />
          <LineColumnHeading labelCode="Item" />
          <LineColumnHeading labelCode="Quantity" />
          <LineColumnHeading labelCode="CostEach" />
          <LineColumnHeading labelCode="Total" />
          {lineType === "outputs" && <LineColumnHeading labelCode="Waste" />}
        </TableRow>
      </TableHead>
      <TableBody>
        {lines.map((line, i) => (
          <TemplateRow
            key={i}
            rowIndex={i}
            line={line}
            items={items}
            itemsCount={itemsCount}
            setRecord={setRecord}
            deleteRow={() => deleteRow(i)}
            lineType={lineType}
          />
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <AddLines
            title={textForAddLineOrLines(numLinesToAdd)}
            onClick={appendRows}
          />
          <LineTableFooterCell colSpan={3} />
          <LineTableFooterCell bordered sx={{ padding: "0.5rem 0.3rem" }}>
            <RowStaticMoney
              decimalPlaces={DEFAULT_DECIMALS_ROUNDED}
              value={footerTotal}
            />
          </LineTableFooterCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}
