export function afterGet(record) {
  const newRecord = {
    ...record,
    returnNumber: {
      prefix: record.prefixReturn,
      nextNumber: record.nextReturnNumber,
    },
  };

  delete newRecord.prefixReturn;
  delete newRecord.nextReturnNumber;

  return newRecord;
}
