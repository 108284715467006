export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixItemReceipt: record.itemReceiptNumber.prefix,
    nextItemReceiptNumber: record.itemReceiptNumber.nextNumber,
  };

  delete newRecord.itemReceiptNumber;

  return newRecord;
}
