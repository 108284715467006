export const SETTINGS = [
  {
    apiName: "neverConnectQBO",
    component: "FrmCheckbox",
  },
  {
    apiName: "multiCurrencyEnabled",
    component: "FrmCheckbox",
  },

  {
    apiName: "homeCurrency",
    component: "FrmHomeCurrency",
  },
  {
    apiName: "closingDate",
    component: "DatePicker",
  },
];
