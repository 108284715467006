import { useSelector, useDispatch } from "react-redux";

import { DoubleArrow } from "@mui/icons-material";
import { IconButton, ListItem } from "@mui/material";

import { toggle } from "globalState/leftNavSlice";
import { LEFT_NAV_TEXT } from "globalState/leftNavSlice";

import { theme } from "SosTheme";

const sx = {
  button: {
    borderColor: "accent",
    border: "solid 2px",
    marginTop: theme.spacing(0.5),
    "&:hover": {
      backgroundColor: "transparent",
    },
    width: "25px",
    height: "25px",
  },
  iconRight: { color: "accent", padding: "2px" },
  iconLeft: { color: "accent", padding: "2px", transform: "rotate(180deg)" },
};

export function NavToggle(props) {
  const { listSx } = props;

  const leftNavState = useSelector((state) => state.leftNav.iconsText);
  const dispatch = useDispatch();

  function switchState() {
    dispatch(toggle());
  }

  const icon =
    leftNavState === LEFT_NAV_TEXT ? (
      <DoubleArrow sx={sx.iconLeft} />
    ) : (
      <DoubleArrow sx={sx.iconRight} />
    );
  return (
    <ListItem sx={listSx}>
      <IconButton
        size="small"
        onClick={switchState}
        sx={sx.button}
        disableFocusRipple
      >
        {icon}
      </IconButton>
    </ListItem>
  );
}
