import { i18n } from "services/i18nService";


export const DATE_FORMAT_OPTIONS = [
  { id: "M/d/yyyy", name: i18n("settings.user.general.dateFormat.M/d/yyyy") },
  {
    id: "dd/MM/yyyy",
    name: i18n("settings.user.general.dateFormat.dd/MM/yyyy"),
  },
];

export const NOTIFICATION_METHOD_OPTIONS = [
  {
    id: "None",
    name: i18n("settings.user.general.notificatioMethod.None"),
  },
  {
    id: "Email",
    name: i18n("settings.user.general.notificatioMethod.Email"),
  },
  {
    id: "Text",
    name: i18n("settings.user.general.notificatioMethod.Text"),
  },
  {
    id: "EmailText",
    name: i18n("settings.user.general.notificatioMethod.EmailText"),
  },
];

export const PASSWORD_QUESTIONS_OPTIONS = [
  {
    id: "What was the name of your elementary / primary school?",
    name: i18n("settings.user.general.passwordQuestion.SchoolName"),
  },
  {
    id: "What is the street number of the house you grew up in?",
    name: i18n("settings.user.general.passwordQuestion.HouseStreetNumber"),
  },
  {
    id: "In what city or town did your mother and father meet?",
    name: i18n("settings.user.general.passwordQuestion.CityWhereParentsMet"),
  },
  {
    id: "What was the color of your first car?",
    name: i18n("settings.user.general.passwordQuestion.FirstCarColor"),
  },
  {
    id: "What is the name of your favorite childhood friend?",
    name: i18n("settings.user.general.passwordQuestion.ChildhoodFriendName"),
  },
  {
    id: "In what city was your first job?",
    name: i18n("settings.user.general.passwordQuestion.FirstJobCity"),
  },
];

export const MOBILE_CARRIER_OPTIONS = [
  { name: i18n("settings.user.general.mobileCarrier.ATT"), id: "ATT" },
  { name: i18n("settings.user.general.mobileCarrier.AU"), id: "AU" },
  { name: i18n("settings.user.general.mobileCarrier.Orange"), id: "Orange" },
  { name: i18n("settings.user.general.mobileCarrier.Sprint"), id: "Sprint" },
  { name: i18n("settings.user.general.mobileCarrier.Telus"), id: "Telus" },
  { name: i18n("settings.user.general.mobileCarrier.TMobile"), id: "TMobile" },
  { name: i18n("settings.user.general.mobileCarrier.Verizon"), id: "Verizon" },
];
