import ClearIcon from "@mui/icons-material/Clear";
import { IconButton } from "@mui/material";

import { theme } from "SosTheme";

const sx = {
  padding: "0.4rem",
  border: "1px solid",
  borderColor: "lineSeparatorMinor",
  backgroundColor: "white",
  displayPrint: "none",
  "& svg": { transition: "transform .2s" },
  "&:hover svg": { transition: "transform .2s", transform: "rotate(90deg)" },
};

export function CloseX(props) {
  const { onClick, dataTesting } = props;

  return (
    <div>
      <IconButton
        sx={sx}
        style={{ borderRadius: theme.shape.borderRadius }}
        onClick={onClick}
        data-testing={dataTesting}
        size="large"
      >
        <ClearIcon />
      </IconButton>
    </div>
  );
}
