import { Decimal } from "classes/DecimalClasses";

import { afterGet as globalAfterGet } from "services/sosInventoryService/afterGet";
import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/build/schema";
import { productionTransactionAfterGet } from "services/sosInventoryService/productionTransaction/afterGet";
import {
  transformFields,
  transformLineItemFields,
  transformSerial,
} from "services/utility/afterGet";
import { isNonZeroDecimalOrMoney } from "services/utility/misc";

import { OBJECT_TYPES } from "appConstants";
import { OUTPUT_LINE_ITEM_FIELDS, INPUT_LINE_ITEM_FIELDS } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.BUILD.fullString;

export function afterGet(record) {
  let newRecord = transformFields(record, FIELDS_TO_TRANSFORM);

  newRecord.inputs = transformLineItemFields(
    newRecord.inputs,
    INPUT_LINE_ITEM_FIELDS[OBJECT_TYPE]
  );

  newRecord.outputs = transformLineItemFields(
    newRecord.outputs,
    OUTPUT_LINE_ITEM_FIELDS[OBJECT_TYPE]
  );

  // Add usedPer input line item data
  const outputQuantity = newRecord.outputs[0].quantity;
  newRecord.inputs.forEach((input, i) => {
    newRecord.inputs[i].usedPer = isNonZeroDecimalOrMoney(outputQuantity)
      ? input.quantity.div(outputQuantity)
      : new Decimal(0);
  });

  // transform the output lot, lotExpiration, and serial
  newRecord.outputs = newRecord.outputs.map((output) =>
    transformSerial(output, newRecord.autoSerialLots)
  );

  newRecord = globalAfterGet(newRecord);
  return productionTransactionAfterGet(newRecord);
}
