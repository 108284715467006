import { useSelector } from "react-redux";

import { Card, CardContent } from "@mui/material";

import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { LocationToggle } from "components/formFields/address/LocationToggle";
import { CardHeader } from "components/formFields/formCards/CardHeader";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function ShippingAddress(props) {
  const { value, handleInputFieldChange, location, customer, isDropShip } =
    props;

  const findCustomerByFullName = useSelector(
    (state) => state.userCompanySettings.settings.findCustomerByFullName
  );

  let options = useRelated(isDropShip ? "customer" : "location") || [];

  function getCustomerOption() {
    if (options?.length && customer) {
      const customerOption = options.find(({ id }) => id === customer.id);
      return customerOption || null;
    }
    return null;
  }

  return (
    <Card variant="outlined" data-testing="shipping">
      <CardContent>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CardHeader
            text={i18n("purchaseOrder.ShippingAddress")}
            gutterBottom={false}
          />
          <div style={{ flexGrow: 1, paddingLeft: "1rem" }}>
            {isDropShip ? (
              <FrmSelectFromObjects
                name="customer"
                optionDisplayText={findCustomerByFullName ? "fullname" : "name"}
                label={i18n("global.Customer")}
                value={getCustomerOption()}
                onValueChange={handleInputFieldChange}
                options={options}
              />
            ) : (
              <FrmSelectFromObjects
                name="location"
                label={i18n("global.Location")}
                value={location?.id ? location : null}
                onValueChange={handleInputFieldChange}
                options={options}
              />
            )}
          </div>
        </div>
        <LocationToggle
          value={value}
          addressType="shipping"
          handleInputFieldChange={handleInputFieldChange}
        />
      </CardContent>
    </Card>
  );
}
