export const SETTINGS = [
  {
    apiName: "autoBuildShipment",
    component: "FrmCheckbox",
  },
  {
    apiName: "shipmentNumber",
    component: "FrmTransactionNumber",
    transactionType: "shipment",
    hasInputLabel: true,
  },
  {
    apiName: "packingSlipText",
    component: "FrmTextField",
    maxLength: 50,
  },
  {
    apiName: "replyEmailPS",
    component: "FrmTextField",
    maxLength: 255,
  },
  {
    apiName: "packingSlipEmail",
    component: "FrmResizeTextField",
  },
  {
    apiName: "packingSlipFooter",
    component: "FrmResizeTextField",
  },
  {
    apiName: "showCustEmailOnPackingSlip",
    component: "FrmCheckbox",
  },
  {
    apiName: "showCustPhoneOnPackingSlip",
    component: "FrmCheckbox",
  },
  {
    apiName: "shipFromAddressOfLocation",
    component: "FrmCheckbox",
  },
  {
    apiName: "billOfLadingFooter",
    component: "FrmResizeTextField",
  },
  {
    apiName: "defaultCCShipment",
    component: "FrmTextField",
    maxLength: 255,
  },
  {
    apiName: "showWarrantyOnPackingSlip",
    component: "FrmCheckbox",
  },
];
