import { i18n } from "services/i18nService";
import { getDocument } from "services/sosInventoryService/sosApi";
import { formatDate } from "services/utility/dates";
import {
  extractName,
  formatMoney,
  formatDocumentInfo,
  formatSync,
} from "services/utility/formatting";

export const billConfig = {
  columns: [
    {
      heading: i18n("columns.Vendor"),
      apiName: "vendor",
      sortable: true,
      formatFunc: extractName,
    },
    {
      heading: i18n("columns.BillDate"),
      defaultSort: true,
      sortDirection: "desc",
      apiName: "date",
      sortable: true,
      formatFunc: formatDate,
    },
    {
      heading: i18n("columns.DueDate"),
      apiName: "dueDate",
      sortable: true,
      minWidth: "8rem",
      formatFunc: formatDate,
    },
    {
      heading: i18n("columns.ReferenceNumber"),
      apiName: "refNumber",
      sortable: true,
    },
    {
      heading: i18n("columns.OriginalAmount"),
      apiName: "amount",
      sortable: true,
      formatFunc: formatMoney,
      align: "right",
    },
    {
      heading: i18n("columns.Sync"),
      apiName: "syncMessage",
      sortable: true,
      formatFunc: formatSync,
    },
    {
      heading: i18n("columns.Documents"),
      apiName: "documents",
      formatFunc: formatDocumentInfo,
      apiFunc: getDocument,
      isDocument: true,
      maxWidth: "12rem",
    },
  ],
};
