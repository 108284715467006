export class CustomFieldDefinitions {
  #definitionDataAsArray;
  #definitionDataAsObject;

  constructor(dataAsArray) {
    this.definitionDataAsArray = dataAsArray;
    this.definitionDataAsObject = customFieldDefArrayToObject(dataAsArray);
  }

  get asArray() {
    return this.definitionDataAsArray;
  }

  get asObject() {
    return this.definitionDataAsObject;
  }
}

function customFieldDefArrayToObject(customFieldDefArray) {
  let customFieldDefs = {};
  customFieldDefArray.forEach(({ name, showOn, ...rest }) => {
    customFieldDefs[name] = rest;
  });
  return customFieldDefs;
}

