import { Box, Dialog } from "@mui/material";
import { Container, Typography } from "@mui/material";

import { Link } from "components/html/Link";
import { CloseX } from "components/utility/CloseX";
import { ModalTitle } from "components/utility/ModalTitle";

import { i18n } from "services/i18nService";

export function ForbiddenModal(props) {
  const { open, handleClose, objectType } = props;

  return (
    <Dialog open={open}>
      <Box
        sx={{
          backgroundColor: "dialogTitleBackground",
          borderBottom: "1px solid",
          borderBottomColor: "lineSeparatorMinor",
          padding: "0.5rem 1rem",
          fontSize: "1.2rem",
        }}
      >
        <div style={{ display: "flex", alignItems: "top" }}>
          <CloseX onClick={handleClose} />
          <ModalTitle label={i18n(`objectType.${objectType}.Sentence`)} />
        </div>
      </Box>
      <Container maxWidth="md" sx={{ paddingTop: "25px" }}>
        <Typography variant="h3">{i18n("restricted.EditTitle")}</Typography>
        <p>
          {i18n("restricted.EditInfo")} <Link to="/">{i18n("global.Home")}</Link>
        </p>
      </Container>
    </Dialog>
  );
}
