import { ErrorOutline, DoneOutline } from "@mui/icons-material";
import { Box } from "@mui/material";

import { Tooltip } from "components/Tooltip";
import { TopNavIcon } from "components/TopNav/TopNavIcon";

import { i18n } from "services/i18nService";

const sx = {
  icon: {
    display: "inline-block",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    marginTop: "-2px",
  },
  box: { display: "inline-block", cursor: "pointer" },
};

export function SyncMenuTopNavIcon(props) {
  const { syncStatus, onClick, functionText } = props;

  const showSpin = syncStatus === "sync_inprogress";

  if (syncStatus === "sync_complete") {
    return (
      <Box onClick={onClick} sx={sx.box}>
        <Tooltip title={i18n("tooltip.SyncComplete")}>
          <DoneOutline
            variant="outlined"
            sx={{ ...sx.icon, color: "accent" }}
          />
        </Tooltip>
      </Box>
    );
  }

  if (syncStatus === "sync_error") {
    return (
      <Box
        onClick={onClick}
        sx={{ display: "inline-block", cursor: "pointer" }}
      >
        <Tooltip title={i18n("tooltip.SyncError")}>
          <ErrorOutline
            variant="outlined"
            sx={{ ...sx.icon, color: "warning.error" }}
          />
        </Tooltip>
      </Box>
    );
  }

  return (
    <TopNavIcon
      syncStatus={syncStatus}
      showSpin={showSpin}
      image="/images/sync.svg"
      onClick={onClick}
      functionText={functionText}
    />
  );
}
