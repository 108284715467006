import { Droppable, Draggable } from "react-beautiful-dnd";

import { Typography } from "@mui/material";

import { VSpace } from "components/utility/VSpace";

import { getCustomFieldName } from "services/utility/customFields";

export function ColumnList(props) {
  const { heading, id, columns } = props;
  return (
    <>
      <Typography variant="h4">{heading}</Typography>
      <VSpace space={1} />
      <Droppable droppableId={id} key={id}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ paddingBottom: "2em", minHeight: "100%" }}
          >
            <div style={{ minHeight: "5em" }}>
              {columns.map((column, index) => (
                <Draggable
                  draggableId={column.name}
                  index={index}
                  key={column.name}
                  isDragDisabled={column?.required}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Typography>
                        {column?.heading || getCustomFieldName(column.name)}
                      </Typography>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
}
