import { FilterSelectField } from "components/formFields/FilterSelectField";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

import { ALL_FILTER_OPTION } from "appConstants";
import { OBJECT_TYPES } from "appConstants";

export function EditVendorFilter(props) {
  const { filters, setFilters } = props;
  const { filterSettings } = filters;
  const { Vendor } = filterSettings;

  const retrievedVendors = useRelated(OBJECT_TYPES.VENDOR.fullString);

  function handleChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        Vendor: value?.id || "",
      },
      startingRecord: 1,
    }));
  }

  const vendors = retrievedVendors?.map(({ id, name }) => ({
    id: id?.toString(),
    name,
  }));

  const options = vendors ? [ALL_FILTER_OPTION, ...vendors] : [];

  return (
    <FilterSelectField
      options={options}
      onValueChange={handleChange}
      value={Vendor ? { id: Vendor } : ALL_FILTER_OPTION}
      label={i18n("filter.Vendor")}
      dataTesting="vendorFilter"
      disableClearable
    />
  );
}
