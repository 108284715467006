import { useMemo } from "react";

import { CustomField } from "components/quickView/CustomField";

import {
  reconcileCustomFields,
  getCustomFieldValue,
} from "services/utility/customFields";

export function CustomFields(props) {
  const { customFieldDefinitions, customFields } = props;

  const reconciledCustomFields = useMemo(
    () => reconcileCustomFields(customFieldDefinitions, customFields),
    [customFieldDefinitions, customFields]
  );

  if (!customFieldDefinitions || customFields?.length === 0) {
    return null;
  }

  return (
    <>
      {customFieldDefinitions.asArray.map(({ name, dataType }) => (
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            overflowWrap: "break-word",
            flexWrap: "wrap",
            lineHeight: 1,
            padding: "0.2rem 0",
          }}
          key={name}
        >
          <CustomField
            name={name}
            dataType={dataType}
            value={getCustomFieldValue(reconciledCustomFields, name)}
          />
        </div>
      ))}
    </>
  );
}
