import { useState } from "react";

import { EditToggle } from "components/EditToggle";
import { ContactAddress } from "components/formFields/address/ContactAddress";

import { i18n } from "services/i18nService";
import { formatCompanyInfo } from "services/utility/formatting";
import { isAddressEmpty } from "services/utility/misc";

import { theme } from "SosTheme";

export function LocationToggle(props) {
  const { value, addressType, handleInputFieldChange } = props;
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      style={{
        padding: "1rem 0",
        display: "grid",
        gridTemplateColumns: "8fr minmax(auto, 1fr)",
        columnGap: theme.spacing(1),
      }}
    >
      {expanded ? (
        <div style={{ flexGrow: 1, paddingRight: "1rem" }}>
          <ContactAddress
            value={value}
            handleInputFieldChange={handleInputFieldChange}
            addressType={addressType}
          />
        </div>
      ) : (
        <div
          style={{
            paddingLeft: "1rem",
            whiteSpace: "pre-line",
            lineHeight: "1.25rem",
            overflow: "auto",
            overflowWrap: "break-word",
          }}
        >
          {isAddressEmpty(value) ? (
            i18n("global.NoAddressEntered")
          ) : (
            <div>{formatCompanyInfo(value)}</div>
          )}
        </div>
      )}
      <div style={{ textAlign: "right" }}>
        <EditToggle
          toggle={() => setExpanded(!expanded)}
          isExpanded={expanded}
        />
      </div>
    </div>
  );
}
