export function ImageFromBase64(props) {
  const { width, height, maxWidth, maxHeight, base64String } = props;

  if (!base64String) {
    return null;
  }

  return (
    <img
      src={"data:image;base64, " + base64String}
      alt="Item"
      style={{ width, height, maxWidth, maxHeight }}
    />
  );
}
