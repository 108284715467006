import { CircularProgress } from "@mui/material";

export function ButtonProgress(props) {
  const { dataTesting, color, size = "1.5em", position = "absolute" } = props;
  return (
    <CircularProgress
      size={size}
      color={color}
      sx={{
        position,
        top: "50%",
        left: "50%",
        marginTop: "-12px",
        marginLeft: "-12px",
      }}
      data-testing={dataTesting}
    />
  );
}
