import { Card } from "@mui/material";

import { IMAGE_DIMENSION } from "appConfig";

import { ActionButton } from "components/ActionButton";
import { CardHeader } from "components/formFields/formCards/CardHeader";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";

export function FrmAddImage(props) {
  const { record, handleImageChange, handleDelete, label } = props;

  return (
    <Card
      variant="outlined"
      sx={{ marginTop: "1.0em", marginBottom: "1.0em", padding: "1.0em" }}
    >
      {record.imageAsBase64String && <CardHeader text={label} />}
      <div
        style={{
          width: record.imageAsBase64String ? IMAGE_DIMENSION : "auto",
          height: record.imageAsBase64String ? IMAGE_DIMENSION : "auto",
        }}
      >
        {record.imageAsBase64String && (
          <img
            src={"data:image;base64, " + record.imageAsBase64String}
            alt="Item"
            style={{ maxWidth: IMAGE_DIMENSION, maxHeight: IMAGE_DIMENSION }}
          />
        )}
      </div>
      <VSpace space={1} />
      <ActionButton component="label" htmlFor="itemImage">
        {record.imageAsBase64String
          ? i18n("item.imageChange")
          : i18n("item.imageAdd")}
        <input
          id="itemImage"
          type="file"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
      </ActionButton>
      {record.imageAsBase64String && (
        <ActionButton
          component="label"
          onClick={handleDelete}
          sx={{ marginLeft: "1.0em" }}
        >
          {i18n("item.imageRemove")}
        </ActionButton>
      )}
    </Card>
  );
}
