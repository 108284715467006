import { Dialog } from "@mui/material";

import { JobProfitLoss } from "components/JobProfitLoss";
import { SalesOrderProfitLoss } from "components/SalesOrderProfitLoss";

const PROFIT_LOSS_PAGES = {
  job: JobProfitLoss,
  salesorder: SalesOrderProfitLoss,
};
const DIALOG_SIZE = { job: "xl", salesorder: "md" };

export function ProfitLossDialog(props) {
  const { close, objectType, id, identifierText } = props;

  const ProfitLossPage = PROFIT_LOSS_PAGES[objectType];
  const size = DIALOG_SIZE[objectType];

  return (
    <Dialog open={true} maxWidth={size} fullWidth onClose={close}>
      <ProfitLossPage
        close={close}
        objectType={objectType}
        id={id}
        identifierText={identifierText}
      />
    </Dialog>
  );
}
