import { useSelector, useDispatch } from "react-redux";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { CustomFields } from "components/CustomFields/CustomFields";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmTextField } from "components/formFields/FrmTextField";
import {
  FrmLocation,
  FrmCustomer,
  FrmWarranty,
  FrmAddImage,
  FrmBarcode,
  FrmItemSelect,
} from "components/formFields/frm";
import { DatePicker } from "components/html/Input/DatePicker";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { HoverHelp } from "components/utility/HoverHelp";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { readImage } from "services/utility/images";
import { isPersistedRecord } from "services/utility/misc";

import { useCustomFieldDefinitions } from "hooks/useCustomFieldDefinitions";
import { useErrors } from "hooks/useErrors";
import { useObject } from "hooks/useObject";
import { useRecord } from "hooks/useRecord";

import { openAlert } from "globalState/alertSlice";

import { OBJECT_TYPES } from "appConstants";
import { ITEM_QUICKLIST_CASES } from "appConstants";

const OBJECT_TYPE = OBJECT_TYPES.SERIAL.fullString;

export function Serial(props) {
  const { id, onClose, onAdd } = props;

  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();
  const dispatch = useDispatch();
  const loadingState = useSelector((state) => state.loading.editModal);

  const customFieldDefs = useCustomFieldDefinitions(OBJECT_TYPE);

  useObject(OBJECT_TYPE, id, setRecord, customFieldDefs, setErrors);

  async function handleWarrantyChange(fieldName, value) {
    setRecord((prevRecord) => ({
      ...prevRecord,
      warranty: { ...prevRecord.warranty, [fieldName]: value },
    }));
  }

  function updateRecordWithImage(imageAsBase64String, imageScaled) {
    setRecord((prevRecord) => ({
      ...prevRecord,
      hasImage: true,
      imageChanged: true,
      imageAsBase64String,
      imageScaled,
    }));
  }

  async function handleImageSelection(e) {
    const { success, unsupportedFileType } = readImage(
      e.target.files[0],
      updateRecordWithImage
    );
    if (!success) {
      const message = i18n("file.FileTypeNotSupported", {
        fileType: unsupportedFileType,
      });
      dispatch(openAlert({ type: "error", message }));
      return;
    }
    // if successful, readImage will call updateRecordWithImage with the
    // image strings (original and scaled)
  }

  function handleDeleteImage() {
    setRecord((prevRecord) => ({
      ...prevRecord,
      imageAsBase64String: null,
      hasImage: false,
      imageChanged: true,
      imageScaled: false,
    }));
  }

  function handleCustomFieldsChange(_, newValue) {
    setRecord((prevRecord) => ({ ...prevRecord, customFields: newValue }));
  }

  // if new transaction with record not defined
  // show null till record set in useObject
  if (!id && !record) {
    return null;
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        setErrors={setErrors}
        errors={errors}
        text={record?.number || ""}
        handleClose={onClose}
        onAdd={onAdd}
      />

      {loadingState && <Loading />}

      {record && (
        <ObjectEditDialogContent>
          <Layout
            pageTitle={
              id
                ? `${i18n("objectType.serial.Sentence")} ${
                    record?.number || ""
                  }`
                : i18n("objectType.serial.New")
            }
          >
            <Box flexGrow="1" position="relative" id="B" m={2} mb={0}>
              <FormErrors errors={errors} setErrors={setErrors} />
              {record ? (
                <Box>
                  <FrmFieldGrid gridAutoFlow="dense">
                    <FrmTextField
                      name="number"
                      value={record.number}
                      label={i18n("frmLabel.SerialNumber")}
                      onValueBlur={handleInputFieldChange}
                      error={isInError("number")}
                    />
                    <FrmItemSelect
                      name="item"
                      value={record.item}
                      onValueChange={handleInputFieldChange}
                      disabled={isPersistedRecord(record.id)}
                      error={isInError("item")}
                      type={ITEM_QUICKLIST_CASES.SERIAL}
                    />
                  </FrmFieldGrid>
                  <VSpace space={1} />
                  <FrmFieldGrid gridAutoFlow="dense">
                    <FrmResizeTextField
                      margin="none"
                      name="description"
                      value={record.description}
                      label={i18n("frmLabel.Description")}
                      minRows={2}
                      width="100%"
                      onValueBlur={handleInputFieldChange}
                    />
                  </FrmFieldGrid>
                  <VSpace space={1} />
                  <FrmFieldGrid gridAutoFlow="dense">
                    <FrmLocation
                      value={record.location}
                      disabled={Boolean(
                        isPersistedRecord(record.id) || record.customer
                      )}
                      onValueChange={handleInputFieldChange}
                      error={isInError("location")}
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FrmCustomer
                        value={record.customer}
                        onValueChange={handleInputFieldChange}
                        disabled={Boolean(
                          isPersistedRecord(record.id) || record.location
                        )}
                        sx={{ flexGrow: 1 }}
                      />
                      <HoverHelp
                        title={i18n("hoverHelp.CustomerHoverHelp")}
                        sx={{ marginLeft: 0.5 }}
                      />
                    </div>
                    <FrmWarranty
                      value={record.warranty?.warranty}
                      onValueChange={handleWarrantyChange}
                    />
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <DatePicker
                        name="start"
                        value={record.warranty?.start}
                        label={i18n("frmLabel.WarrantyStart")}
                        onValueChange={handleWarrantyChange}
                      />
                      <DatePicker
                        name="end"
                        value={record.warranty?.end}
                        label={i18n("frmLabel.WarrantyEnd")}
                        onValueChange={handleWarrantyChange}
                      />
                    </Box>
                  </FrmFieldGrid>
                  <FrmFieldGrid>
                    <FrmAddImage
                      key={record.imageAsBase64String}
                      record={record}
                      label={i18n(
                        record.imageScaled
                          ? "file.ImageScaledToFit"
                          : "file.Image"
                      )}
                      handleImageChange={handleImageSelection}
                      handleDelete={handleDeleteImage}
                    />
                    <div style={{ width: "100%" }}>
                      <FrmBarcode number={record.number} />
                    </div>
                  </FrmFieldGrid>
                  <CustomFields
                    customFieldDefinitions={customFieldDefs}
                    customFields={record.customFields || []}
                    onValueChange={handleCustomFieldsChange}
                    setErrors={setErrors}
                  />
                </Box>
              ) : (
                !isInError("record") && (
                  <FixedLoadingIndicator
                    text={`${i18n("global.Loading")}...`}
                  />
                )
              )}
            </Box>
            <VSpace space={4} />
          </Layout>
        </ObjectEditDialogContent>
      )}
    </>
  );
}
