import { i18n } from "services/i18nService";

export const SETTINGS = [
  {
    apiName: "itemReceiptNumber",
    component: "FrmTransactionNumber",
    transactionType: "item receipt",
    hasInputLabel: true,
  },
  {
    apiName: "defaultSyncItemReceipts",
    component: "FrmRadioGroup",
    options: [
      { value: "None", label: i18n("settings.itemReceiptSyncOptions.None") },
      { value: "Bill", label: i18n("settings.itemReceiptSyncOptions.Bill") },
      { value: "Cash", label: i18n("settings.itemReceiptSyncOptions.Cash") },
      { value: "Check", label: i18n("settings.itemReceiptSyncOptions.Check") },
      {
        value: "Credit Card",
        label: i18n("settings.itemReceiptSyncOptions.CreditCard"),
      },
    ],
  },
];
