import { onPo } from "services/sosInventoryService/onPo/schema";
import { onRental } from "services/sosInventoryService/onRental/schema";
import { onRma } from "services/sosInventoryService/onRma/schema";
import { onSo } from "services/sosInventoryService/onSo/schema";
import { onSr } from "services/sosInventoryService/onSr/schema";
import { onWo } from "services/sosInventoryService/onWo/schema";
import { transformFields } from "services/utility/afterGet";

const FIELDS_TO_TRANSFORM = {
  onPo: onPo.FIELDS_TO_TRANSFORM,
  onRental: onRental.FIELDS_TO_TRANSFORM,
  onRma: onRma.FIELDS_TO_TRANSFORM,
  onSo: onSo.FIELDS_TO_TRANSFORM,
  onSr: onSr.FIELDS_TO_TRANSFORM,
  onWo: onWo.FIELDS_TO_TRANSFORM,
};

export function afterGet(record, type) {
  const newRecord = transformFields(record, FIELDS_TO_TRANSFORM[type]);
  return newRecord;
}
