import { i18n } from "services/i18nService";

export const columnInfo = [
  {
    name: "lastName",
    heading: i18n("columns.LastName"),
    fixed: true,
    defaultSort: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "firstName",
    heading: i18n("columns.FirstName"),
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
  },
];

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];

export function getEmptyRecord() {
  return { firstName: null, lastName: null };
}
