export function afterGet(record) {
  const newRecord = {
    ...record,
    jobNumber: {
      prefix: record.prefixJob,
      nextNumber: record.nextJobNumber,
    },
  };

  delete newRecord.prefixJob;
  delete newRecord.nextJob;

  return newRecord;
}
