import { Decimal } from "classes/DecimalClasses";

import { normalizeSerials } from "services/utility/serials";

export const OUTPUT_ZERO_TOTALS = {
  quantity: new Decimal(0),
};

// when this field (the key) changes, we should run the (value) list of
// updater functions
const UPDATERS = {
  quantity: [updateSerials],
};

export function afterTouchOutputLine(line, changedField, record) {
  if (!UPDATERS[changedField]) {
    return line;
  }
  let newLine = { ...line };

  UPDATERS[changedField].forEach((updater) => {
    newLine = updater(newLine, record);
  });

  return newLine;
}

function updateSerials(line, record) {
  const { quantity, serials, itemDetails } = line;
  const { autoSerialLotNumbering } = record;
  return {
    ...line,
    serials: normalizeSerials(
      serials,
      quantity,
      itemDetails.serialTracking,
      autoSerialLotNumbering
    ),
  };
}

export function updateOutputTotals(lines, setTotals) {
  if (!lines || lines.length === 0) {
    setTotals(OUTPUT_ZERO_TOTALS);
    return;
  }
  const quantity = lines.reduce((total, line) => {
    return total.plus(line.quantity);
  }, new Decimal(0));
  setTotals({ quantity });
}
