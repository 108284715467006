import { yyyymmddToDate, datefromDateFormat } from "services/utility/dates";

// Can simplify this once v8 is obsolete or this story is implemented:
// https://app.clickup.com/t/pv708c

const DATE_FIELDS = ["FromDate", "ToDate", "ToExpiration", "FromExpiration"];
// the following can be treated as Javascript primitive numbers (as opposed
// to using our Decimal class), as there is no multiplication or division
// done with them
const NUMBER_FIELDS = ["startingRecord", "maxResults"];

export function afterGet(record, dateFormat) {
  let newRecord = { ...record };

  DATE_FIELDS.forEach((field) => {
    newRecord.filterSettings[field] = transformListSettingDate(
      record.filterSettings[field],
      dateFormat
    );
  });

  NUMBER_FIELDS.forEach((field) => {
    newRecord.filterSettings[field] = parseInt(record.filterSettings[field]);
  });

  return newRecord;
}

function transformListSettingDate(date, format) {
  if (!date) {
    return null;
  }
  return date.includes("-")
    ? yyyymmddToDate(date)
    : datefromDateFormat(date, format);
}
