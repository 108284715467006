import {
  updateLot,
  updateSerials,
  getUomConversion,
} from "services/utility/afterTouchLine";
import { afterTouchLine as baseAfterTouchLine } from "services/utility/afterTouchLine";

// when this field (the key) changes, we should run the (value) list of
// updater functions
const UPDATERS = {
  uom: [updateOnHand],
  bin: [updateLot, updateSerials],
};

export function afterTouchLine(line, changedField) {
  return baseAfterTouchLine(UPDATERS, line, changedField);
}

function updateOnHand(line) {
  const {
    uom,
    relatedRecords: { item },
    itemDetails,
  } = line;

  // calculate the conversion using the item details UOM data
  const conversion = getUomConversion(itemDetails, uom);

  const onhand = item?.onhand.div(conversion);
  return { ...line, onhand };
}
