import { useState } from "react";

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";

import { FrmCheckbox } from "components/formFields/frm/FrmCheckbox";
import { CloseX } from "components/utility/CloseX";

import { i18n } from "services/i18nService";

import { usePlans } from "hooks/usePlans";

import { DASHBOARD_CONFIG } from "views/Dashboard/dashboardConfig";

export function SettingsDialog(props) {
  const { close, settings: externalSettings, saveSettings } = props;
  const [settings, setSettings] = useState(externalSettings);
  const { hasAtLeastPlusPlan, hasAtLeastProPlan } = usePlans();

  async function onValueChange(name, value) {
    setSettings((prev) => ({ ...prev, [name]: value }));
  }

  return (
    <Dialog open={true} onClose={close} fullWidth maxWidth="xs">
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h3">
            {i18n("dashboard.DashboardSettings")}
          </Typography>
          <CloseX onClick={close} dataTesting="exitSettings" />
        </div>
      </DialogTitle>
      <DialogContent data-testing="dashboardSettings">
        {DASHBOARD_CONFIG.map(({ label, widgets }, i) => {
          return (
            <Box sx={{ pb: 1 }}>
              <Typography
                variant="tag"
                component="p"
                sx={{ textTransform: "uppercase" }}
              >
                {label}
              </Typography>
              {widgets.map(({ type, setting, planIncludesSetting }) => {
                const planEnabled =
                  !planIncludesSetting ||
                  planIncludesSetting(hasAtLeastPlusPlan, hasAtLeastProPlan);
                return planEnabled ? (
                  <div key={i}>
                    <FrmCheckbox
                      name={setting}
                      value={settings[setting]}
                      onValueChange={onValueChange}
                      label={i18n(`dashboard.show.${type}`)}
                      dataTesting={`${type}Setting`}
                    />
                  </div>
                ) : null;
              })}
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={close}>
          {i18n("button.Cancel")}
        </Button>
        <div style={{ position: "relative" }}>
          <Button
            data-testing="applySettings"
            color="secondary"
            onClick={() => saveSettings(settings)}
          >
            {i18n("global.Update")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
