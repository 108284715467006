import { Decimal } from "classes/DecimalClasses";

// input is an array of UOMs, such as those returned by the /item endpoint;
// the base UOM is returned, or null if none
export function getBaseUom(uoms) {
  return uoms.reduce((seed, e) => (e.isBase ? e.uom : seed), null);
}

export function getNonBaseUoms(uoms) {
  return uoms.filter((uom) => !uom.isBase);
}

// Input is uom reference Object and array of UOMS,
// Defaults to 1 if no UOM or UOM conversion is not defined
export function getUomConversionFromUomReference(uom, uoms) {
  if (!uom || !uoms) {
    return new Decimal(1);
  }
  const fullUom = uoms.find(({ uom: { id } }) => id === uom?.id);
  const conversion = fullUom?.conversion || new Decimal(1);
  return conversion;
}
