import { useState, useEffect } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TextField } from "@mui/material";

import { checkForUnexpectedProps } from "services/utility/misc";

export function FrmResizeTextField(props) {
  const {
    value: initialValue,
    label,
    id,
    name,
    margin,
    placeholder,
    onValueBlur,
    width,
    minRows,
    maxLength,
    maxRows,
    dataTesting,
    error,
    sx,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmResizeTextField", unexpected);

  const [value, setValue] = useState(initialValue);
  const [updated, setUpdated] = useState(false);

  useEffect(() => setValue(initialValue), [initialValue]);

  function handleChange(e) {
    if (maxLength && e.target.value.length > maxLength) {
      return;
    }
    setUpdated(true);
    setValue(e.target.value);
  }

  function handleValueBlur(e) {
    if (updated) {
      onValueBlur(e.target.name, e.target?.value);
      setUpdated(false);
    }
  }

  return (
    <TextField
      label={label}
      id={id}
      name={name}
      minRows={minRows}
      maxRows={maxRows}
      fullWidth
      autoComplete="off"
      margin="dense"
      sx={sx}
      onChange={handleChange}
      onBlur={handleValueBlur}
      value={value || ""}
      multiline
      placeholder={placeholder}
      error={error}
      variant="outlined"
      inputProps={{ style: { resize: "both", minWidth: "96%" } }}
      InputProps={{ style: { paddingBottom: "2px", paddingTop: "8px" } }}
      SelectProps={{
        IconComponent: KeyboardArrowDownIcon,
        MenuProps: { disableScrollLock: true },
      }}
      InputLabelProps={{ shrink: true }}
      data-testing={dataTesting}
    />
  );
}
