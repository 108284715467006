import { FrmItemSelect } from "components/formFields/frm";

export function ItemFilter(props) {
  const { value, label, name, onValueChange, itemType } = props;

  return (
    <FrmItemSelect
      name={name}
      label={label}
      value={value}
      onValueChange={onValueChange}
      type={itemType}
      dataTesting="reportItem"
    />
  );
}
