import { FilterSelectField } from "components/formFields/FilterSelectField";

import { ALL_FILTER_OPTION } from "appConstants";

export function SalesRepFilter(props) {
  const { value, onValueChange, name, label, salesReps } = props;

  const options = salesReps ? [ALL_FILTER_OPTION, ...salesReps] : [];

  return (
    <FilterSelectField
      name={name}
      label={label}
      options={options}
      value={value[0]}
      onValueChange={(name, value) => onValueChange(name, [value])}
      disableClearable
    />
  );
}
