import { useState } from "react";

import { setPageDirty } from "services/utility/edit";

export function useRecord() {
  const [record, setRecord] = useState(null);

  function handleInputFieldChange(fieldName, value) {
    setPageDirty();
    setRecord((prevRecord) => ({ ...prevRecord, [fieldName]: value }));
  }

  return [record, setRecord, handleInputFieldChange];
}
