import { FrmTextField } from "components/formFields/FrmTextField";

import { i18n } from "services/i18nService";
import { isPersistedRecord } from "services/utility/misc";

export function FrmNumber(props) {
  const {
    onValueChange,
    label,
    error,
    value,
    recordId,
    name = "number",
    placeholder = i18n("frmLabel.AssignedUponSave"),
  } = props;

  return (
    <FrmTextField
      name={name}
      value={value}
      label={label}
      error={error}
      sx={{ width: "19rem" }}
      maxLength={21}
      onValueBlur={onValueChange}
      placeholder={isPersistedRecord(recordId) ? "" : placeholder}
      dataTesting="number"
    />
  );
}
