import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { CustomFields } from "components/CustomFields/CustomFields";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmTextField } from "components/formFields/FrmTextField";
import {
  FrmBarcode,
  FrmItemSelect,
  FrmCheckbox,
} from "components/formFields/frm";
import { DatePicker } from "components/html/Input/DatePicker";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { isPersistedRecord } from "services/utility/misc";

import { useCustomFieldDefinitions } from "hooks/useCustomFieldDefinitions";
import { useErrors } from "hooks/useErrors";
import { useObject } from "hooks/useObject";
import { useRecord } from "hooks/useRecord";

import { OBJECT_TYPES } from "appConstants";
import { ITEM_QUICKLIST_CASES } from "appConstants";

const OBJECT_TYPE = OBJECT_TYPES.LOT.fullString;

export function Lot(props) {
  const { id, onClose, onAdd } = props;

  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();

  const loadingState = useSelector((state) => state.loading.editModal);

  const customFieldDefs = useCustomFieldDefinitions(OBJECT_TYPE);

  useObject(OBJECT_TYPE, id, setRecord, customFieldDefs, setErrors);

  function handleCustomFieldsChange(_, newValue) {
    setRecord((prevRecord) => ({ ...prevRecord, customFields: newValue }));
  }

  // if new transaction with record not defined
  // show null until record set in useObject
  if (!id && !record) {
    return null;
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        setErrors={setErrors}
        errors={errors}
        text={record?.number || ""}
        handleClose={onClose}
        onAdd={onAdd}
      />
      {loadingState && <Loading />}
      <ObjectEditDialogContent>
        <Layout
          pageTitle={
            id
              ? `${i18n("objectType.lot.Sentence")} ${record?.number || ""}`
              : i18n("objectType.lot.New")
          }
        >
          <Box flexGrow="1" position="relative" id="B" m={2} mb={0}>
            <FormErrors errors={errors} setErrors={setErrors} />
            {record ? (
              <Box>
                <FrmFieldGrid gridAutoFlow="dense">
                  <FrmTextField
                    name="number"
                    value={record.number}
                    label={i18n("frmLabel.LotNumber")}
                    onValueBlur={handleInputFieldChange}
                    error={isInError("number")}
                    dataTesting="number"
                  />
                  <FrmItemSelect
                    name="item"
                    value={record.item}
                    onValueChange={handleInputFieldChange}
                    disabled={isPersistedRecord(record.id)}
                    error={isInError("item")}
                    type={ITEM_QUICKLIST_CASES.LOT}
                  />
                </FrmFieldGrid>
                <VSpace space={1} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <FrmResizeTextField
                    margin="none"
                    name="description"
                    value={record.description}
                    label={i18n("frmLabel.Description")}
                    minRows={2}
                    width="100%"
                    onValueBlur={handleInputFieldChange}
                  />
                </FrmFieldGrid>
                <VSpace space={1} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <DatePicker
                    value={record.expiration}
                    name="expiration"
                    label={i18n("frmLabel.ExpirationDate")}
                    onValueChange={handleInputFieldChange}
                  />
                  <FrmCheckbox
                    name="recalled"
                    value={record.recalled}
                    onValueChange={handleInputFieldChange}
                    label={i18n("frmLabel.Recalled")}
                    sx={{ marginRight: "8px" }}
                  />
                </FrmFieldGrid>
                <FrmFieldGrid>
                  <div style={{ width: "50%" }}>
                    <FrmBarcode number={record.number} />
                  </div>
                </FrmFieldGrid>
                <CustomFields
                  customFieldDefinitions={customFieldDefs}
                  customFields={record.customFields || []}
                  onValueChange={handleCustomFieldsChange}
                  setErrors={setErrors}
                />
              </Box>
            ) : (
              !isInError("record") && (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )
            )}
          </Box>
          <VSpace space={4} />
        </Layout>
      </ObjectEditDialogContent>
    </>
  );
}
