import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";

import { useChartOfAccounts } from "hooks/useChartOfAccounts";

export function FrmAccount(props) {
  const {
    value,
    onValueChange,
    name,
    label = "",
    accountType,
    hideHelpText,
    disableClearable,
  } = props;

  const accounts = useChartOfAccounts(Boolean(accountType));

  function getOptionLabel(option) {
    if (option.name) {
      return option.name.trimStart();
    }
    if (!accounts) {
      return "";
    }
    const label = accounts.find(({ id }) => id === option.id);
    return label ? label.name : "";
  }

  return (
    <div style={{ display: "flex", alignItems: "center", maxWidth: "30em" }}>
      <div style={{ flexGrow: "1" }}>
        <FrmSelectFromObjects
          name={name}
          label={label}
          value={value}
          onValueChange={onValueChange}
          getOptionLabel={getOptionLabel}
          options={accountType && accounts ? accounts[accountType] : accounts}
          disableClearable={disableClearable}
        />
      </div>
      {!hideHelpText && (
        <HoverHelp
          title={i18n("hoverHelp.vendorCreditAccount")}
          sx={{ marginLeft: 0.5 }}
        />
      )}
    </div>
  );
}
