export const SETTINGS = [
  {
    apiName: "defaultIncomeAccount",
    component: "FrmAccount",
    accountType: "income",
  },
  {
    apiName: "defaultAssetAccount",
    component: "FrmAccount",
    accountType: "asset",
  },
  {
    apiName: "defaultCOGSAccount",
    component: "FrmAccount",
    accountType: "cogs",
  },
  {
    apiName: "defaultAdjustmentAccount",
    component: "FrmAccount",
  },
  {
    apiName: "defaultCheckAccount",
    component: "FrmAccount",
    accountType: "tender",
  },
  {
    apiName: "defaultCreditCardAccount",
    component: "FrmAccount",
    accountType: "credit",
  },
  {
    apiName: "defaultCashPurchaseAccount",
    component: "FrmAccount",
    accountType: "tender",
  },
];
