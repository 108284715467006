import { i18n } from "services/i18nService";
import {
  formatNameLinkField,
  formatArrayOfTypes,
} from "services/utility/formatting";

import { OBJECT_TYPES } from "appConstants";

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: formatNameLinkField,
    fixed: true,
    defaultSort: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "description",
    heading: i18n("columns.Description"),
    fixedRightOfAction: true,
    fixed: true,
    sortable: true,
  },
  {
    name: "dataType",
    heading: i18n("columns.DataType"),
    fixedRightOfAction: true,
    fixed: true,
    sortable: true,
  },
  {
    name: "displayOn",
    heading: i18n("columns.ShowOn"),
    formatFunc: formatArrayOfTypes,
    fixed: true,
    fixedRightOfAction: true,
  },
];

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];

const textDataTypeOption = { id: "Text", name: i18n("global.Text") };
export const listDataTypeOption = { id: "List", name: i18n("global.List") };

export const DATA_TYPE = [
  textDataTypeOption,
  { id: "Multiline Text", name: i18n("global.MultiLineText") },
  { id: "Number", name: i18n("global.Number") },
  { id: "Money", name: i18n("global.Money") },
  { id: "Date", name: i18n("global.Date") },
  { id: "Boolean", name: i18n("global.Checkbox") },
  listDataTypeOption,
];

export const DISPLAY_ON_OPTIONS = [
  {
    id: OBJECT_TYPES.ADJUSTMENT.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.ADJUSTMENT.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.BUILD.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.BUILD.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.CUSTOMER.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.CUSTOMER.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.ESTIMATE.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.ESTIMATE.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.INVOICE.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.INVOICE.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.ITEM_RECEIPT.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.ITEM_RECEIPT.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.ITEM.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.ITEM.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.JOB.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.JOB.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.LOT.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.LOT.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.PAYMENT.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.PAYMENT.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.PICK_TICKET.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.PICK_TICKET.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.PROCESS.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.PROCESS.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.PURCHASE_ORDER.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.PURCHASE_ORDER.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.RENTAL.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.RENTAL.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.RENTAL_RETURN.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.RENTAL_RETURN.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.RETURN.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.RETURN.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.RETURN_TO_VENDOR.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.RETURN_TO_VENDOR.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.RMA.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.RMA.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.SALES_ORDER.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.SALES_ORDER.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.SALES_RECEIPT.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.SALES_RECEIPT.fullString}.SentencePlural`
    ),
  },
  {
    id: OBJECT_TYPES.SERIAL.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.SERIAL.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.SHIPMENT.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.SHIPMENT.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.TRANSFER.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.TRANSFER.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.VENDOR.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.VENDOR.fullString}.SentencePlural`),
  },
  {
    id: OBJECT_TYPES.WORK_ORDER.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.WORK_ORDER.fullString}.SentencePlural`
    ),
  },
];

export function getEmptyRecord() {
  return {
    name: null,
    description: null,
    dataType: textDataTypeOption.id,
    displayOn: [],
  };
}
