// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import {
  extractName,
  formatImage,
  formatMoneyWithoutAdornments,
  formatDocumentInfo,
  formatNameLinkField,
  formatInteractiveInventoryColumn,
  formatItemQtyByItemType,
  formatItemAvailabilityByItemType,
  formatMoneyForAccounting,
  truncate,
  formatBooleanYesOrBlank,
  formatPercentWithAdornments,
  formatDecimal,
  indentVariantNames,
} from "services/utility/formatting";
import { parseHTML } from "services/utility/parsing";
import { starColumn } from "services/utility/schema";

import {
  PLAN_PLUS,
  PLAN_PRO,
  USER_PRIVILEGE,
  DEFAULT_ITEM_TYPE,
} from "appConstants";

export const columnInfo = [
  starColumn,
  {
    name: "name",
    fixed: true,
    formatFunc: [indentVariantNames, formatNameLinkField],
    heading: i18n("global.Name"),
    defaultSort: true,
    linkField: true,
    sortable: true,
    maxWidth: "25em",
  },
  {
    name: "id",
    heading: i18n("columns.System"),
    sortDirection: "asc",
    minWidth: "7em",
    align: "right",
    sortable: true,
    default: true,
  },
  {
    name: "description",
    minWidth: "15em",
    heading: i18n("columns.Description"),
    formatFunc: truncate,
    sortable: true,
    default: true,
  },
  {
    name: "onhand",
    heading: i18n("columns.InStock"),
    formatFunc: formatItemQtyByItemType,
    align: "right",
    sortable: true,
    default: true,
  },
  {
    name: "category",
    heading: i18n("columns.Category"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "type",
    heading: i18n("columns.Type"),
    sortable: true,
  },
  {
    name: "fullname",
    heading: i18n("columns.FullName"),
    sortable: true,
  },
  {
    name: "vendor",
    heading: i18n("columns.Vendor"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "20em",
    sortable: true,
    privilegeRestriction: [
      USER_PRIVILEGE.editItems,
      USER_PRIVILEGE.viewVendors,
    ],
  },
  {
    name: "maxStock",
    heading: i18n("columns.MaxStock"),
    formatFunc: formatItemQtyByItemType,
    sortable: true,
    align: "right",
  },
  {
    name: "salesPrice",
    heading: i18n("columns.SalesPrice"),
    formatFunc: formatMoneyWithoutAdornments,
    sortable: true,
    align: "right",
  },
  {
    name: "purchaseDescription",
    heading: i18n("columns.PurchaseDescription"),
    sortable: true,
  },
  {
    name: "warranty",
    heading: i18n("columns.Warranty"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "vendorPartNumber",
    heading: i18n("columns.VendorPartNumber"),
    sortable: true,
    privilegeRestriction: [
      USER_PRIVILEGE.editItems,
      USER_PRIVILEGE.viewVendors,
    ],
  },
  {
    name: "bin",
    heading: i18n("columns.Bin"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "tags",
    heading: i18n("columns.Tags"),
    sortable: true,
  },
  {
    name: "sku",
    heading: i18n("columns.Sku"),
    sortable: true,
  },
  {
    name: "url",
    heading: i18n("columns.Url"),
    sortable: true,
  },
  {
    name: "barcode",
    heading: i18n("columns.Barcode"),
    sortable: true,
  },
  {
    name: "hasImage",
    heading: i18n("columns.Image"),
    formatFunc: formatImage,
  },
  {
    name: "volume",
    heading: i18n("global.Volume"),
    formatFunc: formatItemQtyByItemType,
    sortable: true,
    align: "right",
  },
  {
    name: "weight",
    heading: i18n("global.Weight"),
    formatFunc: formatItemQtyByItemType,
    align: "right",
    sortable: true,
  },
  {
    name: "costBasis",
    heading: i18n("columns.CostBasis"),
    formatFunc: formatMoneyForAccounting,
    align: "right",
    sortable: true,
    privilegeRestriction: [USER_PRIVILEGE.editItems, USER_PRIVILEGE.viewCosts],
  },
  {
    name: "purchaseCost",
    formatFunc: formatMoneyWithoutAdornments,
    heading: i18n("columns.PurchasePrice"),
    align: "right",
    sortable: true,
    privilegeRestriction: USER_PRIVILEGE.viewCosts,
  },
  {
    name: "reorderPoint",
    heading: i18n("columns.ReorderPoint"),
    formatFunc: formatItemQtyByItemType,
    align: "right",
    sortable: true,
  },
  {
    name: "available",
    heading: i18n("columns.Available"),
    formatFunc: formatItemAvailabilityByItemType,
    align: "right",
    sortable: true,
  },
  {
    name: "onSO",
    formatFunc: [formatItemQtyByItemType, formatInteractiveInventoryColumn],
    heading: i18n("columns.OnSO"),
    align: "right",
    inventoryType: "onSo",
    sortable: true,
  },
  {
    name: "onRMA",
    formatFunc: [formatItemQtyByItemType, formatInteractiveInventoryColumn],
    heading: i18n("columns.OnRMA"),
    align: "right",
    inventoryType: "onRma",
    sortable: true,
  },
  {
    name: "baseUOM",
    formatFunc: extractName,
    heading: i18n("columns.BaseUOM"),
    planRestriction: [PLAN_PLUS, PLAN_PRO],
  },
  {
    name: "onPO",
    formatFunc: [formatItemQtyByItemType, formatInteractiveInventoryColumn],
    heading: i18n("columns.OnPO"),
    align: "right",
    inventoryType: "onPo",
    sortable: true,
  },
  {
    name: "onWO",
    formatFunc: [formatItemQtyByItemType, formatInteractiveInventoryColumn],
    heading: i18n("columns.OnWO"),
    align: "right",
    inventoryType: "onWo",
    sortable: true,
  },
  {
    name: "rented",
    heading: i18n("columns.OnRental"),
    formatFunc: [formatItemQtyByItemType, formatInteractiveInventoryColumn],
    align: "right",
    inventoryType: "onRental",
    sortable: true,
  },
  {
    name: "onSR",
    heading: i18n("columns.OnSR"),
    formatFunc: [formatItemQtyByItemType, formatInteractiveInventoryColumn],
    align: "right",
    inventoryType: "onSr",
    sortable: true,
  },
  {
    name: "notes",
    heading: i18n("columns.Notes"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
  },
  {
    name: "sync",
    fieldName: "syncMessage",
    heading: i18n("columns.Sync"),
    sortable: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
  },
  {
    name: "minimumPrice",
    heading: i18n("columns.MinimumSalesPrice"),
    formatFunc: formatMoneyWithoutAdornments,
  },
  {
    name: "serialTracking",
    heading: i18n("columns.SerialTracked"),
    formatFunc: formatBooleanYesOrBlank,
  },
  {
    name: "lotTracking",
    heading: i18n("columns.LotTracked"),
    formatFunc: formatBooleanYesOrBlank,
  },
  {
    name: "incomeAccount",
    heading: i18n("columns.IncomeAccount"),
    formatFunc: extractName,
  },
  {
    name: "cogsAccount",
    heading: i18n("columns.CogsAccount"),
    formatFunc: extractName,
  },
  {
    name: "assetAccount",
    heading: i18n("columns.AssetAccount"),
    formatFunc: extractName,
  },
  {
    name: "expenseAccount",
    heading: i18n("columns.ExpenseAccount"),
    formatFunc: extractName,
  },
  {
    name: "customerPartNumber",
    heading: i18n("columns.CustomerPartNumber"),
  },
  {
    name: "willSync",
    heading: i18n("columns.SyncWithQuickBooks"),
    formatFunc: formatBooleanYesOrBlank,
  },
  {
    name: "updateShopify",
    heading: i18n("columns.UpdateShopify"),
    formatFunc: formatBooleanYesOrBlank,
  },
  {
    name: "updateBigCommerce",
    heading: i18n("columns.UpdateBigCommerce"),
    formatFunc: formatBooleanYesOrBlank,
  },
  {
    name: "taxable",
    heading: i18n("columns.Taxable"),
    formatFunc: formatBooleanYesOrBlank,
  },
  {
    name: "commissionExempt",
    heading: i18n("columns.ComissionExempt"),
    formatFunc: formatBooleanYesOrBlank,
  },
  {
    name: "commissionRate",
    heading: i18n("columns.PercentCommision"),
    formatFunc: formatPercentWithAdornments,
  },
  {
    name: "commissionAmount",
    heading: i18n("columns.PerUnitCommission"),
    formatFunc: formatMoneyWithoutAdornments,
  },
  {
    name: "leadTime",
    heading: i18n("columns.LeadTime"),
    formatFunc: formatDecimal,
  },
  {
    name: "showOnSalesForms",
    heading: i18n("columns.ShowOnSales"),
    formatFunc: formatBooleanYesOrBlank,
  },
  {
    name: "showOnPurchasingForms",
    heading: i18n("columns.ShowOnPurchasing"),
    formatFunc: formatBooleanYesOrBlank,
  },
  {
    name: "showOnProductionForms",
    heading: i18n("columns.ShowOnProduction"),
    formatFunc: formatBooleanYesOrBlank,
  },
];

export function getEmptyRecord(settings) {
  return {
    alwaysShippable: true,
    archived: false,
    assetAccount: settings.defaultAssetAccount,
    available: null,
    barcode: null,
    baseSalesPrice: new Money(0),
    bin: null,
    category: null,
    class: null,
    cogsAccount: settings.defaultCOGSAccount,
    commissionExempt: false,
    commissionRate: new Decimal(0),
    commissionAmount: new Money(0),
    costBasis: new Money(0),
    customFields: null,
    customerPartNumber: null,
    description: null,
    documents: [],
    expenseAccount: settings.defaultExpenseAccount,
    fullname: null,
    hasImage: false,
    hasVariants: false,
    imageAsBase64String: null,
    imageChanged: false,
    incomeAccount: settings.defaultIncomeAccount,
    keys: null,
    leadTime: null,
    lotTracking: false,
    markupPercent: null,
    maxStock: null,
    minimumPrice: null,
    name: null,
    notes: null,
    onPO: 0,
    onRMA: 0,
    onSO: 4,
    onSR: 0,
    onWO: 0,
    onhand: 0,
    priceTiers: [],
    basePurchaseCost: new Money(0),
    purchaseDescription: null,
    purchaseTaxCode: null,
    rented: 0,
    reorderPoint: null,
    salesTaxCode: settings.defaultTaxCode,
    serialTracking: false,
    showOnProductionForms: true,
    showOnPurchasingForms: true,
    showOnSalesForms: true,
    sku: null,
    starred: 0,
    sublevel: 0,
    summaryOnly: false,
    syncToken: 0,
    tags: [],
    taxable: false,
    type: DEFAULT_ITEM_TYPE,
    uoms: [],
    updateBigCommerce: true,
    updateShopify: true,
    url: null,
    useMarkup: false,
    values: null,
    variantMaster: null,
    vendor: null,
    vendorPartNumber: null,
    volume: 0,
    volumeUnit: null,
    warranty: null,
    weight: 0,
    weightUnit: null,
    willSync: true,
    initialQuantity: new Decimal(0),
    initialQuantityDate: (() => new Date())(),
    initialLocation: null,
    initialValue: new Money(0),
  };
}

export const INITIAL_INVENTORY_FIELDS = [
  "initialQuantity",
  "initialQuantityDate",
  "initialLocation",
  "initialValue",
];

export const UOM_FIELDS_TO_TRANSFORM = {
  MONEY_FIELDS: ["purchaseCost", "salesPrice"],
  DECIMAL_FIELDS: ["conversion"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [
    "onhand",
    "available",
    "onSO",
    "onSR",
    "rented",
    "onWO",
    "onPO",
    "onRMA",
    "reorderPoint",
    "maxStock",
    "leadTime",
    "weight",
    "volume",
    "commissionRate",
    "markupPercent",
  ],
  MONEY_FIELDS: [
    "salesPrice",
    "minimumPrice",
    "costBasis",
    "purchaseCost",
    "basePurchaseCost",
    "baseSalesPrice",
    "commissionAmount",
  ],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["lastSync"],
  NON_UTC_DATETIME_FIELDS: [],
  HTML_ENCODED_FIELDS: ["name"],
};

export const REFERENCE_FIELDS = ["category", "cogsAccount"];

export const LINE_ACTIONS = [
  "pdf",
  "copy",
  "delete",
  "archive",
  "variants",
  ["history", "rentalHistory"],
  "addToReorderList",
  "billOfMaterials",
  ["locationSettings", "uom"],
  "boxes",
  "clearCache",
  "sync",
];
export const BATCH_ACTIONS = [
  "pdf",
  "delete",
  "archive",
  "addToReorderList",
  "sync",
];
