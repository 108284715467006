import { useSelector } from "react-redux";

import { LineItemAutocompleteField } from "components/formFields/LineItemAutocompleteField";
import { LineItemErrorText } from "components/formFields/LineItemErrorText";
import { LineSubItemField } from "components/formFields/line/LineSubItemField";

import { i18n } from "services/i18nService";

import { usePlans } from "hooks/usePlans";

import { ITEM_TYPES } from "appConstants";

export function LineWorker(props) {
  const { itemSelected, value, workers, onValueChange, itemType, showWorkers } =
    props;

  const { hasAtLeastProPlan } = usePlans();
  const trackWorkers = useSelector(
    (state) => state.userCompanySettings.settings.trackWorkers
  );
  const isLaborItem = itemType === ITEM_TYPES.LABOR;

  if (!isLaborItem || !showWorkers || !hasAtLeastProPlan || !trackWorkers) {
    return null;
  }

  if (itemSelected && Array.isArray(workers) && !workers.length) {
    return <LineItemErrorText value={i18n("worker.NoWorkers")} />;
  }

  function getValue() {
    if (value && value.id && workers) {
      return workers.find(({ id }) => value.id === id);
    }
    if (value && value.id) {
      return value;
    }
    return null;
  }

  return (
    <LineSubItemField label={i18n("columns.colon.Worker")}>
      <div style={{ flex: "1 1 auto" }}>
        <LineItemAutocompleteField
          onValueChange={onValueChange}
          value={getValue()}
          name="worker"
          options={workers}
        />
      </div>
    </LineSubItemField>
  );
}
