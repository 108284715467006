import { FrmCheckboxWithHoverHelp } from "components/formFields/frm/FrmCheckboxWithHoverHelp";

import { i18n } from "services/i18nService";

import { usePlans } from "hooks/usePlans";

export function FrmAutoSerialLots(props) {
  const { value, onValueChange, name } = props;

  const { hasAtLeastPlusPlan } = usePlans();

  if (!hasAtLeastPlusPlan) {
    return null;
  }

  return (
    <FrmCheckboxWithHoverHelp
      name={name}
      value={value}
      onValueChange={onValueChange}
      label={i18n("frmLabel.AutoSerialLots")}
      helpText={i18n("hoverHelp.autoSerialLots")}
    />
  );
}
