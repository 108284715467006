import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import {
  formatMoneyWithoutAdornments,
  formatDecimal,
  extractName,
  formatTypeStringIntoLabel,
} from "services/utility/formatting";
import {
  getReportDecimalTotal,
  getReportMoneyTotal,
  formatAccountingExceptionMoney,
} from "services/utility/reports";

import {
  SUB_COMPONENT,
  ALL_OPTION_WITH_ALL_ID,
} from "views/Reports/reportConstants";

const BILL_PAYMENT_TYPE_OPTION = {
  id: "Bill",
  name: i18n("reports.FilterLabel.Bills"),
};

const PAYMENT_TYPE_OPTIONS = [
  ALL_OPTION_WITH_ALL_ID,
  BILL_PAYMENT_TYPE_OPTION,
  { id: "Cash", name: i18n("reports.FilterLabel.CashPurchases") },
  { id: "Check", name: i18n("reports.FilterLabel.Check") },
  {
    id: "CreditCard",
    name: i18n("reports.FilterLabel.CreditCardCharges"),
  },
];

const type = i18n("notes.matchingTranasction");

export const ReconcileItemReceipts = {
  userHasSufficientPrivilege: (p) =>
    p.sync && p.receiveInventory && p.viewCosts,
  planIncludesReport: () => true,
  customFieldObjectTypes: [],
  isJsonReport: true,
  reportAction: { id: "AddToSync", label: i18n("reports.AddToSync") },
  notes: [
    i18n("notes.YellowMeansNoMatchingInSOS", { type }),
    i18n("notes.BlueMeansNotSentToQbo", { type }),
    i18n("notes.GreenMeansNoMatchingInQbo", { type }),
    i18n("notes.RedMeansCostDoesNotMatch"),
  ],
  columns: [
    {
      apiName: "Date",
      jsonName: "date",
      formatFunc: formatDate,
      default: true,
    },
    {
      apiName: "Txn Type",
      jsonName: "txnType",
      formatFunc: formatTypeStringIntoLabel,
      default: true,
    },
    {
      apiName: "RefNumber",
      jsonName: "refNumber",
      label: "Ref #",
      default: true,
    },
    {
      apiName: "Vendor",
      jsonName: "vendor",
      formatFunc: extractName,
      default: true,
    },
    {
      apiName: "Item",
      jsonName: "item",
      formatFunc: extractName,
      default: true,
    },
    {
      apiName: "Qty",
      jsonName: "quantity",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      showTotal: true,
      default: true,
      number: true,
    },
    { apiName: "Linked Txn", jsonName: "linkedTxnRefNumber", default: true },
    {
      apiName: "SOS Value",
      jsonName: "sosValue",
      formatFunc: formatMoneyWithoutAdornments,
      footerFunc: getReportMoneyTotal,
      default: true,
      number: true,
    },
    {
      apiName: "Acct Value",
      jsonName: "acctValue",
      formatFunc: formatAccountingExceptionMoney,
      footerFunc: getReportMoneyTotal,
      isExceptionColumn: true,
      default: true,
      number: true,
    },
    {
      apiName: "Diff",
      jsonName: "diff",
      formatFunc: formatAccountingExceptionMoney,
      footerFunc: getReportMoneyTotal,
      isExceptionColumn: true,
      default: true,
      number: true,
    },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      defaultValue: () => new Date(),
      datePrefix: "startOf",
      type: "filter",
    },
    {
      apiName: "date1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "txnType",
      defaultValue: BILL_PAYMENT_TYPE_OPTION,
      label: i18n("reports.FilterLabel.PaymentType"),
      component: "FrmSelectFromObjects",
      options: PAYMENT_TYPE_OPTIONS,
      type: "filter",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
