import { useState, useEffect } from "react";

import { FilterList } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";

import { DateFilter } from "components/ListPage/List/Row/History/DateFilter";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmSelectScalar } from "components/formFields/FrmSelectScalar";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";

import { useLocations } from "hooks/useLocations";

import { ITEM_HISTORY_DEFAULT_FILTERS, ALL_FILTER_OPTION } from "appConstants";
import { LIST_MAX_RESULTS_OPTIONS } from "appConstants";

export function Filters(props) {
  const { retrievalParams, setRetrievalParams, typeOptions } = props;
  const [open, setOpen] = useState(false);
  const [dateError, setDateError] = useState({ to: null, from: null });
  const [dateFilter, setDateFilter] = useState("");
  const [filters, setFilters] = useState(retrievalParams);
  const locations = useLocations();
  const { maxResults, fromLocation, toLocation, toDate, fromDate, type } =
    filters;

  useEffect(() => setFilters(retrievalParams), [retrievalParams, setFilters]);

  function handleChange(name, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      [name]: value,
      startingRecord: 1,
    }));
  }

  function handleClose(isSaved = false) {
    setOpen(false);
    isSaved && setFilters(retrievalParams);
  }

  const locationOptions = locations
    ? [ALL_FILTER_OPTION, ...locations]
    : locations;

  return (
    <>
      <Button data-testing="filterButton" onClick={() => setOpen(true)}>
        <FilterList sx={{ marginRight: "0.5rem" }} />
        {i18n("filter.label")}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <DialogContent data-testing="filters">
            <VSpace space={2} />
            <div style={{ display: "grid" }}>
              <FrmSelectScalar
                name="maxResults"
                options={LIST_MAX_RESULTS_OPTIONS}
                onValueChange={handleChange}
                value={maxResults}
                label={i18n("filter.ResultsPerPage")}
                disableClearable
              />
            </div>
            <div style={{ display: "grid" }}>
              <FrmSelectFromObjects
                name="type"
                options={typeOptions}
                onValueChange={handleChange}
                value={type}
                label={i18n("filter.Type")}
                disableClearable
              />
            </div>
            <div style={{ display: "grid" }}>
              <FrmSelectFromObjects
                name="toLocation"
                options={locationOptions}
                onValueChange={handleChange}
                value={toLocation}
                label={i18n("filter.ToLocation")}
                disableClearable
              />
            </div>
            <div style={{ display: "grid" }}>
              <FrmSelectFromObjects
                name="fromLocation"
                options={locationOptions}
                onValueChange={handleChange}
                value={fromLocation}
                label={i18n("filter.FromLocation")}
                disableClearable
              />
            </div>
            <div style={{ display: "grid" }}>
              <DateFilter
                toDate={toDate}
                fromDate={fromDate}
                setFilters={setFilters}
                setDateError={setDateError}
                setDateFilter={setDateFilter}
                dateFilter={dateFilter}
              />
            </div>
          </DialogContent>
        </div>

        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
          data-testing="dialogActions"
        >
          <div>
            <Button
              color="secondary"
              onClick={() => setFilters(ITEM_HISTORY_DEFAULT_FILTERS)}
              data-testing="clear"
            >
              {i18n("button.Clear")}
            </Button>
          </div>
          <div style={{ display: "flex" }}>
            <Button
              color="secondary"
              onClick={handleClose}
              data-testing="filter-cancel"
            >
              {i18n("button.Cancel")}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setRetrievalParams(filters);
                handleClose(true);
              }}
              disabled={dateError.to || dateError.from}
              data-testing="ok"
            >
              {i18n("button.Apply")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
