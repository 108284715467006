import { i18n } from "services/i18nService";
import { getDocument } from "services/sosInventoryService/sosApi";
import { formatDate } from "services/utility/dates";
import {
  formatMoney,
  formatDocumentInfo,
  formatArray,
  formatSync,
} from "services/utility/formatting";

export const journalEntryConfig = {
  columns: [
    {
      heading: i18n("columns.Date"),
      apiName: "date",
      sortable: true,
      defaultSort: true,
      sortDirection: "desc",
      formatFunc: formatDate,
    },
    {
      heading: i18n("columns.ReferenceNumber"),
      apiName: "refNumber",
      sortable: true,
    },
    {
      heading: i18n("columns.Amount"),
      apiName: "amount",
      sortable: true,
      formatFunc: formatMoney,
      align: "right",
    },
    {
      heading: i18n("columns.Debits"),
      apiName: "debits",
      formatFunc: formatArray,
      sortable: true,
    },
    {
      heading: i18n("columns.Credits"),
      apiName: "credits",
      formatFunc: formatArray,
      sortable: true,
    },
    {
      heading: i18n("columns.Memo"),
      apiName: "memo",
      sortable: true,
    },
    {
      heading: i18n("columns.Sync"),
      apiName: "syncMessage",
      formatFunc: formatSync,
      sortable: true,
    },
    {
      heading: i18n("columns.Documents"),
      apiName: "documents",
      formatFunc: formatDocumentInfo,
      apiFunc: getDocument,
      isDocument: true,
      maxWidth: "12rem",
    },
  ],
};
