import { i18n } from "services/i18nService";

import { NONE_GROUP_BY_OPTION } from "views/Reports/reportConstants";

import { ITEM_QUICKLIST_CASES, OBJECT_TYPES } from "appConstants";

const GROUP_BY_OPTIONS = [
  NONE_GROUP_BY_OPTION,
  { id: "Item", name: i18n("objectType.item.Sentence") },
];

export const LotHistory = {
  userHasSufficientPrivilege: (p) =>
    p.viewInventory || p.viewItems || p.editItems,
  planIncludesReport: (plus) => plus,
  customReportNameFetchList: [{ objectType: OBJECT_TYPES.ITEM.fullString }],
  customFieldObjectTypes: ["lot", "item"],
  columns: [
    { apiName: "Lot", default: true },
    { apiName: "Item", default: true },
    { apiName: "Description" },
    { apiName: "Item Description" },
    { apiName: "Expiration" },
    { apiName: "Recalled" },
    { apiName: "SKU" },
  ],
  settings: [
    {
      label: i18n("filter.Item"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
      itemType: ITEM_QUICKLIST_CASES.LOT,
    },
    {
      label: i18n("reports.FilterLabel.LotNumberContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: GROUP_BY_OPTIONS,
      type: "option",
    },
    {
      defaultValue: "",
      apiName: "header",
      component: "FrmTextField",
      label: i18n("global.Header"),
      type: "option",
    },
    {
      defaultValue: "",
      apiName: "footer",
      component: "FrmTextField",
      label: i18n("global.Footer"),
      type: "option",
    },
  ],
};
