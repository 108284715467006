import { inventoryTransactionBeforeSave } from "services/sosInventoryService/inventoryTransaction/beforeSave";
import { convertNativeToCustom } from "services/utility/customFields";

import { OBJECT_TYPES } from "appConstants";

export function beforeSave(record) {
  const newRecord = inventoryTransactionBeforeSave(
    OBJECT_TYPES.TRANSFER.fullString,
    record
  );

  newRecord.customFields = convertNativeToCustom(record.customFields);

  return newRecord;
}
