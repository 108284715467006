export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixPurchaseOrder: record.purchaseOrderNumber.prefix,
    nextPONumber: record.purchaseOrderNumber.nextNumber,
  };

  delete newRecord.purchaseOrderNumber;

  return newRecord;
}
