import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function FrmDepartment(props) {
  const { value, onValueChange, maxWidth } = props;
  const departments = useRelated("department", "name");

  if (!departments) {
    return null;
  }

  return (
    <FrmSelectFromObjects
      options={departments}
      fullWidth
      name="department"
      label={i18n("frmLabel.Department")}
      onValueChange={onValueChange}
      value={value}
      maxWidth={maxWidth}
    />
  );
}
