import { productionTransactionBeforeSave } from "services/sosInventoryService/productionTransaction/beforeSave";
import { convertNativeToCustom } from "services/utility/customFields";

import { OBJECT_TYPES } from "appConstants";

export function beforeSave(record) {
  const newRecord = productionTransactionBeforeSave(
    OBJECT_TYPES.WORK_ORDER.fullString,
    record
  );

  newRecord.customFields = convertNativeToCustom(record.customFields);

  return newRecord;
}
