import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

import { OBJECT_TYPES } from "appConstants";

export function FrmCustomField(props) {
  const { value, onValueChange, width, error } = props;

  const customFields = useRelated(OBJECT_TYPES.CUSTOM_FIELD.fullString);

  return (
    <FrmSelectFromObjects
      disableClearable
      name="customField"
      options={customFields}
      value={value}
      label={i18n("frmLabel.Field")}
      onValueChange={onValueChange}
      sx={{ width, maxWidth: "100%" }}
      error={error}
    />
  );
}
