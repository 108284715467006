import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function FrmJob(props) {
  const { value, onValueChange, maxWidth, error } = props;
  const [showSelect, setShowSelect] = useState(value);

  const jobEnabled = useSelector(
    (state) => state.userCompanySettings.settings.jobEnabled
  );
  useEffect(() => {
    if (value) {
      // only show job select if value exists
      setShowSelect(true);
    }
  }, [value]);

  const jobs = useRelated("job");

  return showSelect && jobEnabled ? (
    <FrmSelectFromObjects
      name="job"
      label={i18n("frmLabel.Job")}
      value={value}
      onValueChange={onValueChange}
      options={jobs}
      maxWidth={maxWidth}
      error={error}
    />
  ) : null;
}
