// Returns two boolean values, indicating whether Plus features and
// Pro features are active
import { useSelector } from "react-redux";

export function usePlans() {
  const hasAtLeastPlusPlan = useSelector(
    (state) => state.userCompanySettings.settings.allowPlusFeatures
  );
  const hasAtLeastProPlan = useSelector(
    (state) => state.userCompanySettings.settings.allowProFeatures
  );

  return { hasAtLeastPlusPlan, hasAtLeastProPlan };
}
