import {
  FIELDS_TO_TRANSFORM,
  LINE_FIELDS_TO_TRANSFORM,
} from "services/sosInventoryService/document/schema";
import { transformFields } from "services/utility/afterGet";
import { formatDate } from "services/utility/dates";

export function getLineName(line) {
  const { refNumber, displayName, date } = line;
  if (refNumber) {
    const dateString = date ? formatDate(date) : "";
    const contactString = displayName ? `${displayName} ` : "";
    return `${refNumber} (${contactString}${dateString})`;
  }
  return displayName;
}

export function afterGet(record) {
  const newRecord = transformFields(record, FIELDS_TO_TRANSFORM);

  newRecord.lines = record.lines.map((line) =>
    transformFields(line, LINE_FIELDS_TO_TRANSFORM)
  );

  newRecord.lines = newRecord.lines.map((line) => ({
    ...line,
    name: getLineName(line),
  }));

  return newRecord;
}
