import { Box, Typography } from "@mui/material";

import { FrmTextField } from "components/formFields/FrmTextField";
import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";

export function FrmDeposit(props) {
  const {
    onValueChange,
    amountValue,
    percentValue,
    amountError,
    percentError,
    maxWidth,
  } = props;

  return (
    <Box display="flex" alignItems="center" sx={{ maxWidth: maxWidth || "" }}>
      <Box width="50%">
        <FrmTextField
          money
          name="depositAmount"
          label={i18n("frmLabel.DepositAmount")}
          onValueBlur={onValueChange}
          value={amountValue}
          error={amountError}
        />
      </Box>
      <Box>
        <Typography>&nbsp;{i18n("global.or")}&nbsp;</Typography>
      </Box>
      <Box width="50%" display="flex" alignItems="center">
        <FrmTextField
          number
          name="depositPercent"
          label={i18n("frmLabel.DepositPercent")}
          onValueBlur={onValueChange}
          value={percentValue}
          error={percentError}
        />
        <HoverHelp title={i18n("hoverHelp.deposit")} sx={{ marginLeft: 0.5 }} />
      </Box>
    </Box>
  );
}
