import SvgIcon from "@mui/material/SvgIcon";

import { theme } from "SosTheme";

export function SalesIcon(props) {
  const { highlighted, ...otherProps } = props;
  const color = highlighted ? theme.palette.accent : theme.palette.primaryText;

  return (
    <SvgIcon viewBox="0 0 32 32" fill={color} {...otherProps}>
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g fillRule="nonzero">
          <path d="M12.9799896,0.1565104 C12.8378378,0.0130208 12.6265593,-0.0367187333 12.4355509,0.0278646 L3.71543657,2.9403646 L1.01299373,0.232031267 C0.804054087,0.0299479333 0.472193331,0.0328125333 0.266891909,0.238541667 C0.06159042,0.4442708 0.0587317755,0.777083333 0.260394977,0.986197933 L2.9623181,3.69453127 L0.0558731975,12.4325521 C-0.0075363659,12.6242187 0.0420997588,12.8354167 0.184771326,12.9783854 L19.0015593,31.8348959 C19.2094595,32.0429687 19.5462578,32.0429687 19.754158,31.8348959 L31.7972973,19.7664063 C32.0051975,19.5583333 32.0051975,19.2205729 31.7972973,19.0125 L12.9799896,0.1565104 Z M19.3778587,30.7036459 L1.16891889,12.4572917 L3.80327444,4.5377604 L5.86668401,6.60546873 C5.11356547,7.74739587 5.3453742,9.27578127 6.40358626,10.1414063 C7.46179832,11.0072917 9.00233886,10.9296875 9.96855511,9.96145833 C10.9345114,8.9934896 11.0119543,7.4497396 10.1481289,6.38932293 C9.28404364,5.32890627 7.75909562,5.09635413 6.61928276,5.85130207 L4.5569127,3.78359373 L12.4599792,1.14244793 L30.6683992,19.3877604 L19.3778587,30.7036459 Z M7.71153843,8.4528646 C7.92021828,8.65494793 8.25233889,8.65208333 8.45764032,8.44635413 C8.6629418,8.240625 8.66580038,7.90807293 8.46413718,7.69869793 L7.4043659,6.63593747 C8.0119543,6.33906253 8.74090438,6.4627604 9.21725572,6.9434896 C9.7993763,7.5182292 9.85680872,8.43984373 9.35031185,9.08255207 C8.84381499,9.725 7.93581079,9.88281253 7.24298338,9.44817707 C6.55041583,9.01354167 6.2952183,8.12630207 6.65072765,7.38906253 L7.71153843,8.4528646 Z"></path>
        </g>
      </g>
    </SvgIcon>
  );
}
