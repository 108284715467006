import { i18n } from "services/i18nService";
import {
  isSafeUrl,
  isOneOrMoreEmailAddresses,
} from "services/utility/validation";

import { initialErrorState } from "views/common";

export function isValid(record) {
  const errors = initialErrorState();

  // require name
  if (!record.name || !record.name.length) {
    errors.messages.push(i18n("vendor.LineItemError.Name"));
    errors.entity.push("name");
  }

  // validate email
  if (record.email && !isOneOrMoreEmailAddresses(record.email)) {
    errors.messages.push(i18n("vendor.Error.Email"));
    errors.entity.push("email");
  }

  // be sure nothing devious in URL

  if (record.website && !isSafeUrl(record.website)) {
    errors.messages.push(i18n("vendor.Error.Website"));
    errors.entity.push("website");
  }

  return errors;
}
