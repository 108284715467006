export function afterGet(record) {
  const newRecord = {
    ...record,
    salesReceiptNumber: {
      prefix: record.prefixSalesReceipt,
      nextNumber: record.nextSalesReceiptNumber,
    },
  };

  delete newRecord.prefixSalesReceipt;
  delete newRecord.nextSalesReceiptNumber;

  return newRecord;
}
