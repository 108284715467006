import { useEffect, useState } from "react";

import { getCustomerData } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

import {
  getObjectFromTypeString,
  CUSTOMER_DATA_RESULTS_WITH_SINGLE_TXN,
} from "appConstants";

export function useCustomerData(customer, objectType) {
  const [records, setRecords] = useState(null);

  useEffect(() => {
    async function fetchCustomerData() {
      try {
        const openTransactions = await getCustomerData(objectType, customer.id);
        const transactionInfo = openTransactions.map(
          ({ txnType, txnId, refNumber }) => {
            const { fullString, shortString } =
              getObjectFromTypeString(txnType);

            // include txn shortString for name value when the data may include
            // multiple txn types so that we can differetiate between them
            const name = CUSTOMER_DATA_RESULTS_WITH_SINGLE_TXN.includes(
              objectType
            )
              ? refNumber || ""
              : `${shortString}${refNumber ? ` ${refNumber}` : ""}`;
            return {
              txnId,
              objectType: fullString,
              id: `${fullString}|${txnId}`,
              name,
              number: refNumber,
            };
          }
        );
        setRecords(transactionInfo);
      } catch (e) {
        handleProgramError(e);
      }
    }

    if (customer && customer.id) {
      setRecords(null);
      fetchCustomerData();
    } else {
      setRecords([]);
    }
  }, [customer, objectType]);

  return records;
}
