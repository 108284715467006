import { convertCustomToNative } from "services/utility/customFields";

export function afterGet(record) {
  let newRecord = { ...record };

  if (record.customFields) {
    newRecord.customFields = convertCustomToNative(record.customFields);
  }

  return newRecord;
}
