export const SETTINGS = [
  {
    apiName: "rentalNumber",
    component: "FrmTransactionNumber",
    transactionType: "rental",
    hasInputLabel: true,
  },
  { apiName: "rentalHeaderText", component: "FrmTextField", maxLength: 50 },
  { apiName: "rentalFooter", component: "FrmResizeTextField" },
  { apiName: "replyEmailRental", component: "FrmTextField", maxLength: 255 },
  { apiName: "rentalEmailText", component: "FrmResizeTextField" },
];
