import { i18n } from "services/i18nService";
import {
  formatMoneyUnrounded,
  INCLUDE_ADORNMENTS,
} from "services/utility/formatting";

import { OBJECT_TYPES, getObjectFromTypeString } from "appConstants";

function paymentLineDescription(type, refNumber, description) {
  // some payment line items don't have a type; use description
  if (!type) {
    return description;
  } else {
    const { fullString } = getObjectFromTypeString(type);
    const localizedTransType = i18n(`objectType.${fullString}.Sentence`);
    return `${localizedTransType} ${refNumber}`;
  }
}

export function HistoryLineItems(props) {
  const { transactionType, lines } = props;

  if (transactionType === OBJECT_TYPES.PAYMENT.fullString) {
    return (
      <>
        {lines.map(({ type, refNumber, amount, description }, i) => (
          <div style={{ display: "flex" }} key={i}>
            <div style={{ width: "12em" }}>
              {paymentLineDescription(type, refNumber, description)}
            </div>
            <div style={{ width: "10em", textAlign: "right" }}>
              {formatMoneyUnrounded(amount, INCLUDE_ADORNMENTS)}
            </div>
          </div>
        ))}
      </>
    );
  } else {
    return (
      <>
        {lines.map(({ itemName, quantity, unitprice }, i) => (
          <div style={{ display: "flex" }} key={i}>
            <div style={{ width: "12em" }}>{`${itemName} ...`}</div>
            <div
              style={{ width: "10em", textAlign: "right" }}
            >{`${quantity} x ${formatMoneyUnrounded(
              unitprice,
              INCLUDE_ADORNMENTS
            )}`}</div>
          </div>
        ))}
      </>
    );
  }
}
