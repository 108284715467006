import { useState, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Delete, PlaylistAdd } from "@mui/icons-material";
import { TableRow, TableCell, Checkbox, IconButton } from "@mui/material";

import { NEW_RECORD_SKELETON } from "appConfig";

import { DeleteDialog } from "components/DeleteDialog";
import { Tooltip } from "components/Tooltip";
import { LineCell } from "components/formFields/LineCell";

import { i18n } from "services/i18nService";

import { openModal } from "globalState/modalSlice";

import { theme } from "SosTheme";
import { OBJECT_TYPES } from "appConstants";

function formattedContents(column, row) {
  const { fieldName, name, formatFunc } = column;
  const value = row[fieldName || name];
  if (!formatFunc) {
    return value;
  }
  if (Array.isArray(formatFunc)) {
    return formatFunc.reduce((seed, fn) => fn(seed, row, column), value);
  }
  return formatFunc(value, row, column);
}

function Row_(props) {
  const { objectType, columns, row, selected, setCheckedIds } = props;

  const dispatch = useDispatch();
  const [showDelete, setShowDelete] = useState(false);

  const userId = useSelector(
    (state) => state.userCompanySettings.company.userId
  );

  function newTask() {
    const emptyRecord = NEW_RECORD_SKELETON.task();
    const record = {
      ...emptyRecord,
      description: row.message,
      assignedToUser: { id: +userId },
      deleteNotification: true,
      notificationId: row.id,
    };
    dispatch(
      openModal({
        objectType: OBJECT_TYPES.TASK.fullString,
        modalProps: { record },
      })
    );
  }

  function handleCheckChange(e) {
    const { checked } = e.target;
    setCheckedIds((prev) =>
      checked ? [...prev, row.id] : prev.filter((el) => el !== row.id)
    );
  }

  return (
    <>
      <TableRow>
        <TableCell
          sx={{ verticalAlign: "middle", padding: 0, paddingLeft: "1rem" }}
        >
          <Checkbox checked={selected} onChange={handleCheckChange} />
        </TableCell>
        <TableCell
          sx={{ verticalAlign: "middle", textAlign: "right", paddingLeft: 0 }}
        >
          <Tooltip title={i18n("tooltip.CreateTask")}>
            <IconButton
              onClick={newTask}
              sx={{ marginLeft: "1rem", marginRight: "1rem" }}
              size="small"
            >
              <PlaylistAdd sx={{ color: "selectIcon" }} />
            </IconButton>
          </Tooltip>
        </TableCell>
        {columns.map((column, i) => (
          <Cell key={i} columnIndex={i} row={row} column={column} />
        ))}
        <TableCell
          sx={{ verticalAlign: "middle", textAlign: "right", paddingLeft: 0 }}
        >
          <IconButton
            onClick={() => setShowDelete(true)}
            sx={{ marginLeft: "1rem", marginRight: "1rem" }}
            size="small"
          >
            <Delete sx={{ color: "selectIcon" }} />
          </IconButton>
        </TableCell>
      </TableRow>
      {showDelete && (
        <DeleteDialog
          open={showDelete}
          objectType={objectType}
          objectIds={[row.id]}
          message={i18n("global.deleteRecordMessage", {
            objectType: i18n(`objectType.${objectType}.Lower`),
            identifier: row.message,
          })}
          onClose={() => setShowDelete(false)}
        />
      )}
    </>
  );
}

export const Row = memo(Row_);

function Cell(props) {
  const { row, column, columnIndex } = props;
  return (
    <LineCell column={column} columnIndex={columnIndex}>
      <div style={theme.typography.body1}>{formattedContents(column, row)}</div>
    </LineCell>
  );
}
