import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { NEW_RECORD_SKELETON } from "appConfig";

import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmSelectFromObjectsWithHoverHelp } from "components/formFields/FrmSelectFromObjectsWithHoverHelp";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmTextFieldWithHoverHelp } from "components/formFields/FrmTextFieldWithHoverHelp";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { getRecord } from "services/sosInventoryService/sosApi";

import { useErrors } from "hooks/useErrors";
import { useRecord } from "hooks/useRecord";
import { useRelated } from "hooks/useRelated";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

export function ShipMethod(props) {
  const { objectType, id, onClose } = props;

  const dispatch = useDispatch();

  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();

  const vendors = useRelated("vendor");

  const loadingState = useSelector((state) => state.loading.editModal);

  useEffect(() => {
    async function _getRecord() {
      if (id) {
        dispatch(editModalLoadingIndicatorOn());
        const response = await getRecord(objectType, id);
        setRecord(response);
        dispatch(editModalLoadingIndicatorOff());
      } else {
        const emptyRecord = NEW_RECORD_SKELETON[objectType]();
        setRecord(emptyRecord);
      }
    }
    _getRecord();
  }, [setRecord, id, objectType, dispatch]);

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={objectType}
        setErrors={setErrors}
        text={record?.name || ""}
        handleClose={onClose}
      />

      {loadingState && <Loading />}

      {record && (
        <ObjectEditDialogContent>
          <Layout
            pageTitle={
              id
                ? `${i18n(`objectType.${objectType}.Sentence`)} ${
                    record?.name || ""
                  }`
                : i18n(`objectType.${objectType}.New`)
            }
          >
            <Box flexGrow="1" position="relative" m={2} mb={0}>
              <FormErrors errors={errors} setErrors={setErrors} />
              {record ? (
                <Box>
                  <FrmFieldGrid gridAutoFlow="dense">
                    <div style={{ gridColumn: "1 / 2", maxWidth: "24rem" }}>
                      <FrmTextField
                        label={i18n("frmLabel.Name")}
                        onValueBlur={handleInputFieldChange}
                        value={record.name}
                        error={isInError("name")}
                        name="name"
                        dataTesting="name"
                      />
                      <FrmTextFieldWithHoverHelp
                        name="carrierCode"
                        label={i18n("frmLabel.CarrierCode")}
                        onValueBlur={handleInputFieldChange}
                        value={record.carrierCode}
                        helpText={i18n("hoverHelp.shipMethod.CarrierCode")}
                      />
                      <FrmSelectFromObjectsWithHoverHelp
                        selectProps={{
                          name: "vendor",
                          label: i18n("frmLabel.Vendor"),
                          value: record.vendor,
                          onValueChange: handleInputFieldChange,
                          options: vendors,
                          sx: { flexGrow: 1 },
                        }}
                        helpText={i18n("hoverHelp.shipMethod.Vendor")}
                      />
                      <FrmTextFieldWithHoverHelp
                        name="shipStationCarrier"
                        label={i18n("frmLabel.ShipStationCarrier")}
                        onValueBlur={handleInputFieldChange}
                        value={record.shipStationCarrier}
                        helpText={i18n(
                          "hoverHelp.shipMethod.ShipStationCarrier"
                        )}
                      />
                      <FrmTextFieldWithHoverHelp
                        name="shipStationService"
                        label={i18n("frmLabel.ShipStationService")}
                        onValueBlur={handleInputFieldChange}
                        value={record.shipStationService}
                        helpText={i18n(
                          "hoverHelp.shipMethod.ShipStationService"
                        )}
                      />
                    </div>
                  </FrmFieldGrid>
                  <VSpace space={1} />
                </Box>
              ) : (
                !isInError("record") && (
                  <FixedLoadingIndicator
                    text={`${i18n("global.Loading")}...`}
                  />
                )
              )}
            </Box>
            <VSpace space={4} />
          </Layout>
        </ObjectEditDialogContent>
      )}
    </>
  );
}
