import { Decimal } from "classes/DecimalClasses";

import { afterTouchLine as baseAfterTouchLine } from "services/utility/afterTouchLine";

// when this field (the key) changes, we should run the (value) list of
// updater functions
const UPDATERS = {
  quantity: [updateTotal],
};

export function afterTouchLine(line, changedField) {
  return baseAfterTouchLine(UPDATERS, line, changedField);
}

function updateTotal(line) {
  const { cost, quantity } = line;
  const total = cost.times(quantity || new Decimal(0));
  return { ...line, total };
}
