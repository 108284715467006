import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Grid,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";

import { SortableColumnHeader } from "components/SortableColumnHeader";
import { TableCell } from "components/TableCell";
import { CloseX } from "components/utility/CloseX";
import { Loading } from "components/utility/Loading";
import { ModalTitle } from "components/utility/ModalTitle";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { getProfitLoss } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";
import { formatMoney, formatNumber } from "services/utility/formatting";
import { isNonZeroDecimalOrMoney } from "services/utility/misc";

export function SalesOrderProfitLoss(props) {
  const { close, objectType, id, identifierText } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [retrievalParams, setRetrievalParams] = useState({
    direction: "asc",
    sort: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    async function _getProfitLoss() {
      try {
        setIsLoading(true);
        const { direction, sort } = retrievalParams;
        const record = await getProfitLoss(objectType, id, {
          direction,
          sort,
        });
        setRecords(record);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        handleProgramError(e);
      }
    }
    _getProfitLoss();
  }, [id, objectType, dispatch, retrievalParams]);

  function handleColumnHeaderClick(sortName) {
    const newDirection =
      sortName === retrievalParams.sort
        ? retrievalParams.direction === "asc"
          ? "desc"
          : "asc"
        : "asc";
    setRetrievalParams((prevParams) => ({
      ...prevParams,
      sort: sortName,
      direction: newDirection,
      start: 1,
    }));
  }

  return (
    <>
      <DialogTitle
        sx={{
          backgroundColor: "dialogTitleBackground",
          borderBottom: "1px solid",
          borderBottomColor: "lineSeparatorMinor",
          padding: "0.5rem 1rem",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={8}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CloseX onClick={close} />
              <ModalTitle
                label={i18n("global.ProfitLoss")}
                text={identifierText}
              />
            </div>
          </Grid>
        </Grid>
      </DialogTitle>
      {isLoading && <Loading />}
      <DialogContent sx={{ paddingTop: 0 }}>
        <VSpace space={2} />
        <TableContainer component={Paper} sx={{ mt: 1, height: "80vh" }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <SortableColumnHeader
                  isSortable
                  sortName="itemName"
                  label={i18n("columns.Item")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  isSortable
                  sortName="quantity"
                  align="right"
                  label={i18n("columns.Ordered")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  isSortable
                  sortName="shipped"
                  align="right"
                  label={i18n("columns.Shipped")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  isSortable
                  sortName="cost"
                  align="right"
                  label={i18n("columns.Cost")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  isSortable
                  sortName="revenue"
                  align="right"
                  label={i18n("columns.Income")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  align="right"
                  label={i18n("columns.NetTotal")}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {records?.map((line, index) => (
                <TableRow key={index}>
                  <TableCell>{line.itemName}</TableCell>
                  <TableCell align="right">
                    {formatNumber(line.quantity)}
                  </TableCell>
                  <TableCell align="right">
                    {formatNumber(line.shipped)}
                  </TableCell>
                  <TableCell align="right">
                    {isNonZeroDecimalOrMoney(line.cost)
                      ? formatMoney(line.cost, true, true)
                      : null}
                  </TableCell>
                  <TableCell align="right">
                    {isNonZeroDecimalOrMoney(line.revenue)
                      ? formatMoney(line.revenue, true, true)
                      : null}
                  </TableCell>
                  <TableCell align="right">
                    {formatMoney(line.revenue.minus(line.cost), true, true)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </>
  );
}
