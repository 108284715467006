import { Helmet } from "react-helmet";

import { Box, Card, CardContent, Typography } from "@mui/material";

import { i18n, i18nCode } from "services/i18nService";

export function Mobile() {
  return (
    <>
      <Helmet>
        <meta name="apple-itunes-app" content="app-id=1399703758" />
      </Helmet>
      <Box sx={{ display: "grid", minWidth: "1070px" }} m={2} mb={0}>
        <Box mb={1}>
          <Typography variant="h2">{i18n("mobile.MobileApp")}</Typography>
        </Box>
        <Card variant="outlined" sx={{ maxWidth: 500 }}>
          <CardContent>
            <Typography sx={{ paddingBottom: "1rem" }}>
              {i18n("mobile.ClickToInstall")}
            </Typography>
            <a
              href="https://apps.apple.com/us/app/sos-inventory/id1399703758?ls=1"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt={i18n("imgAlt.AppStoreBadge")}
                src={`/images/app_store_badge_${i18nCode()}.svg`}
              />
            </a>
            <Typography sx={{ paddingTop: "1rem" }}>
              {i18n("mobile.OrSearchAppStore")}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
