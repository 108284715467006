import { Decimal } from "classes/DecimalClasses";

import {
  afterTouchLine as baseAfterTouchLine,
  updateWeightAndVolume,
  updateAmount,
} from "services/utility/afterTouchLine";

// when this field (the key) changes, we should run the (value) list of
// updater functions
const UPDATERS = {
  quantity: [updateAmount, updateWeightAndVolume],
  uom: [updateWeightAndVolume, updateAmount],
  item: [updateWeightAndVolume],
  amount: [updateUnitprice],
  unitprice: [updateAmount],
};

export function afterTouchLine(line, changedField) {
  return baseAfterTouchLine(UPDATERS, line, changedField);
}
function updateUnitprice(line) {
  const { quantity, unitprice, amount } = line;
  return {
    ...line,
    unitprice: quantity.eq(new Decimal(0)) ? unitprice : amount.div(quantity),
  };
}
