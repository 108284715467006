import { Box, Checkbox, Typography } from "@mui/material";

import { i18n } from "services/i18nService";

export function EditVariantsFilter(props) {
  const { filters, setFilters } = props;
  const { filterSettings } = filters;
  const { ShowVariants } = filterSettings;

  function handleChange(checked) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        ShowVariants: checked ? "All" : "HideVariants",
      },
      startingRecord: 1,
    }));
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
      data-testing="maxResultsFilter"
    >
      <Checkbox
        checked={ShowVariants === "All"}
        onChange={(e) => handleChange(e.target.checked)}
      />
      <Typography
        sx={{
          fontSize: "0.8125rem",
          fontWeight: "Medium",
          color: "primaryText",
        }}
      >
        {i18n("filter.variant.show")}
      </Typography>
    </Box>
  );
}
