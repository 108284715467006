export function beforeSave(record) {
  const newRecord = { ...record };
  // get rid of empty lines
  // add lineNumber based on array index since BE requires it
  newRecord.lines = record.lines
    .filter(({ componentItem }) => componentItem)
    .map((bomItem, index) => ({ ...bomItem, lineNumber: index + 1 }));

  return newRecord;
}
