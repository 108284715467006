import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@mui/material";

import { Layout } from "Layout";

import { CustomFields } from "components/CustomFields/CustomFields";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { QuickAddVendor } from "components/QuickAddVendor";
import { FrmTextField } from "components/formFields/FrmTextField";
import { LineItemTotals } from "components/formFields/Totals/Purchasing/LineItemTotals";
import { BillingAddress } from "components/formFields/address/purchasing/Billing";
import { ShippingAddress } from "components/formFields/address/purchasing/Shipping";
import {
  FrmDeposit,
  FrmNumber,
  FrmTerms,
  FrmVendorWithAdd,
  FrmVendorNotes,
  FrmShipMethod,
  FrmAssignedTo,
  FrmVendorMessage,
  FrmComment,
  FrmDepartment,
} from "components/formFields/frm";
import { FrmCurrencyExchangeRate } from "components/formFields/frm";
import { FrmCheckbox } from "components/formFields/frm/FrmCheckbox";
import { FrmCheckboxWithHoverHelp } from "components/formFields/frm/FrmCheckboxWithHoverHelp";
import { FrmSignature } from "components/formFields/frm/FrmSignature";
import { DatePicker } from "components/html/Input/DatePicker";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";
import { WarningModal } from "components/utility/WarningModal";

import { i18n } from "services/i18nService";
import {
  updateTotals,
  ZERO_TOTALS,
} from "services/sosInventoryService/purchasingTransaction/updateTotals";
import { getExchangeRate } from "services/sosInventoryService/sosApi";
import {
  fullAddNewVendor,
  quickAddNewVendor,
} from "services/utility/controller";
import { setPageDirty } from "services/utility/edit";

import { useCustomFieldDefsAsObject } from "hooks/useCustomFieldDefinitions";
import { useErrors } from "hooks/useErrors";
import { useItems } from "hooks/useItems";
import { useLineHandler } from "hooks/useLineHandler";
import { useRelated } from "hooks/useRelated";
import { useTaxCodes, TAX_CODE_TYPE_PURCHASE } from "hooks/useTaxCodes";
import { useVendors } from "hooks/useVendors";

import {
  getTransaction,
  updateTransaction,
} from "views/PurchaseOrder/PurchaseOrder/controller";
import { LineItems } from "views/PurchaseTransactions/LineItems";

import { openAlert } from "globalState/alertSlice";
import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { theme } from "SosTheme";
import { OBJECT_TYPES, ITEM_QUICKLIST_CASES } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.PURCHASE_ORDER.fullString;
const CUSTOM_FIELD_OBJECT_TYPES = [OBJECT_TYPE, OBJECT_TYPES.VENDOR.fullString];

export function PurchaseOrder(props) {
  const { id, onClose, newFromObjectType, newFromId, dropShip } = props;

  // TRANSACTION STATE
  const [record, setRecord] = useState();
  const [lines, lineHandler] = useLineHandler();
  const [relatedRecords, setRelatedRecords] = useState({});
  const [totals, setTotals] = useState(ZERO_TOTALS);
  const CURRENT_STATE = { record, lines, relatedRecords, totals };

  // UI STATE
  const { errors, setErrors, isInError } = useErrors();
  const [clearLines, setClearLines] = useState(false);
  const customFieldDefs = useCustomFieldDefsAsObject(CUSTOM_FIELD_OBJECT_TYPES);

  const [addNewVendor, setAddNewVendor] = useState(false);
  const [newVendorName, setNewVendorName] = useState("");
  const [vendorAdded, setVendorAdded] = useState(0);
  const [itemAdded, setItemAdded] = useState(0);
  const loadingState = useSelector((state) => state.loading.editModal);

  // RELATED RECORD LISTS
  const taxCodes = useTaxCodes(TAX_CODE_TYPE_PURCHASE);
  const vendors = useVendors(vendorAdded);
  const currencies = useRelated("currency");
  const { items, itemsCount } = useItems(
    ITEM_QUICKLIST_CASES.PURCHASING,
    itemAdded
  );

  // SETTINGS
  const departmentEnabled = useSelector(
    (state) => state.userCompanySettings.settings.departmentEnabled
  );
  const multiCurrencyEnabled = useSelector(
    (state) => state.userCompanySettings.settings.multiCurrencyEnabled
  );

  // OTHER SETUP
  const dispatch = useDispatch();

  // UPDATERS FOR CONTROLLER TO UPDATE STATE
  const UPDATERS = useMemo(
    () => ({
      setRecord,
      lineHandler,
      setRelatedRecords,
      setErrors,
    }),
    [lineHandler, setErrors]
  );

  // HANDLE ALL USER INPUT
  function handleInputFieldChange(field, newValue) {
    setPageDirty();
    updateTransaction(
      field,
      newValue,
      customFieldDefs[OBJECT_TYPE],
      CURRENT_STATE,
      UPDATERS,
      currencies
    );
  }

  async function handleRefreshExchangeRate() {
    const currencyRecord = currencies.find(
      ({ id }) => id === record?.currency.id
    );
    const response = await getExchangeRate(currencyRecord.code, record?.date);
    const newExchangeRate = response.exchangeRate;
    handleInputFieldChange("exchangeRate", newExchangeRate);
  }

  // LOAD INITIAL TRANSACTION STATE
  useEffect(() => {
    async function _getTransaction() {
      dispatch(editModalLoadingIndicatorOn());
      await getTransaction(
        id,
        newFromId,
        newFromObjectType,
        UPDATERS,
        customFieldDefs[OBJECT_TYPE],
        dropShip
      );
      dispatch(editModalLoadingIndicatorOff());
    }
    if (customFieldDefs) {
      _getTransaction();
    }
  }, [
    dispatch,
    id,
    newFromId,
    newFromObjectType,
    lineHandler,
    UPDATERS,
    dropShip,
    customFieldDefs,
  ]);

  const classes = useRelated("class");

  useEffect(() => updateTotals(lines, setTotals, taxCodes), [lines, taxCodes]);

  useEffect(() => {
    if (record && record.id && record.closed) {
      dispatch(
        openAlert({ type: "error", message: i18n("tooltip.PODisabled") })
      );
      onClose();
    }
  }, [record, dispatch, onClose]);

  async function handleQuickAddNewVendor(name) {
    dispatch(editModalLoadingIndicatorOn());
    await quickAddNewVendor(name, UPDATERS);

    // force a re-get of the vendor list, since we know one was just added
    setVendorAdded((prev) => prev + 1);
    dispatch(editModalLoadingIndicatorOff());
    setPageDirty();
    setAddNewVendor(false);
  }

  async function handleAddNewVendor(newVendor) {
    await fullAddNewVendor(
      newVendor,
      customFieldDefs.vendor,
      customFieldDefs[OBJECT_TYPE],
      record.customFields,
      UPDATERS,
      true
    );

    // force a re-get of the vendor list, since we know one was just added
    setVendorAdded((prev) => prev + 1);
    dispatch(editModalLoadingIndicatorOff());
    setAddNewVendor(false);
  }

  function doClearLines() {
    setPageDirty();
    lineHandler({ type: "clear", blankLine: EMPTY_LINE_ITEM.purchaseorder });
    setClearLines(false);
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        relatedRecords={relatedRecords}
        lines={lines}
        setErrors={setErrors}
        errors={errors}
        text={record?.number}
        handleClose={onClose}
        vendorId={record?.vendor?.id}
      />
      {loadingState && <Loading />}
      <ObjectEditDialogContent>
        <Layout
          pageTitle={
            id
              ? `${i18n("objectType.purchaseorder.Sentence")} ${
                  record?.number || ""
                }`
              : i18n("objectType.purchaseorder.New")
          }
        >
          <div id="B" style={{ margin: theme.spacing(2) }}>
            <FormErrors errors={errors} setErrors={setErrors} />
            {record ? (
              <>
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }}>
                    <DatePicker
                      value={record.date}
                      name="date"
                      label={i18n("frmLabel.Date")}
                      onValueChange={handleInputFieldChange}
                      error={isInError("date")}
                      dataTesting="date"
                    />
                  </div>
                  <FrmNumber
                    recordId={record.id}
                    label={i18n("purchaseOrder.labelNumber")}
                    value={record.number}
                    onValueChange={handleInputFieldChange}
                    error={isInError("number")}
                  />
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmVendorWithAdd
                      value={record.vendor}
                      onValueChange={handleInputFieldChange}
                      setAddNewVendor={setAddNewVendor}
                      setNewVendorName={setNewVendorName}
                      vendors={vendors}
                      error={isInError("vendor")}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmVendorNotes
                      value={record.vendorNotes}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmCheckbox
                      name="dropShip"
                      value={record.dropShip}
                      onValueChange={handleInputFieldChange}
                      label={i18n("frmLabel.DropShip")}
                      sx={{ marginRight: "8px" }}
                    />
                  </div>
                </FrmFieldGrid>
                <VSpace space={2} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ maxWidth: "30rem" }}>
                    <BillingAddress
                      value={record.billing}
                      vendor={record.vendor}
                      handleInputFieldChange={handleInputFieldChange}
                    />
                  </div>
                  <div style={{ maxWidth: "30rem" }}>
                    <ShippingAddress
                      value={record.shipping}
                      customer={record.customer}
                      location={record.location}
                      isDropShip={record.dropShip}
                      handleInputFieldChange={handleInputFieldChange}
                    />
                  </div>
                </FrmFieldGrid>
                <VSpace space={1} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }}>
                    {multiCurrencyEnabled && (
                      <Grid item xs={4}>
                        <FrmCurrencyExchangeRate
                          customerOrVendor={record.vendor}
                          record={record}
                          currencies={currencies}
                          currencyError={isInError("currency")}
                          exchangeRate={record.exchangeRate}
                          handleInputChange={handleInputFieldChange}
                          handleRefreshExchangeRate={() =>
                            handleRefreshExchangeRate(
                              currencies,
                              record,
                              handleInputFieldChange
                            )
                          }
                        />
                      </Grid>
                    )}
                  </div>
                  <div style={{ gridColumn: "2 / 2" }} />
                </FrmFieldGrid>
                <VSpace space={1} />
                <LineItems
                  record={record}
                  objectType={OBJECT_TYPE}
                  lines={lines}
                  taxCodes={taxCodes}
                  items={items}
                  totals={totals}
                  itemsCount={itemsCount}
                  addItem={() => setItemAdded((prev) => prev + 1)}
                  classes={classes}
                  lineHandler={lineHandler}
                  setClearLines={setClearLines}
                  lineItemsErrors={errors.line}
                  setErrors={setErrors}
                  showReceived
                />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexGrow: "1",
                    }}
                  >
                    <FrmCheckboxWithHoverHelp
                      name="blanketPO"
                      value={record.blanketPO}
                      onValueChange={handleInputFieldChange}
                      label={i18n("frmLabel.BlanketPO")}
                      helpText={i18n("hoverHelp.blanketPO")}
                    />

                    <FrmCheckboxWithHoverHelp
                      name="updateDefaultCosts"
                      value={record.updateDefaultCosts}
                      onValueChange={handleInputFieldChange}
                      label={i18n("frmLabel.UpdateDefaultCosts")}
                      helpText={i18n("hoverHelp.UpdateDefaultCosts")}
                    />
                  </div>

                  <LineItemTotals totals={totals} />
                </div>
                <VSpace space={4} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "2 / 3" }}>
                    <FrmTerms
                      maxWidth="20rem"
                      value={record.terms}
                      onValueChange={handleInputFieldChange}
                      error={isInError("terms")}
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 3" }}>
                    <FrmShipMethod
                      maxWidth="20rem"
                      onValueChange={handleInputFieldChange}
                      value={record?.shippingMethod}
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 3" }}>
                    <DatePicker
                      value={record.expectedDate}
                      name="expectedDate"
                      label={i18n("purchaseOrder.labelExpectedDate")}
                      onValueChange={handleInputFieldChange}
                      error={isInError("expectedDate")}
                      dataTesting="expectedDate"
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 3" }}>
                    <DatePicker
                      value={record.expectedShip}
                      name="expectedShip"
                      label={i18n("purchaseOrder.labelExpectedShip")}
                      onValueChange={handleInputFieldChange}
                      error={isInError("expectedShip")}
                    />
                  </div>
                  <FrmAssignedTo
                    maxWidth="20rem"
                    onValueChange={handleInputFieldChange}
                    value={record.assignedToUser}
                  />
                  <FrmTextField
                    name="trackingNumber"
                    label={i18n("frmLabel.TrackingNumber")}
                    sx={{ maxWidth: "20rem" }}
                    maxLength={21}
                    value={record.trackingNumber}
                    onValueBlur={handleInputFieldChange}
                  />
                  <div style={{ gridColumn: "2 / 3" }}>
                    <FrmCheckbox
                      name="contractManufacturing"
                      value={record.contractManufacturing}
                      onValueChange={handleInputFieldChange}
                      label={i18n("frmLabel.ContractManufacturing")}
                      sx={{ marginRight: "8px" }}
                    />
                  </div>

                  {departmentEnabled && (
                    <div style={{ gridColumn: "1 / 2" }}>
                      <FrmDepartment
                        maxWidth="20rem"
                        value={record.department}
                        onValueChange={handleInputFieldChange}
                      />
                    </div>
                  )}
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmDeposit
                      maxWidth="20rem"
                      amountValue={record.depositAmount}
                      percentValue={record.depositPercent}
                      onValueChange={handleInputFieldChange}
                      amountError={isInError("depositAmount")}
                      percentError={isInError("depositPercent")}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2", gridRow: "span 2" }}>
                    <div style={{ maxWidth: "100%" }}>
                      <FrmVendorMessage
                        value={record.vendorMessage}
                        onValueChange={handleInputFieldChange}
                      />
                    </div>
                  </div>
                  <div style={{ gridColumn: "1 / 2", gridRow: "span 2" }}>
                    <FrmComment
                      value={record.comment}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmSignature
                      objectType={OBJECT_TYPE}
                      id={record.id}
                      hasSignature={record.hasSignature}
                    />
                  </div>
                </FrmFieldGrid>

                <CustomFields
                  customFieldDefinitions={customFieldDefs[OBJECT_TYPE]}
                  customFields={record.customFields}
                  onValueChange={handleInputFieldChange}
                  setErrors={setErrors}
                />
              </>
            ) : (
              !isInError("record") && (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )
            )}
            <VSpace space={4} />
          </div>
          {record && (
            <>
              {Boolean(addNewVendor) && (
                <QuickAddVendor
                  open
                  initialNameValue={newVendorName}
                  onClose={() => setAddNewVendor(false)}
                  onQuickAdd={handleQuickAddNewVendor}
                  onAdd={handleAddNewVendor}
                />
              )}
              <WarningModal
                title={i18n("global.ClearLines")}
                message={i18n("global.clearLinesMessage")}
                open={clearLines}
                onOk={doClearLines}
                onClose={() => setClearLines(false)}
              />
            </>
          )}
        </Layout>
      </ObjectEditDialogContent>
    </>
  );
}
