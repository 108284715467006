export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixReturn: record.returnNumber.prefix,
    nextReturnNumber: record.returnNumber.nextNumber,
  };

  delete newRecord.returnNumber;

  return newRecord;
}
