export const SETTINGS = [
  {
    apiName: "logoWidth",
    component: "FrmTextField",
    number: true,
    width: "8rem",
  },
  {
    apiName: "logoHeight",
    component: "FrmTextField",
    number: true,
    width: "8rem",
  },
  {
    apiName: "logoImageFile",
    component: "FrmAddLogo",
  },
];
