import { useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import {
  Container,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  Button,
  Box,
  Paper,
  Checkbox,
} from "@mui/material";

import { ButtonProgress } from "components/utility/ButtonProgress";
import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";
import { retrieveSettings } from "services/sosInventoryService/settings";
import { updateDefaultCompany } from "services/sosInventoryService/sosApi";
import { getRefreshedBearerToken } from "services/utility/authentication";

import { setNeedsCompanyChooser } from "globalState/authenticationSlice";
import {
  setCompany,
  setCompanyList,
} from "globalState/userCompanySettingsSlice";

import { TOP_NAV_BAR_HEIGHT } from "appConstants";

export function CompanyChooser() {
  const { needsCompanyChooser } = useSelector((state) => state.authentication);

  const [done, setDone] = useState(false);
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.userCompanySettings.companies);

  const defaultCompany = companies.find((company) => company.default);

  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    companyId: defaultCompany ? defaultCompany.id : -1,
    makeDefaultCompany: Boolean(defaultCompany),
  });

  const disabled = useMemo(() => inputs.companyId === -1, [inputs.companyId]);

  async function updateChosenCompany(event) {
    event.preventDefault();
    if (inputs.companyId === -1) {
      return;
    }
    setIsLoading(true);
    const { companyId, makeDefaultCompany } = inputs;
    await getRefreshedBearerToken(companyId);

    const foundCompany = companies.find(({ id }) => id === companyId);
    const newCompany = { ...foundCompany, default: makeDefaultCompany };
    dispatch(setCompany(newCompany));

    const newCompanies = makeDefaultCompany
      ? companies.map((company) => ({
          ...company,
          default: company.id === companyId,
        }))
      : [...companies];

    dispatch(setCompanyList(newCompanies));

    const id = makeDefaultCompany ? companyId : -1;
    updateDefaultCompany(id);

    await retrieveSettings();
    if (needsCompanyChooser) {
      dispatch(setNeedsCompanyChooser(false));
    }

    setIsLoading(false);
    setDone(true);
  }

  function handleInputChange(event) {
    const { name, checked, value } = event.target;
    setInputs((inputs) => ({
      ...inputs,
      [name]: name === "companyId" ? value : checked,
    }));
  }

  if (done) {
    // for now we are disabling redirect on login.  Leaving code so this is easy to enable
    // once we handle the notification issue that needs to be done before we allow this.
    // const redirectPath = sessionStorage.getItem("redirectPath") || "/";
    const redirectPath = "/";
    sessionStorage.removeItem("redirectPath");
    return <Navigate to={redirectPath} />;
  }

  return (
    <Container maxWidth="sm">
      <Box m={2} mb={0} sx={{ height: `calc(80vh - ${TOP_NAV_BAR_HEIGHT})` }}>
        <Paper elevation={4} sx={{ height: "100%", padding: "2.0rem" }}>
          <form onSubmit={updateChosenCompany} style={{ height: "100%" }}>
            <FormControl component="fieldset" sx={{ height: "100%" }}>
              <FormLabel component="legend">
                <Typography variant="h2" gutterBottom align="center">
                  {i18n("chooseCompany.Header")}
                </Typography>
              </FormLabel>
              <RadioGroup
                name="companyId"
                onChange={handleInputChange}
                value={inputs.companyId}
                sx={{
                  display: "block",
                  overflowY: "scroll",
                }}
              >
                {companies.map((company) => (
                  <FormControlLabel
                    key={company.id}
                    value={company.id}
                    control={<Radio />}
                    label={company.name}
                    data-testing={company.name}
                    sx={{ width: "90%", marginLeft: "0.25rem" }}
                  />
                ))}
              </RadioGroup>
              <Box my={2} display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      name="makeDefaultCompany"
                      checked={inputs.makeDefaultCompany}
                      onChange={handleInputChange}
                    />
                  }
                  label={i18n("chooseCompany.AutomaticCheck")}
                  sx={{ marginRight: 0 }}
                  color="primary"
                />
                <HoverHelp
                  title={i18n("chooseCompany.HelpText")}
                  sx={{ marginLeft: 0.5 }}
                />
              </Box>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  gap: "1rem",
                }}
              >
                {!needsCompanyChooser && (
                  <Button color="secondary" onClick={() => setDone(true)}>
                    {i18n("button.Cancel")}
                  </Button>
                )}
                <div style={{ position: "relative" }}>
                  <Button
                    color="secondary"
                    type="submit"
                    disabled={disabled || isLoading}
                    data-testing="submit"
                  >
                    {i18n("button.Submit")}
                  </Button>

                  <div>{isLoading && <ButtonProgress color="secondary" />}</div>
                </div>
              </div>
            </FormControl>
          </form>
        </Paper>
      </Box>
    </Container>
  );
}
