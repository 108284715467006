import { Helmet } from "react-helmet";

export function Layout(props) {
  const { pageTitle, children } = props;
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {children}
    </>
  );
}
