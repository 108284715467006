import { i18n } from "services/i18nService";

import { OBJECT_TYPES } from "appConstants";

export const ENTRY_TYPES = [
  {
    typeString: OBJECT_TYPES.TASK.typeString,
    fullString: OBJECT_TYPES.TASK.fullString,
    typeSuffix: i18n("calendar.suffix.toBeCompleted"),
    label: i18n(`calendar.entryLabel.${OBJECT_TYPES.TASK.fullString}`),
    settingName: "showTasksToBeCompleted",
    listFilterFieldName: "completeByDate",
  },
  {
    typeString: OBJECT_TYPES.LOT.typeString,
    fullString: OBJECT_TYPES.LOT.fullString,
    typeSuffix: i18n("calendar.suffix.expiring"),
    label: i18n(`calendar.entryLabel.${OBJECT_TYPES.LOT.fullString}`),
    planIncludesEntryType: (plus, pro) => plus || pro,
    settingName: "showLotsExpiring",
    listFilterFieldName: "expiration",
    listAdditionalFilters: { archived: "no" },
  },
  {
    typeString: OBJECT_TYPES.PURCHASE_ORDER.typeString,
    fullString: OBJECT_TYPES.PURCHASE_ORDER.fullString,
    typeSuffix: i18n("calendar.suffix.expected"),
    label: i18n(
      `calendar.entryLabel.${OBJECT_TYPES.PURCHASE_ORDER.fullString}`
    ),
    settingName: "showPurchaseOrdersExpected",
    listFilterFieldName: "expectedDate",
    listAdditionalFilters: { archived: "no", status: "open" },
  },
  {
    typeString: OBJECT_TYPES.SALES_ORDER.typeString,
    fullString: OBJECT_TYPES.SALES_ORDER.fullString,
    typeSuffix: i18n("calendar.suffix.withAnItemDue"),
    label: i18n(`calendar.entryLabel.${OBJECT_TYPES.SALES_ORDER.fullString}`),
    settingName: "showSalesOrdersWithAnItemDue",
    listFilterFieldName: "earliestDueDate",
    listAdditionalFilters: { archived: "no", status: "open" },
  },
  {
    typeString: OBJECT_TYPES.INVOICE.typeString,
    fullString: OBJECT_TYPES.INVOICE.fullString,
    typeSuffix: i18n("calendar.suffix.due"),
    label: i18n(`calendar.entryLabel.${OBJECT_TYPES.INVOICE.fullString}`),
    settingName: "showInvoicesDue",
    listFilterFieldName: "dueDate",
    listAdditionalFilters: { archived: "no", balanceGreaterThanZero: "true" },
  },
  {
    typeString: OBJECT_TYPES.SALES_RECEIPT.typeString,
    fullString: OBJECT_TYPES.SALES_RECEIPT.fullString,
    typeSuffix: i18n("calendar.suffix.withAnItemDue"),
    label: i18n(`calendar.entryLabel.${OBJECT_TYPES.SALES_RECEIPT.fullString}`),
    settingName: "showSalesReceiptsWithAnItemDue",
    listFilterFieldName: "earliestDueDate",
    listAdditionalFilters: { archived: "no", status: "open" },
  },
  {
    typeString: OBJECT_TYPES.RMA.typeString,
    fullString: OBJECT_TYPES.RMA.fullString,
    typeSuffix: i18n("calendar.suffix.expiring"),
    label: i18n(`calendar.entryLabel.${OBJECT_TYPES.RMA.fullString}`),
    planIncludesEntryType: (plus, pro) => plus || pro,
    settingName: "showRMAsExpiring",
    listFilterFieldName: "expiration",
    listAdditionalFilters: { archived: "no", status: "open" },
  },
  {
    typeString: OBJECT_TYPES.PICK_TICKET.typeString,
    fullString: OBJECT_TYPES.PICK_TICKET.fullString,
    typeSuffix: i18n("calendar.suffix.toBePicked"),
    label: i18n(`calendar.entryLabel.${OBJECT_TYPES.PICK_TICKET.fullString}`),
    planIncludesEntryType: (plus, pro) => plus || pro,
    settingName: "showPickTicketsToBePicked",
    listFilterFieldName: "pickBy",
    listAdditionalFilters: { archived: "no", status: "open" },
  },
  {
    typeString: OBJECT_TYPES.SHIPMENT.typeString,
    fullString: OBJECT_TYPES.SHIPMENT.fullString,
    typeSuffix: i18n("calendar.suffix.toBeShipped"),
    label: i18n(`calendar.entryLabel.${OBJECT_TYPES.SHIPMENT.fullString}`),
    settingName: "showShipmentsToBeShipped",
    listFilterFieldName: "shipBy",
    listAdditionalFilters: { archived: "no" },
  },
  {
    typeString: OBJECT_TYPES.WORK_ORDER.typeString,
    fullString: OBJECT_TYPES.WORK_ORDER.fullString,
    typeSuffix: i18n("calendar.suffix.withAnItemDue"),
    label: i18n(`calendar.entryLabel.${OBJECT_TYPES.WORK_ORDER.fullString}`),
    planIncludesEntryType: (plus, pro) => pro,
    settingName: "showWorkOrdersWithAnItemDue",
    listFilterFieldName: "earliestDueDate",
    listAdditionalFilters: { archived: "no", status: "open" },
  },
  {
    typeString: OBJECT_TYPES.RENTAL.typeString,
    fullString: OBJECT_TYPES.RENTAL.fullString,
    typeSuffix: i18n("calendar.suffix.due"),
    label: i18n(`calendar.entryLabel.${OBJECT_TYPES.RENTAL.fullString}`),
    planIncludesEntryType: (plus, pro) => plus || pro,
    settingName: "showRentalsDue",
    listFilterFieldName: "dueDate",
    listAdditionalFilters: { archived: "no", status: "open" },
  },
];

export function getTypeSuffix(entryType) {
  return getConfigForEntryType(entryType).typeSuffix;
}

export function getConfigForEntryType(entryType) {
  return Object.values(ENTRY_TYPES).find(
    ({ typeString }) => typeString === entryType
  );
}

export function getConfigFromFullString(objectFullString) {
  return Object.values(ENTRY_TYPES).find(
    ({ fullString }) => fullString === objectFullString
  );
}

export const DEFAULT_SETTINGS = {
  showTasksToBeCompleted: true,
  showLotsExpiring: false,
  showPurchaseOrdersExpected: false,
  showSalesOrdersWithAnItemDue: false,
  showInvoicesDue: false,
  showSalesReceiptsWithAnItemDue: false,
  showRMAsExpiring: false,
  showPickTicketsToBePicked: false,
  showShipmentsToBeShipped: false,
  showWorkOrdersWithAnItemDue: false,
  showRentalsDue: false,
};
