import { Checkbox as MuiCheckbox } from "@mui/material";

export function Checkbox(props) {
  const { id, setCheckedIds, isChecked } = props;

  function handleCheck(e) {
    if (e.target.checked) {
      setCheckedIds((prev) => [...prev, id]);
    } else {
      setCheckedIds((prev) => prev.filter((e) => e !== id));
    }
  }

  return (
    <td style={{ textAlign: "center", verticalAlign: "top" }}>
      <MuiCheckbox sx={{ p: 0 }} checked={isChecked} onChange={handleCheck} />
    </td>
  );
}
