import { createSlice } from "@reduxjs/toolkit";

export const dirtyPageSlice = createSlice({
  name: "dirtyPage",
  initialState: false,
  reducers: {
    dirtyPageOn: () => true,
    dirtyPageOff: () => false,
  },
});

export const { dirtyPageOn, dirtyPageOff } = dirtyPageSlice.actions;
export default dirtyPageSlice.reducer;
