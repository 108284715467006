import { LineItemTextField } from "components/formFields/LineItemTextField";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineNotes(props) {
  const { value, onValueChange, objectType } = props;

  return (
    <LineItemTextField
      name="notes"
      multiline
      maxRows={4}
      resize
      value={value}
      onValueChange={onValueChange}
      metadata={LINE_ITEM_METADATA[objectType].notes}
    />
  );
}
