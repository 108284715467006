import { Dialog } from "@mui/material";

import { ItemHistory } from "components/ListPage/List/Row/History/Rental/RentalHistory";

const HISTORY_PAGES = { item: { component: ItemHistory } };

const sx = {
  "& .MuiDialog-paper": { minHeight: "30vh", maxHeight: "93vh" },
};

export function RentalHistoryDialog(props) {
  const { setOpen, objectType, id, identifierText } = props;

  const HistoryPage = HISTORY_PAGES[objectType]?.component;

  return (
    <Dialog
      open={true}
      onClose={() => setOpen(false)}
      maxWidth="lg"
      fullWidth
      sx={sx}
    >
      <HistoryPage
        setOpen={setOpen}
        objectType={objectType}
        id={id}
        identifierText={identifierText}
      />
    </Dialog>
  );
}
