import { afterGet as globalAfterGet } from "services/sosInventoryService/afterGet";
import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/itemReceipt/schema";
import { purchasingTransactionAfterGet } from "services/sosInventoryService/purchasingTransaction/afterGet";
import {
  transformFields,
  transformLineItemFields,
  transformSerial,
} from "services/utility/afterGet";

import { OBJECT_TYPES } from "appConstants";
import { LINE_ITEM_FIELDS } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.ITEM_RECEIPT.fullString;

export function afterGet(record) {
  let newRecord = transformFields(record, FIELDS_TO_TRANSFORM);

  // convert payment to look like a typical reference field
  newRecord.payment = { id: record.payment };

  newRecord.lines = transformLineItemFields(
    newRecord.lines,
    LINE_ITEM_FIELDS[OBJECT_TYPE]
  );

  // transform the output lot and serial
  newRecord.lines = newRecord.lines.map((line) =>
    transformSerial(line, newRecord.autoSerialLots)
  );

  newRecord = purchasingTransactionAfterGet(newRecord);
  return globalAfterGet(newRecord);
}
