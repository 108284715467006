import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useItems } from "hooks/useItems";

import { ITEM_QUICKLIST_CASES } from "appConstants";

export function FrmCategory(props) {
  const { value, onValueChange, width, disabled, placeholder } = props;

  const { items: categories } = useItems(ITEM_QUICKLIST_CASES.CATEGORY);

  return (
    <FrmSelectFromObjects
      name="category"
      options={categories}
      value={value}
      label={i18n("frmLabel.Category")}
      onValueChange={onValueChange}
      sx={{ width, maxWidth: "100%" }}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
}
