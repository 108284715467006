import { LineTableHeadingCell } from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";

export function LineColumnHeading(props) {
  const { labelCode, align = "left", sx } = props;
  return (
    <LineTableHeadingCell align={align} sx={sx}>
      {labelCode && i18n(`columns.${labelCode}`)}
    </LineTableHeadingCell>
  );
}
