import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import AssignmentIcon from "@mui/icons-material/Assignment";
import { IconButton } from "@mui/material";

import { Tooltip } from "components/Tooltip";

import { i18n } from "services/i18nService";

import { openAlert } from "globalState/alertSlice";

import { theme } from "SosTheme";

export function FrmStatusLink(props) {
  const { value } = props;
  const dispatch = useDispatch();
  const customerPortalEnabled = useSelector(
    (state) => state.userCompanySettings.settings.customerPortalEnabled
  );

  if (!value || !customerPortalEnabled) {
    return null;
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(value);
    dispatch(
      openAlert({
        type: "success",
        message: i18n("alert.CopiedToClipboard"),
      })
    );
  }

  return (
    <div
      style={{
        paddingTop: "1.0em",
        paddingBottom: "1.0em",
        paddingLeft: "12px",
      }}
    >
      <a
        style={{ color: theme.palette.primaryLink }}
        target="_blank"
        href={value}
        rel="noreferrer"
      >
        {i18n("frmLabel.StatusUrl")}
      </a>
      <Tooltip title={i18n("tooltip.CopyToClipboard")}>
        <IconButton
          sx={{ marginLeft: "1rem" }}
          size="small"
          onClick={copyToClipboard}
        >
          <AssignmentIcon
            sx={{ "&:hover": { fill: theme.palette.selectIcon } }}
          />
        </IconButton>
      </Tooltip>
    </div>
  );
}
