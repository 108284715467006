import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { NEW_RECORD_SKELETON } from "appConfig";

import { EditModalHeader } from "components/EditModalHeader";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { SectionHeading } from "components/SectionHeading";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { getRecord } from "services/sosInventoryService/sosApi";

import { useErrors } from "hooks/useErrors";
import { useItems } from "hooks/useItems";
import { useRecord } from "hooks/useRecord";

import { TemplateTable } from "views/ProcessTemplates/ProcessTemplate/TemplateTable";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

export function ProcessTemplate(props) {
  const { id, onClose, objectType } = props;

  const dispatch = useDispatch();

  const { items, itemsCount } = useItems(null);
  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();

  const loadingState = useSelector((state) => state.loading.editModal);

  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  useEffect(() => {
    async function _getRecord() {
      if (id) {
        dispatch(editModalLoadingIndicatorOn());
        const response = await getRecord(objectType, id);
        setRecord(response);
        dispatch(editModalLoadingIndicatorOff());
      } else {
        const emptyRecord = NEW_RECORD_SKELETON[objectType]({ numLinesToAdd });
        setRecord(emptyRecord);
      }
    }
    _getRecord();
  }, [setRecord, id, objectType, dispatch, numLinesToAdd]);

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={objectType}
        outputs={record?.outputs}
        inputs={record?.inputs}
        setErrors={setErrors}
        text={record?.name || ""}
        handleClose={onClose}
      />

      {loadingState && <Loading />}

      {record && (
        <ObjectEditDialogContent>
          <Layout
            pageTitle={
              id
                ? `${i18n(`objectType.${objectType}.Sentence`)} ${
                    record?.name || ""
                  }`
                : i18n(`objectType.${objectType}.New`)
            }
          >
            <Box flexGrow="1" position="relative" m={2} mb={0}>
              <FormErrors errors={errors} setErrors={setErrors} />
              {record ? (
                <Box>
                  <div style={{ maxWidth: "24rem" }}>
                    <FrmTextField
                      name="name"
                      label={i18n("frmLabel.Name")}
                      onValueBlur={handleInputFieldChange}
                      value={record.name}
                      error={isInError("name")}
                    />
                  </div>
                  <FrmResizeTextField
                    width="95%"
                    minRows={2}
                    name="description"
                    label={i18n("document.Description")}
                    onValueBlur={handleInputFieldChange}
                    value={record.description}
                  />
                  <SectionHeading>{i18n("global.Outputs")}</SectionHeading>
                  <TemplateTable
                    record={record}
                    setRecord={setRecord}
                    lines={record.outputs}
                    items={items}
                    itemsCount={itemsCount}
                    lineType="outputs"
                  />
                  <SectionHeading>{i18n("global.Inputs")}</SectionHeading>
                  <TemplateTable
                    record={record}
                    setRecord={setRecord}
                    lines={record.inputs}
                    items={items}
                    itemsCount={itemsCount}
                    lineType="inputs"
                  />
                  <VSpace space={1} />
                </Box>
              ) : (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )}
            </Box>
            <VSpace space={4} />
          </Layout>
        </ObjectEditDialogContent>
      )}
    </>
  );
}
