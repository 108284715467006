export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixWorkOrder: record.workOrderNumber.prefix,
    nextWONumber: record.workOrderNumber.nextNumber,
  };

  delete newRecord.workOrderNumber;

  return newRecord;
}
