import { FormControlLabel, Checkbox } from "@mui/material";

import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";

export function FrmCreateVendorCredit(props) {
  const { value, onValueChange } = props;

  return (
    <div style={{ display: "block" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              onChange={(e) => onValueChange(e.target.name, e.target.checked)}
              name="createVendorCredit"
              data-testing="createVendorCredit"
            />
          }
          label={i18n("frmLabel.CreateVendorCredit")}
          sx={{ marginRight: 0 }}
        />
        <HoverHelp
          title={i18n("hoverHelp.createVendorCredit")}
          sx={{ marginLeft: 0.5 }}
        />
      </div>
    </div>
  );
}
