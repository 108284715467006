import {
  FIELDS_TO_TRANSFORM,
  LINE_FIELDS_TO_TRANSFORM,
} from "services/sosInventoryService/variant/schema";
import { transformFields } from "services/utility/afterGet";

export function afterGet(record) {
  const newRecord = transformFields(record, FIELDS_TO_TRANSFORM);

  newRecord.lines = record.lines.map((line) =>
    transformFields(line, LINE_FIELDS_TO_TRANSFORM)
  );

  return newRecord;
}
