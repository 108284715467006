import { Decimal, Money } from "classes/DecimalClasses";

import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/itemHistory/schema";
import { transformFields } from "services/utility/afterGet";

export function afterGet(record) {
  const newRecord = transformFields(record, FIELDS_TO_TRANSFORM);

  const { costBasis, quantity } = newRecord;
  newRecord.txnCostPerUnit = quantity.eq(Decimal.ZERO)
    ? Money.ZERO
    : costBasis.div(quantity);

  return newRecord;
}
