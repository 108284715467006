import { Decimal } from "classes/DecimalClasses";
import { Loading } from "classes/Loading";

export const ZERO_TOTALS = { quantityDiff: new Decimal(0) };

export function updateTotals(lines, setTotals) {
  if (!lines || lines.length === 0) {
    setTotals(ZERO_TOTALS);
    return;
  }

  const quantityDiff = lines.reduce(
    (total, line) => total.plus(line.quantityDiff),
    new Decimal(0)
  );

  const valueDiff = lines.reduce(
    (total, { valueDiff }) =>
      valueDiff instanceof Loading ? total : total.plus(valueDiff),
    new Decimal(0)
  );

  setTotals({ quantityDiff, valueDiff });
}
