import { LineItemTextField } from "components/formFields/LineItemTextField";
import { LineTableCell } from "components/formFields/LineTableCell";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineReturned(props) {
  const { value, onValueChange, objectType } = props;

  return (
    <LineTableCell sx={{ minWidth: "6rem" }}>
      <LineItemTextField
        name="returned"
        value={value}
        number
        onValueChange={onValueChange}
        metadata={LINE_ITEM_METADATA[objectType].returned}
      />
    </LineTableCell>
  );
}
