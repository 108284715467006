import { DragDropContext } from "react-beautiful-dnd";

import { Grid, Paper } from "@mui/material";

import { i18n } from "services/i18nService";

import { ColumnList } from "views/Reports/ColumnList";

export function EditColumns(props) {
  const { columns, setColumns, allColumns } = props;

  const availableColumns = allColumns.filter(
    ({ apiName, alwaysShow }) =>
      !alwaysShow && !columns.map((c) => c.apiName).includes(apiName)
  );
  function onDragEnd(result) {
    const { source, destination } = result;

    // if there's no destination, the item was dropped outside of a droppable
    if (!destination) {
      return;
    }
    // if source and destination are both in available, it's a no-op; this
    // code is included only for completeness, in case we want to do something
    // here later (like indicate it's a no-op somehow)
    if (
      source.droppableId === "available" &&
      destination.droppableId === "available"
    ) {
      return;
    }

    let newColumns = [...columns];
    // if within current...
    if (
      source.droppableId === "current" &&
      destination.droppableId === "current"
    ) {
      // if source and destination are the same, nothing really got moved
      if (destination.index === source.index) {
        return;
      }
      const column = newColumns.splice(source.index, 1)[0];
      newColumns.splice(destination.index, 0, column);
      setColumns(newColumns);
    } else if (
      // from current to available
      source.droppableId === "current" &&
      destination.droppableId === "available"
    ) {
      newColumns.splice(source.index, 1);
      setColumns(newColumns);
    } else if (
      // from available to current
      source.droppableId === "available" &&
      destination.droppableId === "current"
    ) {
      newColumns.splice(destination.index, 0, availableColumns[source.index]);
      setColumns(newColumns);
    }
  }

  return (
    <Grid item xs={12}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div style={{ display: "flex" }}>
          <Paper
            style={{
              padding: "10px",
              width: "50%",
              marginRight: "5px",
              backgroundColor: "inherit",
            }}
          >
            <ColumnList
              heading={i18n("columnSelect.current")}
              id="current"
              columns={columns}
            />
          </Paper>
          <Paper
            style={{
              padding: "10px",
              width: "50%",
              marginLeft: "5px",
              backgroundColor: "inherit",
            }}
          >
            <ColumnList
              heading={i18n("columnSelect.available")}
              id="available"
              columns={availableColumns}
            />
          </Paper>
        </div>
      </DragDropContext>
    </Grid>
  );
}
