import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

import { ButtonProgress } from "components/utility/ButtonProgress";

import { i18n } from "services/i18nService";
import { mergeRecords } from "services/sosInventoryService/sosApi";

import { openAlert } from "globalState/alertSlice";
import { recordsChangedTick } from "globalState/recordsChangedSlice";

export function MergeDialog(props) {
  const { objectType, objectIds, message, open, onClose } = props;

  const [inProgress, setInProgress] = useState(false);

  const dispatch = useDispatch();

  async function doMerge() {
    setInProgress(true);
    const { success, message } = await mergeRecords(objectType, objectIds);
    setInProgress(false);
    if (success) {
      onClose();
      dispatch(
        openAlert({ type: "success", message: i18n("alert.SuccessMerged") })
      );
    } else {
      dispatch(openAlert({ type: "error", message }));
      onClose();
    }
    dispatch(recordsChangedTick());
    onClose();
  }

  return (
    <Dialog open={open} fullWidth maxWidth="xs" sx={{ inset: "0 0 40%" }}>
      <DialogTitle>{i18n("global.Merge")}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {i18n("button.Cancel")}
        </Button>
        <div style={{ position: "relative" }}>
          <Button color="secondary" disabled={inProgress} onClick={doMerge}>
            {i18n("button.Merge")}
          </Button>
          {inProgress && <ButtonProgress />}
        </div>
      </DialogActions>
    </Dialog>
  );
}
