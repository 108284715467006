export function filterNavigationMenu(
  nodes,
  hasAtLeastPlusPlan,
  hasAtLeastProPlan,
  hasPrivilegesOrIsAdmin,
  settings
) {
  let filteredNodes = [];

  // handle plans
  if (hasAtLeastProPlan) {
    filteredNodes = nodes;
  } else if (hasAtLeastPlusPlan) {
    filteredNodes = nodes.filter(
      ({ plan }) => plan === "companion" || plan === "plus"
    );
  } else {
    filteredNodes = nodes.filter(({ plan }) => plan === "companion");
  }

  // handle settings
  filteredNodes = filteredNodes.filter(
    ({ setting }) => !setting || settings[setting]
  );

  // handle privileges
  filteredNodes = filteredNodes.filter(
    ({ privilegeRequired }) =>
      !privilegeRequired || hasPrivilegesOrIsAdmin(privilegeRequired)
  );

  return filteredNodes;
}
