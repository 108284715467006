import { useEffect, useState } from "react";

import { IN_TRANSACTION } from "appConfig";

import { getAll } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

export function useLocations(onlyBinTracked = false) {
  const [locations, setLocations] = useState(null);

  useEffect(() => {
    async function retrieveRecords() {
      const { success, data, message } = await getAll(
        "location",
        IN_TRANSACTION
      );
      if (success) {
        const newLocations = onlyBinTracked
          ? data.records.filter(({ binTracking }) => binTracking)
          : data.records;
        setLocations(newLocations);
      } else {
        handleProgramError(
          new Error(
            `useRelated | unsuccessful call to getAll, message: ${message}`
          )
        );
      }
    }
    retrieveRecords();
  }, [onlyBinTracked]);

  return locations;
}
