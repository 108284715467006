import { Badge } from "@mui/material";

const sx = {
  "& .MuiBadge-anchorOriginTopRightRectangular": {
    top: "0.1rem",
    right: "0.3rem",
  },
  "& .MuiBadge-badge": {
    backgroundColor: "notificationBadge",
    height: "1.0rem",
    padding: "5px",
    fontSize: "0.75rem",
  },
};

export function StyledBadge(props) {
  const { showZero, badgeContent, color, max, overlap } = props;

  return (
    <Badge
      showZero={showZero}
      badgeContent={badgeContent}
      color={color}
      max={max}
      overlap={overlap}
      sx={sx}
    >
      {props.children}
    </Badge>
  );
}
