import { createSlice } from "@reduxjs/toolkit";

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    isLoggedIn: undefined,
    needsCompanyChooser: false,
  },
  reducers: {
    setIsLoggedIn: (state, action) => {
      return { ...state, isLoggedIn: action.payload };
    },
    setNeedsCompanyChooser: (state, action) => {
      return { ...state, needsCompanyChooser: action.payload };
    },
  },
});

export const {
  setIsLoggedIn,
  setNeedsCompanyChooser,
} = authenticationSlice.actions;
export default authenticationSlice.reducer;
