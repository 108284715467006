// see views/common.js for error object structure
import { i18n } from "services/i18nService";
import {
  isInventoryItemOrAssembly,
  isInventoryItemAssemblyOrCategory,
} from "services/sosInventoryService/domainLogic";

import { initialErrorState } from "views/common";

import globalState from "globalState/globalState";

export function isValid(record) {
  const errors = initialErrorState();
  // not sure why this next line is required, but if eliminated, the entity property
  // is somehow getting hydrated with a value from a previous call to this function
  errors.entity = [];

  const connectedToQBO =
    globalState.getState().userCompanySettings.settings.connectedToQBO;

  // require name
  if (!record.name || !record.name.length) {
    errors.messages.push(i18n("item.Error.Name"));
    errors.entity.push("name");
  }

  const isInventoryOrAssembly = isInventoryItemOrAssembly(record.type);

  if (connectedToQBO && !record.incomeAccount) {
    errors.entity.push("incomeAccount");
    errors.messages.push(i18n("item.Error.IncomeAccount"));
  }

  if (connectedToQBO && !record.cogsAccount && isInventoryOrAssembly) {
    errors.entity.push("cogsAccount");
    errors.messages.push(i18n("item.Error.CogsAccount"));
  }

  if (
    connectedToQBO &&
    !record.expenseAccount &&
    !isInventoryItemAssemblyOrCategory(record.type)
  ) {
    errors.entity.push("expenseAccount");
    errors.messages.push(i18n("item.Error.ExpenseAccount"));
  }

  if (
    !record.id &&
    !record.serialTracking &&
    !record.lotTracking &&
    !record.hasVariants &&
    isInventoryOrAssembly &&
    record.initialLocation &&
    record.initialLocation.binTracking &&
    !record.initialBin
  ) {
    // be sure there's an initialBin if the initialLocation has binTracking
    errors.messages.push(i18n("item.Error.needInitialBin"));
    errors.entity.push("initialBin");
  }
  return errors;
}
