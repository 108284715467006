import { useDropzone } from "react-dropzone";

import { Typography, Box } from "@mui/material";

import { ActionButton } from "components/ActionButton";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";

const flexStyles = {
  display: "flex",
  gap: "1rem",
  alignItems: "center",
  justifyContent: "space-between",
};

export function FileSelector(props) {
  const { setRecord, record, setErrors, validator, highlightFileName } = props;

  const FILE_READER = new FileReader();

  function onDrop(files) {
    handleDocument(files[0]);
  }

  // set up drag and drop file selection
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  // convert uploaded file to base64
  function processLocalFile(e) {
    const base64 = window.btoa(e.target.result);
    setRecord((prevRecord) => ({ ...prevRecord, content: base64 }));
  }

  // user selects a file to be uploaded
  function handleDocumentChange(e) {
    setErrors((prev) => ({ ...prev, messages: [] }));
    const file = e.target.files[0];
    handleDocument(file);
  }

  // read in selected file and set callback for converting it to base64
  function handleDocument(file) {
    if (!file) {
      return;
    }
    if (!validator(file)) {
      setErrors((prev) => ({
        ...prev,
        messages: [i18n("file.FileTypeNotSupported", { fileType: file.type })],
      }));
      return;
    }
    FILE_READER.onload = processLocalFile;
    FILE_READER.readAsBinaryString(file);
    setRecord((prevRecord) => ({ ...prevRecord, fileName: file.name }));
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: Boolean(record.fileName) ? "30px" : "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "background.default",
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Typography>
          {i18n(
            `document.${isDragActive ? "DropDocumentHere" : "DragDropDocument"}`
          )}
        </Typography>
      </Box>
      <VSpace space={1} />
      <div style={flexStyles}>
        <div>
          {record.fileName && (
            <>
              {highlightFileName ? (
                <Typography variant="h5">{`${i18n("colon.Document")}  ${
                  record.fileName
                }`}</Typography>
              ) : (
                <>{`${i18n("colon.Document")}  ${record.fileName}`}</>
              )}
            </>
          )}
        </div>
        <ActionButton component="label" htmlFor="documentFile">
          {i18n("document.FileSelector")}
          <input
            value=""
            id="documentFile"
            type="file"
            style={{ display: "none" }}
            onChange={handleDocumentChange}
          />
        </ActionButton>
      </div>
    </>
  );
}
