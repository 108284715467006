import { Link as RouterLink } from "react-router-dom";

import { Link as MUILink } from "@mui/material";
import { styled } from "@mui/system";

const CustomLink = styled(MUILink)({
  color: "primaryLink",
  "&:visited": { color: "primaryLink" },
});

export function Link(props) {
  return <CustomLink component={RouterLink} underline="none" {...props} />;
}
