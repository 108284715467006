export function afterGet(record) {
  const newRecord = {
    ...record,
    buildNumber: {
      prefix: record.prefixBuild,
      nextNumber: record.nextBuildNumber,
    },
  };

  delete newRecord.prefixBuild;
  delete newRecord.nextBuildNumber;

  return newRecord;
}
