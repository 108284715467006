import { i18n } from "services/i18nService";

import { initialErrorState } from "views/common";

export function isValid(record) {
  const errors = initialErrorState();
  errors.entity = [];

  record.lines.forEach((_, index) => (errors.line[index] = []));

  record.lines.forEach(({ id, type }, index) => {
    if (type && !id) {
      errors.messages.push(i18n("isValid.MissingSelection", { index }));
      errors.line[index].push("selection");
    }
  });

  if (!record.id && (!record.fileName || !record.content)) {
    errors.messages.push(i18n("isValid.Document"));
    errors.entity.push("content");
  }

  return errors;
}
