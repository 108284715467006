import { useState } from "react";

import { Checkbox as MuiCheckbox } from "@mui/material";

export function SelectAllCheckbox(props) {
  const { setCheckedIds, reportJson } = props;

  const [selectAllState, setSelectAllState] = useState(false);

  function handleCheckChange() {
    const ids = selectAllState ? [] : reportJson.map((row) => row.item?.id);
    setCheckedIds(ids);
    setSelectAllState(!selectAllState);
  }

  return (
    <td style={{ textAlign: "center" }}>
      <MuiCheckbox
        sx={{ p: 0 }}
        checked={selectAllState}
        onChange={handleCheckChange}
      />
    </td>
  );
}
