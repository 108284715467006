import { Box } from "@mui/material";

const spinStyles = {
  display: "inline-block",
  cursor: "pointer",
  "& img": {
    transform: "rotate(180deg)",
    animation: "rotation 2s infinite linear",
    "@keyframes rotation": {
      from: { transform: "rotate(0deg)" },
      to: { transform: "rotate(360deg)" },
    },
  },
};

export function TopNavIcon(props) {
  const { image, functionText, onClick, refs, dataTesting, showSpin } = props;

  return (
    <Box
      onClick={onClick}
      refs={refs}
      data-testing={dataTesting}
      sx={
        showSpin ? spinStyles : { display: "inline-block", cursor: "pointer" }
      }
    >
      <img src={image} alt={functionText} title={functionText} height={25} />
    </Box>
  );
}
