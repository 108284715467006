import { useState } from "react";

import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

import { FrmCheckbox } from "components/formFields/frm/FrmCheckbox";
import { CloseX } from "components/utility/CloseX";

import { i18n } from "services/i18nService";

import { usePlans } from "hooks/usePlans";

import { ENTRY_TYPES } from "views/Calendar/calendarConfig";
import { getConfigForEntryType } from "views/Calendar/calendarConfig";

export function SettingsDialog(props) {
  const { close, settings: externalSettings, saveSettings } = props;

  const { hasAtLeastPlusPlan, hasAtLeastProPlan } = usePlans();

  const [settings, setSettings] = useState(externalSettings);

  function onValueChange(settingName, newValue) {
    setSettings({ ...settings, [settingName]: newValue });
  }

  return (
    <Dialog open={true} onClose={close} fullWidth maxWidth="xs">
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h3">
            {i18n("calendar.settingsHeader")}
          </Typography>
          <CloseX onClick={close} />
        </div>
      </DialogTitle>
      <DialogContent>
        {ENTRY_TYPES.map(({ label, planIncludesEntryType, typeString }, i) => {
          if (
            !planIncludesEntryType ||
            planIncludesEntryType(hasAtLeastPlusPlan, hasAtLeastProPlan)
          ) {
            return (
              <FrmCheckbox
                key={i}
                name={getConfigForEntryType(typeString).settingName}
                value={settings[getConfigForEntryType(typeString).settingName]}
                onValueChange={onValueChange}
                label={label}
                sx={{ display: "block" }}
              />
            );
          } else {
            return null;
          }
        })}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={close}>
          {i18n("button.Cancel")}
        </Button>
        <div style={{ position: "relative" }}>
          <Button
            data-testing="applySettings"
            color="secondary"
            onClick={() => saveSettings(settings)}
          >
            {i18n("global.Update")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
