import { FilterSelectField } from "components/formFields/FilterSelectField";

import { i18n } from "services/i18nService";

const STATUS_OPTIONS = [
  { id: "No", name: i18n("filter.status.Open") },
  { id: "Yes", name: i18n("filter.status.Closed") },
  { id: "Both", name: i18n("filter.All") },
];

export function EditClosedFilter(props) {
  const { filters, setFilters } = props;
  const { filterSettings } = filters;
  const { ShowClosed = "No" } = filterSettings;

  function handleChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        ShowClosed: value?.id || "",
      },
      startingRecord: 1,
    }));
  }
  return (
    <FilterSelectField
      options={STATUS_OPTIONS}
      onValueChange={handleChange}
      value={{ id: ShowClosed }}
      label={i18n("filter.Status")}
      dataTesting="statusFilter"
      disableClearable
    />
  );
}
