import { useMemo } from "react";

import { LineItemAutocompleteField } from "components/formFields/LineItemAutocompleteField";
import { LineItemErrorText } from "components/formFields/LineItemErrorText";
import { LineSubItemField } from "components/formFields/line/LineSubItemField";

import { i18n } from "services/i18nService";

import { ITEM_TYPES_REQUIRING_BINS } from "appConstants";

export function LineBin(props) {
  const {
    itemSelected,
    value,
    bins,
    onValueChange,
    location,
    itemType,
    label,
    name,
    error,
  } = props;

  const matchingBin = useMemo(
    () => (bins ? bins.find(({ id }) => value && value.id === id) : null),
    [bins, value]
  );

  const matchlessBin = value?.id && !matchingBin;

  function getValue() {
    if (matchingBin) {
      return matchingBin;
    }
    // because of timing issues, we may have a bin value that doesn't
    // match any of the current location bin possibilities; in that
    // case, set the value to "" to avoid a warning
    return matchlessBin ? value : "";
  }

  function getOptions() {
    if (matchlessBin) {
      return bins ? [...bins, value] : [value];
    }
    return bins;
  }

  if (!location?.binTracking) {
    return null;
  }

  if (itemSelected && bins && !bins.length) {
    return <LineItemErrorText value={i18n("bin.NoBins")} />;
  }

  return (
    (ITEM_TYPES_REQUIRING_BINS.includes(itemType) || value) && (
      <LineSubItemField label={label}>
        <div style={{ flex: "1 1 auto" }}>
          <LineItemAutocompleteField
            onValueChange={onValueChange}
            value={getValue() || null}
            name={name}
            options={getOptions()}
            error={error}
            dataTesting="bin"
          />
        </div>
      </LineSubItemField>
    )
  );
}
