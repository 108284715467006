import { Droppable, Draggable } from "react-beautiful-dnd";

import { Typography } from "@mui/material";

import { VSpace } from "components/utility/VSpace";

export function ColumnList(props) {
  const { heading, id, columns } = props;

  return (
    <>
      <Typography variant="h5">{heading}</Typography>
      <VSpace space={1} />
      <Droppable droppableId={id} key={id}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{
              paddingBottom: "2em",
              minHeight: "100%",
            }}
          >
            <div style={{ minHeight: "5em" }}>
              {columns.map((c, index) => (
                <Draggable
                  draggableId={c.apiName + index}
                  key={c.apiName + index}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Typography>{c.label || c.apiName}</Typography>
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </>
  );
}
