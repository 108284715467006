// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["quantity", "outputQuantity"],
  MONEY_FIELDS: [],
  DATE_FIELDS: ["txnDate"],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};
