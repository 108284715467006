export function normalizeLinkedTransaction(linkedTransaction) {
  if (
    linkedTransaction === null ||
    (linkedTransaction.refNumber === "" && !linkedTransaction.lineNumber)
  ) {
    return null;
  }

  const newLinkedTransaction = { ...linkedTransaction };
  if (typeof linkedTransaction.lineNumber !== "number") {
    newLinkedTransaction.lineNumber = 0;
  }
  return newLinkedTransaction;
}
