import WarningIcon from "@mui/icons-material/Warning";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { i18n } from "services/i18nService";

import { theme } from "SosTheme";

export function WarningModal(props) {
  const {
    title,
    message,
    open,
    onOk,
    onClose,
    onOkButtonText = i18n("global.Yes"),
    onCloseButtonText = i18n("global.No"),
  } = props;

  return (
    <Dialog open={open} onClose={onClose} disableScrollLock={true}>
      <DialogTitle>
        <span
          style={{
            color: theme.palette.warning.main,
            display: "inline-block",
            position: "relative",
            bottom: "-2px",
            marginRight: "5px",
          }}
        >
          <WarningIcon />
        </span>
        {title}
      </DialogTitle>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {onCloseButtonText}
        </Button>
        <Button color="secondary" onClick={onOk}>
          {onOkButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
