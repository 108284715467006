import { useState } from "react";

import Delete from "@mui/icons-material/Delete";
import { Box } from "@mui/material";

import { IN_TRANSACTION } from "appConfig";

import { LineTableCell } from "components/formFields/LineTableCell";
import { OcostAmount } from "components/formFields/ocost/OcostAmount";
import { OcostBill } from "components/formFields/ocost/OcostBill";
import { OcostClass } from "components/formFields/ocost/OcostClass";
import { OcostInventoryItem } from "components/formFields/ocost/OcostInventoryItem";
import { OcostVendor } from "components/formFields/ocost/OcostVendor";

import { getRecord } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";

export function OtherCost(props) {
  const {
    otherCost,
    otherCostHandler,
    items,
    vendors,
    classes,
    recordVendorId,
    itemsCount,
  } = props;

  const [lineLoading, setLineLoading] = useState(false);

  async function handleItemChange(newItem) {
    setLineLoading(true);
    const item = await getRecord("item", newItem.id, IN_TRANSACTION);
    if (item) {
      // transfer the appropriate properties from the new inventory
      // item to the line item
      const newOtherCost = {
        ...otherCost,
        item: newItem,
        class: item.class,
      };
      setPageDirty();
      otherCostHandler({ type: "update", updatedLine: newOtherCost });
    } else {
      handleProgramError(
        new Error("OtherCost, handleItemChange | no item returned from getItem")
      );
    }
    setLineLoading(false);
  }

  function handleFieldChange(fieldName, value) {
    const updatedLine = { ...otherCost, [fieldName]: value };

    // set Bill true if this vendor is the same as the main record
    if (fieldName === "vendor" && parseInt(value) === recordVendorId) {
      updatedLine.bill = true;
    }

    otherCostHandler({ type: "update", updatedLine });
  }

  return (
    <>
      <LineTableCell>
        <Box
          onClick={() => {
            setPageDirty();
            otherCostHandler({
              type: "delete",
              deleteAt: otherCost.lineNumber,
            });
          }}
          sx={{
            cursor: "pointer",
            textAlign: "center",
            color: "icons",
            pl: "0.1rem",
            pt: "0.25rem",
          }}
        >
          <Delete />
        </Box>
      </LineTableCell>
      <LineTableCell>
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
          }}
        >
          {otherCost.lineNumber}
        </div>
      </LineTableCell>
      <OcostInventoryItem
        line={otherCost}
        lineLoading={lineLoading}
        items={items}
        itemsCount={itemsCount}
        onValueChange={handleItemChange}
      />
      <OcostAmount value={otherCost.amount} onValueChange={handleFieldChange} />
      <OcostVendor
        value={otherCost.vendor}
        vendors={vendors}
        onValueChange={handleFieldChange}
      />

      <OcostBill value={otherCost.bill} onValueChange={handleFieldChange} />

      <OcostClass
        value={otherCost.class}
        classes={classes}
        onValueChange={handleFieldChange}
      />
    </>
  );
}
