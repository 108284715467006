export function beforeSave(record) {
  const newRecord = { ...record };

  if (!record.smsEnabled) {
    newRecord.mobileNumber = null;
  }

  newRecord.timeZone = record.timeZone.id;
  newRecord.dateFormat = record.dateFormat.id;
  newRecord.notificationMethod = record.notificationMethod.id;
  newRecord.passwordQuestion = record.passwordQuestion.id;
  newRecord.mobileCarrier = record.mobileCarrier.id;

  delete newRecord.smsEnabled;
  delete newRecord.confirmEmail;

  return newRecord;
}
