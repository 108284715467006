import { transformUoms } from "services/utility/afterGet";
import { transformJobWorkCenter } from "services/utility/jobWorkCenter";

export function moreTransactionAfterGet(record) {
  const newRecord = { ...record };

  newRecord.number = record.number === "auto" ? "" : record.number;

  // date can be null on a "populateFrom" API call
  if (!newRecord.date) {
    newRecord.date = new Date();
  }

  const newLines = record.lines.map((line) => {
    let newLine = { ...line };

    newLine.jobWorkcenter = transformJobWorkCenter(line.job, line.workcenter);

    if (line.itemDetails) {
      newLine.itemDetails.itemUoms = transformUoms(line.itemDetails.itemUoms);
    }

    // add property for related records
    newLine.relatedRecords = {};

    return newLine;
  });
  // update the record with the new lines
  newRecord.lines = newLines;
  return newRecord;
}
