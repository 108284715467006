import { ArrowDropDownCircleRounded } from "@mui/icons-material";
import { Box } from "@mui/material";

export function CopyDownLineItem(props) {
  const { onCopyDown } = props;
  return (
    <Box
      sx={{
        position: "absolute",
        top: "calc(100% - 0.4rem)",
        left: "calc(100% - 0.6rem)",
        display: "inline-block",
        cursor: "pointer",
        zIndex: "insertLine",
      }}
      onClick={onCopyDown}
      title="Copy the row above to the row below"
    >
      <ArrowDropDownCircleRounded
        sx={{ fontSize: "1rem", color: "icons" }}
        alt="copy the line above down to the line below"
      />
    </Box>
  );
}
