import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

import { ButtonProgress } from "components/utility/ButtonProgress";

import { i18n } from "services/i18nService";
import { refundPayment } from "services/sosInventoryService/sosApi";

import { openAlert } from "globalState/alertSlice";

export function BatchRefundDialog(props) {
  const { ids: externalIds, close } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  async function refund() {
    setIsLoading(true);
    const ids = externalIds.map((id) => ({ id }));
    const { success, message } = await refundPayment(ids);
    if (success) {
      close();
      dispatch(
        openAlert({
          type: "success",
          message: i18n("alert.RefundPaymentSuccess"),
        })
      );
    } else {
      dispatch(openAlert({ type: "error", message }));
    }

    setIsLoading(false);
  }

  return (
    <Dialog open={true} fullWidth maxWidth="xs" sx={{ inset: "0 0 40%" }}>
      <DialogTitle>{i18n("global.Refund")}</DialogTitle>
      <DialogContent>
        <Typography>{i18n("global.RefundSelectedRecords")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={close}>
          {i18n("button.Cancel")}
        </Button>
        <div style={{ position: "relative" }}>
          <Button color="secondary" disabled={isLoading} onClick={refund}>
            {i18n("global.Refund")}
          </Button>
          {isLoading && <ButtonProgress color="secondary" />}
        </div>
      </DialogActions>
    </Dialog>
  );
}
