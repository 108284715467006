import { FrmTextField } from "components/formFields/FrmTextField";

import { i18n } from "services/i18nService";

const {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} = require("@mui/material");

export function RenameCustomDialog(props) {
  const {
    renameCustomDialogOpen,
    setRenameCustomDialogOpen,
    customName,
    setCustomName,
    reportConfig,
    renameCustom,
  } = props;

  return (
    <Dialog
      open={renameCustomDialogOpen}
      onClose={() => setRenameCustomDialogOpen(false)}
    >
      <DialogTitle>{i18n("reports.Action.RenameCustom")}</DialogTitle>
      <DialogContent>
        <FrmTextField
          value={customName || reportConfig.name}
          onValueBlur={(_, newValue) => setCustomName(newValue)}
          label={i18n("reports.CustomScheduled.Name")}
          sx={{ minWidth: "25em" }}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <div></div>
        <div>
          <Button
            color="secondary"
            onClick={() => setRenameCustomDialogOpen(false)}
          >
            {i18n("button.Cancel")}
          </Button>
          <Button onClick={renameCustom} color="secondary">
            {i18n("global.Save")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
