import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

export function FrmCurrency(props) {
  const {
    value,
    currencies: externalCurrencies,
    onValueChange,
    error,
    disabled,
  } = props;

  const currencies = externalCurrencies?.map(({ code, id }) => ({
    id,
    name: code,
  }));

  return (
    <FrmSelectFromObjects
      disableClearable
      label={i18n("frmLabel.Currency")}
      value={value}
      minWidth="7rem"
      onValueChange={onValueChange}
      options={currencies}
      error={error}
      name="currency"
      disabled={disabled}
    />
  );
}
