import {
  LINE_ACTIONS as lineActionsAdjustment,
  BATCH_ACTIONS as batchActionsAdjustment,
} from "services/sosInventoryService/adjustment/schema";
import {
  LINE_ACTIONS as lineActionsAlert,
  BATCH_ACTIONS as batchActionsAlert,
} from "services/sosInventoryService/alert/schema";
import {
  LINE_ACTIONS as lineActionsAopRule,
  BATCH_ACTIONS as batchActionsAopRule,
} from "services/sosInventoryService/aopRule/schema";
import {
  LINE_ACTIONS as lineActionsBin,
  BATCH_ACTIONS as batchActionsBin,
} from "services/sosInventoryService/bin/schema";
import {
  LINE_ACTIONS as lineActionsBuild,
  BATCH_ACTIONS as batchActionsBuild,
} from "services/sosInventoryService/build/schema";
import {
  LINE_ACTIONS as lineActionsChannel,
  BATCH_ACTIONS as batchActionsChannel,
} from "services/sosInventoryService/channel/schema";
import {
  LINE_ACTIONS as lineActionsClass,
  BATCH_ACTIONS as batchActionsClass,
} from "services/sosInventoryService/class/schema";
import {
  LINE_ACTIONS as lineActionsCustomField,
  BATCH_ACTIONS as batchActionsCustomField,
} from "services/sosInventoryService/customField/schema";
import {
  LINE_ACTIONS as lineActionsCustomer,
  BATCH_ACTIONS as batchActionsCustomer,
} from "services/sosInventoryService/customer/schema";
import {
  LINE_ACTIONS as lineActionsCustomerMessage,
  BATCH_ACTIONS as batchActionsCustomerMessage,
} from "services/sosInventoryService/customerMessage/schema";
import {
  LINE_ACTIONS as lineActionsCustomerType,
  BATCH_ACTIONS as batchActionsCustomerType,
} from "services/sosInventoryService/customerType/schema";
import {
  LINE_ACTIONS as lineActionsDeletedItem,
  BATCH_ACTIONS as batchActionsDeletedItem,
} from "services/sosInventoryService/deletedItem/schema";
import {
  LINE_ACTIONS as lineActionsDepartment,
  BATCH_ACTIONS as batchActionsDepartment,
} from "services/sosInventoryService/department/schema";
import {
  LINE_ACTIONS as lineActionsDocument,
  BATCH_ACTIONS as batchActionsDocument,
} from "services/sosInventoryService/document/schema";
import {
  LINE_ACTIONS as lineActionsEstimate,
  BATCH_ACTIONS as batchActionsEstimate,
} from "services/sosInventoryService/estimate/schema";
import {
  LINE_ACTIONS as lineActionsFormTemplate,
  BATCH_ACTIONS as batchActionsFormTemplate,
} from "services/sosInventoryService/formTemplate/schema";
import {
  LINE_ACTIONS as lineActionsFsPayment,
  BATCH_ACTIONS as batchActionsFsPayment,
} from "services/sosInventoryService/fsPayment/schema";
import {
  LINE_ACTIONS as lineActionsInvoice,
  BATCH_ACTIONS as batchActionsInvoice,
} from "services/sosInventoryService/invoice/schema";
import {
  LINE_ACTIONS as lineActionsItem,
  BATCH_ACTIONS as batchActionsItem,
} from "services/sosInventoryService/item/schema";
import {
  LINE_ACTIONS as lineActionsItemReceipt,
  BATCH_ACTIONS as batchActionsItemReceipt,
} from "services/sosInventoryService/itemReceipt/schema";
import {
  LINE_ACTIONS as lineActionsJob,
  BATCH_ACTIONS as batchActionsJob,
} from "services/sosInventoryService/job/schema";
import {
  LINE_ACTIONS as lineActionsLocation,
  BATCH_ACTIONS as batchActionsLocation,
} from "services/sosInventoryService/location/schema";
import {
  LINE_ACTIONS as lineActionsLot,
  BATCH_ACTIONS as batchActionsLot,
} from "services/sosInventoryService/lot/schema";
import {
  LINE_ACTIONS as lineActionsOrderStage,
  BATCH_ACTIONS as batchActionsOrderStage,
} from "services/sosInventoryService/orderStage/schema";
import {
  LINE_ACTIONS as lineActionsPayment,
  BATCH_ACTIONS as batchActionsPayment,
} from "services/sosInventoryService/payment/schema";
import {
  LINE_ACTIONS as lineActionsPaymentMethod,
  BATCH_ACTIONS as batchActionsPaymentMethod,
} from "services/sosInventoryService/paymentMethod/schema";
import {
  LINE_ACTIONS as lineActionsPickTicket,
  BATCH_ACTIONS as batchActionsPickTicket,
} from "services/sosInventoryService/pickTicket/schema";
import {
  LINE_ACTIONS as lineActionsPriceTier,
  BATCH_ACTIONS as batchActionsPriceTier,
} from "services/sosInventoryService/priceTier/schema";
import {
  LINE_ACTIONS as lineActionsPriority,
  BATCH_ACTIONS as batchActionsPriority,
} from "services/sosInventoryService/priority/schema";
import {
  LINE_ACTIONS as lineActionsProcess,
  BATCH_ACTIONS as batchActionsProcess,
} from "services/sosInventoryService/process/schema";
import {
  LINE_ACTIONS as lineActionsProcessTemplate,
  BATCH_ACTIONS as batchActionsProcessTemplate,
} from "services/sosInventoryService/processTemplate/schema";
import {
  LINE_ACTIONS as lineActionsShipMethod,
  BATCH_ACTIONS as batchActionsShipMethod,
} from "services/sosInventoryService/processTemplate/schema";
import {
  LINE_ACTIONS as lineActionsPurchaseOrder,
  BATCH_ACTIONS as batchActionsPurchaseOrder,
} from "services/sosInventoryService/purchaseOrder/schema";
import {
  LINE_ACTIONS as lineActionsRental,
  BATCH_ACTIONS as batchActionsRental,
} from "services/sosInventoryService/rental/schema";
import {
  LINE_ACTIONS as lineActionsRentalReturn,
  BATCH_ACTIONS as batchActionsRentalReturn,
} from "services/sosInventoryService/rentalReturn/schema";
import {
  LINE_ACTIONS as lineActionsReorder,
  BATCH_ACTIONS as batchActionsReorder,
} from "services/sosInventoryService/reorder/schema";
import {
  LINE_ACTIONS as lineActionsReturn,
  BATCH_ACTIONS as batchActionsReturn,
} from "services/sosInventoryService/return/schema";
import {
  LINE_ACTIONS as lineActionsReturnToVendor,
  BATCH_ACTIONS as batchActionsReturnToVendor,
} from "services/sosInventoryService/returnToVendor/schema";
import {
  LINE_ACTIONS as lineActionsRma,
  BATCH_ACTIONS as batchActionsRma,
} from "services/sosInventoryService/rma/schema";
import {
  LINE_ACTIONS as lineActionsSalesOrder,
  BATCH_ACTIONS as batchActionsSalesOrder,
} from "services/sosInventoryService/salesOrder/schema";
import {
  LINE_ACTIONS as lineActionsSalesReceipt,
  BATCH_ACTIONS as batchActionsSalesReceipt,
} from "services/sosInventoryService/salesReceipt/schema";
import {
  LINE_ACTIONS as lineActionsSalesRep,
  BATCH_ACTIONS as batchActionsSalesRep,
} from "services/sosInventoryService/salesRep/schema";
import {
  LINE_ACTIONS as lineActionsUom,
  BATCH_ACTIONS as batchActionsUom,
} from "services/sosInventoryService/salesRep/schema";
import {
  LINE_ACTIONS as lineActionsSerial,
  BATCH_ACTIONS as batchActionsSerial,
} from "services/sosInventoryService/serial/schema";
import {
  LINE_ACTIONS as lineActionsShipment,
  BATCH_ACTIONS as batchActionsShipment,
} from "services/sosInventoryService/shipment/schema";
import {
  LINE_ACTIONS as lineActionsSyncItem,
  BATCH_ACTIONS as batchActionsSyncItem,
} from "services/sosInventoryService/syncItem/schema";
import {
  LINE_ACTIONS as lineActionsTag,
  BATCH_ACTIONS as batchActionsTag,
} from "services/sosInventoryService/tag/schema";
import {
  LINE_ACTIONS as lineActionsTask,
  BATCH_ACTIONS as batchActionsTask,
} from "services/sosInventoryService/task/schema";
import {
  LINE_ACTIONS as lineActionsTemplateLibrary,
  BATCH_ACTIONS as batchActionsTemplateLibrary,
} from "services/sosInventoryService/templateLibrary/schema";
import {
  LINE_ACTIONS as lineActionsTerm,
  BATCH_ACTIONS as batchActionsTerm,
} from "services/sosInventoryService/term/schema";
import {
  LINE_ACTIONS as lineActionsTransfer,
  BATCH_ACTIONS as batchActionsTransfer,
} from "services/sosInventoryService/transfer/schema";
import {
  LINE_ACTIONS as lineActionsUpsShipment,
  BATCH_ACTIONS as batchActionsUpsShipment,
} from "services/sosInventoryService/upsShipment/schema";
import {
  LINE_ACTIONS as lineActionsUser,
  BATCH_ACTIONS as batchActionsUser,
} from "services/sosInventoryService/user/schema";
import {
  LINE_ACTIONS as lineActionsVendor,
  BATCH_ACTIONS as batchActionsVendor,
} from "services/sosInventoryService/vendor/schema";
import {
  LINE_ACTIONS as lineActionsVendorItem,
  BATCH_ACTIONS as batchActionsVendorItem,
} from "services/sosInventoryService/vendorItem/schema";
import {
  LINE_ACTIONS as lineActionsVendorMessage,
  BATCH_ACTIONS as batchActionsVendorMessage,
} from "services/sosInventoryService/vendorMessage/schema";
import {
  LINE_ACTIONS as lineActionsWarranty,
  BATCH_ACTIONS as batchActionsWarranty,
} from "services/sosInventoryService/warranty/schema";
import {
  LINE_ACTIONS as lineActionsWorkCenter,
  BATCH_ACTIONS as batchActionsWorkCenter,
} from "services/sosInventoryService/workCenter/schema";
import {
  LINE_ACTIONS as lineActionsWorkOrder,
  BATCH_ACTIONS as batchActionsWorkOrder,
} from "services/sosInventoryService/workOrder/schema";
import {
  LINE_ACTIONS as lineActionsWorker,
  BATCH_ACTIONS as batchActionsWorker,
} from "services/sosInventoryService/worker/schema";

export const LINE_ACTIONS = {
  adjustment: lineActionsAdjustment,
  alert: lineActionsAlert,
  aoprule: lineActionsAopRule,
  bins: lineActionsBin,
  build: lineActionsBuild,
  channel: lineActionsChannel,
  class: lineActionsClass,
  customer: lineActionsCustomer,
  deleteditem: lineActionsDeletedItem,
  department: lineActionsDepartment,
  document: lineActionsDocument,
  estimate: lineActionsEstimate,
  formtemplate: lineActionsFormTemplate,
  fspayment: lineActionsFsPayment,
  invoice: lineActionsInvoice,
  item: lineActionsItem,
  itemreceipt: lineActionsItemReceipt,
  job: lineActionsJob,
  location: lineActionsLocation,
  lot: lineActionsLot,
  payment: lineActionsPayment,
  pickticket: lineActionsPickTicket,
  process: lineActionsProcess,
  processtemplate: lineActionsProcessTemplate,
  purchaseorder: lineActionsPurchaseOrder,
  returntovendor: lineActionsReturnToVendor,
  salesorder: lineActionsSalesOrder,
  salesreceipt: lineActionsSalesReceipt,
  salesrep: lineActionsSalesRep,
  serial: lineActionsSerial,
  shipment: lineActionsShipment,
  shipmethod: lineActionsShipMethod,
  syncitem: lineActionsSyncItem,
  task: lineActionsTask,
  transfer: lineActionsTransfer,
  rental: lineActionsRental,
  reorder: lineActionsReorder,
  return: lineActionsReturn,
  rentalreturn: lineActionsRentalReturn,
  rma: lineActionsRma,
  vendor: lineActionsVendor,
  customfield: lineActionsCustomField,
  customermessage: lineActionsCustomerMessage,
  customertype: lineActionsCustomerType,
  priority: lineActionsPriority,
  pricetier: lineActionsPriceTier,
  orderstage: lineActionsOrderStage,
  tag: lineActionsTag,
  terms: lineActionsTerm,
  templatelibrary: lineActionsTemplateLibrary,
  uom: lineActionsUom,
  upsshipment: lineActionsUpsShipment,
  user: lineActionsUser,
  vendoritem: lineActionsVendorItem,
  vendormessage: lineActionsVendorMessage,
  warranty: lineActionsWarranty,
  workcenter: lineActionsWorkCenter,
  worker: lineActionsWorker,
  paymentmethod: lineActionsPaymentMethod,
  workorder: lineActionsWorkOrder,
};

export const BATCH_ACTIONS = {
  adjustment: batchActionsAdjustment,
  alert: batchActionsAlert,
  aoprule: batchActionsAopRule,
  bins: batchActionsBin,
  build: batchActionsBuild,
  channel: batchActionsChannel,
  class: batchActionsClass,
  deleteditem: batchActionsDeletedItem,
  document: batchActionsDocument,
  customer: batchActionsCustomer,
  customfield: batchActionsCustomField,
  customermessage: batchActionsCustomerMessage,
  customertype: batchActionsCustomerType,
  department: batchActionsDepartment,
  estimate: batchActionsEstimate,
  formtemplate: batchActionsFormTemplate,
  fspayment: batchActionsFsPayment,
  invoice: batchActionsInvoice,
  item: batchActionsItem,
  itemreceipt: batchActionsItemReceipt,
  job: batchActionsJob,
  location: batchActionsLocation,
  lot: batchActionsLot,
  orderstage: batchActionsOrderStage,
  payment: batchActionsPayment,
  paymentmethod: batchActionsPaymentMethod,
  pickticket: batchActionsPickTicket,
  process: batchActionsProcess,
  processtemplate: batchActionsProcessTemplate,
  priority: batchActionsPriority,
  pricetier: batchActionsPriceTier,
  purchaseorder: batchActionsPurchaseOrder,
  reorder: batchActionsReorder,
  returntovendor: batchActionsReturnToVendor,
  salesorder: batchActionsSalesOrder,
  salesreceipt: batchActionsSalesReceipt,
  salesrep: batchActionsSalesRep,
  serial: batchActionsSerial,
  shipment: batchActionsShipment,
  shipmethod: batchActionsShipMethod,
  syncitem: batchActionsSyncItem,
  task: batchActionsTask,
  transfer: batchActionsTransfer,
  rental: batchActionsRental,
  return: batchActionsReturn,
  rma: batchActionsRma,
  vendor: batchActionsVendor,
  rentalreturn: batchActionsRentalReturn,
  tag: batchActionsTag,
  terms: batchActionsTerm,
  templatelibrary: batchActionsTemplateLibrary,
  uom: batchActionsUom,
  upsshipment: batchActionsUpsShipment,
  user: batchActionsUser,
  vendoritem: batchActionsVendorItem,
  vendormessage: batchActionsVendorMessage,
  warranty: batchActionsWarranty,
  workcenter: batchActionsWorkCenter,
  worker: batchActionsWorker,
  workorder: batchActionsWorkOrder,
};
