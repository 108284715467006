import { Decimal, Money } from "classes/DecimalClasses";

import { afterGet as globalAfterGet } from "services/sosInventoryService/afterGet";
import {
  FIELDS_TO_TRANSFORM,
  UOM_FIELDS_TO_TRANSFORM,
} from "services/sosInventoryService/item/schema";
import { transformFields } from "services/utility/afterGet";
import { getBaseUom } from "services/utility/uoms";

export function afterGet(record) {
  const newRecord = transformFields(record, FIELDS_TO_TRANSFORM);

  // rename field to eliminate outdated "manufacturing" terminology
  newRecord.showOnProductionForms = record.showOnManufacturingForms;
  delete newRecord.showOnManufacturingForms;

  // if record contains locationBins property, transform the quantity
  // field to a Decimal

  if (record.locationBins) {
    newRecord.locationBins = record.locationBins.map((lb) => ({
      ...lb,
      quantity: new Decimal(lb.quantity),
    }));
  }

  if (record.uoms) {
    //goes through each uom and converts the UOM_MONEY_FIELDS to Money class
    newRecord.uoms = record.uoms.map((uom) =>
      transformFields(uom, UOM_FIELDS_TO_TRANSFORM)
    );

    newRecord.baseUOM = getBaseUom(record.uoms);
  }

  // Convert PriceTier number values to Decimal class
  if (record.priceTiers) {
    newRecord.priceTiers = record.priceTiers.map((priceTier) => {
      const { percentDiscount, price, quantity } = priceTier;
      return {
        ...priceTier,
        percentDiscount: new Decimal(percentDiscount),
        price: new Money(price),
        quantity: new Decimal(quantity),
      };
    });
  }

  // make tags into array
  newRecord.tags = record.tags ? record.tags.split(",") : [];

  return globalAfterGet(newRecord);
}
