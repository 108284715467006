import { Box } from "@mui/material";

import { theme } from "SosTheme.js";

// props space given in units of the theme spacing
export function VSpace(props) {
  const { space } = props;

  return <Box height={theme.spacing(space)} />;
}
