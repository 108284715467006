import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { DatePicker } from "components/html/Input/DatePicker";
import { GroupingBox } from "components/utility/GroupingBox";

import { i18n } from "services/i18nService";
import {
  endOfReportQuickDates,
  startOfReportQuickDates,
} from "services/utility/dateFilter";

const reportQuickDates = {
  startOf: startOfReportQuickDates,
  endOf: endOfReportQuickDates,
};
const MINIMUM_GRID_COLUMN_WIDTH = "170px";

const columnGapInPixels = "24px";

export function DateFilter(props) {
  const {
    macroValue,
    dateValue,
    onValueChange,
    label,
    name,
    macroName,
    oneYearMax,
    datePrefix,
  } = props;

  function handleQuickDateChange(_, newQuickDate) {
    if (!newQuickDate) {
      onValueChange(macroName, null);
      onValueChange(name, new Date());
      return;
    }
    const quickDate = reportQuickDates[datePrefix].find(
      ({ id }) => id === newQuickDate.id
    );
    onValueChange(macroName, quickDate);
    onValueChange(name, quickDate.date());
  }

  function handleDateChange(name, asOfDate) {
    onValueChange(macroName, null);
    onValueChange(name, asOfDate);
  }

  return (
    <GroupingBox
      heading={oneYearMax ? `${label} (${i18n("reports.oneYearMax")})` : label}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(auto-fit, minmax(max(${MINIMUM_GRID_COLUMN_WIDTH}, calc((100% - ${columnGapInPixels}) / 2)), 1fr)`,
          columnGap: columnGapInPixels,
          gridAutoFlow: "dense",
          alignItems: "start",
        }}
      >
        <FrmSelectFromObjects
          value={macroValue}
          onValueChange={handleQuickDateChange}
          options={reportQuickDates[datePrefix]}
          placeholder={i18n("global.QuickDateSelector")}
          sx={{ minWidth: "12rem", marginTop: "0.5rem", mt: 0 }}
        />
        <DatePicker
          key={dateValue}
          name={name}
          value={dateValue}
          onValueChange={handleDateChange}
          textSx={{ width: "100%" }}
          dataTesting={name}
        />
      </div>
    </GroupingBox>
  );
}
