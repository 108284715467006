import { useState } from "react";
import { useSelector } from "react-redux";

import { ViewColumn } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { EditColumns } from "components/ListPage/Widgets/EditDisplayResults/EditColumns";
import { ButtonProgress } from "components/utility/ButtonProgress";

import { i18n } from "services/i18nService";
import { isElementAddedToOldArray } from "services/utility/list";

export function Columns(props) {
  const {
    objectType,
    setColumnParams,
    allColumns,
    retrievalParams,
    setRetrievalParams,
    columnConfig,
  } = props;

  const loadingState = useSelector((state) => state.loading.list);

  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState(retrievalParams.columns.columns);

  function handleClickOpen() {
    setColumns(retrievalParams.columns);
    setOpen(true);
  }

  function onConfirm() {
    setRetrievalParams((prev) => ({ ...prev, columns, persistUpdate: true }));
    if (isElementAddedToOldArray(retrievalParams.columns, columns)) {
      setColumnParams(columns);
    }
    setOpen(false);
  }

  function resetDefaults() {
    setColumns(columnConfig.filter((c) => c.default));
  }

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <ViewColumn sx={{ marginRight: "0.5rem" }} />
        {i18n("global.Columns")}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
        sx={{ height: "80%", minHeight: "80%" }}
      >
        <DialogTitle>{i18n("columnSelect.Heading")}</DialogTitle>
        <DialogContent>
          <EditColumns
            key={open}
            objectType={objectType}
            columns={columns}
            setColumns={setColumns}
            allColumns={allColumns}
          />
        </DialogContent>

        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Button color="secondary" onClick={resetDefaults}>
              {i18n("button.Reset")}
            </Button>
          </div>
          <div style={{ display: "flex" }}>
            <Button color="secondary" onClick={() => setOpen(false)}>
              {i18n("button.Cancel")}
            </Button>
            <div style={{ position: "relative" }}>
              <Button
                color="secondary"
                onClick={onConfirm}
                disabled={loadingState}
              >
                {i18n("button.Apply")}
              </Button>
              {loadingState && <ButtonProgress color="secondary" />}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
