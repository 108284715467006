import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { IMAGE_DIMENSION } from "appConfig";

import { ImageFromBase64 } from "components/ImageFromBase64";
import { ButtonProgress } from "components/utility/ButtonProgress";

import { i18n } from "services/i18nService";
import { getRecord } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

export function ImageDialog(props) {
  const { open, onClose, objectType, id, name } = props;
  const [base64String, setBase64String] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchImage() {
      let record;
      try {
        record = await getRecord(objectType, id);
        setBase64String(record.imageAsBase64String);
      } catch (e) {
        handleProgramError(e);
      }
    }
    fetchImage();
  }, [id, objectType, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      sx={{ inset: "0 0 30%" }}
    >
      <DialogTitle>{name}</DialogTitle>
      <DialogContent>
        {base64String ? (
          <div style={{ textAlign: "center" }}>
            <ImageFromBase64
              maxWidth={IMAGE_DIMENSION}
              maxHeight={IMAGE_DIMENSION}
              base64String={base64String}
            />
          </div>
        ) : (
          <div style={{ minHeight: IMAGE_DIMENSION }}>
            <ButtonProgress size="3em" />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {i18n("button.Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
