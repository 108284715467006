import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { isPersistedRecord } from "services/utility/misc";

import { initialErrorState } from "views/common";

import { ITEM_TYPES_REQUIRING_BINS } from "appConstants";

export function isValid(record, relatedRecords, lines) {
  const errors = initialErrorState();

  errors.entity = [];
  lines.forEach((line) => {
    errors.line[line.lineNumber] = [];
  });

  // if the record has already been persisted, it must have a reference
  // number
  if (isPersistedRecord(record.id) && record.number === "") {
    errors.messages.push(i18n("isValid.ProvideReferenceNumber"));
    errors.entity.push("number");
  }

  // has a valid date
  if (!(record.date instanceof Date)) {
    errors.messages.push(i18n("isValid.Date"));
    errors.entity.push("date");
  }

  lines.forEach((line) => {
    // a bin has been chosen, if the item and location require it
    if (
      relatedRecords.location?.binTracking &&
      line.relatedRecords.item &&
      ITEM_TYPES_REQUIRING_BINS.includes(line.relatedRecords.item?.type) &&
      !line.bin?.id
    ) {
      errors.messages.push(
        i18n("isValid.LineItem.MissingBin", {
          lineNumber: line.lineNumber,
        })
      );
      errors.line[line.lineNumber].push("bin");
    }

    // if not auto-numbered, number of serial numbers
    // must match absolute value of quantity
    if (line.relatedRecords.item?.serialTracking && !record.autoSerialLots) {
      const numNonBlankSerials = line.serials.filter(
        (serial) => serial.name !== ""
      ).length;
      if (!new Decimal(numNonBlankSerials).eq(line.quantityDiff.abs())) {
        errors.messages.push(
          i18n("isValid.LineItem.QuantitySerialsMismatch", {
            lineNumber: line.lineNumber,
          })
        );
        errors.line[line.lineNumber].push("quantityDiff", "serials");
      }
    }
  });

  return errors;
}
