import { QUICK_VIEW_CONFIG as adjustmentConfig } from "services/sosInventoryService/adjustment/schema";
import { QUICK_VIEW_CONFIG as buildConfig } from "services/sosInventoryService/build/schema";
import { QUICK_VIEW_CONFIG as estimateConfig } from "services/sosInventoryService/estimate/schema";
import { QUICK_VIEW_CONFIG as invoiceConfig } from "services/sosInventoryService/invoice/schema";
import { QUICK_VIEW_CONFIG as itemReceiptConfig } from "services/sosInventoryService/itemReceipt/schema";
import { QUICK_VIEW_CONFIG as paymentConfig } from "services/sosInventoryService/payment/schema";
import { QUICK_VIEW_CONFIG as pickTicketConfig } from "services/sosInventoryService/pickTicket/schema";
import { QUICK_VIEW_CONFIG as processConfig } from "services/sosInventoryService/process/schema";
import { QUICK_VIEW_CONFIG as purchaseOrderConfig } from "services/sosInventoryService/purchaseOrder/schema";
import { QUICK_VIEW_CONFIG as rentalConfig } from "services/sosInventoryService/rental/schema";
import { QUICK_VIEW_CONFIG as rentalReturnConfig } from "services/sosInventoryService/rentalReturn/schema";
import { QUICK_VIEW_CONFIG as returnConfig } from "services/sosInventoryService/return/schema";
import { QUICK_VIEW_CONFIG as returnToVendorConfig } from "services/sosInventoryService/returnToVendor/schema";
import { QUICK_VIEW_CONFIG as rmaConfig } from "services/sosInventoryService/rma/schema";
import { QUICK_VIEW_CONFIG as salesOrderConfig } from "services/sosInventoryService/salesOrder/schema";
import { QUICK_VIEW_CONFIG as salesReceiptConfig } from "services/sosInventoryService/salesReceipt/schema";
import { QUICK_VIEW_CONFIG as shipmentConfig } from "services/sosInventoryService/shipment/schema";
import { QUICK_VIEW_CONFIG as transferConfig } from "services/sosInventoryService/transfer/schema";
import { QUICK_VIEW_CONFIG as workOrderConfig } from "services/sosInventoryService/workOrder/schema";

export const QUICK_VIEW_CONFIG = {
  adjustment: adjustmentConfig,
  build: buildConfig,
  estimate: estimateConfig,
  invoice: invoiceConfig,
  itemreceipt: itemReceiptConfig,
  payment: paymentConfig,
  pickticket: pickTicketConfig,
  process: processConfig,
  purchaseorder: purchaseOrderConfig,
  rental: rentalConfig,
  rentalreturn: rentalReturnConfig,
  return: returnConfig,
  returntovendor: returnToVendorConfig,
  rma: rmaConfig,
  salesorder: salesOrderConfig,
  salesreceipt: salesReceiptConfig,
  shipment: shipmentConfig,
  transfer: transferConfig,
  workorder: workOrderConfig,
};
