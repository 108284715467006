import { Box } from "@mui/material";

export function FieldHelperText(props) {
  const { color } = props;
  return (
    <Box
      sx={{
        fontSize: "0.75rem",
        color,
        margin: "0 0.7rem 0 0.7rem",
        fontWeight: 400,
        whiteSpace: "normal",
      }}
    >
      {props.children}
    </Box>
  );
}
