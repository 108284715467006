import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

export function FrmCustomerNotes(props) {
  const { onValueChange, value, ...unexpected } = props;
  checkForUnexpectedProps("FrmCustomerNotes", unexpected);

  return (
    <FrmResizeTextField
      value={value}
      name="customerNotes"
      minRows={2}
      maxRows={4}
      width="60ch"
      label={i18n("customer.labelCustomerNotes")}
      onValueBlur={onValueChange}
      dataTesting="customerNotes"
    />
  );
}
