import { i18n } from "services/i18nService";

import { initialErrorState } from "views/common";

import { SOS_PAY_CREDIT, SOS_PAY_ACH } from "appConstants";

export function isValid(record, _, lines) {
  const errors = initialErrorState();
  errors.entity = [];

  const { paymentMethod, customer } = record;

  lines.forEach(({ lineNumber }) => (errors.line[lineNumber] = []));

  if (!customer) {
    errors.messages.push(i18n("isValid.Customer"));
    errors.entity.push("customer");
  }

  if (!paymentMethod) {
    errors.messages.push(i18n("isValid.PaymentMethodRequired"));
    errors.entity.push("paymentMethod");
  }

  if (paymentMethod?.sosPayType === SOS_PAY_CREDIT && !record.paymentInfo) {
    errors.messages.push(i18n("isValid.EnterCreditInfoThroughSOSPay"));
    errors.entity.push("paymentMethod");
  }

  if (paymentMethod?.sosPayType === SOS_PAY_ACH && !record.paymentInfo) {
    errors.messages.push(i18n("isValid.EnterACHInfoThroughSOSPay"));
    errors.entity.push("paymentMethod");
  }

  return errors;
}
