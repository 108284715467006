export const SETTINGS = [
  { apiName: "estimateText", component: "FrmTextField", maxLength: 50 },
  {
    apiName: "estimateNumber",
    component: "FrmTransactionNumber",
    transactionType: "estimate",
    hasInputLabel: true,
  },
  { apiName: "replyEmailEstimate", component: "FrmEmail", maxLength: 255 },
  { apiName: "estimateEmail", component: "FrmResizeTextField" },
  { apiName: "estimateFooter", component: "FrmResizeTextField" },
  { apiName: "defaultCCEstimate", component: "FrmEmail", maxLength: 255 },
];
