import { ActionButton } from "components/ActionButton";
import { FrmTextField } from "components/formFields/FrmTextField";

import { i18n } from "services/i18nService";

export function FrmMultiplier(props) {
  const { value, onValueChange, onApply, maxWidth } = props;

  return (
    <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
      <FrmTextField
        name="multiplier"
        sx={{ maxWidth }}
        value={value}
        onValueBlur={onValueChange}
        label={i18n("frmLabel.Multiplier")}
        dataTesting="multiplier"
        number
      />
      <ActionButton
        dataTesting="multiplierApply"
        sx={{ marginTop: "0.3em" }}
        onClick={onApply}
      >
        {i18n("global.Apply")}
      </ActionButton>
    </div>
  );
}
