import { Component } from "react";

import { Box, Typography, Card } from "@mui/material";

import { i18n } from "services/i18nService";
import { handleProgramError } from "services/utility/errors";

import { closeAlert } from "globalState/alertSlice";
import globalState from "globalState/globalState";

const sx = {
  margin: "1rem 1rem",
  padding: "1rem 2rem",
  width: "700px",
  height: "auto",
  "& div": { textAlign: "left" },
  "& a": { color: "primaryLink" },
  display: "inline-block",
};

export function ErrorPage(props) {
  const { error, isInBody } = props;

  const wrapperSx = {
    backgroundImage: `url(${process.env.REACT_APP_V9_URL}/images/error.png)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    width: "100vw",
    position: "relative",
    gridArea: "main",
    textAlign: isInBody ? "left" : "center",
  };

  return (
    <Box sx={wrapperSx}>
      <Card sx={sx} data-testing="errorPage">
        <div style={{ margin: "1rem 0" }}>
          <Typography>
            {i18n("error.global.1")}{" "}
            <a href="mailto:support@sosinventory.com">
              support@sosinventory.com
            </a>{" "}
            {i18n("error.global.2")}
          </Typography>
          <div>
            <Typography sx={{ mt: 2, textAlign: "left" }}>
              {i18n("error.global.3")}
            </Typography>
            <Typography sx={{ mt: 0.5 }}>{error.toString()}</Typography>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <a href="/">{i18n("error.GoToTheSOSInventoryHomePage")}</a>
        </div>
      </Card>
    </Box>
  );
}

export class ErrorHandler extends Component {
  state = { hasError: false, error: null };

  static getDerivedStateFromError(error) {
    return {error};
  }

  componentDidCatch(error, _) {
    handleProgramError(error)
  }

  componentDidUpdate(prevProps) {
    if ((this.props.pathname !== prevProps.pathname) && this.state.error) {
      globalState.dispatch(closeAlert());
      this.setState({ hasError: false, error: null });
    }
  }

  render() {
    return this.state.error ? (
      <ErrorPage isInBody={this.props.isInBody} error={this.state.error} />
    ) : (
      this.props.children
    );
  }
}
