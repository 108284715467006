import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmContactMessageSelector } from "components/formFields/frm/FrmContactMessageSelector";

import { i18n } from "services/i18nService";

export function FrmVendorMessage(props) {
  const { value, onValueChange } = props;

  return (
    <>
      <FrmContactMessageSelector
        onChange={(value) => onValueChange("vendorMessage", value.message)}
        type="vendor"
      />
      <FrmResizeTextField
        name="vendorMessage"
        value={value}
        minRows={2}
        width="100%"
        label={i18n("frmLabel.VendorMessage")}
        onValueBlur={onValueChange}
      />
    </>
  );
}
