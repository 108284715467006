import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import {
  formatNameLinkField,
  formatBooleanYesOrBlank,
  formatAdmin,
} from "services/utility/formatting";

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: formatNameLinkField,
    fixed: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "email",
    heading: i18n("columns.Email"),
    minWidth: "12em",
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "lastLogin",
    heading: i18n("columns.LastLogin"),
    formatFunc: formatDate,
    minWidth: "12em",
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "active",
    heading: i18n("columns.Active"),
    formatFunc: formatBooleanYesOrBlank,
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "admin",
    heading: i18n("columns.Admin"),
    formatFunc: formatAdmin,
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
  },
  {
    name: "locked",
    heading: i18n("columns.Locked"),
    formatFunc: formatBooleanYesOrBlank,
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
  },
];

export const LINE_ACTIONS = ["activate", "unlock"];
export const BATCH_ACTIONS = ["activate"];

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: [],
  DATE_FIELDS: ["lastLogin"],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export function getEmptyRecord() {
  return {};
}
