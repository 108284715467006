import { i18n } from "services/i18nService";

const OTHER_OPTION = { id: "Other", name: i18n("global.Other") };
const BILLING_OPTION = { id: "Billing", name: i18n("global.Billing") };
const SHIPPING_OPTION = { id: "Shipping", name: i18n("global.Shipping") };

export const CUSTOMER_ADDRESS_SKELETON = {
  lineId: null,
  lineNumber: null,
  label: SHIPPING_OPTION,
  defaultType: null,
  addressInfo: {
    company: null,
    contact: null,
    phone: null,
    email: null,
    addressName: null,
    addressType: null,
    address: {
      line1: null,
      line2: null,
      line3: null,
      line4: null,
      line5: null,
      city: null,
      stateProvince: null,
      postalCode: null,
      country: null,
    },
  },
};

export const VENDOR_ADDRESS_SKELETON = {
  lineId: null,
  label: { id: "Other", name: i18n("global.Other") },
  defaultType: null,
  addressInfo: {
    company: null,
    contact: null,
    phone: null,
    email: null,
    addressName: null,
    addressType: null,
    address: {
      line1: null,
      line2: null,
      line3: null,
      line4: null,
      line5: null,
      city: null,
      stateProvince: null,
      postalCode: null,
      country: null,
    },
  },
};

export const LOCATION_ADDRESS_SKELETON = {
  customer: CUSTOMER_ADDRESS_SKELETON,
  vendor: VENDOR_ADDRESS_SKELETON,
};

export const CUSTOMER_ADDRESS_OPTIONS = [
  BILLING_OPTION,
  SHIPPING_OPTION,
  OTHER_OPTION,
];

export const VENDOR_ADDRESS_OPTIONS = [OTHER_OPTION];

export const LOCATION_OPTIONS = {
  customer: CUSTOMER_ADDRESS_OPTIONS,
  vendor: VENDOR_ADDRESS_OPTIONS,
};
