import { useState, memo } from "react";

import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

function Star_(props) {
  const { value, stars, updateFunc } = props;
  const numStarStates = stars.length;

  const [state, setState] = useState(value);

  const stateButtons = [<State0 color={stars[0]} />];
  for (let i = 1; i < numStarStates; i++) {
    stateButtons.push(<StateX color={stars[i]} />);
  }

  function changeState() {
    const newState = (state + 1) % numStarStates;
    setState(newState);
    updateFunc(newState);
  }

  return (
    <div
      style={{ height: "24px", width: "24px", margin: 0 }}
      onClick={changeState}
    >
      {stateButtons[state]}
    </div>
  );
}

const State0 = function (props) {
  const { color } = props;
  return <StarBorderIcon sx={{ color: color, fontSize: "24px" }} />;
};

const StateX = function (props) {
  const { color } = props;
  return <StarIcon sx={{ color: color, fontSize: "24px", margin: 0 }} />;
};

export const Star = memo(Star_);
