import { i18n } from "services/i18nService";
import {
  CUSTOM_FIELD_ALERT_TYPE,
  LOW_INVENTORY_ALERT_TYPE,
  SEND_EMAIL_OPTION,
  SEND_TEXT_OPTION,
} from "services/sosInventoryService/alert/schema";
import { isValidEmail } from "services/utility/validation";

import { initialErrorState } from "views/common";

export function isValid(record) {
  const errors = initialErrorState();
  errors.entity = [];

  if (!record.name || !record.name.trim().length) {
    errors.messages.push(i18n("isValid.Name"));
    errors.entity.push("name");
  }

  if (record.type === CUSTOM_FIELD_ALERT_TYPE.id && !record.customField?.id) {
    errors.messages.push(i18n("isValid.CustomFieldField"));
    errors.entity.push("customFieldField");
  }

  if (record.type === CUSTOM_FIELD_ALERT_TYPE.id && !record.customField?.type) {
    errors.messages.push(i18n("isValid.CustomFieldType"));
    errors.entity.push("customFieldType");
  }

  if (
    record.type === LOW_INVENTORY_ALERT_TYPE.id &&
    !record.lowInventoryCalculation
  ) {
    errors.messages.push(i18n("isValid.LowInventoryCalculation"));
    errors.entity.push("lowInventoryCalculation");
  }

  if (record.method === SEND_EMAIL_OPTION.id && !isValidEmail(record.email)) {
    errors.messages.push(i18n("isValid.AlertMethodEmail"));
    errors.entity.push("email");
  }

  if (record.method === SEND_TEXT_OPTION.id && !record.mobileNumber) {
    errors.messages.push(i18n("isValid.AlertMethodMobileNumber"));
    errors.entity.push("mobileNumber");
  }

  if (record.method === SEND_TEXT_OPTION.id && !record.mobileCarrier) {
    errors.messages.push(i18n("isValid.AlertMethodMobileCarrier"));
    errors.entity.push("mobileCarrier");
  }

  if (!record.method) {
    errors.messages.push(i18n("isValid.AlertMethod"));
    errors.entity.push("method");
  }

  return errors;
}
