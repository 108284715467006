import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";

import {
  LineTableCell,
  LineTableHeadingCell,
} from "components/formFields/LineTableCell";
import { DragHandle } from "components/utility/DragHandle";
import { DragHandleHeading } from "components/utility/DragHandle";

import { i18n } from "services/i18nService";
import { handleDrop } from "services/utility/lineItems";

import { FinalGoodsStage } from "views/Jobs/Stage/FinalGoods";
import { Stage } from "views/Jobs/Stage/Stage";

export function Stages(props) {
  const { stages, workCenters, stageHandler } = props;

  if (!stages) {
    return null;
  }

  const finishedGoodsStage = stages.find(({ id }) => id === 1);
  const allStagesButFinishedGoods = stages.filter(({ id }) => id !== 1);

  return (
    <TableContainer data-testing="lineItems" sx={{ width: "auto" }}>
      <Table size="small" padding="none" stickyHeader sx={{ width: "auto" }}>
        <TableHead>
          <TableRow>
            <DragHandleHeading />
            <LineTableHeadingCell />
            <LineTableHeadingCell>
              {i18n("columns.Sequence")}
            </LineTableHeadingCell>
            <LineTableHeadingCell>{i18n("columns.Stage")}</LineTableHeadingCell>
          </TableRow>
        </TableHead>
        <DragDropContext
          onDragEnd={(result) => handleDrop(result, stageHandler, stages)}
        >
          <Droppable droppableId="lineItems">
            {(provided) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {allStagesButFinishedGoods.map((stage, index) => (
                  <Draggable key={index} draggableId={`${index}`} index={index}>
                    {(draggableProvided, snapshot) => {
                      return (
                        <TableRow
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                          sx={{
                            ...draggableProvided.draggableProps.style,
                            position: "inherit",
                            backgroundColor: snapshot.isDragging
                              ? "dragBackground"
                              : "none",
                            padding: "1rem",
                          }}
                          data-testing={"line-" + index}
                        >
                          <LineTableCell
                            sx={{ position: "relative", width: "3rem" }}
                          >
                            <div {...draggableProvided.dragHandleProps}>
                              <DragHandle />
                            </div>
                          </LineTableCell>

                          <Stage
                            stage={stage}
                            stageHandler={stageHandler}
                            draggableProvided={draggableProvided}
                            draggableSnapshot={snapshot}
                          />
                        </TableRow>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
                <TableRow>
                  <FinalGoodsStage
                    stage={finishedGoodsStage}
                    stages={stages}
                    stageHandler={stageHandler}
                    workCenters={workCenters}
                  />
                </TableRow>
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
}
