import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { FrmTextField } from "components/formFields/FrmTextField";

import { i18n } from "services/i18nService";

export function SaveAsCustomDialog(props) {
  const {
    saveAsCustomDialogOpen,
    setSaveAsCustomDialogOpen,
    customName,
    setCustomName,
    saveAsNewCustom,
  } = props;

  return (
    <Dialog
      open={saveAsCustomDialogOpen}
      onClose={() => setSaveAsCustomDialogOpen(false)}
    >
      <DialogTitle>{i18n("reports.Action.SaveAsCustom")}</DialogTitle>
      <DialogContent>
        <FrmTextField
          value={customName}
          onValueBlur={(_, newValue) => setCustomName(newValue)}
          label={i18n("reports.CustomScheduled.Name")}
          sx={{ minWidth: "25em" }}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <div></div>
        <div>
          <Button
            color="secondary"
            onClick={() => setSaveAsCustomDialogOpen(false)}
          >
            {i18n("button.Cancel")}
          </Button>
          <Button onClick={saveAsNewCustom} color="secondary">
            {i18n("global.Save")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
