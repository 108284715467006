import { useState } from "react";

import { Box, Paper, Switch, Typography } from "@mui/material";

import { FrmTextField } from "components/formFields/FrmTextField";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";

function exampleNumber(length, prefix, nextNumber, random) {
  if (random) {
    let digits = "";
    for (let i = 0; i < length; i++) {
      digits += Math.floor(Math.random() * 10);
    }
    return `${i18n("global.Example")}: ${prefix}${digits}`;
  } else {
    let paddedNumber = nextNumber.toString();
    while (paddedNumber.length < length) {
      paddedNumber = "0" + paddedNumber;
    }
    return `${i18n("global.Next")}: ${prefix}${paddedNumber}`;
  }
}

export function FrmSerialLotNumbering(props) {
  const { name, value, onValueChange } = props;
  const { automaticallyNumber, numberRandomly, autoLength, serials, lots } =
    value;

  const [autoLengthError, setAutoLengthError] = useState(false);

  if (!value) {
    return null;
  }

  function handleValueChange(fieldName, newValue) {
    onValueChange(name, {
      ...value,
      [fieldName]: newValue,
    });
  }

  function handleAutoLengthChange(_, newValue) {
    if (/^\d{1,2}$/.test(newValue) && newValue >= 8 && newValue <= 32) {
      onValueChange(name, {
        ...value,
        autoLength: newValue,
      });
      setAutoLengthError(false);
    } else {
      setAutoLengthError(true);
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          position: "relative",
          top: "-1px",
        }}
      >
        <Typography component="span">{i18n("global.Manual")}</Typography>
        <Switch
          size="small"
          checked={automaticallyNumber}
          onChange={(e) =>
            handleValueChange("automaticallyNumber", e.target.checked)
          }
        />
        <Typography component="span">{i18n("global.Automatic")}</Typography>
      </Box>

      {automaticallyNumber && (
        <>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              marginTop: "5px",
            }}
          >
            <Typography component="span">
              {i18n("global.Sequential")}
            </Typography>
            <Switch
              id="numberRandomly"
              size="small"
              checked={numberRandomly}
              onChange={(e) =>
                handleValueChange("numberRandomly", e.target.checked)
              }
            />
            <Typography component="span">{i18n("global.Random")}</Typography>
          </Box>
          <FrmTextField
            name="autoLength"
            label={i18n(
              "settings.inventory.general.serialLotNumbering.autoLength"
            )}
            value={autoLength}
            number
            onValueBlur={handleAutoLengthChange}
            sx={{ maxWidth: "10.0rem", marginTop: "20px" }}
            error={autoLengthError}
            helperText={
              autoLengthError
                ? i18n(
                    "settings.inventory.general.serialLotNumbering.autoLength.helperText"
                  )
                : ""
            }
          />

          <PrefixAndNextNumber
            name="serials"
            value={serials}
            autoLength={autoLength}
            numberRandomly={numberRandomly}
            handleValueChange={handleValueChange}
          />

          <VSpace space={0.5} />

          <PrefixAndNextNumber
            name="lots"
            value={lots}
            autoLength={autoLength}
            numberRandomly={numberRandomly}
            handleValueChange={handleValueChange}
          />
        </>
      )}
    </>
  );
}

export function PrefixAndNextNumber(props) {
  const { name, value, autoLength, numberRandomly, handleValueChange } = props;
  const { prefix, nextNumber } = value;

  const [nextNumberError, setNextNumberError] = useState(false);

  function handleLocalChange(fieldName, newValue) {
    handleValueChange(name, {
      ...value,
      [fieldName]: newValue,
    });
  }

  function handleNextNumberChange(fieldName, newValue) {
    if (/^\d*$/.test(newValue) && newValue >= 0) {
      handleValueChange(name, {
        ...value,
        [fieldName]: newValue,
      });
      setNextNumberError(false);
    } else {
      setNextNumberError(true);
    }
  }

  return (
    <Paper
      variant="outlined"
      sx={{ padding: 1, backgroundColor: "inherit", marginTop: 1 }}
    >
      <Typography component="div" sx={{ fontWeight: "bold" }}>
        {name === "serials" ? i18n("global.Serials") : i18n("global.Lots")}
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: 1,
          marginTop: 1,
        }}
      >
        <FrmTextField
          name="prefix"
          label={i18n("settings.inventory.general.serialLotNumbering.prefix")}
          value={prefix}
          onValueChange={handleLocalChange}
          maxLength={16}
        />
        {!numberRandomly ? (
          <FrmTextField
            name="nextNumber"
            label={i18n(
              "settings.inventory.general.serialLotNumbering.nextNumber"
            )}
            number
            value={nextNumber}
            onValueBlur={handleNextNumberChange}
            error={nextNumberError}
            maxLength={21}
            helperText={
              nextNumberError
                ? i18n(
                    "settings.inventory.general.serialLotNumbering.nextNumber.helperText"
                  )
                : ""
            }
          />
        ) : (
          <div />
        )}
      </Box>
      <Typography variant="helperText" component="div">
        {exampleNumber(autoLength, prefix, nextNumber, numberRandomly)}
      </Typography>
    </Paper>
  );
}
