import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TextField, MenuItem } from "@mui/material";

import {
  outlinedStyles,
  inputPropStyles,
} from "components/formFields/lineItemFieldStyle";

export function LineItemSelectScalar(props) {
  const { name, options, onValueChange, value, allowNoSelection, minWidth } =
    props;

  if (!options) {
    return null;
  }

  return (
    <TextField
      value={value}
      onChange={(e) => onValueChange(name, e.target.value)}
      margin="dense"
      variant="outlined"
      fullWidth
      select
      inputProps={{
        style: {
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
      }}
      sx={{
        marginTop: 0,
        marginBottom: 0,
        minWidth: minWidth ? minWidth : "inherit",
        ...outlinedStyles,
      }}
      InputProps={{
        sx: inputPropStyles,
        style: { paddingBottom: "inherit", cursor: "inherit", minWidth },
      }}
      InputLabelProps={{ shrink: true }}
      SelectProps={{
        IconComponent: KeyboardArrowDownIcon,
        MenuProps: { disableScrollLock: true },
      }}
    >
      {allowNoSelection && (
        <MenuItem key="none" value="">
          (none)
        </MenuItem>
      )}
      {options.map((option, index) => (
        <MenuItem key={index} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
}
