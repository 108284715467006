import { Typography } from "@mui/material";

import { subLineWrapperStyle } from "components/formFields/lineItemFieldStyle";

export function LineSubItemField(props) {
  const { children, label } = props;

  return (
    <div
      style={{
        ...subLineWrapperStyle,
        paddingLeft: "0.7rem",
        paddingTop: "0.44px",
      }}
    >
      <Typography sx={{ flex: "0 1 auto", fontSize: "0.8125rem" }}>
        {label}
      </Typography>
      {children}
    </div>
  );
}
