import { useState, useEffect } from "react";

import { MultipleSelectField } from "components/formFields/MultipleSelectField";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function EditTagsFilter(props) {
  const { filters, setFilters } = props;

  const { filterSettings } = filters;
  const { Tags } = filterSettings;

  const [value, setValue] = useState(formatInitialOptions(Tags));
  const tags = useRelated("tag");

  useEffect(() => setValue(formatInitialOptions(Tags)), [setValue, Tags]);

  function formatInitialOptions(options) {
    return options?.length
      ? options.split(",").map((name) => ({ name }))
      : null;
  }

  function handleChange(_, values) {
    setValue(values);
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        Tags: values.map((tag) => tag.name)?.join(","),
      },
      startingRecord: 1,
    }));
  }

  return (
    <MultipleSelectField
      options={tags}
      onValueChange={handleChange}
      value={value || []}
      label={i18n("filter.Tags")}
      optionId="name"
    />
  );
}
