import { afterGet as globalAfterGet } from "services/sosInventoryService/afterGet";
import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/salesOrder/schema";
import { salesTransactionAfterGet } from "services/sosInventoryService/salesTransaction/afterGet";
import {
  transformFields,
  transformLineItemFields,
  nonUtcDateTimeHandling,
} from "services/utility/afterGet";

import { OBJECT_TYPES } from "appConstants";
import { LINE_ITEM_FIELDS } from "editConfig";

export function afterGet(record) {
  let newRecord = nonUtcDateTimeHandling(record);

  newRecord = transformFields(newRecord, FIELDS_TO_TRANSFORM);

  newRecord.lines = transformLineItemFields(
    newRecord.lines,
    LINE_ITEM_FIELDS[OBJECT_TYPES.SALES_ORDER.fullString]
  );

  newRecord = salesTransactionAfterGet(newRecord);
  return globalAfterGet(newRecord);
}
