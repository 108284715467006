export const SETTINGS = [
  {
    apiName: "purchaseOrderNumber",
    component: "FrmTransactionNumber",
    transactionType: "purchase order",
    hasInputLabel: true,
  },
  {
    apiName: "defaultCCPO",
    component: "FrmEmail",
  },
  {
    apiName: "defaultLocationForPurchasing",
    component: "FrmLocation",
  },
  {
    apiName: "requireApprovalPO",
    component: "FrmCheckbox",
  },
  {
    apiName: "replyEmailPO",
    component: "FrmEmail",
  },
  {
    apiName: "purchaseOrderEmail",
    component: "FrmResizeTextField",
  },
  { apiName: "purchaseOrderFooter", component: "FrmResizeTextField" },
  {
    apiName: "suggestPOQuantity",
    component: "FrmCheckbox",
  },
  {
    apiName: "syncPurchaseOrders",
    component: "FrmCheckbox",
  },
  {
    apiName: "journalEntryDropShipPO",
    component: "FrmCheckbox",
  },
];
