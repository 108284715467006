import {
  DEFAULT_FILTER_SETTINGS,
  OBJECT_SPECIFIC_DEFAULT_FILTERS,
} from "components/ListPage/listConfig";

import {
  getUserListSettings,
  putUserListSettings,
} from "services/sosInventoryService/sosApi";
import { isCustomField } from "services/utility/customFields";
import { removeFalseyValues } from "services/utility/http";

import { DEFAULT_LIST_MAX_RESULTS } from "appConstants";

export async function loadListSettings(
  userId,
  objectType,
  dateFormat,
  columnConfig
) {
  const retrievedSettings = await getUserListSettings(
    userId,
    objectType,
    dateFormat
  );
  if (retrievedSettings) {
    retrievedSettings.maxResults =
      retrievedSettings.numResults || DEFAULT_LIST_MAX_RESULTS;
    delete retrievedSettings.numResults;

    // convert string array into column object array
    retrievedSettings.showFields = retrievedSettings.showFields.reduce(
      (seed, name) => {
        if (isCustomField(name)) {
          return [...seed, { name, isCustomField: true }];
        }
        const column = columnConfig.find((c) => c.name === name);
        return !column || column.fixed ? seed : [...seed, column];
      },
      []
    );
    return retrievedSettings;
  } else {
    return {
      showFields: columnConfig.filter((c) => c.default),
      filterSettings:
        OBJECT_SPECIFIC_DEFAULT_FILTERS[objectType] || DEFAULT_FILTER_SETTINGS,
      maxResults: DEFAULT_LIST_MAX_RESULTS,
    };
  }
}

export async function saveListSettings(
  userId,
  objectType,
  showFields,
  filters,
  maxResults
) {
  const filterSettings = removeFalseyValues(filters);
  const settings = {
    filterSettings,
    showFields: showFields.map(({ name }) => name),
    numResults: maxResults,
  };
  await putUserListSettings(userId, objectType, settings);
}
