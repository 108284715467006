import { FrmSelectBase } from "components/formFields/FrmSelectBase";

import { checkForUnexpectedProps } from "services/utility/misc";

import PropTypes from "prop-types";

/**
 * @name    FrmSelectScalar
 *
 * @summary presents options for the user to choose from when the
 * options are scalars (options should be an array of strings)
 *
 * See FrmSelectBase for descriptions of the props, which are just
 * passed through this component
 */
export function FrmSelectScalar(props) {
  const {
    options,
    onValueChange,
    name,
    label,
    value,
    disableClearable,
    maxWidth,
    minWidth,
    error,
    sx,
    dataIndex,
    dataTesting,
    loading,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmSelectScalar", unexpected);

  function renderOption(props, option) {
    return <li {...props}>{option}</li>;
  }

  return (
    <FrmSelectBase
      name={name}
      loading={loading}
      disableClearable={disableClearable}
      options={options}
      onValueChange={(name, value) => onValueChange(name, value, dataIndex)}
      getOptionLabel={(option) => option?.toString()}
      renderOption={renderOption}
      label={label}
      value={value?.toString()}
      maxWidth={maxWidth}
      minWidth={minWidth}
      error={error}
      sx={sx}
      dataTesting={dataTesting}
    />
  );
}

FrmSelectScalar.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  onValueChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableClearable: PropTypes.bool,
  maxWidth: PropTypes.string,
  minWidth: PropTypes.string,
  error: PropTypes.bool,
  style: PropTypes.object,
  dataTesting: PropTypes.string,
};
