import { Print } from "@mui/icons-material";
import { DialogTitle, IconButton } from "@mui/material";

import { CloseX } from "components/utility/CloseX";
import { ModalTitle } from "components/utility/ModalTitle";

import { i18n } from "services/i18nService";

const sx = {
  iconStyles: {
    padding: "0.4rem",
    height: "36px",
    border: "1px solid",
    borderColor: "lineSeparatorMinor",
    borderRadius: 1,
    backgroundColor: "white",
  },
  header: {
    displayPrint: "none",
    backgroundColor: "dialogTitleBackground",
    borderBottom: "1px solid",
    borderBottomColor: "lineSeparatorMinor",
    padding: "0.5rem 1rem 0.5rem 1rem",
  },
};

export function QuickViewHeader(props) {
  const { objectType, handleClose, text } = props;

  return (
    <DialogTitle sx={sx.header}>
      <div
        style={{
          display: "grid",
          alignItems: "top",
          gridTemplateColumns: "1fr 350px",
        }}
      >
        <div style={{ minWidth: "0" }}>
          <div style={{ display: "flex", alignItems: "top" }}>
            <CloseX onClick={handleClose} dataTesting="exitQuickView" />
            <ModalTitle
              text={text}
              label={i18n(`objectType.${objectType}.Sentence`)}
            />
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={() => window.print()}
            sx={sx.iconStyles}
            size="large"
          >
            <Print />
          </IconButton>
        </div>
      </div>
    </DialogTitle>
  );
}
