import { i18n } from "services/i18nService";

import { initialErrorState } from "views/common";

export function isValid(record) {
  const errors = initialErrorState();
  errors.entity = [];

  if (!record.number) {
    errors.messages.push(i18n("isValid.Number"));
    errors.entity.push("number");
  }

  if (!record.location) {
    errors.messages.push(i18n("isValid.Location"));
    errors.entity.push("location");
  }

  if (!record.sortOrder) {
    errors.messages.push(i18n("isValid.SequenceNumber"));
    errors.entity.push("sortOrder");
  }

  return errors;
}
