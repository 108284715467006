import { useEffect, useState } from "react";

import { jsonFetch } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

// can return:
// undefined: logo info has not yet been retrieved from the API
// null: this company has no logo set up
// { base64String, height, width }: logo info
export function useLogo(objectType, id) {
  const [logoInfo, setLogoInfo] = useState();

  useEffect(() => {
    async function getLogoFromApi() {
      const response = await jsonFetch("GET", "/settings/logo");
      const { success, json } = response;
      if (!success) {
        handleProgramError(new Error("useLogo : error getting logo from API"));
      }
      if (!json.data.base64String) {
        setLogoInfo(null);
      } else {
        setLogoInfo(json.data);
      }
    }
    getLogoFromApi();
  }, [objectType, id]);

  return logoInfo;
}
