import { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Button } from "@mui/material";

import { ButtonProgress } from "components/utility/ButtonProgress";

import { i18n } from "services/i18nService";
import { getMerchantTrackInfo } from "services/sosInventoryService/sosApi";

import { openAlert } from "globalState/alertSlice";

import {
  MERCHANT_TRACK_PROD_URL,
  MERCHANT_TRACK_TEST_URL,
  MAIN_SOS_TEST_ACCOUNT_ID,
} from "appConstants";

export function MerchantTrack() {
  const authKeyRef = useRef();
  const formRef = useRef();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const companyId = useSelector(
    (state) => state.userCompanySettings.settings.id
  );

  async function handleMerchantTrack() {
    setIsLoading(true);
    const { success, data } = await getMerchantTrackInfo();
    if (success) {
      authKeyRef.current.value = data.authKey;
      formRef.current.submit();
    } else {
      const message = i18n("alert.ErrorMerchantTrack");
      dispatch(openAlert({ type: "warning", message }));
    }
    setIsLoading(false);
  }

  return (
    <>
      <div style={{ position: "relative" }}>
        <Button onClick={handleMerchantTrack}>
          {i18n("global.MerchantTrack")}
        </Button>
        {isLoading && <ButtonProgress />}
      </div>
      <form
        ref={formRef}
        id="sso-form"
        action={
          companyId === MAIN_SOS_TEST_ACCOUNT_ID
            ? MERCHANT_TRACK_TEST_URL
            : MERCHANT_TRACK_PROD_URL
        }
        method="post"
        target="_blank"
      >
        <input
          ref={authKeyRef}
          type="hidden"
          id="AuthenticationKey"
          name="AuthenticationKey"
          value=""
        />
      </form>
    </>
  );
}
