import SvgIcon from "@mui/material/SvgIcon";

import { theme } from "SosTheme";

export function TooltipIcon(props) {
  const { color } = props;

  function getColor() {
    if (!color) {
      return "black";
    }
    if (theme.palette[color]) {
      const paletteNode = theme.palette[color];
      return typeof paletteNode === "string" ? paletteNode : paletteNode.main;
    }
    return color;
  }

  const iconColor = getColor();

  return (
    <SvgIcon sx={{ stroke: iconColor, fill: iconColor, strokeWidth: 1 }}>
      <circle cx="12" cy="12" r="9" fill="none"></circle>
      <path
        d="M11.744,14.34 L12.78,13.948 C12.584,13.78 12.444,13.514 12.444,13.29 C12.444,12.702 12.962,12.352 13.522,11.946 C14.25,11.442 15.048,10.868 15.048,9.706 C15.048,8.474 13.998,7.522 12.262,7.522 C10.96,7.522 9.91,8.054 9.224,8.936 L10.036,9.832 C10.526,9.188 11.17,8.768 12.122,8.768 C13.13,8.768 13.606,9.3 13.606,9.916 C13.606,10.56 13.088,10.91 12.528,11.302 C11.898,11.75 11.226,12.226 11.226,13.164 C11.226,13.612 11.394,14.004 11.744,14.34 Z M12.136,17.154 C12.612,17.154 13.018,16.748 13.018,16.272 C13.018,15.796 12.612,15.404 12.136,15.404 C11.66,15.404 11.268,15.796 11.268,16.272 C11.268,16.748 11.66,17.154 12.136,17.154 Z"
        stroke="none"
      />
    </SvgIcon>
  );
}
