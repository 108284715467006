import { Box } from "@mui/material";

import { SmallOutlinedButton } from "components/SmallOutlinedButton";
import { Tooltip } from "components/Tooltip";
import { LineTableHeadingCell } from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";
import { setPageDirty } from "services/utility/edit";

export function LineColumnFillHeading(props) {
  const { lineHandler, labelCode, field } = props;

  function fill() {
    setPageDirty();
    lineHandler({ type: "fillFieldFromFirstLine", field });
  }

  return (
    <LineTableHeadingCell alignItems="end">
      <Box
        sx={{
          display: "flex",
          columnGap: 1,
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {i18n(`columns.${labelCode}`)}
        <Tooltip title={i18n("global.LineItemFillHoverText")}>
          <Box>
            <SmallOutlinedButton onClick={fill} label={i18n("global.Fill")} />
          </Box>
        </Tooltip>
      </Box>
    </LineTableHeadingCell>
  );
}
