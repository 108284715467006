import { useSelector } from "react-redux";

import { LineItemAutocompleteField } from "components/formFields/LineItemAutocompleteField";
import { LineTableCell } from "components/formFields/LineTableCell";

export function LineClass(props) {
  const { value, classes, onValueChange } = props;

  const classEnabled = useSelector(
    (state) => state.userCompanySettings.settings.classEnabled
  );

  return (
    <>
      {classEnabled && (
        <LineTableCell sx={{ minWidth: "13rem" }}>
          <LineItemAutocompleteField
            name="class"
            options={classes}
            value={value}
            onValueChange={onValueChange}
            allowTooltip
          />
        </LineTableCell>
      )}
    </>
  );
}
