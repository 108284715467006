import { LineItemTextField } from "components/formFields/LineItemTextField";
import { LineTableCell } from "components/formFields/LineTableCell";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineAltAmount(props) {
  const { value, onValueChange, objectType } = props;

  return (
    <LineTableCell sx={{ minWidth: "6rem" }}>
      <LineItemTextField
        money
        name="altAmount"
        value={value}
        onValueChange={onValueChange}
        metadata={LINE_ITEM_METADATA[objectType].altAmount}
      />
    </LineTableCell>
  );
}
