export function afterGet(record) {
  const newRecord = {
    ...record,
    shipmentNumber: {
      prefix: record.prefixShipment,
      nextNumber: record.nextShipmentNumber,
    },
  };

  delete newRecord.prefixShipment;
  delete newRecord.nextShipmentNumber;

  return newRecord;
}
