import { useEffect, useState } from "react";

import {
  Grid,
  Typography,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";

import { TableCell } from "components/TableCell";
import { CloseX } from "components/utility/CloseX";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { Loading } from "components/utility/Loading";
import { ModalTitle } from "components/utility/ModalTitle";

import { i18n } from "services/i18nService";
import { getAuditHistory } from "services/sosInventoryService/sosApi";
import { formatDateTimeToDateTimeString } from "services/utility/formatting";

export function GenericHistory(props) {
  const { onClose, objectType, id, identifierText } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState(undefined);

  useEffect(() => {
    async function retrieveHistory() {
      setIsLoading(true);
      const response = await getAuditHistory(objectType, id);
      setIsLoading(false);
      setHistory(response);
    }
    retrieveHistory();
  }, [objectType, id]);

  return (
    <>
      <DialogTitle
        sx={{
          backgroundColor: "dialogTitleBackground",
          borderBottom: "1px solid",
          borderBottomColor: "lineSeparatorMinor",
          padding: "0.5rem 1rem",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={8}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CloseX onClick={onClose} />
              <ModalTitle
                label={i18n("global.History")}
                text={identifierText}
              />
            </div>
          </Grid>
        </Grid>
      </DialogTitle>
      {isLoading && <Loading />}
      <DialogContent>
        {history ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{i18n("global.When")}</TableCell>
                  <TableCell>{i18n("global.Who")}</TableCell>
                  <TableCell>{i18n("global.What")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((h, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {formatDateTimeToDateTimeString(h.eventDateTime)}
                    </TableCell>
                    <TableCell>{h.userName}</TableCell>
                    <TableCell>
                      {`${i18n("auditAction." + h.auditAction)} ${i18n(
                        "auditObject." + objectType
                      )} `}
                      <Typography
                        component="span"
                        sx={{ fontWeight: "fontWeightBold" }}
                      >
                        {h.objectValue}
                      </Typography>
                      {h.auditAction === "Emailed" && <> to {h.note}</>}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
        )}
      </DialogContent>
    </>
  );
}
