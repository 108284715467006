import { FrmSelectScalar } from "components/formFields/FrmSelectScalar";

import { useRelated } from "hooks/useRelated";

export function FrmHomeCurrency(props) {
  const { name, value, onValueChange, maxWidth, disableClearable } = props;

  const currencies = useRelated("currency");

  return (
    <FrmSelectScalar
      name={name}
      value={value}
      onValueChange={onValueChange}
      options={currencies ? currencies.map(({ code }) => code) : []}
      disableClearable={disableClearable}
      loading={!Array.isArray(currencies)}
      maxWidth={maxWidth}
    />
  );
}
