import { useSelector } from "react-redux";

import { Box, FormControlLabel, Checkbox } from "@mui/material";

import { CustomFields } from "components/CustomFields/CustomFields";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { Contact } from "components/formFields/Contact";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmTextField } from "components/formFields/FrmTextField";
import { Address } from "components/formFields/address/Address";
import { FrmTerms } from "components/formFields/frm";
import { FrmCheckbox } from "components/formFields/frm/FrmCheckbox";
import { FrmCurrency } from "components/formFields/frm/FrmCurrency";
import { FrmCustomerType } from "components/formFields/frm/FrmCustomerType";
import { FrmParentCustomer } from "components/formFields/frm/FrmParentCustomer";
import { FrmPaymentMethod } from "components/formFields/frm/FrmPaymentMethod";
import { FrmPortalPassword } from "components/formFields/frm/FrmPortalPassword";
import { FrmPriceTier } from "components/formFields/frm/FrmPriceTier";
import { FrmSalesRep } from "components/formFields/frm/FrmSalesRep";
import { FrmTax } from "components/formFields/frm/FrmTax";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { HoverHelp } from "components/utility/HoverHelp";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { isPersistedRecord } from "services/utility/misc";

import { useCustomFieldDefinitions } from "hooks/useCustomFieldDefinitions";
import { useCustomer } from "hooks/useCustomer";
import { useErrors } from "hooks/useErrors";
import { useRecord } from "hooks/useRecord";
import { useRelated } from "hooks/useRelated";
import { useTaxCodes, TAX_CODE_TYPE_SALES } from "hooks/useTaxCodes";

import { OBJECT_TYPES } from "appConstants";

const OBJECT_TYPE = OBJECT_TYPES.CUSTOMER.fullString;

export function Customer(props) {
  const { id, newFromParent, onAdd, onClose, fromForm, name } = props;

  const currencies = useRelated("currency");
  const taxCodes = useTaxCodes(TAX_CODE_TYPE_SALES);
  const [record, setRecord, handleInputFieldChange] = useRecord();

  const { errors, setErrors, isInError } = useErrors();

  const loadingState = useSelector((state) => state.loading.editModal);

  // SETTINGS
  const connectedToQBO = useSelector(
    (state) => state.userCompanySettings.settings.connectedToQBO
  );
  const globalEnabled = useSelector(
    (state) => state.userCompanySettings.settings.globalEnabled
  );
  const multiCurrencyEnabled = useSelector(
    (state) => state.userCompanySettings.settings.multiCurrencyEnabled
  );
  const customerPortalEnabled = useSelector(
    (state) => state.userCompanySettings.settings.customerPortalEnabled
  );
  const homeCurrencyRef = useSelector(
    (state) => state.userCompanySettings.settings.homeCurrencyRef,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultTaxCode = useSelector(
    (state) => state.userCompanySettings.settings.defaultTaxCode,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultTerms = useSelector(
    (state) => state.userCompanySettings.settings.defaultTerms,
    (prev, curr) => curr?.id === prev?.id
  );

  const customFieldDefs = useCustomFieldDefinitions(OBJECT_TYPE);

  useCustomer(id, setRecord, customFieldDefs, setErrors, name, newFromParent, {
    defaultTaxCode,
    defaultTerms,
    homeCurrencyRef,
  });

  function handleTaxCodeUpdate(_, value) {
    const { id } = value || {};
    setRecord((prevRecord) => ({
      ...prevRecord,
      tax: { ...prevRecord.tax, taxCode: { id } },
    }));
  }
  function handleTaxableUpdate(e) {
    const { id, checked } = e.target;
    setRecord((prevRecord) => ({
      ...prevRecord,
      tax: { ...prevRecord.tax, [id]: checked },
    }));
  }

  function handleCustomFieldsChange(_, newValue) {
    setRecord((prevRecord) => ({ ...prevRecord, customFields: newValue }));
  }

  function setToOtherAddress() {
    setRecord((prevRecord) => ({
      ...prevRecord,
      shipping: prevRecord?.billing,
    }));
  }

  // if new transaction with record not defined
  // show null till record set in useObject
  if (!id && !record) {
    return null;
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        relatedRecords={[]}
        lines={[]}
        setErrors={setErrors}
        errors={errors}
        text={record?.name}
        handleClose={onClose}
        onAdd={onAdd}
        fromForm={fromForm}
      />
      {loadingState && <Loading />}
      <ObjectEditDialogContent>
        <Box flexGrow="1" position="relative" id="B" m={2} mb={0}>
          <FormErrors errors={errors} setErrors={setErrors} />
          {record ? (
            <Box>
              <FrmFieldGrid gridAutoFlow="dense">
                <FrmTextField
                  autoFocus
                  label={i18n("frmLabel.Name")}
                  maxLength={200}
                  onValueBlur={handleInputFieldChange}
                  value={record.name}
                  error={isInError("name")}
                  name="name"
                  dataTesting="name"
                />
                <FrmTextField
                  label={i18n("global.Email")}
                  maxLength={100}
                  onValueBlur={handleInputFieldChange}
                  value={record.email}
                  name="email"
                  type="email"
                  error={isInError("email")}
                />
                <FrmTextField
                  label={i18n("global.Website")}
                  maxLength={255}
                  onValueBlur={handleInputFieldChange}
                  value={record.website}
                  name="website"
                  error={isInError("website")}
                />
                <FrmTextField
                  label={i18n("global.Company")}
                  maxLength={100}
                  onValueBlur={handleInputFieldChange}
                  value={record.companyName}
                  name="companyName"
                />
                <FrmTextField
                  label={i18n("global.Phone")}
                  maxLength={30}
                  onValueBlur={handleInputFieldChange}
                  value={record.phone}
                  name="phone"
                />
                <FrmTextField
                  label={i18n("global.MobilePhone")}
                  maxLength={30}
                  onValueBlur={handleInputFieldChange}
                  value={record.mobile}
                  name="mobile"
                />
                <FrmTextField
                  label={i18n("global.AltPhone")}
                  maxLength={30}
                  onValueBlur={handleInputFieldChange}
                  value={record.altPhone}
                  name="altPhone"
                />
                <FrmTextField
                  label={i18n("global.Fax")}
                  maxLength={30}
                  onValueBlur={handleInputFieldChange}
                  value={record.fax}
                  name="fax"
                />
              </FrmFieldGrid>
              <VSpace space={2} />
              <FrmFieldGrid gridAutoFlow="dense">
                <Contact
                  onValueBlur={handleInputFieldChange}
                  contact={record.contact}
                />
                <div></div>
              </FrmFieldGrid>
              <VSpace space={2} />
              <FrmFieldGrid gridAutoFlow="dense">
                <Address
                  title={i18n("customer.BillingAddress")}
                  addressType="billing"
                  address={record.billing}
                  onValueBlur={handleInputFieldChange}
                />
                <Address
                  title={i18n("customer.ShippingAddress")}
                  addressType="shipping"
                  address={record.shipping}
                  onValueBlur={handleInputFieldChange}
                  setToOtherAddress={setToOtherAddress}
                />
              </FrmFieldGrid>
              <VSpace space={2} />
              <FrmFieldGrid gridAutoFlow="dense">
                <FrmPriceTier
                  onValueChange={handleInputFieldChange}
                  value={record.priceTier}
                />
                <FrmTerms
                  value={record.terms}
                  onValueChange={handleInputFieldChange}
                  error={isInError("terms")}
                />
                <FrmSalesRep
                  onValueChange={handleInputFieldChange}
                  value={record.salesRep}
                />
                <FrmPaymentMethod
                  name="paymentMethod"
                  label={i18n("frmLabel.PaymentMethod")}
                  value={record.paymentMethod}
                  onValueChange={handleInputFieldChange}
                />
                <FrmCustomerType
                  onValueChange={handleInputFieldChange}
                  value={record.customerType}
                />
                <FrmTextField
                  label={i18n("customer.ResaleNumber")}
                  maxLength={100}
                  onValueBlur={handleInputFieldChange}
                  value={record.resaleNumber}
                  name="ResaleNumber"
                />
                <FrmTextField
                  label={i18n("customer.ContractorNumber")}
                  maxLength={100}
                  onValueBlur={handleInputFieldChange}
                  value={record.contractorNumber}
                  name="contractorNumber"
                />
                <FrmTextField
                  label={i18n("customer.BusinessLicense")}
                  maxLength={100}
                  onValueBlur={handleInputFieldChange}
                  value={record.businessLicense}
                  name="businessLicense"
                />
                <FrmTextField
                  label={i18n("customer.FoundUsVia")}
                  maxLength={21}
                  onValueBlur={handleInputFieldChange}
                  value={record.foundUsVia}
                  name="foundUsVia"
                />
              </FrmFieldGrid>
              <FrmFieldGrid gridAutoFlow="dense" alignItems="center">
                {!globalEnabled && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="taxable"
                          checked={record.tax?.taxable}
                          onChange={handleTaxableUpdate}
                        />
                      }
                      label={i18n("customer.Taxable")}
                      sx={{ marginRight: 0 }}
                    />
                    <HoverHelp
                      title={i18n("tooltip.Taxable", {
                        transactionType: OBJECT_TYPE,
                      })}
                      sx={{ marginLeft: 0.5 }}
                    />
                  </div>
                )}
                {(globalEnabled || record.tax?.taxable) && (
                  <FrmTax
                    maxWidth="20rem"
                    taxCodes={taxCodes}
                    value={record.tax?.taxCode}
                    onValueChange={handleTaxCodeUpdate}
                    error={isInError("tax")}
                  />
                )}
                {multiCurrencyEnabled && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ maxWidth: "10em" }}>
                      <FrmCurrency
                        value={record.currency}
                        currencies={currencies}
                        onValueChange={handleInputFieldChange}
                        disabled={
                          isPersistedRecord(record.id) && connectedToQBO
                        }
                        error={isInError("currency")}
                      />
                    </div>
                    {isPersistedRecord(record.id) && connectedToQBO && (
                      <HoverHelp
                        title={i18n("tooltip.CurrencyDisabled", {
                          objectType: i18n("objectType.customer.Lower"),
                        })}
                        sx={{ marginLeft: 0.5 }}
                      />
                    )}
                  </div>
                )}
                {customerPortalEnabled && (
                  <FrmPortalPassword
                    setRecord={setRecord}
                    value={record.portalPassword}
                  />
                )}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FrmCheckbox
                    name="creditHold"
                    value={record && record.creditHold}
                    onValueChange={handleInputFieldChange}
                    label={i18n("customer.CreditHold")}
                    sx={{ marginRight: "8px" }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      maxWidth: "20em",
                      marginRight: "1.5rem",
                      width: "20em",
                    }}
                  >
                    <FrmParentCustomer
                      onValueChange={handleInputFieldChange}
                      value={record.parent}
                    />
                  </div>
                  <FrmCheckbox
                    name="billWithParent"
                    value={record && record.billWithParent}
                    onValueChange={handleInputFieldChange}
                    label={i18n("customer.BillWithParent")}
                    sx={{ marginRight: 0 }}
                  />
                  <HoverHelp
                    title={i18n("tooltip.BillWithParent", {
                      transactionType: OBJECT_TYPE,
                    })}
                    sx={{ marginLeft: 0.5 }}
                  />
                </div>
                <FrmResizeTextField
                  label={i18n("global.Notes")}
                  onValueBlur={handleInputFieldChange}
                  value={record.notes}
                  name="notes"
                  width="50%"
                  minRows={4}
                />
              </FrmFieldGrid>
              <CustomFields
                customFieldDefinitions={customFieldDefs}
                customFields={record.customFields || []}
                onValueChange={handleCustomFieldsChange}
                setErrors={setErrors}
              />
            </Box>
          ) : (
            !isInError("record") && (
              <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
            )
          )}
        </Box>
        <VSpace space={4} />
      </ObjectEditDialogContent>
    </>
  );
}
