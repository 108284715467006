import { LINE_FIELDS_TO_TRANSFORM } from "services/sosInventoryService/box/schema";
import { transformFields } from "services/utility/afterGet";

import {
  DEFAULT_BOX_WEIGHT_UNIT,
  DEFAULT_BOX_DIMENSION_UNIT,
} from "appConstants";

export function afterGet(record) {
  const newRecord = { ...record };

  newRecord.lines = record.lines.map((line) =>
    transformFields(line, LINE_FIELDS_TO_TRANSFORM)
  );

  // set default weight and dimension if not set
  newRecord.weightUnit = newRecord.weightUnit || DEFAULT_BOX_WEIGHT_UNIT;
  newRecord.dimensionUnit =
    newRecord.dimensionUnit || DEFAULT_BOX_DIMENSION_UNIT;

  return newRecord;
}
