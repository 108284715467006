export function afterGet(record) {
  const newRecord = {
    ...record,
    pickTicketNumber: {
      prefix: record.prefixPickTicket,
      nextNumber: record.nextPickTicketNumber,
    },
  };

  delete newRecord.prefixPickTicket;
  delete newRecord.nextPickTicketNumber;

  return newRecord;
}
