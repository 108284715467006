import SvgIcon from "@mui/material/SvgIcon";

import { theme } from "SosTheme";

export function QuicklinksIcon(props) {
  const { highlighted, ...otherProps } = props;
  const color = highlighted ? theme.palette.accent : theme.palette.primaryText;

  return (
    <SvgIcon viewBox="0 0 32 32" fill={color} {...otherProps}>
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g transform="translate(8.000000, 0.000000)" fillRule="nonzero">
          <path d="M15.3286713,0 L0.671328671,0 C0.300643357,0 0,0.291626391 0,0.651194025 L0,31.348806 C0,31.5986474 0.147356643,31.8265653 0.379412587,31.9352062 C0.47227972,31.9787277 0.571972028,32 0.671216783,32 C0.820027972,32 0.96772028,31.9520287 1.08867133,31.8587994 L8.41633566,26.2159861 C8.7067972,25.992301 8.75524476,25.5828085 8.52475524,25.3010585 C8.29426573,25.0194171 7.872,24.9724226 7.58153846,25.1958907 L1.34254545,30.0002917 L1.34254545,1.30238805 L14.6572308,1.30238805 L14.6572308,30.0006173 L11.6618741,27.6946307 C11.3716364,27.4710541 10.9492587,27.5182657 10.7186573,27.7999071 C10.4881678,28.081657 10.5368392,28.4912581 10.8273007,28.7148347 L14.9113287,31.858908 C15.1128392,32.0141092 15.3886434,32.0438471 15.6205874,31.9352062 C15.8525315,31.8265653 16,31.5986474 16,31.348806 L16,0.651194025 C16,0.291626391 15.6994685,0 15.3286713,0 Z"></path>
          <path d="M2.65346535,3 L12.3465347,3 C12.7073564,3 13,2.77608333 13,2.5 C13,2.22391667 12.7073564,2 12.3465347,2 L2.65346535,2 C2.29264356,2 2,2.22391667 2,2.5 C2,2.77608333 2.29264356,3 2.65346535,3 Z"></path>
          <path d="M2.65346535,5 L12.3465347,5 C12.7073564,5 13,4.77608333 13,4.5 C13,4.22391667 12.7073564,4 12.3465347,4 L2.65346535,4 C2.29264356,4 2,4.22391667 2,4.5 C2,4.77608333 2.29264356,5 2.65346535,5 Z"></path>
          <path d="M9.3557047,6 L2.6442953,6 C2.28853691,6 2,6.22391667 2,6.5 C2,6.77608333 2.28853691,7 2.6442953,7 L9.3557047,7 C9.71146309,7 10,6.77608333 10,6.5 C10,6.22391667 9.71157047,6 9.3557047,6 Z"></path>
        </g>
      </g>
    </SvgIcon>
  );
}
