import { i18n } from "services/i18nService";

import { SUB_COMPONENT } from "views/Reports/reportConstants";

import { OBJECT_TYPES } from "appConstants";

export const SalesByCategorySummary = {
  userHasSufficientPrivilege: (p) => p.viewCosts,
  planIncludesReport: () => true,
  customReportNameFetchList: [
    { objectType: OBJECT_TYPES.ITEM.fullString, customReportKey: "categories" },
  ],
  customFieldObjectTypes: [],
  columns: [
    { apiName: "Units Shipped", number: true, alwaysShow: true },
    { apiName: "% Total Units Shipped", number: true, alwaysShow: true },
    { apiName: "Revenue", number: true, alwaysShow: true },
    { apiName: "% Total Revenue", number: true, alwaysShow: true },
    { apiName: "COGS", number: true, alwaysShow: true },
    { apiName: "% Total COGS", number: true, alwaysShow: true },
    { apiName: "Profit", number: true, alwaysShow: true },
    { apiName: "% Total Profit", number: true, alwaysShow: true },
    { apiName: "Open Amt", number: true, alwaysShow: true },
    { apiName: "Total Amt", number: true, alwaysShow: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      defaultValue: null,
      datePrefix: "startOf",
      type: "filter",
    },
    {
      apiName: "date1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("filter.Category"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "suppressIfEmpty",
      defaultValue: true,
      label: i18n("reports.OptionLabel.HideRowsWithNoSales"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedCategories"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
