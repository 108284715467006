// Actual domain logic should be in a domainLogic.js file, at the appropriate
// level in the hierarchy.
import { ITEM_CALCULATED_FIELDS } from "appConfig";
import { IN_TRANSACTION } from "appConfig";

import { removeLotAndSerialValues } from "services/sosInventoryService/domainLogic";
import {
  updateAvailableBinsAndBin,
  updateLineRelatedRecordsItem,
} from "services/sosInventoryService/domainLogic";
import { getEmptyRecord } from "services/sosInventoryService/returnToVendor/schema";
import { getRecord, getRecordFrom } from "services/sosInventoryService/sosApi";
import {
  copyCustomFieldValues,
  reconcileCustomFields,
  getCustomFieldDefinitions,
} from "services/utility/customFields";
import { getRelatedReferenceObjects } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";

import globalState from "globalState/globalState";

import { OBJECT_TYPES } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.RETURN_TO_VENDOR.fullString;

export async function getTransaction(
  id,
  newFromId,
  newFromObjectType,
  updaters,
  customFieldDefs
) {
  let record, lines, relatedRecords;

  if (id || newFromObjectType) {
    if (id) {
      record = await getRecord(OBJECT_TYPE, id);
    } else {
      // it's a "populate from" request
      record = await getRecordFrom(OBJECT_TYPE, newFromObjectType, newFromId);
      delete record.id;
    }

    record.customFields = reconcileCustomFields(
      customFieldDefs,
      record.customFields
    );

    lines = record.lines;
    delete record.lines;

    updaters.lineHandler({ type: "set", lines });
    updaters.setRecord(record);

    relatedRecords = await getRelatedReferenceObjects(OBJECT_TYPE, record);

    updaters.setRelatedRecords(relatedRecords);

    // get items for each line item, so that we have updated item info for
    // the calculations below
    lines = await updateLineRelatedRecordsItem(
      record.location,
      record.date,
      lines,
      ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
    );

    // set the default bin and the available bins for the dropdown
    lines = await updateAvailableBinsAndBin(record.location, lines);
  } else {
    // new record
    const { settings } = globalState.getState().userCompanySettings;
    const defaultLocation =
      globalState.getState().userCompanySettings.settings.defaultLocation;
    const numLinesToAdd =
      globalState.getState().userCompanySettings.settings.numLinesToAdd;
    const defaultLocationForPurchasing =
      globalState.getState().userCompanySettings.settings
        .defaultLocationForPurchasing;

    record = {
      ...getEmptyRecord(settings),
      customFields: reconcileCustomFields(customFieldDefs, []),
    };

    lines = Array(numLinesToAdd).fill(EMPTY_LINE_ITEM[OBJECT_TYPE]);

    relatedRecords = {};

    if (defaultLocation) {
      record.location = defaultLocationForPurchasing || defaultLocation || null;

      const location = await getRecord(
        "location",
        record.location.id,
        IN_TRANSACTION
      );

      relatedRecords = { ...relatedRecords, location };
    }
    updaters.setRelatedRecords(relatedRecords);
    updaters.setRecord(record);
  }

  updaters.lineHandler({ type: "set", lines });
}

export async function updateTransaction(
  field,
  newValue,
  transactionCustomFieldDefs,
  currentState,
  updaters,
  currencies
) {
  const { record, lines, relatedRecords } = currentState;
  if (!record || !lines || !relatedRecords) {
    handleProgramError(new Error("update Transaction | invalid currentState"));
  }

  let newRecord, newLines, newRelatedRecords;

  switch (field) {
    case "date":
      newRecord = { ...record, date: newValue };
      updaters.setRecord(newRecord);

      if (newValue) {
        // reget items for each line item, with the new date, so that we
        // have updated item info for the calculations below
        newLines = await updateLineRelatedRecordsItem(
          record.location,
          newValue,
          lines,
          ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
        );

        // set the default bin and the available bins for the dropdown
        newLines = await updateAvailableBinsAndBin(record.location, newLines);
      }
      break;

    case "location":
      newRecord = { ...record, location: newValue };
      updaters.setRecord(newRecord);
      try {
        const location = newValue
          ? await getRecord("location", newValue.id)
          : null;
        newRelatedRecords = { ...relatedRecords, location };
      } catch (e) {
        handleProgramError(e);
      }

      // reget items for each line item, with the new date, so that we
      // have updated item info for the calculations below
      newLines = await updateLineRelatedRecordsItem(
        newValue,
        record.date,
        lines,
        ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
      );

      // set the default bin and the available bins for the dropdown
      newLines = await updateAvailableBinsAndBin(newValue, newLines);
      newLines = removeLotAndSerialValues(newLines);

      break;

    case "account":
    case "comment":
    case "createVendorCredit":
    case "customFields":
    case "department":
    case "number":
    case "shippingMethod":
    case "trackingNumber":
    case "vendorMessage":
    case "vendorNotes":
      newRecord = { ...record, [field]: newValue };
      updaters.setRecord(newRecord);

      break;

    case "vendor":
      // if the field is empty, null the field
      if (!newValue) {
        newRecord = { ...record, vendor: null };
        updaters.setRecord(newRecord);
        newRelatedRecords = { ...relatedRecords, vendor: null };
        break;
      }

      const vendorCustomFieldDefs = await getCustomFieldDefinitions("vendor");

      try {
        // get the newly selected vendor record, so that we can...
        const vendor = await getRecord("vendor", newValue.id, IN_TRANSACTION);

        // be sure there are custom field entries for each defined custom field
        // in the vendor record...
        const vendorCustomFields = reconcileCustomFields(
          vendorCustomFieldDefs,
          vendor.customFields
        );

        const newTransactionCustomFields = copyCustomFieldValues(
          vendorCustomFieldDefs,
          vendorCustomFields,
          transactionCustomFieldDefs,
          record.customFields
        );

        // ...then initialize any transaction custom fields to their matching vendor
        // custom field values, if any

        newRecord = {
          ...record,
          vendor: { id: newValue.id },
          vendorNotes: vendor.notes,
          taxCode: vendor.taxCode,
          terms: vendor.terms,
          customFields: newTransactionCustomFields,
        };
        updaters.setRecord(newRecord);
        newRelatedRecords = { ...relatedRecords, vendor };
      } catch (e) {
        handleProgramError(e);
      }

      break;

    case "currency":
      const { id: newId, name: newName } = newValue || {};
      updaters.setRecord((prev) => ({
        ...prev,
        currency: newId ? { id: newId, name: newName } : null,
        exchangeRate: newId
          ? currencies.find(({ id }) => id === newId).exchangeRate
          : null,
      }));
      break;

    default:
      handleProgramError(
        new Error(`updateTransaction | unknown field (${field})`)
      );
  }

  if (newLines) {
    updaters.lineHandler({ type: "set", lines: newLines });
  }
  if (newRelatedRecords) {
    updaters.setRelatedRecords(newRelatedRecords);
  }
}
