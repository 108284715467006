import { ALL_FILTER_OPTION } from "appConstants";

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["quantity"],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["txnDate"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const TYPE_FILTER_OPTIONS = [
  ALL_FILTER_OPTION,
  { id: 1, name: "rental" },
  { id: 2, name: "rental return" },
];
