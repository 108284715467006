import { Loading } from "classes/Loading";

import { LineItemTextField } from "components/formFields/LineItemTextField";
import { LineSkeleton } from "components/formFields/LineSkeleton";
import { LineTableCell } from "components/formFields/LineTableCell";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineUnitprice(props) {
  const { value, onValueChange, readOnly, objectType, error } = props;

  const isLoading = value instanceof Loading;

  return (
    <LineTableCell sx={{ minWidth: "6rem" }}>
      {isLoading ? (
        <LineSkeleton />
      ) : (
        <LineItemTextField
          money
          name="unitprice"
          value={value}
          readOnly={readOnly}
          onValueChange={onValueChange}
          metadata={LINE_ITEM_METADATA[objectType].unitprice}
          error={error}
          dataTesting="lineUnitprice"
        />
      )}
    </LineTableCell>
  );
}
