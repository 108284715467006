import { transformFields } from "services/utility/afterGet";

const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: ["amount"],
  DATE_FIELDS: ["date"],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export function afterGet(record) {
  const newRecord = transformFields(record, FIELDS_TO_TRANSFORM);

  return newRecord;
}
