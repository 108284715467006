import { useState, useEffect } from "react";

import { TextField } from "@mui/material";

import {
  outlinedStyles,
  inputPropStyles,
} from "components/formFields/lineItemFieldStyle";

export function RowTextField(props) {
  const { dataIndex, name, value: initialValue, onValueBlur, resize } = props;

  const [value, setValue] = useState(initialValue);
  useEffect(() => setValue(initialValue), [initialValue]);

  return (
    <TextField
      name={name}
      autoComplete="off"
      margin="dense"
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => onValueBlur(e.target?.name, e.target?.value, dataIndex)}
      value={value || ""}
      variant="outlined"
      fullWidth
      data-testing={name}
      inputProps={{
        style: {
          textAlign: "left",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          resize: resize ? "both" : "none",
          minWidth: resize ? "90%" : "inherit",
        },
      }}
      sx={{ marginTop: 0, marginBottom: 0, ...outlinedStyles }}
      InputProps={{ sx: inputPropStyles }}
    />
  );
}
