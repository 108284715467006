import { Button } from "@mui/material";

export function SmallOutlinedButton(props) {
  const { onClick, label, dataTesting } = props;

  return (
    <Button
      onClick={onClick}
      variant="outlined"
      size="small"
      sx={{
        minWidth: "auto",
        height: "1.0rem",
        fontSize: "0.6rem",
        pb: "0.1rem",
      }}
      data-testing={dataTesting}
    >
      {label}
    </Button>
  );
}
