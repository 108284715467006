import { useEffect, useState } from "react";

import { Typography } from "@mui/material";

import { Tooltip } from "components/Tooltip";
import {
  AGGREGATOR_SELECT,
  SerialsAggregator,
} from "components/formFields/Serials/SerialsAggregator/SerialsAggregator";
import { subLineWrapperStyle } from "components/formFields/lineItemFieldStyle";

import { i18n } from "services/i18nService";
import { LINE_ITEM_METADATA } from "services/sosInventoryService/returnToVendor/schema";
import {
  getSerialsAvailable,
  getReturnableSerials,
} from "services/sosInventoryService/sosApi";

import { theme } from "SosTheme";
import { REQUIRE_BIN_SELECTION_FOR_LOT_SERIAL_SELECT } from "appConstants";

export function LineSerialsSelect(props) {
  const {
    objectType,
    location,
    value,
    bin,
    quantity,
    itemId,
    customerId,
    onValueChange,
    error,
    isReturn,
    enableForNegativeQuantity,
  } = props;

  const [serials, setSerials] = useState();

  useEffect(() => {
    async function getAvailable() {
      if (!location?.id) {
        setSerials([]);
        return;
      }
      const fetchedSerials = await getSerialsAvailable(
        itemId,
        location?.id,
        bin?.id
      );
      setSerials(fetchedSerials);
    }

    async function getReturnable() {
      if (!customerId) {
        setSerials([]);
        return;
      }
      const fetchedSerials = await getReturnableSerials(itemId, customerId);
      setSerials(fetchedSerials);
    }
    isReturn ? getReturnable() : getAvailable();
  }, [itemId, customerId, location?.id, bin?.id, isReturn]);

  function isBinSelectionNeed(location) {
    if (!REQUIRE_BIN_SELECTION_FOR_LOT_SERIAL_SELECT.includes(objectType)) {
      return false;
    }
    return location?.binTracking && !bin?.id;
  }

  const binSelectionNeeded = isBinSelectionNeed(location);

  const styles = {
    ...subLineWrapperStyle,
    backgroundColor: binSelectionNeeded
      ? theme.palette.staticTableCell
      : "inherit",
  };

  return (
    <Tooltip title={binSelectionNeeded ? i18n("tooltip.SelectBinFirst") : ""}>
      <div style={styles}>
        <Typography sx={{ flex: "0 1 auto", fontSize: "0.8125rem" }}>
          {i18n("columns.colon.Serials")}
        </Typography>
        <SerialsAggregator
          isReturn={isReturn}
          binSelectionNeeded={binSelectionNeeded}
          enableForNegativeQuantity={enableForNegativeQuantity}
          enterOrSelect={AGGREGATOR_SELECT}
          value={value || []}
          serials={serials}
          quantity={quantity}
          metadata={LINE_ITEM_METADATA.serials}
          onValueChange={onValueChange}
          locationSelected={location?.id}
          error={error}
        />
      </div>
    </Tooltip>
  );
}
