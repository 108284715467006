import { i18n } from "services/i18nService";
import {
  currencyMinMaxFractionDigits,
  decimalMaximumFractionDigits,
} from "services/utility/formatting";

import globalState from "globalState/globalState";

// all the editStr functions should have the same signature of metadata, value, and localization
export function textTypeEditStrFunction(metadata, value) {
  return value ? value : "";
}

export function numberTypeEditStrFunction(metadata, value) {
  return typeof value === "number" ? value : "";
}

export function dateTypeEditStrFunction(metadata, value) {
  return value instanceof Date ? value : null;
}

export function booleanTypeEditStrFunction(metadata, value) {
  return value;
}

export function referenceTypeEditStrFunction(metadata, value) {
  return value ? value.id : "";
}

export function arrayOfReferencesTypeEditStrFunction(metadata, value) {
  return value ? value : [];
}

export function inventoryItemTypeEditStrFunction(metadata, value) {
  return value ? value : "";
}

export function linkedTransactionTypeEditStrFunction(metadata, value) {
  const transactionType = metadata?.transactionType || "";
  if (!value) {
    return {
      transactionType,
      refNumber: "",
      lineNumber: "",
    };
  } else {
    return {
      ...value,
      lineNumber: value.lineNumber === 0 ? "" : value.lineNumber,
    };
  }
}

export function decimalTypeEditStrFunction(metadata, value) {
  const { currencyMinimumFractionDigits } =
    globalState.getState().userCompanySettings.localization;
  if (metadata.currency) {
    return currencyMinMaxFractionDigits(
      value,
      currencyMinimumFractionDigits,
      metadata.placesRight,
      false
    );
  } else {
    return decimalMaximumFractionDigits(value, metadata.placesRight, false);
  }
}

export function isElementAddedToOldArray(oldArray, newArray) {
  return newArray.reduce(
    (seed, element) => seed || !oldArray.includes(element),
    false
  );
}

export function getListHeaderText(objectType, totalRecords, retrievalParams) {
  if (!retrievalParams || !totalRecords) {
    return "";
  }
  return totalRecords === 0
    ? `${i18n("global.No")} ${i18n(`objectType.${objectType}.LowerPlural`)}`
    : `${retrievalParams.startingRecord} - ${Math.min(
        retrievalParams.startingRecord + retrievalParams.maxResults - 1,
        totalRecords
      )} of ${totalRecords}`;
}
