import { useState, useEffect } from "react";

import {
  outlinedStyles,
  inputPropStyles,
} from "components/formFields/lineItemFieldStyle";
import { DatePicker } from "components/html/Input/DatePicker";

const dialogStyles = {
  "& .MuiDialogActions-root": { display: "block", textAlign: "right" },
};

export function LineLotExpirationDate(props) {
  const { name, onValueChange, value: initialValue, metadata, error } = props;

  const borderStyles = error ? {} : outlinedStyles;

  const [value, setValue] = useState(
    metadata[name].editStr(metadata[name], initialValue)
  );

  useEffect(() => {
    setValue(metadata[name].editStr(metadata[name], initialValue));
  }, [initialValue, name, metadata]);

  return (
    <DatePicker
      key={value}
      value={value}
      name={name}
      onValueChange={onValueChange}
      error={error}
      textSx={{ marginTop: 0, marginBottom: 0, width: "100%", ...borderStyles }}
      inputProps={{ sx: inputPropStyles }}
      dialogProps={{ sx: dialogStyles }}
    />
  );
}
