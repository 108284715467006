import { FadedHR } from "components/html/FadedHR";
import { VSpace } from "components/utility/VSpace";

import { Typography } from "@mui/material";

export function SectionHeading(props) {
  return (
    <>
      <VSpace space={2} />
      <Typography variant="h6">{props.children}</Typography>
      <FadedHR />
      <VSpace space={2} />
    </>
  );
}
