export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixShipment: record.shipmentNumber.prefix,
    nextShipmentNumber: record.shipmentNumber.nextNumber,
  };

  delete newRecord.shipmentNumber;

  return newRecord;
}
