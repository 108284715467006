import { Box, Button, ButtonGroup, Typography } from "@mui/material";

import { i18n } from "services/i18nService";

import { LIST_MAX_RESULTS_OPTIONS } from "appConstants";

export function EditMaxResults(props) {
  const { filters, setFilters } = props;
  const { maxResults } = filters;

  function handleChange(selectedOption) {
    setFilters((prevParams) => ({
      ...prevParams,
      maxResults: parseInt(selectedOption),
      startingRecord: 1,
    }));
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      data-testing="maxResultsFilter"
    >
      <Typography
        sx={{
          fontSize: "0.8125rem",
          fontWeight: "Medium",
          color: "primaryText",
          marginLeft: "1.0em",
        }}
      >
        {i18n("filter.ResultsPerPage")}
      </Typography>
      <ButtonGroup variant="outlined" sx={{ py: 1.5 }}>
        {LIST_MAX_RESULTS_OPTIONS.map((option) => (
          <Button
            key={option}
            variant={option === maxResults ? "contained" : "outlined"}
            onClick={() => handleChange(option)}
            size="small"
          >
            {option.toString()}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
}
