import { Decimal } from "classes/DecimalClasses";

export function beforeSave(record) {
  const newRecord = {
    ...record,
    discountDayOfMonth: record.discountDayOfMonthm || new Decimal(0),
    dueNextMonthDays: record.dueNextMonthDays || new Decimal(0),
  };
  return newRecord;
}
