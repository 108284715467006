import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { Tooltip } from "components/Tooltip";

import { i18n } from "services/i18nService";

export function EditToggle(props) {
  const { isExpanded, toggle } = props;
  return (
    <Tooltip
      title={i18n(isExpanded ? "tooltip.Collapse" : "tooltip.ExpandToEdit")}
    >
      <IconButton sx={{ p: "4px" }} border={1} size="medium" onClick={toggle}>
        <Edit fontSize="medium" color={isExpanded ? "primary" : "inherit"} />
      </IconButton>
    </Tooltip>
  );
}
