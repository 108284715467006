import { i18n } from "services/i18nService";
import { mainLog, LOG_LEVEL } from "services/logService";

import { openAlert } from "globalState/alertSlice";
import globalState from "globalState/globalState";

// level should be one of the values exported by LOG_LEVEL in logService.js
export function handleProgramError(error) {
  mainLog(error, LOG_LEVEL.ERROR);
  globalState.dispatch(
    openAlert({
      type: "error",
      message: `${i18n("error.InternalError", {
        errorMessage: error.message,
      })}`,
    })
  );
}
