import { FilterSelectField } from "components/formFields/FilterSelectField";

import { useRelated } from "hooks/useRelated";

export function CustomerFilter(props) {
  const { value, onValueChange, name, label } = props;

  const customers = useRelated("customer");

  return (
    <FilterSelectField
      name={name}
      label={label}
      options={customers}
      value={value}
      onValueChange={onValueChange}
    />
  );
}
