import { normalizeLinkedTransaction } from "services/sosInventoryService/utility/normalizeLinkedTransaction";
import { convertNativeToCustom } from "services/utility/customFields";
import { normalizeJobWorkCenter } from "services/utility/jobWorkCenter";
import { cleanTransactionNumber } from "services/utility/misc";
import { TYPES } from "services/utility/schema";

import { OBJECT_TYPES } from "appConstants";
import { LINE_ITEM_FIELDS } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.PAYMENT.fullString;

export function beforeSave(record) {
  let newRecord = { ...record };

  newRecord.number = cleanTransactionNumber(record.id, record.number);

  // for each line
  newRecord.lines = record.lines.map((line) => {
    let newLine = { ...line };
    newLine = normalizeJobWorkCenter(newLine);
    // normalize other reference fields; if no referenced record is selected,
    // set the field to null
    LINE_ITEM_FIELDS[OBJECT_TYPE][TYPES.reference].forEach((field) => {
      if (
        line[field] &&
        typeof line[field].id === "string" &&
        line[field].id === ""
      ) {
        newLine[field] = null;
      }
    });

    // ensure linkedTransaction is in a proper format for the backend
    newLine.linkedTransaction = normalizeLinkedTransaction(
      line.linkedTransaction
    );

    // remove relatedRecords from line; it's only used in this frontend
    delete newLine.relatedRecords;

    return newLine;
  });

  newRecord.customFields = convertNativeToCustom(record.customFields);

  return newRecord;
}
