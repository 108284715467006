import { i18n } from "services/i18nService";

import { SUB_COMPONENT } from "views/Reports/reportConstants";

import {
  ALL_FILTER_OPTION,
  ITEM_QUICKLIST_CASES,
  OBJECT_TYPES,
} from "appConstants";

export const RentalCalendar = {
  userHasSufficientPrivilege: (p) => p.viewRentals || p.editRentals,
  planIncludesReport: (plus) => plus,
  customReportNameFetchList: [
    { objectType: OBJECT_TYPES.CUSTOMER.fullString },
    { objectType: OBJECT_TYPES.ITEM.fullString },
    {
      objectType: OBJECT_TYPES.LOCATION.fullString,
      customReportKey: "locations",
    },
  ],
  customFieldObjectTypes: ["item", "rental"],
  columns: [
    { apiName: "Rental #", default: true },
    { apiName: "Rental Date", default: true },
    { apiName: "Customer" },
    { apiName: "Item", default: true },
    { apiName: "Location" },
    { apiName: "Description", default: true },
    { apiName: "Rented", default: true, number: true },
    { apiName: "Returned", number: true },
    { apiName: "Outstanding", default: true, number: true },
    { apiName: "UOM" },
    { apiName: "Due Date" },
    { apiName: "Comment" },
    { apiName: "On Hand" },
    { apiName: "Vendor" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      defaultValue: () => new Date(),
      datePrefix: "startOf",
      oneYearMax: true,
      type: "filter",
    },
    {
      apiName: "date1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      oneYearMax: true,
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Location"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: [ALL_FILTER_OPTION],
      includeAllOption: true,
      type: "filter",
    },
    {
      label: i18n("filter.Item"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
      itemType: ITEM_QUICKLIST_CASES.SERIAL,
    },
    {
      label: i18n("reports.FilterLabel.SerialNumberContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
