import { Card, CardContent } from "@mui/material";

import { LocationToggle } from "components/formFields/address/LocationToggle";
import { CardHeader } from "components/formFields/formCards/CardHeader";
import { FrmLocation } from "components/formFields/frm/FrmLocation";

export function ShippingAddress(props) {
  const { value, location, locations, handleInputFieldChange, label } = props;

  return (
    <Card variant="outlined" data-testing="shipping">
      <CardContent>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CardHeader text={label} gutterBottom={false} />
          <div style={{ flexGrow: 1, paddingLeft: "1rem" }}>
            <FrmLocation
              value={location}
              locations={locations}
              onValueChange={handleInputFieldChange}
            />
          </div>
        </div>
        <LocationToggle
          value={value}
          addressType="shipping"
          handleInputFieldChange={handleInputFieldChange}
        />
      </CardContent>
    </Card>
  );
}
