export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixEstimate: record.estimateNumber.prefix,
    nextEstimateNumber: record.estimateNumber.nextNumber,
  };

  delete newRecord.estimateNumber;

  return newRecord;
}
