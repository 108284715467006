import { Decimal } from "classes/DecimalClasses";
import { Loading } from "classes/Loading";

import { i18n } from "services/i18nService";
import { afterTouchLine as baseAfterTouchLine } from "services/utility/afterTouchLine";
import {
  updateLot,
  updateSerials,
  getUomConversion,
} from "services/utility/afterTouchLine";
import { isNonZeroDecimalOrMoney } from "services/utility/misc";

import { openAlert } from "globalState/alertSlice";
import globalState from "globalState/globalState";

const UPDATERS = {
  quantityDiff: [validateQuantityDiff, updateNewQuantity, updateValueDiff],
  newQuantity: [validateNewQuantity, updateQuantityDiff, updateValueDiff],
  uom: [updateOnHand, updateNewQuantity],
  bin: [updateLot, updateSerials],
};

export function afterTouchLine(line, changedField) {
  return baseAfterTouchLine(UPDATERS, line, changedField);
}

function updateNewQuantity(line) {
  const { onhand, quantityDiff } = line;

  const newQuantity = isNonZeroDecimalOrMoney(onhand)
    ? onhand.plus(quantityDiff)
    : quantityDiff;
  return { ...line, newQuantity };
}

function updateQuantityDiff(line) {
  const { onhand, newQuantity } = line;
  const quantityDiff = isNonZeroDecimalOrMoney(onhand)
    ? newQuantity.minus(onhand)
    : newQuantity;
  return { ...line, quantityDiff };
}

function updateValueDiff(line) {
  return { ...line, valueDiff: new Loading() };
}

function updateOnHand(line) {
  const {
    uom,
    oldUom,
    relatedRecords: { item },
    quantityDiff,
    itemDetails,
  } = line;

  // calculate the conversions using the uom data
  const conversion = getUomConversion(itemDetails, uom);
  const oldConversion = getUomConversion(itemDetails, oldUom);

  // calculate the on hand and quantity difference
  // using the conversion ratio
  const onhand = isNonZeroDecimalOrMoney(item?.onhand)
    ? item.onhand.div(conversion)
    : new Decimal(0);

  const difference = quantityDiff.times(oldConversion).div(conversion);
  return { ...line, onhand, quantityDiff: difference };
}

function validateQuantityDiff(line) {
  const {
    quantityDiff,
    relatedRecords: { item },
  } = line;

  if (item?.serialTracking && quantityDiff.gt(Decimal.ZERO)) {
    const message = i18n("error.PositiveSerialAdjustment");
    globalState.dispatch(openAlert({ type: "error", message }));
    return { ...line, quantityDiff: Decimal.ZERO };
  }
  return line;
}

function validateNewQuantity(line) {
  const {
    newQuantity,
    onhand,
    relatedRecords: { item },
  } = line;

  if (item?.serialTracking && newQuantity.gt(onhand)) {
    const message = i18n("error.PositiveSerialAdjustment");
    globalState.dispatch(openAlert({ type: "error", message }));
    return { ...line, newQuantity: onhand };
  }
  return line;
}
