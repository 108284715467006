import { i18n } from "services/i18nService";
import { formatNameLinkField } from "services/utility/formatting";

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: formatNameLinkField,
    fixed: true,
    defaultSort: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "message",
    heading: i18n("columns.Message"),
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
  },
];

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];

export function getEmptyRecord() {
  return { name: null, message: null };
}
