function getPrivilege(stringPosition, privilegeString) {
  return Boolean(+privilegeString?.charAt(stringPosition));
}

export function afterGet(record) {
  const newRecord = { ...record };
  const binaryString = record.privileges.toString(2).padStart(10, "0");
  newRecord.privileges = {
    sysAdmin: getPrivilege(0, binaryString),
    sosSalesManager: getPrivilege(1, binaryString),
    sosSales: getPrivilege(2, binaryString),
    sosQA: getPrivilege(3, binaryString),
    sosDevelopment: getPrivilege(4, binaryString),
    sosAccounting: getPrivilege(5, binaryString),
    sosImpersonate: getPrivilege(6, binaryString),
    sosSupportTier3: getPrivilege(7, binaryString),
    sosSupportTier2: getPrivilege(8, binaryString),
    sosSupport: getPrivilege(9, binaryString),
  };
  return newRecord;
}
