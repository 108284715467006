import { FrmItemSelect } from "components/formFields/frm";

export function EditItemFilter(props) {
  const { filters, setFilters, type } = props;
  const { filterSettings } = filters;
  const { Item } = filterSettings;

  function handleChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        Item: value?.id || "",
      },
      startingRecord: 1,
    }));
  }

  return (
    <FrmItemSelect
      name="item"
      type={type}
      value={Item ? { id: +Item } : null}
      onValueChange={handleChange}
      dataTesting="itemFilter"
    />
  );
}
