import { INACTIVITY_TIMEOUT_OPTIONS } from "services/sosInventoryService/settings/company/general/schema";
import { getTimeZoneOptions } from "services/utility/dates";

export function afterGet(record) {
  const newRecord = { ...record };

  newRecord.inactivityTimeoutMinutes = INACTIVITY_TIMEOUT_OPTIONS.find(
    ({ id }) => id === record.inactivityTimeoutMinutes
  )?.value;

  newRecord.defaultTimeZone = getTimeZoneOptions().find(
    ({ id }) => id === record.defaultTimeZone
  );

  return newRecord;
}
