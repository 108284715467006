import { SERVER } from "appConfig";

import globalState from "globalState/globalState";

const LOGGLY_CUSTOMER_TOKEN = process.env.REACT_APP_LOGGLY_CUSTOMER_TOKEN;
const ERROR_EXPLAINER_URL = process.env.REACT_APP_ERROR_EXPLAINER_URL;
const LOGGLY_URL = `https://logs-01.loggly.com/inputs/${LOGGLY_CUSTOMER_TOKEN}`;
const ERROR_EXPLAINER_ROUTE = `${ERROR_EXPLAINER_URL}/map/live`;
const SECONDS = 1000;
const ERROR_EXPLAINER_TIMEOUT = 3 * SECONDS;

function getEnvironment() {
  const environment = process.env.REACT_APP_ENVIRONMENT;

  // TODO: Update to return environment once
  // https://app.clickup.com/t/2p83rup complete
  switch (environment) {
    case "v9CI":
      return "v9CI";
    case "test":
      return "v9-test";
    case "beta":
      return "v9beta";
    default:
      return "v9development";
  }
}

export async function logError(error, level) {
  const { company, user } = globalState.getState().userCompanySettings;

  const logInfo = {
    environment: getEnvironment(),
    site: SERVER,
    url: window.location.pathname,
    error: error.message,
    stacktrace: error.stack,
    level,
    via: "sos-inventory-react",
    tenant: company.id,
    tenantName: company.name,
    planLevel: company.planLevel,
    subscriptionStatus: company.status,
    user: user.email,
    userId: company.userId,
    userName: user.name,
  };
  try {
    if (ERROR_EXPLAINER_URL) {
      const abortController = new AbortController();
      const timeoutId = setTimeout(
        () => abortController.abort(),
        ERROR_EXPLAINER_TIMEOUT
      );
      await fetch(ERROR_EXPLAINER_ROUTE, {
        method: "POST",
        cache: "no-cache",
        referrerPolicy: "no-referrer",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(logInfo),
        signal: abortController.signal,
      });
      clearTimeout(timeoutId);
    } else {
      throw new Error("ERROR_EXPLAINER_URL not defined");
    }
  } catch (error) {
    console.error("logToErrorExplainer | %o", error.message);
    try {
      await fetch(LOGGLY_URL, {
        method: "POST",
        cache: "no-cache",
        referrerPolicy: "no-referrer",
        headers: new Headers({ "Content-Type": "text/plain" }),
        body: JSON.stringify(logInfo),
      });
    } catch (error) {
      console.error("logToLoggly | %o", error.message);
      return false;
    }
  }
  return true;
}
