import { i18n } from "services/i18nService";

import { initialErrorState } from "views/common";

export function isValid(record) {
  const errors = initialErrorState();

  if (!record.item) {
    errors.messages.push(i18n("reorder.Error.Item"));
    errors.entity.push("item");
  }

  return errors;
}
