import { useState } from "react";

import { ActionButton } from "components/ActionButton";
import { Tooltip } from "components/Tooltip";
import { FrmAutoSerialLots } from "components/formFields/frm/FrmAutoSerialLots";
import { ButtonProgress } from "components/utility/ButtonProgress";

import { i18n } from "services/i18nService";

import { ITEM_TYPES } from "appConstants";

export function BottomWidgets(props) {
  const {
    output,
    updateInputs,
    handleInputFieldChange,
    autoSerialLotNumbering,
  } = props;

  const [inProgress, setInProgress] = useState(false);

  async function handleUpdateInputs() {
    setInProgress(true);
    await updateInputs();
    setInProgress(false);
  }

  const isAssembly = output?.relatedRecords.item?.type === ITEM_TYPES.ASSEMBLY;

  return (
    <div style={{ marginBottom: "0.5rem" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        {!isAssembly ? (
          <Tooltip title={i18n("hoverHelp.UpdateInputs")}>
            <div>
              <UpdateInputButton
                disabled={!isAssembly}
                onClick={handleUpdateInputs}
              />
            </div>
          </Tooltip>
        ) : (
          <div style={{ position: "relative" }}>
            <UpdateInputButton
              disabled={!isAssembly}
              onClick={handleUpdateInputs}
            />
            {inProgress && <ButtonProgress dataTesting="updateInputsLoading" />}
          </div>
        )}
      </div>
      <FrmAutoSerialLots
        value={autoSerialLotNumbering}
        name="autoSerialLotNumbering"
        onValueChange={handleInputFieldChange}
      />
    </div>
  );
}

function UpdateInputButton(props) {
  const { disabled, onClick } = props;
  return (
    <ActionButton
      dataTesting="updateInputs"
      disabled={disabled}
      onClick={onClick}
    >
      {i18n("global.UpdateInputs")}
    </ActionButton>
  );
}
