import { Typography } from "@mui/material";

import { theme } from "SosTheme";

export function ModalTitle(props) {
  const { label, text } = props;
  return (
    <Typography
      sx={{
        overflow: "hidden",
        paddingRight: "1.5rem",
        paddingLeft: "1.5rem",
        marginTop: "2px",
      }}
      variant="h3"
      data-testing="modalTitle"
    >
      <div
        style={{
          display: "grid",
          alignItems: "start",
          gridTemplateColumns: "max-content min-content 1fr",
        }}
      >
        <div
          style={{
            color: theme.palette.modalHeader,
            fontSize: "0.9em",
            position: "relative",
            top: "2px",
          }}
        >
          {label}
        </div>
        <div
          style={{
            color: theme.palette.accent,
            fontSize: "0.9em",
            position: "relative",
            top: "1px",
            marginLeft: "0.3em",
            marginRight: "0.3em",
          }}
        >
          {text ? `|` : ""}
        </div>
        <div
          style={{
            color: theme.palette.accent,
            fontWeight: 500,
            overflowWrap: "anywhere",
          }}
        >
          {text ? `${text}` : ""}
        </div>
      </div>
    </Typography>
  );
}
