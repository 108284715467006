import { useEffect, useState } from "react";

import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ShippingAddress } from "components/formFields/address/rma/Shipping";
import { BillingAddress } from "components/formFields/address/sales/Billing";

import { i18n } from "services/i18nService";
import { getCustomerAddresses } from "services/sosInventoryService/salesTransaction/domainLogic";

export function Addresses(props) {
  const {
    billing,
    shipping,
    location,
    locations,
    customer,
    handleInputFieldChange,
  } = props;

  const [addresses, setAddresses] = useState({});

  useEffect(() => {
    if (customer?.id) {
      setAddresses(getCustomerAddresses(customer));
    }
  }, [customer]);

  return (
    <FrmFieldGrid gridAutoFlow="dense">
      <div style={{ maxWidth: "30rem" }}>
        <BillingAddress
          value={billing}
          locations={addresses.shipping}
          handleInputFieldChange={handleInputFieldChange}
          label={i18n("address.CustomerAddress")}
        />
      </div>
      <div style={{ maxWidth: "30rem" }}>
        <ShippingAddress
          value={shipping}
          location={location}
          locations={locations}
          handleInputFieldChange={handleInputFieldChange}
          label={i18n("address.Shipping")}
        />
      </div>
    </FrmFieldGrid>
  );
}
