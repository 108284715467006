import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";

import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";

import "appConfig";

import { ErrorHandler } from "components/ErrorHandler";
import { GlobalLayout } from "components/GlobalLayout";
import { Timeout } from "components/utility/Timeout";

import { setAuthenticationState } from "services/utility/authentication";

import { theme } from "SosTheme";

export default function App() {
  useEffect(() => {
    async function _setAuthenticationState() {
      await setAuthenticationState();
    }
    _setAuthenticationState();
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <ErrorHandler>
            <GlobalLayout />
            <Timeout />
          </ErrorHandler>
        </BrowserRouter>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
