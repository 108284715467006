import { i18n } from "services/i18nService";
import {
  extractName,
  formatDateTimeToDateTimeString,
  extractLinkedTransactions,
  extractPaymentMethod,
  formatMoneyWithAdornments,
  formatDecimal,
} from "services/utility/formatting";

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: ["amount"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["date"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const columnInfo = [
  {
    name: "refund",
    heading: i18n("columns.Restore"),
    buttonLabel: i18n("columns.Refund"),
    buttonAction: "refund",
    isButton: true,
    fixed: true,
  },
  {
    name: "id",
    heading: i18n("columns.ReferenceNumber"),
    minWidth: "7em",
    default: true,
  },
  {
    name: "date",
    heading: i18n("columns.Date"),
    formatFunc: formatDateTimeToDateTimeString,
    defaultSort: true,
    default: true,
    sortable: true,
    sortDirection: "asc",
  },
  {
    name: "paymentMethod",
    heading: i18n("columns.PmtMethod"),
    formatFunc: extractPaymentMethod,
    default: true,
  },
  {
    name: "customer",
    heading: i18n("columns.Customer"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "25em",
    default: true,
    sortable: true,
  },
  {
    name: "amount",
    heading: i18n("columns.Amount"),
    formatFunc: formatMoneyWithAdornments,
    align: "right",
    default: true,
    sortable: true,
  },
  {
    name: "lastFour",
    heading: i18n("columns.Last4"),
    formatFunc: formatDecimal,
    minWidth: "6em",
    align: "right",
  },
  {
    name: "paymentReference",
    fieldName: "linkedPayments",
    heading: i18n("columns.PmtRef"),
    formatFunc: extractLinkedTransactions,
  },
  {
    name: "status",
    heading: i18n("columns.Status"),
    default: true,
    sortable: true,
  },
  {
    name: "reason",
    heading: i18n("columns.Reason"),
  },
  {
    name: "txnId",
    heading: i18n("columns.TxnId"),
  },
  {
    name: "authCode",
    heading: i18n("columns.AuthCode"),
  },
  {
    name: "currency",
    heading: i18n("columns.Currency"),
    formatFunc: extractName,
  },
  {
    name: "notes",
    heading: i18n("columns.Notes"),
    default: true,
    sortable: true,
  },
];

const columnArray = Object.entries(columnInfo);
const defaultColumnInfo = columnArray.filter((col) => col[1].required);
export const defaultColumns = defaultColumnInfo.map((col) => col[0]);

export const LINE_ACTIONS = ["refund"];
export const BATCH_ACTIONS = ["refund"];
