import { TableCell } from "@mui/material";

function sx(column, stickyColumns) {
  const { name, verticalAlign, minWidth, maxWidth } = column;
  return {
    verticalAlign: verticalAlign || "top",
    minWidth: stickyColumns[column.name]
      ? stickyColumns[name].width
      : minWidth
      ? minWidth
      : "auto",
    maxWidth: stickyColumns[name]
      ? stickyColumns[name].width
      : maxWidth
      ? maxWidth
      : "auto",
    position: stickyColumns[name] ? "sticky" : "static",
    left: stickyColumns[name] ? stickyColumns[name].left : "auto",
    backgroundColor: "inherit",
    zIndex: stickyColumns[name] ? "stickyColumn" : "auto",
    padding: name === "starred" ? "0.4rem 0" : "0.5rem 1rem",
  };
}

export function LineCell(props) {
  const { column, children, stickyColumns = {} } = props;
  return (
    <TableCell
      align={column?.align}
      sx={{
        ...sx(column, stickyColumns),
        margin: 0,
        wordWrap: "break-word",
        whiteSpace: "pre-line",
      }}
    >
      {children}
    </TableCell>
  );
}
