import { SideButton } from "components/SideButton";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { checkForUnexpectedProps } from "services/utility/misc";

const styles = {
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  paddingRight: "1rem",
};

export function SelectButtonCombo(props) {
  const {
    value,
    name,
    onClick,
    onValueChange,
    selectLabel,
    buttonLabel,
    options,
    disableClearable,
    minWidth,
    maxWidth,
    disabled,
    ...unexpected
  } = props;
  checkForUnexpectedProps("SelectButtonCombo", unexpected);

  return (
    <div style={{ display: "flex" }}>
      <FrmSelectFromObjects
        name={name}
        label={selectLabel}
        value={value}
        onValueChange={onValueChange}
        options={options}
        InputProps={{ sx: styles }}
        disableClearable={disableClearable}
        maxWidth={maxWidth}
        minWidth={minWidth}
        disabled={disabled}
      />
      <SideButton onClick={onClick} label={buttonLabel} />
    </div>
  );
}
