import { useEffect } from "react";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { NEW_RECORD_SKELETON } from "appConfig";

import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmTextFieldWithHoverHelp } from "components/formFields/FrmTextFieldWithHoverHelp";
import { FrmItemSelect } from "components/formFields/frm";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { getRecord } from "services/sosInventoryService/sosApi";

import { useErrors } from "hooks/useErrors";
import { useRecord } from "hooks/useRecord";
import { useRelated } from "hooks/useRelated";

import { ITEM_QUICKLIST_CASES } from "appConstants";

export function VendorItem(props) {
  const { id, onClose, objectType } = props;

  const vendors = useRelated("vendor");
  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();

  const loadingState = useSelector((state) => state.loading.editModal);

  useEffect(() => {
    async function _getRecord() {
      if (id) {
        const record = await getRecord("vendoritem", id);
        setRecord(record);
      } else {
        const emptyRecord = NEW_RECORD_SKELETON[objectType]();
        setRecord(emptyRecord);
      }
    }
    _getRecord();
  }, [setRecord, id, objectType]);

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={objectType}
        setErrors={setErrors}
        text={record?.name || ""}
        handleClose={onClose}
      />

      {loadingState && <Loading />}

      {record && (
        <ObjectEditDialogContent>
          <Layout
            pageTitle={
              id
                ? `${i18n(`global.${objectType}.Sentence`)} ${
                    record?.name || ""
                  }`
                : i18n(`global.${objectType}.New`)
            }
          >
            <Box flexGrow="1" position="relative" m={2} mb={0}>
              <FormErrors errors={errors} setErrors={setErrors} />
              {record ? (
                <Box>
                  <FrmFieldGrid gridAutoFlow="dense">
                    <div style={{ gridColumn: "1 / 2", maxWidth: "24rem" }}>
                      <FrmItemSelect
                        name="item"
                        value={record.item}
                        onValueChange={handleInputFieldChange}
                        type={ITEM_QUICKLIST_CASES.ALL}
                        error={isInError("item")}
                      />
                    </div>
                    <div style={{ gridColumn: "1 / 2", maxWidth: "24rem" }}>
                      <FrmFieldGrid gridAutoFlow="dense">
                        <FrmSelectFromObjects
                          name="vendor"
                          options={vendors}
                          label={i18n("frmLabel.Vendor")}
                          onValueChange={handleInputFieldChange}
                          value={record.vendor}
                          dataTesting="vendor"
                        />
                      </FrmFieldGrid>
                    </div>
                    <div style={{ gridColumn: "1 / 2", maxWidth: "8rem" }}>
                      <FrmTextFieldWithHoverHelp
                        money
                        name="price"
                        label={i18n("frmLabel.Price")}
                        onValueBlur={handleInputFieldChange}
                        value={record.price}
                        helpText={i18n("hoverHelp.vendorItem.Price")}
                      />
                    </div>
                    <div style={{ gridColumn: "1 / 2", maxWidth: "24rem" }}>
                      <FrmTextFieldWithHoverHelp
                        name="vendorPartNumber"
                        label={i18n("frmLabel.VendorPartNumber")}
                        onValueBlur={handleInputFieldChange}
                        value={record.vendorPartNumber}
                        helpText={i18n("hoverHelp.vendorItem.VendorPartNumber")}
                      />
                    </div>
                    <div style={{ gridColumn: "1 / 2", maxWidth: "8rem" }}>
                      <FrmTextFieldWithHoverHelp
                        number
                        name="leadTime"
                        label={i18n("frmLabel.LeadTime")}
                        onValueBlur={handleInputFieldChange}
                        value={record.leadTime}
                        helpText={i18n("hoverHelp.vendorItem.LeadTime")}
                      />
                    </div>
                  </FrmFieldGrid>
                  <VSpace space={1} />
                  <FrmFieldGrid gridAutoFlow="dense"></FrmFieldGrid>
                </Box>
              ) : (
                !isInError("record") && (
                  <FixedLoadingIndicator
                    text={`${i18n("global.Loading")}...`}
                  />
                )
              )}
            </Box>
            <VSpace space={4} />
          </Layout>
        </ObjectEditDialogContent>
      )}
    </>
  );
}
