import { FilterSelectField } from "components/formFields/FilterSelectField";

import { i18n } from "services/i18nService";

import { SYNC_ITEM_ACTIONS } from "appConstants";

const SYNC_ACTION_FILTER_OPTIONS = [...SYNC_ITEM_ACTIONS];
SYNC_ACTION_FILTER_OPTIONS.unshift({ id: "", name: i18n("filter.All") });

export function EditActionFilter(props) {
  const { filters, setFilters } = props;
  const { filterSettings } = filters;
  const { Action = "" } = filterSettings;

  function handleChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        Action: value?.id || "",
      },
      startingRecord: 1,
    }));
  }
  return (
    <FilterSelectField
      options={SYNC_ACTION_FILTER_OPTIONS}
      onValueChange={handleChange}
      value={{ id: Action }}
      label={i18n("filter.Action")}
      dataTesting="statusFilter"
      disableClearable
    />
  );
}
