import { LineItemNumericCalculatorField } from "components/formFields/LineItemNumericCalculatorField";
import { LineTableCell } from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineQuantity(props) {
  const {
    value,
    suggestedQuantity,
    onValueChange,
    objectType,
    error,
    disabled = false,
  } = props;

  return (
    <LineTableCell sx={{ minWidth: "5.5rem" }}>
      <LineItemNumericCalculatorField
        name="quantity"
        value={value}
        toolTipText={
          suggestedQuantity !== undefined && suggestedQuantity !== ""
            ? i18n("purchaseOrder.SuggestedQuantity") + ":" + suggestedQuantity
            : ""
        }
        onValueChange={onValueChange}
        metadata={LINE_ITEM_METADATA[objectType].quantity}
        error={error}
        dataTesting="lineQuantity"
        disabled={disabled}
      />
    </LineTableCell>
  );
}
