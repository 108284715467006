import { Typography } from "@mui/material";

export function CardHeader(props) {
  const { text, gutterBottom = true, sx = {} } = props;
  return (
    <Typography sx={sx} variant="subtitle1" gutterBottom={gutterBottom}>
      {text}
    </Typography>
  );
}
