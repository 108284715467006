import {
  DEFAULT_DATE_FORMAT_OPTIONS,
  DEFAULT_REPORT_FORMAT_OPTIONS,
  INACTIVITY_TIMEOUT_OPTIONS,
  STAR_SETTINGS_OPTIONS,
} from "services/sosInventoryService/settings/company/general/schema";
import { getTimeZoneOptions } from "services/utility/dates";

export const SETTINGS = [
  {
    apiName: "defaultTimeZone",
    component: "FrmSelectFromObjects",
    options: getTimeZoneOptions(),
  },
  {
    apiName: "defaultDateFormat",
    component: "FrmRadioGroup",
    options: DEFAULT_DATE_FORMAT_OPTIONS,
  },
  {
    apiName: "starSettings",
    component: "FrmRadioGroup",
    options: STAR_SETTINGS_OPTIONS,
  },
  {
    apiName: "numLinesToAdd",
    component: "FrmTextField",
    number: true,
    width: "8rem",
  },
  {
    apiName: "defaultReportFormat",
    component: "FrmRadioGroup",
    options: DEFAULT_REPORT_FORMAT_OPTIONS,
  },
  { apiName: "childDocsGetSameRefNumber", component: "FrmCheckbox" },
  { apiName: "sendEmailFromCustomerDomain", component: "FrmCheckbox" },
  {
    apiName: "inactivityTimeoutMinutes",
    component: "FrmInactivityTimeout",
    options: INACTIVITY_TIMEOUT_OPTIONS,
    number: true,
  },
  { apiName: "paymentReceiptCC", component: "FrmEmail", maxLength: 255 },
];
