import { useEffect } from "react";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { NEW_RECORD_SKELETON } from "appConfig";

import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmAssignedTo } from "components/formFields/frm";
import { FrmCheckbox } from "components/formFields/frm/FrmCheckbox";
import { DateTimePicker } from "components/html/Input/DateTimePicker";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { getRecord } from "services/sosInventoryService/sosApi";

import { useErrors } from "hooks/useErrors";
import { useRecord } from "hooks/useRecord";

export function Task(props) {
  const { id, onClose, record: recordFromNotification, objectType } = props;

  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();

  const loadingState = useSelector((state) => state.loading.editModal);

  useEffect(() => {
    async function _getRecord() {
      if (id) {
        const record = await getRecord(objectType, id);
        setRecord(record);
      } else {
        const emptyRecord =
          recordFromNotification || NEW_RECORD_SKELETON[objectType]();
        setRecord(emptyRecord);
      }
    }
    _getRecord();
  }, [setRecord, id, objectType, recordFromNotification]);

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={objectType}
        setErrors={setErrors}
        text={record?.name || ""}
        handleClose={onClose}
      />

      {loadingState && <Loading />}

      {record && (
        <ObjectEditDialogContent>
          <Layout
            pageTitle={
              id
                ? `${i18n("objectType.task.Sentence")} ${record?.name || ""}`
                : i18n("objectType.task.New")
            }
          >
            <Box flexGrow="1" position="relative" m={2} mb={0}>
              <FormErrors errors={errors} setErrors={setErrors} />
              {record ? (
                <Box>
                  <FrmFieldGrid gridAutoFlow="dense">
                    <div style={{ gridColumn: "1 / 2" }}>
                      <FrmTextField
                        name="name"
                        value={record.name}
                        maxLength={50} // back end doesn't allow for more than 50 characters
                        label={i18n("frmLabel.Name")}
                        onValueBlur={handleInputFieldChange}
                        error={isInError("name")}
                      />
                    </div>
                    <div style={{ gridColumn: "1 / 2" }}>
                      <DateTimePicker
                        name="duedate"
                        value={record?.dueDate}
                        label={i18n("frmLabel.CompleteBy")}
                        onValueChange={handleInputFieldChange}
                        hideNowButton={true}
                      />
                      <FrmCheckbox
                        name="completed"
                        value={record.completed}
                        onValueChange={handleInputFieldChange}
                        label={i18n("frmLabel.Completed")}
                        sx={{ marginRight: "8px" }}
                      />
                      {recordFromNotification && (
                        <div>
                          <FrmCheckbox
                            name="deleteNotification"
                            value={record.deleteNotification}
                            onValueChange={handleInputFieldChange}
                            label={i18n(
                              "frmLabel.DeletedTheLinkedNotification"
                            )}
                            sx={{ marginRight: "8px" }}
                          />
                        </div>
                      )}
                    </div>

                    <div style={{ gridColumn: "2 / 2" }}>
                      <FrmAssignedTo
                        maxWidth="20rem"
                        value={record?.assignedToUser}
                        onValueChange={handleInputFieldChange}
                      />
                    </div>
                    <div style={{ gridColumn: "2 / 2" }}>
                      <FrmResizeTextField
                        name="description"
                        value={record?.description}
                        label={i18n("frmLabel.Description")}
                        minRows={2}
                        width="100%"
                        onValueBlur={handleInputFieldChange}
                      />
                    </div>
                  </FrmFieldGrid>
                  <VSpace space={1} />
                  <FrmFieldGrid gridAutoFlow="dense"></FrmFieldGrid>
                </Box>
              ) : (
                !isInError("record") && (
                  <FixedLoadingIndicator
                    text={`${i18n("global.Loading")}...`}
                  />
                )
              )}
            </Box>
            <VSpace space={4} />
          </Layout>
        </ObjectEditDialogContent>
      )}
    </>
  );
}
