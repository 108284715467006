import { Typography } from "@mui/material";

import {
  AGGREGATOR_ENTER,
  SerialsAggregator,
} from "components/formFields/Serials/SerialsAggregator/SerialsAggregator";
import { subLineWrapperStyle } from "components/formFields/lineItemFieldStyle";

import { i18n } from "services/i18nService";
import { LINE_ITEM_METADATA } from "services/sosInventoryService/returnToVendor/schema";

export function LineSerialsEnter(props) {
  const { value, quantity, autoNumber, onValueChange, error } = props;

  return (
    <div style={subLineWrapperStyle}>
      <Typography sx={{ flex: "0 1 auto", fontSize: "0.8125rem" }}>
        {i18n("columns.colon.Serials")}
      </Typography>
      <SerialsAggregator
        enterOrSelect={AGGREGATOR_ENTER}
        value={value}
        autoNumber={autoNumber}
        quantity={quantity}
        metadata={LINE_ITEM_METADATA.serials}
        onValueChange={onValueChange}
        error={error}
      />
    </div>
  );
}
