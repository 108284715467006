// TODO: This doesn't handle currency values with adornment(s) (like "$" prefix);
// it may need to at some point.
import { Typography } from "@mui/material";

const DEFAULT_STYLE = {
  paddingLeft: "0.7rem",
  paddingTop: "0.44px",
  whiteSpace: "nowrap",
  fontSize: "0.625rem",
  fontStyle: "italic",
};

export function LineItemErrorText(props) {
  const { id, name, value, style, color = "error" } = props;

  return (
    <Typography id={id} name={name} sx={style || DEFAULT_STYLE} color={color}>
      {value}
    </Typography>
  );
}
