import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

import { OBJECT_TYPES } from "appConstants";

export function FrmPriceTier(props) {
  const { value, onValueChange } = props;

  const priceTiers = useRelated(OBJECT_TYPES.PRICE_TIER.fullString);

  const priceTier =
    value && priceTiers ? priceTiers.find(({ id }) => id === value?.id) : null;

  return (
    <FrmSelectFromObjects
      name="priceTier"
      label={i18n("frmLabel.PriceTier")}
      value={priceTier}
      onValueChange={onValueChange}
      options={priceTiers || []}
    />
  );
}
