import { normalizeWorkCenters } from "services/utility/beforeSave.js";
import { convertNativeToCustom } from "services/utility/customFields";

export function beforeSave(record) {
  const newRecord = { ...record };

  newRecord.number = record.number === "auto" ? "" : record.number;
  newRecord.customFields = convertNativeToCustom(record.customFields);
  newRecord.workcenters = normalizeWorkCenters(record.workcenters);
  return newRecord;
}
