import { useState } from "react";
import { useSelector } from "react-redux";

import { Container, Button, Typography } from "@mui/material";

import { getIsAutomatedTestingAccount } from "appConfig";

import { dbReset } from "services/test/dbReset";

export function DbReset() {
  const [status, setStatus] = useState(["ready"]);

  function addToStatus(statusUpdate) {
    setStatus((prevStatus) => [...prevStatus, statusUpdate]);
  }

  const { company } = useSelector((state) => state.userCompanySettings);

  // check to make sure we are in a testing environment
  // before showing the database reset button
  if (
    !(
      ["development", "test"].includes(process.env.NODE_ENV) ||
      process.env.REACT_APP_ENVIRONMENT === "v9CI"
    )
  ) {
    return null;
  }

  if (!getIsAutomatedTestingAccount(company)) {
    return null;
  }

  return (
    <Container sx={{ paddingTop: "20px" }}>
      <Button
        variant="outlined"
        data-testing="resetButton"
        onClick={() => dbReset(addToStatus)}
      >
        Reset
      </Button>
      <div data-testing="status">
        {status.map((statusLine, lineIndex) => (
          <Typography key={lineIndex}>{statusLine}</Typography>
        ))}
      </div>
    </Container>
  );
}
