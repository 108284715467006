import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useChartOfAccounts } from "hooks/useChartOfAccounts";

export function FrmDepositAccount(props) {
  const { value, onValueChange, maxWidth, error } = props;
  const accounts = useChartOfAccounts(true);
  const depositAccounts = accounts ? accounts.deposit : null;

  if (!depositAccounts) {
    return null;
  }

  return (
    <FrmSelectFromObjects
      name="depositAccount"
      label={i18n("frmLabel.DepositAccount")}
      maxWidth={maxWidth}
      value={value}
      onValueChange={onValueChange}
      options={depositAccounts}
      error={error}
    />
  );
}
