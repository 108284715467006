import { useState, useRef } from "react";

import { KeyboardArrowDown } from "@mui/icons-material";
import { Menu, MenuItem, Button } from "@mui/material";

import { DeleteDialog } from "components/DeleteDialog";
import { Divider } from "components/Divider";

import { i18n } from "services/i18nService";

export function BatchActions(props) {
  const { checkedIds, setCheckedIds, setSelectAllState } = props;

  const [showDelete, setShowDelete] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuRef = useRef();

  function handleClose() {
    setShowDelete(false);
    setAnchorEl(null);
    setCheckedIds([]);
    setSelectAllState(false);
  }

  const disabled = !checkedIds.length;

  return (
    <>
      <Button
        ref={menuRef}
        onClick={() => setAnchorEl(menuRef.current)}
        sx={{ cursor: "pointer" }}
      >
        {i18n("global.BatchActions")}
        <KeyboardArrowDown sx={{ marginLeft: "0.5rem" }} />
      </Button>
      <Menu
        open={Boolean(anchorEl)}
        keepMounted={true}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div>
          {disabled && (
            <>
              <MenuItem
                sx={{
                  color: "warning.error",
                  cursor: "default",
                  backgroundColor: "transparent",
                }}
              >
                {i18n("entityActions.BatchActionDisabled", {
                  objectType: i18n(`objectType.notification.Lower`),
                })}
              </MenuItem>
              <Divider />
            </>
          )}
          <MenuItem disabled={disabled} onClick={() => setShowDelete(true)}>
            {i18n("global.Delete")}
          </MenuItem>
        </div>
      </Menu>
      <DeleteDialog
        open={showDelete}
        objectType="notification"
        objectIds={checkedIds}
        message={i18n("global.deleteSelectedRecords")}
        onClose={handleClose}
      />
    </>
  );
}
