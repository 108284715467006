// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { i18n } from "services/i18nService";
import {
  formatDateTimeToDateTimeString,
  replaceSpaceWithNewLine,
  truncate,
} from "services/utility/formatting";
import { parseHTML } from "services/utility/parsing";

export const EMPTY_RECORD = { dateAdded: null, message: null };

export const columnInfo = [
  {
    name: "message",
    heading: i18n("columns.Message"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    verticalAlign: "middle",
    default: true,
  },
  {
    name: "date",
    fieldName: "dateAdded",
    heading: i18n("columns.Date"),
    formatFunc: [formatDateTimeToDateTimeString, replaceSpaceWithNewLine],
    defaultSort: true,
    sortDirection: "asc",
    width: "8rem",
    default: true,
  },
];

const columnArray = Object.entries(columnInfo);
const defaultColumnInfo = columnArray.filter((col) => col[1].required);
export const defaultColumns = defaultColumnInfo.map((col) => col[0]);

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["dateAdded"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];
export const REFERENCE_FIELDS = [];
export const ITEM_CALCULATED_FIELDS = [];
