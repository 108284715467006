export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixRTV: record.returnToVendorNumber.prefix,
    nextRTVNumber: record.returnToVendorNumber.nextNumber,
  };

  delete newRecord.returnToVendorNumber;

  return newRecord;
}
