import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getAndSortWorkCenters } from "services/sosInventoryService/domainLogic";
import { getEmptyRecord } from "services/sosInventoryService/job/schema";
import { getRecord } from "services/sosInventoryService/sosApi";
import { reconcileCustomFields } from "services/utility/customFields";
import { setPageDirty } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { OBJECT_TYPES } from "appConstants";

// Vendors, Items, and Customers
export function useJob(
  id,
  setRecord,
  setWorkCenters,
  customFieldDefs,
  stageHandler
) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!customFieldDefs) {
      return;
    }
    if (id) {
      async function getVic() {
        dispatch(editModalLoadingIndicatorOn());
        let record;
        try {
          record = await getRecord(OBJECT_TYPES.JOB.fullString, id);
          record.customFields = reconcileCustomFields(
            customFieldDefs,
            record.customFields
          );

          setRecord(record);
          stageHandler({ type: "set", lines: record.workcenters });

          const stages = await getAndSortWorkCenters();
          setWorkCenters(stages);
        } catch (e) {
          handleProgramError(e);
        }

        dispatch(editModalLoadingIndicatorOff());
      }
      getVic();
    } else {
      // new record
      async function getStages() {
        const stages = await getAndSortWorkCenters();

        stageHandler({ type: "set", lines: stages });
        setWorkCenters(stages);
      }
      setRecord((prevRecord) => ({
        ...getEmptyRecord(),
        customFields: reconcileCustomFields(customFieldDefs, []),
        name: prevRecord?.name,
      }));
      getStages();
    }
    setPageDirty(false);
  }, [dispatch, id, setRecord, customFieldDefs, stageHandler, setWorkCenters]);
}
