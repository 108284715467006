import { i18n } from "services/i18nService";

import { SUB_COMPONENT } from "views/Reports/reportConstants";

import { OBJECT_TYPES } from "appConstants";

export const BinContents = {
  userHasSufficientPrivilege: (p) =>
    p.viewInventory || p.viewItems || p.editItems,
  planIncludesReport: (plus) => plus,
  customReportNameFetchList: [
    {
      objectType: OBJECT_TYPES.LOCATION.fullString,
      customReportKey: "locations",
    },
  ],
  customFieldObjectTypes: ["item"],
  requiredFiltersNotSetMsg: i18n("reports.LocationFilterRequired"),
  columns: [
    { apiName: "Name", default: true },
    { apiName: "Category" },
    { apiName: "Description", default: true },
    { apiName: "Purchase Description" },
    { apiName: "SKU" },
    { apiName: "Barcode" },
    { apiName: "Vendor" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
    { apiName: "Sales Price", number: true },
    { apiName: "Purchase Cost", number: true },
    { apiName: "Default Bin" },
    { apiName: "Asset Account" },
    { apiName: "Lead Time", number: true },
    { apiName: "Reorder Point", number: true },
    { apiName: "Max Stock", number: true },
    { apiName: "Tags" },
    { apiName: "Quantity", number: true, alwaysShow: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.asOf"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.AsOfDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date1Macro",
      component: SUB_COMPONENT,
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.BinTrackedLocation"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: null,
      required: true,
      onlyBinTracked: true,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.BinNumberContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "suppressIfEmpty",
      defaultValue: true,
      component: "FrmCheckbox",
      label: i18n("reports.OptionLabel.HideEmptyBins"),
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      component: "FrmCheckbox",
      label: i18n("reports.OptionLabel.ShowAsOfDate"),
      isAsOfDate: true,
      type: "option",
    },
    {
      defaultValue: "",
      apiName: "header",
      component: "FrmTextField",
      label: i18n("global.Header"),
      type: "option",
    },
    {
      defaultValue: "",
      apiName: "footer",
      component: "FrmTextField",
      label: i18n("global.Footer"),
      type: "option",
    },
  ],
};
