import { Tooltip as MuiTooltip } from "@mui/material";

export function Tooltip(props) {
  const { children, title, placement = "right-start", arrow } = props;
  return (
    <MuiTooltip arrow={arrow} title={title || ""} placement={placement}>
      {children}
    </MuiTooltip>
  );
}
