import { useEffect, useState } from "react";

import { getQuickList } from "services/sosInventoryService/sosApi";

export function useJobWorkcenters() {
  const [records, setRecords] = useState(null);

  useEffect(() => {
    async function retrieveRecords() {
      const { data } = await getQuickList("job");

      const jobsAndWorkcenters = data.map(({ workcenters, id, name }) =>
        workcenters.map(({ workcenter }) => ({
          id: `${id}|${workcenter.id}`,
          name: `${name}->${workcenter.name}`,
        }))
      );

      setRecords(jobsAndWorkcenters.flat());
    }
    retrieveRecords();
  }, []);

  return records;
}
