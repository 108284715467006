import { LineItemNumericCalculatorField } from "components/formFields/LineItemNumericCalculatorField";
import { LineTableCell } from "components/formFields/LineTableCell";

import { LINE_ITEM_METADATA } from "services/sosInventoryService/itemReceipt/schema";

export function LineUsedPer(props) {
  const { value, onValueChange, error } = props;

  return (
    <LineTableCell sx={{ minWidth: "5.5rem" }}>
      <LineItemNumericCalculatorField
        name="usedPer"
        value={value}
        onValueChange={onValueChange}
        metadata={LINE_ITEM_METADATA.quantity}
        dataTesting="lineUsedPer"
        error={error}
      />
    </LineTableCell>
  );
}
