import { i18n } from "services/i18nService";

import { initialErrorState } from "views/common";

export function isValid(record) {
  const errors = initialErrorState();
  errors.entity = [];

  if (!record.name?.trim().length) {
    errors.messages.push(i18n("isValid.Name"));
    errors.entity.push("name");
  }
  const outputLineWithItem = record.outputs.find(({ item }) => item?.id);
  const inputLineWithItem = record.inputs.find(({ item }) => item?.id);
  if (!outputLineWithItem) {
    errors.messages.push(i18n("isValid.OutputItemRequired"));
  }

  if (!inputLineWithItem) {
    errors.messages.push(i18n("isValid.InputItemRequired"));
  }

  return errors;
}
