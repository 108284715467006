export function afterGet(record) {
  const newRecord = {
    ...record,
    workOrderNumber: {
      prefix: record.prefixWorkOrder,
      nextNumber: record.nextWONumber,
    },
  };

  delete newRecord.prefixWorkOrder;
  delete newRecord.nextWONumber;

  return newRecord;
}
