import { ATTACHABLE_MS_WORD_TYPES, ATTACHABLE_FILE_TYPES } from "appConstants";

// note that the back end validates file *extensions* and we're validating
// MIME types here; there is substantial overlap, but notable exceptions,
// such as Microsoft Office OpenXML formats; see SUPPORTED_DOCUMENT_TYPES
// for a little more information
//
// input is a Javascript File object
export function isAttachableFile(fileObject) {
  return ATTACHABLE_FILE_TYPES.includes(fileObject.type);
}

export function isAttachableMsWordFile(formFileObject) {
  return ATTACHABLE_MS_WORD_TYPES.includes(formFileObject.type);
}

export function isAttachableTextFile(fileObject) {
  return fileObject.type.startsWith("text/");
}
