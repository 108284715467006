import { afterGet as globalAfterGet } from "services/sosInventoryService/afterGet";
import { purchasingTransactionAfterGet } from "services/sosInventoryService/purchasingTransaction/afterGet";
import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/returnToVendor/schema";
import {
  transformFields,
  transformLineItemFields,
} from "services/utility/afterGet";

import { OBJECT_TYPES } from "appConstants";
import { LINE_ITEM_FIELDS } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.RETURN_TO_VENDOR.fullString;

export function afterGet(record) {
  let newRecord = transformFields(record, FIELDS_TO_TRANSFORM);

  newRecord.lines = transformLineItemFields(
    newRecord.lines,
    LINE_ITEM_FIELDS[OBJECT_TYPE]
  );

  newRecord = purchasingTransactionAfterGet(newRecord);
  return globalAfterGet(newRecord);
}
