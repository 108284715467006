import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function FrmTerms(props) {
  const {
    value,
    onValueChange,
    error,
    disabled,
    maxWidth,
    name = "terms",
    label = i18n("frmLabel.Terms"),
    disableClearable,
  } = props;
  const terms = useRelated("terms");

  return (
    <FrmSelectFromObjects
      name={name}
      label={label}
      value={value}
      onValueChange={onValueChange}
      options={terms}
      error={error}
      disabled={disabled}
      maxWidth={maxWidth}
      disableClearable={disableClearable}
    />
  );
}
