import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box, Card, CardContent } from "@mui/material";

import { Layout } from "Layout";

import { NEW_RECORD_SKELETON } from "appConfig";

import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmTextFieldWithHoverHelp } from "components/formFields/FrmTextFieldWithHoverHelp";
import { ContactAddress } from "components/formFields/address/ContactAddress";
import { CardHeader } from "components/formFields/formCards/CardHeader";
import { FrmCheckboxWithHoverHelp } from "components/formFields/frm/FrmCheckboxWithHoverHelp";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { getRecord } from "services/sosInventoryService/sosApi";
import { isPersistedRecord } from "services/utility/misc";

import { useErrors } from "hooks/useErrors";
import { useRecord } from "hooks/useRecord";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

export function Location(props) {
  const { objectType, id, onClose } = props;
  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();

  const dispatch = useDispatch();

  const loadingState = useSelector((state) => state.loading.editModal);
  const settings = useSelector((state) => state.userCompanySettings.settings);

  useEffect(() => {
    async function _getRecord() {
      if (id) {
        dispatch(editModalLoadingIndicatorOn());
        const response = await getRecord(objectType, id);
        setRecord(response);
        dispatch(editModalLoadingIndicatorOff());
      } else {
        const emptyRecord = NEW_RECORD_SKELETON[objectType](settings);
        setRecord(emptyRecord);
      }
    }
    _getRecord();
  }, [setRecord, id, objectType, dispatch, settings]);

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={objectType}
        setErrors={setErrors}
        text={record?.name || ""}
        handleClose={onClose}
      />

      {loadingState && <Loading />}

      {record && (
        <ObjectEditDialogContent>
          <Layout
            pageTitle={
              id
                ? `${i18n(`objectType.${objectType}.Sentence`)} ${
                    record?.name || ""
                  }`
                : i18n(`objectType.${objectType}.New`)
            }
          >
            <Box flexGrow="1" position="relative" m={2} mb={0}>
              <FormErrors errors={errors} setErrors={setErrors} />
              {record ? (
                <Box>
                  <FrmFieldGrid gridAutoFlow="dense">
                    <div style={{ gridColumn: "1 / 2" }}>
                      <FrmTextField
                        name="name"
                        value={record.name}
                        maxLength={50} // back end doesn't allow for more than 50 characters
                        label={i18n("frmLabel.Name")}
                        onValueBlur={handleInputFieldChange}
                        error={isInError("name")}
                      />
                      <Card sx={{ mt: 1 }} variant="outlined">
                        <CardContent>
                          <CardHeader text={i18n("global.Address")} />
                          <ContactAddress
                            value={record}
                            handleInputFieldChange={(_, value) =>
                              setRecord(value)
                            }
                          />
                        </CardContent>
                      </Card>
                    </div>
                    <div style={{ gridColumn: "2 / 2" }}>
                      <div style={{ maxWidth: "8rem" }}>
                        <FrmTextFieldWithHoverHelp
                          number
                          name="salesTaxRate"
                          label={i18n("frmLabel.SalesTaxRatePercent")}
                          onValueBlur={handleInputFieldChange}
                          value={record.salesTaxRate}
                          helpText={i18n("hoverHelp.SalesTaxRatePercent")}
                        />
                      </div>
                      <Box sx={{ m: "1rem 0" }}>
                        <FrmCheckboxWithHoverHelp
                          name="defaultLocation"
                          value={record.defaultLocation}
                          onValueChange={handleInputFieldChange}
                          label={i18n("frmLabel.Default")}
                          helpText={i18n("hoverHelp.LocationDefault")}
                        />
                      </Box>
                      <Box sx={{ m: "1rem 0" }}>
                        <FrmCheckboxWithHoverHelp
                          name="binTracking"
                          value={record.binTracking}
                          onValueChange={handleInputFieldChange}
                          label={i18n("frmLabel.BinTracking")}
                          helpText={i18n("hoverHelp.BinTracking")}
                          disabled={isPersistedRecord(record.id)}
                        />
                      </Box>
                      <Box sx={{ m: "1rem 0" }}>
                        <FrmCheckboxWithHoverHelp
                          name="nonNettable"
                          value={record.nonNettable}
                          onValueChange={handleInputFieldChange}
                          label={i18n("frmLabel.NonNettable")}
                          helpText={i18n("hoverHelp.NonNettable")}
                        />
                      </Box>
                    </div>
                  </FrmFieldGrid>
                  <VSpace space={1} />
                  <FrmFieldGrid gridAutoFlow="dense"></FrmFieldGrid>
                </Box>
              ) : (
                !isInError("record") && (
                  <FixedLoadingIndicator
                    text={`${i18n("global.Loading")}...`}
                  />
                )
              )}
            </Box>
            <VSpace space={4} />
          </Layout>
        </ObjectEditDialogContent>
      )}
    </>
  );
}
