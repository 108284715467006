import { i18n } from "services/i18nService";

import {
  NONE_GROUP_BY_OPTION,
  GROUP_BY_CATEGORY_OR_VENDOR_OPTIONS,
  SUB_COMPONENT,
} from "views/Reports/reportConstants";

import { ALL_FILTER_OPTION, OBJECT_TYPES } from "appConstants";

export const InventoryTurnover = {
  userHasSufficientPrivilege: (p) => p.editItems && p.viewCosts,
  planIncludesReport: () => true,
  customReportNameFetchList: [
    { objectType: OBJECT_TYPES.ITEM.fullString, customReportKey: "categories" },
    {
      objectType: OBJECT_TYPES.LOCATION.fullString,
      customReportKey: "locations",
    },
    { objectType: OBJECT_TYPES.VENDOR.fullString },
  ],
  customFieldObjectTypes: "item",
  columns: [
    { apiName: "ItemNumber", label: "Item #" },
    { apiName: "Name", default: true },
    { apiName: "Category" },
    { apiName: "Description", default: true },
    { apiName: "Purchase Description" },
    { apiName: "SKU" },
    { apiName: "Barcode" },
    { apiName: "Vendor" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
    { apiName: "Sales Price", number: true },
    { apiName: "Purchase Cost", number: true },
    { apiName: "Default Bin" },
    { apiName: "Asset Account" },
    { apiName: "Lead Time", number: true },
    { apiName: "Reorder Point", number: true },
    { apiName: "Max Stock", number: true },
    { apiName: "Tags" },
    { apiName: "System Count", number: true, alwaysShow: true },
    { apiName: "Units Turned", number: true, alwaysShow: true },
    { apiName: "COGS", number: true, alwaysShow: true },
    { apiName: "Avg Inventory", number: true, alwaysShow: true },
    { apiName: "Turnover", number: true, alwaysShow: true },
    { apiName: "Days of Inventory", number: true, alwaysShow: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      defaultValue: null,
      datePrefix: "startOf",
      type: "filter",
    },
    {
      apiName: "date1Macro",
      component: SUB_COMPONENT,
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2Macro",
      component: SUB_COMPONENT,
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Location"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: [ALL_FILTER_OPTION],
      includeAllOption: true,
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "suppressIfEmpty",
      defaultValue: true,
      label: i18n("reports.OptionLabel.HideRowsWithNoShipmentsOrInventory"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFullOnly",
      defaultValue: false,
      label: i18n("reports.OptionLabel.UseStandardCosts"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: GROUP_BY_CATEGORY_OR_VENDOR_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
