export const SETTINGS = [
  {
    apiName: "portalEnabled",
    component: "FrmCheckbox",
  },
  {
    apiName: "portalDomain",
    component: "FrmCustomerPortalSubdomain",
    displayIf: "portalEnabled",
  },
  {
    apiName: "portalCompanyName",
    component: "FrmTextField",
    displayIf: "portalEnabled",
  },
  {
    apiName: "portalFooter",
    component: "FrmResizeTextField",
    displayIf: "portalEnabled",
  },
  {
    apiName: "portalPasswordOptional",
    component: "FrmCheckbox",
    displayIf: "portalEnabled",
  },
  {
    apiName: "showAssignedUser",
    component: "FrmCheckbox",
    displayIf: "portalEnabled",
  },
];
