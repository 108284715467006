import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

const indianLocations = [
  { name: "", id: "" },
  { name: "Andaman and Nicobar Islands", id: "35" },
  { name: "Andhra Pradesh", id: "37" },
  { name: "Arunachal Pradesh", id: "12" },
  { name: "Assam", id: "18" },
  { name: "Bihar", id: "10" },
  { name: "Chandigarh", id: "04" },
  { name: "Chhattisgarh", id: "22" },
  { name: "Dadra and Nagar Haveli", id: "26" },
  { name: "Daman and Diu", id: "25" },
  { name: "Delhi", id: "07" },
  { name: "Goa", id: "30" },
  { name: "Gujarat", id: "24" },
  { name: "Haryana", id: "06" },
  { name: "Himachal Pradesh", id: "02" },
  { name: "Jammu and Kashmir", id: "01" },
  { name: "Jharkhand", id: "20" },
  { name: "Karnataka", id: "29" },
  { name: "Kerala", id: "32" },
  { name: "Lakshadweep", id: "31" },
  { name: "Madhya Pradesh", id: "23" },
  { name: "Maharashtra", id: "27" },
  { name: "Manipur", id: "14" },
  { name: "Meghalaya", id: "17" },
  { name: "Mizoram", id: "15" },
  { name: "Nagaland", id: "13" },
  { name: "Odisha", id: "21" },
  { name: "Pondicherry", id: "34" },
  { name: "Punjab", id: "03" },
  { name: "Rajasthan", id: "08" },
  { name: "Sikkim", id: "11" },
  { name: "Tamil Nadu", id: "33" },
  { name: "Telangana", id: "36" },
  { name: "Tripura", id: "16" },
  { name: "Uttar Pradesh", id: "09" },
  { name: "Uttarakhand", id: "05" },
  { name: "West Bengal", id: "19" },
  { name: "Other Territory", id: "97" },
];

const frenchLocations = [
  { name: "", id: "" },
  { name: "Within France", id: "WithinFrance" },
  { name: "France Overseas", id: "FranceOverseas" },
  { name: "Outside France Within EU", id: "OutsideFranceWithEU" },
  { name: "Outside EU", id: "OutsideEU" },
];

export function FrmTransactionLocation(props) {
  const { value, onValueChange } = props;
  const quickbooksCountry = useSelector(
    (state) => state.userCompanySettings.quickbooksCountry
  );

  if (quickbooksCountry !== "IN" || quickbooksCountry !== "FR") {
    return null;
  }

  const isFrance = quickbooksCountry === "FR";

  return (
    <Box display="flex" alignItems="center">
      <FrmSelectFromObjects
        label={i18n(
          isFrance ? "frmLabel.TransactionLocation" : "frmLabel.PlaceOfSupply"
        )}
        value={value}
        onValueChange={(name, location) => onValueChange(name, location?.id)}
        minWidth="15rem"
        options={isFrance ? frenchLocations : indianLocations}
        name="transactionLocationQuickBooks"
      />
    </Box>
  );
}
