import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, TextField } from "@mui/material";

import { FieldHelperText } from "components/utility/FieldHelperText";

import { checkForUnexpectedProps } from "services/utility/misc";

import PropTypes from "prop-types";

/**
 * @name    FrmSelectBase
 * @summary base component for more specific select components, like
 * FrmSelectScalar, etc.; this component should not be used directly
 * on forms, only by the higher level select components
 *
 * @param value (object or string) - the current value of this field
 * in the underlying record, that should be displayed in the component
 *
 * @param options (array of the same type as the value param, above) -
 * enumeration of the possible values that this field can take on; each
 * element in the array should be of the same type as the value param
 *
 * @param onValueChange (func) - function that will be called when a new
 * value is chosen by the user
 *
 * signature:
 * function(newValue) => (no return value)
 *
 * @param onBlur (func) - function that will be called when this field
 * loses focus; only necesssary when "post-processing" of the chosen value
 * is required, such as FrmSelectWithAdd's popping up of an add dialog if
 * the value entered is not found among the options
 *
 * @param renderOption (func) - function that will be called to render
 * an individual option in the dropdown list
 *
 * signature:
 * function(option, state) => string
 *
 * for example, for a typical reference property, you would render the
 * [name] property; state is an object with two properties, selected
 * (boolean) and inputValue (the current selected value); in practice,
 * we have not used state so far
 *
 * @param getOptionLabel (func) - function that will be used to fill
 * the input field with the appropriate text for the selected option
 *
 * this also seems to get called for each option when
 * rendering the dropdown list, though it's not clear why this would
 * be; it doesn't appear to get used in the dropdown option rendering;
 *
 * signature:
 * function(option) => string
 *
 * the option will be one element of the array of options passed to
 * FrmSelectBase
 *
 * @param isOptionEqualToValue (func) - function that will be called for
 * each option in the options passed in to FrmSelectBase, to determine
 * which is/are selected; used to change the appearance of the selected
 * option in the dropdown option list
 *
 * signature:
 * function(option, value) => boolean
 *
 * option will be one element of the options array passed in to
 * FrmSelectBase; value will be the currently selected value, which
 * looks like one of the options, but unless the options are primitives,
 * strict comparison won't work (so you have to compare the id
 * properties, for example); if the options *are* primitives, you can
 * pass undefined in for this parameter and the component will use
 * strict comparison
 *
 * @param error (boolean) - if true, this component should be displayed
 * in the error state
 *
 * @param disabled (boolean) - component should be disabled
 *
 * @param label (string) - label for the component
 *
 * @param disableClearable (boolean) -  see Autocomplete API doc
 *
 * @param loading (boolean) - see Autocomplete API doc
 *
 * @param helperText (string) - if present, will render a
 * FieldHelperText component under the Autocomplete component to present
 * helper text to the user
 *
 * @param fullWidth (boolean) - see Autocomplete API doc
 *
 * @param minWidth, maxWidth - will be applied to the underlying input
 * field rendered by the Autocomplete component; can be used to control
 * the overall width of the component
 *
 * @param margin - applied to the Autocomplete component itself (which
 * will pass it through to the underlying HTML component)
 *
 * @param InputProps (object) - props which will be passed through
 * TextField (in our implementation) to the underlying input component
 * (see MUI TextField API documentation)
 *
 * @param style (object) - will be passed by Autocomplete to the under-
 * lying HTML component
 *
 * @dataTesting (string) - will set a data-testing HTML attribute on the
 * underlying element
 */

export function FrmSelectBase(props) {
  const {
    name,
    value,
    options,
    onValueChange,
    renderOption,
    getOptionLabel,
    isOptionEqualToValue,
    onBlur,
    clearOnBlur,
    onInputChange,
    error,
    disabled,
    label,
    disableClearable,
    loading,
    helperText,
    fullWidth,
    minWidth,
    maxWidth,
    margin,
    filterOptions,
    disableListWrap,
    placeholder,
    InputProps = {},
    sx,
    dataTesting,
    getOptionDisabled,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmSelectBase", unexpected);

  return (
    <>
      <Autocomplete
        loading={loading}
        disabled={disabled}
        value={value}
        ListboxProps={{ "data-testing": "autocompleteOptions" }}
        onChange={(_, value) => onValueChange(name, value)}
        onInputChange={onInputChange}
        filterOptions={filterOptions}
        onBlur={onBlur}
        clearOnBlur={clearOnBlur}
        options={options}
        getOptionLabel={getOptionLabel}
        getOptionDisabled={getOptionDisabled}
        isOptionEqualToValue={isOptionEqualToValue}
        renderOption={renderOption}
        disableClearable={disableClearable}
        disableListWrap={disableListWrap}
        size="small"
        margin={margin}
        sx={sx}
        popupIcon={
          <div style={{ height: "28px", width: "28px", paddingTop: "2px" }}>
            <KeyboardArrowDownIcon
              sx={{ color: disabled ? "" : "selectIcon" }}
            />
          </div>
        }
        fullWidth={fullWidth}
        data-testing={dataTesting}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            margin="dense"
            autoComplete="off"
            error={error}
            label={label}
            InputLabelProps={{ shrink: true }}
            InputProps={{ ...params.InputProps, ...InputProps }}
            sx={{
              textAlign: "left",
              minWidth: minWidth ? minWidth : "inherit",
              maxWidth: maxWidth ? maxWidth : "inherit",
            }}
          />
        )}
      />
      {helperText && (
        <FieldHelperText color="warning.error">{helperText}</FieldHelperText>
      )}
    </>
  );
}

FrmSelectBase.propTypes = {
  onValueChange: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  isOptionEqualToValue: PropTypes.func,
  renderOption: PropTypes.func.isRequired,
  gridColumnStart: PropTypes.number,
  onBlur: PropTypes.func,
  filterOptions: PropTypes.func,
  onInputChange: PropTypes.func,
  disableListWrap: PropTypes.bool,
  minWidth: PropTypes.string,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  margin: PropTypes.any,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  error: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]).isRequired,
  disableClearable: PropTypes.bool,
  loading: PropTypes.bool,
  helperText: PropTypes.string,
  InputProps: PropTypes.object,
  style: PropTypes.object,
  dataTesting: PropTypes.string,
};
