import { createSlice } from "@reduxjs/toolkit";

export const globalDialogSlice = createSlice({
  name: "globalDialog",
  initialState: { open: false, dialogProps: {} },
  reducers: {
    openDialog: (_, action) => {
      return {
        dialogProps: action.payload.dialogProps,
        type: action.payload.type,
        open: true,
      };
    },
    closeDialog: () => {
      return { open: false, dialogProps: {} };
    },
  },
});

export const { openDialog, closeDialog } = globalDialogSlice.actions;
export default globalDialogSlice.reducer;
