import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function FrmOrderStage(props) {
  const { value, onValueChange, maxWidth } = props;

  const orderStages = useRelated("orderstage");

  return (
    <FrmSelectFromObjects
      name="orderStage"
      options={orderStages}
      value={value}
      label={i18n("frmLabel.OrderStage")}
      onValueChange={onValueChange}
      maxWidth={maxWidth}
    />
  );
}
