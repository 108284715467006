const EMPTY_SERIAL_ENTRY = { id: -1, name: "" };

// takes as input a line.serials value, the quantity of the serialized
// item in question, and a boolean, indicating whether auto serial numbering
// is on for this transaction; returns a serial number array, or null, ready
// for PUTing or POSTing via the API
export function normalizeSerials(serials, decQuantity, serialized, autoNumber) {
  if (!serialized) {
    return null;
  }

  const quantity = decQuantity.toNumber();

  if (autoNumber) {
    if (!serials || serials.length === 0) {
      // return null if the entire quantity is to be auto numbered
      return null;
    }

    return truncateOrPad(serials, quantity);
  }

  const arrayedSerials = serials || [];
  return truncateOrPad(arrayedSerials, quantity);
}

function truncateOrPad(serials, quantity) {
  // truncate if too long
  let newSerials = serials.slice(0, quantity);

  // pad if too short
  for (let i = newSerials.length + 1; i <= quantity; i++) {
    newSerials.push(EMPTY_SERIAL_ENTRY);
  }

  return newSerials;
}
