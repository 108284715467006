import { i18n } from "services/i18nService";
import {
  formatSyncItemObject,
  extractObjectType,
} from "services/utility/formatting";

export const EMPTY_RECORD = { dateAdded: null, message: null };

export const columnInfo = [
  {
    name: "goTo",
    buttonLabel: i18n("columns.GoTo"),
    buttonAction: "goTo",
    isButton: true,
    fixed: true,
    excludeFromColumnList: true,
  },
  {
    name: "object",
    fixed: true,
    heading: i18n("columns.ObjectAndIdentifier"),
    formatFunc: formatSyncItemObject,
  },
  {
    name: "objectType",
    fixed: true,
    fixedRightOfAction: true,
    fieldName: "object",
    heading: i18n("columns.ObjectType"),
    formatFunc: extractObjectType,
  },
  {
    name: "action",
    fixed: true,
    fixedRightOfAction: true,
    heading: i18n("columns.Action"),
    defaultSort: true,
  },
];

const columnArray = Object.entries(columnInfo);
const defaultColumnInfo = columnArray.filter((col) => col[1].required);
export const defaultColumns = defaultColumnInfo.map((col) => col[0]);

export const LINE_ACTIONS = ["edit", "delete", "moveToEnd"];
export const BATCH_ACTIONS = ["delete"];
