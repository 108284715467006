import { Box, Typography } from "@mui/material";

import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";

import { EditColumns } from "views/Reports/EditColumns";

export function Columns(props) {
  const { columns, availableColumns, setColumns } = props;

  return (
    <Box>
      <Typography variant="h5" color="primaryText" fontWeight="Bold">
        {i18n("reports.WidgetText.Columns")}
      </Typography>
      <VSpace space={1} />
      <EditColumns
        columns={columns}
        setColumns={setColumns}
        allColumns={availableColumns}
      />
    </Box>
  );
}
