import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Delete } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Typography,
} from "@mui/material";

import { CopyDownLineItem } from "components/CopyDownLineItem";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { LineTableCell } from "components/formFields/LineTableCell";
import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";
import { LocationAddress } from "components/formFields/row/LocationAddress";
import { RowBorderedTextField } from "components/formFields/row/RowBorderedTextField";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { LinkText } from "components/utility/LinkText";
import { Loading } from "components/utility/Loading";
import { ModalHeader } from "components/utility/ModalHeader";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { isValid } from "services/sosInventoryService/address/isValid";
import {
  LOCATION_ADDRESS_SKELETON,
  LOCATION_OPTIONS,
} from "services/sosInventoryService/address/schema";
import {
  getContactAddresses,
  saveContactAddresses,
} from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

import { useErrors } from "hooks/useErrors";

import { openAlert } from "globalState/alertSlice";

const sx = {
  "& .MuiDialog-paper": {
    minHeight: "50vh",
    "@media print": { minHeight: "100%", maxHeight: "100%" },
  },
};

export function LocationAddresses(props) {
  const { record, onClose, objectType } = props;

  const [addresses, setAddresses] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { errors, setErrors } = useErrors();
  const dispatch = useDispatch();

  const addressSkeleton = LOCATION_ADDRESS_SKELETON[objectType];

  useEffect(() => {
    async function getAddresses() {
      const response = await getContactAddresses(record.id, objectType);
      setAddresses(response);
    }
    getAddresses();
  }, [setAddresses, record?.id, objectType]);

  function deleteAddress(rowIndex) {
    setAddresses((prevAddresses) =>
      prevAddresses.filter((_, i) => i !== rowIndex)
    );
  }
  function copyRow(rowIndex) {
    const newAddresses = [...addresses];
    const copiedRowAddress = addresses[rowIndex];
    const updatedRowAddress = addresses[rowIndex + 1];
    const { addressName } = updatedRowAddress.addressInfo;
    const newAddress = {
      ...updatedRowAddress,
      addressInfo: { ...copiedRowAddress.addressInfo, addressName },
    };

    newAddresses.splice(rowIndex + 1, 1, newAddress);
    setAddresses(newAddresses);
  }

  function handleNameChange(fieldName, value, rowIndex) {
    setAddresses((prevAddresses) =>
      prevAddresses.map((address, index) =>
        rowIndex === index
          ? {
              ...address,
              addressInfo: { ...address.addressInfo, [fieldName]: value },
            }
          : address
      )
    );
  }

  function handleFieldChange(fieldName, value, rowIndex) {
    setAddresses((prevAddresses) =>
      prevAddresses.map((address, index) =>
        rowIndex === index ? { ...address, [fieldName]: value } : address
      )
    );
  }

  async function save() {
    const errors = isValid(addresses);
    if (errors.messages.length > 0) {
      setErrors(errors);
      return;
    }
    setIsLoading(true);
    const response = await saveContactAddresses(
      record.id,
      objectType,
      addresses
    );
    if (response.success) {
      const message = i18n("alert.SaveAddressesSuccess");
      dispatch(openAlert({ type: "success", message }));
      onClose();
    } else {
      handleProgramError(new Error(i18n("error.CouldNotSaveAddresses")));
    }
    setIsLoading(false);
  }

  function addLine() {
    setAddresses((prevRecord) => [...prevRecord, addressSkeleton]);
  }

  return (
    <Dialog open={true} maxWidth="lg" onClose={onClose} sx={sx}>
      <ModalHeader
        label={i18n("global.Addresses")}
        onClose={onClose}
        text={record?.name}
        save={save}
      />
      {(!addresses || isLoading) && <Loading />}
      <DialogContent>
        <FormErrors errors={errors} setErrors={setErrors} />
        {addresses ? (
          <>
            <VSpace space={1} />
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <LineColumnHeading />
                    <LineColumnHeading labelCode="Type" />
                    <LineColumnHeading labelCode="Name" />
                    <LineColumnHeading labelCode="Address" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {addresses.map((address, index, array) => (
                    <AddressesRow
                      key={index}
                      address={address}
                      copyRow={copyRow}
                      handleNameChange={handleNameChange}
                      handleFieldChange={handleFieldChange}
                      deleteAddress={deleteAddress}
                      rowIndex={index}
                      objectType={objectType}
                      errors={errors}
                      showCopyDown={index !== array.length - 1}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <VSpace space={1} />
            <div style={{ marginBottom: "0.5rem" }}>
              <LinkText onClick={addLine}>{i18n("global.AddLine")}</LinkText>
            </div>
            <VSpace space={1} />
          </>
        ) : (
          <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
        )}
      </DialogContent>
    </Dialog>
  );
}

function AddressesRow(props) {
  const {
    address,
    rowIndex,
    copyRow,
    showCopyDown,
    handleFieldChange,
    handleNameChange,
    deleteAddress,
    objectType,
    errors,
  } = props;
  const { addressInfo, label, isDefault } = address;
  const addressOptions = LOCATION_OPTIONS[objectType];

  function isInError(field) {
    return errors.line[rowIndex]
      ? Boolean(errors.line[rowIndex].find((f) => f === field))
      : false;
  }

  return (
    <TableRow>
      <LineTableCell sx={{ verticalAlign: "top", position: "relative" }}>
        {showCopyDown && (
          <CopyDownLineItem onCopyDown={() => copyRow(rowIndex)} />
        )}
        {!isDefault && (
          <IconButton
            sx={{ marginTop: "0.8rem" }}
            size="small"
            onClick={() => deleteAddress(rowIndex)}
          >
            <Delete />
          </IconButton>
        )}
      </LineTableCell>
      <LineTableCell sx={{ minWidth: "10rem", verticalAlign: "top", p: 1 }}>
        {isDefault ? (
          <Typography>{label.name}</Typography>
        ) : (
          <FrmSelectFromObjects
            name="label"
            disableClearable
            label={i18n("global.Type")}
            value={label}
            dataIndex={rowIndex}
            onValueChange={handleFieldChange}
            options={addressOptions}
          />
        )}
      </LineTableCell>
      <LineTableCell sx={{ minWidth: "10rem", verticalAlign: "top", p: 1 }}>
        {isDefault ? (
          <Typography>{addressInfo.addressName}</Typography>
        ) : (
          <RowBorderedTextField
            dataIndex={rowIndex}
            label={i18n("global.Name")}
            onValueBlur={handleNameChange}
            value={addressInfo.addressName}
            name="addressName"
            error={isInError("addressName")}
          />
        )}
      </LineTableCell>
      <LineTableCell sx={{ p: 1 }}>
        <LocationAddress
          value={addressInfo}
          dataIndex={rowIndex}
          handleInputFieldChange={handleFieldChange}
          addressType="addressInfo"
          disableContactEditing={isDefault}
        />
      </LineTableCell>
    </TableRow>
  );
}
