import { i18n } from "services/i18nService";
import { isValidEmail } from "services/utility/validation";

import { initialErrorState } from "views/common";

export function isValid(record) {
  const { email, confirmEmail, name } = record;

  const errors = initialErrorState();
  errors.entity = [];

  if (!name || !name.trim().length) {
    errors.messages.push(i18n("isValid.Name"));
    errors.entity.push("name");
  }

  if (!isValidEmail(email)) {
    errors.messages.push(i18n("isValid.Email"));
    errors.entity.push("email");
  }

  if (email !== confirmEmail) {
    errors.messages.push(i18n("isValid.EmailMismatch"));
    errors.entity.push("email");
    errors.entity.push("confirmEmail");
  }

  return errors;
}
