import { i18n } from "services/i18nService";

import {
  SALES_ORDER_ORDER_TYPE_OPTION,
  FULFILLMENT_ORDER_TYPE_OPTIONS,
  NONE_GROUP_BY_OPTION,
  SUB_COMPONENT,
  FULFILLMENT_GROUP_BY_OPTIONS,
} from "views/Reports/reportConstants";

import { ALL_FILTER_OPTION, OBJECT_TYPES } from "appConstants";

export const ReadyToPickShip = {
  userHasSufficientPrivilege: (p) =>
    p.viewSalesOrders ||
    p.editSalesOrders ||
    p.editShipments ||
    p.viewSalesReceipts ||
    p.editSalesReceipts,
  planIncludesReport: () => true,
  customReportNameFetchList: [
    { objectType: OBJECT_TYPES.CUSTOMER.fullString },
    {
      objectType: OBJECT_TYPES.LOCATION.fullString,
      customReportKey: "locations",
    },
  ],
  customFieldObjectTypes: ["salesorder", "salesreceipt", "item"],
  columns: [
    { apiName: "OrderNumber", label: "Order #", default: true },
    { apiName: "Order Date", default: true },
    { apiName: "Txn Type", default: true },
    { apiName: "Customer", default: true },
    { apiName: "Item", default: true },
    { apiName: "Location" },
    { apiName: "Description", default: true },
    { apiName: "Ordered", default: true, number: true },
    { apiName: "Picked", default: true, number: true },
    { apiName: "Shipped", default: true, number: true },
    { apiName: "Backordered", number: true },
    { apiName: "UOM" },
    { apiName: "Unit Price", number: true },
    { apiName: "Open Amt", number: true },
    { apiName: "Total Amt", number: true },
    { apiName: "Due Date" },
    { apiName: "Sales Rep" },
    { apiName: "Priority" },
    { apiName: "Customer PO" },
    { apiName: "Comment" },
    { apiName: "On Hand", default: true, number: true },
    { apiName: "Available" },
    { apiName: "Vendor" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      defaultValue: null,
      datePrefix: "startOf",
      type: "filter",
    },
    {
      apiName: "date1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("filter.Location"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: [ALL_FILTER_OPTION],
      includeAllOption: true,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "orderType",
      label: i18n("reports.FilterLabel.OrderType"),
      component: "FrmSelectFromObjects",
      defaultValue: SALES_ORDER_ORDER_TYPE_OPTION,
      options: FULFILLMENT_ORDER_TYPE_OPTIONS,
      type: "filter",
    },
    {
      apiName: "showFullOnly",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowOnlyOrdersThatCanShipInFull"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showPicked",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowOnlyItemsThatHaveBeenPicked"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "useAvailable",
      defaultValue: false,
      label: i18n(
        "reports.OptionLabel.UseAvailableQuantityInsteadOfOnHandQuantity"
      ),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: FULFILLMENT_GROUP_BY_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
