export function afterGet(record) {
  const newRecord = {
    ...record,
    returnToVendorNumber: {
      prefix: record.prefixRTV,
      nextNumber: record.nextRTVNumber,
    },
  };

  delete newRecord.prefixRTV;
  delete newRecord.nextRTVNumber;

  return newRecord;
}
