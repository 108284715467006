import { useSelector } from "react-redux";

import { Checkbox } from "@mui/material";

import { LineTableCell } from "components/formFields/LineTableCell";

export function LineTax(props) {
  const { value: externalValue, customer, onValueChange } = props;

  const globalEnabled = useSelector(
    (state) => state.userCompanySettings.settings.globalEnabled
  );

  const isDisabled = customer && !customer.tax?.taxable;

  const value = isDisabled ? false : externalValue;
  

  return (
    !globalEnabled && (
      <LineTableCell>
        <div style={{ width: "100%", display: "inline-block" }}>
          <div style={{ textAlign: "center" }}>
            <Checkbox
              key={value}
              name="tax"
              checked={value}
              onChange={(e) => onValueChange("tax", e.target.checked)}
              disabled={isDisabled}
              data-testing="lineTax"
            />
          </div>
        </div>
      </LineTableCell>
    )
  );
}
