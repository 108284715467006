import {
  changeCustomer,
  quickAddCustomer,
  changeVendor,
  quickAddVendor,
} from "services/sosInventoryService/domainLogic";

export async function fullAddNewCustomer(
  customer,
  customerCustomFieldDefs,
  transactionCustomFieldDefs,
  transactionCustomFields,
  updaters,
  avoidAddressUpdates
) {
  const { id, name, notes } = customer;
  const returnedValues = await changeCustomer(customer, {
    customerCustomFieldDefs,
    transactionCustomFieldDefs,
    transactionCustomFields,
  });
  const { customFields, billing, shipping } = returnedValues;

  const addressFields = avoidAddressUpdates ? {} : { billing, shipping };

  updaters.setRecord((prevRecord) => ({
    ...prevRecord,
    customer: { id, name },
    customerNotes: notes,
    customFields,
    ...addressFields,
  }));
  updaters.setRelatedRecords((prevRecord) => ({ ...prevRecord, customer }));
}

export async function fullAddNewVendor(
  vendor,
  vendorCustomFieldDefs,
  transactionCustomFieldDefs,
  transactionCustomFields,
  updaters,
  updateBilling
) {
  const { id, name, notes } = vendor;
  const { customFields, billing } = await changeVendor(vendor, {
    vendorCustomFieldDefs,
    transactionCustomFieldDefs,
    transactionCustomFields,
  });

  const billingField = updateBilling ? { billing } : {};

  updaters.setRecord((prevRecord) => ({
    ...prevRecord,
    vendor: { id, name },
    vendorNotes: notes,
    customFields,
    ...billingField,
  }));
  updaters.setRelatedRecords((prevRecord) => ({ ...prevRecord, vendor }));
}

export async function quickAddNewCustomer(name, updaters) {
  const customer = await quickAddCustomer(name);
  if (typeof customer === "object") {
    updaters.setRelatedRecords((prevRecord) => ({
      ...prevRecord,
      customer,
    }));

    // update the record with the new customer
    updaters.setRecord((prevRecord) => ({
      ...prevRecord,
      customer: { id: customer.id, name: customer.name },
    }));
  } else {
    updaters.setErrors((prev) => ({ ...prev, messages: [customer] }));
  }
}

export async function quickAddNewVendor(name, updaters) {
  const vendor = await quickAddVendor(name);
  if (typeof vendor === "object") {
    updaters.setRelatedRecords((prevRecord) => ({
      ...prevRecord,
      vendor,
    }));

    // update the record with the new vendor
    updaters.setRecord((prevRecord) => ({
      ...prevRecord,
      vendor: { id: vendor.id, name: vendor.name },
    }));
  } else {
    updaters.setErrors((prev) => ({ ...prev, messages: [vendor] }));
  }
}
