import { deData } from "services/i18nData/de";
import { enData } from "services/i18nData/en";
import { esData } from "services/i18nData/es";
import { handleProgramError } from "services/utility/errors";

const ini = require("ini");

const translations = {
  en: ini.parse(enData),
  es: ini.parse(esData),
  de: ini.parse(deData),
};

const LANGUAGE = "en"; // TODO: remove when language settings implemented

export function i18n(textKey, substitutions) {
  const { namespace, key } = parseTextKey(textKey);
  let outputText = "";
  if (translations[LANGUAGE][namespace]) {
    if (translations[LANGUAGE][namespace][key] !== null) {
      outputText = translations[LANGUAGE][namespace][key];
    } else {
      handleProgramError(
        new Error(`i18n | key ${textKey} not found in language ${LANGUAGE}`)
      );
    }
  } else {
    handleProgramError(
      new Error(
        `i18n | namespace ${namespace} not found in language ${LANGUAGE}`
      )
    );
  }

  // substitutions
  if (!substitutions) {
    return outputText;
  }
  Object.keys(substitutions).forEach((key) => {
    const regexp = new RegExp(`{{${key}}}`, "g");
    outputText = outputText.replace(regexp, substitutions[key]);
  });
  return outputText;
}

export function i18nCode() {
  const LANGUAGE = "en"; // TODO: remove when language settings implemented
  return LANGUAGE;
}

function parseTextKey(textKey) {
  const dot = textKey.search(/\./);
  if (dot === -1) {
    handleProgramError(
      new Error(`i18n:parseTextKey | no dot found in text key (${textKey}`)
    );
  }
  return {
    namespace: textKey.substring(0, dot),
    key: textKey.substring(dot + 1, textKey.length),
  };
}
