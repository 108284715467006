import { useEffect, useState } from "react";

import { getScheduleForStandardReport } from "services/sosInventoryService/sosApi";

import { ReportPage } from "views/Reports/ReportPage";
import { REPORTS } from "views/Reports/reportConfig";

export function StandardReport(props) {
  const { reportType } = props;

  const [reportConfig, setReportConfig] = useState({
    id: undefined,
    ...REPORTS[reportType],
    reportType,
  });

  useEffect(() => {
    async function getSchedule() {
      const schedule = await getScheduleForStandardReport(reportType);
      if (schedule) {
        const scheduleWithSaved = {
          ...schedule,
          saved: true,
        };
        setReportConfig((prev) => ({
          ...prev,
          schedule: scheduleWithSaved,
        }));
      }
    }
    getSchedule();
  }, [reportType]);

  return <ReportPage reportConfig={reportConfig} />;
}
