// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { i18n } from "services/i18nService";
import {
  extractName,
  formatName,
  formatAddress,
  formatHyperlink,
  formatDocumentInfo,
  formatNameLinkField,
  formatSync,
  truncate,
  formatBooleanYesOrBlank,
  formatTaxable,
  formatDefaultTax,
  formatCardOnFile,
} from "services/utility/formatting";
import { parseHTML } from "services/utility/parsing";
import { starColumn } from "services/utility/schema";

export const columnInfo = [
  starColumn,
  {
    name: "name",
    fixed: true,
    heading: i18n("columns.Name"),
    formatFunc: formatNameLinkField,
    defaultSort: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "id",
    heading: i18n("columns.System"),
    sortDirection: "asc",
    minWidth: "7em",
    align: "right",
    sortable: true,
    default: true,
  },
  {
    name: "companyName",
    heading: i18n("columns.Company"),
    sortable: true,
    default: true,
  },
  {
    name: "phone",
    heading: i18n("columns.Phone"),
    minWidth: "9em",
    sortable: true,
    default: true,
  },
  {
    name: "email",
    heading: i18n("columns.Email"),
    minWidth: "12em",
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "parent",
    heading: i18n("columns.Parent"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "contact",
    heading: i18n("columns.Contact"),
    formatFunc: formatName,
    minWidth: "8em",
    sortable: true,
  },
  {
    name: "billing",
    heading: i18n("columns.BillingAddress"),
    formatFunc: formatAddress,
    sortable: true,
  },
  {
    name: "shipping",
    heading: i18n("columns.ShippingAddress"),
    formatFunc: formatAddress,
    minWidth: "10em",
    maxWidth: "15em",
    sortable: true,
  },
  {
    name: "website",
    heading: i18n("columns.Url"),
    formatFunc: formatHyperlink,
    minWidth: "12em",
    sortable: true,
  },
  {
    name: "salesRep",
    heading: i18n("columns.SalesRep"),
    formatFunc: extractName,
    minWidth: "8em",
    sortable: true,
  },
  {
    name: "terms",
    heading: i18n("columns.Terms"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "priceTier",
    heading: i18n("columns.PriceTier"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "CardOnFile",
    heading: i18n("columns.CardOnFile"),
    formatFunc: formatCardOnFile,
    sortable: true,
  },
  {
    name: "notes",
    heading: i18n("columns.Notes"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
  },
  {
    name: "sync",
    fieldName: "syncMessage",
    heading: i18n("columns.Sync"),
    formatFunc: formatSync,
    sortable: true,
  },
  {
    name: "fullname",
    heading: i18n("columns.FullName"),
  },
  {
    name: "customerType",
    heading: i18n("columns.CustomerType"),
    formatFunc: extractName,
    sortable: true,
  },
  { name: "mobile", heading: i18n("columns.Mobile") },
  { name: "altPhone", heading: i18n("columns.AltPhone") },
  { name: "fax", heading: i18n("columns.Fax") },
  {
    name: "paymentMethod",
    heading: i18n("columns.PaymentMethod"),
    formatFunc: extractName,
  },
  { name: "resaleNumber", heading: i18n("columns.ResaleNumber") },
  { name: "contractorNumber", heading: i18n("columns.ContractorNumber") },
  { name: "businessLicense", heading: i18n("columns.BusinessLicense") },
  { name: "foundUsVia", heading: i18n("columns.FoundUsVia") },
  {
    name: "taxable",
    heading: i18n("columns.Taxable"),
    fieldName: "tax",
    formatFunc: formatTaxable,
  },
  {
    name: "defaultTax",
    heading: i18n("columns.DefaultTax"),
    fieldName: "tax",
    formatFunc: formatDefaultTax,
  },
  {
    name: "currency",
    heading: i18n("columns.Currency"),
    formatFunc: extractName,
  },
  {
    name: "creditHold",
    heading: i18n("columns.CreditHold"),
    formatFunc: formatBooleanYesOrBlank,
  },
  {
    name: "billWithParent",
    heading: i18n("columns.BillWithParent"),
    formatFunc: formatBooleanYesOrBlank,
  },
];

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["lastSync"],
  NON_UTC_DATETIME_FIELDS: [],
  HTML_ENCODED_FIELDS: ["name"],
};

export function getEmptyRecord(settings) {
  return {
    altPhone: null,
    altAddresses: [],
    archived: false,
    billing: {
      line1: null,
      line2: null,
      line4: null,
      line3: null,
      line5: null,
      city: null,
      stateProvince: null,
      postalCode: null,
      country: null,
    },
    billWithParent: false,
    businessLicense: null,
    companyName: null,
    contact: {
      title: null,
      firstName: null,
      middleName: null,
      lastName: null,
      suffix: null,
    },
    contractorNumber: null,
    creditHold: false,
    currency: settings.homeCurrencyRef,
    customerType: null,
    customFields: [],
    documents: [],
    email: null,
    fax: null,
    foundUsVia: null,
    fullname: null,
    hasChildren: false,
    keys: null,
    mobile: null,
    name: null,
    notes: null,
    parent: null,
    paymentMethod: null,
    phone: null,
    portalPassword: null,
    priceTier: null,
    resaleNumber: null,
    salesRep: null,
    shipping: {
      line1: null,
      line2: null,
      line3: null,
      line4: null,
      line5: null,
      city: null,
      stateProvince: null,
      postalCode: null,
      country: null,
    },
    starred: 0,
    sublevel: 1,
    summaryOnly: false,
    syncToken: 1,
    tax: { taxable: true, taxCode: settings.defaultTaxCode },
    terms: settings.defaultTerms,
    values: null,
    website: null,
  };
}

export const LINE_ACTIONS = [
  "pdf",
  "subCustomer",
  "delete",
  ["createPayment", "removePaymentInfo"],
  "archive",
  "addresses",
  "customerHistory",
  "sync",
];
export const BATCH_ACTIONS = ["pdf", "delete", "archive", "sync"];
