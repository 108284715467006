import { useDispatch } from "react-redux";

import { Button } from "@mui/material";

import { Tooltip } from "components/Tooltip";

import { i18n } from "services/i18nService";
import {
  addToReorderList,
  generatePos,
  SUCCESS,
} from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

import { openAlert } from "globalState/alertSlice";
import {
  loadingIndicatorOn,
  loadingIndicatorOff,
} from "globalState/loadingSlice";

export function ReorderReportActions(props) {
  const { checkedIds, settings } = props;
  const dispatch = useDispatch();

  async function handleAddToReorderList() {
    dispatch(loadingIndicatorOn());
    const response = await addToReorderList(checkedIds);
    if (response === SUCCESS) {
      const message = i18n("alert.SuccessAddedToReorderList");
      dispatch(openAlert({ type: "success", message }));
    } else {
      handleProgramError(new Error(i18n("alert.ErrorNotAddedToReorderList")));
    }
    dispatch(loadingIndicatorOff());
  }

  async function handleGeneratePos() {
    dispatch(loadingIndicatorOn());
    const params = {
      items: checkedIds,
      locationId: settings.locations[0]?.id,
      includePOs: settings.includePos,
      includeRMAs: settings.includeRmas,
      includeWOs: settings.includeWos,
      includeRentals: settings.includeRentals,
      includeNulls: settings.includeNulls,
    };
    const response = await generatePos(params);
    if (response === SUCCESS) {
      const message = i18n("alert.SuccessGeneratePos");
      dispatch(openAlert({ type: "success", message }));
    } else {
      handleProgramError(new Error(i18n("alert.ErrorGeneratingPos")));
    }
    dispatch(loadingIndicatorOff());
  }

  const disabled = !checkedIds.length;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Tooltip
        title={
          disabled
            ? i18n("tooltip.GeneratePurchaseOrders")
            : i18n("reports.GeneratePos")
        }
      >
        <div>
          <Button
            sx={{ lineHeight: "1" }}
            disabled={disabled}
            onClick={handleGeneratePos}
          >
            {i18n("reports.Pos")}
          </Button>
        </div>
      </Tooltip>
      <Tooltip
        title={
          disabled
            ? i18n("tooltip.AddToReorderList")
            : i18n("reports.AddToReorderList")
        }
      >
        <div>
          <Button
            sx={{ lineHeight: "1" }}
            disabled={disabled}
            onClick={handleAddToReorderList}
          >
            {i18n("reports.Reorder")}
          </Button>
        </div>
      </Tooltip>
    </div>
  );
}
