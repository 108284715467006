import { Decimal, Money } from "classes/DecimalClasses";

const decimalFields = ["amountDiscount", "percentDiscount"];

export function afterGet(record) {
  const newRecord = { ...record };

  // convert numbers from float to Decimal
  decimalFields.forEach((numField) => {
    if (typeof newRecord[numField] === "number") {
      newRecord[numField] = new Decimal(newRecord[numField]);
    }
  });

  if (record.items) {
    record.items.forEach(({ price, quantity }, i) => {
      newRecord.items[i].price = new Money(price);
      newRecord.items[i].quantity = new Decimal(quantity);
    });
  }

  return newRecord;
}
