import { createSlice } from "@reduxjs/toolkit";

export const bearerTokenSlice = createSlice({
  name: "bearerToken",
  initialState: "",
  reducers: {
    setBearerToken: (state, action) => action.payload,
  },
});

export const { setBearerToken, getBearerToken } = bearerTokenSlice.actions;
export default bearerTokenSlice.reducer;
