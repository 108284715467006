import { productionTransactionBeforeSave } from "services/sosInventoryService/productionTransaction/beforeSave";
import {
  normalizeLot,
  lotSerialNormalizationForFinishedGoods,
} from "services/utility/beforeSave";
import { convertNativeToCustom } from "services/utility/customFields";

import { OBJECT_TYPES } from "appConstants";

export function beforeSave(record) {
  const newRecord = productionTransactionBeforeSave(
    OBJECT_TYPES.BUILD.fullString,
    record
  );

  // for each line
  const newOutputs = [];
  newRecord.outputs.forEach((output) => {
    let newOutput = { ...output };
    newOutput.lot = normalizeLot(newOutput.lot);
    newOutput = lotSerialNormalizationForFinishedGoods(
      newOutput,
      record.workcenter
    );
    newOutputs.push(newOutput);
  });

  // update the record with the new lines
  newRecord.outputs = newOutputs;

  newRecord.customFields = convertNativeToCustom(record.customFields);

  return newRecord;
}
