import { Box } from "@mui/material";

import { Layout } from "Layout";

import { ListPage } from "components/ListPage/ListPage";

import { i18n } from "services/i18nService";

import { OBJECT_TYPES } from "appConstants";

const objectType = OBJECT_TYPES.CUSTOMER_TYPE.fullString;

export function CustomerTypeList() {
  return (
    <Layout pageTitle={i18n(`objectType.${objectType}.SentencePlural`)}>
      <Box sx={{ display: "flex", position: "relative" }}>
        <ListPage objectType={objectType} isConfigurationList />
      </Box>
    </Layout>
  );
}
