import { LineItemTextField } from "components/formFields/LineItemTextField";
import { LineTableCell } from "components/formFields/LineTableCell";

import { LINE_ITEM_METADATA } from "services/sosInventoryService/itemReceipt/schema";

export function LineVendorPartNumber(props) {
  const { value, onValueChange } = props;

  return (
    <LineTableCell sx={{ minWidth: "8rem" }}>
      <LineItemTextField
        name="vendorPartNumber"
        value={value}
        maxLength={50}
        onValueChange={onValueChange}
        metadata={LINE_ITEM_METADATA.vendorPartNumber}
      />
    </LineTableCell>
  );
}
