// see views/common.js for error object structure
import { useState } from "react";

import { initialErrorState } from "views/common";

export function useErrors() {
  const [errors, setErrors] = useState(initialErrorState());

  function isInError(field) {
    return Boolean(errors.entity.find((f) => f === field));
  }

  return { errors, setErrors, isInError };
}
