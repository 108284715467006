export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixSalesReceipt: record.salesReceiptNumber.prefix,
    nextSalesReceiptNumber: record.salesReceiptNumber.nextNumber,
  };

  delete newRecord.salesReceiptNumber;

  return newRecord;
}
