import { FilterSelectField } from "components/formFields/FilterSelectField";

import { i18n } from "services/i18nService";

const ARCHIVE_OPTIONS = [
  { id: "Yes", name: i18n("filter.archive.yes") },
  { id: "No", name: i18n("filter.archive.no") },
  { id: "Both", name: i18n("filter.archive.both") },
];

export function EditArchiveFilter(props) {
  const { filters, setFilters } = props;
  const { filterSettings } = filters;
  const { ShowArchived = "No" } = filterSettings;

  function handleChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        ShowArchived: value?.id,
      },
      startingRecord: 1,
    }));
  }
  return (
    <FilterSelectField
      options={ARCHIVE_OPTIONS}
      onValueChange={handleChange}
      value={{ id: ShowArchived }}
      label={i18n("global.Archive")}
      dataTesting="archiveFilter"
      disableClearable
    />
  );
}
