import { i18n } from "services/i18nService";
import {
  formatTaxCodeRates,
  formatTaxCodeNames,
} from "services/utility/formatting";

export const taxCodeConfig = {
  columns: [
    {
      heading: i18n("columns.Name"),
      apiName: "name",
      defaultSort: true,
      sortable: true,
    },
    {
      heading: i18n("columns.RateName"),
      apiName: "taxRates",
      formatFunc: formatTaxCodeNames,
    },
    {
      heading: i18n("columns.Rate"),
      apiName: "taxRates",
      formatFunc: formatTaxCodeRates,
    },
  ],
  note: i18n("notes.TaxCodesMustBeEditedInQBO"),
};
