import { dateToLocalSosISO } from "services/utility/dates";
import { isFinishedGoods } from "services/utility/jobWorkCenter";

export function normalizeWorkCenters(workCenters) {
  return workCenters
    .map((workcenter) => ({ workcenter }))
    .filter(({ workcenter: { id } }) => id !== 1);
}

export function normalizeLot(lot) {
  // add id of 0 to each lot object
  // back end requires a zero as the id for new lots
  return lot?.name ? { id: 0, name: lot.name } : null;
}

export function lotSerialNormalizationForFinishedGoods(line, workcenter) {
  const newLine = { ...line };
  if (!isFinishedGoods(workcenter)) {
    newLine.lot = null;
    newLine.serials = null;
  }
  return newLine;
}

export function nonUtcDateTimeHandling(record) {
  const newRecord = { ...record };

  newRecord.date = record.dateChanged
    ? dateToLocalSosISO(record.date)
    : record.originalDate;

  delete newRecord.dateChanged;
  delete newRecord.originalDate;
  return newRecord;
}
