import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { Tooltip } from "components/Tooltip";

import { i18n } from "services/i18nService";

export function Favoriting(props) {
  const { favoriteId, favoritingDisabled, handleFavorite, handleUnfavorite } =
    props;

  return (
    <span style={{ marginTop: "0.8rem" }}>
      {favoriteId ? (
        <Tooltip title={i18n("tooltip.unfavorite")}>
          <div>
            <IconButton
              disabled={favoritingDisabled}
              onClick={handleUnfavorite}
            >
              <Favorite sx={{ color: "favoritedHeart", cursor: "pointer" }} />
            </IconButton>
          </div>
        </Tooltip>
      ) : (
        <Tooltip title={i18n("tooltip.favorite")}>
          <div>
            <IconButton disabled={favoritingDisabled} onClick={handleFavorite}>
              <FavoriteBorder sx={{ color: "disabled", cursor: "pointer" }} />
            </IconButton>
          </div>
        </Tooltip>
      )}
    </span>
  );
}
