import { Decimal } from "classes/DecimalClasses";

// convert numbers from float to Decimal
const decimalFields = ["salesTaxRate", "purchaseTaxRate"];

export function afterGet(record) {
  const newRecord = { ...record };

  decimalFields.forEach((numField) => {
    newRecord[numField] = new Decimal(record[numField]);
  });

  return newRecord;
}
