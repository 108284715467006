import { afterGet as globalAfterGet } from "services/sosInventoryService/afterGet";
import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/job/schema";
import {
  transformFields,
  transformWorkCenters,
} from "services/utility/afterGet";

export function afterGet(record) {
  const newRecord = transformFields(record, FIELDS_TO_TRANSFORM);

  if (record.workcenters) {
    newRecord.workcenters = transformWorkCenters(record.workcenters);
  }

  return globalAfterGet(newRecord);
}
