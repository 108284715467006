import { ActionButton } from "components/ActionButton";
import { LineTableCell } from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";
import { getAndSortWorkCenters } from "services/sosInventoryService/domainLogic";

const cellStyles = {
  border: "0",
  borderRight: "0",
  width: "auto",
  padding: "0.5rem 1rem 0.5rem 0.8rem",
};

export function FinalGoodsStage(props) {
  const { stage, stages, stageHandler, workCenters } = props;

  async function restore() {
    const oldStages = [...stages];
    let newStages = workCenters;
    if (!stages) {
      newStages = await getAndSortWorkCenters();
    }
    const stageIds = stages.map(({ id }) => id);
    const finishedGoodsStage = oldStages.pop();
    const restoredStages = newStages.filter(({ id }) => !stageIds.includes(id));

    stageHandler({
      type: "set",
      lines: [...oldStages, ...restoredStages, finishedGoodsStage],
    });
  }

  if (!stage) {
    return null;
  }

  return (
    <>
      <LineTableCell colSpan={3} sx={cellStyles}>
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
          }}
        >
          <ActionButton onClick={restore}>
            {i18n("global.RestoreStages")}
          </ActionButton>
        </div>
      </LineTableCell>
      <LineTableCell sx={cellStyles}>{stage.name}</LineTableCell>
    </>
  );
}
