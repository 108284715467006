import { FilterSelectField } from "components/formFields/FilterSelectField";

import { useRelated } from "hooks/useRelated";

export function VendorFilter(props) {
  const { value, onValueChange, name, label } = props;

  const vendors = useRelated("vendor");

  return (
    <FilterSelectField
      name={name}
      label={label}
      options={vendors}
      value={value}
      onValueChange={onValueChange}
    />
  );
}
