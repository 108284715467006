import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import { formatDecimal, extractName } from "services/utility/formatting";

export const onPo = {
  relatedObjectType: "purchaseorder",
  headerText: i18n("modalHeader.onPo"),
  columns: [
    {
      jsonName: "refNumber",
      label: i18n("columns.ReferenceNumber"),
      linkColumn: true,
      sortable: true,
    },
    {
      jsonName: "quantity",
      label: i18n("columns.Ordered"),
      formatFunc: formatDecimal,
      number: true,
      sortable: true,
    },
    {
      jsonName: "received",
      label: i18n("columns.Received"),
      formatFunc: formatDecimal,
      number: true,
      sortable: true,
    },
    {
      jsonName: "vendor",
      label: i18n("columns.Vendor"),
      formatFunc: extractName,
      sortable: true,
    },
    { jsonName: "memo", label: i18n("columns.Memo") },
    {
      jsonName: "shipMethod",
      label: i18n("columns.ShipMethod"),
      formatFunc: extractName,
    },
    { jsonName: "trackingNumber", label: i18n("columns.TrackingNumber") },
    {
      jsonName: "eta",
      label: i18n("columns.ETA"),
      formatFunc: formatDate,
      sortable: true,
    },
    {
      jsonName: "ets",
      label: i18n("columns.ETS"),
      formatFunc: formatDate,
      sortable: true,
    },
  ],
  FIELDS_TO_TRANSFORM: {
    DECIMAL_FIELDS: ["received", "quantity"],
    MONEY_FIELDS: [],
    DATE_FIELDS: ["eta", "ets"],
    DATETIME_FIELDS: [],
    NON_UTC_DATETIME_FIELDS: [],
  },
};
