import { useSelector } from "react-redux";

import { TableFooter, TableRow } from "@mui/material";

import { AddLines } from "components/EditPage/AddLines";
import { LineTableFooterCell } from "components/formFields/LineTableCell";

import { setPageDirty } from "services/utility/edit";
import { decimalMaximumFractionDigits } from "services/utility/formatting";
import { textForAddLineOrLines } from "services/utility/lineItems";

import { DEFAULT_DECIMALS_UNROUNDED } from "appConstants";

export function LineItemFooter(props) {
  const {
    totals,
    firstCellSpan,
    showWeight,
    showVolume,
    quantityString = "quantity",
    lineHandler,
    objectType,
    lineType,
  } = props;
  const { weight, weightunit, volume, volumeunit } = totals;

  const uomEnabled = useSelector(
    (state) => state.userCompanySettings.settings.uomEnabled
  );
  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  const localizedQuantity = decimalMaximumFractionDigits(
    totals[quantityString],
    DEFAULT_DECIMALS_UNROUNDED,
    true
  );

  function addLines() {
    setPageDirty();
    lineHandler({
      type: "appendEmpty",
      numLinesToAdd,
      objectType,
      lineType,
    });
  }

  return (
    <TableFooter>
      <TableRow>
        <LineTableFooterCell sx={{ border: "none" }} />
        <AddLines
          title={textForAddLineOrLines(numLinesToAdd)}
          onClick={addLines}
        />
        <LineTableFooterCell colSpan={firstCellSpan - 1} />
        <LineTableFooterCell bordered sx={{ padding: "0.5rem 14px" }}>
          {localizedQuantity.toString()}
        </LineTableFooterCell>
        {uomEnabled && (showWeight || showVolume) && <LineTableFooterCell />}
        {showWeight && (
          <LineTableFooterCell bordered sx={{ padding: "0.5rem 0.3rem" }}>
            {`${weight.toString()}${weightunit ? ` ${weightunit}` : ""}`}
          </LineTableFooterCell>
        )}
        {showVolume && (
          <LineTableFooterCell bordered sx={{ padding: "0.5rem 0.3rem" }}>
            {`${volume.toString()}${volumeunit ? ` ${volumeunit}` : ""}`}
          </LineTableFooterCell>
        )}
      </TableRow>
    </TableFooter>
  );
}
