import { useSelector } from "react-redux";

import { TotalsLine } from "components/formFields/Totals/TotalsLine";

import { currencyMinMaxFractionDigits } from "services/utility/formatting";

export function LineItemTotals(props) {
  const { value, label } = props;

  const currencyMinimumFractionDigits = useSelector(
    (state) =>
      state.userCompanySettings.localization.currencyMinimumFractionDigits
  );

  function getLocalizedValue(value) {
    return currencyMinMaxFractionDigits(
      value,
      currencyMinimumFractionDigits,
      currencyMinimumFractionDigits,
      true
    );
  }

  return <TotalsLine label={label} value={getLocalizedValue(value)} />;
}
