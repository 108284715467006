export function afterGet(record) {
  const newRecord = {
    ...record,
    invoiceNumber: {
      prefix: record.prefixInvoice,
      nextNumber: record.nextInvoiceNumber,
    },
  };

  delete newRecord.prefixInvoice;
  delete newRecord.nextInvoiceNumber;

  return newRecord;
}
