import { useSelector } from "react-redux";

import { LineItemSelectWithExact } from "components/formFields/LineItemSelectWithExact";
import { LineTableCell } from "components/formFields/LineTableCell";

export function LineCustomer(props) {
  const { value, customers, onValueChange } = props;

  const showCustomerOnPurchaseForms = useSelector(
    (state) => state.userCompanySettings.settings.showCustomerOnPurchaseForms
  );

  const findCustomerByFullName = useSelector(
    (state) => state.userCompanySettings.settings.findCustomerByFullName
  );

  return (
    showCustomerOnPurchaseForms && (
      <LineTableCell sx={{ minWidth: "13rem" }}>
        <LineItemSelectWithExact
          name="customer"
          options={customers}
          optionDisplayText={findCustomerByFullName ? "fullname" : "name"}
          value={value}
          onValueChange={onValueChange}
          allowTooltip={true}
          dataTesting="customer"
        />
      </LineTableCell>
    )
  );
}
