import { i18n } from "services/i18nService";
import {
  extractName,
  formatMoneyWithoutAdornments,
  truncate,
  formatItemQtyByItemType,
  formatInteractiveInventoryColumn,
  formatItemAvailabilityByItemType,
  formatMoneyForAccounting,
  formatImage,
} from "services/utility/formatting";
import { parseHTML } from "services/utility/parsing";
import { starColumn } from "services/utility/schema";

import { USER_PRIVILEGE } from "appConstants";

export const columnInfo = [
  starColumn,
  {
    name: "restore",
    buttonLabel: i18n("columns.Restore"),
    buttonAction: "restore",
    isButton: true,
    fixed: true,
    excludeFromColumnList: true,
  },
  {
    name: "id",
    heading: i18n("columns.ReferenceNumber"),
    minWidth: "7em",
    default: true,
  },
  {
    name: "name",
    heading: i18n("columns.Name"),
    sortable: true,
    default: true,
    defaultSort: true,
  },
  {
    name: "description",
    heading: i18n("columns.Description"),
    minWidth: "15em",
    sortable: true,
    default: true,
  },
  {
    name: "category",
    heading: i18n("columns.Category"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "type",
    heading: i18n("columns.Type"),
    sortable: true,
    minWidth: "8rem",
  },
  {
    name: "vendor",
    heading: i18n("columns.Vendor"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "20em",
    sortable: true,
  },
  {
    name: "onhand",
    heading: i18n("columns.InStock"),
    formatFunc: formatItemQtyByItemType,
    align: "right",
    sortable: true,
  },
  {
    name: "available",
    heading: i18n("columns.Available"),
    formatFunc: formatItemAvailabilityByItemType,
    align: "right",
    sortable: true,
  },
  {
    name: "onSO",
    formatFunc: [formatItemQtyByItemType, formatInteractiveInventoryColumn],
    heading: i18n("columns.OnSO"),
    align: "right",
    inventoryType: "onSo",
    sortable: true,
  },
  {
    name: "onSR",
    heading: i18n("columns.OnSR"),
    formatFunc: [formatItemQtyByItemType, formatInteractiveInventoryColumn],
    align: "right",
    inventoryType: "onSr",
    sortable: true,
  },
  {
    name: "onPO",
    formatFunc: [formatItemQtyByItemType, formatInteractiveInventoryColumn],
    heading: i18n("columns.OnPO"),
    align: "right",
    inventoryType: "onPo",
    sortable: true,
  },
  {
    name: "onRMA",
    formatFunc: [formatItemQtyByItemType, formatInteractiveInventoryColumn],
    heading: i18n("columns.OnRMA"),
    align: "right",
    inventoryType: "onRma",
    sortable: true,
  },
  {
    name: "onWO",
    formatFunc: [formatItemQtyByItemType, formatInteractiveInventoryColumn],
    heading: i18n("columns.OnWO"),
    align: "right",
    inventoryType: "onWo",
    sortable: true,
  },
  {
    name: "rented",
    heading: i18n("columns.OnRental"),
    formatFunc: [formatItemQtyByItemType, formatInteractiveInventoryColumn],
    align: "right",
    inventoryType: "onRental",
    sortable: true,
  },
  {
    name: "reorderPoint",
    heading: i18n("columns.ReorderPoint"),
    formatFunc: formatItemQtyByItemType,
    align: "right",
    sortable: true,
  },
  {
    name: "maxStock",
    heading: i18n("columns.MaxStock"),
    formatFunc: formatItemQtyByItemType,
    sortable: true,
    align: "right",
  },
  {
    name: "salesPrice",
    heading: i18n("columns.SalesPrice"),
    formatFunc: formatMoneyWithoutAdornments,
    sortable: true,
    align: "right",
  },
  {
    name: "purchaseCost",
    formatFunc: formatMoneyWithoutAdornments,
    heading: i18n("columns.PurchasePrice"),
    align: "right",
    sortable: true,
    privilegeRestriction: USER_PRIVILEGE.viewCosts,
  },
  {
    name: "costBasis",
    heading: i18n("columns.CostBasis"),
    formatFunc: formatMoneyForAccounting,
    align: "right",
    sortable: true,
    privilegeRestriction: [USER_PRIVILEGE.editItems, USER_PRIVILEGE.viewCosts],
  },
  {
    name: "weight",
    heading: i18n("global.Weight"),
    formatFunc: formatItemQtyByItemType,
    align: "right",
    sortable: true,
  },
  {
    name: "volume",
    heading: i18n("global.Volume"),
    formatFunc: formatItemQtyByItemType,
    sortable: true,
    align: "right",
  },
  {
    name: "hasImage",
    heading: i18n("columns.Image"),
    formatFunc: formatImage,
  },
  {
    name: "barcode",
    heading: i18n("columns.Barcode"),
    sortable: true,
  },
  {
    name: "sku",
    heading: i18n("columns.Sku"),
    sortable: true,
  },
  {
    name: "url",
    heading: i18n("columns.Url"),
    sortable: true,
  },
  {
    name: "tags",
    heading: i18n("columns.Tags"),
    sortable: true,
  },
  {
    name: "notes",
    heading: i18n("columns.Notes"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
  },
  {
    name: "bin",
    heading: i18n("columns.Bin"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "purchaseDescription",
    heading: i18n("columns.PurchaseDescription"),
    sortable: true,
  },
  {
    name: "vendorPartNumber",
    heading: i18n("columns.VendorPartNumber"),
    sortable: true,
    privilegeRestriction: [
      USER_PRIVILEGE.editItems,
      USER_PRIVILEGE.viewVendors,
    ],
  },
  {
    name: "warranty",
    heading: i18n("columns.Warranty"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "sync",
    fieldName: "syncMessage",
    heading: i18n("columns.Sync"),
    sortable: true,
  },
];

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [
    "onhand",
    "available",
    "onSO",
    "onSR",
    "rented",
    "onWO",
    "onPO",
    "onRMA",
    "reorderPoint",
    "maxStock",
    "weight",
    "volume",
  ],
  MONEY_FIELDS: ["salesPrice", "costBasis", "purchaseCost"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

const columnArray = Object.entries(columnInfo);
const defaultColumnInfo = columnArray.filter((col) => col[1].required);
export const defaultColumns = defaultColumnInfo.map((col) => col[0]);

export const LINE_ACTIONS = ["history"];
export const BATCH_ACTIONS = ["restore"];
