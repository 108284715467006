import { LineItemNumericCalculatorField } from "components/formFields/LineItemNumericCalculatorField";
import { LineTableCell } from "components/formFields/LineTableCell";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineQuantityDiff(props) {
  const { value, onValueChange, objectType, error } = props;

  return (
    <LineTableCell sx={{ minWidth: "5.5rem" }}>
      <LineItemNumericCalculatorField
        name="quantityDiff"
        value={value}
        onValueChange={onValueChange}
        metadata={LINE_ITEM_METADATA[objectType].quantityDiff}
        error={error}
        dataTesting="lineQuantityDiff"
      />
    </LineTableCell>
  );
}
