export function beforeSave(record) {
  const { format, width, height } = record.settings;
  const newRecord = {
    ...record,
    barcodeFormat: format,
    barcodeWidth: width,
    barcodeHeight: height,
  };

  delete newRecord.settings;

  return newRecord;
}
