import { i18n } from "services/i18nService";
import { setPageDirty } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";
import { normalizeSerials } from "services/utility/serials";

import { EMPTY_LINE_ITEM } from "editConfig";

export function handleDrop(result, lineHandler, lines) {
  const { source, destination } = result;

  // if there's no destination, the item was dropped outside of the
  // droppable area
  if (!destination) {
    return;
  }

  // destination same as source, no-op
  if (destination.index === source.index) {
    return;
  }

  // make the move
  const newLines = [...lines];
  const savedSource = newLines.splice(source.index, 1)[0];
  newLines.splice(destination.index, 0, savedSource);

  // renumber and update changed lines
  const start = Math.min(source.index, destination.index);
  const end = Math.max(source.index, destination.index);
  setPageDirty();
  for (let i = start; i <= end; i++) {
    newLines[i].lineNumber = i + 1;
    lineHandler({ type: "update", updatedLine: newLines[i] });
  }
}

export function copyDown(line, lineHandler, record, objectType) {
  let updatedLine = {
    ...line,
    lineNumber: line.lineNumber + 1,
    serials: normalizeSerials(
      EMPTY_LINE_ITEM[objectType].serials,
      line.quantity,
      line.itemDetails?.serialTracking,
      record.autoSerialLots
    ),
    lot: EMPTY_LINE_ITEM[objectType].lot,
    lotExpiration: EMPTY_LINE_ITEM[objectType].lotExpiration,
  };
  delete updatedLine.id;
  setPageDirty();
  lineHandler({ type: "update", updatedLine: updatedLine });
}

export function textForAddLineOrLines(numLinesToAdd) {
  if (numLinesToAdd < 1) {
    handleProgramError(
      new Error(`Unexpected value for numLinesToAdd (${numLinesToAdd})`)
    );
  }
  return numLinesToAdd === 1 ? i18n("global.AddLine") : i18n("global.AddLines");
}
