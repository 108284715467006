import { useRef } from "react";
import { useSelector } from "react-redux";

import { Button } from "@mui/material";

import { FullsteamForms } from "components/FullsteamForms";
import { Tooltip } from "components/Tooltip";
import { FrmCheckboxWithHoverHelp } from "components/formFields/frm/FrmCheckboxWithHoverHelp";
import { Link } from "components/html/Link";

import { i18n } from "services/i18nService";
import { logHostedPaymentsResult } from "services/sosInventoryService/sosApi";
import { formatCardOnFile } from "services/utility/formatting";
import { getCardType } from "services/utility/misc";
import { launchHostedPayments } from "services/utility/payments";

export function FrmCardInfo(props) {
  const {
    customer,
    onValueChange,
    postalCode,
    contact,
    paymentInfo,
    storeCustomerToken,
    hasCardOnFile,
    showKeepOnFile,
  } = props;

  const idRef = useRef();
  const tokenRef = useRef();
  const responseRef = useRef();
  const fullSteamCardRef = useRef();

  const paymentsBoarded = useSelector(
    (state) => state.userCompanySettings.settings.paymentsBoarded
  );

  async function handleHostedPayments() {
    launchHostedPayments("card", postalCode, contact, fullsteamComplete);
  }

  async function fullsteamComplete() {
    const id = idRef.current.value;
    const token = tokenRef.current.value;
    const result = responseRef.current.value;
    logHostedPaymentsResult({ id, token, result });

    const parsedResult = JSON.parse(result);
    const { gatewayResponse, requestDetails } = parsedResult;
    if (gatewayResponse.accountDetails) {
      const {
        expirationMonth,
        expirationYear,
        paymentAccountLast4,
        paymentMethod,
      } = gatewayResponse.accountDetails;
      const paymentInfo = formatCardOnFile(null, {
        expMonth: expirationMonth,
        expYear: expirationYear,
        lastFour: paymentAccountLast4,
        tokenType: getCardType(paymentMethod),
      });
      onValueChange("accountToken", { token, paymentInfo });
    } else {
      const { accountNumber, accountType } = requestDetails;
      const paymentInfo = formatCardOnFile(null, {
        lastFour: accountNumber,
        tokenType: getCardType(accountType),
      });
      onValueChange("accountToken", { token, paymentInfo });
    }
    fullSteamCardRef.current.innerHTML = "";
  }

  if (!paymentsBoarded) {
    return (
      <Link to="/payment">{i18n("payment.LearnHowToProcessPayments")}</Link>
    );
  }

  return (
    <>
      {hasCardOnFile ? (
        <>
          <div>
            {i18n("global.Via")}: {paymentInfo}
          </div>
          {showKeepOnFile && (
            <FrmCheckboxWithHoverHelp
              name="storeCustomerToken"
              value={storeCustomerToken}
              onValueChange={onValueChange}
              label={i18n("frmLabel.KeepOnFile")}
              helpText={i18n("hoverHelp.StorePaymentInformation")}
            />
          )}
        </>
      ) : (
        <Tooltip title={customer ? "" : i18n("tooltip.SelectCustomerFirst")}>
          <div>
            <Button
              onClick={handleHostedPayments}
              disabled={!customer}
              color="primary"
            >
              {i18n("payment.EnterCardInfo")}
            </Button>
          </div>
        </Tooltip>
      )}
      <FullsteamForms
        idRef={idRef}
        tokenRef={tokenRef}
        responseRef={responseRef}
        fullSteamCardRef={fullSteamCardRef}
      />
    </>
  );
}
