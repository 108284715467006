import { i18n } from "services/i18nService";
import { getDocument } from "services/sosInventoryService/sosApi";
import { formatDocumentInfo } from "services/utility/formatting";

export const accountConfig = {
  columns: [
    {
      heading: i18n("columns.Name"),
      apiName: "name",
      sortable: true,
      defaultSort: true,
    },
    {
      heading: i18n("columns.Description"),
      apiName: "description",
      sortable: true,
    },
    {
      heading: i18n("columns.AccountType"),
      apiName: "accountType",
      sortable: true,
      minWidth: "8rem",
    },
    {
      heading: i18n("columns.AccountNumber"),
      apiName: "accountNumber",
      sortable: true,
    },
    {
      heading: i18n("columns.Documents"),
      apiName: "documents",
      formatFunc: formatDocumentInfo,
      apiFunc: getDocument,
      isDocument: true,
      maxWidth: "12rem",
    },
  ],
};
