import { Layout } from "Layout";

import { ListPage } from "components/ListPage/ListPage";

import { i18n } from "services/i18nService";

import { OBJECT_TYPES } from "appConstants";

const objectType = OBJECT_TYPES.DELETED_ITEM.fullString;

export function DeletedItemList() {
  return (
    <Layout pageTitle={i18n(`objectType.${objectType}.SentencePlural`)}>
      <ListPage objectType={objectType} />
    </Layout>
  );
}
