import { theme } from "SosTheme";

const regWeight = theme.typography.fontWeightRegular;

const wrapperStyles = {
  display: "grid",
  gridTemplateColumns: "3fr 2fr",
  columnGap: theme.spacing(1),
  alignItems: "center",
  padding: "0.5rem 0",
};

export function TotalsLine(props) {
  const { label, value, units, dataTesting } = props;

  return (
    <div style={wrapperStyles}>
      <div style={{ textAlign: "right" }}>
        <span
          data-testing={dataTesting}
          style={{ fontWeight: regWeight, padding: "0 14px" }}
        >
          {value.toString()}
          {units ? ` ${units}` : ""}
        </span>
      </div>
      <div
        style={{
          fontWeight: theme.typography.fontWeightMedium,
          textAlign: "left",
        }}
      >
        {label}
      </div>
    </div>
  );
}
