export function setItemInStorage(key, value) {
  const rememberMe = Boolean(localStorage.getItem("rememberMe"));
  if (rememberMe) {
    localStorage.setItem(key, value);
  } else {
    sessionStorage.setItem(key, value);
  }
}

export function getItemInStorage(key) {
  const rememberMe = Boolean(localStorage.getItem("rememberMe"));
  if (rememberMe) {
    return localStorage.getItem(key);
  } else {
    return sessionStorage.getItem(key);
  }
}

export function removeItemInStorage(key) {
  const rememberMe = Boolean(localStorage.getItem("rememberMe"));
  if (rememberMe) {
    localStorage.removeItem(key);
  } else {
    sessionStorage.removeItem(key);
  }
}
