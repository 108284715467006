import Delete from "@mui/icons-material/Delete";

import { LineTableCell } from "components/formFields/LineTableCell";

import { setPageDirty } from "services/utility/edit";

import { theme } from "SosTheme";

const cellStyles = {
  padding: "0.5rem 1rem 0.5rem 0.8rem",
  width: "auto",
};

// created this wrapper to make drag and drop code easier to see
export function Stage(props) {
  return <StageDetails {...props} />;
}

function StageDetails(props) {
  const { stage, stageHandler } = props;

  function handleDelete() {
    setPageDirty();
    stageHandler({ type: "delete", deleteAt: stage.lineNumber });
  }

  if (!stage) {
    return null;
  }

  return (
    <>
      <LineTableCell sx={cellStyles}>
        <div
          onClick={handleDelete}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
            color: theme.palette.icons,
          }}
        >
          <Delete />
        </div>
      </LineTableCell>
      <LineTableCell sx={cellStyles}>
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
          }}
        >
          {stage.lineNumber}
        </div>
      </LineTableCell>
      <LineTableCell sx={cellStyles}>{stage.name}</LineTableCell>
    </>
  );
}
