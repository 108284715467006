import { i18n } from "services/i18nService";
import { fulfillmentTransactionIsValid } from "services/sosInventoryService/fulfillmentTransaction/isValid";

export function isValid(record, relatedRecords, lines) {
  const errors = fulfillmentTransactionIsValid(record, relatedRecords, lines);

  // is associated with a customer
  if (typeof record.customer?.id !== "number") {
    errors.messages.push(i18n("isValid.Customer"));
    errors.entity.push("customer");
  }

  return errors;
}
