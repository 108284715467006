import { useState } from "react";

import { Button, Menu, MenuItem } from "@mui/material";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function FrmContactMessageSelector(props) {
  const { type, onChange } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const messages = useRelated(`${type}message`) || [];

  function openMenu(e) {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  }

  function handleMessageChange(messageId) {
    if (messageId) {
      const message = messages.find(({ id }) => id === messageId);
      onChange(message);
      setOpen(false);
    }
  }

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        sx={{ fontSize: "0.75em", padding: "0 0.5em", marginBottom: "0.3em" }}
        onClick={openMenu}
      >
        {i18n("global.predefinedMessages")}
      </Button>

      <Menu open={open} anchorEl={anchorEl} onClose={() => setOpen(false)}>
        {messages.map((message) => (
          <MenuItem
            key={message.id}
            onClick={() => handleMessageChange(message.id)}
          >
            {message.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
