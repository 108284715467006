import { formatDate } from "services/utility/dates";
import { handleProgramError } from "services/utility/errors";

import { OBJECT_TYPES, NO_REF_NUMBER_STRING } from "appConstants";

export function downloadDocument(documentBlob, downloadText) {
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);
  a.href = window.URL.createObjectURL(documentBlob);
  a.setAttribute("download", downloadText);
  a.click();
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}

export const DEFAULT_DOCUMENT_LINK_RECORD = {
  description: null,
  canShare: true,
  content: null,
};

export function getDocumentSelectionName(line, type) {
  const { refNumber, number, name, customer, vendor, date, fullname } = line;
  const identifier = number || refNumber || NO_REF_NUMBER_STRING;
  const contactName = customer?.name || vendor?.name;
  switch (type) {
    case OBJECT_TYPES.ACCOUNT.typeString:
      return fullname;
    case OBJECT_TYPES.JOB.typeString:
      return `${identifier} ${contactName ? `(${contactName})` : ""}`;
    case OBJECT_TYPES.BUILD.typeString:
      return `${identifier} (${line.outputs[0].item.name} ${formatDate(date)})`;
    case OBJECT_TYPES.LOT.typeString:
    case OBJECT_TYPES.SERIAL.typeString:
      return identifier;
    case OBJECT_TYPES.CLASS.typeString:
    case OBJECT_TYPES.CUSTOMER.typeString:
    case OBJECT_TYPES.ITEM.typeString:
    case OBJECT_TYPES.VENDOR.typeString:
    case OBJECT_TYPES.EMPLOYEE.typeString:
      return name;
    case OBJECT_TYPES.ADJUSTMENT.typeString:
    case OBJECT_TYPES.BILL.typeString:
    case OBJECT_TYPES.BILL_PAYMENT.typeString:
    case OBJECT_TYPES.JOURNAL_ENTRY.typeString:
    case OBJECT_TYPES.PROCESS.typeString:
    case OBJECT_TYPES.TRANSFER.typeString:
    case OBJECT_TYPES.WORK_ORDER.typeString:
      return `${identifier} (${formatDate(date)})`;
    case OBJECT_TYPES.ESTIMATE.typeString:
    case OBJECT_TYPES.CREDIT_MEMO.typeString:
    case OBJECT_TYPES.INVOICE.typeString:
    case OBJECT_TYPES.ITEM_RECEIPT.typeString:
    case OBJECT_TYPES.PAYMENT.typeString:
    case OBJECT_TYPES.PICK_TICKET.typeString:
    case OBJECT_TYPES.PURCHASE.typeString:
    case OBJECT_TYPES.PURCHASE_ORDER.typeString:
    case OBJECT_TYPES.RENTAL.typeString:
    case OBJECT_TYPES.RENTAL_RETURN.typeString:
    case OBJECT_TYPES.RETURN.typeString:
    case OBJECT_TYPES.RETURN_TO_VENDOR.typeString:
    case OBJECT_TYPES.RMA.typeString:
    case OBJECT_TYPES.SALES_ORDER.typeString:
    case OBJECT_TYPES.SALES_RECEIPT.typeString:
    case OBJECT_TYPES.SHIPMENT.typeString:
      return `${identifier} (${contactName} ${formatDate(date)})`;
    default:
      handleProgramError(
        new Error(
          `getDocumentSelectionName | Unexpected value for type (${type})`
        )
      );
  }
}
