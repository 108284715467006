// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import {
  extractName,
  formatDocumentInfo,
  formatMoneyWithAdornments,
  formatBooleanYesOrBlank,
  formatReverseBooleanYesOrBlank,
  formatStatus,
  formatNumberLinkField,
  truncate,
  extractReceived,
  formatSync,
  formatDecimal,
  formatCompanyInfo,
  formatBooleanToYesOrBlank,
  extractDescription,
  formatQuantityWithUom,
  getMoneyTotalWithAdornments,
  formatLinkableItem,
} from "services/utility/formatting";
import {
  dateTypeEditStrFunction,
  referenceTypeEditStrFunction,
  arrayOfReferencesTypeEditStrFunction,
  inventoryItemTypeEditStrFunction,
  textTypeEditStrFunction,
  linkedTransactionTypeEditStrFunction,
  numberTypeEditStrFunction,
  decimalTypeEditStrFunction,
} from "services/utility/list";
import { parseHTML } from "services/utility/parsing";
import { starColumn, TYPES } from "services/utility/schema";

import { NO_REF_NUMBER_STRING } from "appConstants";
import { USER_PRIVILEGE } from "appConstants";

export const columnInfo = [
  starColumn,
  {
    name: "number",
    fixed: true,
    heading: i18n("columns.ReferenceNumber"),
    formatFunc: formatNumberLinkField,
    minWidth: "7em",
    linkField: true,
    sortable: true,
  },
  {
    name: "date",
    heading: i18n("columns.Date"),
    formatFunc: formatDate,
    defaultSort: true,
    sortDirection: "asc",
    sortable: true,
    default: true,
  },
  {
    name: "vendor",
    heading: i18n("columns.Vendor"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "20em",
    sortable: true,
    default: true,
  },
  {
    name: "terms",
    heading: i18n("columns.Terms"),
    formatFunc: extractName,
    sortable: true,
    default: true,
  },
  {
    name: "total",
    heading: i18n("columns.Amount"),
    formatFunc: formatMoneyWithAdornments,
    align: "right",
    sortable: true,
    default: true,
    privilegeRestriction: USER_PRIVILEGE.viewCosts,
  },
  {
    name: "status",
    fieldName: "closed",
    heading: i18n("columns.Status"),
    formatFunc: formatStatus,
    sortable: true,
    default: true,
  },
  {
    name: "Received",
    fieldName: "linkedReceipts",
    apiColumnName: "linkedReceipts",
    heading: i18n("columns.Received"),
    formatFunc: extractReceived,
    minWidth: "10em",
    sortable: true,
    default: true,
  },
  {
    name: "comment",
    heading: i18n("columns.Comment"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "location",
    heading: i18n("columns.Location"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "pendingApproval",
    heading: i18n("columns.Approved"),
    formatFunc: formatReverseBooleanYesOrBlank,
    sortable: true,
  },
  {
    name: "sync",
    fieldName: "syncMessage",
    heading: i18n("columns.Sync"),
    formatFunc: formatSync,
    sortable: true,
  },
  {
    name: "confirmed",
    heading: i18n("columns.Confirmed"),
    formatFunc: formatBooleanYesOrBlank,
    sortable: true,
  },
  {
    name: "customer",
    heading: i18n("columns.Customer"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "25em",
    sortable: true,
  },
  {
    name: "assignedToUser",
    heading: i18n("columns.AssignedTo"),
    formatFunc: extractName,
    minWidth: "10em",
    sortable: true,
  },
  {
    name: "dropShip",
    heading: i18n("columns.DropShip"),
    formatFunc: formatBooleanYesOrBlank,
    sortable: true,
  },
  {
    name: "expectedShip",
    heading: i18n("columns.ETS"),
    formatFunc: formatDate,
    sortable: true,
  },
  {
    name: "expectedDate",
    heading: i18n("columns.ETA"),
    formatFunc: formatDate,
    sortable: true,
  },
  {
    name: "shippingMethod",
    heading: i18n("columns.ShipMethod"),
    formatFunc: extractName,
  },
  {
    name: "openAmt",
    fieldName: "openAmount",
    heading: i18n("columns.OpenAmount"),
    formatFunc: formatMoneyWithAdornments,
    sortable: true,
    privilegeRestriction: USER_PRIVILEGE.viewCosts,
  },
  {
    name: "trackingNumber",
    heading: `${i18n("columns.Tracking")} #`,
    minWidth: "10em",
  },
  {
    name: "depositAmount",
    heading: i18n("columns.Deposit"),
    formatFunc: formatMoneyWithAdornments,
    align: "right",
  },
  {
    name: "department",
    heading: i18n("columns.Department"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "billing",
    heading: i18n("columns.Billing"),
    formatFunc: formatCompanyInfo,
  },
  {
    name: "shipping",
    heading: i18n("columns.Shipping"),
    formatFunc: formatCompanyInfo,
  },
  {
    name: "currency",
    heading: i18n("columns.Currency"),
    formatFunc: extractName,
  },
  {
    name: "taxCode",
    heading: i18n("columns.TaxCode"),
    formatFunc: extractName,
  },
  {
    name: "exchangeRate",
    heading: i18n("columns.ExchangeRate"),
    formatFunc: formatDecimal,
  },
  {
    name: "vendorMessage",
    heading: i18n("columns.VendorMessage"),
  },
  {
    name: "blanketPO",
    heading: i18n("columns.BlanketPO"),
    formatFunc: formatBooleanYesOrBlank,
  },
  {
    name: "contractManufacturing",
    heading: i18n("columns.ContractManufacturing"),
    formatFunc: formatBooleanYesOrBlank,
  },
  {
    name: "hasSignature",
    heading: i18n("columns.Signature"),
    formatFunc: formatBooleanToYesOrBlank,
  },
];

export function getEmptyRecord(settings) {
  return {
    archived: false,
    assignedToUser: null,
    billing: null,
    blanketPO: false,
    closed: false,
    comment: "",
    contractManufacturing: false,
    confirmed: false,
    currency: settings.homeCurrencyRef,
    customer: null,
    customFields: null,
    date: new Date(),
    department: null,
    depositPercent: null,
    depositAmount: new Money(0),
    documents: null,
    dropShip: false,
    exchangeRate: new Decimal(1.0),
    expectedDate: null,
    expectedShip: null,
    hasSignature: false,
    keys: null,
    lines: [],
    linkedTransaction: null,
    location: null,
    number: "",
    pendingApproval: false,
    shipping: null,
    shippingMethod: null,
    starred: 0,
    subTotal: new Money(0),
    summaryOnly: false,
    syncToken: 0,
    taxCode: null,
    taxAmount: null,
    terms: null,
    total: new Money(0),
    trackingNumber: "",
    updateDefaultCosts: settings.updateDefaultCostsOnPurchase,
    vendor: null,
    vendorMessage: "",
    vendorNotes: "",
    values: null,
  };
}

// can omit initialValue if null
// editStr should return the value that should be used on an input element of a
// form; for example, null is a good value for the database, but should be "" on
// an input item
export const LINE_ITEM_METADATA = {
  lineNumber: {},
  item: {
    type: TYPES.reference,
    editStr: inventoryItemTypeEditStrFunction,
    initialValue: { id: null, name: null, description: null },
  },
  vendorPartNumber: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  class: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  bin: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  jobWorkcenter: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  customer: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  taxCode: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  description: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  quantity: {
    type: TYPES.decimal,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weight: {
    type: TYPES.decimal,
    placesRight: 5,
    editStr: numberTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weightunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  volume: {
    type: TYPES.decimal,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  volumeunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  unitprice: {
    type: TYPES.money,
    currency: true,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  amount: {
    type: TYPES.money,
    currency: true,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  received: {
    type: TYPES.decimal,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  uom: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  serials: {
    type: TYPES.arrayOfReferences,
    editStr: arrayOfReferencesTypeEditStrFunction,
    initialValue: null,
  },
  lot: {
    type: TYPES.reference,
    editStr: textTypeEditStrFunction,
  },
  lotExpiration: {
    type: TYPES.date,
    editStr: dateTypeEditStrFunction,
  },
  linkedTransaction: {
    type: TYPES.linkedTransaction,
    editStr: linkedTransactionTypeEditStrFunction,
  },
  linkedTransactionRefNumber: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  linkedTransactionLineNumber: {
    type: TYPES.number,
    editStr: numberTypeEditStrFunction,
  },
  relatedRecords: { initialValue: {} },
  itemDetails: { initialValue: {} },
};

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["exchangeRate"],
  MONEY_FIELDS: [
    "depositAmount",
    "subTotal",
    "taxAmount",
    "total",
    "openAmount",
  ],
  DATE_FIELDS: ["expectedShip", "expectedDate", "date"],
  DATETIME_FIELDS: ["lastSync"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const REFERENCE_FIELDS = ["location", "terms", "vendor", "currency"];

export const LINE_ACTIONS = [
  ["quickView", "pdf", "email"],
  "approve",
  "receive",
  "invoice",
  "template",
  "delete",
  "archive",
  "openClose",
  "confirm",
  "history",
  "sync",
];
export const BATCH_ACTIONS = [
  ["pdf", "email"],
  "approve",
  "delete",
  "archive",
  "openClose",
  "confirm",
  "sync",
];

export const ITEM_CALCULATED_FIELDS = ["purchaseCost"];

export const QUICK_VIEW_CONFIG = {
  footerSetting: "purchaseOrderFooter",
  addressConfig: [
    { name: "billing", label: i18n("address.Vendor") },
    { name: "shipping", label: i18n("address.ShipTo") },
  ],
  tableConfig: [{ type: "lines" }],
  headerFields: [
    {
      name: "date",
      formatFunc: formatDate,
      label: i18n("columns.Date"),
    },
    {
      name: "number",
      label: i18n("frmLabel.PoNumber"),
      defaultValue: NO_REF_NUMBER_STRING,
    },
  ],
  fields: [
    {
      name: "terms",
      label: i18n("frmLabel.Terms"),
      formatFunc: extractName,
    },
    {
      name: "shippingMethod",
      label: i18n("frmLabel.ShipMethod"),
      formatFunc: extractName,
    },
    {
      name: "trackingNumber",
      label: i18n("frmLabel.TrackingNumber"),
    },
    {
      name: "expectedShip",
      label: i18n("frmLabel.ExpectedShip"),
      formatFunc: formatDate,
    },
    {
      name: "expectedDate",
      label: i18n("frmLabel.ExpectedDate"),
      formatFunc: formatDate,
    },
    {
      name: "vendorMessage",
      label: i18n("frmLabel.VendorMessage"),
    },
  ],
  lines: [
    {
      name: "item",
      formatFunc: formatLinkableItem,
      heading: i18n("columns.Item"),
      align: "left",
      isItemField: true,
    },
    {
      name: "description",
      formatFunc: extractDescription,
      heading: i18n("columns.Description"),
      align: "left",
    },
    {
      name: "vendorPartNumber",
      heading: i18n("columns.VendorPartNumber"),
      align: "left",
    },
    {
      name: "quantity",
      formatFunc: formatQuantityWithUom,
      heading: i18n("columns.Quantity"),
      align: "right",
    },
    {
      name: "unitprice",
      formatFunc: formatMoneyWithAdornments,
      heading: i18n("columns.Rate"),
      align: "right",
    },
    {
      name: "amount",
      formatFunc: formatMoneyWithAdornments,
      footerFunc: getMoneyTotalWithAdornments,
      heading: i18n("columns.Amount"),
      align: "right",
    },
    {
      name: "received",
      formatFunc: formatDecimal,
      heading: i18n("columns.Received"),
      align: "right",
    },
  ],
};
