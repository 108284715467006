import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function FrmWarranty(props) {
  const { value, onValueChange } = props;

  const warranties = useRelated("warranty");

  return (
    <FrmSelectFromObjects
      label={i18n("frmLabel.Warranty")}
      value={value}
      onValueChange={onValueChange}
      options={warranties}
      name="warranty"
    />
  );
}
