export const SETTINGS = [
  {
    apiName: "workOrderNumber",
    component: "FrmTransactionNumber",
    transactionType: "workorder",
    hasInputLabel: true,
  },
  {
    apiName: "workOrderHeaderText",
    component: "FrmResizeTextField",
    maxLength: 50,
  },
  { apiName: "replyEmailWO", component: "FrmTextField", maxLength: 255 },
  { apiName: "workOrderEmail", component: "FrmResizeTextField" },
  { apiName: "workOrderFooter", component: "FrmResizeTextField" },
  { apiName: "defaultCCWorkOrder", component: "FrmTextField", maxLength: 255 },
];
