// see views/common.js for error object structure
import { i18n } from "services/i18nService";

import { initialErrorState } from "views/common";

export function productionTransactionIsValid(record, outputs, inputs) {
  const errors = initialErrorState();
  // not sure why this next line is required, but if eliminated, the entity property
  // is somehow getting hydrated with a value from a previous call to this function
  errors.entity = [];

  // has a valid date
  if (!(record.date instanceof Date)) {
    errors.messages.push(i18n("isValid.Date"));
    errors.entity.push("date");
  }

  // has at least one input item with an inventory item selected
  const withOutput = outputs.filter(({ item }) => item?.id);
  if (!withOutput.length) {
    errors.messages.push(i18n("isValid.OutputItemRequired"));
  }

  // has at least one input item with an inventory item selected
  const withInput = inputs.filter(({ item }) => item?.id);
  if (!withInput.length) {
    errors.messages.push(i18n("isValid.InputItemRequired"));
  }

  return errors;
}
