import SvgIcon from "@mui/material/SvgIcon";

import { theme } from "SosTheme";

export function FulfillmentIcon(props) {
  const { highlighted, ...otherProps } = props;
  const color = highlighted ? theme.palette.accent : theme.palette.primaryText;

  return (
    <SvgIcon viewBox="0 0 32 32" fill={color} {...otherProps}>
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g fillRule="nonzero">
          <path d="M7.4666875,23.0493043 C5.701875,23.0493043 4.2666875,25.046087 4.2666875,27.5014783 C4.2666875,29.9568696 5.701875,31.9536522 7.4666875,31.9536522 C9.2315,31.9536522 10.6666875,29.9568696 10.6666875,27.5014783 C10.6666875,25.046087 9.2314375,23.0493043 7.4666875,23.0493043 Z M7.4666875,30.4695652 C6.290125,30.4695652 5.333375,29.1383478 5.333375,27.5014783 C5.333375,25.8645217 6.2901875,24.5333913 7.4666875,24.5333913 C8.6431875,24.5333913 9.6,25.8646087 9.6,27.5014783 C9.6,29.1383478 8.6431875,30.4695652 7.4666875,30.4695652 Z"></path>
          <path d="M25.6,23.0493043 C23.8351875,23.0493043 22.4,25.046087 22.4,27.5014783 C22.4,29.9568696 23.8351875,31.9536522 25.6,31.9536522 C27.3648125,31.9536522 28.8,29.9568696 28.8,27.5014783 C28.8,25.046087 27.3648125,23.0493043 25.6,23.0493043 Z M25.6,30.4695652 C24.4234375,30.4695652 23.4666875,29.1383478 23.4666875,27.5014783 C23.4666875,25.8645217 24.4235,24.5333913 25.6,24.5333913 C26.7765625,24.5333913 27.7333125,25.8646087 27.7333125,27.5014783 C27.7333125,29.1383478 26.7765625,30.4695652 25.6,30.4695652 Z"></path>
          <path d="M31.9151875,18.9377391 L27.1151875,8.54930435 C27.0170625,8.33704348 26.848,8.20869565 26.666625,8.20869565 L20.8,8.20869565 C20.5050625,8.20869565 20.2666875,8.54113043 20.2666875,8.95069565 L20.2666875,27.5013913 C20.2666875,27.9117391 20.5050625,28.2433913 20.8,28.2433913 L22.9333125,28.2433913 L22.9333125,26.7593043 L21.3333125,26.7593043 L21.3333125,9.69278261 L26.3754375,9.69278261 L30.9333125,19.5573043 L30.9333125,26.7594783 L28.266625,26.7594783 L28.266625,28.2434783 L31.466625,28.2434783 C31.7615625,28.2434783 31.9999375,27.9118261 31.9999375,27.5014783 L31.9999375,19.3391304 C32,19.1973913 31.9706875,19.0571304 31.9151875,18.9377391 Z"></path>
          <path d="M23.4666875,17.8550435 L23.4666875,12.6608696 L27.733375,12.6608696 L27.733375,11.1767826 L22.933375,11.1767826 C22.6384375,11.1767826 22.4000625,11.5092174 22.4000625,11.9187826 L22.4000625,18.5970435 C22.4000625,19.0073913 22.6384375,19.3390435 22.933375,19.3390435 L30.933375,19.3390435 L30.933375,17.8549565 L23.4666875,17.8549565 L23.4666875,17.8550435 Z"></path>
          <path d="M20.8,3.01452174 L0.5333125,3.01452174 C0.2389375,3.01452174 0,3.34695652 0,3.75652174 L0,27.5014783 C0,27.9118261 0.2389375,28.2434783 0.5333125,28.2434783 L4.8,28.2434783 L4.8,26.7593913 L1.0666875,26.7593913 L1.0666875,4.49852174 L20.2666875,4.49852174 L20.2666875,26.7593913 L10.1333125,26.7593913 L10.1333125,28.2434783 L20.8,28.2434783 C21.0949375,28.2434783 21.3333125,27.9118261 21.3333125,27.5014783 L21.3333125,3.75652174 C21.3333125,3.34695652 21.0949375,3.01452174 20.8,3.01452174 Z"></path>
          <rect
            x="0.5333125"
            y="23.7913043"
            width="3.2"
            height="1.48408696"
          ></rect>
          <rect
            x="11.2"
            y="23.7913043"
            width="9.0666875"
            height="1.48408696"
          ></rect>
          <rect
            x="29.3333125"
            y="23.7913043"
            width="2.1333125"
            height="1.48408696"
          ></rect>
          <rect
            x="2.1333125"
            y="5.9826087"
            width="18.6666875"
            height="1.48408696"
          ></rect>
          <rect
            x="6.9333125"
            y="26.7593913"
            width="1.0666875"
            height="1.48408696"
          ></rect>
          <rect
            x="25.0666875"
            y="26.7593913"
            width="1.0666875"
            height="1.48408696"
          ></rect>
          <rect
            x="2.1333125"
            y="0.0463478261"
            width="7.4666875"
            height="1.48408696"
          ></rect>
          <rect
            x="0"
            y="0.0463478261"
            width="1.0666875"
            height="1.48408696"
          ></rect>
        </g>
      </g>
    </SvgIcon>
  );
}
