import { i18n } from "services/i18nService";

import { initialErrorState } from "views/common";

export function isValid(record) {
  const errors = initialErrorState();

  if (!record.subject) {
    errors.messages.push(i18n("isValid.Subject"));
    errors.entity.push("subject");
  }

  if (!record.question) {
    errors.messages.push(i18n("isValid.Question"));
    errors.entity.push("question");
  }

  return errors;
}
