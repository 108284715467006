export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixProcess: record.processNumber.prefix,
    nextProcessNumber: record.processNumber.nextNumber,
  };

  delete newRecord.processNumber;

  return newRecord;
}
