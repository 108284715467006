import { theme } from "SosTheme";

export const tableStyles = {
  "& b": { fontWeight: theme.typography.fontWeightBold },
  "& a": { textDecoration: "none", color: theme.palette.primaryLink },
  "& table": { display: "inline", borderCollapse: "collapse" },
  "& th": {
    border: "1px solid black",
    textAlign: "left",
    padding: "5px 10px",
    verticalAlign: "bottom",
    lineHeight: "1",
  },
  "th.current-sort": {
    backgroundColor: theme.palette.tableHeaderFooter,
  },
  "th.tsj-numbers": {
    textAlign: "right",
  },
  "& td": {
    border: "1px solid black",
    padding: "0 10px",
    verticalAlign: "top",
    maxWidth: "30em",
    overflowWrap: "break-word",
  },
  "& tfoot": {
    "& tr": {
      backgroundColor: theme.palette.tableHeaderFooter,
      border: "1px solid black",
      fontWeight: "bold",
    },
    "& td": { borderLeft: "none", borderRight: "none", textAlign: "right" },
  },
  "& .align-right": { textAlign: "right" },
  "& .highlight": { backgroundColor: "highlightedReportArea" },
};
