import { Card, CardContent } from "@mui/material";

import { CardGrid } from "components/CardGrid";
import { FrmTextField } from "components/formFields/FrmTextField";
import { CardHeader } from "components/formFields/formCards/CardHeader";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

export function Contact(props) {
  const { onValueBlur, contact = {}, ...unexpected } = props;
  checkForUnexpectedProps("Contact", unexpected);

  const { title, firstName, middleName, lastName, suffix } = contact;

  function handleValueBlur(name, value) {
    return onValueBlur("contact", { ...contact, [name]: value });
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <CardHeader text={i18n("global.Contact")} />
        <CardGrid>
          <FrmTextField
            label={i18n("global.Title")}
            maxLength={16} // V8BACK: This maxlengh can be extended (21 characters) once v8 hits EOL
            onValueBlur={handleValueBlur}
            value={title}
            name="title"
          />
          <FrmTextField
            label={i18n("global.FirstName")}
            maxLength={100}
            onValueBlur={handleValueBlur}
            value={firstName}
            name="firstName"
          />
          <FrmTextField
            label={i18n("global.MiddleName")}
            maxLength={100}
            onValueBlur={handleValueBlur}
            value={middleName}
            name="middleName"
          />
          <FrmTextField
            label={i18n("global.LastName")}
            maxLength={100}
            onValueBlur={handleValueBlur}
            value={lastName}
            name="lastName"
          />
          <FrmTextField
            label={i18n("global.Suffix")}
            maxLength={16} // V8BACK: This maxlengh can be extended (21 characters) once v8 hits EOL
            onValueBlur={handleValueBlur}
            value={suffix}
            name="suffix"
          />
        </CardGrid>
      </CardContent>
    </Card>
  );
}
