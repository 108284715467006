import { Fragment } from "react";

export function RecordFields(props) {
  const { record, list } = props;

  return list.map(({ label, name, formatFunc, defaultValue }, i) => {
    const cellContent = record[name] ? record[name] : defaultValue;
    return (
      <Fragment key={i}>
        <div style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
          <div
            style={{
              minWidth: "20ch",
              fontWeight: "bold",
              textAlign: "right",
            }}
          >
            {label}:
          </div>
          <div data-testing={`${name}Field`} style={{ flexGrow: 1 }}>
            {formatFunc ? formatFunc(record[name]) : cellContent}
          </div>
        </div>
      </Fragment>
    );
  });
}
