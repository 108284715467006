import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ListIcon from "@mui/icons-material/List";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  IconButton,
} from "@mui/material";

import { TableCell } from "components/TableCell";
import { Tooltip } from "components/Tooltip";
import { LinkText } from "components/utility/LinkText";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";

import { openModal } from "globalState/modalSlice";

import { NO_REF_NUMBER_STRING } from "appConstants";
import { getObjectFromTypeString } from "appConstants";

export function SearchList(props) {
  const { records } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function openEditModal(objectType, id) {
    dispatch(openModal({ objectType, modalProps: { id } }));
  }

  if (!records) {
    return null;
  }

  return (
    <>
      <Typography variant="h3">{i18n("global.SearchResults")}:</Typography>
      <VSpace space={1} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "14rem", paddingLeft: "1rem" }}>
                {i18n("columns.Type")}
              </TableCell>
              <TableCell>{i18n("columns.ReferenceNumber")}</TableCell>
              <TableCell />
              <TableCell>{i18n("global.Summary")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records?.map((record, index) => {
              const { fullString } = getObjectFromTypeString(record.objectType);
              const objectValue = record.objectValue || NO_REF_NUMBER_STRING;
              return (
                <TableRow key={index}>
                  <TableCell sx={{ paddingLeft: "1rem" }}>
                    {i18n(`objectType.${fullString}.Sentence`)}
                  </TableCell>
                  <TableCell>
                    {record.objectId ? (
                      <LinkText
                        onClick={() => {
                          openEditModal(fullString, record.objectId);
                        }}
                      >
                        {objectValue}
                      </LinkText>
                    ) : (
                      <>{objectValue}</>
                    )}
                  </TableCell>
                  <TableCell>
                    {record.objectId && (
                      <Tooltip title={i18n("tooltip.ClickToGoToList")}>
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() =>
                            navigate(`/${fullString}?id=${record.objectId}`)
                          }
                        >
                          <ListIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>{record.description}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
