import SvgIcon from "@mui/material/SvgIcon";

import { theme } from "SosTheme";

export function DashboardIcon(props) {
  const { highlighted, ...otherProps } = props;
  const color = highlighted ? theme.palette.accent : theme.palette.primaryText;

  return (
    <SvgIcon viewBox="0 0 32 32" fill={color} {...otherProps}>
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g fillRule="nonzero">
          <path d="M14.2806394,0.710479574 C14.2806394,0.317939609 13.9626998,0 13.5701599,0 L0.710479574,0 C0.317939609,0 0,0.317939609 0,0.710479574 L0,13.5701599 C0,13.9626998 0.317939609,14.2806394 0.710479574,14.2806394 L13.5701599,14.2806394 C13.9626998,14.2806394 14.2806394,13.9626998 14.2806394,13.5701599 L14.2806394,0.710479574 Z M12.8596803,12.8596803 L1.42095915,12.8596803 L1.42095915,1.42095915 L12.8596803,1.42095915 L12.8596803,12.8596803 Z"></path>
          <path d="M31.9715808,0.710479574 C31.9715808,0.317939609 31.6536412,0 31.2611012,0 L18.401421,0 C18.008881,0 17.6909414,0.317939609 17.6909414,0.710479574 L17.6909414,13.5701599 C17.6909414,13.9626998 18.008881,14.2806394 18.401421,14.2806394 L31.2611012,14.2806394 C31.6536412,14.2806394 31.9715808,13.9626998 31.9715808,13.5701599 L31.9715808,0.710479574 Z M30.5506217,12.8596803 L19.1119005,12.8596803 L19.1119005,1.42095915 L30.5506217,1.42095915 L30.5506217,12.8596803 Z"></path>
          <path d="M14.2806394,18.401421 C14.2806394,18.008881 13.9626998,17.6909414 13.5701599,17.6909414 L0.710479574,17.6909414 C0.317939609,17.6909414 0,18.008881 0,18.401421 L0,31.2611012 C0,31.6536412 0.317939609,31.9715808 0.710479574,31.9715808 L13.5701599,31.9715808 C13.9626998,31.9715808 14.2806394,31.6536412 14.2806394,31.2611012 L14.2806394,18.401421 Z M12.8596803,30.4795737 L1.42095915,30.4795737 L1.42095915,19.1119005 L12.8596803,19.1119005 L12.8596803,30.4795737 Z"></path>
          <path d="M31.9715808,18.401421 C31.9715808,18.008881 31.6536412,17.6909414 31.2611012,17.6909414 L18.401421,17.6909414 C18.008881,17.6909414 17.6909414,18.008881 17.6909414,18.401421 L17.6909414,31.2611012 C17.6909414,31.6536412 18.008881,31.9715808 18.401421,31.9715808 L31.2611012,31.9715808 C31.6536412,31.9715808 31.9715808,31.6536412 31.9715808,31.2611012 L31.9715808,18.401421 Z M30.5506217,30.4795737 L19.1119005,30.4795737 L19.1119005,19.1119005 L30.5506217,19.1119005 L30.5506217,30.4795737 Z"></path>
        </g>
      </g>
    </SvgIcon>
  );
}
