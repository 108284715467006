import { useParams } from "react-router-dom";

import { Layout } from "Layout";

import { ListPage } from "components/ListPage/ListPage";

import { i18n } from "services/i18nService";

import { useEditModal } from "hooks/useEditModal";
import { usePrivileges } from "hooks/usePrivileges";

import { Forbidden } from "Forbidden";
import { USER_PRIVILEGE, OBJECT_TYPES } from "appConstants";

const objectType = OBJECT_TYPES.SHIPMENT.fullString;

export function ShipmentList() {
  const { id } = useParams();
  const { hasPrivilegesOrIsAdmin } = usePrivileges();

  useEditModal(objectType, id);

  const allowList = hasPrivilegesOrIsAdmin([
    USER_PRIVILEGE.viewShipments,
    USER_PRIVILEGE.editShipments,
  ]);

  return (
    <Layout pageTitle={i18n(`objectType.${objectType}.SentencePlural`)}>
      {allowList ? <ListPage objectType={objectType} /> : <Forbidden />}
    </Layout>
  );
}
