import {
  PhysicalInventoryWorksheet,
  MasterPurchaseReport,
  OpenPurchaseOrderDetail,
  PurchaseVariances,
  PurchasesByCategorySummary,
  PurchasesByClassSummary,
  PurchasesByCustomerSummary,
  PurchasesByDepartmentSummary,
  PurchasesByItemSummary,
  PurchasesByLocationSummary,
  PurchasesByVendorSummary,
  ReorderReport,
  VendorEfficiency,
  WhereToBuy,
  BinContents,
  InventoryAging,
  InventoryStockStatus,
  InventorySummary,
  InventoryTurnover,
  InventoryValuation,
  ItemAnalysis,
  ItemMovement,
  ItemsInBins,
  LotHistory,
  LotStockStatus,
  LotTraceability,
  LotsInBins,
  OverstockUnderstockReport,
  SerialHistory,
  SerialsInBins,
  CommissionReport,
  MasterSalesReport,
  OpenSalesOrderDetail,
  OpenSalesReceiptDetail,
  PriceBook,
  ProfitLossByCustomer,
  ProfitLossByItem,
  ProfitLossByJob,
  ReadyToInvoice,
  SalesByCategorySummary,
  SalesByChannelSummary,
  SalesByClassSummary,
  SalesByDepartmentSummary,
  SalesByCustomerSummary,
  SalesByCustomerTypeSummary,
  SalesByItemSummary,
  SalesByLocationSummary,
  SalesByVendorSummary,
  SellThroughReport,
  BackorderReport,
  FulfillmentEfficiency,
  ReadyToPickShip,
  ShippingReport,
  ExpandedBOM,
  OpenWorkOrderDetail,
  ProductionReport,
  WhereUsed,
  WorkInProgress,
  ReconcileItemReceipts,
  ReconcileJournalEntries,
  ReconcileVendorCredits,
  OpenRentalDetail,
  RentalCalendar,
  DeletedItems,
  TransactionsAssigned,
} from "views/Reports/reportDefinitions/index";

export const REPORTS = {
  PhysicalInventoryWorksheet,
  MasterPurchaseReport,
  OpenPurchaseOrderDetail,
  PurchaseVariances,
  PurchasesByCategorySummary,
  PurchasesByClassSummary,
  PurchasesByCustomerSummary,
  PurchasesByDepartmentSummary,
  PurchasesByItemSummary,
  PurchasesByLocationSummary,
  PurchasesByVendorSummary,
  ReorderReport,
  VendorEfficiency,
  WhereToBuy,
  BinContents,
  InventoryAging,
  InventoryStockStatus,
  InventorySummary,
  InventoryTurnover,
  InventoryValuation,
  ItemAnalysis,
  ItemMovement,
  ItemsInBins,
  LotHistory,
  LotStockStatus,
  LotTraceability,
  LotsInBins,
  OverstockUnderstockReport,
  SerialHistory,
  SerialsInBins,
  CommissionReport,
  MasterSalesReport,
  OpenSalesOrderDetail,
  OpenSalesReceiptDetail,
  PriceBook,
  ProfitLossByCustomer,
  ProfitLossByItem,
  ProfitLossByJob,
  ReadyToInvoice,
  SalesByCategorySummary,
  SalesByChannelSummary,
  SalesByClassSummary,
  SalesByDepartmentSummary,
  SalesByCustomerSummary,
  SalesByCustomerTypeSummary,
  SalesByItemSummary,
  SalesByLocationSummary,
  SalesByVendorSummary,
  SellThroughReport,
  BackorderReport,
  FulfillmentEfficiency,
  ReadyToPickShip,
  ShippingReport,
  ExpandedBOM,
  OpenWorkOrderDetail,
  ProductionReport,
  WhereUsed,
  WorkInProgress,
  ReconcileItemReceipts,
  ReconcileJournalEntries,
  ReconcileVendorCredits,
  OpenRentalDetail,
  RentalCalendar,
  DeletedItems,
  TransactionsAssigned,
};

export const REPORTS_BY_CATEGORY = [
  {
    categoryCode: "Inventory",
    planIncludesCategory: () => true,
    reports: [
      "BinContents",
      "InventoryAging",
      "InventoryStockStatus",
      "InventorySummary",
      "InventoryTurnover",
      "InventoryValuation",
      "ItemAnalysis",
      "ItemMovement",
      "ItemsInBins",
      "LotHistory",
      "LotStockStatus",
      "LotTraceability",
      "LotsInBins",
      "OverstockUnderstockReport",
      "PhysicalInventoryWorksheet",
      "SerialHistory",
      "SerialsInBins",
    ],
  },
  {
    categoryCode: "Purchasing",
    planIncludesCategory: () => true,
    reports: [
      "MasterPurchaseReport",
      "OpenPurchaseOrderDetail",
      "PurchaseVariances",
      "PurchasesByCategorySummary",
      "PurchasesByClassSummary",
      "PurchasesByCustomerSummary",
      "PurchasesByDepartmentSummary",
      "PurchasesByItemSummary",
      "PurchasesByLocationSummary",
      "PurchasesByVendorSummary",
      "ReorderReport",
      "VendorEfficiency",
      "WhereToBuy",
    ],
  },
  {
    categoryCode: "Sales",
    planIncludesCategory: () => true,
    reports: [
      "CommissionReport",
      "MasterSalesReport",
      "OpenSalesOrderDetail",
      "OpenSalesReceiptDetail",
      "PriceBook",
      "ProfitLossByCustomer",
      "ProfitLossByItem",
      "ProfitLossByJob",
      "ReadyToInvoice",
      "SalesByCategorySummary",
      "SalesByChannelSummary",
      "SalesByClassSummary",
      "SalesByCustomerSummary",
      "SalesByCustomerTypeSummary",
      "SalesByDepartmentSummary",
      "SalesByItemSummary",
      "SalesByLocationSummary",
      "SalesByVendorSummary",
      "SellThroughReport",
    ],
  },
  {
    categoryCode: "Fulfillment",
    planIncludesCategory: () => true,
    reports: [
      "BackorderReport",
      "FulfillmentEfficiency",
      "ReadyToPickShip",
      "ShippingReport",
    ],
  },
  {
    categoryCode: "Production",
    planIncludesCategory: () => true,
    reports: [
      "ExpandedBOM",
      "OpenWorkOrderDetail",
      "ProductionReport",
      "WhereUsed",
      "WorkInProgress",
    ],
  },
  {
    categoryCode: "Rentals",
    planIncludesCategory: (plus, pro) => plus || pro,
    reports: ["OpenRentalDetail", "RentalCalendar"],
  },
  {
    categoryCode: "Accounting",
    planIncludesCategory: () => true,
    reports: [
      "ReconcileItemReceipts",
      "ReconcileJournalEntries",
      "ReconcileVendorCredits",
    ],
  },
  {
    categoryCode: "Miscellaneous",
    planIncludesCategory: () => true,
    reports: ["DeletedItems", "TransactionsAssigned"],
  },
];
