import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmAssignedTo } from "components/formFields/frm/FrmAssignedTo";

import { i18n } from "services/i18nService";
import {
  NOTIFY_USER_OPTION,
  SEND_EMAIL_OPTION,
  SEND_TEXT_OPTION,
  ALERT_METHOD_OPTIONS,
  MOBILE_CARRIER_OPTIONS,
} from "services/sosInventoryService/alert/schema";

export function AlertMethod(props) {
  const {
    method,
    email,
    user,
    disabled,
    mobileNumber,
    mobileCarrier,
    onChange,
    onScalarChange,
    isInError,
  } = props;

  return (
    <>
      <FrmSelectFromObjects
        name="method"
        label={i18n("frmLabel.AlertMethod")}
        value={method ? { id: method } : null}
        onValueChange={onScalarChange}
        options={ALERT_METHOD_OPTIONS}
        disabled={disabled}
        error={isInError("method")}
        disableClearable
      />
      {method === NOTIFY_USER_OPTION.id && (
        <FrmAssignedTo
          name="user"
          label={i18n("frmLabel.User")}
          value={user}
          onValueChange={onChange}
          disabled={disabled}
          disableClearable
        />
      )}
      {method === SEND_EMAIL_OPTION.id && (
        <FrmTextField
          name="email"
          label={i18n("frmLabel.Email")}
          onValueBlur={onChange}
          value={email}
          disabled={disabled}
          error={isInError("email")}
        />
      )}
      {method === SEND_TEXT_OPTION.id && (
        <>
          <FrmSelectFromObjects
            name="mobileCarrier"
            label={i18n("frmLabel.MobileCarrier")}
            value={mobileCarrier ? { id: mobileCarrier } : null}
            onValueChange={onScalarChange}
            options={MOBILE_CARRIER_OPTIONS}
            disabled={disabled}
            error={isInError("mobileCarrier")}
            disableClearable
          />
          <FrmTextField
            name="mobileNumber"
            label={i18n("frmLabel.Number")}
            onValueBlur={onChange}
            value={mobileNumber}
            disabled={disabled}
            error={isInError("mobileNumber")}
          />
        </>
      )}
    </>
  );
}
