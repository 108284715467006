import { useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";

import { FrmButtonGroup } from "components/formFields/FrmButtonGroup";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmImageSize } from "components/formFields/frm/FrmImageSize";
import { GroupingBox } from "components/utility/GroupingBox";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { getBarcodeSample } from "services/sosInventoryService/sosApi";
import { imageBlobToBase64 } from "services/utility/images";

const formatOptions = [
  { id: "Code39", name: "Code 39" },
  { id: "ISBN", name: "ISBN" },
  { id: "QR", name: "QR code" },
  { id: "UPC", name: "UPC" },
];

export function FrmBarcodeSettings(props) {
  const { name, value, onValueChange } = props;
  const { format, width, height } = value;

  const [barcodeText, setBarcodeText] = useState("0123456789");
  const [barcodeImage, setBarcodeImage] = useState();

  useEffect(() => {
    if (format && width && height) {
      async function getImage() {
        // make the maximum with and height be 3.125; if bigger dimensions are
        // entered, scale it down, so that the image fits in the content area

        // get the longest dimension
        const longestDimension = Math.max(width, height);
        const scalingFactor = 3.125 / longestDimension;
        const sampleWidth = width * scalingFactor;
        const sampleHeight = height * scalingFactor;

        const barcodeImageBlob = await getBarcodeSample(
          barcodeText,
          format,
          sampleWidth,
          sampleHeight
        );
        imageBlobToBase64(barcodeImageBlob, setBarcodeImage);
      }
      getImage();
    } else {
      setBarcodeImage(undefined);
    }
  }, [format, width, height, barcodeText]);

  if (!value) {
    return null;
  }

  function handleFormatChange(_, newValue) {
    onValueChange("settings", {
      ...value,
      format: newValue.id,
    });
  }

  function handleDimensionsChange(_, newValue) {
    const { width, height } = newValue;
    onValueChange(name, {
      ...value,
      width,
      height,
    });
  }

  return (
    <>
      <FrmButtonGroup
        name={name}
        label={i18n("global.Format")}
        value={{ id: format }}
        options={formatOptions}
        onValueChange={handleFormatChange}
      />
      <VSpace space={3} />
      <FrmImageSize
        value={{ width, height }}
        onValueChange={handleDimensionsChange}
        includeLabels
      />
      <GroupingBox heading={i18n("global.Sample")}>
        <FrmTextField
          name="barcodeText"
          label={i18n("global.Text")}
          value={barcodeText}
          sx={{ mb: 1 }}
          onValueChange={(_, newValue) => setBarcodeText(newValue)}
        />
        <Box sx={{ textAlign: "center" }}>
          {barcodeImage && (
            <>
              <Typography variant="tag" component="div">
                {i18n("settings.inventory.barcode.settings.sampleScaledToFit")}
              </Typography>
              <Box mt={1}>
                <img
                  src={"data:image;base64, " + barcodeImage}
                  alt="barcode"
                  style={{
                    width: "auto",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </GroupingBox>
    </>
  );
}
