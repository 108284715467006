import {
  getTransctionPdfs,
  getPrintLabelPdfs,
} from "services/sosInventoryService/sosApi";

export async function generateAndDownloadPdfs(
  objectType,
  objectIds,
  templateId,
  downloadText
) {
  const pdf = await getTransctionPdfs(objectType, objectIds, templateId);
  createPdfElement(pdf, downloadText);
}

export async function generateAndDownloadPrintLabel(
  ids,
  templateId,
  downloadText
) {
  const pdf = await getPrintLabelPdfs(ids, templateId);
  createPdfElement(pdf, downloadText);
}

function createPdfElement(pdf, downloadText) {
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);
  a.href = window.URL.createObjectURL(pdf);
  a.setAttribute("download", downloadText);
  a.click();
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}
