import { FilterSelectField } from "components/formFields/FilterSelectField";

import { useRelated } from "hooks/useRelated";

export function ChannelFilter(props) {
  const { value, onValueChange, name, label } = props;

  const channels = useRelated("channel");

  return (
    <FilterSelectField
      name={name}
      label={label}
      options={channels}
      value={value}
      onValueChange={onValueChange}
    />
  );
}
