import { useEffect, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, TextField } from "@mui/material";

import { Tooltip } from "components/Tooltip";
import { LineSelectPopper } from "components/formFields/LineSelectPopper";
import {
  textFieldStyle,
  inputPropStyles,
  outlinedStyles,
} from "components/formFields/lineItemFieldStyle";

import { checkForUnexpectedProps } from "services/utility/misc";

export function LineItemAutocompleteField(props) {
  const {
    name,
    options: initialOptions,
    optionId = "id",
    optionDisplayText = "name",
    onValueChange,
    disableClearable,
    disabled,
    value,
    label,
    error,
    allowTooltip,
    dataTesting,
    ...unexpected
  } = props;
  checkForUnexpectedProps("LineItemAutocompleteField", unexpected);

  const [options, setOptions] = useState(initialOptions);

  useEffect(() => {
    setOptions(
      initialOptions
        ? initialOptions.sort((a, b) => (a.name < b.name ? -1 : 1))
        : initialOptions
    );
  }, [initialOptions]);

  // this gets called whenever the user changes the selected option
  function onChange(_, newValue, reason) {
    if (reason === "clear") {
      onValueChange(name, null);
      return;
    }

    onValueChange(name, newValue);
  }

  // this gets called when the component needs to know what to display
  // in the input field; not the value, which is the item id, but the
  // human-friendly *name* of the item
  function getOptionLabel(option) {
    // option or option.id can be blank on a newly inserted line;
    // and if we're adding a new item we don't want any text in the
    // component
    if (option[optionDisplayText]) {
      return option[optionDisplayText];
    }
    if (!options) {
      return "";
    }
    const matchInOptions = options.find(({ id }) => id === option[optionId]);
    return matchInOptions ? matchInOptions[optionDisplayText] : "";
  }

  // this gets called for each option, when the selected option changes, to
  // determine which, of all the options, was selected
  function isOptionEqualToValue(option, value) {
    if (!option || !value) {
      return false;
    }
    return (
      value[optionId] === option[optionId] ||
      (value === "" && option[optionId] === "")
    );
  }

  function renderOption(props, option) {
    return (
      <li {...props} data-testing="selectOption" key={option[optionId]}>
        <div style={{ minHeight: "1.5em" }}>{option[optionDisplayText]}</div>
      </li>
    );
  }

  function renderInput(params) {
    const textfield = (
      <TextField
        {...params}
        error={error}
        variant="outlined"
        label={label}
        margin="dense"
        fullWidth
        sx={error ? textFieldStyle : { ...textFieldStyle, ...outlinedStyles }}
        InputProps={{ ...params.InputProps, sx: inputPropStyles }}
        InputLabelProps={{ shrink: true }}
        SelectProps={{ MenuProps: { disableScrollLock: true } }}
      />
    );
    if (allowTooltip) {
      const title = value ? value[optionDisplayText] : "";
      return (
        <Tooltip title={title} placement="bottom-start">
          {textfield}
        </Tooltip>
      );
    }
    return textfield;
  }

  return (
    <Autocomplete
      fullWidth
      name={name}
      disabled={disabled}
      value={value}
      disableClearable={disableClearable}
      loading={!Array.isArray(options)}
      options={options ? options : []}
      onChange={onChange}
      PopperComponent={LineSelectPopper}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={renderOption}
      popupIcon={<KeyboardArrowDownIcon sx={{ color: "selectIcon" }} />}
      renderInput={renderInput}
      data-testing={dataTesting}
    />
  );
}
