import { useState, useEffect } from "react";

import { ActionButton } from "components/ActionButton";
import { FrmTextField } from "components/formFields/FrmTextField";

import { i18n } from "services/i18nService";
import { generateRandomString } from "services/utility/misc";

export function FrmPortalPassword(props) {
  const { value, setRecord } = props;
  const [password, setPassword] = useState(value);

  function generatePassword() {
    const password = generateRandomString(8);
    setPassword(password);
    setRecord((prevRecord) => ({
      ...prevRecord,
      portalPassword: password,
    }));
  }
  function handleInputFieldChange(name, value) {
    setRecord((prevRecord) => ({ ...prevRecord, [name]: value }));
  }

  useEffect(() => setPassword(value), [value]);

  return (
    <div style={{ display: "flex", gap: "0.5rem" }}>
      <div style={{ flex: "1" }}>
        <FrmTextField
          name="portalPassword"
          label={i18n("customer.PortalPassword")}
          value={password}
          maxLength={21}
          onValueBlur={handleInputFieldChange}
        />
      </div>
      <div style={{ flex: "2" }}>
        <ActionButton
          headerButton
          size="medium"
          onClick={generatePassword}
          sx={{ mt: "0.5rem" }}
        >
          {i18n("customer.GenerateNewPassword")}
        </ActionButton>
      </div>
    </div>
  );
}
