import { i18n } from "services/i18nService";
import {
  formatNameLinkField,
  formatDocumentLinkedTransctions,
  formatDateTimeToDate,
} from "services/utility/formatting";

import { OBJECT_TYPES } from "appConstants";

export const columnInfo = [
  {
    name: "fileName",
    heading: i18n("columns.Name"),
    formatFunc: formatNameLinkField,
    fixed: true,
    linkField: true,
    defaultSort: true,
    sortable: true,
  },
  {
    name: "description",
    heading: i18n("columns.Description"),
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },

  {
    name: "uploadedDate",
    heading: i18n("columns.UploadedDate"),
    formatFunc: formatDateTimeToDate,
    fixed: true,
    sortable: true,
    fixedRightOfAction: true,
  },
  {
    name: "uploadedBy",
    heading: i18n("columns.UploadedBy"),
    fixed: true,
    sortable: true,
    fixedRightOfAction: true,
  },
  {
    name: "lines",
    heading: i18n("columns.LinkedTo"),
    formatFunc: formatDocumentLinkedTransctions,
    minWidth: "15rem",
    fixed: true,
    fixedRightOfAction: true,
  },
];

export const EMPTY_LINE = {
  type: null,
  name: null,
  date: null,
  refNumber: null,
  id: null,
  memo: null,
};

export const LINE_ACTIONS = ["download", "delete"];
export const BATCH_ACTIONS = ["delete"];

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["uploadedDate"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const LINE_FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["date"],
  NON_UTC_DATETIME_FIELDS: [],
};

export function getEmptyRecord({ numLinesToAdd }) {
  const lines = new Array(numLinesToAdd).fill(EMPTY_LINE);
  return { lines, description: null, canShare: true, content: null };
}

const ATTACHABLE_OBJECT_TYPES_ARRAY = [
  OBJECT_TYPES.ACCOUNT,
  OBJECT_TYPES.ADJUSTMENT,
  OBJECT_TYPES.BILL,
  OBJECT_TYPES.BILL_PAYMENT,
  OBJECT_TYPES.BUILD,
  OBJECT_TYPES.CLASS,
  OBJECT_TYPES.CREDIT_MEMO,
  OBJECT_TYPES.CUSTOMER,
  OBJECT_TYPES.EMPLOYEE,
  OBJECT_TYPES.ESTIMATE,
  OBJECT_TYPES.INVOICE,
  OBJECT_TYPES.ITEM,
  OBJECT_TYPES.ITEM_RECEIPT,
  OBJECT_TYPES.JOURNAL_ENTRY,
  OBJECT_TYPES.JOB,
  OBJECT_TYPES.LOT,
  OBJECT_TYPES.PAYMENT,
  OBJECT_TYPES.PICK_TICKET,
  OBJECT_TYPES.PROCESS,
  OBJECT_TYPES.PURCHASE,
  OBJECT_TYPES.PURCHASE_ORDER,
  OBJECT_TYPES.RENTAL,
  OBJECT_TYPES.RENTAL_RETURN,
  OBJECT_TYPES.RETURN,
  OBJECT_TYPES.RETURN_TO_VENDOR,
  OBJECT_TYPES.RMA,
  OBJECT_TYPES.SALES_ORDER,
  OBJECT_TYPES.SALES_RECEIPT,
  OBJECT_TYPES.SERIAL,
  OBJECT_TYPES.SHIPMENT,
  OBJECT_TYPES.TRANSFER,
  OBJECT_TYPES.VENDOR,
  OBJECT_TYPES.WORK_ORDER,
];

export const ATTACHABLE_OBJECT_TYPES = ATTACHABLE_OBJECT_TYPES_ARRAY.map(
  ({ fullString, typeString }) => ({
    id: typeString,
    name: i18n(`objectType.${fullString}.Sentence`),
  })
);
