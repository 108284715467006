import { i18n } from "services/i18nService";

import {
  SUB_COMPONENT,
  ALL_OPTION_WITH_ALL_ID,
} from "views/Reports/reportConstants";

import { OBJECT_TYPES } from "appConstants";

const TRANSACTION_TYPE_OPTIONS = [
  ALL_OPTION_WITH_ALL_ID,
  { id: "Pick Ticket", name: i18n("objectType.pickticket.Sentence") },
  { id: "Purchase Order", name: i18n("objectType.purchaseorder.Sentence") },
  { id: "Sales Order", name: i18n("objectType.salesorder.Sentence") },
  { id: "Sales Receipt", name: i18n("objectType.salesreceipt.Sentence") },
  { id: "Shipment", name: i18n("objectType.shipment.Sentence") },
  { id: "Work Order", name: i18n("objectType.workorder.Sentence") },
];

export const TransactionsAssigned = {
  userHasSufficientPrivilege: (p) => true,
  planIncludesReport: () => true,
  customFieldObjectTypes: [],
  customReportNameFetchList: [
    { objectType: OBJECT_TYPES.USER.fullString, customReportKey: "users" },
  ],
  requiredFiltersNotSetMsg: i18n("reports.AssignedToFilterRequired"),
  columns: [
    { apiName: "Txn Date", default: true },
    { apiName: "Txn Type", default: true },
    { apiName: "RefNumber", label: "Ref #", default: true },
    { apiName: "Priority", default: true },
    { apiName: "Assigned To", default: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("global.FromDate"),
      datePrefix: "startOf",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date1Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("global.ToDate"),
      datePrefix: "endOf",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.AssignedTo"),
      component: "AssignedToFilter",
      apiName: "users",
      defaultValue: null,
      required: true,
      type: "filter",
    },
    {
      apiName: "txnType",
      defaultValue: ALL_OPTION_WITH_ALL_ID,
      label: i18n("reports.FilterLabel.TransactionType"),
      component: "FrmSelectFromObjects",
      options: TRANSACTION_TYPE_OPTIONS,
      type: "filter",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
