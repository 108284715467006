export function afterGet(record) {
  const newRecord = {
    ...record,
    rmaNumber: {
      prefix: record.prefixRMA,
      nextNumber: record.nextRMANumber,
    },
  };

  delete newRecord.prefixRMA;
  delete newRecord.nextRMANumber;

  return newRecord;
}
