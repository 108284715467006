import { Link } from "react-router-dom";

import { NavMenuItem } from "components/TopNav/NavMenuItem";
import { A } from "components/html/A";

import { checkForUnexpectedProps } from "services/utility/misc";

export function MenuItemLink(props) {
  const {
    to,
    target,
    onClick,
    disabled,
    children,
    tabIndex, // appears to be injected by MUI
    ...unexpected
  } = props;
  checkForUnexpectedProps("MenuItemLink", unexpected);

  const isToMenuItem = /^https?:\/\//.test(to);

  return (
    <NavMenuItem
      to={to}
      component={isToMenuItem ? A : Link}
      target={isToMenuItem ? target : undefined}
      tabIndex={tabIndex}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </NavMenuItem>
  );
}
