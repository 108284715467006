import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";

export function MoreTextDialog(props) {
  const { open, onClose, content, heading } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      sx={{ inset: "0 0 30%" }}
    >
      <DialogTitle>{heading}</DialogTitle>
      <DialogContent>
        <Box sx={{ whiteSpace: "pre-wrap" }}>{content}</Box>
      </DialogContent>
      <VSpace space={2} />
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {i18n("button.Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
