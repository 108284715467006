import { i18n } from "services/i18nService";

import { initialErrorState } from "views/common";

function hasValue(input) {
  return input && input.replace(/\s/g, "").length;
}

export function isValid(addresses) {
  const errors = initialErrorState();

  addresses.forEach((_, index) => {
    errors.line[index] = [];
  });

  addresses.forEach((address, index) => {
    // address does not have a name
    if (!hasValue(address.addressInfo.addressName)) {
      errors.messages.push(
        i18n("isValid.address.NameFieldRequired", {
          lineNumber: index + 1,
        })
      );
      errors.line[index].push("addressName");
    }
  });

  return errors;
}
