import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { NEW_RECORD_SKELETON } from "appConfig";

import { i18n } from "services/i18nService";
import { getRecord } from "services/sosInventoryService/sosApi";
import { reconcileCustomFields } from "services/utility/customFields";
import { setPageDirty } from "services/utility/edit";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

export function useVendor(
  id,
  setRecord,
  customFieldDefs,
  setErrors,
  presetName,
  settings
) {
  const { homeCurrencyRef, defaultTerms } = settings;

  const getEmptyRecord = NEW_RECORD_SKELETON["vendor"];

  const dispatch = useDispatch();

  useEffect(() => {
    if (!customFieldDefs) {
      return;
    }
    if (id) {
      async function getVic(id) {
        dispatch(editModalLoadingIndicatorOn());
        try {
          let record = await getRecord("vendor", id);
          record.customFields = reconcileCustomFields(
            customFieldDefs,
            record.customFields
          );

          setRecord(record);
          dispatch(editModalLoadingIndicatorOff());
        } catch (error) {
          return setLoadError(error);
        }
      }
      getVic(id);
    } else {
      const emptyRecord = getEmptyRecord({ homeCurrencyRef, defaultTerms });
      setRecord((prevRecord) => ({
        ...prevRecord,
        ...emptyRecord,
        name: presetName || emptyRecord.name,
        customFields: reconcileCustomFields(customFieldDefs, []),
      }));
    }

    setPageDirty(false);

    function setLoadError(error) {
      setErrors((prev) => ({
        ...prev,
        entity: [...prev.entity, "record"],
        messages: [error.message || i18n("error.RecordLoadError")],
      }));
      return dispatch(editModalLoadingIndicatorOff());
    }
  }, [
    getEmptyRecord,
    dispatch,
    id,
    setRecord,
    customFieldDefs,
    presetName,
    setErrors,
    defaultTerms,
    homeCurrencyRef,
  ]);
}
