import globalState from "globalState/globalState";

import { format, parse, parseISO, addMinutes } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

/**
 * @name    formatDate
 *
 * @summary get a localized date string from a Date value
 *
 * @param   date (Date)
 *
 * @returns a localized string with just the date portion of the input
 *          value, and which takes into account the user's date setting
 */
export function formatDate(date) {
  if (!date) {
    return "";
  }

  if (!(date instanceof Date)) {
    throw new Error(`Invalid date value: ${date}`);
  }
  const { dateFormat } =
    globalState.getState().userCompanySettings.localization;
  return format(date, dateFormat);
}

export function removeDateSeconds(date) {
  date.setSeconds("00");
  return date;
}

export function formatZonelessDateTime(date) {
  const { dateFormat } =
    globalState.getState().userCompanySettings.localization;
  if (!(date instanceof Date)) {
    throw new Error(`Invalid date value: ${date}`);
  }
  return format(date, dateFormat + " h:mm:ssaaa");
}

// input is ISO date/time format string, which is what SOS Inventory uses
// internally, with no time zone indicator, and assumed to be in UTC time
// (offset zero); returns a Date object in the user's local time
export function isoToLocalDateTime(isoString) {
  // append the "Z" (for UTC) to make it valid ISO 8601
  return isoString ? parseISO(isoString + "Z") : null;
}

// input is ISO date/time format string, which is what SOS Inventory uses
// internally, with no time zone indicator; in this case (as contrasted
// with isoToLocalDateTime, above)
export function isoToDateTime(isoString) {
  return new Date(isoString);
}

export function dateToSosISODate(dateObject) {
  // returns just the date, in format 2024-01-05
  return dateObject.toISOString().slice(0, -14);
}

export function dateToSosISODateTime(dateObject) {
  // the slice removes the "Z" time zone indicator (which our back end
  // assumes) and the decimal places on the seconds, which we don't care
  // about, and returns format 2024-01-05T05:15:47
  return dateObject.toISOString().slice(0, -5);
}

export function dateToLocalSosISO(dateObject) {
  // returns ISO-style string, but without timezone conversion
  return format(dateObject, "yyyy-MM-dd'T'HH':'mm':'ssx").slice(0, 19);
}

export function yyyymmddToDate(dateString) {
  const string = dateString.includes("T") // strip out time values
    ? dateString.split("T")[0]
    : dateString;
  return parse(string, "yyyy-MM-dd", new Date());
}

export function datefromDateFormat(dateString, dateFormat) {
  return parse(dateString, dateFormat, new Date());
}

// userTimeZone should be as formatted in SOS user settings (e.g., "UTC-05:00");
// returns the new time-zone-aware date
export function timeZoneAwareDateTime(date, userTimeZone) {
  if (userTimeZone === "device") {
    return date;
  } else {
    const timeZoneOffset = userTimeZone.substr(3, 6);
    return utcToZonedTime(date, timeZoneOffset);
  }
}

// this reverses the calculation done in timeZoneAwareDateTime,
// to get back to a UTC date/time
export function utcDateTime(timeZoneAwareDateTime, userTimeZone) {
  if (userTimeZone === "device") {
    return timeZoneAwareDateTime;
  } else {
    const timeZoneOffset = userTimeZone.substr(3, 6);
    return zonedTimeToUtc(timeZoneAwareDateTime, timeZoneOffset);
  }
}

// for system use only; this is not localized, so don't use it in the UI
export function calendarFormat(date) {
  return format(date, "yyyy-MM-dd");
}

/**
 * @name    getTimeForUtcOffset
 * @summary takes the user's current date time, and a certain UTC offset, and returns
 *          a time string in the "h:mm aaa" format
 *
 * @param   currentTime (Date) - the user's current date time
 *
 * @param   offset (string) - the UTC offset (i.e. "UTC-12:00")
 *
 * @returns     (string) - string in the "h:mm aaa" format (i.e. 11:41 AM )
 */
function getTimeForUtcOffset(currentTime, offset) {
  // get the local timezone offset in minutes
  const localOffset = currentTime.getTimezoneOffset();

  // convert the offset to minutes and adjust by the local offset
  const totalMinutes = offset * 60 + localOffset;

  // calculate the local time for the given offset
  const localTime = addMinutes(currentTime, totalMinutes);

  // Format the local time to a readable string
  return format(localTime, "h:mm aaa").toUpperCase();
}

export function getTimeZoneOptions() {
  const currentTime = new Date();
  return [
    {
      id: "UTC-12:00",
      name: `${getTimeForUtcOffset(currentTime, -12)} (UTC-12:00)`,
    },
    {
      id: "UTC-11:00",
      name: `${getTimeForUtcOffset(currentTime, -11)} (UTC-11:00)`,
    },
    {
      id: "UTC-10:00",
      name: `${getTimeForUtcOffset(currentTime, -10)} (UTC-10:00)`,
    },
    {
      id: "UTC-09:30",
      name: `${getTimeForUtcOffset(currentTime, -9.5)} (UTC-9:30)`,
    },
    {
      id: "UTC-09:00",
      name: `${getTimeForUtcOffset(currentTime, -9)} (UTC-9:00)`,
    },
    {
      id: "UTC-08:00",
      name: `${getTimeForUtcOffset(currentTime, -8)} (UTC-8:00)`,
    },
    {
      id: "UTC-07:00",
      name: `${getTimeForUtcOffset(currentTime, -7)} (UTC-7:00)`,
    },
    {
      id: "UTC-06:00",
      name: `${getTimeForUtcOffset(currentTime, -6)} (UTC-6:00)`,
    },
    {
      id: "UTC-05:00",
      name: `${getTimeForUtcOffset(currentTime, -5)} (UTC-5:00)`,
    },
    {
      id: "UTC-04:30",
      name: `${getTimeForUtcOffset(currentTime, -4.5)} (UTC-4:30)`,
    },
    {
      id: "UTC-04:00",
      name: `${getTimeForUtcOffset(currentTime, -4)} (UTC-4:00)`,
    },
    {
      id: "UTC-03:30",
      name: `${getTimeForUtcOffset(currentTime, -3.5)} (UTC-3:30)`,
    },
    {
      id: "UTC-03:00",
      name: `${getTimeForUtcOffset(currentTime, -3)} (UTC-3:00)`,
    },
    {
      id: "UTC-02:00",
      name: `${getTimeForUtcOffset(currentTime, -2)} (UTC-2:00)`,
    },
    {
      id: "UTC-01:00",
      name: `${getTimeForUtcOffset(currentTime, -1)} (UTC-1:00)`,
    },
    {
      id: "UTC-00:00",
      name: `${getTimeForUtcOffset(currentTime, +0)} (UTC-0:00)`,
    },
    {
      id: "UTC+01:00",
      name: `${getTimeForUtcOffset(currentTime, +1)} (UTC+1:00)`,
    },
    {
      id: "UTC+02:00",
      name: `${getTimeForUtcOffset(currentTime, +2)} (UTC+2:00)`,
    },
    {
      id: "UTC+03:00",
      name: `${getTimeForUtcOffset(currentTime, +3)} (UTC+3:00)`,
    },
    {
      id: "UTC+03:30",
      name: `${getTimeForUtcOffset(currentTime, +3.5)} (UTC+3:30)`,
    },
    {
      id: "UTC+04:00",
      name: `${getTimeForUtcOffset(currentTime, +4)} (UTC+4:00)`,
    },
    {
      id: "UTC+04:30",
      name: `${getTimeForUtcOffset(currentTime, +4.5)} (UTC+4:30)`,
    },
    {
      id: "UTC+05:00",
      name: `${getTimeForUtcOffset(currentTime, +5)} (UTC+5:00)`,
    },
    {
      id: "UTC+05:30",
      name: `${getTimeForUtcOffset(currentTime, +5.5)} (UTC+5:30)`,
    },
    {
      id: "UTC+05:45",
      name: `${getTimeForUtcOffset(currentTime, +5.75)} (UTC+5:45)`,
    },
    {
      id: "UTC+06:00",
      name: `${getTimeForUtcOffset(currentTime, +6)} (UTC+6:00)`,
    },
    {
      id: "UTC+06:30",
      name: `${getTimeForUtcOffset(currentTime, +6.5)} (UTC+6:30)`,
    },
    {
      id: "UTC+07:00",
      name: `${getTimeForUtcOffset(currentTime, +7)} (UTC+7:00)`,
    },
    {
      id: "UTC+08:00",
      name: `${getTimeForUtcOffset(currentTime, +8)} (UTC+8:00)`,
    },
    {
      id: "UTC+08:45",
      name: `${getTimeForUtcOffset(currentTime, +8.75)} (UTC+8:45)`,
    },
    {
      id: "UTC+09:00",
      name: `${getTimeForUtcOffset(currentTime, +9)} (UTC+9:00)`,
    },
    {
      id: "UTC+09:30",
      name: `${getTimeForUtcOffset(currentTime, +9.5)} (UTC+9:30)`,
    },
    {
      id: "UTC+10:00",
      name: `${getTimeForUtcOffset(currentTime, +10)} (UTC+10:00)`,
    },
    {
      id: "UTC+10:30",
      name: `${getTimeForUtcOffset(currentTime, +10.5)} (UTC+10:30)`,
    },
    {
      id: "UTC+11:00",
      name: `${getTimeForUtcOffset(currentTime, +11)} (UTC+11:00)`,
    },
    {
      id: "UTC+11:30",
      name: `${getTimeForUtcOffset(currentTime, +11.5)} (UTC+11:30)`,
    },
    {
      id: "UTC+12:00",
      name: `${getTimeForUtcOffset(currentTime, +12)} (UTC+12:00)`,
    },
    {
      id: "UTC+12:45",
      name: `${getTimeForUtcOffset(currentTime, +12.75)} (UTC+12:45)`,
    },
    {
      id: "UTC+13:00",
      name: `${getTimeForUtcOffset(currentTime, +13)} (UTC+13:00)`,
    },
    {
      id: "UTC+14:00",
      name: `${getTimeForUtcOffset(currentTime, +14)} (UTC+14:00)`,
    },
  ];
}
