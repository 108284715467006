import { FrmButtonGroup } from "components/formFields/FrmButtonGroup";
import { HoverHelp } from "components/utility/HoverHelp";

import { checkForUnexpectedProps } from "services/utility/misc";

export function FrmButtonGroupWithHoverHelp(props) {
  const { buttonProps, helpText, ...unexpected } = props;
  checkForUnexpectedProps("FrmSelectReference", unexpected);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
      <FrmButtonGroup {...buttonProps} />
      <HoverHelp title={helpText} sx={{ marginTop: "0.3em" }} />
    </div>
  );
}
