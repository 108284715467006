import { PRIVILEGE_STRING_TO_PROPERTY } from "appConstants";

// goal is that userPrivileges looks like
// { privilegeOne: true, privilegeFour: true }; a property in the object
// for each privilege this user has
export function userPrivilegesObject(userPrivilegesRaw) {
  const userPrivilegesArray = userPrivilegesRaw.split("|").slice(0, -1);
  const userPrivileges = {};
  for (const privilege of userPrivilegesArray) {
    userPrivileges[PRIVILEGE_STRING_TO_PROPERTY[privilege]] = true;
  }
  return userPrivileges;
}
