import { useEffect, useState } from "react";

import { getQuickList } from "services/sosInventoryService/sosApi";

export function useCustomers(customerAddedCount) {
  const [records, setRecords] = useState(null);

  useEffect(() => {
    async function retrieveRecords() {
      const response = await getQuickList("customer", {
        inTransaction: true,
      });
      setRecords(response.data);
    }
    retrieveRecords();
  }, [customerAddedCount]);

  return records;
}
