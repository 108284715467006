import { createSlice } from "@reduxjs/toolkit";

import { getItemInStorage } from "services/localStorageManager";
import { getLocalization } from "services/utility/localization";

const DEFAULT_HOME_CURRENCY = { id: null, code: "USD" };
const DEFAULT_LOCALE_CODE = "en-US";
const DEFAULT_DATE_FORMAT = "M/d/yyyy";

const initialUserCompanySettings = {
  company: {
    id: 0,
    name: "",
    userId: 0,
    expirationDate: "",
    userPrivileges: "",
    planLevel: 0,
    status: "",
    accountNumber: "",
    default: false,
  },
  companies: [
    {
      id: 0,
      name: "",
      userId: 0,
      expirationDate: "",
      userPrivileges: "",
      planLevel: 0,
      status: "",
      accountNumber: "",
      default: false,
    },
  ],
  user: { name: "", email: "" },
  settings: {
    localeCode: DEFAULT_LOCALE_CODE,
    dateFormat: DEFAULT_DATE_FORMAT,
    homeCurrency: DEFAULT_HOME_CURRENCY,
  },
  localization: {
    decimalSeparator: "",
    currencyMinimumFractionDigits: "",
    validNumber: "",
    dateFormat: "",
    homeCurrency: "",
  },
  supportPrivileges: {
    sysAdmin: false,
    sosSalesManager: false,
    sosSales: false,
    sosQA: false,
    sosDevelopment: false,
    sosAccounting: false,
    sosImpersonate: false,
    sosSupportTier3: false,
    sosSupportTier2: false,
    sosSupport: false,
  },
};

export const userCompanySettingsSlice = createSlice({
  name: "userCompanySettings",
  initialState: loadFromBrowserStorage() || initialUserCompanySettings,
  reducers: {
    reset: () => initialUserCompanySettings,
    setCompany: (state, action) => ({ ...state, company: action.payload }),
    setCompanyList: (state, action) => ({
      ...state,
      companies: action.payload,
    }),
    setUser: (state, action) => ({ ...state, user: action.payload }),
    setSettings: (state, action) => {
      //TODO: Remove localeCode hardcoding once localeCode added to settings EP
      const settings = { localeCode: DEFAULT_LOCALE_CODE, ...action.payload };
      const localization = getLocalization(settings);
      return { ...state, settings, localization };
    },
    setSupportPrivileges: (state, action) => ({
      ...state,
      supportPrivileges: action.payload,
    }),
  },
});

export const {
  reset,
  setCompany,
  setCompanyList,
  setUser,
  setSettings,
  setSupportPrivileges,
} = userCompanySettingsSlice.actions;
export default userCompanySettingsSlice.reducer;

function loadFromBrowserStorage() {
  const encodedState = getItemInStorage("userCompanySettings");
  // see https://developer.mozilla.org/en-US/docs/Glossary/Base64#solution_1_%E2%80%93_escaping_the_string_before_encoding_it
  return encodedState
    ? JSON.parse(decodeURIComponent(window.atob(encodedState)))
    : null;
}
