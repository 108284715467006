import { useState } from "react";
import { useDispatch } from "react-redux";

import { AttachFile, Print } from "@mui/icons-material";
import { IconButton, Box } from "@mui/material";

import { DocumentDialog } from "components/DocumentDialog";
import { SaveButtonSuite } from "components/SaveButtonSuite";
import { Tooltip } from "components/Tooltip";
import { CloseX } from "components/utility/CloseX";
import { ModalTitle } from "components/utility/ModalTitle";
import { StyledBadge } from "components/utility/StyledBadge";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";
import { isPersistedRecord } from "services/utility/misc";

import { recordsChangedTick } from "globalState/recordsChangedSlice";

import { OBJECT_TYPES } from "appConstants";
import { saveActions, NO_DOCUMENTS_OBJECTS_TYPES } from "editConfig";

const SAVE_ACTION_WIDTH_LARGE = "400px";
const SAVE_ACTION_WIDTH_MEDIUM = "320px";
const SAVE_ACTION_WIDTH_SMALL = "240px";

const iconButtonSx = {
  padding: "0.4rem",
  height: "36px",
  border: "1px solid",
  borderColor: "lineSeparatorMinor",
  backgroundColor: "white",
  borderRadius: 1,
};

const EDIT_PAGES_WITH_NO_DOCUMENTS = [OBJECT_TYPES.REORDER.fullString];

export function EditModalHeader(props) {
  const {
    objectType,
    relatedRecords,
    record,
    lines,
    stages,
    inputs,
    outputs,
    otherCosts,
    setErrors,
    errors,
    handleClose,
    text,
    vendorId,
    customerId,
    onAdd,
    fromForm,
    setRecord,
    newFromId,
    ...unexpected
  } = props;
  checkForUnexpectedProps("EditModalHeader", unexpected);

  const hideDocuments = NO_DOCUMENTS_OBJECTS_TYPES.includes(objectType);

  const dispatch = useDispatch();

  const [showAddDocument, setShowAddDocument] = useState(false);

  function closeDocumentDialogAndRefreshList() {
    setShowAddDocument(false);
    dispatch(recordsChangedTick());
  }

  function getSaveActionWidth() {
    if (saveActions[objectType].length) {
      return hideDocuments ? SAVE_ACTION_WIDTH_MEDIUM : SAVE_ACTION_WIDTH_LARGE;
    }
    return SAVE_ACTION_WIDTH_SMALL;
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: "dialogTitleBackground",
          borderBottom: "1px solid",
          borderBottomColor: "lineSeparatorMinor",
          padding: "0.5rem 1rem",
          fontSize: "1.2rem",
        }}
      >
        <div
          style={{
            display: "grid",
            alignItems: "top",
            gridTemplateColumns: `1fr ${getSaveActionWidth()}`,
          }}
        >
          <div style={{ minWidth: "0" }}>
            <div style={{ display: "flex", alignItems: "top" }}>
              <CloseX onClick={handleClose} dataTesting="exitEdit" />
              <ModalTitle
                label={
                  fromForm
                    ? `${i18n(`global.addNewSentence`)} ${objectType}`
                    : i18n(`objectType.${objectType}.Sentence`)
                }
                text={text}
              />
            </div>
          </div>

          <Box sx={{ displayPrint: "none" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              <Tooltip placement="bottom-start" title={i18n("global.Print")}>
                <IconButton
                  onClick={() => window.print()}
                  sx={iconButtonSx}
                  size="large"
                >
                  <Print />
                </IconButton>
              </Tooltip>

              <StyledBadge
                showZero={false}
                badgeContent={record?.documents?.length}
                color="error"
                max={99}
                overlap="rectangular"
              >
                {!hideDocuments && (
                  <Tooltip
                    placement="bottom-start"
                    title={
                      isPersistedRecord(record?.id)
                        ? i18n("global.Documents")
                        : i18n("document.SaveFirst", {
                            objectType: i18n(`objectType.${objectType}.Lower`),
                          })
                    }
                  >
                    {!EDIT_PAGES_WITH_NO_DOCUMENTS.includes(objectType) && (
                      <div>
                        <IconButton
                          onClick={() => setShowAddDocument(true)}
                          disabled={!isPersistedRecord(record?.id)}
                          sx={iconButtonSx}
                          size="large"
                        >
                          <AttachFile />
                        </IconButton>
                      </div>
                    )}
                  </Tooltip>
                )}
              </StyledBadge>

              <SaveButtonSuite
                objectType={objectType}
                setRecord={setRecord}
                record={record}
                relatedRecords={relatedRecords}
                lines={lines}
                stages={stages}
                inputs={inputs}
                outputs={outputs}
                otherCosts={otherCosts}
                setErrors={setErrors}
                errors={errors}
                vendorId={vendorId}
                customerId={customerId}
                onAdd={onAdd}
                fromForm={fromForm}
                newFromId={newFromId}
              />
            </Box>
          </Box>
        </div>
      </Box>

      {showAddDocument && (
        <DocumentDialog
          open={showAddDocument}
          id={record.id}
          closeDocumentDialog={closeDocumentDialogAndRefreshList}
          objectType={objectType}
          setDocuments={(documents) => setRecord((p) => ({ ...p, documents }))}
          documents={record.documents}
        />
      )}
    </>
  );
}
