import { FilterSelectField } from "components/formFields/FilterSelectField";

import { formatLotsWithItemName } from "services/utility/formatting";

import { useLots } from "hooks/useLots";

export function LotFilter(props) {
  const { value, onValueChange, label, name } = props;

  const rawLots = useLots();
  const lots = rawLots ? formatLotsWithItemName(rawLots) : null;

  return (
    <FilterSelectField
      name={name}
      options={lots}
      onValueChange={(name, value) => onValueChange(name, value)}
      value={value || null}
      label={label}
      disableClearable
      dataTesting="reportLot"
    />
  );
}
