import { afterGet as globalAfterGet } from "services/sosInventoryService/afterGet";
import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/payment/schema";
import { transformLineItemFields } from "services/utility/afterGet";
import { transformFields } from "services/utility/afterGet";
import { transformJobWorkCenter } from "services/utility/jobWorkCenter";

import { OBJECT_TYPES } from "appConstants";
import { LINE_ITEM_FIELDS } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.PAYMENT.fullString;

export function afterGet(record) {
  const newRecord = transformFields(record, FIELDS_TO_TRANSFORM);
  newRecord.number = record.number === "auto" ? "" : record.number;

  // date can be null on a "populateFrom" API call
  if (!newRecord.date) {
    newRecord.date = new Date();
  }

  // line items
  if (record.lines) {
    newRecord.lines = record.lines.map((line) => {
      const jobWorkcenter = transformJobWorkCenter(line.job, line.workcenter);
      return { ...line, jobWorkcenter, relatedRecords: {} };
    });

    newRecord.lines = transformLineItemFields(
      newRecord.lines,
      LINE_ITEM_FIELDS[OBJECT_TYPE]
    );
  }

  // date can be null on a "populateFrom" API call
  if (!newRecord.date) {
    newRecord.date = new Date();
  }

  return globalAfterGet(newRecord);
}
