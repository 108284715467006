// this component aggregates the serial number info if the quantity
// is > 1
import { useState } from "react";
import { useDispatch } from "react-redux";

import ListIcon from "@mui/icons-material/List";
import { InputAdornment } from "@mui/material";

import { Decimal } from "classes/DecimalClasses";
import { Loading } from "classes/Loading";

import { LineItemTextField } from "components/formFields/LineItemTextField";
import { SerialsEnter } from "components/formFields/Serials/SerialsEnter";
import { SerialsSelect } from "components/formFields/Serials/SerialsSelect";

import { i18n } from "services/i18nService";
import { LINE_ITEM_METADATA } from "services/sosInventoryService/itemReceipt/schema";

import { openAlert } from "globalState/alertSlice";

// This is to avoid creating and hydrating the text field with a
// very long string that will never be seen.
const MAX_SERIALS_IN_TEXT_FIELD = 25;

// Tells the component whether it should open a serials entry dialog or
// a serials select dialog.
export const AGGREGATOR_ENTER = 0;
export const AGGREGATOR_SELECT = 1;

export function SerialsAggregator(props) {
  const {
    enterOrSelect,
    value,
    autoNumber,
    serials,
    quantity,
    binSelectionNeeded,
    onValueChange,
    error,
    metadata,
    locationSelected,
    isReturn,
    enableForNegativeQuantity,
  } = props;

  const dispatch = useDispatch();
  const [selectorOpen, setSelectorOpen] = useState(false);

  const invalidItemQuantity = enableForNegativeQuantity
    ? quantity.eq(Decimal.ZERO)
    : quantity.lte(Decimal.ZERO);

  const isDisabled =
    quantity instanceof Loading || invalidItemQuantity || binSelectionNeeded;

  function formatValue(value) {
    if (!value) {
      return "";
    }
    return value
      .slice(0, MAX_SERIALS_IN_TEXT_FIELD)
      .map(({ name }) => name)
      .filter((serial) => serial)
      .join(", ");
  }

  function onClick() {
    if (enterOrSelect === AGGREGATOR_SELECT && !locationSelected && !isReturn) {
      const message = i18n("serials.MustSelectLocation");
      dispatch(openAlert({ type: "info", message }));
      return;
    }

    if (!isDisabled) {
      setSelectorOpen(true);
    }
  }

  if (enterOrSelect === AGGREGATOR_SELECT && !serials) {
    return null;
  }

  return (
    <>
      <LineItemTextField
        value={formatValue(value)}
        onClick={onClick}
        onValueChange={function () {}}
        cursorPointer={!isDisabled}
        error={error}
        endAdornment={
          <InputAdornment position="end">
            <ListIcon />
          </InputAdornment>
        }
        metadata={metadata}
        disabled={isDisabled}
        dataTesting="serial"
        readOnly
      />

      {enterOrSelect === AGGREGATOR_ENTER && (
        <SerialsEnter
          enumeratorOpen={selectorOpen}
          key={selectorOpen}
          value={value}
          autoNumber={autoNumber}
          label="Label"
          quantity={quantity}
          metadata={LINE_ITEM_METADATA.serials}
          onValueChange={onValueChange}
          onClose={() => setSelectorOpen(false)}
          error={error}
        />
      )}

      {enterOrSelect === AGGREGATOR_SELECT && (
        <SerialsSelect
          enumeratorOpen={selectorOpen}
          key={selectorOpen}
          onValueChange={onValueChange}
          onClose={() => setSelectorOpen(false)}
          value={value}
          quantity={enableForNegativeQuantity ? quantity.abs() : quantity}
          serials={serials}
        />
      )}
    </>
  );
}
