import { Decimal } from "classes/DecimalClasses";

import { updateLot, updateSerials } from "services/utility/afterTouchLine";
import {
  afterTouchLine as baseAfterTouchLine,
  updateWeightAndVolume,
  getUomConversion,
} from "services/utility/afterTouchLine";
import { isNonZeroDecimalOrMoney } from "services/utility/misc";

// when this field (the key) changes, we should run the (value) list of
// updater functions
const UPDATERS = {
  quantity: [updateWeightAndVolume],
  uom: [updateOnHand, updateWeightAndVolume],
  item: [updateWeightAndVolume],
  bin: [updateLot, updateSerials],
};

export function afterTouchLine(line, changedField) {
  return baseAfterTouchLine(UPDATERS, line, changedField);
}

function updateOnHand(line) {
  const {
    uom,
    relatedRecords: { item },
    itemDetails,
  } = line;

  // calculate the conversion using the item details UOM data
  const conversion = getUomConversion(itemDetails, uom);

  const onhand = isNonZeroDecimalOrMoney(item?.onhand)
    ? item.onhand.div(conversion)
    : new Decimal(0);
  return { ...line, onhand };
}
