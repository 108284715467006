import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { NEW_RECORD_SKELETON } from "appConfig";

import { i18n } from "services/i18nService";
import { getRecord } from "services/sosInventoryService/sosApi";
import { reconcileCustomFields } from "services/utility/customFields";
import { setPageDirty } from "services/utility/edit";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

// This is now only used for lots and serials
export function useObject(
  objectType,
  id,
  setRecord,
  customFieldDefs,
  setErrors,
  noCustomFields
) {
  const getEmptyRecord = NEW_RECORD_SKELETON[objectType];
  const dispatch = useDispatch();

  useEffect(() => {
    if (!customFieldDefs && !noCustomFields) {
      return;
    }
    if (id) {
      async function getObject(id) {
        dispatch(editModalLoadingIndicatorOn());
        try {
          const record = await getRecord(objectType, id);
          if (!noCustomFields) {
            record.customFields = reconcileCustomFields(
              customFieldDefs,
              record.customFields
            );
          }
          setRecord(record);
          dispatch(editModalLoadingIndicatorOff());
        } catch (error) {
          return setLoadError(error);
        }
      }
      getObject(id);
    } else {
      const emptyRecord = getEmptyRecord();
      setRecord((prevRecord) => ({
        ...prevRecord,
        ...emptyRecord,
        name: emptyRecord.name,
        customFields: noCustomFields
          ? undefined
          : reconcileCustomFields(customFieldDefs, []),
      }));
    }
    setPageDirty(false);

    function setLoadError(error) {
      setErrors((prev) => ({
        ...prev,
        entity: [...prev.entity, "record"],
        messages: [error.message || i18n("error.RecordLoadError")],
      }));
      return dispatch(editModalLoadingIndicatorOff());
    }
  }, [
    objectType,
    getEmptyRecord,
    dispatch,
    id,
    setRecord,
    customFieldDefs,
    setErrors,
    noCustomFields,
  ]);
}
