import { i18n } from "services/i18nService";

export const DEFAULT_DATE_FORMAT_OPTIONS = [
  {
    value: "M/d/yyyy",
    label: i18n("settings.user.general.dateFormat.M/d/yyyy"),
  },
  {
    value: "dd/MM/yyyy",
    label: i18n("settings.user.general.dateFormat.dd/MM/yyyy"),
  },
];

export const RED_YELLOW_GREEN_STRING = "FOUR_STATE";
export const YELLOW_STRING = "TWO_STATE";

export const STAR_SETTINGS_OPTIONS = [
  {
    value: RED_YELLOW_GREEN_STRING,
    label: i18n("settings.company.general.starSettings.redyellowgreen"),
  },
  {
    value: YELLOW_STRING,
    label: i18n("settings.company.general.starSettings.yellow"),
  },
];

export const DEFAULT_REPORT_FORMAT_OPTIONS = [
  {
    value: "xlsx",
    label: i18n("settings.company.general.defaultReportFormat.xlsx"),
  },
  {
    value: "xls",
    label: i18n("settings.company.general.defaultReportFormat.xls"),
  },
  {
    value: "csv",
    label: i18n("settings.company.general.defaultReportFormat.csv"),
  },
];

export const STAR_SETTING_OPTIONS = [
  {
    value: "xlsx",
    label: i18n("settings.company.general.defaultReportFormat.xlsx"),
  },
  {
    value: "xls",
    label: i18n("settings.company.general.defaultReportFormat.xls"),
  },
  {
    value: "csv",
    label: i18n("settings.company.general.defaultReportFormat.csv"),
  },
];

export const INACTIVITY_TIMEOUT_OPTIONS = [
  {
    id: 0,
    value: 0,
    label: i18n("settings.company.general.inactivityTimeoutMinutes.0"),
  },
  {
    id: 5,
    value: 1,
    label: i18n("settings.company.general.inactivityTimeoutMinutes.5"),
  },
  {
    id: 15,
    value: 2,
    label: i18n("settings.company.general.inactivityTimeoutMinutes.15"),
  },
  {
    id: 30,
    value: 3,
    label: i18n("settings.company.general.inactivityTimeoutMinutes.30"),
  },
  {
    id: 60,
    value: 4,
    label: i18n("settings.company.general.inactivityTimeoutMinutes.60"),
  },
  {
    id: 480,
    value: 5,
    label: i18n("settings.company.general.inactivityTimeoutMinutes.480"),
  },
];
