import { Typography } from "@mui/material";

import { LineItemTextField } from "components/formFields/LineItemTextField";
import { LineLotExpirationDate } from "components/formFields/LineLotExpirationDate";
import { subLineWrapperStyle } from "components/formFields/lineItemFieldStyle";

import { i18n } from "services/i18nService";

import { usePlans } from "hooks/usePlans";

export function LineLotWithExpiration(props) {
  const {
    lot,
    lotExpiration,
    onValueChange,
    itemLotTracked,
    lotError,
    lotExpirationError,
    quantity,
    metadata,
  } = props;

  const { hasAtLeastPlusPlan } = usePlans();
  const showEmptyWarning = quantity > 0 && !lot?.name;

  return (
    <>
      {hasAtLeastPlusPlan && itemLotTracked && (
        <div style={subLineWrapperStyle}>
          <Typography sx={{ flex: "0 1 auto", fontSize: "0.8125rem" }}>
            {i18n("columns.colon.Lot")}
          </Typography>
          <div style={{ flex: "2 0 0" }}>
            <LineItemTextField
              name="lot"
              value={lot?.name || ""}
              onValueChange={(fieldName, newValue) =>
                onValueChange(fieldName, { name: newValue })
              }
              metadata={metadata.lot}
              error={lotError || showEmptyWarning}
              dataTesting="lotEnter"
            />
          </div>
          <Typography sx={{ flex: "0 1 auto", fontSize: "0.8125rem" }}>
            {i18n("columns.colon.Expiration")}
          </Typography>
          <div style={{ flex: "0 0 10rem" }}>
            <LineLotExpirationDate
              name="lotExpiration"
              value={lotExpiration}
              onValueChange={onValueChange}
              metadata={metadata}
              error={lotExpirationError}
            />
          </div>
        </div>
      )}
    </>
  );
}
