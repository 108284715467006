import { useEffect, useState } from "react";

import { getOpenPurchaseOrdersByVendor } from "services/sosInventoryService/sosApi";

export function useVendorPos(vendor) {
  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (vendor) {
      async function retrieveRecords() {
        const response = await getOpenPurchaseOrdersByVendor(vendor.id);
        const pos = response.data;
        pos.sort((a, b) => (a.number < b.number ? -1 : 1));
        const minimalPoInfo = pos.map(({ id, number }) => ({ id, number }));
        setRecords(minimalPoInfo);
      }
      retrieveRecords();
    }
  }, [vendor]);

  return records;
}
