import { useState, useRef, memo } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TableRow, TableCell, Checkbox, Box } from "@mui/material";

import { ActionMenu } from "components/ActionMenu";
import { BillOfMaterials } from "components/BillOfMaterials";
import { BinContentsDialog } from "components/BinContentsDialog";
import { Boxes } from "components/Boxes";
import { DeleteDialog } from "components/DeleteDialog";
import { DocumentDialog } from "components/DocumentDialog";
import { EmailDialog } from "components/EmailDialog";
import { HistoryDialog } from "components/HistoryDialog";
import { JobDashboard } from "components/JobDashboard";
import { Cell } from "components/ListPage/List/Cell";
import { getIdentifier } from "components/ListPage/listConfig";
import { LocationAddresses } from "components/LocationAddresses";
import { LocationSettings } from "components/LocationSettings";
import { PdfDialog } from "components/PdfDialog";
import { PrintLabelDialog } from "components/PrintLabelDialog";
import { ProfitLossDialog } from "components/ProfitLossDialog";
import { RefundDialog } from "components/RefundDialog";
import { RentalHistoryDialog } from "components/RentalHistoryDialog";
import { Tooltip } from "components/Tooltip";
import { UnitsOfMeasure } from "components/UnitsOfMeasure";
import { Variants } from "components/Variants";

import { i18n } from "services/i18nService";
import { reconcileCustomFields } from "services/utility/customFields";
import { checkForUnexpectedProps } from "services/utility/misc";

import { theme } from "SosTheme";
import { OBJECT_TYPES } from "appConstants";

function Row_(props) {
  const {
    index,
    objectType,
    columns,
    fixedColumnsRightOfAction,
    fixedColumnsLeftOfAction,
    customFieldDefinitions,
    setQuickViewId,
    row: inputRow,
    selected,
    setCheckedIds,
    formTemplates,
    stickyColumns,
    setRecords,
    ...unexpected
  } = props;
  checkForUnexpectedProps("Row", unexpected);

  const [anchorEl, setAnchorEl] = useState(null);
  const [showPdf, setShowPdf] = useState(false);
  const [showPrintLabel, setShowPrintLabel] = useState(false);
  const [showJobDashboard, setShowJobDashboard] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showBillOfMaterials, setShowBillOfMaterials] = useState(false);
  const [showVariants, setShowVariants] = useState(false);
  const [showProfitLoss, setShowProfitLoss] = useState(false);
  const [showAddDocument, setShowAddDocument] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showBinContents, setShowBinContents] = useState(false);
  const [showRentalHistory, setShowRentalHistory] = useState(false);
  const [showUom, setShowUom] = useState(false);
  const [showLocationSettings, setShowLocationSettings] = useState(false);
  const [showAddresses, setShowAddresses] = useState(false);
  const [showBoxes, setShowBoxes] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const menuRef = useRef();

  const reconciledCustomFields = reconcileCustomFields(
    customFieldDefinitions,
    inputRow.customFields
  );
  const row = { ...inputRow, customFields: reconciledCustomFields };

  const identifierText = getIdentifier(objectType, row);

  function handleCheckChange(e) {
    const { checked } = e.target;
    setCheckedIds((prev) =>
      checked ? [...prev, row.id] : prev.filter((el) => el !== row.id)
    );
  }

  function handleDocumentUpdate(documents) {
    setRecords((prev) =>
      prev.map((e, i) => {
        if (index === i) {
          e.documents = documents;
        }
        return e;
      })
    );
  }

  return (
    <>
      <TableRow
        hover
        sx={{
          backgroundColor: index % 2 ? "banding" : "white",
          "&:hover": {
            backgroundColor: `${theme.palette.tableRowHover} !important`,
          },
        }}
      >
        <TableCell
          sx={{
            padding: 0,
            position: "sticky",
            top: 1,
            backgroundColor: "inherit",
            zIndex: "stickyColumn",
            left: stickyColumns.colorCode.left,
            minWidth: stickyColumns.colorCode.width,
            maxWidth: stickyColumns.colorCode.width,
          }}
        >
          {row.archived && (
            <ColorCode
              color="rowColorCode.archived"
              titleText={i18n("global.archived")}
            />
          )}
          {row.pendingApproval && (
            <ColorCode
              color="rowColorCode.pendingApproval"
              titleText={i18n("global.pendingApproval")}
            />
          )}
        </TableCell>

        <TableCell
          sx={{
            verticalAlign: "top",
            left: stickyColumns.checkbox.left,
            minWidth: stickyColumns.checkbox.width,
            maxWidth: stickyColumns.checkbox.width,
            position: "sticky",
            backgroundColor: "inherit",
            zIndex: "stickyColumn",
          }}
        >
          <Checkbox checked={selected} onChange={handleCheckChange} />
        </TableCell>

        {fixedColumnsLeftOfAction.map((column, i) => (
          <Cell
            key={column.name}
            row={row}
            columnIndex={i}
            objectType={objectType}
            column={column}
            customFieldDefinitions={customFieldDefinitions}
            stickyColumns={stickyColumns}
            setShowRefund={setShowRefund}
          />
        ))}

        {OBJECT_TYPES.FS_PAYMENT.fullString !== objectType && (
          <TableCell
            sx={{
              verticalAlign: "top",
              paddingLeft: 0,
              paddingTop: "0.4rem",
              paddingBottom: "0.4rem",
              left: stickyColumns.dropDown.left,
              minWidth: stickyColumns.dropDown.width,
              maxWidth: stickyColumns.dropDown.width,
              position: "sticky",
              backgroundColor: "inherit",
              borderRight: "1px solid rgba(0,0,0,0.25)",
              zIndex: theme.zIndex.stickyColumn,
            }}
          >
            <KeyboardArrowDownIcon
              ref={menuRef}
              onClick={() => setAnchorEl(menuRef.current)}
              color="primary"
              sx={{ cursor: "pointer" }}
              data-testing="listActionIcon"
            />
          </TableCell>
        )}

        {fixedColumnsRightOfAction.map((column, i) => (
          <Cell
            key={column.name}
            row={row}
            columnIndex={i}
            objectType={objectType}
            column={column}
            customFieldDefinitions={customFieldDefinitions}
            stickyColumns={stickyColumns}
            setShowAddDocument={setShowAddDocument}
          />
        ))}

        {columns.map((column, columnIndex) => (
          <Cell
            key={column.name}
            row={row}
            column={column}
            columnIndex={columnIndex}
            objectType={objectType}
            customFieldDefinitions={customFieldDefinitions}
          />
        ))}
      </TableRow>

      <ActionMenu
        row={row}
        ids={[row.id]}
        setCheckedIds={setCheckedIds}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        setShowPdf={setShowPdf}
        setShowPrintLabel={setShowPrintLabel}
        setShowJobDashboard={setShowJobDashboard}
        setQuickViewId={setQuickViewId}
        setShowEmail={setShowEmail}
        setShowDelete={setShowDelete}
        setShowHistory={setShowHistory}
        setShowProfitLoss={setShowProfitLoss}
        setShowRentalHistory={setShowRentalHistory}
        setShowLocationSettings={setShowLocationSettings}
        setShowUom={setShowUom}
        setShowVariants={setShowVariants}
        setShowAddresses={setShowAddresses}
        setShowBillOfMaterials={setShowBillOfMaterials}
        setShowBoxes={setShowBoxes}
        setShowBinContents={setShowBinContents}
        formTemplates={formTemplates}
        objectType={objectType}
      />

      {showEmail && (
        <EmailDialog
          open={showEmail}
          setOpen={setShowEmail}
          onClose={() => {
            setShowEmail(false);
            setAnchorEl(null);
          }}
          billing={row.billing}
          vendorId={row.vendor?.id}
          customerId={row.customer?.id}
          objectType={objectType}
          objectIds={[row.id]}
          documents={row.documents}
          transactionNumber={row.number}
          formTemplates={formTemplates}
        />
      )}

      {showBinContents && (
        <BinContentsDialog
          id={row.id}
          binNumber={row.number}
          onClose={() => {
            setShowBinContents(false);
            setAnchorEl(null);
          }}
        />
      )}

      {showPdf && (
        <PdfDialog
          objectType={objectType}
          objectIds={[row.id]}
          recordNumber={row.number}
          open={showPdf}
          onClose={() => {
            setShowPdf(false);
            setAnchorEl(null);
          }}
          formTemplates={formTemplates}
        />
      )}

      {showDelete && (
        <DeleteDialog
          open={showDelete}
          objectType={objectType}
          objectIds={[row.id]}
          message={
            objectType === OBJECT_TYPES.SYNC_ITEM.fullString
              ? i18n("syncitem.DeleteConfirm")
              : i18n("global.deleteRecordMessage", {
                  objectType: i18n(`objectType.${objectType}.Lower`),
                  identifier: identifierText,
                })
          }
          onClose={() => {
            setShowDelete(false);
            setAnchorEl(null);
          }}
        />
      )}
      {showAddresses && (
        <LocationAddresses
          record={row}
          onClose={() => setShowAddresses(false)}
          objectType={objectType}
        />
      )}

      {showPrintLabel && (
        <PrintLabelDialog
          ids={[row.id]}
          objectType={objectType}
          onClose={() => setShowPrintLabel(false)}
          formTemplates={formTemplates}
        />
      )}

      {showHistory && (
        <HistoryDialog
          id={row.id}
          onClose={() => setShowHistory(false)}
          objectType={objectType}
          identifierText={identifierText}
        />
      )}

      {showRentalHistory && (
        <RentalHistoryDialog
          id={row.id}
          setOpen={setShowRentalHistory}
          objectType={objectType}
          identifierText={identifierText}
        />
      )}

      {showLocationSettings && (
        <LocationSettings
          id={row.id}
          close={() => setShowLocationSettings(false)}
          identifierText={identifierText}
        />
      )}

      {showUom && (
        <UnitsOfMeasure
          id={row.id}
          close={() => setShowUom(false)}
          identifierText={identifierText}
        />
      )}

      {showVariants && (
        <Variants
          id={row.id}
          itemType={row.type}
          close={() => setShowVariants(false)}
          identifierText={identifierText}
        />
      )}

      {showBillOfMaterials && (
        <BillOfMaterials
          id={row.id}
          close={() => setShowBillOfMaterials(false)}
          identifierText={identifierText}
        />
      )}

      {showBoxes && (
        <Boxes
          id={row.id}
          close={() => setShowBoxes(false)}
          identifierText={identifierText}
        />
      )}

      {showJobDashboard && (
        <JobDashboard
          id={row.id}
          close={() => setShowJobDashboard(false)}
          identifierText={identifierText}
        />
      )}
      {showProfitLoss && (
        <ProfitLossDialog
          id={row.id}
          close={() => setShowProfitLoss(false)}
          identifierText={row.name}
          objectType={objectType}
        />
      )}
      {showRefund && (
        <RefundDialog
          id={row.id}
          amount={row.amount}
          close={() => setShowRefund(false)}
          identifierText={identifierText}
          objectType={objectType}
        />
      )}
      {showAddDocument && (
        <DocumentDialog
          id={row.id}
          objectType={objectType}
          open={showAddDocument}
          closeDocumentDialog={() => setShowAddDocument(false)}
          setDocuments={handleDocumentUpdate}
          documents={row.documents}
        />
      )}
    </>
  );
}

export const Row = memo(Row_);

function ColorCode(props) {
  const { color, titleText } = props;
  return (
    <Tooltip title={titleText}>
      <Box
        sx={{
          position: "relative",
          top: 2,
          backgroundColor: color,
          maxWidth: "7px",
          minWidth: "7px",
          padding: 0,
          height: "100%",
          display: "inline-block",
        }}
      />
    </Tooltip>
  );
}
