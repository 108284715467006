import { i18n } from "services/i18nService";

import {
  EXPIRED_RECALLED_OPTIONS,
  ALL_OPTION_WITH_ALL_ID,
} from "views/Reports/reportConstants";

import { ITEM_QUICKLIST_CASES, OBJECT_TYPES } from "appConstants";

export const LotsInBins = {
  userHasSufficientPrivilege: (p) =>
    p.viewInventory || p.viewItems || p.editItems,
  planIncludesReport: (plus) => plus,
  customReportNameFetchList: [
    { objectType: OBJECT_TYPES.ITEM.fullString, customReportKey: "categories" },
    {
      objectType: OBJECT_TYPES.LOCATION.fullString,
      customReportKey: "locations",
    },
    { objectType: OBJECT_TYPES.ITEM.fullString },
  ],
  customFieldObjectTypes: ["lot", "item"],
  columns: [
    { apiName: "Lot", default: true },
    { apiName: "Item", default: true },
    { apiName: "Description" },
    { apiName: "Item Description" },
    { apiName: "Expiration" },
    { apiName: "Recalled" },
    { apiName: "SKU" },
    { apiName: "Quantity", number: true, alwaysShow: true },
  ],
  settings: [
    {
      label: i18n("filter.BinTrackedLocations"),
      component: "LocationsFilter",
      apiName: "locations",
      defaultValue: null,
      onlyBinTracked: true,
      placeholder: i18n("reports.placeholder.DefaultIsAll", {
        type: i18n("reports.locations"),
      }),
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Item"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
      itemType: ITEM_QUICKLIST_CASES.LOT,
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.BinNumberContains"),
      component: "FrmTextField",
      apiName: "nameContains2",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "txnType",
      defaultValue: ALL_OPTION_WITH_ALL_ID,
      label: "Status",
      component: "FrmSelectFromObjects",
      options: EXPIRED_RECALLED_OPTIONS,
      type: "filter",
    },
    {
      apiName: "suppressIfEmpty",
      defaultValue: true,
      label: i18n("reports.OptionLabel.HideRowsWithAllZeros"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
