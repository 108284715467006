import { useEffect } from "react";
import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import { CustomFields } from "components/CustomFields/CustomFields";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { Contact } from "components/formFields/Contact";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmTextField } from "components/formFields/FrmTextField";
import { Address } from "components/formFields/address/Address";
import { FrmTerms } from "components/formFields/frm";
import { FrmCheckbox } from "components/formFields/frm/FrmCheckbox";
import { FrmCurrency } from "components/formFields/frm/FrmCurrency";
import { FrmTax } from "components/formFields/frm/FrmTax";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { HoverHelp } from "components/utility/HoverHelp";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { isPersistedRecord } from "services/utility/misc";

import { useCustomFieldDefinitions } from "hooks/useCustomFieldDefinitions";
import { useErrors } from "hooks/useErrors";
import { useRecord } from "hooks/useRecord";
import { useRelated } from "hooks/useRelated";
import { useTaxCodes, TAX_CODE_TYPE_PURCHASE } from "hooks/useTaxCodes";
import { useVendor } from "hooks/useVendor";

import { OBJECT_TYPES } from "appConstants";

const OBJECT_TYPE = OBJECT_TYPES.VENDOR.fullString;

export function Vendor(props) {
  const { id, onClose, onAdd, fromForm, name } = props;

  const currencies = useRelated("currency");
  const taxCodes = useTaxCodes(TAX_CODE_TYPE_PURCHASE);
  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();

  const loadingState = useSelector((state) => state.loading.editModal);

  // SETTINGS
  const homeCurrencyRef = useSelector(
    (state) => state.userCompanySettings.settings.homeCurrencyRef,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultTerms = useSelector(
    (state) => state.userCompanySettings.settings.defaultTerms,
    (prev, curr) => curr?.id === prev?.id
  );
  const globalEnabled = useSelector(
    (state) => state.userCompanySettings.settings.globalEnabled
  );
  const multiCurrencyEnabled = useSelector(
    (state) => state.userCompanySettings.settings.multiCurrencyEnabled
  );

  const customFieldDefs = useCustomFieldDefinitions(OBJECT_TYPE);

  useVendor(id, setRecord, customFieldDefs, setErrors, name, {
    homeCurrencyRef,
    defaultTerms,
  });

  useEffect(() => {
    if (fromForm) {
      setRecord((prevRecord) => ({ ...prevRecord, showOnForms: true }));
    }
  }, [setRecord, fromForm]);

  function handleTaxCodeUpdate(e) {
    setRecord((prevRecord) => ({
      ...prevRecord,
      taxCode: { id: e.target.value },
    }));
  }

  // if new transaction with record not defined, show null till record set in
  // useVendor
  if (!id && !record) {
    return null;
  }

  function handleCustomFieldChange(_, newValue) {
    setRecord((prevRecord) => ({ ...prevRecord, customFields: newValue }));
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        relatedRecords={[]}
        lines={[]}
        setErrors={setErrors}
        errors={errors}
        text={record?.name}
        handleClose={onClose}
        onAdd={onAdd}
        fromForm={fromForm}
      />
      {loadingState && <Loading />}
      <ObjectEditDialogContent>
        <Box flexGrow="1" position="relative" id="B" m={2} mb={0}>
          <FormErrors errors={errors} setErrors={setErrors} />
          {record ? (
            <Box>
              <FrmFieldGrid gridAutoFlow="dense">
                <FrmTextField
                  autoFocus
                  label={i18n("frmLabel.Name")}
                  error={isInError("name")}
                  maxLength={200}
                  onValueBlur={handleInputFieldChange}
                  value={record.name}
                  name="name"
                  dataTesting="name"
                />
                <FrmTextField
                  label={i18n("global.Email")}
                  maxLength={100}
                  onValueBlur={handleInputFieldChange}
                  value={record.email}
                  name="email"
                  type="email"
                  error={isInError("email")}
                />
                <FrmTextField
                  label={i18n("global.Website")}
                  maxLength={255}
                  onValueBlur={handleInputFieldChange}
                  value={record.website}
                  name="website"
                  error={isInError("website")}
                />
                <FrmTextField
                  label={i18n("global.Company")}
                  maxLength={200}
                  onValueBlur={handleInputFieldChange}
                  value={record.companyName}
                  name="companyName"
                />
                <FrmTextField
                  label={i18n("global.Phone")}
                  maxLength={30}
                  onValueBlur={handleInputFieldChange}
                  value={record.phone}
                  name="phone"
                />
                <FrmTextField
                  label={i18n("global.MobilePhone")}
                  maxLength={30}
                  onValueBlur={handleInputFieldChange}
                  value={record.mobile}
                  name="mobile"
                />
                <FrmTextField
                  label={i18n("global.AltPhone")}
                  maxLength={30}
                  onValueBlur={handleInputFieldChange}
                  value={record.altPhone}
                  name="altPhone"
                />
                <FrmTextField
                  label={i18n("global.Fax")}
                  maxLength={30}
                  onValueBlur={handleInputFieldChange}
                  value={record.fax}
                  name="fax"
                />
              </FrmFieldGrid>
              <VSpace space={2} />
              <FrmFieldGrid gridAutoFlow="dense">
                <Contact
                  onValueBlur={handleInputFieldChange}
                  contact={record.contact}
                />
                <Address
                  title={i18n("global.Address")}
                  addressType="address"
                  address={record.address}
                  onValueBlur={handleInputFieldChange}
                />
              </FrmFieldGrid>
              <VSpace space={2} />
              <FrmFieldGrid alignItems="center" gridAutoFlow="dense">
                <FrmTerms
                  maxWidth="20rem"
                  value={record.terms}
                  onValueChange={handleInputFieldChange}
                  error={isInError("terms")}
                />
                {globalEnabled && taxCodes?.length ? (
                  <FrmTax
                    maxWidth="20rem"
                    taxCodes={taxCodes}
                    value={record.taxCode}
                    onValueChange={handleTaxCodeUpdate}
                    error={isInError("tax")}
                  />
                ) : null}
                {multiCurrencyEnabled && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ maxWidth: "10em" }}>
                      <FrmCurrency
                        value={record.currency || homeCurrencyRef}
                        currencies={currencies}
                        onValueChange={handleInputFieldChange}
                        setRecord={setRecord}
                        error={isInError("currency")}
                        disabled={
                          isPersistedRecord(record.id) && record.isInQuickBooks
                        }
                      />
                    </div>
                    {isPersistedRecord(record.id) && record.isInQuickBooks && (
                      <HoverHelp
                        title={i18n("tooltip.CurrencyDisabled", {
                          objectType: i18n(`objectType.vendor.Lower`),
                        })}
                        sx={{ marginLeft: 0.5 }}
                      />
                    )}
                  </div>
                )}
                <FrmTextField
                  sx={{ maxWidth: "20rem" }}
                  label={i18n("global.AccountNumber")}
                  maxLength={100}
                  onValueBlur={handleInputFieldChange}
                  value={record.accountNumber || ""}
                  name="accountNumber"
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FrmCheckbox
                    name="showOnForms"
                    value={record.showOnForms}
                    onValueChange={handleInputFieldChange}
                    dataTesting="showOnForms"
                    label={i18n("global.ShowOnForms")}
                    sx={{ marginRight: 0 }}
                  />
                  <HoverHelp
                    title={i18n("tooltip.ShowOnForms", {
                      objectType: OBJECT_TYPE,
                    })}
                    sx={{ marginLeft: 0.5 }}
                  />
                </div>
                <FrmResizeTextField
                  label={i18n("global.Notes")}
                  onValueBlur={handleInputFieldChange}
                  value={record.notes}
                  name="notes"
                  width="50%"
                  minRows={4}
                />
              </FrmFieldGrid>
              <CustomFields
                customFieldDefinitions={customFieldDefs}
                customFields={record.customFields || []}
                onValueChange={handleCustomFieldChange}
                setErrors={setErrors}
              />
            </Box>
          ) : (
            !isInError("record") && (
              <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
            )
          )}
        </Box>
        <VSpace space={4} />
      </ObjectEditDialogContent>
    </>
  );
}
