import { FormControlLabel, Checkbox } from "@mui/material";

import { checkForUnexpectedProps } from "services/utility/misc";

export function FrmCheckbox(props) {
  const {
    name,
    value,
    onValueChange,
    disabled,
    readOnly,
    label,
    dataTesting,
    sx = {},
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmCheckbox", unexpected);

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={value}
          onChange={(e) => onValueChange(e.target.name, e.target.checked)}
          disabled={disabled}
          data-testing={dataTesting}
          sx={{ py: 0 }}
        />
      }
      label={label}
      sx={{ alignItems: "start", ...sx }}
    />
  );
}
