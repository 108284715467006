import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";

export function SignatureLines(props) {
  const { label } = props;

  return (
    <>
      <div style={{ display: "flex", width: "40ch", gap: "0.5rem" }}>
        <div style={{ minWidth: "10ch" }}>{label}</div>
        <div style={{ borderBottom: "1px solid black", flexGrow: 1 }}></div>
      </div>
      <VSpace space={2} />
      <div style={{ display: "flex", width: "40ch", gap: "0.5rem" }}>
        <div style={{ minWidth: "10ch" }}>{i18n("colon.Date")}</div>
        <div style={{ borderBottom: "1px solid black", flexGrow: 1 }}></div>
      </div>
    </>
  );
}
