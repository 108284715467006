import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { IN_TRANSACTION } from "appConfig";

import { CustomFields } from "components/CustomFields/CustomFields";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { QuickAddCustomer } from "components/QuickAddCustomer";
import { SectionHeading } from "components/SectionHeading";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmTextField } from "components/formFields/FrmTextField";
import { Address } from "components/formFields/address/Address";
import { FrmCustomerWithAdd, FrmNumber } from "components/formFields/frm";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { HoverHelp } from "components/utility/HoverHelp";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import {
  getQuickList,
  postRecord,
  getRecord,
} from "services/sosInventoryService/sosApi";
import {
  reconcileCustomFields,
  copyCustomFieldValues,
} from "services/utility/customFields";
import { setPageDirty } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";

import { useCustomFieldDefsAsObject } from "hooks/useCustomFieldDefinitions";
import { useErrors } from "hooks/useErrors";
import { useJob } from "hooks/useJob";
import { useLineHandler } from "hooks/useLineHandler";
import { useRecord } from "hooks/useRecord";

import { Stages } from "views/Jobs/Stage/Stages";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { OBJECT_TYPES } from "appConstants";

const OBJECT_TYPE = OBJECT_TYPES.JOB.fullString;
const CUSTOM_FIELD_OBJECT_TYPES = [
  OBJECT_TYPE,
  OBJECT_TYPES.CUSTOMER.fullString,
];

export function Job(props) {
  const { id, onClose, onAdd } = props;

  const dispatch = useDispatch();
  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();
  const [workCenters, setWorkCenters] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [addNewCustomer, setAddNewCustomer] = useState(false);
  const [newCustomerName, setNewCustomerName] = useState("");
  const [stages, stageHandler] = useLineHandler();
  const loadingState = useSelector((state) => state.loading.editModal);

  const customFieldDefs = useCustomFieldDefsAsObject(CUSTOM_FIELD_OBJECT_TYPES);

  useJob(
    id,
    setRecord,
    setWorkCenters,
    customFieldDefs ? customFieldDefs[OBJECT_TYPE] : null,
    stageHandler
  );

  useEffect(() => {
    (async () => {
      const response = await getQuickList("customer", { inTransaction: true });
      setCustomers(response.data);
    })();
  }, []);

  function handleCustomFieldsChange(_, newValue) {
    setRecord((prevRecord) => ({ ...prevRecord, customFields: newValue }));
  }

  async function onChangeCustomer(_, newCustomer) {
    if (!newCustomer.id) {
      setRecord((prevRecord) => ({ ...prevRecord, customer: null }));
      return;
    }

    try {
      // get the newly selected customer record, so that we can...
      const customer = await getRecord(
        "customer",
        newCustomer.id,
        IN_TRANSACTION
      );

      // be sure there are custom field entries for each defined custom field
      // in the customer record...
      const customerCustomFields = reconcileCustomFields(
        customFieldDefs.customer,
        customer.customFields
      );

      // ...then initialize any transaction custom fields to their matching customer
      // custom field values, if any
      const newTransactionCustomFields = copyCustomFieldValues(
        customFieldDefs.customer,
        customerCustomFields,
        customFieldDefs[OBJECT_TYPE],
        record.customFields
      );

      if (customer)
        setRecord((prevRecord) => ({
          ...prevRecord,
          customer,
          address: customer.shipping,
          customFields: newTransactionCustomFields,
        }));
    } catch (e) {
      handleProgramError(e);
    }
  }

  async function handleQuickAddNewCustomer(name) {
    const newObject = { name, showOnForms: true };

    dispatch(editModalLoadingIndicatorOn());
    const { success, record, message } = await postRecord(
      "customer",
      newObject
    );
    if (success) {
      const customer = record;
      // re-get the customers to pick up new one
      const response = await getQuickList("customer");
      setCustomers(response.data);

      setPageDirty();
      // update the record with the new customer
      setRecord((prevRecord) => ({
        ...prevRecord,
        customer: {
          id: customer.id,
          name: customer.name,
        },
      }));
    } else {
      setErrors((prev) => ({ ...prev, messages: [message] }));
    }
    dispatch(editModalLoadingIndicatorOff());
    setAddNewCustomer(false);
  }

  async function handleAddNewCustomer(customer) {
    // be sure there are custom field entries for each defined custom field
    // in the customer record...
    const customerCustomFields = reconcileCustomFields(
      customFieldDefs.customer,
      customer.customFields
    );

    // ...then initialize any transaction custom fields to their matching customer
    // custom field values, if any
    const newTransactionCustomFields = copyCustomFieldValues(
      customFieldDefs.customer,
      customerCustomFields,
      customFieldDefs[OBJECT_TYPE],
      record.customFields
    );

    setPageDirty();
    setRecord((prevRecord) => ({
      ...prevRecord,
      customer,
      address: customer.shipping,
      customFields: newTransactionCustomFields,
    }));

    // re-get the customers to pick up new one
    const response = await getQuickList("customer");
    setCustomers(response.data);
    dispatch(editModalLoadingIndicatorOff());
    setAddNewCustomer(false);
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        setErrors={setErrors}
        errors={errors}
        text={record?.name || ""}
        handleClose={onClose}
        onAdd={onAdd}
        stages={stages}
      />

      {loadingState && <Loading />}

      {record && (
        <ObjectEditDialogContent>
          <Layout
            pageTitle={
              id
                ? `${i18n("objectType.job.Sentence")} ${record?.number || ""}`
                : i18n("objectType.job.New")
            }
          >
            <Box flexGrow="1" position="relative" id="B" m={2} mb={0}>
              {id && !record ? (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              ) : (
                <>
                  <Box>
                    <FormErrors errors={errors} setErrors={setErrors} />
                    <FrmFieldGrid gridAutoFlow="dense">
                      <FrmTextField
                        autoFocus
                        name="name"
                        label={i18n("frmLabel.Name")}
                        error={isInError("name")}
                        maxLength={200}
                        onValueBlur={handleInputFieldChange}
                        value={record?.name}
                        dataTesting="name"
                      />
                      <FrmNumber
                        recordId={record.id}
                        label={i18n("frmLabel.JobNumber")}
                        value={record.number}
                        onValueChange={handleInputFieldChange}
                        error={isInError("number")}
                      />
                    </FrmFieldGrid>
                    <VSpace space={1} />
                    <FrmFieldGrid gridAutoFlow="dense">
                      <FrmResizeTextField
                        name="description"
                        value={record.description}
                        label={i18n("frmLabel.Description")}
                        minRows={2}
                        width="100%"
                        onValueBlur={handleInputFieldChange}
                      />
                    </FrmFieldGrid>
                    <VSpace space={1} />
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ maxWidth: "100%", width: "100%" }}
                    >
                      <FrmCustomerWithAdd
                        value={record.customer}
                        onValueChange={onChangeCustomer}
                        setAddNewCustomer={setAddNewCustomer}
                        setNewCustomerName={setNewCustomerName}
                        error={isInError("customer")}
                        customers={customers}
                      />
                      <HoverHelp
                        title={i18n("hoverHelp.CustomerOptional")}
                        sx={{ marginLeft: 0.5 }}
                      />
                    </Box>
                    <VSpace space={2} />
                    <div style={{ maxWidth: "50%" }}>
                      <Address
                        title={i18n("frmLabel.JobAddress")}
                        addressType="address"
                        address={record.address}
                        onValueBlur={handleInputFieldChange}
                      />
                    </div>
                    <VSpace space={1} />
                    <SectionHeading>{i18n("global.Stages")}</SectionHeading>
                    <Stages
                      stages={stages}
                      workCenters={workCenters}
                      stageHandler={stageHandler}
                    />
                  </Box>
                </>
              )}

              <CustomFields
                customFieldDefinitions={customFieldDefs[OBJECT_TYPE]}
                customFields={record.customFields || []}
                onValueChange={handleCustomFieldsChange}
                setErrors={setErrors}
              />
            </Box>
            <VSpace space={4} />
            {record && !!addNewCustomer && (
              <QuickAddCustomer
                open
                initialNameValue={newCustomerName}
                onClose={() => setAddNewCustomer(false)}
                onQuickAdd={handleQuickAddNewCustomer}
                onAdd={handleAddNewCustomer}
              />
            )}
          </Layout>
        </ObjectEditDialogContent>
      )}
    </>
  );
}
