import { updateLot, updateSerials } from "services/utility/afterTouchLine";
import {
  afterTouchLine as baseAfterTouchLine,
  updateWeightAndVolume,
} from "services/utility/afterTouchLine";

// when this field (the key) changes, we should run the (value) list of
// updater functions
const UPDATERS = {
  quantity: [updateWeightAndVolume],
  uom: [updateWeightAndVolume],
  item: [updateWeightAndVolume],
  bin: [updateLot, updateSerials],
};

export function afterTouchLine(line, changedField) {
  return baseAfterTouchLine(UPDATERS, line, changedField);
}
