import { theme } from "SosTheme";

export function FrmFieldGrid(props) {
  const { columnGap = 6, gridAutoFlow, alignItems = "start" } = props;

  // the constant below is the minimum width we want to allow for a grid
  // column; below that width it will switch to a single column
  const MINIMUM_GRID_COLUMN_WIDTH = "368px";

  const columnGapInPixels = theme.spacing(columnGap);

  return (
    <div
      style={{
        display: "grid",
        // the other part of the max function makes the minimum column width
        // large in large windows, so that it doesn't flip to more than two
        // columns
        gridTemplateColumns: `repeat(auto-fit, minmax(max(${MINIMUM_GRID_COLUMN_WIDTH}, calc((100% - ${columnGapInPixels}) / 2)), 1fr)`,
        columnGap: columnGapInPixels,
        rowGap: theme.spacing(1),
        gridAutoFlow: gridAutoFlow,
        alignItems,
      }}
    >
      {props.children}
    </div>
  );
}
