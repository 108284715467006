import { ReportMultiSelect } from "components/formFields/ReportMultiSelect";

import { useRelated } from "hooks/useRelated";

import { UNASSIGNED_OPTION } from "views/Reports/reportConstants";

export function AssignedToFilter(props) {
  const { value, name, onValueChange, label } = props;

  const users = useRelated("user");

  return (
    <ReportMultiSelect
      name={name}
      options={users ? [UNASSIGNED_OPTION, ...users] : users}
      onValueChange={onValueChange}
      value={value || []}
      label={label}
    />
  );
}
