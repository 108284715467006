import { useDispatch } from "react-redux";

import { LinkText } from "components/utility/LinkText";

import { i18n } from "services/i18nService";
import { syncRecords } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

import { openAlert } from "globalState/alertSlice";
import {
  loadingIndicatorOn,
  loadingIndicatorOff,
} from "globalState/loadingSlice";

export function AddToSync(props) {
  const { id, objectType } = props;
  const dispatch = useDispatch();

  async function handleSync() {
    dispatch(loadingIndicatorOn());
    const { success, message } = await syncRecords(objectType, [id]);
    if (success) {
      dispatch(
        openAlert({ type: "success", message: i18n("alert.SuccessSync") })
      );
    } else {
      handleProgramError(new Error(message));
    }
    dispatch(loadingIndicatorOff());
  }

  return (
    <td style={{ textAlign: "center" }}>
      <LinkText onClick={handleSync}>{i18n("global.AddToQueue")}</LinkText>
    </td>
  );
}
