import { normalizeLinkedTransaction } from "services/sosInventoryService/utility/normalizeLinkedTransaction";
import { convertNativeToCustom } from "services/utility/customFields";
import { normalizeJobWorkCenter } from "services/utility/jobWorkCenter";
import { cleanTransactionNumber } from "services/utility/misc";
import { TYPES } from "services/utility/schema";

import { LINE_ITEM_FIELDS } from "editConfig";

const LINE_TYPES = ["lines", "outputs", "inputs"];

export function productionTransactionBeforeSave(objectType, record) {
  const newRecord = { ...record };

  newRecord.number = cleanTransactionNumber(record.id, record.number);

  // for each line
  LINE_TYPES.filter((lineType) => record[lineType]).forEach((lineType) => {
    // remove any line items with no inventory item
    let materialLines = record[lineType].filter((line) => line.item?.id);
    const newLines = [];
    materialLines.forEach((line) => {
      let newLine = { ...line };

      newLine = normalizeJobWorkCenter(newLine);

      // normalize other reference fields; if no referenced record is selected,
      // set the field to null
      LINE_ITEM_FIELDS[objectType][TYPES.reference].forEach((field) => {
        if (
          line[field] &&
          typeof line[field].id === "string" &&
          line[field].id === ""
        ) {
          newLine[field] = null;
        }
      });

      // convert any number or currency fields that are "" to zero
      const fieldsToCheck = LINE_ITEM_FIELDS[objectType][TYPES.number];
      fieldsToCheck.forEach((field) => {
        if (line[field] === "") {
          newLine[field] = 0;
        }
      });

      // convert item to remove unnecessary data
      if (line.item?.id) {
        newLine.item = { id: line.item.id };
      }

      // ensure linkedTransaction is in a proper format for the backend
      if (newLine.linkedTransaction) {
        newLine.linkedTransaction = normalizeLinkedTransaction(
          line.linkedTransaction
        );
      }

      // remove itemDetails from line; it's only used in this frontend
      delete newLine.itemDetails;

      // remove relatedRecords from line; it's only used in this frontend
      delete newLine.relatedRecords;

      newLines.push(newLine);
      // update the record with the new lines
      newRecord[lineType] = newLines;
    });
  });

  newRecord.customFields = convertNativeToCustom(record.customFields);

  return newRecord;
}
