import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function FrmPriority(props) {
  const { value, onValueChange, maxWidth } = props;

  const priorities = useRelated("priority");
  const sortedPriorities = priorities
    ? priorities.sort((a, b) => (a.id < b.id ? -1 : 1))
    : null;

  return (
    <FrmSelectFromObjects
      name="priority"
      options={sortedPriorities}
      value={value}
      label={i18n("frmLabel.Priority")}
      onValueChange={onValueChange}
      maxWidth={maxWidth}
    />
  );
}
