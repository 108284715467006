import { Money } from "classes/DecimalClasses";

export const ZERO_TOTALS = { amount: Money.ZERO };

export function updateTotals(lines, setTotals) {
  if (!lines || lines.length === 0) {
    setTotals(ZERO_TOTALS);
    return;
  }

  const amount = lines.reduce(
    (total, line) => total.plus(line.amount || Money.ZERO),
    Money.ZERO
  );

  setTotals({ amount });
}
