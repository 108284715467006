import { Box } from "@mui/material";

import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";

export const PAYMENT_TYPE_BILL_ID = "Bill";

const payments = [
  { id: "None", name: i18n("payment.None") },
  { id: PAYMENT_TYPE_BILL_ID, name: i18n("payment.Bill") },
  { id: "CashPurchase", name: i18n("payment.CashPurchase") },
  { id: "Check", name: i18n("payment.Check") },
  { id: "CreditCardCharge", name: i18n("payment.CreditCardCharge") },
];

export function FrmPayment(props) {
  const { value, onValueChange, error } = props;

  const payment = payments.find(({ id }) => id === value?.id);

  return (
    <Box display="flex" alignItems="center">
      <FrmSelectFromObjects
        name="payment"
        options={payments}
        label={i18n("frmLabel.Payment")}
        onValueChange={onValueChange}
        minWidth="10rem"
        value={payment}
        error={error}
        disableClearable
      />
      <HoverHelp title={i18n("hoverHelp.payment")} sx={{ marginLeft: 0.5 }} />
    </Box>
  );
}
