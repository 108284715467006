import { Checkbox } from "@mui/material";

import { LineTableCell } from "components/formFields/LineTableCell";

export function LineWaste(props) {
  const { value, onValueChange } = props;

  function onChange(e) {
    onValueChange("waste", e.target.checked);
  }

  return (
    <LineTableCell>
      <div style={{ width: "100%", display: "inline-block" }}>
        <div style={{ textAlign: "center" }}>
          <Checkbox
            key={value}
            name="waste"
            checked={value}
            onChange={onChange}
          />
        </div>
      </div>
    </LineTableCell>
  );
}
