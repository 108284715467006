export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["conversion"],
  MONEY_FIELDS: ["salesPrice", "purchaseCost"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const EMPTY_RECORD = {
  conversion: null,
  purchaseCost: null,
  isBase: false,
  salesPrice: null,
  uom: null,
};

export const BASE_UOM_SKELETON = {
  conversion: 1,
  purchaseCost: null,
  isBase: true,
  salesPrice: null,
};
