import { moreTransactionBeforeSave } from "services/sosInventoryService/moreTransaction/beforeSave";

import { OBJECT_TYPES } from "appConstants";

export function beforeSave(record) {
  return moreTransactionBeforeSave(
    OBJECT_TYPES.RENTAL_RETURN.fullString,
    record
  );
}
