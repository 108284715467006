import { useSelector } from "react-redux";

import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function FrmParentCustomer(props) {
  const { value, onValueChange } = props;

  const customers = useRelated("customer");

  const findCustomerByFullName = useSelector(
    (state) => state.userCompanySettings.settings.findCustomerByFullName
  );
  const optionDisplayText = findCustomerByFullName ? "fullname" : "name";

  return (
    <FrmSelectFromObjects
      label={i18n("customer.ParentCustomer")}
      name="parent"
      value={value}
      onValueChange={onValueChange}
      options={customers}
      optionDisplayText={optionDisplayText}
    />
  );
}
