import { useLocation } from "react-router-dom";

import { Box, Card, CardContent, Typography } from "@mui/material";

import { i18n } from "services/i18nService";

export function NotFound() {
  const { pathname } = useLocation();
  const pathNameArray = pathname.split("/");
  const pathNameHeader = pathNameArray[pathNameArray.length - 1];

  return (
    <>
      <Box sx={{ display: "grid", minWidth: "1070px" }} m={2} mb={0}>
        <Box mb={1} sx={{ textTransform: "capitalize" }}>
          <Typography variant="h2">{pathNameHeader}</Typography>
        </Box>
        <Card variant="outlined" sx={{ maxWidth: 500 }}>
          <CardContent>
            <Typography sx={{ paddingBottom: "1rem" }}>
              {i18n("beta.NotFinished")}
            </Typography>
            <Typography>
              {i18n("beta.GoToProduction")}{" "}
              <a
                href="https://live.sosinventory.com"
                rel="noreferrer"
                target="_blank"
              >
                https://live.sosinventory.com
              </a>
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
