import { ALL_FILTER_OPTION } from "appConstants";

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["outputQuantity", "quantity", "totalQuantity"],
  MONEY_FIELDS: ["costBasis", "costPerUnit", "totalCostBasis", "variance"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["txnDate"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const TYPE_FILTER_OPTIONS = [
  ALL_FILTER_OPTION,
  { id: 1, name: "shipment" },
  { id: 2, name: "return to vendor" },
  { id: 3, name: "item receipt" },
  { id: 4, name: "return" },
  { id: 5, name: "inventory adjustment" },
  { id: 6, name: "transfer" },
  { id: 7, name: "build" },
  { id: 8, name: "process transaction" },
];
