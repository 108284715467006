import { Delete } from "@mui/icons-material";
import { TableRow, IconButton } from "@mui/material";

import { Money } from "classes/DecimalClasses";
import { Loading } from "classes/Loading";

import { LineTableCell } from "components/formFields/LineTableCell";
import { LineInventoryItem } from "components/formFields/line/LineInventoryItem";
import { LineWaste } from "components/formFields/line/LineWaste";
import { RowDecimalField } from "components/formFields/row/RowDecimalField";
import { RowStaticMoney } from "components/formFields/row/RowStaticMoney";

import { getItemRecord } from "services/sosInventoryService/domainLogic";
import { setPageDirty } from "services/utility/edit";

import {
  ITEM_QUICKLIST_FORM_TYPES,
  DEFAULT_DECIMALS_UNROUNDED,
} from "appConstants";

export function TemplateRow(props) {
  const { line, deleteRow, rowIndex, items, itemsCount, setRecord, lineType } =
    props;

  async function onItemChange(item) {
    setPageDirty();
    if (!item) {
      const newLine = {
        ...line,
        item: { id: null, name: null },
        cost: Money.ZERO,
        total: Money.ZERO,
      };
      setRecord((prevRecord) => {
        const data = [...prevRecord[lineType]];
        data[rowIndex] = newLine;
        return { ...prevRecord, [lineType]: data };
      });
      return;
    }
    const initialLine = {
      ...line,
      item,
      cost: new Loading(),
      total: new Loading(),
    };
    setRecord((prevRecord) => {
      const data = [...prevRecord[lineType]];
      data[rowIndex] = initialLine;
      return { ...prevRecord, [lineType]: data };
    });
    const { purchaseCost } = await getItemRecord(item.id);
    const total = purchaseCost.times(line.quantity);
    const newLine = { ...line, item, cost: purchaseCost, total };
    setRecord((prevRecord) => {
      const data = [...prevRecord[lineType]];
      data[rowIndex] = newLine;
      return { ...prevRecord, [lineType]: data };
    });
  }

  async function onQuantityChange(_, quantity) {
    setPageDirty();
    if (!line.item) {
      const newLine = { ...line, quantity, total: Money.ZERO };
      setRecord((prevRecord) => {
        const data = [...prevRecord[lineType]];
        data[rowIndex] = newLine;
        return { ...prevRecord, [lineType]: data };
      });
      return;
    }
    const newLine = { ...line, quantity, total: line.cost.times(quantity) };
    setRecord((prevRecord) => {
      const data = [...prevRecord[lineType]];
      data[rowIndex] = newLine;
      return { ...prevRecord, [lineType]: data };
    });
  }

  function onValueChange(fieldName, newValue) {
    const newLine = { ...line, [fieldName]: newValue };
    setRecord((prevRecord) => {
      const data = [...prevRecord[lineType]];
      data[rowIndex] = newLine;
      return { ...prevRecord, [lineType]: data };
    });
  }

  return (
    <TableRow sx={{ position: "inherit" }}>
      <LineTableCell
        borderLeft
        sx={{ width: "3rem", textAlign: "center", position: "relative" }}
      >
        <IconButton size="small" onClick={() => deleteRow(rowIndex)}>
          <Delete />
        </IconButton>
      </LineTableCell>
      <LineTableCell sx={{ minWidth: "13rem" }}>
        <LineInventoryItem
          itemFormType={ITEM_QUICKLIST_FORM_TYPES.SALES}
          line={line}
          items={items}
          itemsCount={itemsCount}
          onValueChange={onItemChange}
        />
      </LineTableCell>
      <LineTableCell sx={{ width: "5rem" }}>
        <RowDecimalField
          name="quantity"
          dataIndex={rowIndex}
          value={line.quantity || ""}
          onValueBlur={onQuantityChange}
        />
      </LineTableCell>
      <LineTableCell sx={{ width: "6rem", backgroundColor: "staticTableCell" }}>
        <RowStaticMoney
          decimalPlaces={DEFAULT_DECIMALS_UNROUNDED}
          value={line.cost}
        />
      </LineTableCell>
      <LineTableCell sx={{ width: "6rem", backgroundColor: "staticTableCell" }}>
        <RowStaticMoney
          decimalPlaces={DEFAULT_DECIMALS_UNROUNDED}
          value={
            line.cost && !(line.cost instanceof Loading)
              ? line.cost.times(line.quantity)
              : line.cost
          }
        />
      </LineTableCell>
      {lineType === "outputs" && (
        <LineWaste value={line.waste} onValueChange={onValueChange} />
      )}
    </TableRow>
  );
}
