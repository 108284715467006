import { Dialog } from "@mui/material";

import { CustomerVendorHistory } from "components/ListPage/List/Row/History/CustomerVendorHistory";
import { GenericHistory } from "components/ListPage/List/Row/History/GenericHistory";
import { ItemHistory } from "components/ListPage/List/Row/History/Item/ItemHistory";
import { SerialLotHistory } from "components/ListPage/List/Row/History/SerialLotHistory";

const HISTORY_PAGES = {
  customer: { component: CustomerVendorHistory, size: "lg" },
  deleteditem: { component: ItemHistory, size: "xl" },
  item: { component: ItemHistory, size: "xl" },
  lot: { component: SerialLotHistory, size: "lg" },
  serial: { component: SerialLotHistory, size: "lg" },
  vendor: { component: CustomerVendorHistory, size: "lg" },
};

const sx = {
  "& .MuiDialog-paper": {
    minHeight: "30vh",
    maxHeight: "93vh",
    "@media print": { minHeight: "100%", maxHeight: "100%" },
  },
};

export function HistoryDialog(props) {
  const { onClose, objectType, id, identifierText } = props;

  // transactions use the History component, exceptions are mapped in
  // HISTORY_PAGES
  const HistoryPage = HISTORY_PAGES[objectType]?.component || GenericHistory;

  const maxWidth = HISTORY_PAGES[objectType]?.size || "md";

  return (
    <Dialog open={true} onClose={onClose} maxWidth={maxWidth} fullWidth sx={sx}>
      <HistoryPage
        onClose={onClose}
        objectType={objectType}
        id={id}
        identifierText={identifierText}
      />
    </Dialog>
  );
}
