import { yyyymmddToDate } from "services/utility/dates";

export function afterGet(record) {
  const newRecord = { ...record };

  newRecord.closingDate = record.closingDate
    ? yyyymmddToDate(record.closingDate)
    : null;

  return newRecord;
}
