import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { Box, Card, Button, CardContent, Typography } from "@mui/material";

import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FormErrors } from "components/utility/FormErrors";

import { i18n } from "services/i18nService";
import { contactUs } from "services/sosInventoryService/sosApi";

import { useErrors } from "hooks/useErrors";

import { isValid } from "views/ContactUs/isValid";
import { initialErrorState } from "views/common";

import { openAlert } from "globalState/alertSlice";

const sx = {
  display: "grid",
  minWidth: "1070px",
  m: 2,
  mb: 0,
  "& a": { color: "primaryLink" },
};

export function ContactUs() {
  const userEmail = useSelector(
    (state) => state.userCompanySettings.user.email
  );

  const dispatch = useDispatch();
  const { errors, setErrors, isInError } = useErrors();

  const [record, setRecord] = useState({ subject: null, question: null });
  const [showConfirmation, setShowConfirmation] = useState(false);

  async function submit() {
    const errors = isValid(record);
    if (errors.messages.length) {
      setErrors(errors);
      return;
    }
    const { success, message } = await contactUs(record);
    dispatch(
      openAlert({
        type: success ? "success" : "error",
        message: success ? i18n("contactUs.Success") : message,
      })
    );
    if (success) {
      setRecord({ subject: null, question: null });
      setErrors(initialErrorState());
      setShowConfirmation(true);
    }
  }

  return (
    <>
      <Box sx={sx}>
        <Box mb={1}>
          <Typography variant="h2">{i18n("contactUs.header")}</Typography>
        </Box>
        <Card variant="outlined" sx={{ maxWidth: 750 }}>
          <CardContent sx={{ "&:last-child": { pb: 1 } }}>
            <Typography sx={{ pb: 1 }}>{i18n("contactUs.1")}</Typography>
            <Typography sx={{ pb: 1 }}>
              {i18n("contactUs.2.1")}{" "}
              <a href="mailto:support@sosinventory.com">
                support@sosinventory.com
              </a>{" "}
              {i18n("contactUs.2.2")}
            </Typography>
            <Typography sx={{ pb: 1 }}>{i18n("contactUs.3")}</Typography>
            <Typography sx={{ pb: 1, fontWeight: "bold" }}>
              {`${i18n("contactUs.WeWillReplyTo")} ${userEmail}.`}
            </Typography>

            <FormErrors errors={errors} setErrors={setErrors} />
            {showConfirmation ? (
              <Box sx={{ mt: 1 }}>
                <Typography variant="h4">
                  {i18n("contactUs.Confirmation")}
                </Typography>
                <Button
                  sx={{ mt: 1 }}
                  onClick={() => setShowConfirmation(false)}
                >
                  {i18n("contactUs.SubmitAnotherTicket")}
                </Button>
              </Box>
            ) : (
              <Box sx={{ mt: 1 }}>
                <div style={{ maxWidth: "30rem" }}>
                  <FrmTextField
                    label={i18n("frmLabel.Subject")}
                    onValueBlur={(_, subject) =>
                      setRecord((prev) => ({ ...prev, subject }))
                    }
                    value={record.subject}
                    name="subject"
                    error={isInError("subject")}
                  />
                </div>
                <FrmResizeTextField
                  minRows={4}
                  name="question"
                  label={i18n("frmLabel.Question")}
                  onValueBlur={(_, question) =>
                    setRecord((prev) => ({ ...prev, question }))
                  }
                  width="95%"
                  value={record.question}
                  error={isInError("question")}
                />
                <Box sx={{ mt: 1, textAlign: "right" }}>
                  <Button color="secondary" onClick={submit}>
                    {i18n("button.Submit")}
                  </Button>
                </Box>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
