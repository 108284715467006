export function afterGet(record) {
  const newRecord = {
    ...record,
    rentalReturnNumber: {
      prefix: record.prefixRentalReturn,
      nextNumber: record.nextRentalReturnNumber,
    },
  };

  delete newRecord.prefixRentalReturn;
  delete newRecord.nextRentalReturnNumber;

  return newRecord;
}
