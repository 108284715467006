import { memo } from "react";

import { CellContents } from "components/ListPage/List/CellContents";
import { LineCell } from "components/formFields/LineCell";

function Cell_(props) {
  const {
    row,
    column,
    columnIndex,
    objectType,
    customFieldDefinitions,
    stickyColumns,
    setShowRefund,
    setShowAddDocument,
  } = props;

  return (
    <LineCell key={columnIndex} column={column} stickyColumns={stickyColumns}>
      <CellContents
        objectType={objectType}
        row={row}
        column={column}
        customFieldDefinitions={customFieldDefinitions}
        setShowRefund={setShowRefund}
        setShowAddDocument={setShowAddDocument}
      />
    </LineCell>
  );
}

export const Cell = memo(Cell_);
