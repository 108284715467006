import { useDispatch } from "react-redux";

import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";

import { SortableColumnHeader } from "components/SortableColumnHeader";
import { TableCell } from "components/TableCell";
import { LineItemText } from "components/formFields/LineItemText";
import { LinkText } from "components/utility/LinkText";

import { i18n } from "services/i18nService";
import { formatDateTimeToDate } from "services/utility/formatting";

import { openModal } from "globalState/modalSlice";

import { getObjectFromTypeString } from "appConstants";

export function List(props) {
  const { history, retrievalParams, setRetrievalParams } = props;
  const dispatch = useDispatch();

  function getHistoryString(type) {
    const { fullString } = getObjectFromTypeString(type);
    return i18n(`objectType.${fullString}.Sentence`);
  }

  function handleColumnHeaderClick(sortName) {
    const newDirection =
      sortName === retrievalParams.sort
        ? retrievalParams.direction === "asc"
          ? "desc"
          : "asc"
        : "asc";
    setRetrievalParams((prevParams) => ({
      ...prevParams,
      sort: sortName,
      direction: newDirection,
      start: 1,
    }));
  }

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ verticalAlign: "bottom" }}>
            <SortableColumnHeader label={i18n("columns.Date")} />
            <SortableColumnHeader
              isSortable
              label={i18n("columns.Event")}
              sortName="type"
              activeSortName={retrievalParams.sort}
              direction={retrievalParams.direction}
              onClick={handleColumnHeaderClick}
            />
            <SortableColumnHeader
              isSortable
              label={i18n("columns.ReferenceNumber")}
              sortName="refNumber"
              activeSortName={retrievalParams.sort}
              direction={retrievalParams.direction}
              onClick={handleColumnHeaderClick}
            />
            <SortableColumnHeader
              align="right"
              label={i18n("columns.Qty")}
              maxWidth="8rem"
            />
            <SortableColumnHeader
              label={i18n("columns.Customer")}
              maxWidth="8rem"
            />
            <SortableColumnHeader
              label={i18n("columns.FromLocation")}
              sortName="fromLocation"
              activeSortName={retrievalParams.sort}
              direction={retrievalParams.direction}
              onClick={handleColumnHeaderClick}
              isSortable
            />
            <SortableColumnHeader
              label={i18n("columns.ToLocation")}
              sortName="toLocation"
              activeSortName={retrievalParams.sort}
              direction={retrievalParams.direction}
              onClick={handleColumnHeaderClick}
              isSortable
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((h, index) => (
            <TableRow key={index}>
              <TableCell>
                <LineItemText value={formatDateTimeToDate(h.txnDate)} />
              </TableCell>
              <TableCell>
                <LineItemText value={getHistoryString(h.type)} />
              </TableCell>
              <TableCell>
                <LinkText
                  onClick={() =>
                    dispatch(
                      openModal({
                        objectType: getObjectFromTypeString(h.type).fullString,
                        modalProps: { id: h.id },
                      })
                    )
                  }
                >
                  {h.refNumber || `[${h.id}]`}
                </LinkText>
              </TableCell>
              <TableCell>
                <LineItemText decimal value={h.quantity} />
              </TableCell>
              <TableCell>
                <LineItemText value={h.contactName} />
              </TableCell>
              <TableCell>
                <LineItemText value={h.fromLocation.name} />
              </TableCell>
              <TableCell>
                <LineItemText value={h.toLocation.name} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
