import { transformFields } from "services/utility/afterGet";

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["sortOrder"],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export function afterGet(record) {
  const newRecord = transformFields(record, FIELDS_TO_TRANSFORM);
  return newRecord;
}
