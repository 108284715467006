import { useEffect, useRef } from "react";

import { Box } from "@mui/material";

import { FormError } from "components/utility/FormError";

import { scrollTo } from "services/utility/misc";

export function FormErrors(props) {
  const { errors, setErrors } = props;
  const { messages } = errors;

  const errorRef = useRef();

  useEffect(() => {
    if (messages?.length) {
      scrollTo(errorRef);
    }
  }, [messages]);

  if (!messages?.length) {
    return null;
  }

  return (
    <Box width="75%" ref={errorRef}>
      <FormError
        message={messages.join(" ")}
        clear={() => setErrors((prev) => ({ ...prev, messages: [] }))}
      />
    </Box>
  );
}
