import { INACTIVITY_TIMEOUT_OPTIONS } from "services/sosInventoryService/settings/company/general/schema";

export function beforeSave(record) {
  const newRecord = { ...record };

  newRecord.inactivityTimeoutMinutes = INACTIVITY_TIMEOUT_OPTIONS.find(
    ({ value }) => value === record.inactivityTimeoutMinutes
  )?.id;

  newRecord.defaultTimeZone = record.defaultTimeZone.id;

  return newRecord;
}
