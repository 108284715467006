import { useState } from "react";
import { useSelector } from "react-redux";

import { Settings, Logout, List } from "@mui/icons-material";
import { Typography, MenuList, Box } from "@mui/material";

import { Divider } from "components/Divider";
import { DropDownMenu } from "components/TopNav/DropDownMenu";
import { MenuItemLink } from "components/TopNav/MenuItemLink";
import { MenuListItemText } from "components/TopNav/MenuListItemText";
import { NavListItemIcon } from "components/TopNav/NavListItemIcon";
import { TopNavAvatar } from "components/TopNav/TopNavAvatar";
import { WarningModal } from "components/utility/WarningModal";

import { i18n } from "services/i18nService";
import { logout } from "services/utility/authentication";

import { UserSettings } from "views/UserSettings/UserSettings";

export function UserMenu() {
  const dirtyPage = useSelector((state) => state.dirtyPage);

  const user = useSelector((state) => state.userCompanySettings.user);

  const [anchorUser, setAnchorUser] = useState(null);
  const [exiting, setExiting] = useState(false);
  const [userSettingsOpen, setUserSettingsOpen] = useState(false);

  const openUserMenu = Boolean(anchorUser);
  const userMenuId = openUserMenu ? "user-menu" : undefined;

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
    }

    setAnchorUser(null);
  }

  function logoutUser() {
    setAnchorUser(null);
    logout();
  }

  return (
    <>
      <WarningModal
        open={exiting}
        title={i18n("global.LeaveThisPageHeader")}
        message={i18n("global.LeaveThisPage")}
        onOk={logoutUser}
        onClose={() => setExiting(false)}
      />
      <TopNavAvatar
        name={user.name}
        altText={i18n("iconAltText.user")}
        onClick={(e) => setAnchorUser(e.currentTarget)}
        dataTesting="userMenu"
      />

      <DropDownMenu
        id={userMenuId}
        open={openUserMenu}
        anchorEl={anchorUser}
        onClose={() => setAnchorUser(null)}
      >
        <Box>
          <Typography sx={{ px: 1, pt: 1, fontWeight: "bold" }}>
            {user.name}
          </Typography>
          <Typography sx={{ px: 1, pt: 0.5, pb: 0.25 }}>
            {user.email}
          </Typography>
          <MenuList
            id="help-list-grow"
            onKeyDown={handleListKeyDown}
            sx={{ px: 1 }}
          >
            <Divider />
            <MenuItemLink to="/task" target="_blank">
              <NavListItemIcon>
                <List />
              </NavListItemIcon>
              <MenuListItemText
                primary={i18n("topNav.userMenu.tasks")}
                onClick={() => setAnchorUser(null)}
              />
            </MenuItemLink>
            <MenuItemLink>
              <NavListItemIcon>
                <Settings />
              </NavListItemIcon>
              <MenuListItemText
                primary={i18n("topNav.userMenu.userSettings")}
                onClick={() => setUserSettingsOpen(true)}
              />
            </MenuItemLink>

            <MenuItemLink to="/home/usersettings" target="_blank">
              <NavListItemIcon>
                <Logout />
              </NavListItemIcon>
              <MenuListItemText
                primary={i18n("topNav.userMenu.signOut")}
                onClick={() => (dirtyPage ? setExiting(true) : logoutUser())}
                data-testing="logout"
              />
            </MenuItemLink>
          </MenuList>
        </Box>
      </DropDownMenu>
      {userSettingsOpen && (
        <UserSettings onClose={() => setUserSettingsOpen(false)} />
      )}
    </>
  );
}
