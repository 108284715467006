import { useState } from "react";

import { Card, CardContent } from "@mui/material";

import { ActionButton } from "components/ActionButton";
import { FrmSelectScalar } from "components/formFields/FrmSelectScalar";
import { LocationToggle } from "components/formFields/address/LocationToggle";
import { CardHeader } from "components/formFields/formCards/CardHeader";

import { i18n } from "services/i18nService";

const defaultOption = i18n("global.Default");

export function ShippingAddress(props) {
  const { value, locations, handleInputFieldChange, label, setToAddress } =
    props;

  const [location, setLocation] = useState({ name: defaultOption });

  function handleOptionChange(name, value) {
    if (value) {
      const location = locations.find((location) => location.name === value);
      handleInputFieldChange(name, location);
    }
    setLocation({ name: value });
  }

  return (
    <Card variant="outlined" data-testing="shipping">
      <CardContent>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CardHeader text={label} gutterBottom={false} />
          <div style={{ flexGrow: 1, paddingLeft: "1rem" }}>
            <FrmSelectScalar
              disableClearable
              name="shipping"
              label={i18n("global.Address")}
              value={location.name}
              onValueChange={handleOptionChange}
              options={
                locations ? locations.map(({ name }) => name) : [defaultOption]
              }
            />
          </div>
          <ActionButton
            sx={{ marginTop: "0.3em" }}
            headerButton
            onClick={() => handleInputFieldChange("shipping", setToAddress)}
            dataTesting="setToOtherAddress"
          >
            {i18n("customer.SameAsBilling")}
          </ActionButton>
        </div>
        <LocationToggle
          value={value}
          addressType="shipping"
          handleInputFieldChange={handleInputFieldChange}
        />
      </CardContent>
    </Card>
  );
}
