import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ITEM_CALCULATED_FIELDS } from "appConfig";
import {
  REFERENCE_FIELDS,
  REFERENCE_ENTITY_MAP,
  NEW_RECORD_SKELETON,
} from "appConfig";
import { IN_TRANSACTION } from "appConfig";

import { getItemRecord } from "services/sosInventoryService/domainLogic";
import {
  getRecord,
  getRecordFrom,
  disassemble,
  getRecordFromLine,
} from "services/sosInventoryService/sosApi";
import { reconcileCustomFields } from "services/utility/customFields";
import { setPageDirty } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";
import { filterAndFormatBinOptions } from "services/utility/misc";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { OBJECT_TYPES } from "appConstants";
import { EMPTY_INPUT_LINE_ITEM } from "editConfig";

export function useInputOutputTransaction(
  objectType,
  id,
  setRecord,
  setRelatedRecords,
  outputLineHandler,
  inputLineHandler,
  transactionCustomFieldDefs,
  newFromObject,
  newFromObjectTypeLine,
  newFromId,
  setErrors,
  disassembleId
) {
  const getEmptyRecord = NEW_RECORD_SKELETON[objectType];

  const dispatch = useDispatch();

  // SETTINGS

  const defaultLocation = useSelector(
    (state) => state.userCompanySettings.settings.defaultLocation,
    (prev, curr) => curr?.id === prev?.id
  );
  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );
  const autoSerialLotNumbers = useSelector(
    (state) => state.userCompanySettings.settings.autoSerialLotNumbers
  );

  useEffect(() => {
    if (!transactionCustomFieldDefs) {
      return;
    }
    if (id || newFromObject || newFromObjectTypeLine || disassembleId) {
      async function getTransaction() {
        dispatch(editModalLoadingIndicatorOn());

        let record;
        try {
          if (id) {
            record = await getRecord(objectType, id);
          } else if (disassembleId) {
            // it's a dissasemble line action from builds list
            record = await disassemble(disassembleId);
          } else if (newFromObjectTypeLine) {
            // its a "populate from line" request
            record = await getRecordFromLine(
              objectType,
              newFromObjectTypeLine,
              newFromId
            );
            if (!record.inputs.length) {
              record.inputs = new Array(numLinesToAdd).fill(
                EMPTY_INPUT_LINE_ITEM[objectType]
              );
            }
          } else {
            // it's a "populate from" request
            record = await getRecordFrom(objectType, newFromObject, newFromId);

            delete record.id;
          }
        } catch (error) {
          return setLoadError(error);
        }

        record.customFields = reconcileCustomFields(
          transactionCustomFieldDefs,
          record.customFields
        );

        const outputs = record.outputs;
        const inputs = record.inputs;

        delete record.outputs;
        delete record.inputs;

        setRecord(record);
        outputLineHandler({ type: "set", lines: outputs });
        inputLineHandler({ type: "set", lines: inputs });
        dispatch(editModalLoadingIndicatorOff());

        // get related inventory items, if applicable; note that this equates
        // the presence of an "item" property (with an id) with there being a
        // related inventory item
        const itemPromises = [];
        for (let i = 0; i < outputs.length; i++) {
          const output = outputs[i];
          if (output.item?.id) {
            itemPromises.push(
              getItemRecord(
                output.item.id,
                record.location?.id,
                record.date,
                ITEM_CALCULATED_FIELDS[objectType]
              )
            );
          }
        }

        for (let i = 0; i < inputs.length; i++) {
          const input = inputs[i];
          if (input.item?.id) {
            itemPromises.push(
              getItemRecord(
                input.item.id,
                record.location?.id,
                record.date,
                ITEM_CALCULATED_FIELDS[objectType]
              )
            );
          }
        }

        const inventoryItems = await Promise.all(itemPromises);

        for (let i = 0; i < outputs.length; i++) {
          const output = outputs[i];
          const item = inventoryItems.find(
            (item) => output.item && output.item.id === item.id
          );
          if (item) {
            output.relatedRecords = { item };
            output.onhand = item.onhand;
            output.onSO = item.onSO;
          }
        }

        for (let i = 0; i < inputs.length; i++) {
          const input = inputs[i];
          const item = inventoryItems.find(
            (item) => input.item && input.item.id === item.id
          );
          if (item) {
            //set available bins only for inputs
            input.availableBins = filterAndFormatBinOptions(item.locationBins);
            input.onhand = item.onhand;
            input.relatedRecords = { ...input.relatedRecords, item };
          }
        }

        outputLineHandler({ type: "set", lines: outputs });
        inputLineHandler({ type: "set", lines: inputs });

        REFERENCE_FIELDS[objectType].forEach(async (field) => {
          if (record[field]) {
            let relatedRecord;
            try {
              relatedRecord = await getRecord(
                REFERENCE_ENTITY_MAP[field]
                  ? REFERENCE_ENTITY_MAP[field]
                  : field,
                record[field].id,
                IN_TRANSACTION
              );
              setRelatedRecords((prevRelatedRecords) => ({
                ...prevRelatedRecords,
                [field]: relatedRecord,
              }));
            } catch (e) {
              handleProgramError(e);
            }
          }
        });
      }
      getTransaction();
    } else {
      // new record
      const emptyRecord = getEmptyRecord({ autoSerialLotNumbers });
      async function createNewTransaction() {
        const record = {
          ...emptyRecord,
          customFields: reconcileCustomFields(transactionCustomFieldDefs, []),
        };

        const inputs = record.inputs;
        const outputs = record.outputs;
        delete record.inputs;
        delete record.outputs;

        // set location based on settings
        let defaultTransactionLocation = defaultLocation || null;
        record.location = defaultTransactionLocation;

        // if we have a location, need to add it to relatedRecords
        if (defaultTransactionLocation) {
          try {
            const location = await getRecord(
              "location",
              record.location.id,
              IN_TRANSACTION
            );
            const relatedRecords = {
              location: emptyRecord.hasOwnProperty("location")
                ? location
                : undefined,
            };
            setRelatedRecords((prevRelatedRecords) => ({
              ...prevRelatedRecords,
              ...relatedRecords,
            }));
          } catch (e) {
            handleProgramError(e);
          }
        }

        setRecord(record);
        outputLineHandler({ type: "set", lines: outputs });
        inputLineHandler({ type: "set", lines: inputs });
        setPageDirty();
        outputLineHandler({
          type: "appendEmpty",
          lineType: "outputs",
          numLinesToAdd:
            objectType === OBJECT_TYPES.BUILD.fullString ? 1 : numLinesToAdd,
          objectType: objectType,
        });
        inputLineHandler({
          type: "appendEmpty",
          lineType: "inputs",
          numLinesToAdd: numLinesToAdd,
          objectType: objectType,
        });
      }
      createNewTransaction();
      setPageDirty(false);
    }

    function setLoadError(error) {
      setErrors((prev) => ({
        ...prev,
        entity: [...prev.entity, "record"],
        messages: [error.message],
      }));
      return dispatch(editModalLoadingIndicatorOff());
    }
  }, [
    id,
    newFromId,
    disassembleId,
    objectType,
    dispatch,
    getEmptyRecord,
    setErrors,
    setRecord,
    setRelatedRecords,
    inputLineHandler,
    outputLineHandler,
    transactionCustomFieldDefs,
    newFromObject,
    newFromObjectTypeLine,
    numLinesToAdd,
    defaultLocation,
    autoSerialLotNumbers,
  ]);
}
