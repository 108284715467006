import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { FrmTextField } from "components/formFields/FrmTextField";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

export function QuickAdd(props) {
  const {
    title,
    onClose,
    onValueChange,
    onQuickAdd,
    setFull,
    name,
    ctaText,
    label,
    ...unexpected
  } = props;
  checkForUnexpectedProps("QuickAdd", unexpected);

  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FrmTextField label={label} value={name} onValueBlur={onValueChange} />
        <VSpace space={2} />
        <Button variant="outlined" onClick={() => setFull(true)}>
          {ctaText}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {i18n("button.Cancel")}
        </Button>
        <Button color="secondary" data-testing="addButton" onClick={onQuickAdd}>
          {i18n("button.Add")}
        </Button>
      </DialogActions>
    </>
  );
}
