import { Decimal } from "classes/DecimalClasses";

import {
  afterTouchLine as baseAfterTouchLine,
  updateWeightAndVolume,
  totalWeight,
  weightUnit,
} from "services/utility/afterTouchLine";

// when this field (the key) changes, we should run the (value) list of
// updater functions
const UPDATERS = {
  quantity: [updateWeightAndVolume],
  uom: [updateWeightAndVolume],
};

export function afterTouchLine(line, changedField) {
  return baseAfterTouchLine(UPDATERS, line, changedField);
}

export const ZERO_TOTALS = {
  quantity: new Decimal(0),
  weight: new Decimal(0),
  volume: new Decimal(0),
};

export function updateTotals(lines, setTotals) {
  if (!lines || lines.length === 0) {
    setTotals(ZERO_TOTALS);
    return;
  }

  const weight = totalWeight(lines);
  const weightunit = weightUnit(lines);

  const volume = lines.reduce(
    (total, line) => total.plus(line.volume),
    new Decimal(0)
  );

  const quantity = lines.reduce(
    (total, line) => total.plus(line.quantity),
    new Decimal(0)
  );

  setTotals({ quantity, weight, weightunit, volume, volumeunit: "cbm" });
}
