// TODO: This doesn't handle currency values with adornment(s) (like "$" prefix);
// it may need to at some point.
import { Typography } from "@mui/material";

import { formatMoney } from "services/utility/formatting";
import { formatNumber } from "services/utility/formatting";
import { checkForUnexpectedProps } from "services/utility/misc";

export function LineItemText(props) {
  const {
    value,
    decimal,
    money,
    allowBlank,
    decimalPlaces = 1,
    rightJustified,
    dataTesting,
    variant,
    sx,
    ...unexpected
  } = props;
  checkForUnexpectedProps("LineItemText", unexpected);

  // hard-coded to 1 decimal place; add decimals to the field definitions
  // and honor that here, if needed

  function getValue() {
    if (decimal) {
      return formatNumber(value, decimalPlaces, allowBlank);
    } else if (money) {
      return formatMoney(value);
    }
    return value;
  }

  return (
    <Typography
      align={decimal || money || rightJustified ? "right" : "left"}
      sx={{
        p: "0 0.75rem",
        whiteSpace: "nowrap",
        fontSize: "0.8125rem",
        ...sx,
      }}
      variant={variant}
      data-testing={dataTesting}
    >
      {getValue()}
    </Typography>
  );
}
