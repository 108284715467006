import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box, Typography } from "@mui/material";

import { Layout } from "Layout";

import { NEW_RECORD_SKELETON } from "appConfig";

import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmAddFile } from "components/formFields/frm/FrmAddFile";
import { FrmCheckboxWithHoverHelp } from "components/formFields/frm/FrmCheckboxWithHoverHelp";
import { FadedHR } from "components/html/FadedHR";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { getRecord } from "services/sosInventoryService/sosApi";
import { isAttachableFile } from "services/utility/files";

import { useDocumentObjectLists } from "hooks/useDocumentObjectLists";
import { useErrors } from "hooks/useErrors";
import { useRecord } from "hooks/useRecord";

import { LinkTable } from "views/Documents/Document/LinkTable";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

export function Document(props) {
  const { objectType, id, onClose } = props;

  const dispatch = useDispatch();

  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();

  const loadingState = useSelector(({ loading }) => loading.editModal);
  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  const { objectLists, addObjectList, addInitialObjectLists } =
    useDocumentObjectLists();

  useEffect(() => {
    async function _getRecord() {
      if (id) {
        dispatch(editModalLoadingIndicatorOn());
        const response = await getRecord(objectType, id);
        setRecord(response);
        addInitialObjectLists(response.lines);
        dispatch(editModalLoadingIndicatorOff());
      } else {
        const emptyRecord = NEW_RECORD_SKELETON[objectType]({ numLinesToAdd });
        setRecord(emptyRecord);
      }
    }
    _getRecord();
  }, [
    setRecord,
    id,
    objectType,
    dispatch,
    numLinesToAdd,
    addInitialObjectLists,
  ]);

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={objectType}
        lines={record?.lines}
        setErrors={setErrors}
        text={record?.name || ""}
        handleClose={onClose}
      />

      {loadingState && <Loading />}

      {record && (
        <ObjectEditDialogContent>
          <Layout
            pageTitle={
              id
                ? `${i18n(`objectType.${objectType}.Sentence`)} ${
                    record?.name || ""
                  }`
                : i18n(`objectType.${objectType}.New`)
            }
          >
            <Box flexGrow="1" m={2} mb={0}>
              <FormErrors errors={errors} setErrors={setErrors} />
              {record ? (
                <Box>
                  <FrmFieldGrid gridAutoFlow="dense">
                    <div style={{ gridColumn: "1 / 2" }}>
                      <FrmResizeTextField
                        name="description"
                        value={record.description}
                        label={i18n("frmLabel.Description")}
                        minRows={2}
                        width="100%"
                        onValueBlur={handleInputFieldChange}
                      />
                    </div>
                    <div style={{ gridColumn: "1 / 2" }}>
                      <FrmCheckboxWithHoverHelp
                        name="canShare"
                        value={record.canShare}
                        onValueChange={handleInputFieldChange}
                        label={i18n("frmLabel.Share")}
                        helpText={i18n("hoverHelp.Share")}
                      />
                    </div>
                    <div style={{ gridColumn: "1 / 2" }}>
                      <FrmAddFile
                        record={record}
                        setRecord={setRecord}
                        setErrors={setErrors}
                        error={isInError("content")}
                        validator={isAttachableFile}
                        highlightFileName
                      />
                    </div>
                  </FrmFieldGrid>
                  <VSpace space={2} />
                  <Typography variant="h6">
                    {i18n("frmLabel.AttachedTo")}
                  </Typography>
                  <FadedHR />
                  <LinkTable
                    errors={errors}
                    objectLists={objectLists}
                    addObjectList={addObjectList}
                    lines={record.lines}
                    setRecord={setRecord}
                  />
                </Box>
              ) : (
                !isInError("record") && (
                  <FixedLoadingIndicator
                    text={`${i18n("global.Loading")}...`}
                  />
                )
              )}
            </Box>
            <VSpace space={4} />
          </Layout>
        </ObjectEditDialogContent>
      )}
    </>
  );
}
