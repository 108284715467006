import { useState } from "react";

import {
  Info,
  List,
  Help,
  ImportContacts,
  Mail,
  MenuBook,
  NewReleases,
  PhoneIphone,
  Public,
  ThumbUp,
  YouTube,
  HelpCenter,
} from "@mui/icons-material";
import { MenuList } from "@mui/material";

import { Divider } from "components/Divider";
import { DropDownMenu } from "components/TopNav/DropDownMenu";
import { MenuItemLink } from "components/TopNav/MenuItemLink";
import { MenuListItemText } from "components/TopNav/MenuListItemText";
import { NavListItemIcon } from "components/TopNav/NavListItemIcon";
import { TopNavIcon } from "components/TopNav/TopNavIcon";

import { i18n } from "services/i18nService";

export function HelpMenu() {
  const [anchor, setAnchor] = useState(null);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
    }

    setAnchor(null);
  }
  const openMenu = Boolean(anchor);
  const menuId = openMenu ? "help-menu" : undefined;

  return (
    <>
      <TopNavIcon
        image="/images/help-support.svg"
        onClick={(e) => setAnchor(e.currentTarget)}
        functionText={i18n("iconAltText.help")}
      />
      <DropDownMenu
        id={menuId}
        open={openMenu}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
      >
        <MenuList
          id="help-list-grow"
          onKeyDown={handleListKeyDown}
          sx={{ px: 1 }}
        >
          <MenuItemLink
            to="https://help.sosinventory.com/"
            target="_blank"
            onClick={() => setAnchor(null)}
          >
            <NavListItemIcon>
              <HelpCenter />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.helpMenu.helpCenter")} />
          </MenuItemLink>
          <MenuItemLink
            to="https://www.sosinventory.com/sos-inventory-users-guide"
            target="_blank"
            onClick={() => setAnchor(null)}
          >
            <NavListItemIcon>
              <MenuBook />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.helpMenu.userGuide")} />
          </MenuItemLink>
          <Divider />
          <MenuItemLink
            to="https://www.sosinventory.com/faqs"
            target="_blank"
            onClick={() => setAnchor(null)}
          >
            <NavListItemIcon>
              <Help />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.helpMenu.faq")} />
          </MenuItemLink>
          <MenuItemLink
            to="https://www.sosinventory.com/videos"
            target="_blank"
            onClick={() => setAnchor(null)}
          >
            <NavListItemIcon>
              <YouTube />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.helpMenu.videos")} />
          </MenuItemLink>
          <MenuItemLink
            to="https://www.sosinventory.com/how-tos"
            target="_blank"
            onClick={() => setAnchor(null)}
          >
            <NavListItemIcon>
              <List />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.helpMenu.howTo")} />
          </MenuItemLink>
          <MenuItemLink
            to="https://www.sosinventory.com/customertraining"
            target="_blank"
            onClick={() => setAnchor(null)}
          >
            <NavListItemIcon>
              <ImportContacts />
            </NavListItemIcon>
            <MenuListItemText
              primary={i18n("topNav.helpMenu.customerTraining")}
            />
          </MenuItemLink>
          <Divider />
          <MenuItemLink to="/help/whatsnew" onClick={() => setAnchor(null)}>
            <NavListItemIcon>
              <NewReleases />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.helpMenu.new")} />
          </MenuItemLink>
          <MenuItemLink to="/help/uservoice" onClick={() => setAnchor(null)}>
            <NavListItemIcon>
              <ThumbUp />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.helpMenu.features")} />
          </MenuItemLink>
          <MenuItemLink
            to="https://status.sosinventory.com/"
            target="_blank"
            onClick={() => setAnchor(null)}
          >
            <NavListItemIcon>
              <Public />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.helpMenu.status")} />
          </MenuItemLink>
          <Divider />
          <MenuItemLink to="/home/mobile" onClick={() => setAnchor(null)}>
            <NavListItemIcon>
              <PhoneIphone />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.helpMenu.mobile")} />
          </MenuItemLink>
          <Divider />
          <MenuItemLink to="/help/contact" onClick={() => setAnchor(null)}>
            <NavListItemIcon>
              <Mail />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.helpMenu.contact")} />
          </MenuItemLink>
          <MenuItemLink to="/about" onClick={() => setAnchor(null)}>
            <NavListItemIcon>
              <Info />
            </NavListItemIcon>
            <MenuListItemText primary={i18n("topNav.helpMenu.about")} />
          </MenuItemLink>
        </MenuList>
      </DropDownMenu>
    </>
  );
}
