import { Button, ButtonGroup } from "@mui/material";

import { GroupingBox } from "components/utility/GroupingBox";

export function FrmButtonGroup(props) {
  const { name, label, value, options, onValueChange, error, disabled } = props;

  return (
    <GroupingBox
      disabled={disabled}
      heading={label}
      width="fit-content"
      error={error}
    >
      <ButtonGroup variant="outlined" sx={{ py: 0.5 }}>
        {options.map((option) => (
          <Button
            key={option.id}
            variant={option.id === value?.id ? "contained" : "outlined"}
            onClick={() => onValueChange(name, option)}
            disabled={disabled}
            size="small"
          >
            {option.name}
          </Button>
        ))}
      </ButtonGroup>
    </GroupingBox>
  );
}
