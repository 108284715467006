import { LineItemText } from "components/formFields/LineItemText";
import { LineTableCell } from "components/formFields/LineTableCell";

export function LineWeightAndWeightUnit(props) {
  const { weight, weightunit } = props;

  return (
    <LineTableCell sx={{ backgroundColor: "staticTableCell" }}>
      <LineItemText value={`${weight} ${weightunit || ""}`} rightJustified />
    </LineTableCell>
  );
}
