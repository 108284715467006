import { SETTINGS as builds } from "views/Configuration/settings/production/builds";
import { SETTINGS as jobs } from "views/Configuration/settings/production/jobs";
import { SETTINGS as processes } from "views/Configuration/settings/production/processes";
import { SETTINGS as workOrders } from "views/Configuration/settings/production/workOrders";

export const SETTINGS = {
  builds,
  jobs,
  processes,
  workOrders,
};
