import { useState } from "react";

import { Dialog } from "@mui/material";

import { QuickAdd } from "components/QuickAdd";

import { i18n } from "services/i18nService";

import { Item } from "views/Items/Item/Item";

const sx = {
  "& .MuiDialog-paper": { minHeight: "93vh", maxHeight: "93vh" },
};

export function QuickAddItem(props) {
  const { open, onAdd, onQuickAdd, onClose, initialNameValue } = props;

  const [itemName, setItemName] = useState(initialNameValue);
  const [full, setFull] = useState(false);

  const dialogOptions = full
    ? {
        maxWidth: "md",
        fullWidth: true,
        scroll: "paper",
        style: { height: "100%" },
        PaperProps: { style: { minHeight: "93vh", maxHeight: "93vh" } },
      }
    : {};

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={full ? sx : {}}
      data-testing="addNewItem"
      {...dialogOptions}
    >
      {full ? (
        <Item
          key={open}
          name={itemName}
          onAdd={onAdd}
          onClose={onClose}
          fromForm={true}
        />
      ) : (
        <QuickAdd
          onValueChange={(_, value) => setItemName(value)}
          setFull={setFull}
          title={i18n("global.AddNewItem")}
          onClose={onClose}
          name={itemName}
          onQuickAdd={() => onQuickAdd(itemName)}
          ctaText={i18n("global.AddMoreItem")}
          label={i18n("global.Name")}
        />
      )}
    </Dialog>
  );
}
