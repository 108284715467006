import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { HoverHelp } from "components/utility/HoverHelp";

import { checkForUnexpectedProps } from "services/utility/misc";

export function FrmSelectFromObjectsWithHoverHelp(props) {
  const { selectProps, helpText, ...unexpected } = props;
  checkForUnexpectedProps("FrmSelectReference", unexpected);

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 6 }}>
      <FrmSelectFromObjects {...selectProps} />
      <HoverHelp title={helpText} sx={{ marginTop: "0.3em" }} />
    </div>
  );
}
