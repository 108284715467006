import { IN_TRANSACTION } from "appConfig";

import { getRecord } from "services/sosInventoryService/sosApi";
import { getRecordFrom } from "services/sosInventoryService/sosApi";
import { getEmptyRecord } from "services/sosInventoryService/workOrder/schema";
import {
  copyCustomFieldValues,
  reconcileCustomFields,
  getCustomFieldDefinitions,
} from "services/utility/customFields";
import { getRelatedReferenceObjects } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";

import globalState from "globalState/globalState";

import { OBJECT_TYPES } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.WORK_ORDER.fullString;

export async function getTransaction(
  id,
  newFromId,
  newFromObjectType,
  updaters,
  customFieldDefs
) {
  let record, lines, relatedRecords;

  if (id || newFromObjectType) {
    if (id) {
      record = await getRecord(OBJECT_TYPE, id);
    } else {
      // it's a "populate from" request
      record = await getRecordFrom(OBJECT_TYPE, newFromObjectType, newFromId);
      delete record.id;
    }

    record.customFields = reconcileCustomFields(
      customFieldDefs,
      record.customFields
    );

    lines = record.lines;
    delete record.lines;

    updaters.lineHandler({ type: "set", lines });
    updaters.setRecord(record);

    relatedRecords = await getRelatedReferenceObjects(OBJECT_TYPE, record);

    updaters.setRelatedRecords(relatedRecords);
  } else {
    // new record
    const { settings } = globalState.getState().userCompanySettings;
    const defaultLocation =
      globalState.getState().userCompanySettings.settings.defaultLocation;
    const numLinesToAdd =
      globalState.getState().userCompanySettings.settings.numLinesToAdd;

    record = {
      ...getEmptyRecord(settings),
      customFields: reconcileCustomFields(customFieldDefs, []),
    };

    lines = Array(numLinesToAdd).fill(EMPTY_LINE_ITEM[OBJECT_TYPE]);

    relatedRecords = {};

    if (defaultLocation) {
      record.location = defaultLocation;

      const location = await getRecord(
        "location",
        record.location.id,
        IN_TRANSACTION
      );

      relatedRecords = { ...relatedRecords, location };
    }
    updaters.setRelatedRecords(relatedRecords);
    updaters.setRecord(record);
  }
  updaters.lineHandler({ type: "set", lines });
}

export async function updateTransaction(
  field,
  newValue,
  transactionCustomFieldDefs,
  currentState,
  updaters
) {
  const { record, lines, relatedRecords } = currentState;
  if (!record || !lines || !relatedRecords) {
    handleProgramError(new Error("update Transaction | invalid currentState"));
  }

  let newRecord, newLines, newRelatedRecords;

  switch (field) {
    case "date":
    case "number":
    case "location":
    case "customerNotes":
    case "channel":
    case "assignedToUser":
    case "priority":
    case "department":
    case "comment":
    case "customFields":
      newRecord = { ...record, [field]: newValue };
      updaters.setRecord(newRecord);

      break;

    case "customer":
      // if user de-selected customer, all we need to do is set the
      // customer state and the customer field in the record to null
      if (!newValue) {
        newRecord = { ...record, customer: null };
        updaters.setRecord(newRecord);

        break;
      }

      // get the newly selected customer record
      const customer = await getRecord("customer", newValue.id, IN_TRANSACTION);

      const customerCustomFieldDefs = await getCustomFieldDefinitions(
        "customer"
      );

      // be sure there are custom field entries for each defined custom field
      // in the customer record
      const customerCustomFields = reconcileCustomFields(
        customerCustomFieldDefs,
        customer.customFields
      );

      // initialize any transaction custom fields to their matching customer
      // custom field values, if any
      const newTransactionCustomFields = copyCustomFieldValues(
        customerCustomFieldDefs,
        customerCustomFields,
        transactionCustomFieldDefs,
        record.customFields
      );

      newRecord = {
        ...record,
        customer: newValue,
        customerNotes: customer.notes,
        customFields: newTransactionCustomFields,
      };
      updaters.setRecord(newRecord);
      newRelatedRecords = { ...relatedRecords, customer };

      break;

    default:
      handleProgramError(
        new Error(`updateTransaction | unknown field (${field})`)
      );
  }

  if (newLines) {
    updaters.lineHandler({ type: "set", lines: newLines });
  }
  if (newRelatedRecords) {
    updaters.setRelatedRecords(newRelatedRecords);
  }
}
