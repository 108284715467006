import { IMAGE_DIMENSION } from "appConfig";

import {
  SUPPORTED_IMAGE_TYPES,
  SUPPORTED_LOGO_IMAGE_TYPES,
} from "appConstants";

export const imageTypeMatcher = new RegExp(
  `^image/(${SUPPORTED_IMAGE_TYPES.join("|")})`
);

export const logoImageTypeMatcher = new RegExp(
  `^image/(${SUPPORTED_LOGO_IMAGE_TYPES.join("|")})`
);

export async function getImageDimensions(file) {
  const img = new Image();
  img.src = URL.createObjectURL(file);
  await img.decode();
  return { width: img.naturalWidth, height: img.naturalHeight };
}

const FILE_READER = new FileReader();

/**
 * @name    imageBlobToBase64
 *
 * @summary given a BLOB that is presumed to represent an image (jpg, png,
 *          etc., encode it with Base64 so that it can be displayed in an
 *          image tag with data:image;base64
 *
 * @param   imageBlob - self-explanatory
 *
 * @param   setter - a function with the signature (base64string), that will
 *          receive the encoded BLOB
 *
 * @returns undefined
 */
export function imageBlobToBase64(imageBlob, setter) {
  FILE_READER.onload = binary2Ascii;
  FILE_READER.readAsBinaryString(imageBlob);

  function binary2Ascii(e) {
    const base64Image = window.btoa(e.target.result);
    setter(base64Image);
  }
}

/**
 * @name    readImage
 *
 * @summary given an input file spec of an image file, reads that image file
 *          and calls a callback function passed in, with that image as a
 *          base64 screen and an indication of scaling
 *
 * @param   imageFile - a file path and name on the user's local storage
 *
 * @param   imageProcessor - a function that will be called after the file
 *          is uploaded, signature is (imageAsBase64String, imageScaled),
 *          where imageAsBase64String is self-explanatory, and imageScaled
 *          is a Boolean, indicating whether or not the image is exactly
 *          IMAGE_DIMENSION x IMAGE_DIMENSION; if it is, imageScaled will be
 *          false, otherwise it will be true
 *
 * @returns undefined
 */
export function readImage(imageFile, imageProcessor, excludeBmp) {
  const fileType = imageFile.type;
  const imageMatcher = excludeBmp ? logoImageTypeMatcher : imageTypeMatcher;
  if (!fileType.match(imageMatcher)) {
    return { success: false, unsupportedFileType: fileType };
  }
  FILE_READER.onload = async (e) => {
    const { height, width } = await getImageDimensions(imageFile);
    const imageAsBase64String = window.btoa(e.target.result);
    // the IMAGE_DIMENSION is expressed as a CSS string, in pixels, like
    // "200px"; the parseInt below turns it into a number
    const numericImgDimension = parseInt(IMAGE_DIMENSION);
    const imageScaled =
      numericImgDimension !== height || numericImgDimension !== width;
    imageProcessor(imageAsBase64String, imageScaled, fileType);
  };
  FILE_READER.readAsBinaryString(imageFile);
  return { success: true };
}

export function downloadImage(imageAsBase64String, downloadText) {
  const link = document.createElement("a");
  link.href = "data:image;base64," + imageAsBase64String;
  link.download = downloadText;
  link.click();
}

export function getImageType(mimeType) {
  const imageType = mimeType.split("/")[1];
  return imageType === "jpeg" ? "jpg" : imageType;
}
