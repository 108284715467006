import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  Dialog,
  DialogContentText,
  DialogActions,
  Button,
  Box,
} from "@mui/material";

import { i18n } from "services/i18nService";
import { mainLog, LOG_LEVEL } from "services/logService";
import { logout } from "services/utility/authentication";

import { closeAlert } from "globalState/alertSlice";
import globalState from "globalState/globalState";
import { closeModal } from "globalState/modalSlice";

import { MINUTES } from "appConstants";

const WARNING_TIME_DEFICIT = 1 * MINUTES;

var warnTimeout, logoutTimeout;
var warnHandler = () => {};
var logoutHandler = () => {};

const events = [
  "mousemove",
  "keydown",
  "wheel",
  "DOMMouseScroll",
  "mouseWheel",
  "mousedown",
  "touchstart",
  "touchmove",
  "MSPointerDown",
  "MSPointerMove",
  "visibilitychange",
];

function setEvents() {
  events.forEach((event) => {
    window.addEventListener(event, debounced(resetTimeout, 100));
  });
}

function destroyEvents() {
  clearTimeout();
  events.forEach((event) => {
    window.removeEventListener(event, debounced(resetTimeout, 100));
  });
}

function resetTimeout() {
  clearTimeouts();
  setTimeouts();
}

function clearTimeouts() {
  if (warnTimeout) clearTimeout(warnTimeout);
  if (logoutTimeout) clearTimeout(logoutTimeout);
}

function setTimeouts() {
  if (window.location.pathname.includes("/login")) {
    return;
  }
  const { inactivityTimeoutMinutes } =
    globalState.getState().userCompanySettings.settings;

  if (inactivityTimeoutMinutes > 0) {
    // if timeout is out of bounds - log error
    // TODO: Move to appConstants.js when setting page is implemented
    if (inactivityTimeoutMinutes < 5 || inactivityTimeoutMinutes > 480) {
      const error = new Error(
        `Inactivity timeout setting of ${inactivityTimeoutMinutes} is invalid`
      );
      mainLog(error, LOG_LEVEL.DEBUG);
    }
    const warnTime = inactivityTimeoutMinutes * MINUTES - WARNING_TIME_DEFICIT;
    const logoutTime = inactivityTimeoutMinutes * MINUTES;
    warnTimeout = setTimeout(warnHandler, warnTime);
    logoutTimeout = setTimeout(logoutHandler, logoutTime);
  }
}

function debounced(fn, delay) {
  let timerId;
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}

export function Timeout() {
  const dispatch = useDispatch();
  const [timeoutWarning, setTimeoutWarning] = useState(false);

  useEffect(() => {
    warnHandler = () => setTimeoutWarning(true);
    logoutHandler = handleLogout;
  });

  // idle timeout
  useEffect(() => {
    setEvents();
    return () => destroyEvents();
  });

  function handleLogout() {
    dispatch(closeModal());
    dispatch(closeAlert());
    setTimeoutWarning(false);
    logout();
  }
  return (
    <Dialog open={timeoutWarning} onClose={() => setTimeoutWarning(false)}>
      <Box m={2}>
        <DialogContentText>{i18n("login.TimeoutMessage")}</DialogContentText>
        <DialogActions>
          <Button onClick={handleLogout} variant="contained">
            {i18n("login.Logout")}
          </Button>
          <Button
            onClick={() => setTimeoutWarning(false)}
            variant="contained"
            autoFocus
          >
            {i18n("login.Stay")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
