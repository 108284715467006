function getTypes(typeArr) {
  const namesTypesArray = typeArr.map((typeName, index) => [typeName, index]);
  return Object.fromEntries(namesTypesArray);
}
const TYPE_NAMES = [
  "text",
  "number",
  "decimal",
  "money",
  "date",
  "boolean",
  "reference",
  "arrayOfReferences",
  "inventoryItem",
  "linkedTransaction",
  "itemField",
];
export const TYPES = getTypes(TYPE_NAMES);

export const starColumn = {
  name: "starred",
  fixed: true,
  heading: "",
  maxWidth: "1.5rem",
  excludeFromColumnList: true,
  starredField: true,
  sortable: true,
};
