import { Typography } from "@mui/material";

export function LinkText(props) {
  const { children, onClick, disabled, dataTesting } = props;
  return (
    <Typography
      onClick={onClick}
      disabled={disabled}
      sx={{
        color: "primaryLink",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      component="span"
      data-testing={dataTesting}
    >
      {children}
    </Typography>
  );
}
