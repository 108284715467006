export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefixJob: record.jobNumber.prefix,
    nextJobNumber: record.jobNumber.nextNumber,
  };

  delete newRecord.jobNumber;

  return newRecord;
}
