import { i18n } from "services/i18nService";
import { formatLastSync, formatDecimal } from "services/utility/formatting";

export const DASHBOARD_CONFIG = [
  {
    label: i18n("global.Sync"),
    widgets: [
      {
        type: "lastSync",
        setting: "showLastSync",
        label: i18n("dashboard.lastSync"),
        isDateTime: true,
        formatFunc: formatLastSync,
        linkUrl: "/syncitem",
      },
      {
        type: "txnsToSend",
        setting: "showTxnsToSend",
        label: i18n("dashboard.txnsToSend"),
        formatFunc: formatDecimal,
        linkUrl: "/syncitem",
      },
      {
        type: "showTxnsSent",
        setting: "showTxnsSent",
        label: i18n("dashboard.showTxnsSent"),
        formatFunc: formatDecimal,
        linkUrl: "/syncitem",
      },
    ],
  },
  {
    label: i18n("global.Sales"),
    widgets: [
      {
        type: "pendingEstimates",
        setting: "showPendingEstimates",
        label: i18n("dashboard.pendingEstimates"),
        formatFunc: formatDecimal,
        linkUrl: "/estimate?status=Pending",
      },
      {
        type: "openSos",
        setting: "showOpenSOs",
        label: i18n("dashboard.openSos"),
        formatFunc: formatDecimal,
        linkUrl: "/report/OpenSalesOrderDetail",
      },
      {
        type: "openSrs",
        setting: "showOpenSRs",
        label: i18n("dashboard.openSrs"),
        formatFunc: formatDecimal,
        linkUrl: "/report/OpenSalesReceiptDetail",
      },
      {
        type: "readyToShip",
        setting: "showReadyToShip",
        label: i18n("dashboard.readyToShip"),
        formatFunc: formatDecimal,
        linkUrl: "/report/ReadyToPickShip",
      },
      {
        type: "readyToInvoice",
        setting: "showReadyToInvoice",
        label: i18n("dashboard.readyToInvoice"),
        formatFunc: formatDecimal,
        linkUrl: "/report/ReadyToInvoice",
      },
    ],
  },
  {
    label: i18n("global.OtherTransactions"),
    widgets: [
      {
        type: "openPos",
        setting: "showOpenPOs",
        label: i18n("dashboard.openPos"),
        formatFunc: formatDecimal,
        linkUrl: "/report/OpenPurchaseOrderDetail",
      },
      {
        type: "openWos",
        setting: "showOpenWOs",
        planIncludesSetting: (_, pro) => pro,
        label: i18n("dashboard.openWos"),
        formatFunc: formatDecimal,
        linkUrl: "/report/OpenWorkOrderDetail",
      },
      {
        type: "openRmas",
        setting: "showOpenRMAs",
        planIncludesSetting: (plus, pro) => plus || pro,
        label: i18n("dashboard.openRmas"),
        formatFunc: formatDecimal,
        linkUrl: "/rma?status=open",
      },
      {
        type: "showRentalsPastDue",
        setting: "showRentalsPastDue",
        planIncludesSetting: (plus, pro) => plus || pro,
        label: i18n("dashboard.showRentalsPastDue"),
        formatFunc: formatDecimal,
        linkUrl: "/report/OpenRentalDetail",
      },
    ],
  },
];
