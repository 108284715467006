import { theme } from "SosTheme";

export function Chipper(props) {
  const { children, onClick, disabled, dataTesting } = props;

  return (
    <div
      onClick={onClick}
      style={{
        color: disabled
          ? theme.palette.Chipper.disabled.color
          : theme.palette.Chipper.basic.color,
        backgroundColor: disabled
          ? theme.palette.Chipper.disabled.backgroundColor
          : theme.palette.Chipper.basic.backgroundColor,
        marginTop: "0.4em",
        padding: "0.1em 0.6em",
        borderRadius: "1.0em",
        overflowWrap: "anywhere",
        display: "table",
        cursor: disabled ? "default" : "pointer",
      }}
      data-testing={dataTesting}
    >
      {children}
    </div>
  );
}
