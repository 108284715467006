export function afterGet(record) {
  const newRecord = {
    ...record,
    itemReceiptNumber: {
      prefix: record.prefixItemReceipt,
      nextNumber: record.nextItemReceiptNumber,
    },
  };

  delete newRecord.prefixItemReceipt;
  delete newRecord.nextItemReceiptNumber;

  return newRecord;
}
