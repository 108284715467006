import { useParams } from "react-router-dom";

import { Layout } from "Layout";

import { ListPage } from "components/ListPage/ListPage";

import { i18n } from "services/i18nService";

import { useEditModal } from "hooks/useEditModal";

import { OBJECT_TYPES } from "appConstants";

const objectType = OBJECT_TYPES.TASK.fullString;

export function TaskList() {
  const { id } = useParams();

  useEditModal(objectType, id);

  return (
    <Layout pageTitle={i18n(`objectType.${objectType}.SentencePlural`)}>
      <ListPage objectType={objectType} />
    </Layout>
  );
}
