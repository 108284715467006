import { useSelector } from "react-redux";

import { Box, Typography } from "@mui/material";

import { FrmButtonGroup } from "components/formFields/FrmButtonGroup";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmCheckbox } from "components/formFields/frm/FrmCheckbox";
import { FrmCheckboxWithHoverHelp } from "components/formFields/frm/FrmCheckboxWithHoverHelp";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

import { DateFilter } from "views/Reports/Settings/Date";
import { Switch } from "views/Reports/Settings/Switch";
import { SUB_COMPONENT } from "views/Reports/reportConstants";

export function Options(props) {
  const {
    options,
    setOptions,
    reportOptions,
    includePaymentDates,
    ...unexpected
  } = props;
  checkForUnexpectedProps("Options", unexpected);

  function handleValueChange(fieldName, newValue) {
    setOptions((prev) => ({ ...prev, [fieldName]: newValue }));
  }

  return (
    <Box>
      <Typography variant="h5" color="primaryText" fontWeight="Bold">
        {i18n("reports.WidgetText.Options")}
      </Typography>
      <VSpace space={1} />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {reportOptions.map((option, index) => {
          return (
            <Option
              key={index}
              option={option}
              options={options}
              handleValueChange={handleValueChange}
              includePaymentDates={includePaymentDates}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export function Option(props) {
  const { option, options, handleValueChange, includePaymentDates } = props;
  const { label, component, apiName, helpText, macroApiName, datePrefix } =
    option;
  const paymentsBoarded = useSelector(
    (state) => state.userCompanySettings.settings.paymentsBoarded
  );

  switch (component) {
    case SUB_COMPONENT:
      return null;
    case "PaymentDateFilter":
      // for reports that include payment data options,
      // only include these params when the account
      // is payments boarded, and the user has opted
      // to only show the corresponding data that
      // has been invoiced or paid
      if (paymentsBoarded && options.showFullOnly && includePaymentDates) {
        return (
          <DateFilter
            macroValue={options[macroApiName]}
            dateValue={options[apiName]}
            onValueChange={handleValueChange}
            label={label}
            name={apiName}
            macroName={macroApiName}
            oneYearMax={options.oneYearMax}
            datePrefix={datePrefix}
          />
        );
      }
      return null;
    case "FrmCheckboxWithHoverHelp":
      return (
        <FrmCheckboxWithHoverHelp
          label={label}
          name={apiName}
          value={options[apiName]}
          onValueChange={handleValueChange}
          helpText={helpText}
        />
      );
    case "FrmCheckbox":
      return (
        <FrmCheckbox
          label={label}
          name={apiName}
          value={options[apiName]}
          onValueChange={handleValueChange}
        />
      );
    case "FrmSelectFromObjects":
      return (
        <FrmSelectFromObjects
          label={label}
          name={apiName}
          options={option.options}
          value={options[apiName]}
          onValueChange={handleValueChange}
          disableClearable
        />
      );
    case "FrmTextField":
      return (
        <FrmTextField
          label={label}
          name={apiName}
          value={options[apiName]}
          onValueBlur={handleValueChange}
        />
      );
    case "Switch":
      return (
        <Switch
          checked={options[apiName].id === option.options[1].id}
          name={apiName}
          onChange={handleValueChange}
          label={label}
          options={option.options}
        />
      );
    case "FrmButtonGroup":
      return (
        <FrmButtonGroup
          label={label}
          name={apiName}
          options={option.options}
          value={options[apiName]}
          onValueChange={handleValueChange}
        />
      );
    default:
      throw new Error(`Option component ${component} not supported in Options`);
  }
}
