import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { useRelated } from "hooks/useRelated";

import { OBJECT_TYPES } from "appConstants";

export function PriceTierFilter(props) {
  const { value, onValueChange, name, label } = props;

  const priceTiers = useRelated(OBJECT_TYPES.PRICE_TIER.fullString);

  return (
    <FrmSelectFromObjects
      name={name}
      label={label}
      options={priceTiers}
      value={value}
      onValueChange={onValueChange}
    />
  );
}
