export function afterGet(record) {
  const newRecord = {
    ...record,
    estimateNumber: {
      prefix: record.prefixEstimate,
      nextNumber: record.nextEstimateNumber,
    },
  };

  delete newRecord.prefixEstimate;
  delete newRecord.nextEstimateNumber;

  return newRecord;
}
