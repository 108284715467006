import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useSalesReps } from "hooks/useSalesReps";

export function FrmSalesRep(props) {
  const { value, onValueChange, maxWidth } = props;

  const salesReps = useSalesReps();

  return (
    <FrmSelectFromObjects
      name="salesRep"
      label={i18n("frmLabel.SalesRep")}
      value={value}
      onValueChange={onValueChange}
      options={salesReps}
      maxWidth={maxWidth}
      dataTesting="salesRep"
    />
  );
}
