import ArrowForward from "@mui/icons-material/ArrowForward";
import { Box } from "@mui/material";

export function InsertLineItem(props) {
  const { onClick } = props;

  return (
    <Box
      sx={{
        position: "absolute",
        right: "100%",
        bottom: "calc(100% - 0.9rem)",
        width: "1.3em",
        cursor: "pointer",
        zIndex: "insertLine",
      }}
      onClick={onClick}
      data-testing="insertLineItem"
      title="Insert line"
    >
      <ArrowForward sx={{ color: "icons" }} />
    </Box>
  );
}
