import { i18n } from "services/i18nService";

import { LOT_TRACE_OPTIONS } from "views/Reports/reportConstants";

export const LotTraceability = {
  userHasSufficientPrivilege: (p) =>
    p.viewInventory || p.viewItems || p.editItems,
  planIncludesReport: (plus) => plus,
  customFieldObjectTypes: ["lot", "item"],
  columns: [
    { apiName: "Lot", default: true },
    { apiName: "Item", default: true },
    { apiName: "Description" },
    { apiName: "Item Description" },
    { apiName: "Expiration" },
    { apiName: "Recalled" },
    { apiName: "SKU" },
  ],
  requiredFiltersNotSetMsg: i18n("reports.LotFilterRequired"),
  settings: [
    {
      label: i18n("filter.Lot"),
      component: "LotFilter",
      apiName: "item",
      defaultValue: null,
      required: true,
      type: "filter",
    },
    {
      label: i18n("filter.CameFromOrWentTo"),
      component: "Switch",
      apiName: "txnType",
      defaultValue: LOT_TRACE_OPTIONS[0],
      options: LOT_TRACE_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "suppressIfEmpty",
      defaultValue: false,
      type: "static",
    },
  ],
};
