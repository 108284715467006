// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.

export const FIELDS_TO_TRANSFORM = {
  WhereToBuy: {
    DECIMAL_FIELDS: [
      "leadTime",
      "reorderLevel",
      "maxStockLevel",
      "onHand",
      "available",
      "needed",
    ],
    MONEY_FIELDS: ["price"],
    DATE_FIELDS: [],
    DATETIME_FIELDS: [],
    NON_UTC_DATETIME_FIELDS: [],
  },
  ReconcileItemReceipts: {
    DECIMAL_FIELDS: ["diff", "quantity"],
    MONEY_FIELDS: ["acctValue", "sosValue"],
    DATE_FIELDS: ["date"],
    DATETIME_FIELDS: [],
    NON_UTC_DATETIME_FIELDS: [],
  },
  ReconcileVendorCredits: {
    DECIMAL_FIELDS: ["diff", "quantity"],
    MONEY_FIELDS: ["acctValue", "sosValue"],
    DATE_FIELDS: ["date"],
    DATETIME_FIELDS: [],
    NON_UTC_DATETIME_FIELDS: [],
  },
  ReconcileJournalEntries: {
    DECIMAL_FIELDS: ["diff", "quantity"],
    MONEY_FIELDS: ["acctValue", "sosValue"],
    DATE_FIELDS: ["date"],
    DATETIME_FIELDS: [],
    NON_UTC_DATETIME_FIELDS: [],
  },
  ReorderReport: {
    DECIMAL_FIELDS: [
      "leadTime",
      "reorderLevel",
      "maxStockLevel",
      "available",
      "needed",
      "onHand",
      "onSr",
      "onSo",
      "onPo",
      "onRma",
      "onWo",
      "onRental",
    ],
    MONEY_FIELDS: ["salesPrice", "purchaseCost"],
    DATE_FIELDS: [],
    DATETIME_FIELDS: [],
    NON_UTC_DATETIME_FIELDS: [],
  },
  InventorySummary: {
    DECIMAL_FIELDS: [
      "available",
      "leadTime",
      "maxStock",
      "onHand",
      "onPo",
      "onRental",
      "onRma",
      "onSo",
      "onSr",
      "onWo",
      "reorderPoint",
    ],
    MONEY_FIELDS: ["purchaseCost", "salesPrice"],
    DATE_FIELDS: [],
    DATETIME_FIELDS: [],
    NON_UTC_DATETIME_FIELDS: [],
  },
};
