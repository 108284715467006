import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmTextFieldWithHoverHelp } from "components/formFields/FrmTextFieldWithHoverHelp";
import { FrmCategory } from "components/formFields/frm/FrmCategory";
import { FrmCustomField } from "components/formFields/frm/FrmCustomField";
import { FrmLocation } from "components/formFields/frm/FrmLocation";

import { i18n } from "services/i18nService";
import {
  ALERT_TYPES,
  ALERT_ON_OPTIONS,
  LOW_INV_LOT_EXP_OPTIONS,
} from "services/sosInventoryService/alert/schema";

import { getObjectFromTypeString } from "appConstants";

export function AlertType(props) {
  const { record, onValueChange, handleScalarChange, disabled, isInError } =
    props;

  function onCustomFieldValueChange(_, value) {
    onValueChange("customField", { ...record.customField, value });
  }

  function onCustomFieldChange(_, { name, id }) {
    onValueChange("customField", { ...record.customField, name, id });
  }

  function onCustomFieldTypeChange(_, { id }) {
    onValueChange("customField", { ...record.customField, type: id });
  }

  function getSubFields(alertType) {
    switch (alertType) {
      case "Bill Due":
      case "Invoice Past Due":
      case "SalesReceiptDue":
      case "SalesOrderDue":
      case "TaskDue":
        return (
          <FrmTextFieldWithHoverHelp
            name="dateCompare"
            label={i18n("frmLabel.DaysBeforeDueDate")}
            value={record.dateCompare}
            onValueBlur={onValueChange}
            helpText={i18n("hoverHelp.alert.Max30Min30")}
            disabled={disabled}
          />
        );
      case "CustomField":
        return (
          <>
            <FrmCustomField
              value={record.customField}
              onValueChange={onCustomFieldChange}
              disabled={disabled}
              error={isInError("customFieldField")}
            />
            <FrmSelectFromObjects
              name="type"
              label={i18n("frmLabel.AlertOn")}
              value={
                record.customField?.type
                  ? {
                      id: record.customField?.type,
                      name: i18n(
                        `objectType.${
                          getObjectFromTypeString(record.customField?.type)
                            .fullString
                        }.SentencePlural`
                      ),
                    }
                  : null
              }
              onValueChange={onCustomFieldTypeChange}
              options={ALERT_ON_OPTIONS}
              disabled={disabled}
              error={isInError("customFieldType")}
              disableClearable
            />
            <FrmTextFieldWithHoverHelp
              name="value"
              label={i18n("frmLabel.ComparisonValue")}
              value={record.customField?.value}
              onValueBlur={onCustomFieldValueChange}
              helpText={i18n("hoverHelp.alert.EnterAValueToCompare")}
              disabled={disabled}
            />
          </>
        );
      case "LotExpiration":
      case "Low Inventory":
        return (
          <>
            <FrmLocation
              disableClearable={false}
              value={record.location}
              onValueChange={onValueChange}
              disabled={disabled}
              placeholder={i18n("alert.DefaultIsAllLocations")}
            />
            <FrmCategory
              value={record.category}
              onValueChange={onValueChange}
              disabled={disabled}
              placeholder={i18n("alert.DefaultIsAllCategories")}
            />
            {alertType === "Low Inventory" && (
              <FrmSelectFromObjects
                name="lowInventoryCalculation"
                label={i18n("frmLabel.Calculation")}
                value={
                  record.lowInventoryCalculation
                    ? { id: record.lowInventoryCalculation }
                    : null
                }
                onValueChange={handleScalarChange}
                options={LOW_INV_LOT_EXP_OPTIONS}
                disabled={disabled}
                error={isInError("lowInventoryCalculation")}
                disableClearable
              />
            )}
            <FrmTextField
              name="itemNameContains"
              label={i18n("frmLabel.ItemNameContains")}
              value={record.itemNameContains}
              onValueBlur={onValueChange}
              disabled={disabled}
            />
            {alertType === "LotExpiration" && (
              <FrmTextFieldWithHoverHelp
                name="dateCompare"
                label={i18n("frmLabel.DaysPriorToExpiration")}
                value={record.dateCompare}
                onValueBlur={onValueChange}
                helpText={i18n("hoverHelp.alert.Max30Min30")}
                disabled={disabled}
              />
            )}
          </>
        );
      default:
    }
  }

  return (
    <>
      <FrmSelectFromObjects
        name="type"
        label={i18n("frmLabel.AlertType")}
        value={record.type ? { id: record.type } : null}
        onValueChange={handleScalarChange}
        options={ALERT_TYPES}
        disabled={disabled}
        disableClearable
      />
      {getSubFields(record.type)}
    </>
  );
}
