import { Money, Decimal } from "classes/DecimalClasses";

import { transformUoms } from "services/utility/afterGet";
import { transformJobWorkCenter } from "services/utility/jobWorkCenter";

import globalState from "globalState/globalState";

export function salesTransactionAfterGet(record) {
  const newRecord = { ...record };

  newRecord.number = record.number === "auto" ? "" : record.number;

  // date can be null on a "populateFrom" API call
  if (!newRecord.date) {
    newRecord.date = new Date();
  }

  if (!record.currency) {
    newRecord.currency =
      globalState.getState().userCompanySettings.settings.homeCurrencyRef;
  }

  // line items
  const newLines = [];
  record.lines.forEach((line) => {
    let newLine = { ...line };

    // add property for related records
    newLine.relatedRecords = {};

    newLine.jobWorkcenter = transformJobWorkCenter(line.job, line.workcenter);

    if (line.itemDetails) {
      newLine.itemDetails.itemUoms = transformUoms(line.itemDetails.itemUoms);

      newLine.itemDetails.baseSalesPrice = new Money(
        line.itemDetails.baseSalesPrice
      );
      newLine.itemDetails.markupPercent = new Decimal(
        line.itemDetails.markupPercent
      );
    }

    // extract the taxCode directly into the line item, so that it is easy to
    // maintain it like any other select field; it will get reformatted back
    // into the tax property on beforeSave
    if (line.tax) {
      newLine.taxCode = line.tax.taxCode;
      delete newLine.tax.taxCode;
    }

    // and add this line to the collection of new lines
    newLines.push(newLine);
  });
  // update the record with the new lines
  newRecord.lines = newLines;
  return newRecord;
}
