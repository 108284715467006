import { i18n } from "services/i18nService";
import { getDocument } from "services/sosInventoryService/sosApi";
import { formatDate } from "services/utility/dates";
import {
  extractName,
  formatMoney,
  formatDocumentInfo,
} from "services/utility/formatting";

export const creditMemoConfig = {
  columns: [
    {
      heading: i18n("columns.Customer"),
      apiName: "customer",
      sortable: true,
      formatFunc: extractName,
    },
    {
      heading: i18n("columns.MemoDate"),
      apiName: "date",
      sortable: true,
      defaultSort: true,
      sortDirection: "desc",
      formatFunc: formatDate,
    },
    {
      heading: i18n("columns.ReferenceNumber"),
      apiName: "refNumber",
      sortable: true,
    },
    {
      heading: i18n("columns.Amount"),
      apiName: "amount",
      sortable: true,
      formatFunc: formatMoney,
      align: "right",
    },
    {
      heading: i18n("columns.Documents"),
      apiName: "documents",
      formatFunc: formatDocumentInfo,
      apiFunc: getDocument,
      isDocument: true,
      maxWidth: "12rem",
    },
  ],
};
