import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Typography } from "@mui/material";

import { Loading } from "classes/Loading";

import { LineSkeleton } from "components/formFields/LineSkeleton";

import { currencyMinMaxFractionDigits } from "services/utility/formatting";

const DEFAULT_STYLE = {
  padding: "0 0.3rem",
  whiteSpace: "nowrap",
  fontSize: "0.8125rem",
};

// TODO: This doesn't handle currency values with adornment(s) (like "$" prefix);
// it may need to at some point.
export function RowStaticMoney(props) {
  const { name, value: initialValue, style, decimalPlaces } = props;
  const [value, setValue] = useState(initialValue);

  const currencyMinimumFractionDigits = useSelector(
    (state) =>
      state.userCompanySettings.localization.currencyMinimumFractionDigits
  );

  useEffect(() => {
    if (!(initialValue instanceof Loading)) {
      setValue(
        currencyMinMaxFractionDigits(
          initialValue,
          currencyMinimumFractionDigits,
          decimalPlaces || currencyMinimumFractionDigits,
          false
        )
      );
    }
  }, [initialValue, decimalPlaces, currencyMinimumFractionDigits]);

  if (initialValue instanceof Loading) {
    return <LineSkeleton />;
  }

  return (
    <Typography name={name} align="right" sx={style || DEFAULT_STYLE}>
      {value.toString()}
    </Typography>
  );
}
