import { useEffect, useState } from "react";

import { RowSelectBase } from "components/formFields/row/RowSelectBase";

import { checkForUnexpectedProps } from "services/utility/misc";

import PropTypes from "prop-types";

export function RowSelectReference(props) {
  const {
    name,
    options: initialOptions,
    optionDisplayText = "name",
    dataIndex,
    onValueChange,
    value,
    disableClearable,
    dataTesting,
    error,
    ...unexpected
  } = props;
  checkForUnexpectedProps("RowSelectReference", unexpected);

  const [options, setOptions] = useState(initialOptions);

  useEffect(() => {
    setOptions(
      initialOptions
        ? initialOptions.sort((a, b) => (a.name < b.name ? -1 : 1))
        : initialOptions
    );
  }, [initialOptions]);

  // this gets called whenever the user changes the selected option
  function handleChange(_, newValue, reason) {
    const value = reason === "clear" ? null : newValue;
    onValueChange(name, value, dataIndex);
  }

  // this gets called when the component needs to know what to display
  // in the input field; not the value, which is the item id, but the
  // human-friendly *name* of the item
  function getOptionLabel(option) {
    // option or option.id can be blank on a newly inserted line;
    // and if we're adding a new item we don't want any text in the
    // component
    if (option[optionDisplayText]) {
      return option[optionDisplayText];
    }
    if (!options) {
      return "";
    }
    const matchInOptions = options.find(({ id }) => id === option.id);
    return matchInOptions ? matchInOptions[optionDisplayText] : "";
  }

  // this gets called for each option, when the selected option changes, to
  // determine which, of all the options, was selected
  function isOptionEqualToValue(option, value) {
    if (!option || !value) {
      return false;
    }
    return value.id === option.id || (value === "" && option.id === "");
  }

  function renderOption(props, option) {
    return (
      <li {...props} key={option.id}>
        <div style={{ minHeight: "1.5em" }}>{option[optionDisplayText]}</div>
      </li>
    );
  }

  return (
    <RowSelectBase
      renderOption={renderOption}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      onValueChange={handleChange}
      options={options}
      disableClearable={disableClearable}
      value={value}
      error={error}
      dataTesting={dataTesting}
    />
  );
}

RowSelectReference.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  onValueChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  optionDisplayText: PropTypes.string,
  value: PropTypes.object,
  error: PropTypes.bool,
  disableClearable: PropTypes.bool,
  disabled: PropTypes.bool,
};
