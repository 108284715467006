import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { setPageDirty } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";
import { formatBinInfo } from "services/utility/formatting";

import globalState from "globalState/globalState";
import {
  LEFT_NAV_TEXT,
  LIST_TABLE_OFFSET_MAX,
  LIST_TABLE_OFFSET_MIN,
} from "globalState/leftNavSlice";

import { theme } from "SosTheme";
import {
  getObjectFromFullString,
  MIN_SUPPORTED_PAGE_SIZE,
  TOP_NAV_BAR_HEIGHT,
  ITEM_QUICKLIST_CASES,
  ITEM_TYPES,
  CONFIGURATION_PANEL1_WIDTH,
  CONFIGURATION_PANEL_2_WIDTH,
  CONFIGURATION_BAR_HEIGHT,
} from "appConstants";

export function scrollTo(el) {
  el &&
    el.current &&
    el.current.scrollIntoView({ behavior: "smooth", block: "center" });
}

export function isZero(str) {
  return Number(str) === 0;
}

export function splitArrByAmt(array, size) {
  const localArray = [...array];
  const results = [];
  while (localArray.length) {
    results.push(localArray.splice(0, size));
  }
  return results;
}

export function generateRandomString(length) {
  return Math.random()
    .toString(36)
    .substring(2, length + 2);
}

/**
 * @name    getFullValueFromOptions
 * @summary 
 * Takes incomplete @value (i.e. {name: "Default"}) and returns the full option (i.e. {name: "Default", id: 2}) 
 * from the options array  that matches it
 * 
 * Flow order for matching is:
 * (1) returns @value unchanged, if both its @optionText and @optionId are defined/present 
 * (2) match @value to @options array based off @optionText
 * (3) match @value to @options array based off @optionId
 *
 * @value (object)
 * The value to be compared against the @options
 *
 * @options (array) 
 * Array of objects to be compared against the supplied @value

 * @optionText (string)
 * First object key value that gets checked against @options
 *
 * @optionId (string)
 * Second objecy key value that gets checked against the @options 
 */

export function getFullValueFromOptions(value, options, optionText, optionId) {
  if (value && value[optionId] && value[optionText]) {
    return value;
  } else if (value && value[optionText]) {
    return options.filter(({ name }) => value && value[optionText] === name)[0];
  } else {
    return options.filter(({ id }) => value && value[optionId] === id)[0];
  }
}

// V8BACK: v8 sets these values as "No" and "Yes" so this conversion is needed currently for list api
// Can be updated to be set the list settings as closed or open when v8 hits end of life
export function showClosedToStatus(showClosed) {
  if (showClosed === "Yes") {
    return "closed";
  }
  if (showClosed === "No") {
    return "open";
  }
  return "";
}

export function isAddressEmpty(value) {
  if (!value) {
    return true;
  }
  const { address = {}, company, contact, phone, email } = value;
  const contactValues = [company, contact, phone, email];
  const hasContactValues = contactValues.some((ele) => ele);
  const hasAddressValues = Object.values(address).some((item) => item);
  return !hasContactValues && !hasAddressValues;
}

export function getEmailBody(objectType) {
  const settings = globalState.getState().userCompanySettings.settings;
  const object = getObjectFromFullString(objectType);
  const emailBody = object?.emailBody;
  if (!emailBody) {
    return "";
  }
  if (!settings.hasOwnProperty(emailBody)) {
    throw new Error(`${emailBody} not found within company settings`);
  }
  return settings[emailBody];
}

export function isNonZeroDecimalOrMoney(value) {
  if (!value) {
    return false;
  }
  if (!(value instanceof Decimal) && !(value instanceof Money)) {
    throw new Error(`${value} is neither Money nor Decimal class`);
  }
  return !value.eq(Decimal.ZERO);
}

export function calculateMarginPercent(cost, price, quantity) {
  if (isNonZeroDecimalOrMoney(cost) && isNonZeroDecimalOrMoney(quantity)) {
    const unitCost = cost.div(quantity);
    return price.minus(unitCost).div(unitCost).times(new Decimal(100));
  }
  return undefined;
}

export function checkForUnexpectedProps(componentName, unexpected) {
  if (Object.keys(unexpected).length > 0) {
    const unexpectedKeysAndValues = Object.entries(unexpected);
    const stringifiedKeysAndValues = unexpectedKeysAndValues.map(
      (kv) => `${kv[0]}: ${kv[1]}`
    );
    handleProgramError(
      new Error(
        `${componentName} | Unexpected props: ${stringifiedKeysAndValues.join(
          ", "
        )}`
      )
    );
  }
}

export function filterAndFormatBinOptions(locationBins) {
  const { preventNegativeInBins } =
    globalState.getState().userCompanySettings.settings;
  if (!preventNegativeInBins) {
    return formatBinInfo(locationBins);
  }

  const binsWithInventory = locationBins.filter((lb) =>
    lb.quantity.gt(new Decimal(0))
  );
  return formatBinInfo(binsWithInventory);
}

export function getPercentFromAmount(amount, total) {
  if (!isNonZeroDecimalOrMoney(total)) {
    return undefined;
  }
  return new Decimal(1)
    .minus(total.minus(amount).div(total))
    .times(new Decimal(100));
}

export function getAmountFromPercent(percent, total) {
  return percent.times(total).times(new Decimal(0.01));
}

/*
Determines whether we are dealing with a record 
that has previously been persisted to the database or not, 
by detecting whether the record ID exists.
*/
export function isPersistedRecord(recordId) {
  return Boolean(recordId);
}

export function getCardType(cardTypeCode) {
  switch (cardTypeCode) {
    case 1:
      return "Visa";
    case 2:
      return "Mastercard";
    case 3:
      return "Discover";
    case 4:
      return "American Express";
    case 5:
      return "Personal Checking";
    case 6:
      return "Personal Savings";
    case 7:
      return "Business Checking";
    case 8:
      return "Business Savings";
    default:
      handleProgramError(
        new Error(`unexpected param for getCardType: ${cardTypeCode}`)
      );
  }
}

export function getConfigurationListPageTableWidth() {
  const listMargin = theme.spacing(4);
  return `calc(100vw - ${CONFIGURATION_PANEL1_WIDTH} - ${CONFIGURATION_PANEL_2_WIDTH} - ${listMargin})`;
}

// takes a minimum supported width of 1280, and calculates
// the minimum allowed size of any list table accounting
// for left nav and margin sizing
export function getMinListPageWidth() {
  const iconsText = globalState.getState().leftNav.iconsText;
  const listMargin = theme.spacing(5.5); // added extra 1.5 to account for unknown width
  const leftNavState =
    iconsText === LEFT_NAV_TEXT ? LIST_TABLE_OFFSET_MAX : LIST_TABLE_OFFSET_MIN;
  return `calc(${MIN_SUPPORTED_PAGE_SIZE} - ${leftNavState}rem - ${listMargin})`;
}

// set height to 100% of screen minus left nav width
export function getPageWidth() {
  const iconsText = globalState.getState().leftNav.iconsText;
  const leftNavState =
    iconsText === LEFT_NAV_TEXT ? LIST_TABLE_OFFSET_MAX : LIST_TABLE_OFFSET_MIN;
  return `calc(100vw - ${leftNavState}rem)`;
}

// set height to 100% of screen minus panel 1 and panel 2
export function getConfigurationWidth() {
  return `calc(100vw - ${CONFIGURATION_PANEL1_WIDTH} - ${CONFIGURATION_PANEL_2_WIDTH})`;
}

// set height to 100% of screen minus topNav height
export function getPageHeight() {
  return `calc(100vh - ${TOP_NAV_BAR_HEIGHT})`;
}

// set height to 100% of screen minus topNav height and confirmation bar height
export function getConfigurationHeight() {
  return `calc(100vh - ${TOP_NAV_BAR_HEIGHT} - ${CONFIGURATION_BAR_HEIGHT})`;
}

// if this is a new record, and no number has been set, set the number
// field to "auto" to tell the back end to auto-generate a number
export function cleanTransactionNumber(id, number) {
  if (!id && !number?.trim().length) {
    return "auto";
  }
  return number;
}

const {
  SALES,
  PURCHASING,
  PRODUCTION,
  SERIAL,
  LOT,
  CATEGORY,
  ASSEMBLY_AND_KIT,
} = ITEM_QUICKLIST_CASES;
export function createItemQuickListFilters(type) {
  let filters;
  switch (type) {
    case SALES:
    case PRODUCTION:
    case PURCHASING: {
      filters = { formType: type };
      break;
    }
    case LOT: {
      filters = { lotTracked: true };
      break;
    }
    case SERIAL: {
      filters = { serialTracked: true };
      break;
    }
    case CATEGORY: {
      filters = { type: ITEM_TYPES.CATEGORY };
      break;
    }
    case ASSEMBLY_AND_KIT: {
      filters = { type: [ITEM_TYPES.ASSEMBLY, ITEM_TYPES.KIT] };
      break;
    }
    case ITEM_QUICKLIST_CASES.ALL: {
      filters = {};
      break;
    }
    default: {
      handleProgramError(
        new Error(
          `Unexpected value for type in createItemQuickListFilter (${type})`
        )
      );
      break;
    }
  }
  return filters;
}

export function dirtyFnCheck(dirtyFn) {
  const dirtyPage = globalState.getState().dirtyPage;
  if (!dirtyPage || window.confirm(i18n("global.LeaveThisPage"))) {
    setPageDirty(false);
    dirtyFn();
  }
}

export const isBetaEnvironment = process.env.REACT_APP_ENVIRONMENT === "beta";
export const isInBetaMaintenanceMode =
  process.env.REACT_APP_BETA_MAINTENANCE_MODE === "true";
