import { Box } from "@mui/material";

import { Link } from "components/html/Link";

import { i18n } from "services/i18nService";

export function extractOutputData(output) {
  const { bin, lot, serials } = output;
  const mappedSerials = serials
    ? serials
        .map(({ name }) => {
          const serialLabel = i18n("frmLabel.SerialNumberAbbreviated");
          return `${serialLabel}: ${name}`;
        })
        .join("\n")
    : "";
  const serialValues = mappedSerials ? `${mappedSerials}\n` : "";
  const lotValue = lot ? `${i18n("frmLabel.Lot")}: ${lot.name}\n` : "";
  const binValue = bin ? `${i18n("frmLabel.Bin")}: ${bin.name}\n` : "";
  return `${serialValues}${lotValue}${binValue}`;
}

export function BuildOutput(props) {
  const { output } = props;
  const { item } = output;

  return (
    <div style={{ margin: "2rem 0" }}>
      <Box sx={{ display: "flex", gap: 0.5, flexWrap: "wrap", mb: 1 }}>
        <div style={{ fontWeight: "bold", textAlign: "right" }}>
          {i18n("global.Assembly")}:
        </div>
        <div>
          <Link to={`/item/${item.id}`}>{item.name}</Link>
          <div style={{ whiteSpace: "pre-wrap", lineHeight: 1 }}>
            {extractOutputData(output)}
          </div>
        </div>
      </Box>
      <Box sx={{ display: "flex", gap: 0.5, flexWrap: "wrap" }}>
        <div style={{ fontWeight: "bold", textAlign: "right" }}>
          {i18n("global.Quantity")}:
        </div>
        <div>{output.quantity.toString()}</div>
      </Box>
    </div>
  );
}
