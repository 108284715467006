// From https://www.regular-expressions.info/email.html
const EMAIL_MATCHER = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/;
export function isValidEmail(str) {
  return str ? EMAIL_MATCHER.test(str.trim().toUpperCase()) : false;
}

const INTEGER_STRING_MATCHER = /^[0-9]+$/;
export function isIntegerString(str) {
  return INTEGER_STRING_MATCHER.test(str);
}

// one or more email addresses, separated by commas
export function isOneOrMoreEmailAddresses(str) {
  if (!str) {
    return false;
  }
  const addresses = str.split(",");
  return addresses.every((address) => isValidEmail(address));
}

// insure that a string intended as a URL does not contain prohibited code;
// the line containing the UNSAFE_STRINGS will not pass eslint, because it
// prohibits "javacript:" in a string, so we have to disable eslint
// eslint-disable-next-line
const UNSAFE_STRINGS = ["onclick", "onerror", "<script", "javascript:"];
export function isSafeUrl(urlString) {
  if (!urlString) {
    return true;
  }
  if (UNSAFE_STRINGS.some((str) => urlString.toLowerCase().includes(str))) {
    return false;
  } else {
    return true;
  }
}
