import { useEffect, useState } from "react";

import { FrmFieldGrid } from "components/FrmFieldGrid";
import { BillingAddress } from "components/formFields/address/sales/Billing";
import { ShippingAddress } from "components/formFields/address/sales/Shipping";

import { getCustomerAddresses } from "services/sosInventoryService/salesTransaction/domainLogic";
import { checkForUnexpectedProps } from "services/utility/misc";

export function Addresses(props) {
  const {
    billing,
    shipping,
    customer,
    handleInputFieldChange,
    billingLabel,
    shippingLabel,
    ...unexpected
  } = props;
  checkForUnexpectedProps("Addresses", unexpected);

  const [addresses, setAddresses] = useState({});

  useEffect(() => {
    if (customer?.id) {
      setAddresses(getCustomerAddresses(customer));
    }
  }, [customer]);

  return (
    <FrmFieldGrid gridAutoFlow="dense">
      <div style={{ maxWidth: "30rem" }}>
        <BillingAddress
          locations={addresses.billing}
          value={billing}
          handleInputFieldChange={handleInputFieldChange}
          label={billingLabel}
        />
      </div>
      <div style={{ maxWidth: "30rem" }}>
        <ShippingAddress
          value={shipping}
          locations={addresses.shipping}
          setToAddress={billing}
          handleInputFieldChange={handleInputFieldChange}
          label={shippingLabel}
        />
      </div>
    </FrmFieldGrid>
  );
}
