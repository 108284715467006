import { ITEM_CALCULATED_FIELDS } from "appConfig";
import { IN_TRANSACTION } from "appConfig";

import { i18n } from "services/i18nService";
import { getDefaultBin } from "services/sosInventoryService/domainLogic";
import {
  getVendorItemInfo,
  getItemLocationSettings,
  postRecord,
} from "services/sosInventoryService/sosApi";
import { getByIds, getItemBom } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";
import { filterAndFormatBinOptions } from "services/utility/misc";
import { normalizeSerials } from "services/utility/serials";
import { getBaseUom } from "services/utility/uoms";

import globalState from "globalState/globalState";
import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { EMPTY_LINE_ITEM } from "editConfig";

/**
 * @name    getVendorItemCatalogInfo
 *
 * @summary given a vendor and an item (and the default data, if no catalog
 *          entry), this function looks in the vendor-item catalog for vendor-
 *          specific pricing (and related vendor product number) and returns
 *          the values for vendor part number and item cost that should be
 *          shown on purchasing line items
 *
 * @param   vendor (standard reference object)
 *
 * @param   itemId (number) - item id
 *
 * @param   itemBasePurchaseCost (Money) - the purchase cost in the item record,
 *          which will be used if there is no entry for this vendor in the
 *          vendor-item catalog
 *
 * @param   itemVendorPartNumber (string) - the vendor part number from the
 *          item record, which will be used if there is no entry for this
 *          vendor in the vendor-item catalog
 *
 * @returns an object containing the vendor part number and the purchase cost
 *          that should be used on the line item
 */
export async function getVendorItemCatalogInfo(
  vendor,
  itemId,
  itemBasePurchaseCost,
  itemVendorPartNumber = ""
) {
  if (!vendor) {
    return {
      vendorPartNumber: itemVendorPartNumber,
      itemCost: itemBasePurchaseCost,
    };
  }
  const vendorItemInfo = await getVendorItemInfo(vendor.id, itemId);
  // getVendorItemInfo returns an array; since we've only provided one
  // itemId, it will always be an array of 0 or 1 entries
  let vendorPartNumber = "";
  let itemCost = itemBasePurchaseCost;
  if (vendorItemInfo.length) {
    vendorPartNumber = vendorItemInfo[0].vendorPartNumber || "";
    itemCost = vendorItemInfo[0].price
      ? vendorItemInfo[0].price
      : itemBasePurchaseCost;
  }

  return { vendorPartNumber, itemCost };
}

export async function updateLineWithItem(item, line, record) {
  if (!item) {
    return line;
  }
  const { location, vendor, autoSerialLots } = record;
  const { vendorPartNumber, itemCost } = await getVendorItemCatalogInfo(
    vendor,
    item.id,
    item.basePurchaseCost,
    item.vendorPartNumber
  );

  // transfer the appropriate properties from the new inventory
  // item to the line item
  const newLine = {
    ...line,
    item,
    description: descriptionForPurchasingTransactions(item),
    weightunit: item.weightUnit,
    volumeunit: item.volumeUnit,
    vendorPartNumber: vendorPartNumber,
    unitprice: itemCost,
    class: item.class,
    uom: getBaseUom(item.uoms),
    serials: normalizeSerials(
      line.serials,
      line.quantity,
      item.serialTracking,
      autoSerialLots
    ),
    taxCode: item.purchaseTaxCode,
    relatedRecords: { ...line.relatedRecords, item },
    itemDetails: {
      itemWeight: item.weight,
      itemVolume: item.volume,
      itemUoms: item.uoms,
      serialTracking: item.serialTracking,
      lotTracking: item.lotTracking,
      purchaseCost: item.basePurchaseCost,
      type: item.type,
    },
  };

  // change the bin to the default for this item/location
  if (location?.id) {
    let itemLocation;
    const response = await getItemLocationSettings(item.id, location.id);
    if (response.success) {
      itemLocation = response.data;
    } else {
      handleProgramError(
        new Error(i18n("error.CouldNotRetrieveItemLocationSettings"))
      );
    }
    if (itemLocation) {
      newLine.bin = itemLocation.defaultBin;
    }
  }
  return newLine;
}

export function createPurchasingLineFromItem(item, emptyLineData, lineNumber) {
  return {
    ...emptyLineData,
    lineNumber,
    item: { id: item.id, name: item.name },
    class: item.class,
    onhand: item.onhand,
    description: descriptionForPurchasingTransactions(item),
    unitprice: item.basePurchaseCost,
    relatedRecords: { item },
    itemDetails: {
      serialTracking: item.serialTracking,
      lotTracking: item.lotTracking,
      itemWeight: item.weight,
      itemVolume: item.volume,
      itemUoms: item.uoms,
      type: item.type,
    },
  };
}

export async function postItemAndCreatePurchasingLineItem(
  item,
  emptyLineData,
  lineNumber,
  addItem,
  setErrors
) {
  globalState.dispatch(editModalLoadingIndicatorOn());
  const { success, record, message } = await postRecord("item", item);
  if (success) {
    const { id, name } = record;
    const description = descriptionForPurchasingTransactions(record);
    addItem({ id, name, description });
    globalState.dispatch(editModalLoadingIndicatorOff());
    return createPurchasingLineFromItem(record, emptyLineData, lineNumber);
  } else {
    globalState.dispatch(editModalLoadingIndicatorOff());
    setErrors((prev) => ({ ...prev, messages: [message] }));
  }
}

export async function updateReturnToVendorLineWithItem(item, line, record) {
  const { location } = record;
  if (!item) {
    return line;
  }
  // transfer the appropriate properties from the new inventory
  // item to the line item
  const newLine = {
    ...line,
    item,
    description: descriptionForPurchasingTransactions(item),
    weightunit: item.weightUnit,
    volumeunit: item.volumeUnit,
    class: item.class,
    uom: getBaseUom(item.uoms),
    taxCode: item.purchaseTaxCode,
    relatedRecords: { ...line.relatedRecords, item },
    serials: [],
    itemDetails: {
      itemWeight: item.weight,
      itemVolume: item.volume,
      itemUoms: item.uoms,
      serialTracking: item.serialTracking,
      lotTracking: item.lotTracking,
      purchaseCost: item.basePurchaseCost,
      type: item.type,
    },
  };

  // if we have locationBins, update bin options
  if (item.locationBins) {
    const filteredAndFormattedBinOptions = filterAndFormatBinOptions(
      item.locationBins
    );
    newLine.availableBins = filteredAndFormattedBinOptions;
    // and set the default bin on the line
    newLine.bin = await getDefaultBin(
      item.id,
      location.id,
      filteredAndFormattedBinOptions
    );
  } else {
    newLine.availableBins = null;
    newLine.bin = null;
  }

  return newLine;
}

export async function expandPurchasingItemGroup(lineToExpand, record) {
  globalState.dispatch(editModalLoadingIndicatorOn());
  const bom = await getItemBom(lineToExpand.item.id);
  const bomIds = bom.map((bomItem) => bomItem.componentItem.id);
  const purchasingItemCalculatedFields = [
    ...ITEM_CALCULATED_FIELDS.purchaseorder,
    ...ITEM_CALCULATED_FIELDS.itemreceipt,
    ...ITEM_CALCULATED_FIELDS.returntovendor,
    // TODO: reorder list need to be added to this list when implemented?
  ];
  const uniqueCalculatedFields = [...new Set(purchasingItemCalculatedFields)];
  const data = await getByIds("item", bomIds, uniqueCalculatedFields);

  const vendorItemsInfo = record.vendor
    ? await getVendorItemInfo(record.vendor.id, bomIds, IN_TRANSACTION)
    : null;

  const lines = bom.map((bomItem, index) => {
    const item = data.find(({ id }) => id === bomItem.componentItem.id);
    let itemCost = item.purchaseCost;
    let vendorPartNumber = item.vendorPartNumber;

    const vendorItemInfo = vendorItemsInfo?.find(
      (vendorItem) => bomItem.componentItem.id === vendorItem.item.id
    );

    if (vendorItemInfo) {
      ({ price: itemCost, vendorPartNumber } = vendorItemInfo);
    }

    const newLine = {
      ...lineToExpand,
      lineNumber: lineToExpand.lineNumber + index,
      item: bomItem.componentItem,
      description: descriptionForPurchasingTransactions(item),
      weightunit: item.weightUnit,
      volumeunit: item.volumeUnit,
      uom: getBaseUom(item.uoms),
      vendorPartNumber,
      quantity: bomItem.quantity.times(lineToExpand.quantity),
      bin: EMPTY_LINE_ITEM.itemreceipt.bin,
      lot: EMPTY_LINE_ITEM.itemreceipt.lot,
      serials: EMPTY_LINE_ITEM.itemreceipt.serials,
      unitprice: itemCost,
      amount: EMPTY_LINE_ITEM.itemreceipt.amount,
      relatedRecords: { item },
      itemDetails: {
        itemWeight: item.weight,
        itemVolume: item.volume,
        itemUoms: item.uoms,
        serialTracking: item.serialTracking,
        lotTracking: item.lotTracking,
        type: item.type,
      },
    };
    return newLine;
  });
  return lines;
}

export function descriptionForPurchasingTransactions(item) {
  return item.purchaseDescription || item.description;
}
