import { useSelector } from "react-redux";

import { LineItemAutocompleteField } from "components/formFields/LineItemAutocompleteField";
import { LineTableCell } from "components/formFields/LineTableCell";

export function LineJobWorkcenter(props) {
  const { job, workcenter, value, jobWorkcenters, onValueChange } = props;

  const jobEnabled = useSelector(
    (state) => state.userCompanySettings.settings.jobEnabled
  );

  if (!jobWorkcenters || !jobEnabled) {
    return null;
  }

  // does jobWorkcenters contain the jobWorkcenter referenced? if not, add it
  let jobWorkcentersPlus = [...jobWorkcenters];
  if (job && workcenter) {
    const jobWorkcenterId = job.id + "|" + workcenter.id;
    const jwc = jobWorkcenters.find((item) => item.id === jobWorkcenterId);
    if (!jwc) {
      jobWorkcentersPlus.unshift({
        id: jobWorkcenterId,
        name: job.name + "->" + workcenter.name,
      });
    }
  }
  return (
    <LineTableCell sx={{ minWidth: "13rem" }}>
      <LineItemAutocompleteField
        name="jobWorkcenter"
        options={jobWorkcentersPlus}
        value={value}
        onValueChange={onValueChange}
        allowTooltip
        dataTesting="jobWorkcenter"
      />
    </LineTableCell>
  );
}
