import { useSelector } from "react-redux";

import { FormControlLabel, Checkbox } from "@mui/material";

import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";

export function FrmShipStation(props) {
  const { value, onValueChange } = props;

  const shipStationEnabled = useSelector(
    (state) => state.userCompanySettings.settings.shipStationEnabled
  );

  if (!shipStationEnabled) {
    return null;
  }

  return (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      <FormControlLabel
        control={
          <Checkbox
            name="forceToShipStation"
            checked={value}
            onChange={(e) => onValueChange(e.target.name, e.target.checked)}
          />
        }
        label={i18n("frmLabel.ForceToShipStation")}
        sx={{ marginRight: 0 }}
      />
      <HoverHelp
        title={i18n("hoverHelp.ForceToShipStation")}
        sx={{ marginLeft: 0.5 }}
      />
    </div>
  );
}
