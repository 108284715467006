import { useEffect, useState } from "react";

import {
  Description,
  Sell,
  RequestQuote,
  LocalShipping,
  LocalAtm,
  ShoppingCart,
  Build,
  FormatListBulleted,
  ReplayCircleFilled,
  ErrorOutline,
  SwapHorizontalCircle,
  ForwardToInbox,
} from "@mui/icons-material";
import { Card, CardContent, Box, Avatar, Skeleton } from "@mui/material";

import { Decimal } from "classes/DecimalClasses";

import { Link } from "components/html/Link";

import { jsonFetch } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

const ICONS = {
  pendingEstimates: Description,
  openSos: Sell,
  openSrs: RequestQuote,
  readyToShip: LocalShipping,
  readyToInvoice: LocalAtm,
  openPos: ShoppingCart,
  openWos: Build,
  openRmas: FormatListBulleted,
  lastSync: ReplayCircleFilled,
  txnsToSend: SwapHorizontalCircle,
  showTxnsSent: ForwardToInbox,
  showRentalsPastDue: ErrorOutline,
};

export function Widget(props) {
  const {
    type,
    settingEnabled,
    label,
    color,
    formatFunc,
    isDateTime,
    linkUrl,
  } = props;

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function getCount() {
      const response = await jsonFetch("GET", `/dashboard/${type}`);
      const { success, json } = response;
      if (!success) {
        handleProgramError(
          new Error(`getCount : error getting count for ${type}`)
        );
      }
      const value = json.data?.value;
      setData(isDateTime ? value : new Decimal(value));
      setIsLoading(false);
    }
    settingEnabled && getCount();
  }, [type, settingEnabled, isDateTime]);

  if (!settingEnabled) {
    return null;
  }

  const Icon = ICONS[type];

  return (
    <Link to={linkUrl}>
      <Card
        data-testing="dashboardCard"
        variant="outlined"
        sx={{ width: "180px" }}
      >
        <CardContent
          sx={{ "&:last-child": { pb: "16px" } }}
          data-testing={type}
        >
          <Box sx={{ display: "flex", gap: 1, verticalAlign: "center" }}>
            <Avatar sx={{ bgcolor: color }}>
              <Icon sx={{ fontSize: "1.5rem" }} />
            </Avatar>
            {isLoading ? (
              <Skeleton
                sx={{ width: "100%" }}
                animation="wave"
                data-testing="skeleton"
              />
            ) : (
              <Box style={{ minHeight: "50px" }}>
                <Box sx={{ color, fontWeight: "bold" }}>
                  {formatFunc ? formatFunc(data) : data}
                </Box>
                <div style={{ lineHeight: 1 }}>{label}</div>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
}
