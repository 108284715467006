import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

import { useRelated } from "hooks/useRelated";

export function FrmShipMethod(props) {
  const { value, onValueChange, error, maxWidth, ...unexpected } = props;
  checkForUnexpectedProps("FrmShipMethod", unexpected);

  const methods = useRelated("shipmethod");

  return (
    <FrmSelectFromObjects
      disableClearable
      name="shippingMethod"
      label={i18n("frmLabel.ShipMethod")}
      value={value}
      onValueChange={onValueChange}
      options={methods}
      error={error}
      maxWidth={maxWidth}
    />
  );
}
