import { BaseDateFilter } from "components/ListPage/Widgets/EditDisplayResults/BaseDateFilter";

export function EditDateFilter(props) {
  const {
    filters,
    setFilters,
    setDateError,
    setDateFilter,
    dateFilter,
  } = props;
  const { filterSettings } = filters;
  const { FromDate, ToDate } = filterSettings;

  function onDateChange(dates) {
    const { fromDate, toDate } = dates;
    const newDates = { FromDate: fromDate, ToDate: toDate };
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: { ...prevParams.filterSettings, ...newDates },
      startingRecord: 1,
    }));
  }

  return (
    <BaseDateFilter
      toDate={ToDate}
      fromDate={FromDate}
      setDateError={setDateError}
      setDateFilter={setDateFilter}
      onDateChange={onDateChange}
      dateFilter={dateFilter}
    />
  );
}
