import { TextField } from "@mui/material";

import { SideButton } from "components/SideButton";

export function TextFieldButtonCombo(props) {
  const { TextFieldProps, children, ButtonProps, buttonLabel } = props;
  return (
    <div>
      <TextField
        {...TextFieldProps}
        margin="dense"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        sx={{ minWidth: "7em" }}
        InputProps={{
          sx: { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
        }}
      >
        {children}
      </TextField>
      <SideButton label={buttonLabel} {...ButtonProps} />
    </div>
  );
}
