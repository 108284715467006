import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Typography,
} from "@mui/material";

import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";

export function WhySosPayments() {
  const navigate = useNavigate();

  const paymentsBoarded = useSelector(
    (state) => state.userCompanySettings.settings.paymentsBoarded
  );

  return (
    <Box
      sx={{
        margin: 2,
        marginBottom: 0,
        width: "100%",
      }}
    >
      <Typography sx={{ mb: 1 }} variant="h2">
        {i18n("sosPayments.01")}
      </Typography>
      <Card variant="outlined" sx={{ maxWidth: 520 }}>
        <CardContent>
          <Typography variant="h3">{i18n("sosPayments.02")}</Typography>
          <List sx={{ mb: 1 }}>
            <ListItem disablePadding>&bull; {i18n("sosPayments.03")}</ListItem>
            <ListItem disablePadding>&bull; {i18n("sosPayments.04")}</ListItem>
            <ListItem disablePadding>&bull; {i18n("sosPayments.05")}</ListItem>
            <ListItem disablePadding>&bull; {i18n("sosPayments.06")}</ListItem>
          </List>
          <Typography variant="h4">{i18n("sosPayments.07")}</Typography>
          <VSpace space={1} />
          <Card sx={{ mb: 2 }}>
            <CardHeader
              title={i18n("sosPayments.08")}
              subheader={i18n("sosPayments.09")}
              sx={{ backgroundColor: "primary.main" }}
              titleTypographyProps={{ variant: "h2", color: "white" }}
              subheaderTypographyProps={{ color: "white" }}
            />
            <List sx={{ p: 1 }}>
              <ListItem disablePadding>
                &bull; {i18n("sosPayments.10")}
              </ListItem>
              <ListItem disablePadding>
                &bull; {i18n("sosPayments.11")}
              </ListItem>
              <ListItem disablePadding>
                &bull; {i18n("sosPayments.12")}
              </ListItem>
              <ListItem disablePadding>
                &bull; {i18n("sosPayments.13")}
              </ListItem>
            </List>
          </Card>
          <Button
            variant="contained"
            onClick={() => navigate("/sospaymentscontact")}
            disabled={paymentsBoarded}
          >
            {i18n("sosPayments.14")}
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}
