import { afterGet as globalAfterGet } from "services/sosInventoryService/afterGet";
import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/serial/schema";
import { transformFields } from "services/utility/afterGet";
import { yyyymmddToDate } from "services/utility/dates";

export function afterGet(record) {
  const newRecord = transformFields(record, FIELDS_TO_TRANSFORM);

  if (record.warranty) {
    newRecord.warranty.start = record.warranty.start
      ? yyyymmddToDate(record.warranty.start)
      : null;
    newRecord.warranty.end = record.warranty.end
      ? yyyymmddToDate(record.warranty.end)
      : null;
  }

  return globalAfterGet(newRecord);
}
