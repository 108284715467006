import { Loading } from "classes/Loading";

import { LineItemText } from "components/formFields/LineItemText";
import { LineSkeleton } from "components/formFields/LineSkeleton";
import { LineTableCell } from "components/formFields/LineTableCell";

export function LineCost(props) {
  const { cost } = props;

  const isLoading = cost instanceof Loading;

  return (
    <LineTableCell
      sx={{
        minWidth: "6rem",
        backgroundColor: isLoading ? "inherit" : "staticTableCell",
      }}
    >
      {isLoading ? (
        <LineSkeleton />
      ) : (
        <LineItemText money value={cost} dataTesting="lineCost" />
      )}
    </LineTableCell>
  );
}
