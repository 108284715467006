import { LineTableCell } from "components/formFields/LineTableCell";
import { DatePicker } from "components/html/Input/DatePicker";

import { LINE_ITEM_METADATA } from "editConfig";

const outlinedStyles = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": { borderWidth: 0 },
    "& input": { paddingTop: "0", paddingBottom: "0" },
  },
};

export function LineDueDate(props) {
  const { value: initialValue, onValueChange, objectType, error } = props;

  const value = LINE_ITEM_METADATA[objectType].duedate.editStr(
    LINE_ITEM_METADATA[objectType].duedate,
    initialValue,
    null
  );

  return (
    <LineTableCell sx={{ minWidth: "10rem" }}>
      <DatePicker
        key={value}
        name="duedate"
        value={value}
        onValueChange={onValueChange}
        textSx={outlinedStyles}
        dataTesting="duedate"
        error={error}
      />
    </LineTableCell>
  );
}
