import { useState, useEffect } from "react";

import { Card, CardContent } from "@mui/material";

import { FrmSelectScalar } from "components/formFields/FrmSelectScalar";
import { LocationToggle } from "components/formFields/address/LocationToggle";
import { CardHeader } from "components/formFields/formCards/CardHeader";

import { i18n } from "services/i18nService";

const defaultOption = i18n("global.Default");

export function BillingAddress(props) {
  const { value, locations, handleInputFieldChange, label } = props;

  const [location, setLocation] = useState({ name: defaultOption });

  useEffect(() => setLocation({ name: defaultOption }), [locations]);

  function handleOptionChange(name, value) {
    if (value) {
      const location = locations.find((location) => location.name === value);
      handleInputFieldChange(name, location);
    }
    setLocation({ name: value });
  }

  return (
    <Card variant="outlined">
      <CardContent>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CardHeader text={label} gutterBottom={false} />
          <div style={{ flexGrow: 1, paddingLeft: "1rem" }}>
            <FrmSelectScalar
              disableClearable
              name="billing"
              label={i18n("global.Address")}
              value={location.name}
              onValueChange={handleOptionChange}
              options={
                locations ? locations.map(({ name }) => name) : [defaultOption]
              }
            />
          </div>
        </div>
        <LocationToggle
          value={value}
          addressType="billing"
          handleInputFieldChange={handleInputFieldChange}
        />
      </CardContent>
    </Card>
  );
}
