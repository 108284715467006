import { getIsAutomatedTestingAccount } from "appConfig";

import {
  getPaged,
  deleteRecords,
  SUCCESS,
} from "services/sosInventoryService/sosApi";

import globalState from "globalState/globalState";

import { OBJECT_TYPES } from "appConstants";

const OBJECT_LIST = [
  OBJECT_TYPES.ADJUSTMENT.fullString,
  OBJECT_TYPES.BUILD.fullString,
  OBJECT_TYPES.CUSTOMER.fullString,
  OBJECT_TYPES.ESTIMATE.fullString,
  OBJECT_TYPES.INVOICE.fullString,
  OBJECT_TYPES.ITEM.fullString,
  OBJECT_TYPES.SHIPMENT.fullString,
  OBJECT_TYPES.RENTAL.fullString,
  OBJECT_TYPES.ITEM_RECEIPT.fullString,
  OBJECT_TYPES.PICK_TICKET.fullString,
  OBJECT_TYPES.PROCESS.fullString,
  OBJECT_TYPES.PURCHASE_ORDER.fullString,
  OBJECT_TYPES.RENTAL_RETURN.fullString,
  OBJECT_TYPES.RETURN.fullString,
  OBJECT_TYPES.RETURN_TO_VENDOR.fullString,
  OBJECT_TYPES.RMA.fullString,
  OBJECT_TYPES.SALES_RECEIPT.fullString,
  OBJECT_TYPES.SALES_ORDER.fullString,
  OBJECT_TYPES.SALES_RECEIPT.fullString,
  OBJECT_TYPES.TRANSFER.fullString,
  OBJECT_TYPES.VENDOR.fullString,
  OBJECT_TYPES.WORK_ORDER.fullString,
  OBJECT_TYPES.LOT.fullString,
  OBJECT_TYPES.JOB.fullString,
];

export async function dbReset(addToStatus) {
  // check to make sure we are in a testing environment
  // before allowing for a database reset
  if (
    !(
      ["development", "test"].includes(process.env.NODE_ENV) ||
      process.env.REACT_APP_ENVIRONMENT === "v9CI"
    )
  ) {
    return null;
  }

  // be sure we're in the right company
  const { company } = globalState.getState().userCompanySettings;
  if (!getIsAutomatedTestingAccount(company)) {
    addToStatus(`Wrong test account: ${company.name}, aborting`);
    throw new Error(`Wrong test account: ${company.id}, ${company.name}`);
  }

  // out with the old...
  addToStatus("removing old records...");
  for (let i = 0; i < OBJECT_LIST.length; i++) {
    const obj = OBJECT_LIST[i];
    addToStatus(`about to delete ${obj}s...`);
    const success = await remove(obj);
    if (!success) {
      addToStatus("error");
      return;
    }
    addToStatus(`removed ${obj}s`);
  }

  addToStatus("DONE");
}

const BATCH_SIZE = 200;
async function remove(objectType) {
  // get number of records
  const response = await getPaged(objectType, 1, 1);
  if (response.success) {
    const numRecords = response.data.totalCount;
    if (numRecords === 0) {
      return true;
    }
    const numCalls = Math.floor(numRecords / BATCH_SIZE) + 1;
    let ids = [];
    for (let i = 0; i < numCalls; i++) {
      const loopResponse = await getPaged(
        objectType,
        i * BATCH_SIZE + 1,
        BATCH_SIZE
      );
      if (loopResponse.success) {
        const idBatch = loopResponse.data.records.map((record) => record.id);
        ids = ids.concat(idBatch);
      } else {
        throw new Error(
          `dbReset | remove (inner loop) | unsuccessful call to getList, message: ${loopResponse.message}`
        );
      }
    }

    const deleteResponse = await deleteRecords(objectType, ids);
    return deleteResponse === SUCCESS ? true : false;
  } else {
    throw new Error(
      `dbReset | remove | unsuccessful call to getList, message: ${response.message}`
    );
  }
}
