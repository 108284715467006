import { useDispatch, useSelector } from "react-redux";

import { CloudDownload, Add } from "@mui/icons-material";
import { Button, Box, Pagination } from "@mui/material";

import { BatchActions } from "components/ListPage/Widgets/BatchActions";
import { Columns } from "components/ListPage/Widgets/EditDisplayResults/Columns";
import { Filters } from "components/ListPage/Widgets/EditDisplayResults/Filters";
import { Search } from "components/ListPage/Widgets/Search";
import { HIDE_COLUMN_SELECTOR } from "components/ListPage/listConfig";
import { HIDE_SEARCH_FIELD } from "components/ListPage/listConfig";

import { i18n } from "services/i18nService";
import { checkForUnexpectedProps } from "services/utility/misc";

import { openModal } from "globalState/modalSlice";

import { LIST_PAGE_HIDE_ADD_NEW_OBJECTS } from "appConstants";
import { OBJECT_TYPES } from "appConstants";

export function Widgets(props) {
  const {
    objectType,
    checkedIds,
    setCheckedIds,
    setSelectAllState,
    retrievalParams,
    setRetrievalParams,
    setColumnParams,
    allColumns,
    totalRecords,
    formTemplates,
    setErrors,
    columnConfig,
    ...unexpected
  } = props;
  checkForUnexpectedProps("Widgets", unexpected);

  const dispatch = useDispatch();

  const sosSupportTier3 = useSelector(
    (state) => state.userCompanySettings.supportPrivileges?.sosSupportTier3
  );

  function pageChange(_, page) {
    setRetrievalParams((prevParams) => ({
      ...prevParams,
      startingRecord: (page - 1) * retrievalParams.maxResults + 1,
      persistUpdate: false,
    }));
  }

  return (
    <Box
      sx={{
        displayPrint: "none",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: "3.5rem",
        m: "0 0.5rem",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        {!LIST_PAGE_HIDE_ADD_NEW_OBJECTS.includes(objectType) && (
          <Button
            onClick={() => dispatch(openModal({ objectType }))}
            data-testing="listPageAdd"
            variant="contained"
          >
            <Add />
            {i18n("global.Add")}
          </Button>
        )}

        {objectType === OBJECT_TYPES.TEMPLATE_LIBRARY.fullString &&
          sosSupportTier3 && (
            <Button
              onClick={() => dispatch(openModal({ objectType }))}
              data-testing="listPageAdd"
              variant="contained"
            >
              <Add />
              {i18n("global.Add")}
            </Button>
          )}

        <BatchActions
          objectType={objectType}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
          setSelectAllState={setSelectAllState}
          formTemplates={formTemplates}
        />

        {/* Export button hidden until functionality complete */}
        <Button sx={{ display: "none" }}>
          <CloudDownload sx={{ marginRight: "0.5rem" }} />
          {i18n("listPage.Widgets.export")}
        </Button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        {!HIDE_SEARCH_FIELD.includes(objectType) && (
          <Search
            setRetrievalParams={setRetrievalParams}
            objectType={objectType}
          />
        )}
        <Filters
          objectType={objectType}
          retrievalParams={retrievalParams}
          setRetrievalParams={setRetrievalParams}
        />
        {!HIDE_COLUMN_SELECTOR.includes(objectType) && (
          <Columns
            objectType={objectType}
            retrievalParams={retrievalParams}
            setRetrievalParams={setRetrievalParams}
            setColumnParams={setColumnParams}
            allColumns={allColumns}
            columnConfig={columnConfig}
          />
        )}
        {totalRecords !== 0 && (
          <Pagination
            page={
              (retrievalParams.startingRecord - 1) /
                retrievalParams.maxResults +
              1
            }
            size="small"
            shape="rounded"
            siblingCount={1}
            count={Math.ceil(totalRecords / retrievalParams.maxResults)}
            onChange={pageChange}
          />
        )}
      </div>
    </Box>
  );
}
