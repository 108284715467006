import { useEffect, useState } from "react";

import { Print } from "@mui/icons-material";
import {
  Box,
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
} from "@mui/material";

import { SortableColumnHeader } from "components/SortableColumnHeader";
import { TableCell } from "components/TableCell";
import { Link } from "components/html/Link";
import { CloseX } from "components/utility/CloseX";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { Loading } from "components/utility/Loading";
import { ModalTitle } from "components/utility/ModalTitle";

import { i18n } from "services/i18nService";
import { onPo } from "services/sosInventoryService/onPo/schema";
import { onRental } from "services/sosInventoryService/onRental/schema";
import { onRma } from "services/sosInventoryService/onRma/schema";
import { onSo } from "services/sosInventoryService/onSo/schema";
import { onSr } from "services/sosInventoryService/onSr/schema";
import { onWo } from "services/sosInventoryService/onWo/schema";
import { getInventory } from "services/sosInventoryService/sosApi";

import { theme } from "SosTheme";

const sx = {
  "& .MuiDialog-paper": {
    minHeight: "30vh",
    maxHeight: "93vh",
    "@media print": { minHeight: "100%", maxHeight: "100%" },
  },
};

const iconSx = {
  padding: "0.4rem",
  height: "36px",
  border: "1px solid",
  borderColor: "lineSeparatorMinor",
  backgroundColor: "white",
  displayPrint: "none",
};

const CONFIG = { onSo, onSr, onPo, onRma, onWo, onRental };

export function InventoryDialog(props) {
  const { type, id, name, onClose } = props;
  const [data, setData] = useState({
    records: null,
    sort: null,
    direction: "desc",
  });
  const [isLoading, setIsLoading] = useState(false);
  const { columns, relatedObjectType, headerText, note } = CONFIG[type];

  useEffect(() => {
    async function retrieveInventory() {
      setIsLoading(true);
      const { data } = await getInventory(id, type, relatedObjectType);
      setData((prev) => ({ ...prev, records: data }));
      setIsLoading(false);
    }
    retrieveInventory();
  }, [id, relatedObjectType, type]);

  function sortRecords(column, formatFunc, isNumber) {
    const sortAscending = data.sort === column && data.direction === "desc";
    const records = data.records.sort((a, b) => {
      const aValue = (formatFunc ? formatFunc(a[column], a) : a[column]) || "";
      const bValue = (formatFunc ? formatFunc(b[column], b) : b[column]) || "";
      if (isNumber) {
        return sortAscending ? aValue - bValue : bValue - aValue;
      }
      const sort = aValue < bValue;
      if (sortAscending) {
        return sort && sortAscending ? 1 : -1;
      }
      return sort ? -1 : 1;
    });
    setData((prev) => ({
      ...prev,
      records,
      sort: column,
      direction: sortAscending ? "asc" : "desc",
    }));
  }

  return (
    <Dialog open={true} onClose={onClose} maxWidth="lg" fullWidth sx={sx}>
      <>
        <DialogTitle
          sx={{
            backgroundColor: "dialogTitleBackground",
            borderBottom: "1px solid",
            borderBottomColor: "lineSeparatorMinor",
            padding: "0.5rem 1rem",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CloseX onClick={onClose} />
                  <ModalTitle label={`${headerText} - ${name}`} />
                </div>
                <IconButton
                  onClick={() => window.print()}
                  sx={iconSx}
                  style={{ borderRadius: theme.shape.borderRadius }}
                  size="large"
                >
                  <Print />
                </IconButton>
              </div>
            </Grid>
          </Grid>
        </DialogTitle>
        {isLoading && <Loading />}
        <DialogContent>
          {data.records ? (
            <Box sx={{ height: "80vh" }}>
              <TableContainer component={Paper} sx={{ mt: 1 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {columns.map((column, index) => {
                        const {
                          label,
                          number,
                          sortable,
                          jsonName,
                          formatFunc,
                        } = column;
                        const textAlign = number ? "right" : "left";
                        if (sortable) {
                          return (
                            <SortableColumnHeader
                              key={index}
                              isSortable
                              align={textAlign}
                              sortName={jsonName}
                              label={label}
                              activeSortName={data.sort}
                              direction={data.direction}
                              onClick={(sortName) =>
                                sortRecords(sortName, formatFunc, number)
                              }
                            />
                          );
                        }
                        return (
                          <TableCell
                            key={index}
                            sx={{ textAlign, verticalAlign: "bottom" }}
                          >
                            {label}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.records.map((row, index) => {
                      return (
                        <TableRow key={index}>
                          {columns.map((col, index) => {
                            const { jsonName, formatFunc, linkColumn, number } =
                              col;
                            const textAlign = number ? "right" : "left";
                            const cellValue = formatFunc
                              ? formatFunc(row[jsonName])
                              : row[jsonName];
                            return (
                              <TableCell
                                key={index}
                                sx={{ textAlign, verticalAlign: "top" }}
                              >
                                {linkColumn ? (
                                  <Link
                                    onClick={onClose}
                                    to={`/${relatedObjectType}?id=${row.id}`}
                                    underline="none"
                                  >
                                    {cellValue}
                                  </Link>
                                ) : (
                                  cellValue
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {note && <div style={{ marginTop: "1rem" }}>{note}</div>}
            </Box>
          ) : (
            <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
          )}
        </DialogContent>
      </>
    </Dialog>
  );
}
