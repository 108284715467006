import { FilterSelectField } from "components/formFields/FilterSelectField";

import { useRelated } from "hooks/useRelated";

export function DepartmentFilter(props) {
  const { value, onValueChange, name, label } = props;

  const departments = useRelated("department", "name");

  return (
    <FilterSelectField
      name={name}
      label={label}
      options={departments}
      value={value}
      onValueChange={onValueChange}
    />
  );
}
