import { LineItemText } from "components/formFields/LineItemText";
import { LineSkeleton } from "components/formFields/LineSkeleton";
import { LineTableCell } from "components/formFields/LineTableCell";

export function LineOnShortOrder(props) {
  const { value } = props;

  const isLoading = value === undefined;

  return (
    <LineTableCell
      sx={{
        minWidth: "5.5rem",
        backgroundColor: isLoading ? "inherit" : "staticTableCell",
      }}
    >
      {isLoading ? <LineSkeleton /> : <LineItemText decimal value={value} />}
    </LineTableCell>
  );
}
