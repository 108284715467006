import { LineItemAutocompleteField } from "components/formFields/LineItemAutocompleteField";
import {
  LineTableCell,
  LineTableHeadingCell,
} from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";

export function OcostVendorHeading() {
  return <LineTableHeadingCell>{i18n("columns.Vendor")}</LineTableHeadingCell>;
}

export function OcostVendor(props) {
  const { value, onValueChange, vendors } = props;

  return (
    <LineTableCell sx={{ minWidth: "7em" }}>
      <LineItemAutocompleteField
        name="vendor"
        options={vendors}
        value={value}
        onValueChange={onValueChange}
        allowTooltip
      />
    </LineTableCell>
  );
}
