import { useSelector } from "react-redux";

import {
  LEFT_NAV_TEXT,
  LIST_TABLE_OFFSET_MAX,
  LIST_TABLE_OFFSET_MIN,
} from "globalState/leftNavSlice";

export function useLeftNavWidth() {
  const leftNavState = useSelector((state) => state.leftNav.iconsText);

  // Units are in rem
  return leftNavState === LEFT_NAV_TEXT
    ? LIST_TABLE_OFFSET_MAX
    : LIST_TABLE_OFFSET_MIN;
}
