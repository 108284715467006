import { Slider, Switch, Typography, Box } from "@mui/material";

import { i18n } from "services/i18nService";

export function FrmInactivityTimeout(props) {
  const { value, onValueChange, name, label, options } = props;

  return (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          mb: value !== 0 ? "2rem" : "inherit",
        }}
      >
        <Typography component="span">{i18n("global.Off")}</Typography>
        <Switch
          size="small"
          checked={value !== 0}
          onChange={(e) => onValueChange(name, e.target.checked ? 1 : 0)}
        />
        <Typography component="span">{i18n("global.On")}</Typography>
      </Box>
      {value !== 0 && (
        <Slider
          label={label}
          value={value}
          aria-label={name}
          marks={options}
          onChange={(e) => onValueChange(name, e.target.value)}
          valueLabelDisplay="off"
          step={null}
          max={5}
          min={1}
        />
      )}
    </>
  );
}
