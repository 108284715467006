import { useSelector } from "react-redux";

import { LineItemAutocompleteField } from "components/formFields/LineItemAutocompleteField";
import { LineTableCell } from "components/formFields/LineTableCell";

export function LineUom(props) {
  const { value, onValueChange, itemUoms } = props;
  const uomEnabled = useSelector(
    (state) => state.userCompanySettings.settings.uomEnabled
  );

  if (!uomEnabled) {
    return null;
  }

  // uom is not just name and id, so need to extract those parts
  const uomsForSelect = itemUoms?.map(({ uom }) => uom) || [];
  return (
    <LineTableCell>
      {uomsForSelect.length > 0 && (
        <LineItemAutocompleteField
          name="uom"
          options={uomsForSelect}
          value={value}
          onValueChange={onValueChange}
          disableClearable
        />
      )}
    </LineTableCell>
  );
}
