import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { QuickAddVendor } from "components/QuickAddVendor";
import { FrmTextField } from "components/formFields/FrmTextField";
import {
  FrmItemSelect,
  FrmVendorWithAdd,
  FrmLocation,
} from "components/formFields/frm";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { quickAddVendor } from "services/sosInventoryService/domainLogic";
import { getVendorItemCatalogInfo } from "services/sosInventoryService/purchasingTransaction/domainLogic";
import { getRecord } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";

import { useCustomFieldDefinitions } from "hooks/useCustomFieldDefinitions";
import { useErrors } from "hooks/useErrors";
import { useObject } from "hooks/useObject";
import { useVendors } from "hooks/useVendors";

import { openAlert } from "globalState/alertSlice";
import { editModalLoadingIndicatorOn } from "globalState/loadingSlice";
import { editModalLoadingIndicatorOff } from "globalState/loadingSlice";

import { OBJECT_TYPES, ITEM_QUICKLIST_CASES } from "appConstants";

const OBJECT_TYPE = OBJECT_TYPES.REORDER.fullString;

export function Reorder(props) {
  const { id, onClose, onAdd } = props;

  // TRANSACTION STATE
  const [record, setRecord] = useState();

  // UI STATE
  const { errors, setErrors, isInError } = useErrors();
  const [addNewVendor, setAddNewVendor] = useState(false);
  const [newVendorName, setNewVendorName] = useState("");
  const [vendorAdded, setVendorAdded] = useState(0);
  const loadingState = useSelector((state) => state.loading.editModal);
  const dispatch = useDispatch();

  // RELATED RECORD LISTS
  const vendors = useVendors(vendorAdded);
  const customFieldDefs = useCustomFieldDefinitions(OBJECT_TYPE);

  useObject(OBJECT_TYPE, id, setRecord, customFieldDefs, setErrors);

  function handleInputFieldChange(fieldName, value) {
    setPageDirty();
    setRecord((prevRecord) => ({ ...prevRecord, [fieldName]: value }));
  }

  async function handleItemChange(_, newValue) {
    if (!newValue) {
      setRecord((prev) => ({ ...prev, item: null }));
      setPageDirty();
      return;
    }

    // doing this update here so that we don't have a pause in the UI while
    // we look up the full item record
    setRecord((prev) => ({
      ...prev,
      item: {
        id: newValue.id,
        name: newValue.name,
      },
    }));

    // get full item record
    const newItem = await getRecord("item", newValue.id);
    if (!newItem) {
      dispatch(
        openAlert({
          type: "warning",
          message: i18n("alert.RetrieveItemFailed"),
        })
      );
      return;
    }

    const { vendorPartNumber: newVendorPartNumber, itemCost: newUnitprice } =
      await getVendorItemCatalogInfo(
        record.vendor,
        newItem.id,
        newItem.basePurchaseCost,
        newItem.vendorPartNumber
      );
    setRecord((prev) => ({
      ...prev,
      item: {
        ...prev.item,
        description: newItem.description,
        sku: newItem.sku,
        type: newItem.type,
        basePurchaseCost: newItem.basePurchaseCost,
        vendorPartNumber: newItem.vendorPartNumber,
      },
      vendorPartNumber: newVendorPartNumber,
      unitprice: newUnitprice,
    }));
    setPageDirty();
  }

  async function handleVendorChange(_, newValue) {
    if (!newValue) {
      setRecord((prev) => ({ ...prev, vendor: null }));
      return;
    }

    if (record.item) {
      const { vendorPartNumber: newVendorPartNumber, itemCost: newUnitprice } =
        await getVendorItemCatalogInfo(
          record.vendor,
          record.item.id,
          record.item.basePurchaseCost,
          record.item.vendorPartNumber
        );
      setRecord((prev) => ({
        ...prev,
        vendor: { id: newValue.id, name: newValue.name },
        vendorPartNumber: newVendorPartNumber,
        unitprice: newUnitprice,
      }));
    }
    setPageDirty();
  }

  async function handleQuickAddNewVendor(name) {
    dispatch(editModalLoadingIndicatorOn());
    const vendor = await quickAddVendor(name);
    if (typeof vendor === "object") {
      setRecord((prevRecord) => ({
        ...prevRecord,
        vendor: { id: vendor.id, name: vendor.name },
      }));
    } else {
      setErrors((prev) => ({ ...prev, messages: [vendor] }));
    }

    // force a re-get of the vendor list, since we know one was just added
    setVendorAdded((prev) => prev + 1);
    dispatch(editModalLoadingIndicatorOff());
    setPageDirty();
    setAddNewVendor(false);
  }

  async function handleAddNewVendor(newVendor) {
    const { id, name } = newVendor;
    setRecord((prevRecord) => ({
      ...prevRecord,
      vendor: { id, name },
    }));

    // force a re-get of the vendor list, since we know one was just added
    setVendorAdded((prev) => prev + 1);
    dispatch(editModalLoadingIndicatorOff());
    setAddNewVendor(false);
  }

  if (!id && !record) {
    return null;
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        setErrors={setErrors}
        text={record?.number || ""}
        handleClose={onClose}
        onAdd={onAdd}
      />

      {loadingState && <Loading />}

      <ObjectEditDialogContent>
        <Layout
          pageTitle={
            id
              ? `${i18n("objectType.reorder.Sentence")} ${record?.number || ""}`
              : i18n("objectType.reorder.New")
          }
        >
          <Box flexGrow="1" position="relative" m={2} mb={0}>
            <FormErrors errors={errors} setErrors={setErrors} />
            {record ? (
              <Box>
                <FrmFieldGrid gridAutoFlow="dense">
                  <FrmItemSelect
                    name="item"
                    value={record.item}
                    onValueChange={handleItemChange}
                    type={ITEM_QUICKLIST_CASES.ALL}
                  />
                  <FrmVendorWithAdd
                    value={record.vendor}
                    onValueChange={handleVendorChange}
                    setAddNewVendor={setAddNewVendor}
                    setNewVendorName={setNewVendorName}
                    vendors={vendors}
                    error={isInError("vendor")}
                  />
                </FrmFieldGrid>
                <VSpace space={1} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <FrmLocation
                    value={record.location}
                    error={isInError("location")}
                    onValueChange={handleInputFieldChange}
                    width="25rem"
                  />
                  <FrmTextField
                    name="vendorPartNumber"
                    label={i18n("frmLabel.VendorPartNumber")}
                    onValueBlur={handleInputFieldChange}
                    value={record.vendorPartNumber}
                  />
                </FrmFieldGrid>
                <VSpace space={1} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <FrmTextField
                    number
                    name="quantity"
                    label={i18n("frmLabel.Quantity")}
                    onValueBlur={handleInputFieldChange}
                    value={record.quantity}
                    error={isInError("quantity")}
                    sx={{ maxWidth: "10rem" }}
                  />
                  <FrmTextField
                    money
                    name="unitprice"
                    label={i18n("frmLabel.Unitprice")}
                    onValueBlur={handleInputFieldChange}
                    value={record.unitprice}
                    error={isInError("unitprice")}
                    sx={{ maxWidth: "10rem" }}
                  />
                </FrmFieldGrid>
              </Box>
            ) : (
              !isInError("record") && (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )
            )}
          </Box>
          <VSpace space={4} />
        </Layout>
      </ObjectEditDialogContent>

      {Boolean(addNewVendor) && (
        <QuickAddVendor
          open
          initialNameValue={newVendorName}
          onClose={() => setAddNewVendor(false)}
          onQuickAdd={handleQuickAddNewVendor}
          onAdd={handleAddNewVendor}
        />
      )}
    </>
  );
}
