// small size button for forms; indicates that an action will be
// taken as soon as you click the button
import { Button } from "@mui/material";

import { checkForUnexpectedProps } from "services/utility/misc";

export function ActionButton(props) {
  const {
    component,
    headerButton,
    type,
    size = "small",
    disabled,
    children,
    htmlFor,
    sx = {},
    dataTesting,
    onClick,
    ...unexpected
  } = props;
  checkForUnexpectedProps("ActionButton", unexpected);

  const headerStyles = headerButton
    ? { minWidth: "auto", marginLeft: "0.5rem" }
    : {};
  return (
    <div style={{ display: "inline" }}>
      <Button
        type={type}
        onClick={onClick}
        htmlFor={htmlFor}
        variant="outlined"
        size={size}
        sx={{
          color: "ActionButton.buttonText",
          borderColor: "ActionButton.buttonOutline",
          "&:hover": {
            backgroundColor: "ActionButton.buttonHover",
          },
          cursor: "pointer",
          display: "inline",
          ...headerStyles,
          ...sx,
        }}
        disableElevation
        component={component || "button"}
        disabled={disabled}
        data-testing={dataTesting}
      >
        {children}
      </Button>
    </div>
  );
}
