import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { EmailDialog } from "components/EmailDialog";
import { ImageDialog } from "components/ImageDialog";
import { InventoryDialog } from "components/InventoryDialog";
import { MoreTextDialog } from "components/MoreTextDialog";
import { PdfDialog } from "components/PdfDialog";

import { closeDialog } from "globalState/globalDialogSlice";

const ActionDialog = {
  email: EmailDialog,
  pdf: PdfDialog,
  image: ImageDialog,
  moreText: MoreTextDialog,
  inventory: InventoryDialog,
};

export function GlobalDialog() {
  const dispatch = useDispatch();
  const dialogState = useSelector((state) => state.globalDialog);
  const { open, type, dialogProps } = dialogState;
  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  function handleClose() {
    dispatch(closeDialog());
  }

  const DialogComponent = ActionDialog[type];

  return open ? (
    <DialogComponent open={open} onClose={handleClose} {...dialogProps} />
  ) : null;
}
