import { useEffect, useState } from "react";

import { Card, Typography } from "@mui/material";

import { IMAGE_DIMENSION } from "appConfig";

import { ImageFromBase64 } from "components/ImageFromBase64";

import { i18n } from "services/i18nService";
import { getSignature } from "services/sosInventoryService/sosApi";
import { imageBlobToBase64 } from "services/utility/images";

export function FrmSignature(props) {
  const { objectType, id, hasSignature } = props;
  const [signatureImage, setSignatureImage] = useState(null);

  useEffect(() => {
    async function _getSignature() {
      const signatureBlob = await getSignature(objectType, id);
      imageBlobToBase64(signatureBlob, setSignatureImage);
    }
    if (hasSignature) {
      _getSignature();
    }
  }, [objectType, id, hasSignature]);

  if (!hasSignature) {
    return (
      <Typography variant="subtitle1" sx={{ color: "frmLabel" }}>
        {i18n("global.NoSignature")}
      </Typography>
    );
  }

  return (
    <Card
      variant="outlined"
      sx={{ margin: "0.5em 0", padding: "1em", color: "frmLabel" }}
    >
      <Typography variant="subtitle1" sx={{ color: "label" }}>
        {i18n("frmLabel.Signature")}
      </Typography>
      <ImageFromBase64
        maxWidth={IMAGE_DIMENSION}
        maxHeight="auto"
        base64String={signatureImage}
      />
    </Card>
  );
}
