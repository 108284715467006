import {
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";

import { CloseX } from "components/utility/CloseX";
import { ModalTitle } from "components/utility/ModalTitle";

import { i18n } from "services/i18nService";

import { SEARCH_ABBREVIATIONS } from "appConstants";

export function TipsDialog(props) {
  const { setOpen } = props;

  return (
    <Dialog open={true} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <DialogTitle
        sx={{
          backgroundColor: "dialogTitleBackground",
          borderBottom: "1px solid",
          borderBottomColor: "lineSeparatorMinor",
          padding: "0.5rem 1rem",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={8}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CloseX onClick={() => setOpen(false)} />
              <ModalTitle label={i18n("global.SearchTips")} />
            </div>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ padding: "1rem 0" }}>
          {i18n("search.Description")}
        </Typography>
        <Typography sx={{ paddingBottom: "1rem" }}>
          {i18n("search.AvailableKeywordsBelow")}
        </Typography>
        <div
          style={{
            lineHeight: "1.5rem",
            display: "flex",
            flexWrap: "wrap",
            paddingBottom: "1rem",
          }}
        >
          {SEARCH_ABBREVIATIONS.map((abbreviation, index) => {
            return (
              <Typography key={index} sx={{ width: "33%", fontWeight: "bold" }}>
                {abbreviation}
              </Typography>
            );
          })}
        </div>
        <Typography sx={{ paddingBottom: "1rem" }}>
          {i18n("search.TipExample")}
        </Typography>
        <Typography>{i18n("search.QuotesTip")}</Typography>
      </DialogContent>
    </Dialog>
  );
}
