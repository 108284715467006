import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";

import { i18n } from "services/i18nService";

export function FrmVendorNotes(props) {
  const { onValueChange, value } = props;

  return (
    <FrmResizeTextField
      value={value}
      name="vendorNotes"
      minRows={2}
      maxRows={4}
      width="60ch"
      label={i18n("frmLabel.VendorNotes")}
      onValueBlur={onValueChange}
      dataTesting="vendorNotes"
    />
  );
}
