import { Tooltip } from "components/Tooltip";
import { SwapIcon } from "components/TopNav/SwapIcon";

import { i18n } from "services/i18nService";

export function ToggleTaxDiscount(props) {
  const { discountTaxable, setDiscountTaxable } = props;
  return (
    <Tooltip
      title={i18n(
        `tooltip.${
          discountTaxable ? "ToggleDiscountTaxable" : "ToggleDiscountNotTaxable"
        }`
      )}
    >
      <div>
        <SwapIcon onClick={() => setDiscountTaxable(!discountTaxable)} />
      </div>
    </Tooltip>
  );
}
