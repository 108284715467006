import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";

import { ButtonProgress } from "components/utility/ButtonProgress";

import { i18n } from "services/i18nService";
import {
  deleteRecords,
  SUCCESS,
  DELETE_RECORDS_LOT_HAS_ONHAND,
  DELETE_RECORDS_LOT_USED_ON_TRANSACTIONS,
} from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";
import { checkForUnexpectedProps } from "services/utility/misc";

import { openAlert } from "globalState/alertSlice";
import { deleteNotifications } from "globalState/notificationsSlice";
import { recordsChangedTick } from "globalState/recordsChangedSlice";

export function DeleteDialog(props) {
  const { objectType, objectIds, message, open, onClose, ...unexpected } =
    props;
  checkForUnexpectedProps("DeleteDialog", unexpected);

  const [inProgress, setInProgress] = useState(false);

  const dispatch = useDispatch();

  async function doDelete() {
    setInProgress(true);
    const response = await deleteRecords(objectType, objectIds);
    setInProgress(false);
    if (response === SUCCESS) {
      onClose();
      dispatch(
        openAlert({ type: "success", message: i18n("alert.SuccessDeleted") })
      );
      // Updates top nav list to reflect deleted notifications
      if (objectType === "notification") {
        dispatch(deleteNotifications(objectIds));
      }
    } else {
      switch (response) {
        case DELETE_RECORDS_LOT_HAS_ONHAND:
          dispatch(
            openAlert({
              type: "error",
              message: i18n("lots.DeleteRecordsLotHasOnhand"),
            })
          );
          break;
        case DELETE_RECORDS_LOT_USED_ON_TRANSACTIONS:
          dispatch(
            openAlert({
              type: "error",
              message: i18n("lots.DeleteRecordsLotUsedOnTransactions"),
            })
          );
          break;
        default:
          handleProgramError(new Error(i18n("global.Error")));
      }
    }
    dispatch(recordsChangedTick());
    onClose();
  }

  return (
    <Dialog open={open} fullWidth maxWidth="xs" sx={{ inset: "0 0 40%" }}>
      <DialogTitle>{i18n("global.Delete")}</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {i18n("button.Cancel")}
        </Button>
        <div style={{ position: "relative" }}>
          <Button color="secondary" disabled={inProgress} onClick={doDelete}>
            {i18n("button.Delete")}
          </Button>
          {inProgress && <ButtonProgress color="secondary" />}
        </div>
      </DialogActions>
    </Dialog>
  );
}
