import { useState, useEffect } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, TextField } from "@mui/material";

import { Tooltip } from "components/Tooltip";
import { LineSelectPopper } from "components/formFields/LineSelectPopper";
import {
  textFieldStyle,
  outlinedStyles,
  inputPropStyles,
} from "components/formFields/lineItemFieldStyle";

import { exactNameMatch } from "services/sosInventoryService/sosApi";
import { checkForUnexpectedProps } from "services/utility/misc";

export function LineItemSelectWithExact(props) {
  const {
    name,
    options: initialOptions,
    optionDisplayText = "name",
    onValueChange,
    value: initialValue,
    error,
    allowTooltip,
    dataTesting,
    ...unexpected
  } = props;
  checkForUnexpectedProps("LineItemSelectWithExact", unexpected);

  const [value, setValue] = useState(initialValue);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [dirtyObjectInput, setDirtyObjectInput] = useState(false);
  const [, setUserInput] = useState("");

  useEffect(() => {
    if (!initialOptions || !initialOptions.length) {
      return;
    }
    setOptions(initialOptions);
  }, [initialOptions]);

  // be sure value[optionDisplayText] exists; it may be undefined on initial
  // load, as its value may be a standard reference object
  useEffect(() => {
    if (value && !value[optionDisplayText]) {
      const matchingOption = options.find(({ id }) => id === value.id);
      if (matchingOption) {
        setValue((prevValue) => ({
          ...prevValue,
          [optionDisplayText]: matchingOption[optionDisplayText],
        }));
      }
    }
  }, [optionDisplayText, value, options]);

  useEffect(() => setValue(initialValue), [initialValue]);

  if (!initialOptions || initialOptions.length === 0 || options.length === 0) {
    return null;
  }

  // this gets called whenever what the user types in the field changes
  function onInputChange(_, newInputValue, reason) {
    setInputValue(newInputValue);
    if (reason === "input") {
      setDirtyObjectInput(true);
    }
  }

  // this gets called whenever the user changes the selected option
  function onChange(_, newValue, reason) {
    if (reason === "clear") {
      setValue(null);
      setUserInput("");
      onValueChange(name, null);
      return;
    }

    setValue(newValue);
    onValueChange(name, newValue);
    setDirtyObjectInput(false);
  }

  async function onBlur() {
    if ((value && !dirtyObjectInput) || (!value && !inputValue)) {
      return;
    }

    if ((!value || dirtyObjectInput) && inputValue) {
      // first, see if the user's input is an exact match on any option
      const objectMatch = options.find(
        (option) =>
          typeof option[optionDisplayText] === "string" &&
          option[optionDisplayText].toLowerCase() === inputValue.toLowerCase()
      );
      if (objectMatch) {
        setValue(objectMatch);
        onValueChange(name, objectMatch);
        return;
      }

      // no match on selectable options; see if there is a match on an object that is not
      // in the option list for some reason (archived, not shown on forms, etc.)
      const object = await exactNameMatch(name, inputValue);
      if (object) {
        // add the "new" object to the options list
        const newObject = {
          id: object.id,
          [optionDisplayText]: object[optionDisplayText],
        };
        const newOptions = [newObject, ...options];
        setOptions(newOptions);
        setValue(newObject);
        onValueChange(name, object);
        return;
      }
    }
  }

  // this gets called when the component needs to know what to display
  // in the input field; not the value, which is the item id, but the
  // human-friendly *name* of the item
  function getOptionLabel(option) {
    // option or option.id can be blank on a newly inserted line;
    // and if we're adding a new item we don't want any text in the
    // component
    if (!Boolean(option?.id)) {
      return "";
    }

    if (value && value[optionDisplayText]) {
      return value[optionDisplayText];
    }

    const selectedOption = options.find(({ id }) => id === option.id);
    if (selectedOption) {
      return selectedOption[optionDisplayText];
    }

    if (value && value.name) {
      return value.name;
    }

    return "";
  }

  // this gets called for each option, when the selected option changes, to
  // determine which, of all the options, was selected
  function isOptionEqualToValue(option, value) {
    if (!option || !value) {
      return false;
    }
    return option.id === value.id || (value === "" && option.id === "");
  }

  function renderOption(props, option) {
    return (
      <li {...props} data-testing="selectOption" key={option.id}>
        {option[optionDisplayText]}
      </li>
    );
  }

  function renderInput(params) {
    const textfield = (
      <TextField
        {...params}
        error={error}
        variant="outlined"
        margin="dense"
        fullWidth
        sx={error ? textFieldStyle : { ...textFieldStyle, ...outlinedStyles }}
        InputProps={{ ...params.InputProps, sx: inputPropStyles }}
        InputLabelProps={{ shrink: true }}
        SelectProps={{ MenuProps: { disableScrollLock: true } }}
        data-testing={dataTesting}
      />
    );
    if (allowTooltip) {
      const title = value ? value[optionDisplayText] : "";
      return (
        <Tooltip title={title} placement="bottom-start">
          {textfield}
        </Tooltip>
      );
    }
    return textfield;
  }

  return (
    <Autocomplete
      name={name}
      value={value}
      loading={!Array.isArray(options)}
      options={options}
      onChange={onChange}
      onInputChange={onInputChange}
      onBlur={onBlur}
      PopperComponent={LineSelectPopper}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderOption={renderOption}
      fullWidth
      popupIcon={<KeyboardArrowDownIcon sx={{ color: "selectIcon" }} />}
      renderInput={renderInput}
    />
  );
}
