import { useState, useEffect, useCallback, useMemo } from "react";

import { DEBOUNCE_WAIT } from "appConfig";

import { FrmSelectBase } from "components/formFields/FrmSelectBase";

import { i18n } from "services/i18nService";
import { getItemRecord } from "services/sosInventoryService/domainLogic";
import { getItemQuicklist } from "services/sosInventoryService/sosApi";
import { itemsIncludingValue } from "services/utility/edit";
import { checkForUnexpectedProps } from "services/utility/misc";
import { createItemQuickListFilters } from "services/utility/misc";

import PropTypes from "prop-types";

/**
 * @name    FrmItemSelect
 *
 * @summary component to present users with a list of items and let the
 *          caller know which one was selected (if any) via a callback
 *
 * @param   name (string) - HTML name to set on this field
 *
 * @param   value (inventory item object, containing at least an id) -
 *          current value that should be shown in this component
 *
 * @param   type (one of the enumerated strings in ITEM_QUICKLIST_CASES)
 *          see appConstants.js for valid values
 *
 * @param   onValueChange (function)
 *          signature: function(fieldName, newValue) => (no return value)
 *          fieldName should be the name of the record field to be updated
 *          newValue should be the user's selected item record
 *
 * @param   error (boolean) - whether error status should be set on this
 *          component
 *
 * @param   disabled (boolean) - whether this component should be disabled
 */
export function FrmItemSelect(props) {
  const {
    name,
    label = i18n("frmLabel.Item"),
    value: inputValue,
    type,
    onValueChange,
    error = false,
    disabled,
    disableClearable,
    dataTesting,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmItemSelect", unexpected);

  const [items, setItems] = useState([]);
  const [value, setValue] = useState(inputValue);
  const [itemsCount, setItemsCount] = useState([]);
  const [timeoutId, setTimeoutId] = useState();

  const itemsPlusValue = useMemo(() => {
    return itemsIncludingValue(items, value);
  }, [items, value]);

  useEffect(() => {
    async function getItemData() {
      const { id, name } = await getItemRecord(inputValue.id);
      setValue({ id, name });
    }
    if (
      inputValue?.id &&
      !inputValue?.name &&
      items?.length &&
      !items.find(({ id }) => id === inputValue.id)
    ) {
      getItemData();
    }
  }, [inputValue, items]);

  const fetchItems = useCallback(
    (query) => {
      clearTimeout(timeoutId);
      setItems(null); // triggers select loading UI
      setTimeoutId(
        setTimeout(async () => {
          const { data } = await getItemQuicklist({
            ...createItemQuickListFilters(type),
            query,
          });
          setItems(data);
        }, DEBOUNCE_WAIT)
      );
    },
    [timeoutId, type]
  );

  const fetchItemsOnChange = useMemo(
    () => items && items.length < itemsCount,
    [items, itemsCount]
  );

  useEffect(() => {
    async function getInitialItems() {
      const { data, totalCount } = await getItemQuicklist({
        ...createItemQuickListFilters(type),
      });
      setItems(data);
      setItemsCount(totalCount);
    }
    getInitialItems();
  }, [type]);

  function filterOptions(options, state) {
    return options.filter(
      ({ id, name }) =>
        id !== "" &&
        name?.toLowerCase().includes(state.inputValue.toLowerCase())
    );
  }

  function getOptionLabel(option) {
    if (option?.name) {
      return option.name;
    }
    const selectedOption = itemsPlusValue?.find(({ id }) => id === option.id);
    return selectedOption?.name || "";
  }

  function renderOption(props, option) {
    return (
      <div
        {...props}
        data-testing="selectOption"
        style={{ minHeight: "1.5em" }}
        key={option.id}
      >
        {option.name}
      </div>
    );
  }

  function onInputChange(_, query, reason) {
    if (reason !== "reset") {
      fetchItemsOnChange && fetchItems(query);
    }
  }

  return (
    <FrmSelectBase
      loading={!Array.isArray(items)}
      name={name}
      label={label}
      isOptionEqualToValue={({ id }, value) => id === value.id}
      options={itemsPlusValue}
      onValueChange={onValueChange}
      value={inputValue}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      filterOptions={filterOptions}
      onInputChange={onInputChange}
      error={error}
      disabled={disabled}
      disableClearable={disableClearable}
      dataTesting={dataTesting}
    />
  );
}

FrmItemSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  disableClearable: PropTypes.bool,
  dataTesting: PropTypes.string,
};
