import { useRef } from "react";

import { Button } from "@mui/material";

import { FullsteamForms } from "components/FullsteamForms";
import { Tooltip } from "components/Tooltip";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmCheckboxWithHoverHelp } from "components/formFields/frm/FrmCheckboxWithHoverHelp";

import { i18n } from "services/i18nService";
import { logHostedPaymentsResult } from "services/sosInventoryService/sosApi";
import { formatCardOnFile } from "services/utility/formatting";
import { isPersistedRecord } from "services/utility/misc";
import { getCardType } from "services/utility/misc";
import { launchHostedPayments } from "services/utility/payments";

import { SOS_PAY_CREDIT, SOS_PAY_ACH } from "appConstants";

export function FrmFsPaymentMethod(props) {
  const {
    recordId,
    label,
    paymentInfo,
    options,
    storeCustomerToken,
    paymentMethod,
    postalCode,
    hasCardOnFile,
    contact,
    checkNumber,
    customer,
    showKeepOnFile,
    error,
    onValueChange: externalOnValueChange,
  } = props;

  const idRef = useRef();
  const tokenRef = useRef();
  const responseRef = useRef();
  const fullSteamCardRef = useRef();

  async function fullsteamComplete() {
    const token = tokenRef.current.value;
    const result = responseRef.current.value;
    const id = idRef.current.value;
    logHostedPaymentsResult({ id, token, result });

    const parsedResult = JSON.parse(result);
    const { gatewayResponse, requestDetails } = parsedResult;
    if (gatewayResponse.accountDetails) {
      const {
        expirationMonth,
        expirationYear,
        paymentAccountLast4,
        paymentMethod,
      } = gatewayResponse.accountDetails;
      const paymentInfo = formatCardOnFile(null, {
        expMonth: expirationMonth,
        expYear: expirationYear,
        lastFour: paymentAccountLast4,
        tokenType: getCardType(paymentMethod),
      });
      externalOnValueChange("accountToken", { token, paymentInfo });
    } else {
      const { accountNumber, accountType } = requestDetails;
      const paymentInfo = formatCardOnFile(null, {
        lastFour: accountNumber,
        tokenType: getCardType(accountType),
      });
      externalOnValueChange("accountToken", { token, paymentInfo });
    }
    fullSteamCardRef.current.innerHTML = "";
  }

  async function handleHostedPayments() {
    launchHostedPayments(
      isCardPayment ? "card" : "ach",
      postalCode,
      contact,
      fullsteamComplete
    );
  }

  function onValueChange(name, value) {
    const newValue = options.find(({ id }) => id === value.id);
    externalOnValueChange(name, newValue);
  }

  const isCardPayment = paymentMethod?.sosPayType === SOS_PAY_CREDIT;
  const isAchPayment = paymentMethod?.sosPayType === SOS_PAY_ACH;

  function getOptionDisabled(option) {
    if (!isPersistedRecord(recordId)) {
      return false;
    }
    if (isCardPayment) {
      return option.sosPayType !== SOS_PAY_CREDIT;
    }
    if (isAchPayment) {
      return option.sosPayType !== SOS_PAY_ACH;
    }
    return (
      option.sosPayType === SOS_PAY_CREDIT || option.sosPayType === SOS_PAY_ACH
    );
  }

  function getDisabledToolTip() {
    return isCardPayment || isAchPayment
      ? i18n("payment.PaymentMethodDisabledOnEdit")
      : i18n("payment.SosPayOptionsDisabled");
  }

  function renderOption(props, option) {
    return (
      <Tooltip
        key={option.id}
        title={getOptionDisabled(option) ? getDisabledToolTip() : ""}
      >
        <div>
          <li
            {...props}
            className={props.className}
            data-testing="selectOption"
          >
            {option.name}
          </li>
        </div>
      </Tooltip>
    );
  }

  if (!options) {
    return null;
  }

  return (
    <>
      <FrmSelectFromObjects
        disableClearable
        maxWidth="20rem"
        name="paymentMethod"
        label={label}
        value={paymentMethod}
        onValueChange={onValueChange}
        getOptionDisabled={getOptionDisabled}
        renderOption={renderOption}
        options={options}
        error={error}
      />
      {paymentMethod?.name === "Check" && (
        <div style={{ gridColumn: "1 / 2" }}>
          <FrmTextField
            name="checkNumber"
            label={i18n("frmLabel.CheckNumber")}
            value={checkNumber}
            onValueBlur={onValueChange}
          />
        </div>
      )}
      {(isCardPayment || isAchPayment) && (
        <>
          {hasCardOnFile ? (
            <>
              <div style={{ marginTop: "1rem" }}>
                {i18n("global.Details")}: {paymentInfo}
              </div>
              {showKeepOnFile && (
                <FrmCheckboxWithHoverHelp
                  name="storeCustomerToken"
                  value={storeCustomerToken}
                  onValueChange={externalOnValueChange}
                  label={i18n("frmLabel.KeepOnFile")}
                  helpText={i18n("hoverHelp.StorePaymentInformation")}
                />
              )}
            </>
          ) : (
            <Tooltip
              title={customer ? "" : i18n("tooltip.SelectCustomerFirst")}
            >
              <span>
                <Button
                  onClick={handleHostedPayments}
                  disabled={!customer}
                  color="primary"
                >
                  {i18n(
                    isCardPayment
                      ? "payment.EnterCardInfo"
                      : "payment.EnterAchInfo"
                  )}
                </Button>
              </span>
            </Tooltip>
          )}
        </>
      )}
      <FullsteamForms
        idRef={idRef}
        tokenRef={tokenRef}
        responseRef={responseRef}
        fullSteamCardRef={fullSteamCardRef}
      />
    </>
  );
}
