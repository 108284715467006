import { Box } from "@mui/material";

import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";

export function FrmComment(props) {
  const { onValueChange, value } = props;

  return (
    <Box display="flex" alignItems="center" sx={{ maxWidth: "100%" }}>
      <FrmResizeTextField
        minRows={2}
        name="comment"
        label={i18n("frmLabel.Comment")}
        onValueBlur={onValueChange}
        width="95%"
        value={value}
      />
      <HoverHelp title={i18n("hoverHelp.comment")} sx={{ marginLeft: 0.5 }} />
    </Box>
  );
}
