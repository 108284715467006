export function beforeSave(record) {
  const newRecord = {
    ...record,
    pictureWidth: record.pictureSize.width,
    pictureHeight: record.pictureSize.height,
    preventNegativeInBins: !record.allowNegativeInBins,
    findItemsByShortName: !record.includeCategoryPrefix,
    // serialLotNumbering fields
    autoSerialNumbers: record.serialLotNumbering.automaticallyNumber,
    autoSerialLotRandom: record.serialLotNumbering.numberRandomly,
    autoSerialNumberLength: record.serialLotNumbering.autoLength,
    autoSerialNumberPrefix: record.serialLotNumbering.serials.prefix,
    autoSerialNextSequential: record.serialLotNumbering.serials.nextNumber,
    autoLotNumberPrefix: record.serialLotNumbering.lots.prefix,
    autoLotNextSequential: record.serialLotNumbering.lots.nextNumber,
  };

  delete newRecord.pictureSize;
  delete newRecord.allowNegativeInBins;
  delete newRecord.includeCategoryPrefix;
  delete newRecord.serialLotNumbering;

  return newRecord;
}
