// Actual domain logic should be in a domainLogic.js file, at the appropriate
// level in the hierarchy.
import { ITEM_CALCULATED_FIELDS } from "appConfig";
import { IN_TRANSACTION } from "appConfig";

import { Money } from "classes/DecimalClasses";

import { removeLotAndSerialValues } from "services/sosInventoryService/domainLogic";
import { extractBillingAndShippingFromCustomer } from "services/sosInventoryService/domainLogic";
import { updateLineItemOnhand } from "services/sosInventoryService/domainLogic";
import {
  updateAvailableBinsAndBin,
  updateLineRelatedRecordsItem,
} from "services/sosInventoryService/domainLogic";
import { getEmptyRecord } from "services/sosInventoryService/shipment/schema";
import { getRecord, getRecordFrom } from "services/sosInventoryService/sosApi";
import {
  copyCustomFieldValues,
  reconcileCustomFields,
  getCustomFieldDefinitions,
} from "services/utility/customFields";
import { getRelatedReferenceObjects } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";

import globalState from "globalState/globalState";

import { OBJECT_TYPES } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.SHIPMENT.fullString;

export async function getTransaction(
  id,
  newFromId,
  newFromObjectType,
  updaters,
  customFieldDefs
) {
  let record, lines, relatedRecords;

  if (id || newFromObjectType) {
    if (id) {
      record = await getRecord(OBJECT_TYPE, id);
    } else {
      // it's a "populate from" request
      record = await getRecordFrom(OBJECT_TYPE, newFromObjectType, newFromId);
      delete record.id;
    }

    record.customFields = reconcileCustomFields(
      customFieldDefs,
      record.customFields
    );

    lines = record.lines;
    delete record.lines;

    updaters.lineHandler({ type: "set", lines });
    updaters.setRecord(record);

    relatedRecords = await getRelatedReferenceObjects(OBJECT_TYPE, record);

    updaters.setRelatedRecords(relatedRecords);

    // get items for each line item, so that we have updated item info for
    // the calculations below
    lines = await updateLineRelatedRecordsItem(
      record.location,
      record.date,
      lines,
      ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
    );

    // set the default bin and the available bins for the dropdown
    lines = await updateAvailableBinsAndBin(record.location, lines);

    // update onhand info in the line items
    lines = updateLineItemOnhand(lines);
  } else {
    // new record
    const { settings } = globalState.getState().userCompanySettings;
    const defaultLocation =
      globalState.getState().userCompanySettings.settings.defaultLocation;
    const numLinesToAdd =
      globalState.getState().userCompanySettings.settings.numLinesToAdd;

    record = {
      ...getEmptyRecord(settings),
      customFields: reconcileCustomFields(customFieldDefs, []),
    };

    lines = Array(numLinesToAdd).fill(EMPTY_LINE_ITEM[OBJECT_TYPE]);

    relatedRecords = {};

    if (defaultLocation) {
      record.location = defaultLocation;

      const location = await getRecord(
        "location",
        record.location.id,
        IN_TRANSACTION
      );

      relatedRecords = { ...relatedRecords, location };
    }
    updaters.setRelatedRecords(relatedRecords);
    updaters.setRecord(record);
  }
  updaters.lineHandler({ type: "set", lines });
}

export async function updateTransaction(
  field,
  newValue,
  transactionCustomFieldDefs,
  currentState,
  updaters
) {
  const { record, lines, relatedRecords } = currentState;
  if (!record || !lines || !relatedRecords) {
    handleProgramError(new Error("update Transaction | invalid currentState"));
  }

  let newRecord, newLines, newRelatedRecords;

  switch (field) {
    case "date":
      newRecord = { ...record, date: newValue };
      updaters.setRecord(newRecord);

      if (newValue) {
        // reget items for each line item, so that we have updated item info
        // for the calculations below
        newLines = await updateLineRelatedRecordsItem(
          newRecord.location,
          newRecord.date,
          lines,
          ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
        );

        // set the default bin and the available bins for the dropdown
        newLines = await updateAvailableBinsAndBin(
          newRecord.location,
          newLines
        );

        // update onhand info in the line items
        newLines = updateLineItemOnhand(newLines);
      }

      break;

    case "location":
      newRecord = { ...record, location: newValue };
      updaters.setRecord(newRecord);

      const location = newValue
        ? await getRecord("location", newValue.id)
        : null;
      newRelatedRecords = { ...relatedRecords, location: location };

      // reget items for each line item, so that we have updated item info
      // for the calculations below
      newLines = await updateLineRelatedRecordsItem(
        newRecord.location,
        newRecord.date,
        lines,
        ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
      );

      // set the default bin and the available bins for the dropdown
      newLines = await updateAvailableBinsAndBin(newRecord.location, newLines);

      // update onhand info in the line items
      newLines = updateLineItemOnhand(newLines);

      newLines = removeLotAndSerialValues(newLines);

      break;

    case "assignedToUser":
    case "priority":
    case "channel":
    case "shippingMethod":
    case "customerNotes":
    case "customerMessage":
    case "comment":
    case "department":
    case "shipBy":
    case "number":
    case "trackingNumber":
    case "customerPO":
    case "customFields":
    case "forceToShipStation":
    case "createBillForShippingAmount":
    case "billing":
    case "shipping":
      newRecord = { ...record, [field]: newValue };
      updaters.setRecord(newRecord);
      break;

    case "shippingAmount":
      newRecord = { ...record, [field]: new Money(newValue || 0) };
      updaters.setRecord(newRecord);
      break;

    case "customer":
      // if user de-selected customer, all we need to do is set the
      // customer state and the customer field in the record to null
      if (!newValue) {
        newRecord = { ...record, customer: null };
        updaters.setRecord(newRecord);
        newRelatedRecords = { ...relatedRecords, customer: null };
        break;
      }

      const customerCustomFieldDefs = await getCustomFieldDefinitions(
        "customer"
      );

      // get the newly selected customer record
      const customer = await getRecord("customer", newValue.id, IN_TRANSACTION);

      // be sure there are custom field entries for each defined custom field
      // in the customer record
      const customerCustomFields = reconcileCustomFields(
        customerCustomFieldDefs,
        customer.customFields
      );

      // initialize any transaction custom fields to their matching customer
      // custom field values, if any
      const newTransactionCustomFields = copyCustomFieldValues(
        customerCustomFieldDefs,
        customerCustomFields,
        transactionCustomFieldDefs,
        record.customFields
      );

      // get contact, billing, and shipping info from customer
      const { billing, shipping } =
        extractBillingAndShippingFromCustomer(customer);

      newRecord = {
        ...record,
        customer: newValue,
        customerNotes: customer.notes,
        customFields: newTransactionCustomFields,
        billing,
        shipping,
      };
      updaters.setRecord(newRecord);
      newRelatedRecords = { ...relatedRecords, customer };

      break;

    default:
      handleProgramError(
        new Error(`updateTransaction | unknown field (${field})`)
      );
  }

  if (newLines) {
    updaters.lineHandler({ type: "set", lines: newLines });
  }
  if (newRelatedRecords) {
    updaters.setRelatedRecords(newRelatedRecords);
  }
}
