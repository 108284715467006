import { useState, useEffect } from "react";

import { Decimal } from "classes/DecimalClasses";

import { SelectButtonCombo } from "components/formFields/SelectButtonCombo";
import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

import { OBJECT_TYPES } from "appConstants";

const listPrice = {
  id: 0,
  name: "List Price",
  items: [],
  percentDiscount: new Decimal(0),
  amountDiscount: new Decimal(0),
};

export function FrmApplyPriceTier(props) {
  const { value, onValueChange, refreshTierRates } = props;

  useEffect(() => {
    if (value) {
      setPriceTier(value);
    }
  }, [value]);

  const priceTiers = useRelated(OBJECT_TYPES.PRICE_TIER.fullString);
  const [priceTier, setPriceTier] = useState(value || listPrice);

  function handleChange(field, value) {
    const { id } = value || {};
    const priceTier = id
      ? priceTiers.find((tier) => tier.id === id)
      : listPrice;
    setPriceTier(priceTier);
    onValueChange(field, priceTier);
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <SelectButtonCombo
        disableClearable
        minWidth="10rem"
        name="priceTier"
        selectLabel={i18n("frmLabel.PriceTier")}
        buttonLabel={i18n("global.Apply")}
        value={priceTier}
        onValueChange={handleChange}
        onClick={() =>
          refreshTierRates(
            priceTiers.find(({ id }) => id === priceTier.id) || listPrice
          )
        }
        options={priceTiers ? [listPrice, ...priceTiers] : [listPrice]}
      />
      <div>
        <HoverHelp
          title={i18n("hoverHelp.PriceTier")}
          sx={{ marginLeft: 0.5, top: "0.3rem" }}
        />
      </div>
    </div>
  );
}
