import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { Loading } from "classes/Loading";

import { Tooltip } from "components/Tooltip";
import { LineItemText } from "components/formFields/LineItemText";
import { LineSkeleton } from "components/formFields/LineSkeleton";
import { LineTableCell } from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";

export function LineAvailable(props) {
  const { value, fetchAvailable } = props;

  const isLoading = value instanceof Loading;

  if (value === null) {
    return (
      <LineTableCell
        sx={{
          backgroundColor: "background.lineItems",
          textAlign: "center",
        }}
      >
        <Tooltip title={i18n("tooltip.ClickToRetrieveAvailable")}>
          <IconButton size="small" onClick={fetchAvailable}>
            <Visibility />
          </IconButton>
        </Tooltip>
      </LineTableCell>
    );
  }

  return (
    <LineTableCell
      sx={{
        minWidth: "5.5rem",
        backgroundColor: "staticTableCell",
      }}
    >
      {isLoading ? (
        <LineSkeleton />
      ) : (
        <LineItemText
          allowBlank
          decimal
          value={value}
          sx={{ color: "staticTableCellText" }}
        />
      )}
    </LineTableCell>
  );
}
