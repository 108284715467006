import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import {
  extractName,
  formatDateTimeToDate,
  formatDocumentInfo,
  formatNumberLinkField,
  truncate,
  formatMoneyWithAdornments,
  formatBooleanToYesOrBlank,
  extractDescription,
  formatQuantityWithUom,
  getMoneyTotalWithAdornments,
  formatLinkableItem,
} from "services/utility/formatting";
import {
  inventoryItemTypeEditStrFunction,
  textTypeEditStrFunction,
  decimalTypeEditStrFunction,
  numberTypeEditStrFunction,
  referenceTypeEditStrFunction,
  linkedTransactionTypeEditStrFunction,
  arrayOfReferencesTypeEditStrFunction,
} from "services/utility/list";
import { parseHTML } from "services/utility/parsing";
import { TYPES } from "services/utility/schema";
import { starColumn } from "services/utility/schema";

import {
  NO_COLUMNS,
  NO_REF_NUMBER_STRING,
  DEFAULT_DECIMALS_UNROUNDED,
} from "appConstants";

export const columnInfo = [
  starColumn,
  {
    name: "number",
    fixed: true,
    heading: i18n("columns.ReferenceNumber"),
    formatFunc: formatNumberLinkField,
    minWidth: "7em",
    linkField: true,
    sortable: true,
  },
  {
    name: "date",
    heading: i18n("columns.Date"),
    formatFunc: formatDateTimeToDate,
    minWidth: "8em",
    defaultSort: true,
    sortDirection: "asc",
    sortable: true,
    default: true,
  },
  {
    name: "customer",
    heading: i18n("columns.Customer"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "location",
    heading: i18n("columns.Location"),
    formatFunc: extractName,
    sortable: true,
    default: true,
  },
  {
    name: "comment",
    heading: i18n("columns.Comment"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "department",
    heading: i18n("columns.Department"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "customerNotes",
    heading: i18n("columns.CustomerNotes"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
  },
  {
    name: "shippingMethod",
    heading: i18n("columns.ShipMethod"),
    formatFunc: extractName,
  },
  {
    name: "trackingNumber",
    heading: i18n("columns.TrackingNumber"),
  },
  {
    name: "channel",
    heading: i18n("columns.Channel"),
    formatFunc: extractName,
  },
  {
    name: "salesRep",
    heading: i18n("columns.SalesRep"),
    formatFunc: extractName,
    minWidth: "8em",
  },
  {
    name: "shippingAmount",
    heading: i18n("columns.ShippingCost"),
    formatFunc: formatMoneyWithAdornments,
  },
  {
    name: "hasSignature",
    heading: i18n("columns.Signature"),
    formatFunc: formatBooleanToYesOrBlank,
  },
];

export function getEmptyRecord(settings) {
  return {
    starred: 0,
    syncToken: 1,
    number: "",
    date: new Date(),
    customer: null,
    currency: settings.homeCurrencyRef,
    location: null,
    salesRep: null,
    channel: null,
    department: null,
    shippingMethod: null,
    trackingNumber: null,
    linkedTransaction: null,
    exchangeRate: new Decimal(1.0),
    customerMessage: null,
    comment: null,
    customerNotes: null,
    customFields: null,
    shipBy: null,
    shippingAmount: new Money(0.0),
    total: new Decimal(0.0),
    archived: false,
    summaryOnly: false,
    hasSignature: false,
    documents: [],
    keys: null,
    values: null,
    createCreditMemo: false,
    createBillForShippingAmount: false,
  };
}

export const LINE_ITEM_METADATA = {
  lineNumber: {},
  item: {
    type: TYPES.reference,
    editStr: inventoryItemTypeEditStrFunction,
    initialValue: { id: null, name: null, description: null },
  },
  description: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  quantity: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weight: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: numberTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weightunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  volume: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  volumeunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  uom: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  unitprice: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  amount: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  altAmount: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  linkedTransaction: {
    type: TYPES.linkedTransaction,
    editStr: linkedTransactionTypeEditStrFunction,
  },
  linkedTransactionRefNumber: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  linkedTransactionLineNumber: {
    type: TYPES.number,
    editStr: numberTypeEditStrFunction,
  },
  class: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  jobWorkcenter: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  bin: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  lot: {
    type: TYPES.reference,
    editStr: textTypeEditStrFunction,
  },
  serials: {
    type: TYPES.arrayOfReferences,
    editStr: arrayOfReferencesTypeEditStrFunction,
    initialValue: null,
  },
  itemDetails: { initialValue: {} },
  relatedRecords: { initialValue: {} },
};

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: ["shippingAmount"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["date"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const LINE_ACTIONS = [
  ["quickView", "pdf", "email"],
  "template",
  "shipUPS",
  "archive",
  "delete",
  "history",
  "sync",
];

export const REFERENCE_FIELDS = ["location", "customer"];
export const BATCH_ACTIONS = [["pdf", "email"], "delete", "archive", "sync"];
export const ITEM_CALCULATED_FIELDS = [NO_COLUMNS];

export const QUICK_VIEW_CONFIG = {
  signatureLinesLabel: i18n("colon.ReceivedBy"),
  tableConfig: [{ type: "lines" }],
  headerFields: [
    {
      name: "date",
      formatFunc: formatDateTimeToDate,
      label: i18n("columns.Date"),
    },
    {
      name: "number",
      label: i18n("frmLabel.ReturnNumber"),
      defaultValue: NO_REF_NUMBER_STRING,
    },
  ],
  fields: [
    {
      label: i18n("frmLabel.Customer"),
      name: "customer",
      formatFunc: extractName,
    },
    {
      label: i18n("frmLabel.Location"),
      name: "location",
      formatFunc: extractName,
    },
  ],
  lines: [
    {
      name: "item",
      formatFunc: formatLinkableItem,
      heading: i18n("columns.Item"),
      align: "left",
      isItemField: true,
    },
    {
      name: "description",
      formatFunc: extractDescription,
      heading: i18n("columns.Description"),
      align: "left",
    },
    {
      name: "quantity",
      formatFunc: formatQuantityWithUom,
      heading: i18n("columns.Quantity"),
      align: "right",
    },
    {
      name: "unitprice",
      formatFunc: formatMoneyWithAdornments,

      heading: i18n("columns.Rate"),
      align: "right",
    },
    {
      name: "amount",
      formatFunc: formatMoneyWithAdornments,
      footerFunc: getMoneyTotalWithAdornments,
      heading: i18n("columns.Amount"),
      align: "right",
      isTotalColumn: true,
    },
  ],
};
