import { FormControlLabel, Checkbox } from "@mui/material";

import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";

export function FrmCreateBill(props) {
  const { onValueChange, value } = props;

  return (
    <div style={{ display: "flex", alignItems: "baseline" }}>
      <FormControlLabel
        control={
          <Checkbox
            name="createBillForShippingAmount"
            checked={value}
            onChange={(e) => onValueChange(e.target.name, e.target.checked)}
          />
        }
        label={i18n("frmLabel.CreateBill")}
        sx={{ marginRight: 0 }}
      />
      <HoverHelp
        title={i18n("hoverHelp.CreateBill")}
        sx={{ marginLeft: 0.5 }}
      />
    </div>
  );
}
