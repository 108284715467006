import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { Layout } from "Layout";

import { CustomFields } from "components/CustomFields/CustomFields";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { SectionHeading } from "components/SectionHeading";
import {
  FrmNumber,
  FrmLocation,
  FrmMultiplier,
  FrmTemplate,
  FrmComment,
  FrmDepartment,
} from "components/formFields/frm";
import { FrmAutoSerialLots } from "components/formFields/frm";
import { FrmJob } from "components/formFields/frm/FrmJob";
import { FrmSignature } from "components/formFields/frm/FrmSignature";
import { FrmWorkCenter } from "components/formFields/frm/FrmWorkCenter";
import { DatePicker } from "components/html/Input/DatePicker";
import { DateTimePicker } from "components/html/Input/DateTimePicker";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";
import { WarningModal } from "components/utility/WarningModal";

import { i18n } from "services/i18nService";
import {
  updateInputTotals,
  INPUT_ZERO_TOTALS,
} from "services/sosInventoryService/process/afterTouchInputLine";
import {
  updateOutputTotals,
  OUTPUT_ZERO_TOTALS,
} from "services/sosInventoryService/process/afterTouchOutputLine";
import {
  updateByMultiplier,
  addTemplateLines,
} from "services/sosInventoryService/productionTransaction/domainLogic";
import { setPageDirty } from "services/utility/edit";

import { useCustomFieldDefinitions } from "hooks/useCustomFieldDefinitions";
import { useErrors } from "hooks/useErrors";
import { useInputOutputTransaction } from "hooks/useInputOutputTransaction";
import { useItems } from "hooks/useItems";
import { useLineHandler } from "hooks/useLineHandler";

import { BottomWidgets } from "views/Processes/Process/BottomWidgets";
import { LineItems as InputLineItems } from "views/Processes/Process/Input/LineItems";
import { LineItems as OutputLineItems } from "views/Processes/Process/Output/LineItems";
import { updateTransaction } from "views/Processes/Process/controller";

import { theme } from "SosTheme";
import { OBJECT_TYPES } from "appConstants";
import { EMPTY_OUTPUT_LINE_ITEM, EMPTY_INPUT_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.PROCESS.fullString;

export function Process(props) {
  const {
    id,
    onClose,
    newFromId,
    newFromObjectType,
    newFromObjectTypeLine,
    disassembleId,
  } = props;

  // TRANSACTION STATE
  const [record, setRecord] = useState();
  const [relatedRecords, setRelatedRecords] = useState({});
  const [outputLines, outputLineHandler] = useLineHandler();
  const [inputLines, inputLineHandler] = useLineHandler();

  // UI STATE
  const [outputTotals, setOutputTotals] = useState(OUTPUT_ZERO_TOTALS);
  const [inputTotals, setInputTotals] = useState(INPUT_ZERO_TOTALS);
  const { errors, setErrors, isInError } = useErrors();
  const [clearOutputLines, setClearOutputLines] = useState(false);
  const [clearInputLines, setClearInputLines] = useState(false);
  const loadingState = useSelector((state) => state.loading.editModal);
  const [itemAdded, setItemAdded] = useState(0);
  const { items, itemsCount } = useItems(null, itemAdded);

  // SETTINGS
  const departmentEnabled = useSelector(
    (state) => state.userCompanySettings.settings.departmentEnabled
  );

  const transactionCustomFieldDefs = useCustomFieldDefinitions(OBJECT_TYPE);

  const CURRENT_STATE = { record, outputLines, inputLines, relatedRecords };

  // UPDATERS FOR CONTROLLER TO UPDATE STATE
  const UPDATERS = useMemo(
    () => ({
      setRecord,
      outputLineHandler,
      inputLineHandler,
      setRelatedRecords,
      setErrors,
    }),
    [outputLineHandler, inputLineHandler, setErrors]
  );

  useInputOutputTransaction(
    OBJECT_TYPE,
    id,
    setRecord,
    setRelatedRecords,
    outputLineHandler,
    inputLineHandler,
    transactionCustomFieldDefs,
    newFromObjectType,
    newFromObjectTypeLine,
    newFromId,
    setErrors,
    disassembleId
  );

  useEffect(() => {
    updateInputTotals(inputLines, setInputTotals);
  }, [inputLines]);

  useEffect(() => {
    updateOutputTotals(outputLines, setOutputTotals);
  }, [outputLines]);

  // HANDLE ALL USER INPUT
  function handleInputFieldChange(field, newValue) {
    setPageDirty();
    updateTransaction(field, newValue, CURRENT_STATE, UPDATERS);
  }

  async function handleMutiplierApply() {
    const { newOutputs, newInputs } = await updateByMultiplier(
      record.multiplier,
      outputLines,
      inputLines
    );
    outputLineHandler({ type: "set", lines: newOutputs });
    inputLineHandler({ type: "set", lines: newInputs });
  }

  async function handleTemplateApply() {
    const { newOutputs, newInputs } = await addTemplateLines(record);
    outputLineHandler({ type: "set", lines: newOutputs });
    inputLineHandler({ type: "set", lines: newInputs });
  }

  function doClearOutputLines() {
    setPageDirty();
    outputLineHandler({
      type: "clear",
      blankLine: EMPTY_OUTPUT_LINE_ITEM[OBJECT_TYPE],
    });
    setClearOutputLines(false);
  }

  function doClearInputLines() {
    inputLineHandler({
      type: "clear",
      blankLine: EMPTY_INPUT_LINE_ITEM[OBJECT_TYPE],
    });
    setPageDirty();
    setClearInputLines(false);
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        relatedRecords={relatedRecords}
        outputs={outputLines}
        inputs={inputLines}
        setErrors={setErrors}
        errors={errors}
        text={record?.number}
        handleClose={onClose}
      />
      {loadingState && <Loading />}
      <ObjectEditDialogContent>
        <Layout
          pageTitle={
            id
              ? `${i18n("objectType.process.Sentence")} ${record?.number || ""}`
              : i18n("objectType.process.New")
          }
        >
          <div id="B" style={{ margin: theme.spacing(2) }}>
            <FormErrors errors={errors} setErrors={setErrors} />
            {record ? (
              <>
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }}>
                    <DateTimePicker
                      name="date"
                      value={record.date}
                      label={i18n("frmLabel.DateTime")}
                      onValueChange={handleInputFieldChange}
                      error={isInError("date")}
                      dataTesting="date"
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmNumber
                      recordId={record.id}
                      label={i18n("process.labelNumber")}
                      value={record.number}
                      onValueChange={handleInputFieldChange}
                      error={isInError("number")}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmTemplate
                      width="25rem"
                      value={record.template}
                      onValueChange={handleInputFieldChange}
                      onApply={handleTemplateApply}
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmLocation
                      value={record.location}
                      onValueChange={handleInputFieldChange}
                      error={isInError("location")}
                    />
                  </div>
                </FrmFieldGrid>

                <VSpace space={2} />
                <SectionHeading>{i18n("global.Outputs")}</SectionHeading>
                <VSpace space={1} />

                <OutputLineItems
                  record={record}
                  objectType={OBJECT_TYPE}
                  relatedRecords={relatedRecords}
                  lines={outputLines}
                  items={items}
                  totals={outputTotals}
                  itemsCount={itemsCount}
                  addItem={() => setItemAdded((prev) => prev + 1)}
                  lineHandler={outputLineHandler}
                  setClearLines={setClearOutputLines}
                  lineItemsErrors={errors.line}
                  setErrors={setErrors}
                />

                <VSpace space={2} />

                <FrmFieldGrid gridAutoFlow="dense">
                  <FrmAutoSerialLots
                    value={record.autoSerialLotNumbering || false}
                    name="autoSerialLotNumbering"
                    onValueChange={handleInputFieldChange}
                  />
                </FrmFieldGrid>

                <VSpace space={1} />
                <SectionHeading>{i18n("global.Inputs")}</SectionHeading>
                <VSpace space={1} />

                <InputLineItems
                  record={record}
                  objectType={OBJECT_TYPE}
                  relatedRecords={relatedRecords}
                  lines={inputLines}
                  items={items}
                  totals={inputTotals}
                  itemsCount={itemsCount}
                  addItem={() => setItemAdded((prev) => prev + 1)}
                  lineHandler={inputLineHandler}
                  setClearLines={setClearInputLines}
                  lineItemsErrors={errors.line}
                  setErrors={setErrors}
                />
                <VSpace space={2} />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <BottomWidgets
                    objectType={OBJECT_TYPE}
                    lineType="inputs"
                    lineHandler={inputLineHandler}
                  />
                </div>
                <VSpace space={2} />
                <FrmFieldGrid gridAutoFlow="dense">
                  <FrmMultiplier
                    maxWidth="5rem"
                    value={record?.multiplier}
                    onValueChange={handleInputFieldChange}
                    onApply={handleMutiplierApply}
                  />
                  <DatePicker
                    value={record.startDate}
                    name="startDate"
                    label={i18n("frmLabel.StartDate")}
                    onValueChange={handleInputFieldChange}
                  />
                  <FrmJob
                    maxWidth="20rem"
                    value={record.job}
                    onValueChange={handleInputFieldChange}
                  />
                  <FrmWorkCenter
                    maxWidth="20rem"
                    value={record.workcenter}
                    onValueChange={handleInputFieldChange}
                  />
                  {departmentEnabled && (
                    <div style={{ gridColumn: "2 / 2" }}>
                      <FrmDepartment
                        maxWidth="20rem"
                        value={record.department}
                        onValueChange={handleInputFieldChange}
                      />
                    </div>
                  )}
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmComment
                      value={record.comment}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmSignature
                      objectType={OBJECT_TYPE}
                      id={record.id}
                      hasSignature={record.hasSignature}
                    />
                  </div>
                </FrmFieldGrid>

                <CustomFields
                  customFieldDefinitions={transactionCustomFieldDefs}
                  customFields={record.customFields}
                  onValueChange={handleInputFieldChange}
                  setErrors={setErrors}
                />
              </>
            ) : (
              !isInError("record") && (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )
            )}
            <VSpace space={4} />
          </div>
          {record && (
            <>
              <WarningModal
                title={i18n("global.ClearLines")}
                message={i18n("global.clearLinesMessage")}
                open={clearOutputLines}
                onOk={doClearOutputLines}
                onClose={() => setClearOutputLines(false)}
              />
              <WarningModal
                title={i18n("global.ClearLines")}
                message={i18n("global.clearLinesMessage")}
                open={clearInputLines}
                onOk={doClearInputLines}
                onClose={() => setClearInputLines(false)}
              />
            </>
          )}
        </Layout>
      </ObjectEditDialogContent>
    </>
  );
}
