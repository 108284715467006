import { useEffect, useState } from "react";

import { Typography } from "@mui/material";

import { LineItemTextField } from "components/formFields/LineItemTextField";
import { LineTableCell } from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";
import { handleProgramError } from "services/utility/errors";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineLinkedTransaction(props) {
  const {
    name,
    onValueChange,
    value: initialValue,
    objectType,
    transactionLabel,
  } = props;

  const [value, setValue] = useState(
    LINE_ITEM_METADATA[objectType].linkedTransaction.editStr(
      LINE_ITEM_METADATA[objectType].linkedTransaction,
      initialValue,
      null
    )
  );

  useEffect(() => {
    setValue(
      LINE_ITEM_METADATA[objectType].linkedTransaction.editStr(
        LINE_ITEM_METADATA[objectType].linkedTransaction,
        initialValue,
        null
      )
    );
  }, [initialValue, name, objectType]);

  function handleOnChange(fieldName, newValue) {
    switch (fieldName) {
      case "linkedTransactionRefNumber":
        onValueChange("linkedTransaction", {
          ...value,
          refNumber: newValue,
        });
        break;
      case "linkedTransactionLineNumber":
        onValueChange("linkedTransaction", {
          ...value,
          lineNumber: newValue ? parseInt(newValue) : null,
        });
        break;
      default:
        handleProgramError(
          new Error(
            `LinkedTransaction | Unexpected value for e.target.name (${fieldName})`
          )
        );
    }
  }

  return (
    <LineTableCell sx={{ minWidth: "10rem" }}>
      <div
        style={{ display: "flex", alignItems: "center", padding: "0 .5rem" }}
      >
        <Typography sx={{ whiteSpace: "nowrap", fontSize: "0.8125rem" }}>
          {transactionLabel}
        </Typography>
        <LineItemTextField
          name="linkedTransactionRefNumber"
          value={value.refNumber}
          onValueChange={handleOnChange}
          metadata={LINE_ITEM_METADATA[objectType].linkedTransactionRefNumber}
          width="8rem"
        />
        <Typography sx={{ whiteSpace: "nowrap", fontSize: "0.8125rem" }}>
          {i18n("global.LineNumber")}
        </Typography>
        <LineItemTextField
          name="linkedTransactionLineNumber"
          value={value.lineNumber}
          number
          onValueChange={handleOnChange}
          metadata={LINE_ITEM_METADATA[objectType].linkedTransactionLineNumber}
          width="3.5rem"
        />
      </div>
    </LineTableCell>
  );
}
