import {
  getPageWidth,
  getPageHeight,
  getConfigurationWidth,
  getConfigurationHeight,
} from "services/utility/misc";

export const listPageStyles = {
  minWidth: getPageWidth(),
  maxHeight: getPageHeight(),
};

export const configurationListPageStyles = {
  minWidth: getConfigurationWidth(),
  maxHeight: getConfigurationHeight(),
};
