export const SETTINGS = [
  {
    apiName: "adjustmentNumber",
    component: "FrmTransactionNumber",
    transactionType: "adjustment",
    hasInputLabel: true,
  },
  {
    apiName: "transferNumber",
    component: "FrmTransactionNumber",
    transactionType: "transfer",
    hasInputLabel: true,
  },
];
